import React, { useEffect, useState } from "react";
import { sleep } from "../../../../../../utils/misc";
import { useAppSelector } from "../../../../../../redux/hooks";
import { generateShortProductReview } from "../../../../../../api/rest/authenticated/Review";
import { ReviewQuestionContainer } from "./pros";
import {
  FlowSolidButton,
  FlowSolidButtonGrey,
} from "../../components/FlowButtons";
import FlowContainer from "../../components/FlowContainer";
import { brandclub_colors } from "@brandclub/common-ui";
import CharactersLimit from "../components/CharactersLimit";
import { AnimatePresence } from "framer-motion";
import { useMediaQuery } from "@mui/material";
import { MOBILE_SCREEN_SIZE } from "../../../../../AppNavigation/constants";
import ProductHeader from "../components/ProductHeader";

export const TITLE_LENGTH_MIN = 20;
export const TITLE_LENGTH_MAX = 50;
export const REVIEW_LENGTH_MIN = 50;
export const REVIEW_LENGTH_MAX = 500;
export const REVIEW_LENGTH_MAX_AMAZON = 1000;

const truncateTitle = (str: string) => {
  if (str.length > TITLE_LENGTH_MAX) {
    return str.slice(0, TITLE_LENGTH_MAX - 3) + "...";
  } else {
    return str;
  }
};

const truncateReview = (str: string, maxLength = REVIEW_LENGTH_MAX) => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength - 3) + "...";
  } else {
    return str;
  }
};

const TRANSITION_TEXT_CONST =
  "I’m your AI writing assistant.\n\nGive me a few seconds to incorporate your feedback and draft a product review for you.\n\n";

const TRANSITION_TEXT_ONE =
  TRANSITION_TEXT_CONST + "Adding your tone of voice now.";
const TRANSITION_TEXT_TWO =
  TRANSITION_TEXT_CONST + "Applying a few personalized comments.";
const TRANSITION_TEXT_THREE =
  TRANSITION_TEXT_CONST +
  "Polishing up the grammar, so your middle school teachers will be proud.";
const TRANSITION_TEXT_ARR = [
  TRANSITION_TEXT_ONE,
  TRANSITION_TEXT_TWO,
  TRANSITION_TEXT_THREE,
];

export const reviewLengthCheck = (
  title: string,
  review: string,
  maxLength = REVIEW_LENGTH_MAX
) => {
  if (title.length < TITLE_LENGTH_MIN || title.length > TITLE_LENGTH_MAX) {
    return false;
  }
  if (review.length < REVIEW_LENGTH_MIN || review.length > maxLength) {
    return false;
  }
  return true;
};

const ReviewInput = ({
  product,
  formattedSurvey,
  reviewText,
  setReviewText,
  reviewTitle,
  setReviewTitle,
  moveNext,
  moveBack,
  starRating,
}: {
  product: any;
  formattedSurvey: any;
  reviewText: string;
  setReviewText: React.Dispatch<React.SetStateAction<string>>;
  reviewTitle: string;
  setReviewTitle: React.Dispatch<React.SetStateAction<string>>;
  moveNext: () => void;
  moveBack: () => void;
  starRating: number;
}) => {
  const mobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);
  const maxReviewLength =
    product?.retailerId === 1 ? REVIEW_LENGTH_MAX_AMAZON : REVIEW_LENGTH_MAX;
  const userNameFromProfile = useAppSelector(
    ({ userProfile }) => userProfile?.given_name ?? userProfile?.name
  );
  const [generateStatus, setGenerateStatus] =
    useState<
      "normal" | "loading" | "loading failed" | "loading done" | "generating"
    >("normal");
  const [fetchedTitle, setFetchedTitle] = useState<string | null>(null);
  const [fetchedText, setFetchedText] = useState<string | null>(null);
  const [transitionReview, setTransitionReview] = useState<string>("");

  const generate = async () => {
    try {
      setReviewTitle("");
      setReviewText("");

      startLoadingAnimation();
      const res = await generateShortProductReview({
        req: {
          userProductFeedback: formattedSurvey,
          productId: product,
          userProductStarRating: starRating,
        },
      });

      if (
        res?.shortProductReview?.reviewTitle &&
        res?.shortProductReview?.reviewText
      ) {
        setFetchedTitle(
          truncateTitle(res?.shortProductReview?.reviewTitle || "")
        );
        setFetchedText(
          truncateReview(
            res?.shortProductReview?.reviewText || "",
            maxReviewLength
          )
        );
      } else {
        // error handle when res.status === 'failed'
        setFetchedTitle("");
        setFetchedText("");
      }
    } catch (error) {
      // error handle for other errors
      setFetchedTitle("");
      setFetchedText("");
      console.error("error generating review: ", error);
    }
  };

  const canEdit = generateStatus === "normal";

  const startAutoType = async (str: string) => {
    setGenerateStatus("generating");
    const strArr = str.split("");

    let finalStr = "";
    setReviewText("");
    while (strArr.length > 0) {
      const word = strArr.shift();
      if (word) {
        if (word === " ") {
          let waitTime = Math.floor(Math.random() * 20) + 2;
          if (waitTime > 22) {
            waitTime += 10;
          }
          await sleep(waitTime);
        } else {
          const waitTime = Math.floor(Math.random() * 2) + 2;
          await sleep(waitTime);
        }
        finalStr += `${word}`;
        setReviewText(finalStr);
      }
    }
    setGenerateStatus("normal");
  };

  const startLoadingAnimation = async () => {
    setGenerateStatus("loading");
    const text =
      `Hi, ${userNameFromProfile ?? "dear"}. ` +
      TRANSITION_TEXT_ARR[
        Math.floor(Math.random() * TRANSITION_TEXT_ARR.length)
      ];
    const strArr = text.split("");

    let finalStr = "";
    setTransitionReview("");
    while (strArr.length > 0) {
      const word = strArr.shift();
      if (word) {
        if (word === " ") {
          let waitTime = Math.floor(Math.random() * 20) + 2;
          if (waitTime > 22) {
            waitTime += 10;
          }
          await sleep(waitTime);
        } else {
          const waitTime = Math.floor(Math.random() * 2) + 2;
          await sleep(waitTime);
        }
        finalStr += `${word}`;
        setTransitionReview(finalStr);
      }
    }
    await sleep(1500);
    setGenerateStatus("loading done");
  };

  useEffect(() => {
    if (!reviewText.length && !reviewTitle.length) {
      // only generate if there is no text, otherwise user is moving back and forth
      generate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const doAutoType = async () => {
      setReviewTitle(fetchedTitle as string);
      await startAutoType(fetchedText as string);
    };
    if (generateStatus === "loading done" && fetchedText !== null) {
      doAutoType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateStatus, fetchedText]);

  const disabledNext = !reviewLengthCheck(
    reviewTitle,
    reviewText,
    maxReviewLength
  );
  return (
    <FlowContainer style={{ background: brandclub_colors.white }}>
      <ReviewQuestionContainer>
        <div className="review_info">
          <ProductHeader product={product} />
          <div className="section">
            <div className="title">Let’s start your product review.</div>
            <div className="text_input">
              <div className="second_title">Title</div>
              <div className="input_wrapper" style={{ position: "relative" }}>
                {mobileView ? ( // make two for mobile, one line for desktop
                  <textarea
                    disabled={!canEdit}
                    className="input_box single"
                    value={reviewTitle}
                    onChange={(e) => setReviewTitle(e.target.value)}
                  ></textarea>
                ) : (
                  <input
                    disabled={!canEdit}
                    className="input_box single"
                    value={reviewTitle}
                    onChange={(e) => setReviewTitle(e.target.value)}
                  ></input>
                )}
                <AnimatePresence mode="wait">
                  {["normal"].includes(generateStatus) && (
                    <CharactersLimit
                      text={reviewTitle}
                      minLength={TITLE_LENGTH_MIN}
                      maxLength={TITLE_LENGTH_MAX}
                    />
                  )}
                </AnimatePresence>
              </div>
              <div className="second_title" style={{ marginTop: 40 }}>
                Review
              </div>
              <div className="input_wrapper" style={{ position: "relative" }}>
                <textarea
                  disabled={!canEdit}
                  className="input_box"
                  value={reviewText}
                  placeholder={
                    ["loading", "loading done"].includes(generateStatus)
                      ? transitionReview
                      : ""
                  }
                  // style={{ height: 434 }}
                  onChange={(e) => setReviewText(e.target.value)}
                ></textarea>
                <AnimatePresence mode="wait">
                  {["normal"].includes(generateStatus) && (
                    <CharactersLimit
                      text={reviewText}
                      minLength={REVIEW_LENGTH_MIN}
                      maxLength={maxReviewLength}
                    />
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
        </div>
        <div className="actions">
          <FlowSolidButtonGrey
            onClick={() => {
              moveBack();
            }}
          >
            Back
          </FlowSolidButtonGrey>
          <FlowSolidButton
            disabled={disabledNext}
            onClick={() => {
              moveNext();
            }}
          >
            Next
          </FlowSolidButton>
        </div>
      </ReviewQuestionContainer>
    </FlowContainer>
  );
};

export default ReviewInput;
