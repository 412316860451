import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../redux/hooks";

const useRetailerIdToNameMap = () => {
  const retailers = useAppSelector(({ retailers }) => retailers);
  const [retailerIdToNameMap, setRetailerIdToNameMap] = useState<
    Record<number, string>
  >({});
  const getRetailerIdToNameMap = useCallback(() => {
    const idToNameMap: Record<number, string> = {};
    const addToMap = (id: number, name: string) => {
      idToNameMap[id] = name;
    };
    retailers?.forEach((item) => {
      addToMap(item.retailerId, item.displayName);
      if (item.parentRetailerId) {
        addToMap(item.parentRetailerId, item.displayName);
      }
    });
    return idToNameMap;
  }, [retailers]);

  useEffect(() => {
    if (retailers?.length && retailers?.length > 0) {
      const newRetailerIdToNameMap = getRetailerIdToNameMap();
      setRetailerIdToNameMap(newRetailerIdToNameMap);
    }
  }, [retailers, getRetailerIdToNameMap]);

  return retailerIdToNameMap;
};

export default useRetailerIdToNameMap;
