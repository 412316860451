import { styled, useTheme } from "@mui/material";
import React from "react";
import { TABLET_SCREEN_SIZE } from "../../../../AppNavigation/constants";
import { useAppSelector } from "@/redux/hooks";
import { getCartCounts } from "@/redux/selectors";
import { scaleFont } from "@/utils/font";

const OuterContainer = styled("div")(({ theme }) => ({
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "53px",
  minWidth: "53px",
  height: "53px",
  minHeight: "53px",
  cursor: "pointer",
  borderRadius: theme.globalButtonRadius ?? 999,
  border: `1px solid`,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    width: "46px",
    minWidth: "46px",
    height: "46px",
    minHeight: "46px",
  },
}));

const InnerContainer = styled("div")(({ theme }) => ({
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "37px",
  height: "37px",
  fontSize: scaleFont(theme.baseFontSize, 20),
  borderRadius: theme.globalButtonRadius ?? 999,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    width: "32px",
    height: "32px",
    fontSize: scaleFont(theme.baseFontSize, 14),
  },
}));

const BagButton = ({ onClick }: { onClick: () => void }) => {
  const theme = useTheme();
  const cartCount = useAppSelector(getCartCounts);

  const bagButtonBorder = theme?.componentStyleOverrides?.cartButton?.enabled
    ? theme?.componentStyleOverrides?.cartButton?.tertiary
    : theme.palette.secondary.main;

  const bagButtoBgColor =
    cartCount > 0
      ? theme?.componentStyleOverrides?.cartButton?.enabled
        ? theme?.componentStyleOverrides?.cartButton?.secondary
        : theme.palette.primary.main
      : "#f6f8fa";

  const bagButtonTextColor =
    cartCount > 0
      ? theme?.componentStyleOverrides?.cartButton?.enabled
        ? theme?.componentStyleOverrides?.cartButton?.primary
        : "#ffffff"
      : theme.palette.primary.main;

  return (
    <OuterContainer
      className="bc-bag-button-outter"
      sx={{
        borderColor: bagButtonBorder,
      }}
      onClick={onClick}
    >
      <InnerContainer
        className="bc-bag-button-inner"
        sx={{
          color: bagButtonTextColor,
          backgroundColor: bagButtoBgColor,
        }}
      >
        {cartCount}
      </InnerContainer>
    </OuterContainer>
  );
};

export default BagButton;
