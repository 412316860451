import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { canSubmitSurveyResponse } from "../../../../../api/rest/authenticated/Survey";
import useWithLoading from "../../../../../utils/hooks/useWithLoading";

import SurveyLimitDialog from "../../../../StoreComponents/StoreDialogs/SurveyLimitDialog";
import { useUserLogin } from "../../../Auth/UserLoginProvider";

export const useSurveyCheck = () => {
  const navigate = useNavigate();
  const { signedIn } = useUserLogin();
  const [fetchCanAnswer, loading] = useWithLoading(canSubmitSurveyResponse);
  const [hasUserExceededLimit, setHasUserExceededLimit] = useState(false);

  const checkUserExceededLimit = useCallback(async () => {
    if (!signedIn) {
      return;
    }
    let redirected = false;
    const canSubmitSurveyResponseResult = await fetchCanAnswer();
    if (!canSubmitSurveyResponseResult) {
      setHasUserExceededLimit(true);
      SurveyLimitDialog.show(() => {
        // ensure we only navigate once since this callback may be called multiple times
        !redirected && navigate(-1);
        redirected = true;
      });
    } else {
      setHasUserExceededLimit(false);
    }
  }, [fetchCanAnswer, navigate, signedIn]);

  return {
    checkUserExceededLimit,
    loading,
    hasUserExceededLimit,
  };
};
