import React from "react";
import { getEntityImage } from "../../utils/StringUtils";

const StoreRetailerLogosOverlap = ({
  size = 20,
  retailerIds = [1, 2],
}: {
  size: number;
  retailerIds: number[];
}) => {
  return (
    <div
      style={{
        display: "flex",
        width: (size / 2) * (retailerIds.length + 1),
        // minWidth: (size / 2) * 4,
      }}
    >
      {retailerIds.map((retailerId, index) => {
        const shiftOffset = size / 2;
        return (
          <img
            key={retailerId}
            src={getEntityImage(retailerId, "retailer")}
            alt="retailer-logo"
            style={{
              width: size,
              aspectRatio: 1,
              borderRadius: 999,
              fontSize: 10,
              position: "relative",
              left: -shiftOffset * index,
              zIndex: index,
            }}
          />
        );
      })}
    </div>
  );
};

export default StoreRetailerLogosOverlap;
