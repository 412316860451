import React from 'react';
import { brandclub_colors } from '@brandclub/common-ui';
import { styled } from '@mui/material';
import { formatMoney } from '../../../../../utils/StringUtils';
import { useAppSelector } from '../../../../../redux/hooks';

const RewardCardContainer = styled('div')(({ theme }) => ({
  width: '100%',
  border: 'solid 1px rgba(222, 222, 222, 0.5)',
  boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  borderRadius: 8,
  backgroundColor: '#FFF',
  '.reward-card__placeholders': {
    backgroundColor: 'rgba(220, 221, 220, 0.3)',
    borderRadius: 2,
    position: 'relative',
    '&:first-of-type::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      right: -8,
      top: '50%',
      transform: 'translateY(-50%)',
      borderRadius: '100%',
      height: 4,
      width: 4,
      backgroundColor: '#a21671',
    }
  },
  ".reward-card__bottom": {
    borderRadius: '0 0 8px 8px',
  },
}));

export const RewardCard = ({ title, subtitle, rewardAmount, image, imageStyle } :
  { title: string, subtitle?: string, rewardAmount: number, image?: string
    imageStyle?: React.CSSProperties
  }) => {

  const { brandApp } = useAppSelector((state) => state);

  const { imageUrls, title: brandTitle} = brandApp?.topProduct || {};

  return (
    <RewardCardContainer className="reward-card"
     >
      <div className="reward-card__top"
        style={{
          padding: '13px 0px 15.6px 10px',
          display: 'flex',
          position: 'relative',
        }}
      >
        <img src={image || (imageUrls && imageUrls[0]) || "https://via.placeholder.com/150"} alt="reward"
          style={{
            width: 43,
            height: 43,
            display: 'inline-block',
            marginRight: 10,
            objectFit: 'contain',
            objectPosition: 'center',
            ...imageStyle,
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            flex: 1,
          }}
        >
          <h4
            style={{
              margin: 'unset',
              fontSize: 12,
              fontWeight: 600,
              marginBottom: 5,
            }}
          >{title}</h4>
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              flexShrink: 1,
              fontSize: 10,
              fontWeight: 500,
              marginRight: 58,
            }}
          >{subtitle || brandTitle}</div>
          <div className="reward-card__amount-card"
            style={{
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              width: 48,
              height: 48,
              backgroundColor: '#FFF',
              top: 14,
              right: '-11px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 6,
              border: 'solid 1px rgba(222, 222, 222, 0.5)',
              boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
            }}
          >
            <span
              style={{
                fontSize: 8,
                fontWeight: 600,
                marginBottom: 1.5,
              }}
              >Amount

            </span>
            <span
              style={{
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              {formatMoney(rewardAmount)}
            </span>
          </div>
        </div>
      </div>
      <div className="reward-card__bottom"
        style={{
          backgroundColor: brandclub_colors.blueWhite,
          padding: '9.4px 12px 8px 10px',
        }}
      >
        <div className="reward-card__placeholders"
          style={{
            width: 75,
            height: 12,
            marginBottom: 7,
          }}
        />


        <div className="reward-card__placeholders"
          style={{
            width: 199,
            height: 9.5,
            marginBottom: 2.6,
          }}
        />

        <div className="reward-card__placeholders"
          style={{
            width: 138,
            height: 9.5,
          }}
        />
      </div>
    </RewardCardContainer>
  )
}
