import { Box, styled } from "@mui/material";
import {
  TABLET_SCREEN_SIZE,
  MOBILE_SCREEN_SIZE,
} from "../../AppNavigation/constants";

const InputContainerContainer = styled(Box)(({ theme }) => ({
  marginTop: "30px",
  display: "flex",
  gap: 16,
  flexDirection: "row",
  justifyContent: "space-between",
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    flexDirection: "row",
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    flexDirection: "column",
  },
}));
export const InputContainer = (props: React.ComponentProps<typeof Box>) => (
  <InputContainerContainer
    mt={"30px"}
    display="flex"
    flexDirection="row"
    justifyContent="space-between"
    {...props}
  />
);
