import _get from "lodash/get";

import { useAppSelector } from "../../../../../redux/hooks";
import { formatMoney } from "../../../../../utils/StringUtils";
import { InfoButton } from "./components/InfoButton";
import {
  CashoutContainer,
  handleRewardBalanceClick,
  handleTotalAdjustmentsClick,
  handleTotalPayoutClick,
  handleTotalRewardsClick,
} from "./Balance";
import { useSiteContext } from "../../../../SiteProvider";
import reduxApolloClient from "../../../../../redux/reduxApolloClient";
import { getRewardAmountsByStatus } from "../../../../../api";
import ConfigUtils from "../../../../../utils/ConfigUtils";
import { useEffect, useState } from "react";
import { MOBILE_SCREEN_SIZE } from "../../../../AppNavigation/constants";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DashboardPreviewHeader } from "../DashboardPreviewHeader";
import { SiteBrandingType, StoreBrandingType } from "../../../../../types/misc";
import useStoreType from "../../../../../utils/hooks/useStoreType";

const loadBrandRewards = async (brandIds: number[]) => {
  const variables = { brandIds, forceRefresh: true };
  const { data } = await reduxApolloClient.query({
    query: getRewardAmountsByStatus,
    variables,
    context: {
      uri: `${ConfigUtils.getGraphQlUri()}?id=getRewardAmountsByStatus`,
    },
  });
  if (data && data.RewardSummary) {
    return data.RewardSummary;
  }
};

const BalanceSummary = () => {
  const storeType = useStoreType();
  const isMobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);
  const { siteBrandingType } = useSiteContext();
  const mainEntity = useAppSelector(({ mainEntity }) => mainEntity);
  const rewards = useAppSelector(({ rewards }) => rewards);
  const [rewardSummary, setRewardSummary] = useState<{
    lifetimeEarnings: number;
    lifetimePayouts: number;
    lifetimeAdjustments: number;
    lockedBalance: number;
    pendingBalance: number;
    unlockedBalance: number;
    cancelledBalance: number;
    hasLoaded: boolean;
  }>({
    lifetimeEarnings: 0,
    lifetimePayouts: 0,
    lifetimeAdjustments: 0,
    lockedBalance: 0,
    pendingBalance: 0,
    unlockedBalance: 0,
    cancelledBalance: 0,
    hasLoaded: false,
  });

  useEffect(() => {
    (async () => {
      if (
        mainEntity?.id &&
        (siteBrandingType === SiteBrandingType.CustomStore ||
          siteBrandingType === SiteBrandingType.BrandStore)
      ) {
        const brandRewardSummary = await loadBrandRewards([mainEntity.id]);
        setRewardSummary({ ...brandRewardSummary, hasLoaded: true });
      } else {
        const lifetimeEarnings = _get(
          rewards,
          ["rewards", "lifetimeEarnings"],
          0
        );
        const lifetimePayouts = _get(
          rewards,
          ["rewards", "lifetimePayouts"],
          0
        );
        const lifetimeAdjustments = _get(
          rewards,
          ["rewards", "lifetimeAdjustments"],
          0
        );

        const lockedBalance = _get(rewards, ["rewards", "lockedBalance"], 0);
        const pendingBalance = _get(rewards, ["rewards", "pendingBalance"], 0);
        const unlockedBalance = _get(
          rewards,
          ["rewards", "unlockedBalance"],
          0
        );
        const cancelledBalance = _get(
          rewards,
          ["rewards", "cancelledBalance"],
          0
        );
        setRewardSummary({
          lifetimeEarnings,
          lifetimePayouts,
          lifetimeAdjustments,
          lockedBalance,
          pendingBalance,
          unlockedBalance,
          cancelledBalance,
          hasLoaded: true,
        });
      }
    })();
  }, [siteBrandingType, mainEntity, rewards]);

  const { lifetimeEarnings, lifetimePayouts, lifetimeAdjustments } =
    rewardSummary;

  const rewardBalance =
    lifetimeEarnings - lifetimePayouts - lifetimeAdjustments;
  const linkPrefix =
    storeType === StoreBrandingType.CustomDTCStore ? "" : "/dashboard";

  if (!rewardSummary.hasLoaded) return null;

  return (
    <div>
      <DashboardPreviewHeader
        title={"Your rewards"}
        linkTo={`${linkPrefix}/rewards/balance`}
        subtitle={isMobileView ? undefined : "View your reward summary"}
        indicator="grey"
        marginBottom={isMobileView ? 6 : undefined}
      />
      <CashoutContainer
        sx={{
          ".balance-section": {
            [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
              border: "none",
              padding: 0,
            },
            ".row": {
              [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
                height: "auto",
                padding: "10px 0",
                "&:last-of-type": {
                  paddingBottom: 0,
                },
              },
              ".value": {
                [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
                  gap: 1,
                },
              },
            },
          },
        }}
      >
        <div className="balance-section">
          <div className="row">
            <div className="label">Total rewards</div>
            <div className="value">
              {formatMoney(lifetimeEarnings)}{" "}
              <div role="button" onClick={handleTotalRewardsClick}>
                <InfoButton />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="label">Total payouts</div>
            <div className="value">
              {formatMoney(lifetimePayouts)}{" "}
              <div role="button" onClick={handleTotalPayoutClick}>
                <InfoButton />
              </div>
            </div>
          </div>
          {lifetimeAdjustments ? (
            <div className="row">
              <div className="label">Total adjustments</div>
              <div className="value">
                {formatMoney(lifetimeAdjustments)}{" "}
                <div role="button" onClick={handleTotalAdjustmentsClick}>
                  <InfoButton />
                </div>
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="label">Reward balance</div>
            <div className="value">
              {formatMoney(rewardBalance)}{" "}
              <div role="button" onClick={handleRewardBalanceClick}>
                <InfoButton />
              </div>
            </div>
          </div>
        </div>
      </CashoutContainer>
    </div>
  );
};

export default BalanceSummary;
