import { brandclub_colors } from "@brandclub/common-ui";
import Radio, { RadioProps } from "@mui/material/Radio";
import { styled } from "@mui/material/styles";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../AppNavigation/constants";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 20,
  height: 20,

  backgroundColor: "#fff",
  border: `1px solid ${theme.palette.primary.main}`,

  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    width: 15,
    height: 15,
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    width: 15,
    height: 15,
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  backgroundColor: brandclub_colors.white,
  border: `1px solid ${theme.palette.primary.main}`,
  "&::before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage: `radial-gradient(${theme.palette.primary.main},${theme.palette.primary.main} 48%,transparent 50%)`,
    content: '""',
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      width: 15,
      height: 15,
    },
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      width: 15,
      height: 15,
    },
  },
  "input:hover ~ &": {},
}));

// Inspired by blueprintjs
const StoreRadio = (props: RadioProps) => {
  return (
    <Radio
      sx={{ padding: 0 }}
      disableRipple
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
};

export default StoreRadio;
