import { useMemo } from "react";
import { RetailerWithSyncStatus } from "../../../../../redux/types";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";

import { useResponsiveData } from "../../../../../utils/hooks/useResponsiveData";
import { useFetchAccount } from "../../Pages/Accounts/data";
import { CardGridForFour } from "../../Pages/components/CardGrid";
import RetailerCard from "../../Pages/Accounts/components/RetailerCard";
import { DashboardPreviewHeader } from "../DashboardPreviewHeader";
import useStoreType from "../../../../../utils/hooks/useStoreType";
import { StoreBrandingType } from "../../../../../types/misc";

const MAX_RETAILERS = 4;

const AccountSummary = () => {
  const storeType = useStoreType();
  const { loading, connectedRetailers, disconnectedRetailers } =
    useFetchAccount({});

  const retailers = useMemo(
    () => [...connectedRetailers, ...disconnectedRetailers],
    [connectedRetailers, disconnectedRetailers]
  );

  const homeRetailer = useMemo(() => {
    return retailers.slice(0, MAX_RETAILERS);
  }, [retailers]);

  const responsiveHomeRetailer = useResponsiveData(homeRetailer);

  if (!loading && homeRetailer.length === 0) {
    return null;
  }

  const linkPrefix =
    storeType === StoreBrandingType.CustomDTCStore ? "" : "/dashboard";
  return (
    <div>
      {loading ? (
        <StoreLoadingSkeleton header row={2} />
      ) : (
        <>
          <DashboardPreviewHeader
            title={"Your accounts"}
            linkTo={`${linkPrefix}/accounts`}
            subtitle="View the status of connected accounts"
            indicator="grey"
          />
          <CardGridForFour style={{ marginTop: 20 }}>
            {responsiveHomeRetailer.map((r) => {
              return (
                <RetailerCard
                  key={r.retailerId}
                  retailer={r as RetailerWithSyncStatus}
                />
              );
            })}
          </CardGridForFour>
        </>
      )}
    </div>
  );
};

export default AccountSummary;
