import React from "react";
import { FlowSolidButton } from "../../components/FlowButtons";
import { motion } from "framer-motion";
import { MINIMUM_ANSWERING_TIME } from "../flow";
import { useTheme } from "@mui/material";

const SurveyTimingNextButton = ({
  onClick,
  disabled,
  answerTime,
  text,
}: {
  onClick: () => void;
  answerTime: number;
  disabled?: boolean;
  text?: string | React.ReactNode;
}) => {
  const theme = useTheme();
  return (
    <FlowSolidButton
      style={{
        // minWidth: 140,
        position: "relative",
        overflow: "hidden",
        border: "none",
        background: "#9a9dbe",
        cursor: disabled ? "not-allowed" : "pointer",
      }}
      // disabled={!finishWatching}
      onClick={disabled ? undefined : onClick}
    >
      <motion.div
        initial={{ width: `${(answerTime / MINIMUM_ANSWERING_TIME) * 100}%` }}
        animate={{ width: "100%" }}
        transition={{
          duration: MINIMUM_ANSWERING_TIME - answerTime,
          ease: "linear",
        }}
        className="bg"
        style={{
          left: 0,
          position: "absolute",
          height: "100%",
          background:
            MINIMUM_ANSWERING_TIME > answerTime
              ? theme.palette.primary.main
              : disabled
              ? "transparent"
              : theme.palette.primary.main,
          borderRadius: 15,
        }}
      ></motion.div>
      <div style={{ zIndex: 1, display: "flex" }}>{text}</div>
    </FlowSolidButton>
  );
};

export default SurveyTimingNextButton;
