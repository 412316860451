import { FC } from "react";
import styled from "@emotion/styled";
import Box, { BoxProps } from "@mui/material/Box";
import {
  TABLET_SCREEN_SIZE,
  MOBILE_SCREEN_SIZE,
} from "../../AppNavigation/constants";
import { Button } from "@brandclub/common-ui";
import { AuthHelper } from "../../pages/Auth/AuthHelper";

const LoginOutlineButton = styled(Button)({
  borderWidth: "1px",
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    width: "100%",
  },
});

interface StoreThirdPartyLoginProps extends BoxProps {
  redirectPathname?: string;
  redirectSearch?: string;
}
export const StoreThirdPartyLogin: FC<StoreThirdPartyLoginProps> = ({
  redirectPathname,
  redirectSearch,
  width = 353,
  ...props
}) => {
  const url = new URL(window.location.href);
  if (redirectPathname) {
    url.pathname = redirectPathname;
  }
  if (redirectSearch) {
    const searchParams = new URLSearchParams(redirectSearch);
    searchParams.forEach((value, key) => {
      url.searchParams.set(key, value);
    });
  }
  const redirectUrl = url.toString();

  const linkProvider = async (provider: string) => {
    await AuthHelper.linkProvider({
      provider,
      redirectUrl: redirectUrl,
    });
  };

  const signInWithGoogle = async () => {
    return linkProvider("Google");
  };

  const signInWithApple = async () => {
    return linkProvider("SignInWithApple");
  };

  return (
    <Box display="flex" flexDirection="column" gap="20px" {...props}>
      <LoginOutlineButton
        type="button"
        variant="outline"
        sx={{
          color: "#4285f4",
          borderColor: "#4285f4",
          width,
        }}
        onClick={signInWithGoogle}
      >
        <img
          alt="google"
          src={"https://media.brandclub.com/brandclub/icons/signin_google.webp"}
          className="button_img"
        />
        Sign up with Google
      </LoginOutlineButton>
      <LoginOutlineButton
        type="button"
        variant="outline"
        sx={{ color: "#242526", borderColor: "#242526", width }}
        onClick={signInWithApple}
      >
        <img
          alt="apple"
          src={"https://media.brandclub.com/brandclub/icons/signin_apple.webp"}
          className="button_img"
        />
        Sign up with Apple
      </LoginOutlineButton>
    </Box>
  );
};
