import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../../../../Loading";
import Cover from "./cover";
import Finish from "./finish";
import Media from "./media";
import { submitContentForReward } from "../../../../../../api/rest/authenticated/Content";
import { useAppDispatch } from "../../../../../../redux/hooks";
import { setBrandEntity } from "../../../../../../redux/reducers/brandEntity";
import ScrollToTop from "../../../../../../utils/routing/ScrollToTop";

const ContentFlow = () => {
  const [step, setStep] = useState<"cover" | "media" | "finish">("cover");
  const [loading, setLoading] = useState(false);
  const [receivedReward, setReceivedReward] = useState(0);
  const location = useLocation();
  const content = location?.state?.content;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (content?.brandclub) {
      dispatch(setBrandEntity({ entity: content.brandclub, loading: false }));
    } else {
    }
  }, [content, dispatch]);

  useEffect(() => {
    if (!content) {
      // redirect to homepage
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!content) {
    return <Loading dot></Loading>;
  }

  const handleSubmit = async () => {
    const campaignId = content?.campaignId;
    const contentId = content?.extendedAttributes.contentId;
    try {
      setLoading(true);
      const res = await submitContentForReward({ campaignId, contentId });
      if (res.data) {
        if (res.data.reward?.rewardAmount) {
          setReceivedReward(res.data.reward.rewardAmount);
        }
        setStep("finish");
      }
    } catch (e) {
      // Go to error state here
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <ScrollToTop key={step} />
      {step === "cover" && (
        <Cover
          content={content}
          moveNext={() => {
            setStep("media");
            // setStep("finish");
          }}
        />
      )}
      {step === "media" && (
        <Media
          submitting={loading}
          content={content}
          moveBack={() => {
            setStep("cover");
          }}
          moveNext={() => {
            handleSubmit();
          }}
        />
      )}
      {step === "finish" && (
        <Finish content={content} receivedReward={receivedReward} />
      )}
    </>
  );
};

export default ContentFlow;
