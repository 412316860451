import { brandclub_colors } from "@brandclub/common-ui";
import { styled, TextField, TextFieldProps } from "@mui/material";
import { CSSProperties, useState } from "react";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../AppNavigation/constants";
import { StoreError, StoreErrorProps } from "./StoreError";

const InputField = styled("div")(({ theme }) => ({
  // marginTop: 30,
  position: "relative",
  marginBottom: 0,
  ".label": {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginBottom: 12,
  },

  ".errorMsg": {
    color: "#E57373",
    fontSize: 12,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  maxWidth: 353,
  width: "100%",

  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    maxWidth: "unset",
  },
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    maxWidth: "unset",
  },
  ".MuiInputLabel-root": {
    fontSize: 16,
    color: theme.palette.primary.main,
    opacity: 0.3,
    fontWeight: 500,
    zIndex: 1,
  },
  ".MuiFilledInput-root": {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    boxSizing: "border-box",
    fontFamily: "Poppins",
    border: "1.5px solid #dcdddc",
    outline: "none",
    borderRadius: "6px",
    fontSize: 14,
    fontWeight: 500,

    color: theme.palette.primary.main,
    "& .Mui-disabled": {
      color: brandclub_colors.darkBlue3,
      textFillColor: brandclub_colors.darkBlue3,
      webkitTextFillColor: brandclub_colors.darkBlue3,
    },
    "& fieldset": {
      borderColor: "transparent !important",
    },
    "&.Mui-focused": {
      backgroundColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
  },
}));
const StoreInput = ({
  style = {},
  label = "",
  inputStyle = {},
  errorMsg = "",
  errorProps,
  textFieldStyle = {},
  placeholder,
  value,
  ...inputProps
}: TextFieldProps & {
  style?: CSSProperties;
  label?: string;
  errorMsg?: string;
  errorProps?: StoreErrorProps;
  inputStyle?: CSSProperties;
  textFieldStyle?: CSSProperties;
}) => {
  /**
   * Material UI TextField with a custom react-number-format component has a bug where the input field does not focus properly on autoFocus.
   * To fix this, we force the focus state of the input field to be true when autoFocus is true. and reset it to be managed by the TextField component onBlur.
   */
  const [focused, setFocused] = useState<boolean | undefined>(
    inputProps.autoFocus
  );

  return (
    <InputField style={{ width: "100%", ...style }}>
      <StyledTextField
        value={value ?? ""} // fixes the MUI error: "changing the controlled value state to be uncontrolled"
        focused={focused}
        {...inputProps}
        onBlur={(...args) => {
          // reset the focus state to be managed by the TextField component since this was only used as a workaround for the autoFocus bug
          setFocused(undefined);
          inputProps.onBlur?.(...args);
        }}
        InputProps={{
          style: {
            ...inputStyle,
          },
          ...inputProps.InputProps,
          disableUnderline: true,
        }}
        style={textFieldStyle}
        className="store-input"
        variant="filled"
        label={label || placeholder}
      />
      <StoreError
        sx={{
          marginTop: "10px",
          marginBottom: "0px",
        }}
        errorMessage={errorMsg}
        {...errorProps}
      />
    </InputField>
  );
};

export default StoreInput;
