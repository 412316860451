/* eslint-disable @typescript-eslint/no-explicit-any */
import Branch from 'branch-sdk';

export const ua = `${window.navigator.userAgent || navigator.vendor || (window as any).opera || ''}`.toLowerCase();

const branchSessionDataKey = `branch_data-${Date.now()}`;

export const BranchSessionData = (branchIOConfig: { branchKey: string }) =>
  new Promise<Branch.SessionData | null>((resolve, reject) => {
    if ((window as any)[branchSessionDataKey]) {
      resolve((window as any)[branchSessionDataKey]);
      return;
    }

    const branchKey = branchIOConfig.branchKey;
    if (!branchKey || branchKey === '') {
      console.warn(`Couldn't determine branchIO api key. Not initializing branch.io`);
      return;
    }
    Branch.init(branchKey, { retries: 3, retry_delay: 20 }, (err, data) => {
      if (err) {
        reject(err);
      } else {
        (window as any)[branchSessionDataKey] = data;
        resolve(data);
      }
    });
  });

export const isInstagramBrowser = (): boolean => {
  return ua.indexOf('instagram') >= 0;
};

export const isFaceBookBrowser = (): boolean => {
  return ua.indexOf('fban') >= 0 || ua.indexOf('fbav') >= 0;
};

export const isInstagramOrFBBrowser = () => {
  return isInstagramBrowser() || isFaceBookBrowser() ? true : false;
};

export const setupDeepView = (trackingData: Record<string, any>) => {
  return new Promise<void>((resolve, reject) =>
    Branch.deepview(
      {
        data: trackingData,
      },
      {},
      (e) => {
        if (e) {
          reject(e);
        } else {
          resolve();
        }
      },
    ),
  );
};

export const runDeepViewCTA = () => {
  Branch.deepviewCta();
};

// export const isBranchInitialized = async (branchIOConfig: { branchKey: string }) => {
// export const initialize = (branchIOConfig: { branchKey: string }) => isBranchInitialized(branchIOConfig);
export default Branch;
