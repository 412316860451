import { useCallback, useEffect, useState } from "react";

import { ProductRewardSearchResult } from "@brandclub/common-ui";
import _get from "lodash/get";
import _uniqBy from "lodash/uniqBy";
import { getBestRewardProducts } from "../../../../../api/graphQl/authenticated/BestRewards";
import {
  getInstantRewardsV2,
  getUnauthedInstantRewardProductsV2,
} from "../../../../../api/graphQl/authenticated/InstantRewards";
import {
  getMostPopularProducts,
  getUnauthedMostPopular,
} from "../../../../../api/graphQl/authenticated/MostPopular";
import reduxApolloClient from "../../../../../redux/reduxApolloClient";
import ConfigUtils from "../../../../../utils/ConfigUtils";
import { useUserLogin } from "../../../Auth/UserLoginProvider";

const requestMapping = {
  instant: {
    query: getInstantRewardsV2,
    resultKey: "InstantRewardProductsV2",
  },
  unauthedInstant: {
    query: getUnauthedInstantRewardProductsV2,
    resultKey: "UnauthedInstantRewardProductsV2",
  },
  best: {
    query: getBestRewardProducts,
    resultKey: "BestRewards",
  },
  popular: {
    query: getMostPopularProducts,
    resultKey: "MostPopular",
  },
  unauthedpopular: {
    query: getUnauthedMostPopular,
    resultKey: "UnauthedMostPopular",
  },
};

const getRequestMappingKey = (
  type: "instant" | "best" | "popular",
  signedIn: boolean
): keyof typeof requestMapping => {
  switch (type) {
    case "instant":
      return signedIn ? "instant" : "unauthedInstant";
    case "best":
      return "best";
    case "popular":
      return signedIn ? "popular" : "unauthedpopular";
    default:
      throw new Error("Invalid type");
  }
};

export const useFetchDepartmentProduct = ({
  type,
  departmentIds,
  pageSize = 25,
}: {
  type: "instant" | "best" | "popular";
  departmentIds?: number[];
  pageSize?: number;
}) => {
  const [data, setData] = useState<ProductRewardSearchResult[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);
  const { signedIn } = useUserLogin();
  const requestMappingKey = getRequestMappingKey(type, signedIn);
  const fetchData = useCallback(
    async (pageNumber = 0) => {
      try {
        setError(null);

        const res = await reduxApolloClient.query({
          query: requestMapping[requestMappingKey].query,

          variables: {
            departmentIds,
            page: {
              start: pageNumber,
              size: pageSize,
            },
          },
          fetchPolicy: "network-only",
          context: {
            uri: `${ConfigUtils.getGraphQlUri()}?id=${
              requestMapping[requestMappingKey].resultKey
            }`,
          },
        });
        const resKey = requestMapping[requestMappingKey].resultKey;
        const newData = _get(res, ["data", resKey, "items"], []);
        const newHasMore = _get(res, ["data", resKey, "hasMore"], false);
        setData((d) => _uniqBy([...d, ...newData], "stacklineSku"));
        setHasMore(newHasMore);
      } catch (e: any) {
        setError(e);
      }
    },
    [requestMappingKey, departmentIds, pageSize]
  );

  const fetchInit = useCallback(async () => {
    try {
      setLoading(true);
      await fetchData(0);
    } finally {
      setPage(0);
      setLoading(false);
    }
  }, [fetchData]);

  const fetchMore = useCallback(async () => {
    try {
      setLoadingMore(true);
      await fetchData((page + 1) * pageSize);
    } finally {
      setPage((p) => p + 1);
      setLoadingMore(false);
    }
  }, [fetchData, page, pageSize]);

  useEffect(() => {
    fetchInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, loading, error, hasMore, fetchMore, loadingMore };
};
