import { useTreatments } from "@splitsoftware/splitio-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  publishReview,
  saveReviewAttempt,
  suggestProductFeaturesToReviewAsStream,
} from "../../../../../../api/rest/authenticated/Review";
import Loading from "../../../../../Loading";
import Cons from "./cons";
import Cover from "./cover";
import Finish from "./finish";
import ReviewInput from "./input";
import LowRatingInput from "./lowRatingInput";
import Pros from "./pros";
import Summary from "./summary";
import { setBrandEntity } from "../../../../../../redux/reducers/brandEntity";
import { useAppDispatch } from "../../../../../../redux/hooks";
import ScrollToTop from "../../../../../../utils/routing/ScrollToTop";
import { useTrackActions } from "../../../../../../utils/hooks/useTracking";
import { AnalyticsTrackingEvent } from "@brandclub/common-ui";

export type QuestionType =
  | "features_you_like"
  | "features_for_improvement"
  | "free_form";
export type Question = {
  question: string;
  options?: string[];
  questionType: QuestionType;
};

const getFormattedSurvey = (
  prosAns: string[],
  consAns: string[],
  feedBack: string,
  startRating: number
) => {
  const res = [];
  if (prosAns?.length) {
    res.push({
      question: "What did you like most about this product?",
      selectedOptions: prosAns,
    });
  }
  if (consAns?.length) {
    res.push({
      question: "What could be improved?",
      selectedOptions: consAns,
    });
  }
  if (feedBack?.length) {
    res.push({
      question: "Additional Comments",
      selectedOptions: [feedBack],
    });
  }
  return res;
};

const ReviewFlow = () => {
  const [trackAction] = useTrackActions();
  const [step, setStep] =
    useState<
      | "cover"
      | "pros"
      | "cons"
      | "input"
      | "lowStarInput"
      | "summary"
      | "finish"
    >("cover");
  const [starRating, setStartRating] = useState<number>(0);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [, setSurveyAns] = useState<{
    [index: number]: number[] | string;
  }>({});
  const [prosOptions, setProsOptions] = useState<string[]>([]);
  const [consOptions, setConsOptions] = useState<string[]>([]);
  const [selectedProsOptions, setSelectedProsOptions] = useState<string[]>([]);
  const [selectedConsOptions, setSelectedConsOptions] = useState<string[]>([]);
  const [feedback, setFeedback] = useState<string>("");
  const [reviewText, setReviewText] = useState<string>("");
  const [reviewTitle, setReviewTitle] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const reviewGPTTreatment = useTreatments(["brandclub_product_review_gpt"])[
    "brandclub_product_review_gpt"
  ].treatment;
  const dispatch = useAppDispatch();
  const location = useLocation();
  const product = location?.state?.product;
  const navigate = useNavigate();

  useEffect(() => {
    if (!product) {
      // redirect to homepage
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (product) {
      dispatch(
        setBrandEntity({
          entity: { brandId: product.brandId, name: product.brandName },
          loading: false,
        })
      );
    }
  }, [product, dispatch]);

  const onSurveyLoadError = (e: any) => {
    console.error(e);
  };

  const onSurveyInProgress = (
    qs: {
      question: string;
      questionType: "features_you_like" | "features_for_improvement";
      options: string[];
    }[]
  ) => {
    setQuestions(() => [...qs]);
  };

  const onSurveyLoadDone = () => {
    setQuestions((qs) => [
      ...qs,
      {
        question:
          "Please provide any additional comments or feedback about the product.",
        questionType: "free_form",
      },
    ]);
  };

  const fetchReviewSurvey = async () => {
    try {
      setSurveyAns({});
      setQuestions([]);
      await suggestProductFeaturesToReviewAsStream(
        product.retailerId,
        product.retailerSku,
        onSurveyLoadError,
        onSurveyInProgress,
        onSurveyLoadDone
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // fine pros and cons options from questions
    if (questions.length) {
      const pros = questions.find(
        (q) => q.questionType === "features_you_like"
      );
      const cons = questions.find(
        (q) => q.questionType === "features_for_improvement"
      );
      setProsOptions(() => pros?.options || []);
      setConsOptions(() => cons?.options || []);
    }
  }, [questions]);

  useEffect(() => {
    fetchReviewSurvey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveReview = async () => {
    try {
      setSending(true);
      await saveReviewAttempt({
        purchaseRetailerId: product.retailerId,
        purchaseRetailerSku: product.retailerSku,
        reviewStarRating: starRating,
        reviewTitle: reviewTitle,
        reviewText: reviewText,
        extendedAttributes: {
          publishedStateByRetailer: {
            [product.retailerId]: {
              status: "padding",
            },
          },
        },
      });
    } catch (error) {
      console.error("save error", error);
    } finally {
      setSending(false);
    }
  };

  const submitReview = async () => {
    try {
      setSubmitting(true);
      await saveReviewAttempt({
        purchaseRetailerId: product.retailerId,
        purchaseRetailerSku: product.retailerSku,
        reviewStarRating: starRating,
        reviewTitle: reviewTitle,
        reviewText: reviewText,
        extendedAttributes: {
          publishedStateByRetailer: {
            [product.retailerId]: {
              status: "padding",
            },
          },
        },
      });

      await publishReview({
        reviewInfo: {
          reviewStarRating: starRating,
          reviewText,
          reviewTitle,
          product: {
            brandId: product.brandId,
            retailerId: product.retailerId,
            retailerSku: product.retailerSku,
            stacklineSku: product.stacklineSku,
          },
        },
        retailerId: product.retailerId,
        productId: product.retailerSku,
        action: "publishReview",
        parentRetailerSku: "string",
      });
    } catch (error) {
      console.error("publish error", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmit = async (e: "SUBMIT" | "SEND_TO_BRAND") => {
    trackAction(AnalyticsTrackingEvent.SAVE_REVIEW, {
      product,
      reviewTitle,
      reviewText,
      starRating,
    });
    if (e === "SEND_TO_BRAND") {
      await saveReview();
    } else if (e === "SUBMIT") {
      await submitReview();
    }
    trackAction(AnalyticsTrackingEvent.SAVE_REVIEW_SUCCESS, {
      product,
      reviewTitle,
      reviewText,
      starRating,
    });

    setStep("finish");
  };

  if (!product) {
    return <Loading dot></Loading>;
  }

  return (
    <>
      <ScrollToTop key={step} />
      {step === "cover" && (
        <Cover
          starRating={starRating}
          setStarRating={setStartRating}
          reviewProduct={product}
          moveNext={() => {
            const nextStep =
              reviewGPTTreatment === "C" || starRating < 3
                ? "lowStarInput"
                : "pros";
            setReviewText("");
            setReviewTitle("");
            setStep(nextStep);
          }}
        />
      )}
      {step === "pros" && (
        <Pros
          product={product}
          setSelectedOptions={setSelectedProsOptions}
          options={prosOptions}
          selectedOptions={selectedProsOptions}
          feedback={feedback}
          setFeedback={setFeedback}
          moveBack={() => {
            setStep("cover");
          }}
          moveNext={() => {
            // We should not show the cons page if the user has given 5 stars
            // any non-positive input can be included via the text input
            if (starRating === 5) {
              setStep("input");
            } else {
              setStep("cons");
            }
          }}
        />
      )}
      {step === "cons" && (
        <Cons
          product={product}
          setSelectedOptions={setSelectedConsOptions}
          options={consOptions}
          selectedOptions={selectedConsOptions}
          moveBack={() => {
            setStep("pros");
          }}
          moveNext={() => {
            setStep("input");
          }}
        />
      )}
      {step === "input" && (
        <ReviewInput
          product={product}
          formattedSurvey={getFormattedSurvey(
            selectedProsOptions,
            selectedConsOptions,
            feedback,
            starRating
          )}
          reviewText={reviewText}
          setReviewText={setReviewText}
          reviewTitle={reviewTitle}
          setReviewTitle={setReviewTitle}
          starRating={starRating}
          moveBack={() => {
            setStep("cons");
          }}
          moveNext={() => {
            setStep("summary");
          }}
        />
      )}

      {step === "lowStarInput" && (
        <LowRatingInput
          product={product}
          reviewText={reviewText}
          setReviewText={setReviewText}
          reviewTitle={reviewTitle}
          setReviewTitle={setReviewTitle}
          moveBack={() => {
            setStep("cover");
          }}
          moveNext={() => {
            setStep("summary");
          }}
        />
      )}
      {step === "summary" && (
        <Summary
          product={product}
          title={reviewTitle}
          reviewText={reviewText}
          starRating={starRating}
          sending={sending}
          submitting={submitting}
          moveBack={() => {
            setStep("input");
          }}
          moveNext={(e) => {
            handleSubmit(e);
          }}
        />
      )}
      {step === "finish" && <Finish product={product} />}
    </>
  );
};

export default ReviewFlow;
