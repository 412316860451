import { gql } from "@apollo/client";

export const getRetailersAuth = gql`
  query getRetailers($enableExperimentalRetailers: Boolean) {
    Retailers(enableExperimentalRetailers: $enableExperimentalRetailers) {
      retailerId
      name
      color
      displayName
      type
      currencySymbol
      locale
      logo
      baseOrderUrl
      isBrandClubReady
      isSocialPlatform
      retailerConnectRewardAmount
      existingRetailerConnectReward
      retailerType
      parentRetailerId
      parentDisplayName
      sortScore
      tosUrl
      syncConfig
      retailerLoginUsernameFieldTypes
    }
  }
`;
