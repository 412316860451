import GenericDialog, {
  DialogButtonAction,
} from "@/components/StoreComponents/StoreDialogs/GenericDialog";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../AppNavigation/constants";
import { useNavigate } from "react-router-dom";

export const showBagFullDialog = (
  navigate: ReturnType<typeof useNavigate>,
  currentRoute: string,
  listener?: (newState: Readonly<any>) => void
) => {
  const actions: DialogButtonAction[] = [
    {
      text: "Close",
      variant: "outline",
    },
  ];
  const isMyBagRoute = currentRoute === "/mybag";

  if (!isMyBagRoute) {
    actions.push({
      text: "View my bag",
      onClick: () => {
        navigate("/mybag");
      },
      variant: "solid",
    });
  }

  GenericDialog.show({
  title: "Your bag is full",
  text: "You’ve reached the maximum number of items you can have in your bag for this retailer.",
  textProps: {
    sx: {
      marginBottom: "30px",
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
        marginTop: "10px",
      },
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: 12,
      },
    },
  },
  actionsProps: {
    sx: {
      "&.actions": {
        [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
          flexDirection: "column-reverse",
          button: {
            height: 44,
            fontSize: 14,
            maxWidth: 355,
          },
        },
      },
    },
  },
  actions,
  listener,
});
};
