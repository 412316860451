import { useMatch } from "react-router-dom";

export const useLinkPrefix = () => {
  const match = useMatch({
    path: "/:page/b/:brandclubId",
    end: false,
    caseSensitive: false,
  });

  const linkPrefix = match?.pathname || "";
  return {
    linkPrefix,
  };
};
