import { TrackingParams } from "@brandclub/common-ui";
import axios from "../../../utils/axios";

export const joinWaitList = async (req: {
  phoneNumber?: string;
  email?: string;
  name?: string;
}) => {
  const res = await axios.post("/waitingList/signUp", {
    ...req,
  });
  return res;
};

export const sendTextInvite = async (sendTextInviteRequest: {
  id: string;
  forceSendInvite: boolean;
  queryParams?: TrackingParams;
}) => {
  const res = await axios.post(
    "/waitingList/sendTextInvite",
    sendTextInviteRequest
  );
  return res?.data;
};

export const submitContactForm = async (req: { type: string; form: any }) => {
  const res = await axios.post("/customerSupport/contactUs", req);
  return res?.data;
};
