import { BrandClub } from "@brandclub/types";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import { escapeUriPath, formatMoney } from "../../../../../utils/StringUtils";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../AppNavigation/constants";
import { ArrowRightIcon, CheckCircleIcon } from "@brandclub/common-ui";

interface BrandRewardSummary {
  rewardAmountPending: number;
  rewardAmountCancelled: number;
  rewardAmountLocked: number;
  rewardAmountUnlocked: number;
  id: number;
  name: string;
  brand: BrandClub & {
    isMember: boolean;
    totalRewardAmount: number;
    rewardAmountByStatusLifetime: {
      rewardAmountUnlocked: number;
      rewardAmountLocked: number;
      rewardAmountPending: number;
      rewardAmountCancelled: number;
    };
  };
}

const BrandSummaryContainer = styled("div")(({ theme }) => ({
  display: "flex",
  border: "1.5px solid #dedede",
  padding: "30px 33px",
  //   margin: "15px 0",
  borderRadius: 10,
  backgroundColor: "#fff",
  gap: 26,
  justifyContent: "space-between",
  flexDirection: "row",
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    flexDirection: "column",
    padding: "20px 20px",
  },

  ".brand_summary": {
    display: "flex",
    ".logo": {
      width: 57,
      height: 57,
      objectFit: "contain",
      marginRight: 20,
    },
    ".brand_name": {
      fontSize: 18,
      fontWeight: 700,
      marginBottom: 9,
    },
    ".link_detail": {
      fontSize: 14,
      fontWeight: 600,
      marginBottom: 16,
      display: "flex",
      color: theme.palette.primary.main,
      flexDirection: "row",
      alignItems: "center",
    },
    ".member_pill": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      boxSizing: "border-box",
      alignItems: "center",
      padding: "0 15px",
      marginTop: 5,
      height: 28,
      width: 100,
      borderRadius: 50,
      backgroundColor: "rgba(158, 224, 208, 0.2)",
      fontSize: 12,
      fontWeight: 600,
    },
  },
  ".rewards_detail": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    maxWidth: 343,
    gap: 20,
    ".row": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      ".title": { fontSize: 16, fontWeight: 600, maxWidth: 150 },
      ".subtitle": {
        fontSize: 12,
        fontWeight: 500,
        maxWidth: 187,
      },
    },
  },
}));

const BrandSummaryCard = ({ brandData }: { brandData: BrandRewardSummary }) => {
  const netUnlockedRewards = brandData.rewardAmountUnlocked;
  const netLifetimeUnlockedRewards =
    brandData.brand.rewardAmountByStatusLifetime.rewardAmountUnlocked;
  return (
    <BrandSummaryContainer>
      <div className="brand_summary">
        <img className="logo" alt="re" src={brandData.brand.imageUrl} />

        <div>
          <div className="brand_name">{brandData.name}</div>
          <Link
            to={`/${escapeUriPath(brandData?.name)}/b/${
              brandData?.id
            }/branddashboard/rewards/activity`}
            style={{ textDecoration: "none" }}
          >
            <div className="link_detail">
              View details
              <ArrowRightIcon
                color="primary"
                sx={{ fontSize: 13, marginLeft: "7.7px" }}
              />
            </div>
          </Link>
          {/* Put Join Button here */}
          {brandData.brand.isMember ? (
            <div className="member_pill" style={{}}>
              <CheckCircleIcon
                backgroundColor="primary"
                htmlColor="#fff"
                sx={{ fontSize: 11, marginRight: "5px" }}
                className="left_icon"
              />
              Member
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <div className="rewards_detail">
        {/* Pending Rewards */}
        {brandData.rewardAmountPending > 0 && (
          <div className="row">
            <div>
              <div className="title">New pending</div>
              <div className="subtitle">
                {`You earned ${formatMoney(
                  brandData.rewardAmountPending
                )} in\nnew pending rewards`}
              </div>
            </div>
            <div>
              <div className="title">
                +{formatMoney(brandData.rewardAmountPending)}
              </div>
              <div className="subtitle">
                (
                {formatMoney(
                  brandData.brand.rewardAmountByStatusLifetime
                    .rewardAmountPending
                )}{" "}
                total)
              </div>
            </div>
          </div>
        )}
        {/* Locked Rewards */}
        <div className="row">
          <div>
            <div className="title">New locked</div>
            <div className="subtitle">
              {brandData.rewardAmountLocked > 0
                ? `You earned ${formatMoney(
                    brandData.rewardAmountLocked
                  )} in new\npurchase rewards`
                : "Your purchases didn’t qualify for any new rewards"}
            </div>
          </div>
          <div>
            <div className="title">
              +{formatMoney(brandData.rewardAmountLocked)}
            </div>
            <div className="subtitle">
              (
              {formatMoney(
                brandData.brand.rewardAmountByStatusLifetime.rewardAmountLocked
              )}{" "}
              total)
            </div>
          </div>
        </div>
        {/* Unlocked Rewards */}
        <div className="row">
          <div>
            <div className="title">New unlocked</div>
            <div className="subtitle">
              {netUnlockedRewards > 0
                ? `You unlocked ${formatMoney(
                    netUnlockedRewards
                  )} in\nexisting rewards`
                : "Your purchases didn’t qualify to unlock rewards"}
            </div>
          </div>
          <div>
            <div className="title">+{formatMoney(netUnlockedRewards)}</div>
            <div className="subtitle">
              ({formatMoney(netLifetimeUnlockedRewards)} total)
            </div>
          </div>
        </div>
        {/* Cancelled Rewards */}
        {brandData.rewardAmountCancelled > 0 && (
          <div className="row">
            <div>
              <div className="title">New cancelled</div>
              <div className="subtitle">{`Reward removed due to order cancellation`}</div>
            </div>
            <div>
              <div className="title">
                +{formatMoney(brandData.rewardAmountCancelled)}
              </div>
            </div>
          </div>
        )}
      </div>
    </BrandSummaryContainer>
  );
};

export default BrandSummaryCard;
