import { Link } from "react-router-dom";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import { CardGrid } from "../components/CardGrid";
import { PreviewHeader } from "../components/Header";
import PreviewWidgetWrapper from "../components/PreviewWidgetWrapper";
import { useFetchInstantRewards } from "./data";
import {
  INSTANT_REWARDS_ROUTE,
  INSTANT_REWARDS_SUBTITLE,
  INSTANT_REWARDS_TITLE,
} from "./text";

import { useEffect, useRef } from "react";
import { useInView } from "framer-motion";
import PreviewContainer from "../components/PreviewContainer";
import { defaultPreviewLazyLoadConfig } from "../../../../../utils/lazyLoadConfig";
import ProductCard from "../../../../StoreComponents/StoreCard.tsx/ProductCard";

interface InstantRewardsPreviewProps {
  brandIds?: number[];
}
const InstantRewardsPreview = (props: InstantRewardsPreviewProps) => {
  const ref = useRef(null);
  const widgetInView = useInView(ref, defaultPreviewLazyLoadConfig);
  const { data, loading, fetchInit } = useFetchInstantRewards({
    pageSize: 10,
    autoInitFetch: false,
    brandIds: props.brandIds,
  });

  useEffect(() => {
    if (widgetInView) {
      fetchInit();
    }
  }, [widgetInView, fetchInit]);

  if (!loading && data.length === 0) {
    return null;
  }

  return (
    <PreviewWidgetWrapper ref={ref}>
      <PreviewContainer>
        {loading ? (
          <StoreLoadingSkeleton header row={2} />
        ) : (
          <>
            <PreviewHeader
              title={INSTANT_REWARDS_TITLE}
              linkTo={INSTANT_REWARDS_ROUTE}
              subtitle={INSTANT_REWARDS_SUBTITLE}
              indicator="grey"
            />
            <CardGrid>
              {data.map((p) => (
                <ProductCard
                  key={`trending:${p.stacklineSku}`}
                  LinkComponent={Link}
                  product={p}
                />
              ))}
            </CardGrid>
          </>
        )}
      </PreviewContainer>
    </PreviewWidgetWrapper>
  );
};

export default InstantRewardsPreview;
