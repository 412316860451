import { brandclub_colors } from "@brandclub/common-ui";
import { styled } from "@mui/material";
import React from "react";
import { Department } from "../departmentMapping";
import { Link } from "react-router-dom";
import { MOBILE_SCREEN_SIZE } from "../../../../../AppNavigation/constants";

const CardContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 10,
  cursor: "pointer",
  transition: "transform 0.4s",
  width: "100%",
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    gap: 7,
  },
  ".image_container": {
    width: "100%",
    aspectRatio: "1/1",
    backgroundColor: brandclub_colors.blueWhite,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    img: {
      width: "60%",
      height: "60%",
      maxWidth: 56,
      maxHeight: 56,
      objectFit: "contain",
    },
  },
  ".label": {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 12,
    },
  },
}));
const DepartmentCard = ({ department }: { department: Department }) => {
  return (
    <Link
      to={`/department/${department.departmentId}`}
      style={{ textDecoration: "none" }}
    >
      <CardContainer>
        <div className="image_container">
          <img alt="bc_action" src={department.imageUrl} />
        </div>
        <div className="label">{department.departmentName}</div>
      </CardContainer>
    </Link>
  );
};

export default DepartmentCard;
