import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";

/**
 * A wrapper around useState that resets the state to the initial value when the location changes.
 * @param defaultOpen The default value of the state
 */
export const useStateWithLocationReset = <T>(defaultValue: T) => {
  const defaultValueRef = useRef(defaultValue);
  const location = useLocation();
  const [state, setState] = useState(defaultValue);

  // Store the default value in a ref
  useEffect(() => {
    defaultValueRef.current = defaultValue;
  }, [defaultValue]);

  // Reset the state to the default value when the location changes
  useEffect(() => {
    setState(defaultValueRef.current);
  }, [location]);

  return [state, setState] as const;
};
