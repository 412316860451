import { styled } from "@mui/material/styles";
import { FC, ReactNode } from "react";
import { useNavigate } from "react-router";
import { MOBILE_SCREEN_SIZE } from "../../AppNavigation/constants";
import { SolidButton } from "../StoreButton";
import { AnalyticsTrackingEvent } from "@brandclub/common-ui";

export interface StoreMessageCardProps {
  cta: ReactNode;
  onActionClick?: (event: React.MouseEvent<HTMLElement>) => void;
  linkTo?: string;
  title: string;
  content: ReactNode;
  className?: string;
  trackedEventName?: AnalyticsTrackingEvent;
  trackingParams?: Record<string, any>;
}

const StoreMessageCardWrapper = styled("div")(({ theme }) => ({
  boxSizing: "border-box",
  textAlign: "center",
  border: `1px solid #dcdddc`,
  borderRadius: 10,
  padding: "27px 40px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#f6f8fa",
  gap: 10,
}));

const StoreMessageCardTitle = styled("div")(({ theme }) => ({
  fontSize: 20,
  fontWeight: 700,
  color: theme.palette.primary.main,
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    fontSize: 18,
  },
}));

const StoreMessageCardContent = styled("div")(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  color: theme.palette.primary.main,
  textAlign: "center",
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    fontSize: 14,
  },
}));

const StoreMessageActionButton = styled(SolidButton)({
  marginTop: 10,
  height: 50,
  fontSize: 16,
  whiteSpace: "nowrap",
  minWidth: 355,
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    minWidth: "unset",
    height: 44,
    fontSize: 14,
  },
});

const StoreMessageCard: FC<StoreMessageCardProps> = ({
  title,
  content,
  cta,
  onActionClick,
  linkTo,
  trackedEventName,
  trackingParams,
  ...props
}) => {
  const navigate = useNavigate();
  return (
    <StoreMessageCardWrapper {...props}>
      <StoreMessageCardTitle className="store-message-card__title">
        {title}
      </StoreMessageCardTitle>
      <StoreMessageCardContent className="store-message-card__content">
        {content}
      </StoreMessageCardContent>
      <StoreMessageActionButton
        trackedEventName={trackedEventName}
        trackedAdditionalData={{ linkTo, ...trackingParams }}
        className="store-message-card__cta"
        onClick={(e) => {
          onActionClick?.(e);
          if (linkTo !== "/" && linkTo?.startsWith("http")) {
            window.open(linkTo, "_self");
          } else if (linkTo) {
            navigate(linkTo);
          }
        }}
      >
        {cta}
      </StoreMessageActionButton>
    </StoreMessageCardWrapper>
  );
};

export default StoreMessageCard;
