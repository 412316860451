import _orderBy from "lodash/orderBy";
import { getAllCategories } from "../../api/graphQl/unauthenticated/Categories";
import { Category } from "../../types/misc";
import ConfigUtils from "../../utils/ConfigUtils";
import { setCategories, setCategoriesLoading } from "../reducers/categories";
import { setDepartments, setDepartmentsLoading } from "../reducers/departments";
import reduxApolloClient from "../reduxApolloClient";
import { AppDispatch } from "../store";
import { DepartmentForRedux } from "../types";

/* --------------------- loadCategories ---------------------*/

export const loadCategories = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setCategoriesLoading());
    dispatch(setDepartmentsLoading());

    const { data } = await reduxApolloClient.query({
      query: getAllCategories,
      fetchPolicy: "no-cache",
      context: {
        uri: `${ConfigUtils.getGraphQlUri()}?id=getAllCategories`,
      },
    });

    const departmentMap: Record<number, DepartmentForRedux> = {};
    if (data && data.AllCategories) {
      const categories = data.AllCategories as Category[];
      categories.forEach((category) => {
        const { parentCategoryId, parentCategoryName } = category;
        if (parentCategoryId && parentCategoryName) {
          const departmentId = parentCategoryId;
          if (departmentMap[departmentId]) {
            departmentMap[departmentId].relevancyScore +=
              category.relevancyScore;
            departmentMap[departmentId].categories.push(category);
          } else {
            departmentMap[departmentId] = {
              departmentId,
              departmentName: parentCategoryName,
              relevancyScore: category.relevancyScore,
              categories: [category],
            };
          }
        }
      });

      // Sort departments by relevancy score descending (effectively retail sales)
      const orderedDepartments = _orderBy(
        Object.values(departmentMap),
        ["relevancyScore"],
        ["desc"]
      );

      dispatch(setDepartments(orderedDepartments));
      dispatch(setCategories(categories));
    }
  } catch (e) {
    console.error("loadCategories error: ", e);
  }
};
