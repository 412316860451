import { Auth } from "aws-amplify";
import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import { initiateIdpLinking } from "../../../api";
export class AuthHelper {
  static INSTANCE = new AuthHelper();

  static configure(config: any) {
    try {
      Auth.configure(config);
    } catch (e) {
      console.error(e);
    }
  }

  static async linkProvider({
    provider,
    redirectUrl,
  }: {
    provider: string;
    redirectUrl: string;
  }) {
    const result = await initiateIdpLinking({
      idpProvider: provider,
      redirectUrl,
    });

    window.location.href = result.data.authRedirectUrl;
  }

  static buildClientMetadata(clientMetadata?: Record<string, string>) {
    return {
      origin: window.location.origin,
      phoneNumberAuth: "true",
      hostname: window.location.hostname,
      ...clientMetadata,
    };
  }

  static getEmailForPhoneNumber(phoneNumber: string) {
    return phoneNumber + "@user.brandclub.com";
  }

  static getPhoneNumberForEmail(email: string) {
    if (email.endsWith("@user.brandclub.com")) {
      return email.substring(0, email.indexOf("@user.brandclub.com"));
    }
    return null;
  }

  static async getJwt(
    cognitoUser?: CognitoUser,
    forceRefreshSession?: boolean
  ) {
    const session = await AuthHelper.getCurrentSession(
      cognitoUser,
      forceRefreshSession
    );
    const accessToken = session.getAccessToken();
    const jwt = accessToken.getJwtToken();
    return jwt;
  }

  static async getCurrentSession(
    cognitoUserGiven?: CognitoUser,
    forceRefreshSession?: boolean
  ) {
    try {
      const cognitoUser: CognitoUser =
        cognitoUserGiven ?? (await Auth.currentAuthenticatedUser());
      const currentSession = await new Promise<CognitoUserSession | null>(
        (resolve, reject) => {
          cognitoUser.getSession(
            (err: Error | null, session: CognitoUserSession | null) => {
              if (err) {
                reject(err);
              } else {
                resolve(session);
              }
            }
          );
        }
      );
      if (!currentSession) {
        throw new Error("No current session");
      }
      if (!forceRefreshSession) {
        return currentSession;
      }
      return new Promise<CognitoUserSession>((resolve, reject) => {
        cognitoUser.refreshSession(
          currentSession.getRefreshToken(),
          (err: Error | null, session: CognitoUserSession) => {
            if (err) {
              reject(err);
            } else {
              resolve(session);
            }
          }
        );
      });
    } catch (e) {
      console.log("Failed to refresh Session", e);
      throw e;
    }
  }

  static async signUp(
    phoneNumber: string,
    clientMetaData?: Record<string, string>
  ) {
    const email = AuthHelper.getEmailForPhoneNumber(phoneNumber);
    const result = await Auth.signUp({
      username: email,
      password:
        "P1!" +
        Math.random().toString(36).substring(7) +
        Math.random().toString(36).substring(7),
      attributes: {
        email,
        phone_number: phoneNumber,
      },
      clientMetadata: AuthHelper.buildClientMetadata(clientMetaData),
    });
    if (result.userConfirmed) {
      return result.user;
    }
    // throws exception if user is not confirmed
  }

  static async signIn(phoneNumber: string, isNewUser?: boolean) {
    const email = AuthHelper.getEmailForPhoneNumber(phoneNumber);
    const session = await Auth.signIn(
      {
        username: email,
        password: "",
        validationData: {
          phoneNumber,
          email,
        },
      },
      undefined,
      AuthHelper.buildClientMetadata({
        isNewUser: isNewUser ? "true" : "false",
      })
    );
    return session;
  }

  static async sendOtpMechanism(
    cognitoUser: CognitoUser,
    mechanism: "email" | "sms"
  ) {
    await Auth.sendCustomChallengeAnswer(cognitoUser, mechanism, {
      origin: window.location.origin,
      phoneNumberAuth: "true",
      hostname: window.location.hostname,
    });
  }

  static async sendAuthOtpWithSms(phoneNumber: string, isNewUser?: boolean) {
    const session = await AuthHelper.signIn(phoneNumber, isNewUser);
    await AuthHelper.sendOtpMechanism(session, "sms"); // IMP - This sets the challengeName to CUSTOM_CHALLENGE
    return session;
  }

  static async answerCustomChallenge(
    cognitoUser: CognitoUser,
    code: string,
    clientMetadata?: Record<string, string>
  ) {
    // This will throw an error if it's the 3rd wrong answer
    const challengeResult = await Auth.sendCustomChallengeAnswer(
      cognitoUser,
      code,
      AuthHelper.buildClientMetadata(clientMetadata)
    );
    if (challengeResult.challengeName) {
      const attemptsLeft = parseInt(
        challengeResult.challengeParam.attemptsLeft
      );
      throw new Error(
        `The code you entered is incorrect. ${attemptsLeft} attempts left.`
      );
    } else {
      return challengeResult as CognitoUser;
    }
  }
}
