import { useCallback, useEffect, useState } from "react";
import _uniqBy from "lodash/uniqBy";
import { ProductRewardSearchResult } from "@brandclub/common-ui";
import {
  getTrendingProductsV2,
  getUnauthedGetTrendingProducts,
} from "../../../../../api/graphQl/authenticated/Trending";
import { SearchResult } from "../../../../../api/types/searchResult";
import reduxApolloClient from "../../../../../redux/reduxApolloClient";
import ConfigUtils from "../../../../../utils/ConfigUtils";
import { getUserSignedInState } from "../../../../../Auth";

export const useFetchTrendingProduct = ({
  brandIds,
  pageSize = 25,
  autoInitFetch = true,
}: {
  brandIds?: number[];
  pageSize?: number;
  autoInitFetch?: boolean;
}) => {
  const [data, setData] = useState<ProductRewardSearchResult[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(
    async (pageNumber = 0) => {
      try {
        setError(null);
        const { signedIn } = await getUserSignedInState();
        const RES_KEY = signedIn
          ? "GetTrendingProductsV2"
          : "UnauthedGetTrendingProducts";

        const query = signedIn
          ? getTrendingProductsV2
          : getUnauthedGetTrendingProducts;

        const res = await reduxApolloClient.query<{
          [k: string]: SearchResult<ProductRewardSearchResult>;
        }>({
          query,
          variables: {
            brandIds,
            page: {
              start: pageNumber,
              size: pageSize,
            },
          },
          fetchPolicy: "network-only",
          context: {
            uri: `${ConfigUtils.getGraphQlUri()}?id=${RES_KEY}`,
          },
        });

        const newData = res?.data?.[RES_KEY]?.items ?? [];
        setData((d) => _uniqBy([...d, ...newData], "stacklineSku"));
        const newHasMore = res?.data?.[RES_KEY]?.hasMore ?? false;
        setHasMore(newHasMore);
      } catch (e: any) {
        setError(e?.message ?? "Something went wrong");
      }
    },

    [brandIds, pageSize]
  );

  const fetchInit = useCallback(async () => {
    try {
      setLoading(true);
      await fetchData(0);
    } finally {
      setPage(0);
      setLoading(false);
    }
  }, [fetchData]);

  const fetchMore = useCallback(async () => {
    try {
      setLoadingMore(true);
      await fetchData((page + 1) * pageSize);
    } finally {
      setPage((p) => p + 1);
      setLoadingMore(false);
    }
  }, [fetchData, page, pageSize]);

  useEffect(() => {
    if (autoInitFetch) {
      fetchInit();
    }
  }, [autoInitFetch, fetchInit]);

  return { data, loading, error, hasMore, fetchMore, loadingMore, fetchInit };
};
