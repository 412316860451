import axios from "../../../utils/axios";
import { getAuthorizationHeader } from "../../../Auth";

export const generateAffiliateLink = async ({
  stacklineSku,
  retailerSku,
  retailerId,
}: {
  stacklineSku: string;
  retailerSku: string;
  retailerId: number;
}) => {
  return axios.post(
    "/affiliate/generateAffiliateLink",
    {
      stacklineSku,
      retailerSku,
      retailerId,
    },
    {
      headers: {
        ...(await getAuthorizationHeader()),
      },
    }
  );
};
