import { Skeleton } from "@mui/material";
import React from "react";

import {
  HeadBarContainer,
  HeaderBarInner,
  NavBarContainer,
  NavigationContainer,
} from "../../AppNavigation/DesktopNav";

const StoreLoadingSkeletonFullSite = ({
  header = true,
}: {
  header?: boolean;
}) => {
  const colorStyle = "#f6f8fa";
  const darKColorStyle = "#e6ebf0";
  return (
    <>
      {header ? (
        <HeadBarContainer data-testid="store-loading-skeleton-fullsite-headerbar">
          <HeaderBarInner container wrap="nowrap">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "flex-start",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={130}
                height={27}
                style={{ borderRadius: 6 }}
                sx={{ bgcolor: darKColorStyle, display: "block" }}
              />
              <Skeleton
                variant="rectangular"
                width={45}
                height={27}
                style={{ borderRadius: 6, marginLeft: 80 }}
                sx={{ bgcolor: darKColorStyle, display: "block" }}
              />
              <Skeleton
                variant="rectangular"
                width={79}
                height={27}
                style={{ borderRadius: 6, marginLeft: 50 }}
                sx={{ bgcolor: darKColorStyle, display: "block" }}
              />
              <Skeleton
                variant="rectangular"
                width={149}
                height={27}
                style={{ borderRadius: 6, marginLeft: 50 }}
                sx={{ bgcolor: darKColorStyle, display: "block" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={28}
                height={28}
                style={{ borderRadius: 6 }}
                sx={{ bgcolor: darKColorStyle, display: "block" }}
              />
              <Skeleton
                variant="rectangular"
                width={28}
                height={28}
                style={{ borderRadius: 6, marginLeft: 17 }}
                sx={{ bgcolor: darKColorStyle, display: "block" }}
              />
              <Skeleton
                variant="rectangular"
                width={28}
                height={28}
                style={{ borderRadius: 6, marginLeft: 17 }}
                sx={{ bgcolor: darKColorStyle, display: "block" }}
              />
            </div>
          </HeaderBarInner>
        </HeadBarContainer>
      ) : null}
      {header ? (
        <NavigationContainer data-testid="store-loading-skeleton-fullsite-nav-header">
          <NavBarContainer>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                position: "relative",
              }}
            >
              <div style={{ flex: 1 }}></div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  position: "absolute",
                  justifyContent: "center",
                }}
              >
                <Skeleton
                  variant="rectangular"
                  width={46}
                  height={28}
                  style={{ borderRadius: 6, margin: "0 20px" }}
                  sx={{ bgcolor: colorStyle, display: "block" }}
                />
                <Skeleton
                  variant="rectangular"
                  width={80}
                  height={28}
                  style={{ borderRadius: 6, margin: "0 20px" }}
                  sx={{ bgcolor: colorStyle, display: "block" }}
                />
                <Skeleton
                  variant="rectangular"
                  width={89}
                  height={28}
                  style={{ borderRadius: 6, margin: "0 20px" }}
                  sx={{ bgcolor: colorStyle, display: "block" }}
                />
              </div>
            </div>
          </NavBarContainer>
        </NavigationContainer>
      ) : null}

      <NavigationContainer data-testid="store-loading-skeleton-fullsite-content">
        <NavBarContainer style={{ height: 800, alignItems: "flex-start" }}>
          <div
            style={{
              marginTop: 40,
              display: "flex",
              flexDirection: "column",
              width: "100%",
              position: "relative",
            }}
          >
            <Skeleton
              variant="rectangular"
              width={175}
              height={35}
              style={{ borderRadius: 6 }}
              sx={{ bgcolor: colorStyle, display: "block" }}
            />
            <Skeleton
              variant="rectangular"
              width={305}
              height={25}
              style={{ borderRadius: 6, marginTop: 20 }}
              sx={{ bgcolor: colorStyle, display: "block" }}
            />
            <div style={{ display: "flex", width: "100%", gap: "30px" }}>
              <div style={{ flex: 7 }}>
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={500}
                  style={{ borderRadius: 6, marginTop: 40 }}
                  sx={{ bgcolor: colorStyle, display: "block" }}
                />
              </div>
              <div style={{ flex: 3 }}>
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={500}
                  style={{ borderRadius: 6, marginTop: 40 }}
                  sx={{ bgcolor: colorStyle, display: "block" }}
                />
              </div>
            </div>
          </div>
        </NavBarContainer>
      </NavigationContainer>
    </>
  );
};

export default StoreLoadingSkeletonFullSite;
