import {
  BrandClub,
  useJoinBrandClub as useCommonUiJoinBrandclub,
  useJoinBrandClubWithRedirect as useCommonUiJoinBrandClubWithRedirect,
} from "@brandclub/common-ui";
import { handleJoinClub } from "../../api";
import { useNavigate } from "react-router-dom";

export type JoinBrandClubState = "not_join" | "joining" | "joined";

export const useJoinBrandClub = (brand: Partial<BrandClub> | undefined) => {
  return useCommonUiJoinBrandclub(brand, handleJoinClub);
};

export const useJoinBrandClubWithRedirect = (
  brand: Partial<BrandClub> | undefined,
  delay = 500,
  link?: string
) => {
  const navigate = useNavigate();
  return useCommonUiJoinBrandClubWithRedirect({
    brand,
    delay,
    link,
    navigate,
    handleJoinClub,
  });
};
