import React from "react";
import { Outlet } from "react-router-dom";
import { MainOutlet } from "../../Layout/MainOutlet";

const SyncSummaryOutlet = () => {
  return (
    <MainOutlet>
      <Outlet />
    </MainOutlet>
  );
};

export default SyncSummaryOutlet;
