import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  StoreFinish,
  StoreFinishButton,
  StoreFinishLink,
  StoreFinishLinkButton,
} from "../../../StoreComponents/StoreFinish";
import { getJoinRewardAmount } from "./BrandstoreRewardsCarousel";
import FlowContainer from "../Pages/components/FlowContainer";

import {
  getBrandEntity,
  getBrandclubEntity,
} from "../../../../redux/reducers/brandEntity/thunk";
import { useNavigate, useNavigationType, useParams } from "react-router-dom";
import { useEffect } from "react";
import { formatMoney } from "../../../../utils/StringUtils";
import { getBrandPagePath } from "../../../../utils/pageUtils";
import { useSiteContext } from "../../../SiteProvider";

import LoginButtons from "../../../StoreComponents/StoreLogin/LoginButtons";
import { useSignInPage } from "../../../StoreComponents/StoreLogin/useStoreLogin";
import { useUserLogin } from "../../Auth/UserLoginProvider";

export const generateJoinRewardMessage = (reward?: number | string): string => {
  if (!reward || typeof reward === "string" || reward <= 0) {
    return "Thank you for joining.";
  }
  const formattedRewardAmount = formatMoney(reward, undefined, true);
  return `Thank you for joining. You earned a ${formattedRewardAmount} reward that will be unlocked with your next purchase.`;
};

export const JoinBrandStoreBrandClubSuccess = () => {
  const params = useParams();
  const signIn = useSignInPage();
  const createAccount = useSignInPage();
  const { signedIn } = useUserLogin();
  const brandEntity = useAppSelector(({ brandEntity }) => brandEntity);
  const { brandId: brandIdFromParams } = params;
  const { brandId: brandIdFromSiteContext } = useSiteContext();

  const brandId = brandIdFromSiteContext
    ? `${brandIdFromSiteContext}`
    : brandIdFromParams;

  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const dispatch = useAppDispatch();
  const brandclub = brandEntity?.brandclub;
  const brandPagePath = brandclub ? getBrandPagePath(brandclub) : "/";

  useEffect(() => {
    /* Redirect to the brand page if the navigation action is not a "PUSH".
     * Navigation actions:
     *   - Clicking a link: "PUSH"
     *   - Refreshing the page: "REPLACE"
     *   - Using the browser's back or forward buttons: "POP"
     * If the action is not a "PUSH", redirect to the brand page and replace the current entry in the history stack.
     * This prevents the user from landing on the join club success page when using the browser's back or forward buttons.
     */
    if (navigationType !== "PUSH") {
      navigate(brandPagePath, { replace: true });
    }
  }, [navigationType, brandPagePath, navigate]);

  useEffect(() => {
    // get brand entity if not already in state
    if (brandId) {
      dispatch(getBrandEntity({ id: brandIdFromParams }));
      dispatch(getBrandclubEntity({ id: Number(brandIdFromParams) }));
    }
  }, [brandIdFromParams, dispatch, brandId]);

  const brandName = brandclub?.name;
  const joinReward = brandEntity && getJoinRewardAmount(brandEntity);
  let actionButtons = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {!!brandName && (
        <StoreFinishLink to={brandPagePath} replace={true}>
          <StoreFinishButton>{`Explore ${brandName}`}</StoreFinishButton>
        </StoreFinishLink>
      )}
      <StoreFinishLinkButton linkProps={{ to: -1 }}>Back</StoreFinishLinkButton>
    </div>
  );
  if (!signedIn) {
    actionButtons = (
      <LoginButtons
        trackedAdditionalData={{
          brandName,
          brandId,
        }}
        onSignInClick={() => signIn({ pathname: "/" })}
        onCreateAccountClick={() => createAccount({ pathname: "/" })}
        sx={{ maxWidth: "355px", marginTop: "10px" }}
      />
    );
  }
  return (
    <FlowContainer>
      <StoreFinish
        sx={{
          margin: "0 auto",
        }}
        title="You're in"
        subtitle={generateJoinRewardMessage(joinReward?.rewardAmount)}
      >
        {actionButtons}
      </StoreFinish>
    </FlowContainer>
  );
};
