import { assign, createMachine, DoneActorEvent, fromPromise } from "xstate";
import _get from "lodash/get";
import { UserRetailerCheckoutBatch, UserRetailerCheckoutOrder } from "../types";
import { setBatch } from "@/redux/reducers/checkout";
import Store from "@/redux/store";
import {
  cancelCheckout,
  getRetailerCheckoutOrderStatus,
  initiateRetailerCheckout,
  RetailerCheckoutOrderStatus,
  retryRetailerLoginDuringCheckout,
} from "@/api/rest/checkoutApi";
import {
  CheckoutStatusChangeEvent,
  UserInputUpdateType,
  GlobalCheckoutMachineContext,
  GlobalCheckoutMachineEvents,
  GlobalCheckoutState,
  InitiateCheckoutEvent,
  LoginSubmitEvent,
  UpdateRetailerConnectionInfoList,
} from "./types/GlobalCheckoutMachine";
import { getTOtp } from "../../../api";
import { encryptData } from "../../../utils/encryptionUtils";
import { CheckoutUserInputMachine } from "./CheckoutUserInputMachine";
import { CheckoutUserInputEvent } from "./types/CheckoutUserInputMachine";
import { serverSideSyncApolloClient } from "@/redux/reduxApolloClient";
import { SearchResult } from "../../../types/search";
import { UserPurchaseHistory } from "@/types/misc";
import { getUserRetailerCheckoutOrderDetailBreakup } from "@/api/graphQl/authenticated/Purchase";
import { isPurchaseCheckoutSuccess } from "../utils";
import { getStepFromCartProducts } from "../utils";

export type GlobalCheckoutMachineType = typeof globalCheckoutMachine;

export const globalCheckoutMachine = (() => {
  const machineId = "globalCheckoutMachine";
  return createMachine(
    {
      id: machineId,
      initial: "waiting",
      types: {} as unknown as {
        context: GlobalCheckoutMachineContext;
        events: GlobalCheckoutMachineEvents;
      },
      on: {
        UPDATE_RETAILER_CONNECTION_INFO_LIST: {
          actions: ["updateRetailerConnectionInfoListFromRedux"],
        },
        RESET_CHECKOUT: {
          target: "#globalCheckoutMachine.handleResetCheckout",
        },
        CHECKOUT_NEXT_RETAILER: {
          target: "#globalCheckoutMachine.checkoutNextRetailer",
        },
        INITIATE_CHECKOUT: [
          {
            target: "#globalCheckoutMachine.loginScreen",
            actions: [
              "resetContext",
              "updateRetailerIds",
              "updateActiveCheckoutRetailerId",
              "updateRetailerConnectionInfoList",
              "resetCheckoutBatchId",
            ],
            guard: "isRetailerNotConnected",
          },
          {
            target: "#globalCheckoutMachine.initiateRetailerCheckout",
            actions: [
              "updateRetailerIds",
              "updateActiveCheckoutRetailerId",
              "updateRetailerConnectionInfoList",
              "resetCheckoutBatchId",
            ],
          },
        ],
      },
      entry: () => {
        console.debug("Machine initialized");
      },
      context: ({ input: { context } }: { input: { context: any } }) =>
        context as GlobalCheckoutMachineContext,
      states: {
        waiting: {
          entry: [
            assign({
              globalCheckoutState: GlobalCheckoutState.IDLE,
            }),
          ],
        },
        loginScreen: {
          entry: [
            assign({
              globalCheckoutState: GlobalCheckoutState.LOGIN_SCREEN,
            }),
          ],
          on: {
            ON_LOGIN_INFO_SUBMIT: [
              {
                target: "retryLogin",
                guard: "isRetryLoginState",
                actions: ["updateConnectionInfo"],
              },
              {
                target: "initiateRetailerCheckout",
                actions: ["updateConnectionInfo"],
              },
            ],
            ON_LOGIN_SCREEN_CLOSE: {
              target: "waiting",
            },
          },
        },
        retryLogin: {
          invoke: {
            input: ({ context, event }) => ({ context, event }),
            src: "retryLogin",
            onDone: {
              target: "checkoutInProgress",
              actions: [assign({ checkoutRetailerConnectionState: undefined })],
            },
          },
        },
        initiateRetailerCheckout: {
          entry: [
            assign({
              globalCheckoutState: GlobalCheckoutState.INITIATING_CHECKOUT,
            }),
          ],
          invoke: {
            input: ({ context, event }) => ({ context, event }),
            src: "initiateRetailerCheckout",
            onDone: [
              {
                target: "checkoutSummary",
                actions: [
                  "updateUserRetailerCheckoutBatch",
                  "updateUserRetailerCheckoutOrder",
                  "updateRetailerCheckoutOrderStatus",
                ],
                guard: ({ event }) => {
                  const { retailerCheckoutOrderStatus } = (
                    event as DoneActorEvent<{
                      retailerCheckoutOrderStatus: RetailerCheckoutOrderStatus;
                    }>
                  )?.output;
                  return (
                    retailerCheckoutOrderStatus
                      ?.userRetailerCheckoutSessionStatus?.status ===
                    "checkout_waiting_for_user_input"
                  );
                },
              },
              {
                target: "checkoutInProgress",
                actions: [
                  "updateUserRetailerCheckoutBatch",
                  "updateUserRetailerCheckoutOrder",
                  "updateRetailerCheckoutOrderStatus",
                ],
              },
            ],
            onError: {
              actions: "updateError",
              target: "checkoutError",
            },
          },
        },
        checkoutInProgress: {
          entry: [
            assign({
              globalCheckoutState: GlobalCheckoutState.CHECKOUT_IN_PROGRESS,
              animationCompleted: false,
            }),
            "updateProductCards",
          ],
          initial: "waiting",
          states: {
            waiting: {
              on: {
                ON_CHECKOUT_STATUS_CHANGE: [
                  {
                    actions: ["updateRetailerCheckoutOrderStatusFromPoller"],
                    target: "#globalCheckoutMachine.handleFailedAttempt",
                    guard: "isRetryableFailureAttempt",
                  },
                  {
                    actions: ["updateRetailerCheckoutOrderStatusFromPoller"],
                    target: "#globalCheckoutMachine.checkoutSummary",
                    guard: "isCheckoutSummaryStage",
                  },
                  {
                    actions: [
                      "handleError",
                      "updateRetailerCheckoutOrderStatusFromPoller",
                    ],
                    target: "#globalCheckoutMachine.checkoutError",
                    guard: "isCheckoutErrorStage",
                  },
                  {
                    actions: [
                      "updateRetailerCheckoutOrderStatusFromPoller",
                      "updateProductCards",
                    ],
                  },
                ],
                ON_CHECKOUT_ANIMATION_COMPLETE: [
                  {
                    target: "#globalCheckoutMachine.checkoutSummary",
                    actions: ["updateAnimationComplete"],
                  },
                ],
              },
            },
          },
        },
        checkoutSummary: {
          entry: [
            assign({
              globalCheckoutState: GlobalCheckoutState.CHECKOUT_SUMMARY,
            }),
          ],
          initial: "waiting",
          states: {
            waiting: {
              on: {
                ON_CHECKOUT_STATUS_CHANGE: [
                  {
                    actions: ["updateRetailerCheckoutOrderStatusFromPoller"],
                  },
                ],
                CHECKOUT_USER_INPUT_EVENT: {
                  target: "#globalCheckoutMachine.handleCheckoutUserInput",
                },
              },
            },
          },
        },
        handleCheckoutUserInput: {
          entry: assign({
            userInputError: undefined,
          }),
          invoke: {
            input: ({ context, event }) => ({
              context: {
                ...context,
                userInput: event as CheckoutUserInputEvent,
              },
            }),
            src: CheckoutUserInputMachine,
            onDone: [
              {
                target: "checkoutSummary",
                guard: ({ event }) => {
                  return !!event.output.error?.message;
                },
                actions: [
                  assign({
                    userInputError: ({ event }) => event.output.error,
                  }),
                ],
              },
              {
                target: "checkoutUserInputUpdateInProgress",
                actions: [
                  assign(({ event }) => {
                    const { userInputUpdateType } = (
                      event as DoneActorEvent<{
                        userInputUpdateType: UserInputUpdateType;
                      }>
                    )?.output;
                    return {
                      userInputUpdateType,
                    };
                  }),
                ],
              },
            ],
            onError: {
              target: "checkoutError",
            },
          },
        },
        handleResetCheckout: {
          entry: [
            assign({
              globalCheckoutState: GlobalCheckoutState.IDLE,
              error: undefined,
              retailerCheckoutOrderStatus: undefined,
              activeCheckoutRetailerId: undefined,
            }),
            ({ context }) => {
              context.navigate("/mybag");
            },
          ],
          invoke: {
            input: ({ context }) => ({ context }),
            src: "handleResetCheckout",
            onDone: {
              target: "waiting",
              actions: ["resetContext"],
            },
          },
        },
        checkoutUserInputUpdateInProgress: {
          entry: [
            assign({
              globalCheckoutState:
                GlobalCheckoutState.CHECKOUT_USER_INPUT_UPDATE_IN_PROGRESS,
            }),
          ],
          on: {
            ON_CHECKOUT_STATUS_CHANGE: [
              {
                actions: ["updateRetailerCheckoutOrderStatusFromPoller"],
                target: "#globalCheckoutMachine.handleFailedAttempt",
                guard: "isRetryableFailureAttempt",
              },
              {
                actions: ["updateRetailerCheckoutOrderStatusFromPoller"],
                target: "#globalCheckoutMachine.checkoutSummary",
                guard: "isCheckoutSummaryStage",
              },
              {
                actions: ["updateRetailerCheckoutOrderStatusFromPoller"],
                target: "#globalCheckoutMachine.checkoutNextRetailer",
                guard: "isCheckoutSuccessStageAndNextRetailerAvailable",
              },
              {
                actions: ["updateRetailerCheckoutOrderStatusFromPoller"],
                target: "#globalCheckoutMachine.waitForCheckoutSuccessDataLoad",
                guard: "isCheckoutSuccessStage",
              },
              {
                actions: ["updateRetailerCheckoutOrderStatusFromPoller"],
                target: "#globalCheckoutMachine.checkoutError",
                guard: "isCheckoutErrorStage",
              },
              {
                actions: ["updateRetailerCheckoutOrderStatusFromPoller"],
              },
            ],
          },
          exit: [assign({ userInputUpdateType: undefined })],
        },
        handleFailedAttempt: {
          invoke: {
            input: ({ context, event }) => ({ context, event }),
            src: "handleFailedAttempt",
            onDone: [
              {
                target: "loginScreen",
                actions: ["updateCheckoutRetailerConnectionState"],
                guard: "isLoginError",
              },
              {
                target: "checkoutSummary",
                guard: "isCheckoutSummaryStage",
              },
            ],
          },
        },
        waitForCheckoutSuccessDataLoad: {
          entry: [
            assign({
              globalCheckoutState:
                GlobalCheckoutState.WAIT_FOR_CHECKOUT_SUCCESS_DATA_LOAD,
            }),
            () => console.log("Waiting for checkout success data load"),
          ],
          invoke: {
            input: ({ context }) => ({ context }),
            src: "waitForCheckoutSuccessDataLoad",
            onDone: {
              target: "checkoutSuccess",
            },
            onError: {
              target: "checkoutError",
              actions: ["updateError"],
            },
          },
        },
        checkoutSuccess: {
          entry: [
            assign({
              globalCheckoutState: GlobalCheckoutState.CHECKOUT_SUCCESS,
            }),
          ],
        },
        checkoutError: {},
        checkoutNextRetailer: {
          invoke: {
            input: ({ context }) => ({ context }),
            src: "checkoutNextRetailer",
            onDone: [
              {
                target: "loginScreen",
                actions: [
                  assign(
                    ({
                      event,
                    }: {
                      event: DoneActorEvent<{
                        activeCheckoutRetailerId: number;
                      }>;
                    }) => ({
                      activeCheckoutRetailerId:
                        event?.output?.activeCheckoutRetailerId,
                    })
                  ),
                ],
                guard: ({ context, event }) => {
                  const { activeCheckoutRetailerId } = event.output;
                  return (
                    context.retailerConnectionInfoList?.find(
                      (info: any) =>
                        info.retailerId === activeCheckoutRetailerId
                    )?.credential?.connectionStatus !== "valid"
                  );
                },
              },
              {
                target: "initiateRetailerCheckout",
                actions: [
                  assign(
                    ({
                      event,
                    }: {
                      event: DoneActorEvent<{
                        activeCheckoutRetailerId: number;
                      }>;
                    }) => ({
                      activeCheckoutRetailerId:
                        event?.output?.activeCheckoutRetailerId,
                    })
                  ),
                ],
              },
            ],
            onError: {
              actions: [
                ({ event }) =>
                  console.log("Error in checkoutNextRetailer", event),
              ],
            },
          },
        },
      },
    },
    {
      actors: {
        initiateRetailerCheckout: fromPromise(
          async ({
            input: { context },
          }: {
            input: { context: GlobalCheckoutMachineContext };
          }) => {
            const { currentStamp, token } = (await getTOtp()) ?? {};
            const loginInfo = context.retailerConnectionInfoList?.find(
              (info: any) =>
                info.retailerId === context.activeCheckoutRetailerId
            )?.credential;

            if (
              !token ||
              !context.activeCheckoutRetailerId ||
              !context.retailerIds
            ) {
              throw new Error("Invalid input");
            }

            let connectionInfo = undefined;

            if (loginInfo?.username && loginInfo?.password) {
              connectionInfo = encryptData(
                [
                  {
                    retailerId: context.activeCheckoutRetailerId,
                    credential: {
                      username: loginInfo?.username,
                      password: loginInfo?.password,
                    },
                  },
                ],
                token
              );
            }
            // TODO Implement this
            const checkoutBatchId =
              context?.checkoutBatch?.checkoutBatchId ?? "";

            const { userRetailerCheckoutBatch, userRetailerCheckoutOrder } =
              await initiateRetailerCheckout({
                retailerIds: context.retailerIds,
                checkoutBatchId,
                retailerId: context?.activeCheckoutRetailerId,
                currentStamp,
                connectionInfo,
                checkoutMode: "serverSide",
              });
            Store.dispatch(setBatch(userRetailerCheckoutBatch));

            const retailerCheckoutOrderStatus =
              await getRetailerCheckoutOrderStatus({
                orderId: userRetailerCheckoutOrder?.orderId,
              });

            return {
              userRetailerCheckoutBatch,
              userRetailerCheckoutOrder,
              retailerCheckoutOrderStatus,
            };
          }
        ),
        handleResetCheckout: fromPromise(
          async ({
            input: { context },
          }: {
            input: { context: GlobalCheckoutMachineContext };
          }) => {
            console.log("Resetting checkout");
          }
        ),
        handleFailedAttempt: fromPromise(
          async ({
            input: { context },
          }: {
            input: { context: GlobalCheckoutMachineContext };
          }) => {
            const { lastFailedAttempt } =
              context?.retailerCheckoutOrderStatus
                ?.userRetailerCheckoutSessionStatus ?? {};
            return { lastFailedAttempt };
          }
        ),
        retryLogin: fromPromise(
          async ({
            input: { context, event },
          }: {
            input: {
              context: GlobalCheckoutMachineContext;
              event: LoginSubmitEvent;
            };
          }) => {
            const { currentStamp, token } = (await getTOtp()) ?? {};
            const loginInfo = event.data.connectionInfo.credential;
            if (
              !token ||
              !loginInfo ||
              !context.activeCheckoutRetailerId ||
              !context.retailerIds
            ) {
              throw new Error("Invalid input");
            }
            const connectionInfo = encryptData(
              [
                {
                  retailerId: context.activeCheckoutRetailerId,
                  credential: {
                    username: loginInfo?.username,
                    password: loginInfo?.password,
                  },
                },
              ],
              token
            );
            await retryRetailerLoginDuringCheckout({
              orderId: context.activeCheckoutOrder?.orderId ?? "",
              currentStamp,
              connectionInfo,
            });
          }
        ),
        checkoutNextRetailer: fromPromise(
          async ({
            input: { context },
          }: {
            input: { context: GlobalCheckoutMachineContext };
          }) => {
            if (
              !context.activeCheckoutOrder?.orderId ||
              !context.activeCheckoutRetailerId
            ) {
              throw new Error("Invalid input");
            }
            await cancelCheckout({
              orderId: context.activeCheckoutOrder?.orderId,
              retailerId: context.activeCheckoutRetailerId,
            });
            const { checkoutBatch, retailerCheckoutOrderStatus } = context;
            if (!checkoutBatch?.retailerIds) {
              return;
            }
            const currentRetailerId =
              retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
                ?.retailerId ?? 0;
            const index = checkoutBatch?.retailerIds.indexOf(currentRetailerId);
            if (
              index === -1 ||
              index === checkoutBatch?.retailerIds?.length - 1
            ) {
              return;
            }
            const nextRetailerId = checkoutBatch?.retailerIds?.[index + 1];
            return {
              activeCheckoutRetailerId: nextRetailerId,
            };
          }
        ),
        waitForCheckoutSuccessDataLoad: fromPromise(
          async ({
            input: { context },
          }: {
            input: { context: GlobalCheckoutMachineContext };
          }) => {
            const { retailerCheckoutOrderStatus } = context;
            let interval: NodeJS.Timeout | undefined = undefined;
            try {
              await Promise.race([
                new Promise((resolve) => {
                  interval = setInterval(async () => {
                    const res = await serverSideSyncApolloClient.query<{
                      UserRetailerCheckoutOrders: SearchResult<UserPurchaseHistory>;
                    }>({
                      query: getUserRetailerCheckoutOrderDetailBreakup,
                      variables: {
                        checkoutBatchIds: [
                          retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
                            ?.checkoutBatchId,
                        ],
                        page: {
                          start: 0,
                          size: 10,
                        },
                      },
                      fetchPolicy: "network-only",
                    });
                    const summaryRetailers = _get(
                      res,
                      ["data", "UserRetailerCheckoutOrders", "items"],
                      []
                    );

                    const items = summaryRetailers.filter(
                      (d) =>
                        d.checkoutBatchId ===
                          retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
                            ?.checkoutBatchId && isPurchaseCheckoutSuccess(d)
                    );

                    if (items.length > 0) {
                      resolve(true);
                    }
                  }, 1e3);
                }),
                new Promise((resolve) => {
                  // resolve in 10 seconds
                  setTimeout(() => {
                    resolve(false);
                  }, 5e3);
                }),
              ]);
            } catch (e: any) {
              console.error(e);
            } finally {
              clearInterval(interval);
            }
          }
        ),
      },
      actions: {
        enterCheckoutFlow: ({ context }) => {
          context.navigate("entercheckout");
        },
        updateProductCards: assign(({ context }) => {
          const { activeCheckoutRetailerId, retailerIds } = context;
          const carts =
            Store.getState().checkout?.allCarts?.find(
              (item) => item.retailerId === activeCheckoutRetailerId
            )?.items || [];

          if (!retailerIds) {
            return context;
          }
          return {
            enterAnimationConfig: {
              title:
                retailerIds?.length > 1
                  ? `Preparing order ${
                      retailerIds?.indexOf(activeCheckoutRetailerId as number) +
                      1
                    } of ${retailerIds?.length}`
                  : `Preparing order`,
              checkoutCards: getStepFromCartProducts(carts),
            },
          };
        }),
        updateRetailerConnectionInfoList: assign(({ event }) => {
          const { retailerConnectionInfoList } = (
            event as InitiateCheckoutEvent
          ).data;
          return { retailerConnectionInfoList };
        }),
        updateRetailerConnectionInfoListFromRedux: assign(({ event }) => {
          const { retailerConnectionInfoList } = (
            event as UpdateRetailerConnectionInfoList
          ).data;
          return { retailerConnectionInfoList };
        }),
        updateActiveCheckoutRetailerId: assign(({ event }) => {
          const { retailerId } = (event as InitiateCheckoutEvent).data;
          return { activeCheckoutRetailerId: retailerId };
        }),
        updateUserRetailerCheckoutBatch: assign(({ event }) => {
          const { userRetailerCheckoutBatch } = (
            event as DoneActorEvent<{
              userRetailerCheckoutBatch: UserRetailerCheckoutBatch;
            }>
          ).output;
          Store.dispatch(setBatch(userRetailerCheckoutBatch));
          return { checkoutBatch: userRetailerCheckoutBatch };
        }),
        updateUserRetailerCheckoutOrder: assign(({ event }) => {
          const { userRetailerCheckoutOrder } = (
            event as DoneActorEvent<{
              userRetailerCheckoutOrder: UserRetailerCheckoutOrder;
            }>
          ).output;
          return { activeCheckoutOrder: userRetailerCheckoutOrder };
        }),
        updateRetailerCheckoutOrderStatus: assign(({ event }) => {
          const { retailerCheckoutOrderStatus } = (
            event as DoneActorEvent<{
              retailerCheckoutOrderStatus: RetailerCheckoutOrderStatus;
            }>
          ).output;
          return { retailerCheckoutOrderStatus };
        }),
        updateRetailerCheckoutOrderStatusFromPoller: assign(
          ({ context, event }) => {
            const { retailerCheckoutOrderStatus } = (
              event as CheckoutStatusChangeEvent
            ).data;
            const otpStatus = _get(
              retailerCheckoutOrderStatus,
              "retailerSyncSessions[0].eventSequence.type"
            );
            return {
              retailerCheckoutOrderStatus,
              otpStatus: otpStatus,
            };
          }
        ),
        updateRetailerIds: assign(({ event }) => {
          const { retailerIds } = (event as InitiateCheckoutEvent).data;
          return { retailerIds };
        }),
        updateError: assign(({ event }) => {
          const error = _get(event, "data.error");
          return { error };
        }),
        resetContext: assign(() => {
          return {
            activeCheckoutRetailerId: undefined,
            activeCheckoutOrder: undefined,
            retailerCheckoutOrderStatus: undefined,
            error: undefined,
            enterAnimationConfig: undefined,
          };
        }),
        updateCheckoutRetailerConnectionState: assign(({ context }) => {
          const { retailerCheckoutOrderStatus } = context;
          const { lastFailedAttempt } =
            retailerCheckoutOrderStatus?.userRetailerCheckoutSessionStatus ??
            {};
          if (lastFailedAttempt?.type === "login") {
            return {
              checkoutRetailerConnectionState: {
                retailerId:
                  retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
                    ?.retailerId,
                type: "RETRY_LOGIN",
                errorMessage: "please try again",
              } as GlobalCheckoutMachineContext["checkoutRetailerConnectionState"],
            };
          }
          return { checkoutRetailerConnectionState: undefined };
        }),
        resetCheckoutBatchId: assign(({ context }) => {
          Store.dispatch(setBatch(undefined));
          return {
            checkoutBatch: undefined,
          };
        }),
        resetAnimation: assign(() => {
          return { animationCompleted: false };
        }),
        updateAnimationComplete: assign(() => {
          return { animationCompleted: true };
        }),
        updateConnectionInfo: assign(({ context, event }) => {
          const { connectionInfo } = (event as LoginSubmitEvent).data;
          const { retailerConnectionInfoList = [] } = context;
          const index = retailerConnectionInfoList.findIndex(
            (info: any) => info.retailerId === connectionInfo.retailerId
          );

          if (index === -1) {
            retailerConnectionInfoList.push(connectionInfo);
          } else {
            retailerConnectionInfoList[index] = connectionInfo;
          }
          return {
            retailerConnectionInfoList: retailerConnectionInfoList ?? [],
          };
        }),
      },
      guards: {
        isRetailerNotConnected: ({ event }) => {
          const { retailerConnectionInfoList, retailerId } = (
            event as InitiateCheckoutEvent
          ).data;
          const isRetailerNotConnected = !retailerConnectionInfoList?.find(
            (info: any) =>
              info.retailerId === retailerId &&
              info.credential?.connectionStatus === "valid"
          );
          return isRetailerNotConnected;
        },
        isLoginError: ({ context }) => {
          return (
            context?.retailerCheckoutOrderStatus
              ?.userRetailerCheckoutSessionStatus?.lastFailedAttempt?.type ===
            "login"
          );
        },
        isCheckoutSummaryStage: ({ context, event }) => {
          const { retailerCheckoutOrderStatus } = (
            event as CheckoutStatusChangeEvent
          ).data;
          return !!(
            retailerCheckoutOrderStatus?.userRetailerCheckoutSessionStatus
              ?.status === "checkout_waiting_for_user_input" &&
            context?.animationCompleted
          );
        },
        isAnimationCompleteAndCheckoutSummaryStage: ({ context, event }) => {
          return !!(
            context.retailerCheckoutOrderStatus
              ?.userRetailerCheckoutSessionStatus?.status ===
              "checkout_waiting_for_user_input" &&
            event?.type === "ON_CHECKOUT_ANIMATION_COMPLETE"
          );
        },
        isCheckoutSuccessStage: ({ event }) => {
          const { retailerCheckoutOrderStatus } = (
            event as CheckoutStatusChangeEvent
          ).data;
          return (
            retailerCheckoutOrderStatus?.userRetailerCheckoutOrder?.status ===
            "checkout_success"
          );
        },
        isCheckoutErrorStage: ({ event }) => {
          const { retailerCheckoutOrderStatus } = (
            event as CheckoutStatusChangeEvent
          ).data;
          return (
            retailerCheckoutOrderStatus?.userRetailerCheckoutOrder?.status ===
            "checkout_error"
          );
        },
        isRetryableFailureAttempt: ({ event }) => {
          const { retailerCheckoutOrderStatus } = (
            event as CheckoutStatusChangeEvent
          )?.data;
          return !!(
            retailerCheckoutOrderStatus?.userRetailerCheckoutSessionStatus
              ?.lastFailedAttempt?.type &&
            retailerCheckoutOrderStatus?.userRetailerCheckoutSessionStatus
              ?.status === "checkout_waiting_for_user_input"
          );
        },
        isRetryLoginState: ({ context }) => {
          return (
            context?.retailerCheckoutOrderStatus
              ?.userRetailerCheckoutSessionStatus?.lastFailedAttempt?.type ===
            "login"
          );
        },
        isCheckoutSuccessStageAndNextRetailerAvailable: ({
          context,
          event,
        }) => {
          const { checkoutBatch } = context;
          const { retailerCheckoutOrderStatus } = (
            event as CheckoutStatusChangeEvent
          ).data;
          if (
            retailerCheckoutOrderStatus?.userRetailerCheckoutOrder?.status !==
            "checkout_success"
          ) {
            return false;
          }
          if (!checkoutBatch?.retailerIds) {
            return false;
          }
          const currentRetailerId =
            retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
              ?.retailerId ?? 0;
          const index = checkoutBatch?.retailerIds.indexOf(currentRetailerId);
          if (
            index === -1 ||
            index === checkoutBatch?.retailerIds?.length - 1
          ) {
            return false;
          }
          return true;
        },
      },
    }
  );
})();
