import { StarIcon, StarOutlineIcon } from "@brandclub/common-ui";
import { Rating, styled } from "@mui/material";
import React from "react";

const StyledRating = styled(Rating)({
  // '& .MuiRating-iconFilled': {
  //   color: '#ff6d75',
  // },

  "& .MuiRating-iconHover": {
    transform: "scale(1)",
  },

  opacity: "1 !important",
});

const FiveStarRating = ({
  value,
  setValue = (v) => {},
  size = 23,
  disabled = false,
}: {
  value?: number;
  setValue?: (v: number) => void;
  size?: number;
  disabled?: boolean;
}) => {
  return (
    <StyledRating
      name="customized-color"
      defaultValue={0}
      precision={1}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue ?? 0);
      }}
      disabled={disabled}
      icon={
        <StarIcon
          color="primary"
          sx={{ fontSize: size, marginRight: `${(size * 19) / 35 / 2}px` }}
        />
      }
      emptyIcon={
        <StarOutlineIcon
          color="primary"
          sx={{ fontSize: size, marginRight: `${(size * 19) / 35 / 2}px` }}
        />
      }
    />
  );
};

export default FiveStarRating;
