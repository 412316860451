import { styled } from "@mui/material";
import React from "react";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../../AppNavigation/constants";
import { ResponseOptionsMetaData } from "../../../../../../types/misc";
import { CheckCircleIcon } from "@brandclub/common-ui";

const RowContainer = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#f6f8fa",

  cursor: "pointer",
  display: "flex",
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {},

  alignItems: "center",
  ".row_text": {
    maxWidth: "100%",
    width: "100%",
    borderRadius: 8,
    objectFit: "cover",
    "&.square": {
      aspectRatio: 1,
    },
    "&.rectangleHorizontal": {
      aspectRatio: 2,
    },
    "&.rectangleVertical": {
      aspectRatio: 0.5,
    },

    color: theme.palette.primary.main,
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 16,
    },
  },
  ".indicator": {
    marginLeft: 10,
    width: 23,
    height: 23,
    objectFit: "contain",
    position: "absolute",
    right: -7,
    bottom: -4,
    zIndex: 1,
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      width: 18,
      height: 18,
      right: -4,
      bottom: -3,
    },
  },

  "&.disabled": {
    ".row_text": {
      opacity: 0.5,
    },
  },
}));

const SurveyImageSelect = ({
  mode,
  imageUrl,
  onClick,
  selected = false,
  disabled = false,
}: {
  mode: ResponseOptionsMetaData["layoutMode"];
  imageUrl: string;
  onClick: () => void;
  selected?: boolean;
  disabled?: boolean;
}) => {
  return (
    <RowContainer className={disabled ? "disabled" : ""} onClick={onClick}>
      <img alt="" src={imageUrl} className={`row_text ${mode}`}></img>
      {selected ? (
        <CheckCircleIcon
          className="indicator"
          htmlColor="#ffffff"
          backgroundColor="primary"
        />
      ) : null}
    </RowContainer>
  );
};

export default SurveyImageSelect;
