// HELPER PATTERNS
const EMAIL_PATTERN =
  /(e(-)?mail( address)?|correo electrónico|email address|electronic mail|e-mail address)/;
const PASSWORD_PATTERN =
  /(password|contraseña|passcode|passphrase|secret|clave)/;
const INVALID_PATTERN =
  /(invalid|incorrect|wrong|invalido|incorrecto|incorrecta|incorrectamente|wrongly|invalidly|invalida|invalidamente)/;
const VALID_PATTERN =
  /(valid|correct|right|válido|correcto|correcta|correctamente|rightly|correctly|válida|correctamente)/;
const VALID_OR_INVALID_PATTERN = new RegExp(
  `(${VALID_PATTERN.source}|${INVALID_PATTERN.source})`
);
const FIND_PATTERN = /(find|locate|encontrar)/;
const UNABLE_TO_PATTERN =
  /((cannot|could not|couldn't|can't)|(unable|failed) to|no se puede|no pudimos|(we )?(were not|weren't) able to)/;
const PHONE_PATTERN =
  /(phone|(numero de )?teléfono|phone number|telephone|cellphone|cell phone|mobile phone|mobile|celular|teléfono móvil)/;
const DEACTIVATED_PATTERN =
  /(deactivated|desactivado|desactivada|desactivar|desactivarse|desactivada|desactivado|desactivar|desactivarse|deleted|eliminado|eliminada|eliminar|eliminarse|eliminada|eliminado|eliminar|eliminarse|removed|removido|removida|remover|removerse|removida|removido|remover|removerse)/;
const UI_ELEMENT_PATTERN =
  /(\s?#\w+\s?|\binput\b|\belement\b|\bdiv\b|\bbutton(s)?\b|\b(email|password) field\b)/;
const CANNOT_BE_USED_PATTERN =
  /((can't|cannot) be used|no se puede usar|no se puede utilizar)/;
const FAILED_TO_FIND_ACCOUNT_PATTERN = new RegExp(
  `(\\b(cannot|can't|${UNABLE_TO_PATTERN.source})\\b \\b${FIND_PATTERN.source}\\b .* \\baccount\\b)`
);
const MUST_HAVE_PATTERN =
  /(must (contain|consist of|have)|debe tener|debería tener|should have)/;

/**
 * ------------------------------------------------------
 *                USER ERROR PATTERNS
 *
 * Tip: If you encounter conflicts or unexpected matches,
 * try listing patterns from the most specific to the least specific
 * to reduce false positives.
 * ------------------------------------------------------
 */
export const USER_ERRORS = [
  // OTP errors
  {
    pattern:
      /wait .* requesting .*\s?(new|another) \b(code|otp|token)\b|too many otp(s)?/i,
    category: "User Error",
    subcategory: "OTP Exceeded Attempts Error",
  },
  {
    pattern:
      /\b(code|mfa|otp) .* requested\b|badotpinput|mfa input not received|emptyotptext|token expired|verification code/i,
    category: "User Error",
    subcategory: "OTP Error",
  },
  // Credential errors
  {
    pattern: /resetpassworderror/i,
    category: "User Error",
    subcategory: "Reset Password Error",
  },
  {
    pattern: new RegExp(`(add|missing) recovery ${PHONE_PATTERN.source}`, "i"),
    category: "User Error",
    subcategory: "Missing Recovery Credentials",
  },
  {
    pattern: new RegExp(
      `(enter|provide|escriba) (your|an|su|a)?\\s?(${EMAIL_PATTERN.source}|${PASSWORD_PATTERN.source})`,
      "i"
    ),
    category: "User Error",
    subcategory: "Missing Credentials",
  },
  {
    pattern: new RegExp(
      `credentials.*${VALID_OR_INVALID_PATTERN.source}|badlogin|(${EMAIL_PATTERN.source}|${PASSWORD_PATTERN.source}) (and/or|and|or) (${PASSWORD_PATTERN.source}|${EMAIL_PATTERN.source})|logincredentialerror|\\b${UNABLE_TO_PATTERN.source}\\b.*\\b${FIND_PATTERN.source}\\b.*\\baccount\\b`,
      "i"
    ),
    category: "User Error",
    subcategory: "Invalid Credentials",
  },
  {
    pattern: new RegExp(
      `${INVALID_PATTERN.source} ${PHONE_PATTERN.source}|${PHONE_PATTERN.source} .* ${INVALID_PATTERN.source}|${PHONE_PATTERN.source} .* ${CANNOT_BE_USED_PATTERN.source}|${FAILED_TO_FIND_ACCOUNT_PATTERN.source} .* ${PHONE_PATTERN.source}`,
      "i"
    ),
    category: "User Error",
    subcategory: "Invalid Phone",
  },
  {
    pattern: /enter the country code/i,
    category: "User Error",
    subcategory: "Missing Phone Country Code",
  },
  {
    pattern: new RegExp(
      `${VALID_OR_INVALID_PATTERN.source} .*\\s?${PASSWORD_PATTERN.source}|${PASSWORD_PATTERN.source} .*\\s?${VALID_OR_INVALID_PATTERN.source}|${PASSWORD_PATTERN.source} .*\\s?required|${PASSWORD_PATTERN.source} ${MUST_HAVE_PATTERN.source}`,
      "i"
    ),
    category: "User Error",
    subcategory: "Incorrect Password",
  },
  {
    pattern: new RegExp(
      `\\b${VALID_OR_INVALID_PATTERN.source}\\b.*\\b${EMAIL_PATTERN.source}\\b|\\b${EMAIL_PATTERN.source}\\b.*\\b${VALID_OR_INVALID_PATTERN.source}\\b|\\b${EMAIL_PATTERN.source}\\b.*\\brequired\\b|\\b${EMAIL_PATTERN.source}\\b.*\\bformat\\b|\\b${FAILED_TO_FIND_ACCOUNT_PATTERN.source}\\b.*\\b${EMAIL_PATTERN.source}\\b|${UNABLE_TO_PATTERN.source} ${FIND_PATTERN.source} .* ${EMAIL_PATTERN.source}|problem .* entered ${EMAIL_PATTERN.source}`,
      "i"
    ),
    category: "User Error",
    subcategory: "Incorrect Email",
  },
  // Account errors
  {
    pattern:
      /nomembership|user .* bi member|membership.*exist|proaccounterror/i,
    category: "User Error",
    subcategory: "Invalid Membership",
  },
  {
    pattern:
      /\baccount\b.*\b(locked|closed|misuse|blocked)\b|\b(access|lock|close)(ed)?\b.*\baccount\b|\breactivate\b.*\baccount\b/i,
    category: "User Error",
    subcategory: "Account Status",
  },
  {
    pattern: new RegExp(
      `duplicate ${EMAIL_PATTERN.source}|user .* already exists|account .* used .* user|(${EMAIL_PATTERN.source}|${PHONE_PATTERN.source}) .* associated .* (different|another) account|User already exist`,
      "i"
    ),
    category: "User Error",
    subcategory: "Duplicate Account",
  },
  {
    pattern: new RegExp(
      `${DEACTIVATED_PATTERN.source}.*${EMAIL_PATTERN.source}|${EMAIL_PATTERN.source}.*${DEACTIVATED_PATTERN.source}`,
      "i"
    ),
    category: "User Error",
    subcategory: "Deactivated Account",
  },
  {
    pattern: /verify.*you|trouble verifying/i,
    category: "User Error",
    subcategory: "Verify Account",
  },
] as const;

export const INTERNAL_ERRORS = [
  // Type errors
  {
    pattern: /\binvalid event\b/i,
    category: "Internal Error",
    subcategory: "Invalid Event Error",
  },
  {
    pattern: /syntax\s?error/i,
    category: "Internal Error",
    subcategory: "Syntax Error",
  },
  {
    pattern:
      /cannot read property|cannot destructure property|read properties.*(null|undefined)|(undefined|null) is not a(n)?|destructure property|not valid json|cannot be serialized|empty array|not a function/i,
    category: "Internal Error",
    subcategory: "Type Error",
  },
  // Connection errors
  {
    pattern: /client .* not (connected|found)/i,
    category: "Internal Error",
    subcategory: "Client Connection",
  },
  {
    pattern: /timeout|timed out/i,
    category: "Internal Error",
    subcategory: "Timeout",
  },
  // UI element errors
  {
    pattern: new RegExp(
      `${UI_ELEMENT_PATTERN.source}\\s?not\\s?found|(cannot|could not|unable( to)?) (find|locate) ${UI_ELEMENT_PATTERN.source}|no ${UI_ELEMENT_PATTERN.source} found|form button|not\\s?(clickable|clicked)|not .* \\belement\\b|failed.*click(ing)?|(unrecognized|waiting).*selector|(no)?\\s?(parser|selector)(not)?\\s?found|not pressed|data-testid|signinbuttonnotfound`,
      "i"
    ),
    category: "Internal Error",
    subcategory: "UI Element Error",
  },
  // OTP errors
  {
    pattern: /can't solve \b(mfa|otp)\b|failed to set otp/i,
    category: "Internal Error",
    subcategory: "OTP Error",
  },
  // Service errors
  {
    pattern: /(service|login).*unavailable/i,
    category: "Internal Error",
    subcategory: "Service Unavailable",
  },
  // Retry errors
  {
    pattern: /retry.*fail(ed)?/i,
    category: "Internal Error",
    subcategory: "Retry Fail",
  },
  // Script errors
  {
    pattern: /unable to set script|script_start/i,
    category: "Internal Error",
    subcategory: "Script Error",
  },
  // Customer sync order lookup errors
  {
    pattern: /customersyncorderlookup/i,
    category: "Internal Error",
    subcategory: "CustomerSyncOrderLookup Error",
  },
  // Puppeteer errors
  {
    pattern: /puppeteer|puppeteerbrowserpage|puppet bridge|lock not acquired/i,
    category: "Internal Error",
    subcategory: "Puppeteer Error",
  },
  // Command errors
  {
    pattern: /command failed/i,
    category: "Internal Error",
    subcategory: "Command Failure",
  },
  {
    pattern: /signuppage|failedtoonboard|createnewaccounterror/i,
    category: "Internal Error",
    subcategory: "SignUp Error",
  },
  // Network errors
  {
    pattern:
      /ssl|tls|protocol error|network error|\bconnection\b|failed to fetch|err_connection|socket hang up|econnrefused|econnreset|etimedout|net::.*|url for port|proxy|mitmproxy|ts header|session .* not defined|request failed with status code \d+/i,
    category: "Internal Error",
    subcategory: "Network Error",
  },
  {
    pattern:
      /(login|sign in|authorisation)\s?(is )?(required|failed)|signoutrequired|login error|loginpageloading|logging in\.\.\.|login: |sign in to |login page loading|authentication failed|failed to authenticate/i,
    category: "Internal Error",
    subcategory: "Auth Server Error",
  },
  // Navigation errors
  {
    pattern: /captcha/i,
    category: "Internal Error",
    subcategory: "Captcha",
  },
  {
    pattern: /bot|automation detected/i,
    category: "Internal Error",
    subcategory: "Bot Detection",
  },
  {
    pattern: /permission|denied|unauthorized/i,
    category: "Internal Error",
    subcategory: "Authorization Error",
  },
  {
    pattern:
      /failed to (launch|open .* tab)|(navigation|launch|webview) failed|browser (process|plugin)|\bbrowser\b.*not found|invalid url|browserrelaunchrequired|error.*navigation|navigation.*error|chrome error|navigating.*detached|navigation .* not complete|url changed|requesting main frame too early|because .* navigation/i,
    category: "Internal Error",
    subcategory: "Launch/Navigation",
  },
  {
    pattern: /failed to load page|err_empty_response|load failed/i,
    category: "Internal Error",
    subcategory: "Page Load Failure",
  },
  // Data retrieval errors
  {
    pattern: /failed to retrieve|failed while retrieving/i,
    category: "Internal Error",
    subcategory: "Data Retrieval Failure",
  },
  // Purchase history errors
  {
    pattern: /error .* purchase history/i,
    category: "Internal Error",
    subcategory: "Purchase History",
  },
  // Elastic search errors
  {
    pattern: /\belasticsearch\b/i,
    category: "Internal Error",
    subcategory: "Elastic Search",
  },
  // AWS errors
  {
    pattern: /\bupdatetable api\b/i,
    category: "Internal Error",
    subcategory: "DynamoDB Error",
  },
  // Unknown device errors
  {
    pattern: /device id format mismatch/i,
    category: "Internal Error",
    subcategory: "Unknown Device Error",
  },
] as const;

export const UNEXPECTED_ERRORS = [
  {
    pattern:
      /unexpectedresponse|unexpected error|unexpected response|server (error|response)|internal error/i,
    category: "Unexpected Error",
    subcategory: "Unexpected Response",
  },
  {
    pattern:
      /something went wrong|technical (issue|difficulties)|error occurred|having trouble signing you in/i,
    category: "Unexpected Error",
    subcategory: "Retailer Unexpected Error",
  },
] as const;

// Combine all error patterns with INTERNAL_ERRORS first as we default to internal errors if there are conflicts in the matched patterns
export const ERROR_PATTERNS = [
  ...INTERNAL_ERRORS,
  ...UNEXPECTED_ERRORS,
  ...USER_ERRORS,
];

export type ErrorMatch = typeof ERROR_PATTERNS[number];
export type ErrorCategory = ErrorMatch["category"];
export type ErrorSubcategory = ErrorMatch["subcategory"];
export type UserErrorMatch = Extract<ErrorMatch, { category: "User Error" }>;
export type InternalErrorMatch = Extract<
  ErrorMatch,
  { category: "Internal Error" }
>;
export type UnexpectedErrorMatch = Extract<
  ErrorMatch,
  { category: "Unexpected Error" }
>;
export type UserErrorSubcategory = UserErrorMatch["subcategory"];
export type InternalErrorSubcategory = InternalErrorMatch["subcategory"];
export type UnexpectedErrorSubcategory = UnexpectedErrorMatch["subcategory"];

export const getErrorCategory = (error: string): ErrorMatch | undefined => {
  return ERROR_PATTERNS.find((pattern) => pattern.pattern.test(error));
};
