import LongProductCard from "../../../../StoreComponents/StoreCard.tsx/LongProductCard";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import { LongCardGrid } from "../components/CardGrid";
import { PreviewHeader } from "../components/Header";
import PreviewWidgetWrapper from "../components/PreviewWidgetWrapper";
import { useFetchRecentlyViewedProducts } from "./data";
import {
  RECENTLY_VIEWED_LANDING_ROUTE,
  RECENTLY_VIEWED_SUBTITLE,
  RECENTLY_VIEWED_TITLE,
} from "./text";
import { useEffect, useRef } from "react";
import { useInView } from "framer-motion";
import PreviewContainer from "../components/PreviewContainer";
import { defaultPreviewLazyLoadConfig } from "../../../../../utils/lazyLoadConfig";

interface RecentlyViewedPreviewProps {
  brandIds?: number[];
}
const RecentlyViewedPreview = (props: RecentlyViewedPreviewProps) => {
  const ref = useRef(null);
  const widgetInView = useInView(ref, defaultPreviewLazyLoadConfig);
  const { data, loading, fetchInit } = useFetchRecentlyViewedProducts({
    pageSize: 8,
    autoInitFetch: false,
    brandIds: props.brandIds,
  });

  useEffect(() => {
    if (widgetInView) {
      fetchInit();
    }
  }, [widgetInView, fetchInit]);

  if (!loading && data.length === 0) {
    return null;
  }

  return (
    <PreviewWidgetWrapper ref={ref}>
      <PreviewContainer>
        {loading ? (
          <StoreLoadingSkeleton header cardStyle="longCard" row={4} />
        ) : (
          <>
            <PreviewHeader
              title={RECENTLY_VIEWED_TITLE}
              linkTo={RECENTLY_VIEWED_LANDING_ROUTE}
              subtitle={RECENTLY_VIEWED_SUBTITLE}
              indicator="grey"
            />
            <LongCardGrid>
              {data.map((d) => (
                <LongProductCard key={d.stacklineSku} product={d} />
              ))}
            </LongCardGrid>
          </>
        )}
      </PreviewContainer>
    </PreviewWidgetWrapper>
  );
};

export default RecentlyViewedPreview;
