import { styled } from "@mui/material";
import { SolidButton } from "../../../StoreComponents/StoreButton";
import { TABLET_SCREEN_SIZE } from "../../../AppNavigation/constants";

const AuthSolidButton = styled(SolidButton)({
  width: "100%",
  height: 50,
  fontSize: 16,
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    fontSize: 14,
  },
});

export default AuthSolidButton;
