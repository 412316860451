import { gql } from "@apollo/client";

export enum ProductOperation {
  UnauthenticatedSearch = "UnauthenticatedSearchProducts",
  AuthenticatedSearch = "SearchProducts",
}

export const UNAUTHENTICATED_SEARCH_PRODUCTS = gql`
  query UnauthenticatedSearchProducts(
    $titleInclude: [String!]
    $titleExclude: [String!]
    $stacklineSkus: [String!]
    $stacklineSkusExclude: [String!]
    $campaignTypes: [String!]
    $retailPrice: [RangeFilter!]
    $reviewsRating: RangeFilter
    $reviewsCount: RangeFilter
    $brandIds: [Float!]
    $departmentIds: [Float!]
    $categoryIds: [Float!]
    $subCategoryIds: [Float!]
    $retailerIds: [Float!]
    $isBrandClubReady: Boolean
    $totalRewardAmount: RangeFilter
    $page: PageFilter!
    $sort: [SortFilter!]
    $facetFieldNames: [String!]
    $computeFacets: Boolean!
    $applySpellingCheck: Boolean
    $treatment: TreatmentFilter
  ) {
    ${ProductOperation.UnauthenticatedSearch}(
      titleInclude: $titleInclude
      titleExclude: $titleExclude
      stacklineSkus: $stacklineSkus
      stacklineSkusExclude: $stacklineSkusExclude
      campaignTypes: $campaignTypes
      retailPrice: $retailPrice
      reviewsRating: $reviewsRating
      totalRewardAmount: $totalRewardAmount
      reviewsCount: $reviewsCount
      brandIds: $brandIds
      categoryIds: $categoryIds
      departmentIds: $departmentIds
      subCategoryIds: $subCategoryIds
      retailerIds: $retailerIds
      isBrandClubReady: $isBrandClubReady
      page: $page
      sort: $sort
      facetFieldNames: $facetFieldNames
      computeFacets: $computeFacets
      applySpellingCheck: $applySpellingCheck
      treatment: $treatment
    ) {
      products {
        affiliateRewardAmount
        brandId
        hasActiveInstantReward
        imageUrls
        retailerId
        retailerSku
        retailPrice
        checkoutEligibility
        stacklineSku
        title
        totalRewardAmount
        isAvailable
        hasFirstPartyOffer
        offers
        scores {
          maxPurchaseRewardPercent
          maxPurchaseRewardAmount
          maxAffiliateRewardPercent
          maxAffiliateRewardAmount
          totalReviewRewardFeesAmount
        }
        matchedProducts {
          retailPrice
          retailerId
          retailerProductPageUrl
          totalRewardAmount
          stacklineSku
          retailerSku
          checkoutEligibility
          isAvailable
          hasFirstPartyOffer
          offers
        }
        campaignRewards {
          campaignStatus
          campaignType
          rewardAmount
          rewardAmountType
        }
      }
      totalProducts
      facets
      suggestedTerm
    }
  }
`;
