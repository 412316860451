import { useEffect, useMemo, useRef } from "react";
import { useFetchPurchase } from "./data";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import LandingContainer from "../../Pages/components/LandingContainer";
import { LandingHeader } from "../../Pages/components/Header";
import { useInView } from "framer-motion";
import { LongCardList } from "../../Pages/components/CardGrid";
import PurchaseListRow from "./PurchaseListRow";
import { styled } from "@mui/material";
import { DashboardStoreMessageCard } from "../../../../StoreComponents/StoreCard.tsx/DashboardStoreMessageCard";
import { isItemCheckoutSuccess } from "../../../../Checkout/utils";
import { useAppSelector } from "../../../../../redux/hooks";
import { useSiteContext } from "../../../../SiteProvider";
import { TABLET_SCREEN_SIZE } from "../../../../AppNavigation/constants";
import { SiteBrandingType, StoreBrandingType } from "../../../../../types/misc";

const ListContainer = styled(LongCardList)({
  ".row + .row": {
    borderTop: "1px solid #dcdddc",
  },
  gridGap: "0",
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    gridGap: 0,
  },
});

const PurchaseList = () => {
  const appConfig = useAppSelector(({ appConfig }) => appConfig);

  const linkTo =
    appConfig?.domainConfig?.storeBrandingType ===
      StoreBrandingType.CustomDTCStore &&
    appConfig?.domainConfig?.customShopDomainName
      ? `https://${appConfig?.domainConfig?.customShopDomainName}/`
      : `/trending`;

  const { siteBrandingType } = useSiteContext();
  const mainEntity = useAppSelector((state) => state.mainEntity);
  const brandIds = useMemo(
    () =>
      (siteBrandingType === SiteBrandingType.BrandStore ||
        siteBrandingType === SiteBrandingType.CustomStore) &&
      mainEntity &&
      mainEntity.type === "brand"
        ? [mainEntity.id]
        : [],
    [mainEntity, siteBrandingType]
  );
  const { data, loading, loadingMore, fetchMore, hasMore } = useFetchPurchase({
    pageSize: 5,
    brandIds,
  });

  const successfulPurchases = useMemo(
    () => data.filter((d) => isItemCheckoutSuccess(d)),
    [data]
  );

  const ref = useRef<any>(null);
  const widgetInView = useInView(ref);

  useEffect(() => {
    if (widgetInView && !loading && !loadingMore && hasMore) {
      fetchMore();
    }
  }, [widgetInView, loading, loadingMore, hasMore, fetchMore]);

  return (
    <>
      <LandingContainer sx={{ paddingTop: 0 }}>
        {loading ? (
          <StoreLoadingSkeleton cardStyle="longCardList" header row={1} />
        ) : (
          <>
            <LandingHeader title="Purchases" marginBottom={10} />
            {successfulPurchases.length ? (
              <ListContainer>
                {successfulPurchases.map((d) => (
                  <PurchaseListRow item={d} key={`${d.orderId}-${d.id}`} />
                ))}
              </ListContainer>
            ) : (
              <DashboardStoreMessageCard
                title="You haven’t made any purchases yet."
                content="Can we make some suggestions to help you get started?"
                cta="Shop popular products"
                linkTo={linkTo}
              />
            )}
          </>
        )}
        {loadingMore ? (
          <div style={{ marginTop: 20 }}>
            <StoreLoadingSkeleton
              cardStyle="longCardList"
              row={1}
              header={false}
            />
          </div>
        ) : null}
        <div className="end_detect" ref={ref}></div>
      </LandingContainer>
    </>
  );
};

export default PurchaseList;
