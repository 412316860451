import { styled } from "@mui/material";
import AffiliatePreview from "../Affiliate/preview";
import ClubInvitesPreview from "../ClubInvites/preview";
import ContentPreview from "../Content/preview";
import DailyDealsPreview from "../DailyDeals/preview";
import MyClubsPreview from "../MyClubs/preview";
import ReviewPreview from "../Review/preview";
import SurveysPreview from "../Survey/preview";
import TrendingPreview from "../Trending/preview";
import LandingPageWrapper from "./LandingPageWrapper";
import { useAppSelector } from "../../../../../redux/hooks";
import { getBrandIds } from "@brandclub/common-ui";
import { useMemo } from "react";
import { StoreBrandingType } from "../../../../../types/misc";

const RewardEmptyContainer = styled("div")({
  width: "100%",
  padding: "60px 0 30px 0",
});

const EmptyHeaderContainer = styled("div")(({ theme }) => ({
  ".title": {
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginBottom: 10,
  },
  ".subtitle": {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
}));

const EmptyHeader = ({ title }: { title: string }) => {
  return (
    <LandingPageWrapper>
      <RewardEmptyContainer>
        <EmptyHeaderContainer>
          <div className="title">{title}</div>
          <div className="sutitle">
            Explore more ways to engage and earn with Brandclub
          </div>
        </EmptyHeaderContainer>
      </RewardEmptyContainer>
    </LandingPageWrapper>
  );
};
const RewardEmptyPage = ({ emptyTitle }: { emptyTitle: string }) => {
  const appConfig = useAppSelector((state) => state.appConfig);
  const brandEntity = useAppSelector(({ brandEntity }) => brandEntity);
  const brandClubIds = useMemo(
    () =>
      brandEntity?.entity?.brandclubId != null
        ? [brandEntity.entity.brandclubId]
        : undefined,
    [brandEntity?.entity?.brandclubId]
  );

  const brandIds = useMemo(
    () => getBrandIds(brandEntity?.entity),
    [brandEntity?.entity]
  );
  return (
    <>
      <EmptyHeader title={emptyTitle} />
      <DailyDealsPreview />
      <ReviewPreview brandIds={brandIds} arrowColor="grey"/>
      <AffiliatePreview brandIds={brandIds} />
      {appConfig?.domainConfig?.storeBrandingType ===
        StoreBrandingType.UniversalStore && <MyClubsPreview />}
      <ContentPreview brandclubIds={brandClubIds} />
      {appConfig?.domainConfig?.storeBrandingType ===
        StoreBrandingType.UniversalStore && (
        <ClubInvitesPreview brandIds={brandIds} />
      )}
      <SurveysPreview brandclubIds={brandClubIds} />
      <TrendingPreview brandIds={brandIds} />
    </>
  );
};

export default RewardEmptyPage;
