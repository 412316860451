import { useRef, useEffect, useMemo, useCallback } from "react";
import {
  AnalyticsService as analytics,
  AnalyticsTrackingEvent,
} from "@brandclub/common-ui";
import { useLocationTrackingParams } from "../../components/providers/LocationTrackingProvider";
import { useLocation } from "react-router-dom";

const AnalyticsService = {
  track: (eventName: AnalyticsTrackingEvent, data: any) => {
    return analytics.track(eventName, data).catch((error: any) => {
      console.error("Error tracking event", error);
    });
  },
};

interface AdditionalData {
  [key: string]: any;
}

/**
 * useTrackViewEvent: Tracks a view event only if the event hasn't been tracked or has expired.
 * The event is only tracked once per page until the expiry time (in seconds) passes.
 */
export const useTrackViewEvent = <T = Record<string, unknown>>(
  eventName: AnalyticsTrackingEvent,
  expiryInSeconds = 5
) => {
  const [trackingParams] = useLocationTrackingParams(); // Custom hook for additional tracking params
  const location = useLocation(); // Get the current URL/location
  const previousLocation = useRef(location.pathname); // Reference to the previous location
  const expiryInMs = expiryInSeconds * 1000; // Convert expiry to milliseconds
  const timerRef = useRef<number | null>(null); // Reference to the timer

  const changedPath = useMemo(
    () =>
      location.pathname !== previousLocation.current
        ? location.pathname
        : undefined,
    [location.pathname]
  );
  useEffect(() => {
    if (changedPath) {
      previousLocation.current = changedPath;
      if (timerRef.current) {
        // Reset the timer and previous location on page change
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    }
    return () => {
      timerRef.current && clearTimeout(timerRef.current); // Clear the timer on unmount
      timerRef.current = null;
    };
  }, [changedPath]);

  // Create an instance of EventTracker for the current page and event
  const eventTracker = new EventTracker(eventName, location.pathname);

  return (additionalData: T) => {
    // If the event hasn't been tracked or has expired, track the event
    if (changedPath && !eventTracker.isTracked(expiryInMs) && trackingParams) {
      timerRef.current && clearTimeout(timerRef.current); // Clear the timer if it exists
      timerRef.current = window.setTimeout(() => {
        eventTracker.markAsTracked(); // Mark event as tracked
        AnalyticsService.track(eventName, {
          ...trackingParams,
          ...additionalData,
        });
        timerRef.current = null;
      }, 1000); // Delay tracking by 1 second
    }
  };
};

export const useTrackActions = () => {
  const [trackingParams] = useLocationTrackingParams();
  const additionDataRef = useRef<AdditionalData>({});

  const trackAction = useCallback(
    (
      eventName: AnalyticsTrackingEvent,
      additionalData: AdditionalData = {}
    ) => {
      AnalyticsService.track(eventName, {
        ...trackingParams,
        ...additionDataRef.current,
        ...additionalData,
      });
    },
    [trackingParams]
  );

  const updateAdditionalData = useCallback((additionData: AdditionalData) => {
    additionDataRef.current = { ...additionDataRef.current, ...additionData };
  }, []);

  return [trackAction, updateAdditionalData] as const;
};

// In-memory local storage implementation using Map
const inMemoryLocalStorage = (): typeof localStorage => {
  const data = new Map<string, string>();
  return {
    getItem: (key: string) => data.get(key) || null,
    setItem: (key: string, value: string) => data.set(key, value),
    removeItem: (key: string) => data.delete(key),
    clear: () => data.clear(),
    length: data.size,
    key: (index: number) => Array.from(data.keys())[index],
  };
};

const storage = inMemoryLocalStorage();
class EventTracker {
  private storageKey: string;

  constructor(eventName: string, path: string) {
    this.storageKey = this.getStorageKey(eventName, path);
  }

  // Generate a unique key based on the event name and path
  private getStorageKey(eventName: string, path: string): string {
    return `tracked_${eventName}_${path}`;
  }

  // Check if the event has been tracked and if the tracking has expired
  public isTracked(expiryInMs: number): boolean {
    const trackedData = storage.getItem(this.storageKey);

    if (trackedData) {
      try {
        const { trackedAt } = JSON.parse(trackedData);
        const timeDiff = Date.now() - trackedAt;

        // If the event has expired, remove tracking info
        if (timeDiff > expiryInMs) {
          this.removeTracking();
          return false;
        }
        return true; // Event is still valid
      } catch (e) {
        // In case of JSON parse error, assume data is corrupted and remove it
        this.removeTracking();
        return false;
      }
    }

    return false; // Event hasn't been tracked yet
  }

  // Mark the event as tracked by storing the current timestamp
  public markAsTracked(): void {
    const eventData = { trackedAt: Date.now() };
    storage.setItem(this.storageKey, JSON.stringify(eventData));
  }

  // Remove the tracked event data
  private removeTracking(): void {
    storage.removeItem(this.storageKey);
  }
}
