import { Link } from "react-router-dom";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import { CardGrid } from "../components/CardGrid";
import { PreviewHeader } from "../components/Header";
import PreviewWidgetWrapper from "../components/PreviewWidgetWrapper";
import { useFetchTrendingProduct } from "./data";
import { useRef, useEffect } from "react";
import { useInView } from "framer-motion";
import PreviewContainer from "../components/PreviewContainer";
import { defaultPreviewLazyLoadConfig } from "../../../../../utils/lazyLoadConfig";
import ProductCard from "../../../../StoreComponents/StoreCard.tsx/ProductCard";
import { useResponsiveData } from "../../../../../utils/hooks/useResponsiveData";

const TrendingPreview = ({ brandIds }: { brandIds?: number[] }) => {
  const ref = useRef(null);

  const widgetInView = useInView(ref, defaultPreviewLazyLoadConfig);
  const { data, loading, fetchInit } = useFetchTrendingProduct({
    brandIds,
    pageSize: 10,
    autoInitFetch: false,
  });

  useEffect(() => {
    if (widgetInView) {
      fetchInit();
    }
  }, [widgetInView, fetchInit]);

  const responsiveData = useResponsiveData(data, 6);

  if (!loading && data.length === 0) {
    return null;
  }

  return (
    <PreviewWidgetWrapper ref={ref}>
      <PreviewContainer>
        {loading ? (
          <StoreLoadingSkeleton header />
        ) : (
          <>
            <PreviewHeader
              title={"Trending"}
              linkTo="/trending"
              subtitle="Popular products with great rewards"
              indicator="grey"
            />

            <CardGrid>
              {responsiveData.map((p) => (
                <ProductCard
                  key={`trending:${p.stacklineSku}`}
                  LinkComponent={Link}
                  product={p}
                />
              ))}
            </CardGrid>
          </>
        )}
      </PreviewContainer>
    </PreviewWidgetWrapper>
  );
};

export default TrendingPreview;
