import { Outlet } from "react-router-dom";
import { styled, useMediaQuery } from "@mui/material";

import { MainOutlet } from "../Layout/MainOutlet";
import {
  DESKTOP_TOP_BAR_HEIGHT,
  MOBILE_TOP_BAR_HEIGHT,
  TABLET_SCREEN_SIZE,
} from "../../../AppNavigation/constants";

const SyncBG = styled("div")(({ theme }) => ({
  width: "100vw",
  height: 400,
  position: "absolute",
  bottom: "0",

  overflow: "hidden",
  left: "50%",
  transform: "translateX(-50%)",
  "&::after": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -100px)",
    aspectRatio: "1/1",
    width: "400%",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "100%",
  },
}));
const SyncOutlet = () => {
  const mobileView = useMediaQuery(`(max-width:${TABLET_SCREEN_SIZE}px)`);
  const heightOffset = mobileView
    ? MOBILE_TOP_BAR_HEIGHT
    : DESKTOP_TOP_BAR_HEIGHT;
  return (
    <MainOutlet>
      <div
        style={{
          width: "100vw",
          minHeight: "400px",

          height: `calc(100vh - ${heightOffset}px)`,
          position: "relative",
          marginBottom: -100, // to undo the padding in AppMainPageOutlet
        }}
      >
        <SyncBG></SyncBG>
        <Outlet />
      </div>
    </MainOutlet>
  );
};

export default SyncOutlet;
