import { PersonIcon } from "@brandclub/common-ui";
import { NavButton } from "./NavSearchButton";

const NavUserButton = ({ onClick }: any) => {
  return (
    <NavButton onClick={onClick}>
      <PersonIcon color="inherit" sx={{ fontSize: 25 }} />
    </NavButton>
  );
};

export default NavUserButton;
