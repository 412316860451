import ConfigUtils from "../../utils/ConfigUtils";
import { AppDispatch } from "../store";
import { TopBrand } from "../types";
import { getRewardAmountsByBrand } from "../../api/graphQl/authenticated";
import { setTopBrandLoading, setTopBrands } from "../reducers/topBrands";
import reduxApolloClient from "../reduxApolloClient";

/* --------------------- topBrands ---------------------*/

export const loadTopBrands =
  (variables?: Record<string, any>) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setTopBrandLoading());
      const { data } = await reduxApolloClient.query({
        query: getRewardAmountsByBrand,
        variables,
        fetchPolicy: "network-only",
        context: {
          uri: `${ConfigUtils.getGraphQlUri()}?id=getRewardAmountsByBrand`,
        },
      });
      if (data) {
        dispatch(
          setTopBrands(
            data.UsersBrandClubs.map(
              (d: { brandClub: TopBrand }) => d.brandClub
            )
          )
        );
      }
    } catch (e) {
      console.error("loadTopBrands error: ", e);
    }
  };
