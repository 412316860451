import { brandclub_colors } from "@brandclub/common-ui";
import { styled } from "@mui/material";
import { decodeHTMLCharacters } from "@stackline/ui";
import React from "react";
import { getEntityImage } from "../../../../../../utils/StringUtils";
import { FlowSolidButton } from "../../components/FlowButtons";
import FlowContainer from "../../components/FlowContainer";
import FiveStarRating from "../components/FiveStarRating";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../../AppNavigation/constants";

const CoverContainer = styled("div")(({ theme }) => ({
  padding: "20px 40px",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  flex: 1,
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    padding: "20px 20px",
  },
  ".review_info": {
    padding: "50px 20px",
    // maxWidth: 512,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ">div": {
      maxWidth: 512,
    },
  },

  ".logo": {
    maxWidth: 352,
    maxHeight: 352,
    objectFit: "contain",
    borderRadius: 5,

    aspectRatio: 1,
    marginBottom: 36,
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      maxWidth: 302,
      maxHeight: 302,
    },
  },
  ".title": {
    marginBottom: 11,
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.primary.main,
    textAlign: "center",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 24,
    },
  },
  ".subtitle": {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 30,
    color: theme.palette.primary.main,
    textAlign: "center",
    maxWidth: "385px !important",
    WebkitLineClamp: 3,
    lineHeight: 1.5,
    height: `4.5em`, // line height * 3
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    width: "100%",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 16,
    },
  },
}));

const Cover = ({
  reviewProduct,
  moveNext,
  starRating,
  setStarRating,
}: {
  reviewProduct: any;
  moveNext: () => void;
  starRating: number;
  setStarRating: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const cleanProductTitle = decodeHTMLCharacters(reviewProduct?.title || "");
  return (
    <FlowContainer style={{ background: brandclub_colors.white }}>
      <CoverContainer>
        <div className="review_info">
          <img
            className="logo"
            alt="logo"
            src={getEntityImage(reviewProduct.stacklineSku, "product")}
          />

          <div className="subtitle">{cleanProductTitle}</div>
          <div className="title">What did you think?</div>
          <div className="five_star">
            <FiveStarRating
              size={32}
              value={starRating}
              setValue={setStarRating}
            />
          </div>
        </div>
        <div className="actions">
          <div></div>
          <FlowSolidButton
            disabled={!starRating}
            onClick={() => {
              moveNext();
            }}
          >
            Next
          </FlowSolidButton>
        </div>
      </CoverContainer>
    </FlowContainer>
  );
};

export default Cover;
