import { AnimatePresence } from "framer-motion";
import { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../../../redux/hooks";
import { setBrandEntity } from "../../../../../../redux/reducers/brandEntity";
import { SurveyRewardCampaignForCarousel } from "../../../../../../types/misc";
import Loading from "../../../../../Loading";
import { useFetchSurveys } from "../data";
import { SurveyProvider, useSurvey } from "./SurveyProvider";
import Cover from "./cover";
import Finish from "./finish";
import SurveyQuestionStep from "./surveyQuestionStep";
import { useSurveyCheck } from "../useSurveyCheck";
import { useScrollSinglePageWrapperToTop } from "../../../../../StoreComponents/StoreSinglePageOutlet";
import { styled } from "@mui/material";
import { MOBILE_SCREEN_SIZE } from "@brandclub/common-ui";
import { SurveyQuestionContainer } from "../components/SurveyQuestionWarpper";
import FlowContainer from "../../components/FlowContainer";

export const MINIMUM_ANSWERING_TIME = 5;

const StepCount = styled("div")(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 16,
  marginTop: 50,
  display: "flex",
  textAlign: "center",
  fontWeight: 700,
  justifyContent: "center",
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    marginTop: 30,
  },
}));
const SurveyFlow = () => {
  const { campaignId } = useParams();
  const includedCampaignIds = useMemo(
    () => (campaignId ? [campaignId] : []),
    [campaignId]
  );
  const { data, loading, error } = useFetchSurveys({
    includedCampaignIds,
  });
  const {
    checkUserExceededLimit,
    loading: loadingSurveyCheck,
    hasUserExceededLimit,
  } = useSurveyCheck();
  const [survey, setSurvey] = useState<SurveyRewardCampaignForCarousel>(); // useState(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    checkUserExceededLimit();
  }, [checkUserExceededLimit]);

  useEffect(() => {
    if (data?.length === 1 && !loading) {
      setSurvey(data[0]);
    } else if (error) {
      console.error("survey fetching error: ", error);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (data?.length) {
      dispatch(setBrandEntity({ entity: data[0].brandclub, loading: false }));
    }
  }, [data, dispatch]);

  if (loadingSurveyCheck || loading || !survey) {
    return <Loading dot></Loading>;
  }

  return (
    <SurveyProvider survey={survey}>
      <SurveyFlowInner disabled={hasUserExceededLimit} />
    </SurveyProvider>
  );
};

const SurveyFlowInner: FC<{ disabled: boolean }> = (props) => {
  const { currentStep, numberOfQuestion } = useSurvey();
  useScrollSinglePageWrapperToTop(currentStep);
  return (
    <AnimatePresence mode="wait">
      {currentStep <= -1 ? (
        <Cover disabled={props.disabled} />
      ) : currentStep >= numberOfQuestion ? (
        <Finish />
      ) : (
        <>
          <FlowContainer style={{ background: "#fff" }} key={currentStep}>
            <SurveyQuestionContainer>
              <StepCount>
                Question {currentStep + 1} of {numberOfQuestion}
              </StepCount>
              <SurveyQuestionStep />
            </SurveyQuestionContainer>
          </FlowContainer>
        </>
      )}
    </AnimatePresence>
  );
};

export default SurveyFlow;
