import ConfigUtils from "../../utils/ConfigUtils";
import { AppDispatch } from "../store";
import reduxApolloClient from "../reduxApolloClient";
import { setRetailers } from "../reducers/retailers";
import { getRetailersAuth } from "../../api/graphQl/authenticated";
import { getRetailers } from "../../api";
import { EligibilityStatus, RetailerWithSyncStatus } from "../types";

/* --------------------- retailers ---------------------*/

interface AuthRetailerResponse {
  Retailers: RetailerWithSyncStatus[];
}
interface UnauthRetailerResponse {
  RetailersForLandingPage: RetailerWithSyncStatus[];
}
export const loadRetailersAuth = () => async (dispatch: AppDispatch) => {
  try {
    const { data } = await reduxApolloClient.query<AuthRetailerResponse>({
      query: getRetailersAuth,
      variables: { enableExperimentalRetailers: true },
      fetchPolicy: "no-cache",
      context: {
        uri: `${ConfigUtils.getGraphQlUri()}?id=getRetailers`,
      },
    });
    if (data?.Retailers?.length > 0) {
      const serverSideEnabledRetailers = data.Retailers.map((retailer) => ({
        ...retailer,
        parentRetailerId: retailer?.parentRetailerId ?? retailer.retailerId,
      })).filter((retailer) => {
        return retailer.syncConfig.serverSide === EligibilityStatus.ENABLED;
      });
      dispatch(setRetailers(serverSideEnabledRetailers));
    }
  } catch (e) {
    console.error("loadRetailersAuth error: ", e);
  }
};

export const loadRetailersUnauth = () => async (dispatch: AppDispatch) => {
  try {
    const { data } = await reduxApolloClient.query<UnauthRetailerResponse>({
      query: getRetailers,
      variables: { enableExperimentalRetailers: true },
      fetchPolicy: "cache-first",
      context: {
        uri: `${ConfigUtils.getGraphQlUri()}?id=getRetailers`,
      },
    });
    const serverSideEnabledRetailers = data?.RetailersForLandingPage.filter(
      (retailer) => {
        return retailer.syncConfig.serverSide === EligibilityStatus.ENABLED;
      }
    );
    dispatch(setRetailers(serverSideEnabledRetailers));
  } catch (e) {}
};
