import { SolidButton } from "../../../../../StoreComponents/StoreButton";
import { styled, useTheme } from "@mui/material";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../../AppNavigation/constants";

const Pill = styled(SolidButton)(({ theme }) => ({
  // height: 57,
  fontSize: 16,
  fontWeight: 600,
  color: theme.palette.primary.main,
  backgroundColor: "#f6f8fa",
  borderColor: "#f6f8fa",
  height: 46,
  padding: "0px 18px",
  textAlign: "left",
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    padding: "0px 13px",
    fontSize: 14,

    height: 36,
  },
}));

const ReviewSelectPill = ({
  onClick,
  selected,
  text = "",
}: {
  onClick?: () => void;
  selected?: boolean;
  text: string;
}) => {
  const theme = useTheme();
  const variants = {
    normal: {
      backgroundColor: "#f6f8fa",
      borderColor: "#f6f8fa",
      color: theme.palette.primary.main,
    },
    selected: {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: "#fff",
    },
  };
  return (
    <Pill
      onClick={onClick}
      animate={selected ? "selected" : "normal"}
      variants={variants}
      initial={variants.normal}
    >
      {text}
    </Pill>
  );
};

export default ReviewSelectPill;
