import React, { useEffect } from "react";
import { Route, IndexRouteProps, PathRouteProps, useParams } from "react-router-dom";
import { useAnalyticsTrackPageView } from "../AppRoutes";

interface AppRoutesProps
  extends Omit<IndexRouteProps, "index" | "children" | "lazy"> {
  path?: string;
  index?: true;
  element: JSX.Element;
  id?: string;
  // for tracking
  pageType?: string, pageId?: string
}

interface NonIndexRouteProps extends Omit<PathRouteProps, "children"> {
  id?: string;
  path: string;
  element?: JSX.Element;
  children: AppRoutesWithChildrenProps[];
  // for tracking
  pageType?: string, pageId?: string
}

export type AppRoutesWithChildrenProps = (AppRoutesProps | NonIndexRouteProps);

/**
 * Retrieves the name of the component from a React node.
 * @param element The React node to get the component name from.
 * @returns The name of the component, or undefined if the element is not a valid React node.
 */
const getComponentName = (element: React.ReactNode): string | undefined => {
  if (React.isValidElement(element)) {
    if (typeof element.type === "string") {
      return element.type;
    }
    return element.type.name;
  }
  return undefined;
};

/**
 * Generates a unique route key based on the provided route and index.
 * If the route has a manually assigned id, it will be used as the key.
 * Otherwise, the key will be generated based on the route's path, index, and component name (if available).
 * If no unique identifier can be generated, the key will be "route-{index}".
 *
 * @param route - The route object.
 * @param index - The index of the route.
 * @returns The generated unique route key.
 */
const generateUniqueRouteKey = (
  route: AppRoutesWithChildrenProps,
  index: number
): string => {
  if (route.id) {
    // Use manually assigned id if available
    return route.id;
  }
  const parts: string[] = [];
  if (route.path) {
    parts.push(route.path);
  }
  if ("index" in route) {
    parts.push("index");
  }
  if (route.element) {
    const componentName = getComponentName(route.element);
    componentName && parts.push(componentName);
  }
  // If we still don't have a unique identifier, use the index
  if (parts.length === 0) parts.push(`route-${index}`);
  return parts.join("-");
};

interface PageViewWrapperProps {
  pageType?: string;
  pageId?: string;
  children: React.ReactNode;
}

const PageViewWrapper: React.FC<PageViewWrapperProps> = ({ pageType, pageId, children }) => {
  const params = useParams();
  // Resolve dynamic params in pageId (if it's a dynamic route like ":id")
  const resolvedPageId = pageId ? pageId.replace(/:(\w+)/g, (_, param) => params[param] || param) : undefined;
  const trackView = useAnalyticsTrackPageView(
    pageType, resolvedPageId
  );
  useEffect(() => {
    if (pageType && resolvedPageId) {
      trackView();
    }
  }, [trackView, pageType, resolvedPageId]);
  return <>{children}</>;
};

/**
 * Builds a router component based on the provided route object.
 * Recursively builds child routes if the route has children.
 *
 * @param route - The route object to build the route from.
 * @param index - The index of the route in the array.
 * @returns The built React route.
 */
const buildRoute = (
  route: AppRoutesWithChildrenProps,
  index: number
): React.ReactNode => {
  const propsWithChildren = "children" in route ? route : undefined;
  const routeProps = !("children" in route) ? route : undefined;
  const key = generateUniqueRouteKey(route, index);
  if (propsWithChildren) {
    const { children, ...props } = propsWithChildren;
    return (
      <Route key={key} {...props}>
        {children.map(buildRoute)}
      </Route>
    );
  }
  if (routeProps?.pageType && routeProps?.pageId) {
    // Wrap the route element with PageViewWrapper
    const wrappedElement = (
      <PageViewWrapper
        pageType={routeProps?.pageType}
        pageId={routeProps?.pageId}
      >{routeProps?.element}</PageViewWrapper>
    );
    return <Route key={key} {...routeProps} element={wrappedElement} />;
  } else {
    return <Route key={key} {...routeProps} />;
  }
};

/**
 * Builds an array of Router components based on the provided routes.
 *
 * @param routes - An array of routes with children.
 * @returns An array of React nodes representing the built routes.
 */
export const buildRoutes = (
  routes: AppRoutesWithChildrenProps[]
): React.ReactNode[] => {
  return routes.map(buildRoute);
};
