import React, { useEffect, useState, useContext } from "react";
import AuthSolidButton from "./components/AuthSolidButton";
import reduxApolloClient from "../../../redux/reduxApolloClient";
import ConfigUtils from "../../../utils/ConfigUtils";
import { searchBrandClubsForUserV2 } from "../../../api/graphQl/authenticated";
import { styled } from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { formatMoney } from "../../../utils/StringUtils";
import _get from "lodash/get";
import _orderBy from "lodash/orderBy";
import Loading from "../../Loading";
import {
  brandclub_colors,
  brandclubStyles,
  hexadecimal,
} from "@brandclub/common-ui";
import {
  ButtonLoadingEllipsis,
  SolidButton,
} from "../../StoreComponents/StoreButton";
import { joinBrandClub } from "../../../api";
import RewardFooter from "./components/RewardFooter";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import ReduxStore from "../../../redux/types";

import { loadRewards } from "../../../redux/actions/rewards";
import { loadTopBrands } from "../../../redux/actions";
import { UserLoginContext } from "./UserLoginProvider";
import { MIN_N_BRANDCLUB_MEMBERSHIPS } from "./userAuthHelpers";
import {
  desktopHeaderHeight,
  mobileHeaderHeight,
} from "../../StoreComponents/StoreSinglePageOutlet";
import { MOBILE_SCREEN_SIZE } from "../../AppNavigation/constants";

const ROW_SPACING = 25;

const JoinClubContainer = styled("div")({});
const BrandclubTableContainer = styled("div")({
  flex: 1,
  padding: "0 10px",
  overflow: "scroll",
  margin: "23.5px 0 7px 0 !important",
  minHeight: "60px",
  ...brandclubStyles.customScrollbar,

  ".list": {
    display: "flex",
    flexDirection: "column",
    gap: 10,

    width: "100%",
  },
});
const BrandclubRowContainer = styled("div")(({ theme }) => ({
  height: 46,
  display: "flex",
  alignItems: "center",
  padding: "7px 0",
  ".logo": {
    width: 36,
    height: 36,
    objectFit: "contain",
    borderRadius: 100,
  },
  ".detail": {
    margin: "0 13px",
    flex: 1,
    ".title": {
      fontSize: 14,
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    ".reward": {
      fontSize: 12,

      color: theme.palette.primary.main,
      fontWeight: 500,
    },
  },
}));

export const OnBoardingButtonGrey = styled(SolidButton)(({ theme }) => ({
  padding: "4px 20px",
  backgroundColor: brandclub_colors.blueWhite,
  borderColor: brandclub_colors.blueWhite,
  color: theme.palette.primary.main,
}));

export const OnBoardingButtonGreen = styled(SolidButton)(({ theme }) => ({
  padding: "4px 16px",
  cursor: "auto",
  backgroundColor: brandclub_colors.white,
  borderWidth: "1px",
  color: theme.palette.primary.main,
}));

const BrandclubRow = ({
  item,
  joinSuccessCallback,
}: {
  item: any;
  joinSuccessCallback: (reward: any) => void;
}) => {
  const [isMember, setIsMember] = useState(!!item?.isMember);
  const [joining, setJoining] = useState(false);

  const campaignRewards = _orderBy(
    _get(item, ["campaignRewards"]),
    ["rewardAmount"],
    ["desc"]
  );
  const reward = _get(
    campaignRewards,
    ["0", "rewardAmount"],
    item.totalRewardAmount || 0
  );

  const campaignId = _get(campaignRewards, ["0", "campaignId"], undefined);

  const handleJoin = async (event: any) => {
    event.preventDefault();

    try {
      setJoining(true);
      const res = await joinBrandClub({
        brandclubId: item.brandclubId,
        campaignId,
      });
      if (res.status === 200 && res.data && res.data.status === "success") {
        setIsMember(true);
        joinSuccessCallback(res.data);
      }
    } catch (e) {
    } finally {
      setJoining(false);
    }
  };

  return (
    <BrandclubRowContainer>
      <img className="logo" alt="brand_logo" src={item.imageUrl} />
      <div className="detail">
        <div className="title">{item.name}</div>
        {item.hasRewards && reward > 0 ? (
          <div className="reward">{`Earn ${formatMoney(
            reward
          )} when you join`}</div>
        ) : null}
      </div>
      <div>
        {isMember ? (
          <OnBoardingButtonGreen>
            <CheckRoundedIcon
              className="left_icon"
              sx={(theme) => ({
                color: theme.palette.primary.main,
                width: "8px",
                height: "8px",
                padding: "3px",
                backgroundColor: hexadecimal(theme.palette.secondary.main)(20),
                borderRadius: "100%",
              })}
            />
            Joined
          </OnBoardingButtonGreen>
        ) : (
          <OnBoardingButtonGrey onClick={joining ? undefined : handleJoin}>
            {joining ? (
              <>
                Joining
                <ButtonLoadingEllipsis baseFontSize={12} blue />
              </>
            ) : (
              "Join"
            )}
          </OnBoardingButtonGrey>
        )}
      </div>
    </BrandclubRowContainer>
  );
};

const BrandclubTable = ({
  loading,
  rows,
  joinSuccessCallback,
}: {
  loading: boolean;
  rows: any[];
  joinSuccessCallback: (reward: any) => void;
}) => {
  if (loading) {
    return (
      <BrandclubTableContainer>
        <Loading dot />
      </BrandclubTableContainer>
    );
  }
  return (
    <BrandclubTableContainer>
      <div className="list">
        {rows.map((brandclub) => (
          <BrandclubRow
            key={`onboardingJoinClub:${brandclub.brandclubId}`}
            item={brandclub}
            joinSuccessCallback={joinSuccessCallback}
          />
        ))}
      </div>
    </BrandclubTableContainer>
  );
};

const OnBoardingJoinClub = () => {
  const { send } = useContext(UserLoginContext);
  const [loadingBrands, setLoadingBrands] = useState(true);
  const [rewardAmount, setRewardAmount] = useState(0);
  const [onBoardingBrandclub, setOnBoardingBrandclub] = useState<any[]>([]); // [brandclub1, brandclub2, ...

  const topBrands = useAppSelector(({ topBrands }: ReduxStore) => topBrands);

  const rewards = useAppSelector(({ rewards }: ReduxStore) => rewards);
  const dispatch = useAppDispatch();
  const loadingRewards =
    _get(rewards, ["rewards", "lifetimeEarnings"], null) === null ||
    rewards.loading;
  const lifetimeEarnings = _get(rewards, ["rewards", "lifetimeEarnings"], 0);

  useEffect(() => {
    dispatch(loadRewards());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingRewards) {
      setRewardAmount(lifetimeEarnings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingRewards]);

  const fetchOnBoardingBrand = async () => {
    try {
      setLoadingBrands(true);
      const res = await reduxApolloClient.query({
        query: searchBrandClubsForUserV2,
        variables: {
          page: { start: 0, size: 50 },
          clubsWithRewardOnly: true,
          searchIntent: "UserOnboarding",
        },
        fetchPolicy: "network-only",
        context: {
          uri: `${ConfigUtils.getGraphQlUri()}?id=getOnboardingBrands`,
        },
      });
      setOnBoardingBrandclub(res?.data?.SearchBrandClubsForUserV2?.items || []);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingBrands(false);
    }
  };

  useEffect(() => {
    fetchOnBoardingBrand();
  }, []);

  const moveNext = () => {
    send({ type: "SUBMIT" });
  };

  useEffect(() => {
    (async () => {
      if (topBrands) {
        send({
          type: "RECEIVE_TOP_BRANDS",
          topBrands: topBrands?.topBrands,
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topBrands]);

  const joinSuccessCallback = async (reward: any) => {
    if (reward) {
      dispatch(loadTopBrands());
      setRewardAmount((prev) => prev + (reward?.reward?.rewardAmount || 0));
    }
  };

  const disableNext =
    (topBrands.topBrands?.length ?? 0) < MIN_N_BRANDCLUB_MEMBERSHIPS;
  const canShowRewardFooter = !disableNext || !!rewardAmount;

  return (
    <JoinClubContainer
      className="auth_container"
      sx={{
        height: `calc(100dvh - ${desktopHeaderHeight}px)`, // handle mobile here
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          height: `calc(100dvh - ${mobileHeaderHeight}px)`, // handle mobile here
        },
      }}
    >
      <div className="page_title" style={{ textAlign: "center" }}>
        Join {MIN_N_BRANDCLUB_MEMBERSHIPS} or more clubs to earn cash rewards
      </div>
      <BrandclubTable
        joinSuccessCallback={joinSuccessCallback}
        loading={loadingBrands}
        rows={onBoardingBrandclub}
      />
      <AuthSolidButton
        disabled={disableNext}
        style={{
          marginTop: ROW_SPACING + 5,
        }}
        onClick={moveNext}
      >
        Continue
      </AuthSolidButton>
      {canShowRewardFooter && <RewardFooter rewardAmount={rewardAmount} />}
    </JoinClubContainer>
  );
};

export default OnBoardingJoinClub;
