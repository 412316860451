import { AnalyticsTrackingEvent, hexadecimal } from "@brandclub/common-ui";
import {
  Dialog,
  Fade,
  Slide,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { create } from "zustand";
import { OutlineButton, SolidButton } from "../StoreButton";
import { MOBILE_SCREEN_SIZE } from "../../AppNavigation/constants";
import React, { CSSProperties } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { useLocation } from "react-router";

interface BearState {
  open: boolean;
  title: string;
  text: string;
  actions: DialogButtonAction[];
  close: () => void;
  titleStyle?: CSSProperties;
  textStyle?: CSSProperties;
  actionsStyle?: CSSProperties;
  trackingParams?: Record<string, any>;
  trackedEventName?: AnalyticsTrackingEvent;
}

export type DialogButtonAction = {
  text: string;

  onClick: () => void;

  variant: "solid" | "outline";
};

const useConfirmDialogStore = create<BearState>((set) => ({
  open: false,
  title: "",
  text: "",
  actions: [],
  close: () => set({ open: false }),
}));

const ContentContainer = styled("div")(({ theme }) => ({
  padding: "30px 35px",
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    padding: "40px 20px 50px 20px",
  },
  ".title": {
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette?.primary.main,
    marginBottom: 7,
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      textAlign: "center",
    },
  },
  ".text": {
    fontSize: 16,
    color: theme.palette?.primary.main,
    marginBottom: 16,
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      textAlign: "center",
    },
  },
  ".actions": {
    display: "flex",
    gap: 20,
    justifyContent: "flex-end",
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      flexDirection: "column",
      gap: 10,
      alignItems: "center",
    },
  },
}));

const SlideUpTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GenericDialogNoClose = () => {
  const {
    open,
    close,
    title,
    text,
    actions,
    textStyle,
    actionsStyle,
    titleStyle,
    trackedEventName,
    trackingParams,
  } = useConfirmDialogStore();
  const location = useLocation();
  React.useEffect(() => {
    close();
    // close the dialog whenever the location changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const desktopView = useMediaQuery(`(min-width:${MOBILE_SCREEN_SIZE}px)`);
  const theme = useTheme() as any;

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {}}
        fullScreen={false}
        sx={{
          zIndex: 1400,
          "& div.MuiDialog-paper": {
            margin: 0,
            minWidth: 569,
            maxWidth: 569,
            color: theme.palette.primary.main,
          },

          // media queries
          [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            "& div.MuiDialog-paper": {
              width: "100vw",
              minWidth: "100vw",
              maxWidth: "100vw",
              maxHeight: "75vh",
              position: "absolute",
              bottom: 0,
            },
          },
        }}
        PaperProps={{
          sx: { zIndex: 1502, width: "100%", maxWidth: "580px" },
        }}
        TransitionComponent={desktopView ? Fade : SlideUpTransition}
        slotProps={{
          backdrop: {
            sx: (theme) => ({
              backgroundColor: hexadecimal(theme.palette.primary.main)(21),
              zIndex: 1501,
            }),
          },
        }}
      >
        <ContentContainer>
          <div className="title" style={titleStyle}>
            {title}
          </div>
          <div className="text" style={textStyle}>
            {text}
          </div>
          <div className="actions" style={actionsStyle}>
            {actions.map((action, index) => {
              return action.variant === "solid" ? (
                <SolidButton
                  trackedEventName={trackedEventName}
                  trackedAdditionalData={trackingParams}
                  sx={{
                    fontSize: 16,
                    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
                      width: "100%",
                      maxWidth: 333,
                      fontSize: 14,
                    },
                  }}
                  key={index}
                  onClick={() => {
                    action.onClick();
                    close();
                  }}
                >
                  {action.text}
                </SolidButton>
              ) : (
                <OutlineButton
                  trackedEventName={trackedEventName}
                  trackedAdditionalData={trackingParams}
                  sx={{
                    fontSize: 16,
                    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
                      width: "100%",
                      maxWidth: 333,
                      fontSize: 14,
                    },
                  }}
                  key={index}
                  onClick={() => {
                    action.onClick();
                    close();
                  }}
                >
                  {action.text}
                </OutlineButton>
              );
            })}
          </div>
        </ContentContainer>
      </Dialog>
    </>
  );
};

GenericDialogNoClose.show = (props: Omit<BearState, "open" | "close">) => {
  useConfirmDialogStore.setState({
    ...props,
    open: true,
  });
};

GenericDialogNoClose.hide = () => {
  useConfirmDialogStore.setState({
    open: false,
  });
};

export default GenericDialogNoClose;
