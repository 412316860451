import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button, hexadecimal } from "@brandclub/common-ui";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { create } from "zustand";

interface BearState {
  open: boolean;
  close: () => void;
}

const SurveyLimitContainer = styled("div")(({ theme }) => ({
  padding: "30px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  maxWidth: "400px",
  color: theme.palette.primary.main,
}));

const SurveyLimitTitle = styled("div")({
  fontSize: 24,
  fontWeight: 700,
  margin: "30px 0 10px 0",
});

const SurveyLimitSubtitle = styled("div")({
  fontSize: 16,
  fontWeight: 500,
  marginBottom: 50,
});

const useConfirmDialogStore = create<BearState>((set) => ({
  retailer: undefined,
  open: false,
  close: () => set({ open: false }),
}));

const SurveyLimit = ({ close }: { close: () => void }) => {
  return (
    <SurveyLimitContainer>
      <img
        alt="disconect"
        src="https://media.brandclub.com/brandclub/icons/heroicons/synclimit.svg"
      />
      <SurveyLimitTitle>Survey limit</SurveyLimitTitle>
      <SurveyLimitSubtitle>
        You may complete up to 3 surveys per day. Please wait 24 hours to
        participate in more surveys.
      </SurveyLimitSubtitle>
      <Button
        variant="solid"
        onClick={close}
        sx={{ width: "100%", maxWidth: 335 }}
      >
        Close
      </Button>
    </SurveyLimitContainer>
  );
};

const SurveyLimitDialog = () => {
  const { open, close } = useConfirmDialogStore();
  const location = useLocation();

  useEffect(() => {
    close();
    // close the dialog whenever the location changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Dialog
      open={open}
      onClose={close}
      fullScreen={false}
      sx={{
        zIndex: 1400,
      }}
      PaperProps={{
        sx: { zIndex: 1502 },
      }}
      slotProps={{
        backdrop: {
          sx: (theme) => ({
            backgroundColor: hexadecimal(theme.palette.primary.main)(21),
            zIndex: 1501,
          }),
        },
      }}
    >
      <SurveyLimit close={close} />
    </Dialog>
  );
};

SurveyLimitDialog.show = (onClose?: VoidFunction) => {
  useConfirmDialogStore.setState((state) => ({
    open: true,
    close() {
      onClose && onClose();
      state.close();
    },
  }));
};

export default SurveyLimitDialog;
