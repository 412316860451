import _get from "lodash/get";
import ReduxStore from "../../../redux/types";

export const isPhoneNumberNotSetup = (
  userProfile?: Partial<ReduxStore["userProfile"]>
) => (userProfile && !userProfile.phoneNumber ? true : false);

export const isPhoneNumberNotVerified = (
  userProfile?: Partial<ReduxStore["userProfile"]>
) => userProfile && userProfile.phoneNumber && !userProfile.phoneNumberVerified;

export const isOAuthEmailNotVerified = (
  userProfile?: Partial<ReduxStore["userProfile"]>
) => {
  let loginType: "apple" | "google" | "facebook" | "custom" = "custom";
  const oauthUserId = _get(userProfile, ["oauthUserId"], "");
  if (oauthUserId.indexOf("signinwithapple") >= 0) {
    loginType = "apple";
  } else if (oauthUserId.indexOf("google") >= 0) {
    loginType = "google";
  } else if (oauthUserId.indexOf("facebook") >= 0) {
    loginType = "facebook";
  }

  return (
    userProfile && !userProfile.oauthEmailVerified && loginType === "custom"
  );
};

export const shouldCheckout = (userProfile?: Partial<ReduxStore["userProfile"]>) => {
  const search = window.location.search;
  return search.includes("action=checkout") && !!userProfile ;
}

// connect retailer if not connected to any retailer previously
export const shouldConnectRetailer = (
  userProfile?: Partial<ReduxStore["userProfile"]>
) => {

  const connectedStateValues = Object.values(
    userProfile?.extendedAttributes || {}
  ).filter((val) => val === "valid"); // e.g. connectedRetailer_1_state
  const lastScanAnyRetailer =
    userProfile?.extendedAttributes?.lastScanAnyRetailer;

  return (
    !!userProfile && connectedStateValues.length < 1 && !lastScanAnyRetailer && !window.location.search.includes("action=checkout")
  );
};

export const shouldDoIdentityVerification = (
  userProfile?: Partial<ReduxStore["userProfile"]>
) => {
  if (
    !userProfile?.userPhysicalIdVerificationSessionId ||
    !userProfile?.userPhysicalIdVerificationStatus ||
    userProfile?.userPhysicalIdVerificationStatus === "notVerified" ||
    userProfile?.userPhysicalIdVerificationStatus === "verificationFailed" ||
    userProfile?.userPhysicalIdVerificationStatus === "verificationRedacted" ||
    userProfile?.userPhysicalIdVerificationStatus === "verificationCancelled" ||
    userProfile?.userPhysicalIdVerificationStatus === "verificationStarted"
  )
    return true;
  return false;
};

export const getUserStatusShouldNotPass = (
  userProfile: ReduxStore["userProfile"]
): boolean => {
  const userStatus = userProfile?.userStatus || "";
  return ["waitlist", "disabled"].includes(userStatus);
};

export const MIN_N_BRANDCLUB_MEMBERSHIPS = 4;
