import { gql } from "@apollo/client";

export const getRetailers = gql`
  query getRetailers($enableExperimentalRetailers: Boolean) {
    RetailersForLandingPage(
      enableExperimentalRetailers: $enableExperimentalRetailers
    ) {
      retailerId
      name
      color
      displayName
      type
      currencySymbol
      logo
      isBrandClubReady
      retailerConnectRewardAmount
      retailerType
      parentRetailerId
      parentDisplayName
      sortScore
      tosUrl
      syncConfig
      retailerLoginUsernameFieldTypes
    }
  }
`;
