import { gql } from "@apollo/client";

export const getBrandClubForLandingPage = gql`
  query getBrandClubForLandingPage($brandclubId: String!) {
    GetBrandClubForLandingPage(brandclubId: $brandclubId) {
      imageUrl
      brandclubId
      brandIds
      name
      rewardAmounts
    }
  }
`;

/*************************************************
 *
 *             Unauthenticated Queries
 *
 *************************************************/

export const getBrandClubForBrandPublic = gql`
  query getBrandClubForBrandPublic($brandId: Float!) {
    BrandClubForBrandPublic(brandId: $brandId) {
      brandclubId
      companyId
      divisionId
      imageUrl
      name
      brandIds
      brandId
      searchScore
      totalRewardAmount
      hasRewards
      campaignRewards
      availableSurveyRewardAmount
      availableContentRewardAmount
    }
  }
`;
