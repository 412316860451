import BrandCard from "../../../../StoreComponents/StoreCard.tsx/BrandCard";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import { CardGrid } from "../components/CardGrid";
import { LandingHeader } from "../components/Header";
import LandingContainer from "../components/LandingContainer";
import LandingPageWrapper from "../components/LandingPageWrapper";
import { useFetchMyClubs } from "./data";
import _orderBy from "lodash/orderBy";
import _uniqBy from "lodash/uniqBy";

const MyClubLanding = () => {
  const { data, loading } = useFetchMyClubs();

  const rows = _orderBy(
    _uniqBy([...(data || [])], "brandclubId"),
    ["rewardAmount"],
    ["desc"]
  );

  return (
    <LandingPageWrapper>
      <LandingContainer>
        <LandingHeader
          title="My clubs"
          subtitle="View your reward balance for each club"
        ></LandingHeader>
        {loading ? (
          <StoreLoadingSkeleton header />
        ) : (
          <CardGrid>
            {rows?.map((d) => (
              <BrandCard joined key={d.brandclubId} brand={d} />
            ))}
          </CardGrid>
        )}
      </LandingContainer>
    </LandingPageWrapper>
  );
};

export default MyClubLanding;
