import React, { forwardRef } from "react";
import { SvgIconProps } from "@mui/material/SvgIcon";
import Box, { BoxProps } from "@mui/material/Box";
import { ShoppingBagIcon } from "@brandclub/common-ui";

interface StoreShoppingBagIconProps extends SvgIconProps {
  count?: number;
  countProps?: BoxProps;
}

const MAX_COUNT = 99;
export const StoreShoppingBagIcon = forwardRef<
  SVGSVGElement,
  StoreShoppingBagIconProps
>(({ count, countProps, ...props }, ref) => {
  count = count && Math.min(count, MAX_COUNT);
  count = count && Math.max(count, 0);
  return (
    <Box
      display="inline-block"
      lineHeight={0}
      padding={0}
      margin={0}
      minWidth={0}
      boxSizing="border-box"
      position="relative"
    >
      <ShoppingBagIcon {...props} ref={ref} />
      {!!count && (
        <Box
          {...countProps}
          sx={{
            position: "absolute",
            left: "50%",
            bottom: "50%",
            transform: "translate(-50%, 3.75px)",
            fontSize: 10,
            fontWeight: 600,
            ...countProps?.sx,
          }}
        >
          {count}
        </Box>
      )}
    </Box>
  );
});
