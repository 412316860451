import React from "react";
import { styled } from "@mui/material";

const ListEmptyContainer = styled("div")(({ theme }) => ({
  borderRadius: 15,
  background: "#e2f6f1",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.primary.main,
  fontSize: 16,
  fontWeight: 600,
  padding: "40px 40px",
  textAlign: "center",
}));

const ListEmpty = () => {
  return (
    <ListEmptyContainer className="empty_comp">
      {`You don’t have any recent syncs.`}
    </ListEmptyContainer>
  );
};

export default ListEmpty;
