import DateQuestion from "./DateQuestion";
import { useSurvey } from "./SurveyProvider";
import CountBreakdownQuestion from "./countBreakdownQuestion";
import ImageQuestion from "./imageQuestion";
import PillQuestion from "./pillQuestion";
import SelectQuestion from "./selectQuestion";
import TextQuestion from "./textQuestion";
import VideoQuestion from "./videoQuestion";

const SurveyQuestionStep = () => {
  const { question } = useSurvey();
  if (!question) {
    return null;
  }

  // render components like singleChoiceImage using singleChoice
  const type = question?.type?.endsWith("Image")
    ? question?.type?.replace("Image", "")
    : question?.type;

  if (["multipleChoice", "singleChoice"].includes(type)) {
    return <SelectQuestion />;
  } else if (["pills"].includes(type)) {
    return <PillQuestion />;
  } else if (["longAnswer", "shortAnswer"].includes(type)) {
    return <TextQuestion />;
  } else if (type === "image") {
    return <ImageQuestion />;
  } else if (
    [
      "squaretype1",
      "squaretype2",
      "rectangleVertical",
      "rectangleHorizontal",
    ].includes(type)
  ) {
    return <ImageQuestion />;
  } else if (["video"].includes(type)) {
    return <VideoQuestion question={question} />;
  }
  // else if (type === 'likert') {
  //   return <Likert key={currentStep} moveToNextState={moveNext} />;
  // }
  else if (type === "date") {
    return <DateQuestion />;
  } else if (["countBreakdown"].includes(type)) {
    return <CountBreakdownQuestion />;
  }

  return <div>{question.description}</div>;
};

export default SurveyQuestionStep;
