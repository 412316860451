import { useEffect, useState } from "react";
import { useSyncContext } from "../../../../SyncProvider";
import { Link, useParams } from "react-router-dom";
import reduxApolloClient from "../../../../../redux/reduxApolloClient";
import { getRewardSummaryByScan } from "../../../../../api/graphQl/authenticated/ServerSideSync";
import { styled } from "@mui/material";
import Loading from "../../../../Loading";
import { BrandClub } from "@brandclub/types";
import { formatMoney } from "../../../../../utils/StringUtils";
import { useAppSelector } from "../../../../../redux/hooks";
import RetailerSummaryCard from "./RetailerSummaryCard";
import { SyncButton } from "../";
import BrandSummaryCard from "./BrandSummaryCard";
import { markSyncSummaryAsViewed } from "../../../../../api";
import RewardEmptyPageSmall from "../../Pages/components/RewardEmptyPageSmall";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../AppNavigation/constants";
import { isDefined } from "../../../../../utils/misc";

interface BrandRewardSummary {
  rewardAmountPending: number;
  rewardAmountCancelled: number;
  rewardAmountLocked: number;
  rewardAmountUnlocked: number;
  id: number;
  name: string;
  brand: BrandClub & {
    isMember: boolean;
    totalRewardAmount: number;
    rewardAmountByStatusLifetime: {
      rewardAmountUnlocked: number;
      rewardAmountLocked: number;
      rewardAmountPending: number;
      rewardAmountCancelled: number;
    };
  };
}

interface ScanResults {
  rewardAmountLocked: number;
  rewardAmountUnlocked: number;
  rewardAmountPending: number;
  rewardAmountCancelled: number;
  userRewardAmount: {
    rewardAmountUnlocked: number;
    rewardAmountLocked: number;
  };
  rewardSummaryByBrandId: BrandRewardSummary[];
}

const SummaryContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  marginTop: 80,
  maxWidth: 746,
  padding: "0",
  // marginBottom: 300,
  alignItems: "center",
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    marginTop: 50,
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    marginTop: 50,
  },

  ".head": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 15,
    maxWidth: 552,
    marginBottom: 35,
    ".title": {
      fontSize: 24,
      fontWeight: 700,
      textAlign: "center",
    },
    ".subtitle": { fontSize: 18, fontWeight: 500, textAlign: "center" },
  },
  ".unlock_reward": {
    marginBottom: 20,
    padding: "26px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 10,
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    ".title": {
      fontSize: 18,
      fontWeight: 700,
      textAlign: "center",
      color: "#fff",
      marginBottom: 5,
    },
    ".subtitle": {
      fontSize: 16,
      fontWeight: 500,
      textAlign: "center",
      color: "#fff",
      marginBottom: 10,
      maxWidth: 576,
      padding: "0 20px",
    },
  },
  ".reward_summarys": {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",

    flexDirection: "column",
    gap: 15,
  },
  ".rewards_by_brand": {
    paddingTop: 30,
    width: "100%",
  },
}));

const SyncSummary = () => {
  const [fetching, setFetching] = useState(true);
  const [connectedRetailerIds, setConnectedRetailerIds] = useState<number[]>(
    []
  );
  const [disconnectedRetailerIds, setDisconnectedRetailerIds] = useState<
    number[]
  >([]);
  const [scanResults, setScanResults] = useState<ScanResults>();
  const { syncBatchId, loading } = useParams();
  const retailers = useAppSelector(({ retailers }) => retailers);

  const userProfile = useAppSelector(({ userProfile }) => userProfile);

  const {
    latestSyncBatchId,
    syncStatusByParentRetailer,
    refreshSyncProvider,
    sessionRewardSummaryDisplayStatus,
    syncStatus,
    retailerConnectionsFromSyncSession,
  } = useSyncContext();

  useEffect(() => {
    if (
      latestSyncBatchId &&
      syncStatus === "normal" &&
      sessionRewardSummaryDisplayStatus !== "viewed"
    ) {
      (async () => {
        await markSyncSummaryAsViewed({ syncBatchId: latestSyncBatchId });
        await refreshSyncProvider();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestSyncBatchId, sessionRewardSummaryDisplayStatus]);

  const fetchData = async () => {
    try {
      setFetching(true);
      const syncIds = syncStatusByParentRetailer.map((s) => s.syncId);

      const { data } = await reduxApolloClient.query({
        query: getRewardSummaryByScan,
        variables: {
          syncIds,
        },
      });

      setScanResults({
        ...data.RewardSummaryByScan,
      });
    } catch (e) {
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (latestSyncBatchId && syncBatchId && latestSyncBatchId === syncBatchId) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncBatchId, latestSyncBatchId]);

  useEffect(() => {
    if (retailers && retailerConnectionsFromSyncSession) {
      const retailerMap = new Map(retailers.map((r) => [r.retailerId, r]));
      const connectedR = retailerConnectionsFromSyncSession
        .filter((s) => s.syncStatus === "completed")
        .map((s) => retailerMap.get(s.retailerId))
        .filter(isDefined);
      const disconnectedR = retailerConnectionsFromSyncSession
        .filter((s) => s.syncStatus !== "completed")
        .map((s) => retailerMap.get(s.retailerId))
        .filter(isDefined);
      setConnectedRetailerIds(connectedR.map((r) => r.retailerId));
      setDisconnectedRetailerIds(disconnectedR.map((r) => r.retailerId));
    }
  }, [retailers, retailerConnectionsFromSyncSession]);

  if (loading || fetching) {
    return (
      <SummaryContainer>
        <Loading dot />
      </SummaryContainer>
    );
  }

  if (
    !scanResults?.rewardAmountLocked &&
    !scanResults?.rewardAmountUnlocked &&
    !scanResults?.rewardAmountPending &&
    !scanResults?.rewardAmountCancelled
  ) {
    return (
      <SummaryContainer>
        <div className="head">
          <div className="title">More ways to engage</div>
          <div className="subtitle">
            {`We didn’t find any rewards in this sync, but we’ve found the best activities for you.`}
          </div>
        </div>
        <div className="reward_summarys">
          <RetailerSummaryCard
            retailerIdList={disconnectedRetailerIds}
            type={"fix"}
          ></RetailerSummaryCard>
          <RetailerSummaryCard
            retailerIdList={connectedRetailerIds}
            type={"success"}
          ></RetailerSummaryCard>
        </div>
        <div className="rewards_by_brand">
          <RewardEmptyPageSmall />
        </div>
      </SummaryContainer>
    );
  }
  // net rewards received = unlocked + locked + pending
  const netRewardsReceived =
    scanResults.rewardAmountUnlocked +
    scanResults.rewardAmountLocked +
    scanResults?.rewardAmountPending;
  // New locked rewards, new unlocked rewards
  // New locked rewards, no unlocked rewards
  // No locked rewards, new unlocked rewards
  if (
    scanResults?.rewardAmountLocked ||
    scanResults?.rewardAmountUnlocked ||
    scanResults?.rewardAmountPending ||
    scanResults?.rewardAmountCancelled
  ) {
    return (
      <SummaryContainer>
        <div className="head">
          <div className="title">
            This sync earned you {formatMoney(netRewardsReceived)} new rewards
          </div>
        </div>
        {scanResults.rewardAmountUnlocked > 0 ? (
          <div className="unlock_reward">
            <div className="title">
              {userProfile?.given_name
                ? `Congrats, ${userProfile.given_name.trim()}!`
                : "Congrats!"}
            </div>
            <div className="subtitle">
              {`Your purchases unlocked ${formatMoney(
                scanResults.rewardAmountUnlocked
              )} in existing rewards, bringing your total unlocked reward balance to ${formatMoney(
                scanResults.userRewardAmount.rewardAmountUnlocked // is this total or no?
              )}.`}
            </div>
            <Link
              to="/dashboard/rewards/activity"
              style={{ textDecoration: "none", maxWidth: 345, width: "100%" }}
            >
              <SyncButton>View rewards summary</SyncButton>
            </Link>
          </div>
        ) : null}
        <div className="reward_summarys">
          <RetailerSummaryCard
            retailerIdList={disconnectedRetailerIds}
            type={"fix"}
          ></RetailerSummaryCard>
          <RetailerSummaryCard
            retailerIdList={connectedRetailerIds}
            type={"success"}
          ></RetailerSummaryCard>
          {scanResults.rewardSummaryByBrandId.map((brandData) => {
            return (
              <BrandSummaryCard
                brandData={brandData}
                key={`reward-summary-${brandData.id}`}
              />
            );
          })}
        </div>
      </SummaryContainer>
    );
  }
  return (
    <SummaryContainer>
      <div>
        rewardAmountCancelled : {scanResults?.rewardAmountCancelled}
        <br /> rewardAmountLocked : {scanResults?.rewardAmountLocked}
        <br />
        rewardAmountPending : {scanResults?.rewardAmountPending}
        <br />
        rewardAmountUnlocked : {scanResults?.rewardAmountUnlocked}
        <br />
        rewardAmountLocked : {scanResults?.userRewardAmount?.rewardAmountLocked}
        <br></br>rewardAmountUnlocked :
        {scanResults?.userRewardAmount?.rewardAmountUnlocked}
      </div>
    </SummaryContainer>
  );
};

export default SyncSummary;
