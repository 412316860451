import { getAuthorizationHeader } from "../../../Auth";
import { SurveyResponse } from "../../../types/misc";
import axios from "../../../utils/axios";

export interface SubmitSurveyForRequest {
  responses: (SurveyResponse | null)[];
  campaignId: string;
  surveyId: string;
}

export const submitSurveyForReward = async (req: SubmitSurveyForRequest) => {
  return await axios.post("/survey/submitSurveyV2", req, {
    headers: {
      ...(await getAuthorizationHeader()),
    },
  });
};

export const canSubmitSurveyResponse = async (): Promise<boolean> => {
  return (
    await axios.post(
      "/survey/canSubmitSurveyV2",
      {},
      {
        headers: {
          ...(await getAuthorizationHeader()),
        },
      }
    )
  )?.data;
};
