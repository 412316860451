import {
  ArrowRightIcon,
  BrandClub,
  SuccessCheckCircleIcon,
  brandclub_colors,
} from "@brandclub/common-ui";
import { styled } from "@mui/material";
import _get from "lodash/get";
import { Link } from "react-router-dom";
import { TopBrand } from "../../../redux/types";
import { useJoinBrandClubWithRedirect } from "../../../utils/hooks/useJoinBrandClub";
import { escapeUriPath, formatMoney } from "../../../utils/StringUtils";
import { MOBILE_SCREEN_SIZE } from "../../AppNavigation/constants";
import { ButtonLoadingEllipsis, SolidButton } from "../StoreButton";

const BrandCardContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  padding: "27px 15px 21px 15px",
  borderRadius: 10,
  border: "solid 1px #dcdddc",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 17,
  cursor: "pointer",
  transition: "background-color 0.4s ease-in-out",
  [`@media screen and (max-width: ${1250}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    gap: 10,
    padding: "20.9px 15px",
  },
  "*": {
    mixBlendMode: "multiply",
  },
  // "&:hover": {
  //   backgroundColor: "#F6F8FA",
  // },
  ".logo": {
    width: `46%`,
    aspectRatio: "1/1",
    backgroundColor: brandclub_colors.white,
    objectFit: "contain",
    borderRadius: 999,
  },
  ".name": {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.primary.main,
    overflow: "hidden",
    width: "80%",
    textAlign: "center",
    textDecoration: "none",
    letterSpacing: 0.36,
    WebkitLineClamp: 1,
    lineHeight: 1.5,
    height: `1.5em`, // line height * 2
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-word",
    [`@media screen and (max-width: ${1250}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 16,
    },
  },
  ".button": {
    "&, *": {
      mixBlendMode: "unset !important",
    },
  },
  ".status": {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",

    textAlign: "center",
    [`@media screen and (max-width: ${1250}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 12,
    },
    ".arrow": {
      width: 13,
      height: 13,
      objectFit: "contain",
      marginLeft: 10,
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        width: 10,
        height: 10,
      },
    },
  },
}));

const BrandCardButton = styled(SolidButton)(({ theme }) => ({
  backgroundColor: brandclub_colors.white,
  color: theme.palette.primary.main,
  border: `1.5px solid ${theme.palette.primary.main}`,
  height: 40,
  fontSize: 14,
  fontWeight: 600,
  minWidth: 108,
  [`@media screen and (max-width: ${1250}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    height: 30,
    fontSize: 12,
    minWidth: 88,
  },
}));

const BrandCardButtonSolid = styled(SolidButton)(({ theme }) => ({
  backgroundColor: brandclub_colors.grayF3F4F5,
  color: theme.palette.primary.main,
  border: `1.5px solid ${brandclub_colors.grayF3F4F5}`,
  height: 40,
  fontSize: 14,
  fontWeight: 600,
  minWidth: 108,
  [`@media screen and (max-width: ${1250}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    height: 30,
    fontSize: 12,
    minWidth: 88,
  },
}));

const BrandCard = ({
  brand,
  joined,
}: {
  brand: Partial<TopBrand & BrandClub>;
  joined?: boolean;
}) => {
  const { handleJoin, joinedState, campaignRewards } =
    useJoinBrandClubWithRedirect(brand);
  const path = `/${escapeUriPath(brand.name)}/b/${brand.brandId}/home`;

  const viewActivityPath = `/${escapeUriPath(brand.name)}/b/${
    brand.brandId
  }/branddashboard/rewards/activity`;

  const reward = _get(
    campaignRewards,
    ["0", "rewardAmount"],
    brand.totalRewardAmount || 0
  );

  return (
    <Link
      to={joined ? viewActivityPath : path}
      style={{ textDecoration: "none" }}
    >
      <BrandCardContainer>
        <img alt="logo" className="logo" src={brand.imageUrl}></img>
        <div className="name">{brand.name}</div>
        <div className="button">
          {joined ? (
            <BrandCardButton>
              {formatMoney(brand?.rewardAmount ?? 0)}
            </BrandCardButton>
          ) : joinedState === "not_join" ? (
            <BrandCardButtonSolid onClick={handleJoin}>
              Join club
            </BrandCardButtonSolid>
          ) : joinedState === "joining" ? (
            <BrandCardButtonSolid>
              <>
                Joining
                <ButtonLoadingEllipsis
                  baseFontSize={17}
                  blue
                ></ButtonLoadingEllipsis>
              </>
            </BrandCardButtonSolid>
          ) : (
            <BrandCardButton>
              <SuccessCheckCircleIcon className="button_img" />
              Member
            </BrandCardButton>
          )}
        </div>
        {joinedState === "joined" && reward ? (
          <div className="status">{`You earned ${formatMoney(
            reward
          )} by joining this club`}</div>
        ) : joined ? (
          <div className="status">
            {"View activity"}
            <ArrowRightIcon className="arrow" color="primary" />
          </div>
        ) : reward ? (
          <div className="status">{`Earn ${formatMoney(
            reward
          )} when you join`}</div>
        ) : null}
      </BrandCardContainer>
    </Link>
  );
};

export default BrandCard;
