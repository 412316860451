import _orderBy from "lodash/orderBy";
import { useMemo } from "react";
import { RetailerWithSyncStatus } from "../../../../../redux/types";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import { CardGrid } from "../components/CardGrid";
import { PreviewHeader } from "../components/Header";
import PreviewContainer from "../components/PreviewContainer";
import PreviewWidgetWrapper from "../components/PreviewWidgetWrapper";
import RetailerCard from "./components/RetailerCard";
import { useFetchAccount } from "./data";
import { useResponsiveData } from "../../../../../utils/hooks/useResponsiveData";
import { getRetailerSortScores } from "../../../../../utils/misc";

const MAX_RETAILERS = 10;

const HomeAccountsPreview = () => {
  const { loading, connectedRetailers, disconnectedRetailers, newRetailers } =
    useFetchAccount({});

  const retailers = useMemo(
    () => [...connectedRetailers, ...disconnectedRetailers, ...newRetailers],
    [connectedRetailers, disconnectedRetailers, newRetailers]
  );

  const sortScores = useMemo(
    () => getRetailerSortScores(retailers),
    [retailers]
  );

  const homeRetailer = useMemo(() => {
    return _orderBy(
      retailers,
      (retailer) => sortScores[retailer?.retailerId as number] || 0,
      ["desc"]
    ).slice(0, MAX_RETAILERS);
  }, [sortScores, retailers]);

  const responsiveHomeRetailer = useResponsiveData(homeRetailer);

  if (!loading && homeRetailer.length === 0) {
    return null;
  }

  return (
    <PreviewWidgetWrapper>
      <PreviewContainer>
        {loading ? (
          <StoreLoadingSkeleton header row={2} />
        ) : (
          <>
            <PreviewHeader
              title={"Accounts"}
              linkTo="/dashboard/accounts"
              subtitle="Earn more rewards by connecting accounts"
              indicator="grey"
            />
            <CardGrid style={{ marginTop: 20 }}>
              {responsiveHomeRetailer.map((r) => {
                return (
                  <RetailerCard
                    key={r.retailerId}
                    retailer={r as RetailerWithSyncStatus}
                  />
                );
              })}
            </CardGrid>
          </>
        )}
      </PreviewContainer>
    </PreviewWidgetWrapper>
  );
};

export default HomeAccountsPreview;
