import { styled } from "@mui/material";

import React from "react";
import {
  MOBILE_HORIZONTAL_PADDING,
  MOBILE_SCREEN_SIZE,
  TABLET_HORIZONTAL_PADDING,
  TABLET_SCREEN_SIZE,
} from "../../../AppNavigation/constants";

const blocks = [
  {
    title: "Cash rewards",
    subtitle:
      "No points, no gimmicks. Earn cash that can be sent to your PayPal or Venmo.",
    image: `https://media.brandclub.com/brandclub/image_asset/membership_money.jpg`,
  },
  {
    title: "8 ways to earn",
    subtitle:
      "Exclusive reward offers include taking surveys, watching content, sharing products, and more.",
    image: `https://media.brandclub.com/brandclub/image_asset/membership_computer.jpeg`,
  },
  {
    title: "Join for free",
    subtitle:
      "Becoming a member is instant and free. Get endless opportunities to earn more.",
    image: `https://media.brandclub.com/brandclub/image_asset/membership_pig.jpeg`,
  },
];

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  padding: `0 5%`,
  boxSizing: "border-box",
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    padding: `0 ${TABLET_HORIZONTAL_PADDING}px`,
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    padding: `0 ${MOBILE_HORIZONTAL_PADDING}px`,
  },
  ".title": {
    fontSize: 32,
    fontWeight: "bold",
    color: theme.palette.primary.main,
    marginBottom: 16,
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 24,
    },
  },
  ".subtitle": {
    fontSize: 20,
    color: theme.palette.primary.main,
    marginBottom: 54,
    maxWidth: 660,
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 16,
      marginBottom: 38,
    },
  },
  ".blocks": {
    display: "flex",
    flexWrap: "wrap",
    gap: "40px",
    justifyContent: "center",
    maxWidth: 1680,
    width: "100%",
    marginBottom: 60,
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  ".bottom_subtitle": {
    fontSize: 20,
    color: theme.palette.primary.main,
    marginBottom: 30,
  },
}));

const BlockContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: 398,
  width: 398,
  overflow: "hidden",
  gap: 0,

  borderRadius: 10,
  ".image": {
    width: "100%",
    height: 333,
    overflow: "hidden",
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      height: 233,
    },
    img: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  ".info": {
    flex: 1,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    padding: "37.5px 81px 36.5px 30px",
    border: `1px solid #dcdddc`,
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    width: "100%",
    boxSizing: "border-box",
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      padding: "15px 20px",
    },
    ".block_label": {
      fontSize: 20,
      fontWeight: 700,
      color: theme.palette.primary.main,
      textAlign: "left",
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: 16,
      },
    },
    ".block_sublabel": {
      fontWeight: 500,
      fontSize: 16,
      color: theme.palette.primary.main,
      textAlign: "left",
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: 14,
      },
    },
  },
}));

const Block = ({
  title,
  subtitle,
  image,
}: {
  title: string;
  subtitle: string;
  image: string;
}) => {
  return (
    <BlockContainer>
      <div className="image">
        <img alt="" src={image} />
      </div>
      <div className="info">
        <div className="block_label">{title}</div>
        <div className="block_sublabel">{subtitle}</div>
      </div>
    </BlockContainer>
  );
};

const MemberSection = () => {
  return (
    <Container>
      <div className="title">Why you’ll love being a member</div>
      <div className="subtitle">
        Key benefits designed exclusively for our members.
      </div>
      <div className="blocks">
        {blocks.map((block) => {
          return (
            <Block
              image={block.image}
              title={block.title}
              subtitle={block.subtitle}
              key={block.title}
            />
          );
        })}
      </div>
    </Container>
  );
};

export default MemberSection;
