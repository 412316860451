import { styled } from "@mui/material";
import { formatMoney } from "@stackline/ui";
import { useNavigate } from "react-router-dom";
import { ContentRewardCampaignForCarousel } from "../../../../../../types/misc";
import { escapeUriPath } from "../../../../../../utils/StringUtils";
import {
  OutlineButtonWithArrow,
  SolidButtonWithArrow,
} from "../../../../../StoreComponents/StoreButton";
import StoreConfetti, {
  ConfettiContainer,
} from "../../../../../StoreComponents/StoreConfetti";
import FlowContainer from "../../components/FlowContainer";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../../AppNavigation/constants";

import { useSignInPage } from "../../../../../StoreComponents/StoreLogin/useStoreLogin";
import LoginButtons from "../../../../../StoreComponents/StoreLogin/LoginButtons";
import { useUserLogin } from "../../../../Auth/UserLoginProvider";

const FinishContainer = styled("div")(({ theme }) => ({
  maxWidth: 705,
  padding: 20,
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  flex: 1,
  zIndex: 0,

  ".title": {
    marginBottom: 9,
    fontSize: 32,
    fontWeight: 700,
    color: theme.palette.primary.main,
    textAlign: "center",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 24,
    },
  },
  ".subtitle": {
    maxWidth: 652,
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 30,
    color: theme.palette.primary.main,
    textAlign: "center",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 18,
    },
  },
}));
const Finish = ({
  content,
  receivedReward,
}: {
  content: ContentRewardCampaignForCarousel;
  receivedReward: number;
}) => {
  const navigate = useNavigate();
  const { signedIn } = useUserLogin();
  const signIn = useSignInPage();
  const createAccount = useSignInPage();

  const brandName = content?.brandclub?.name;
  const brandId = content?.brandclub?.brandId;
  const successSubtitle = content?.content?.successSubtitle;
  const shopBrand = () => {
    const path = `/${escapeUriPath(brandName)}/b/${brandId}/home`;
    navigate(path);
  };

  const returnHome = () => {
    navigate("/");
  };

  let actionButtons = (
    <>
      <SolidButtonWithArrow
        style={{
          width: "100%",
          maxWidth: 349,
          marginBottom: "15px",
          fontSize: 16,
          height: 50,
        }}
        onClick={shopBrand}
      >
        Explore {brandName}
      </SolidButtonWithArrow>
      <OutlineButtonWithArrow
        style={{ width: "100%", maxWidth: 349, fontSize: 16, height: 50 }}
        onClick={returnHome}
      >
        Return to home
      </OutlineButtonWithArrow>
    </>
  );
  if (!signedIn) {
    actionButtons = (
      <LoginButtons
        trackedAdditionalData={{
          brandId,
          content,
          brandName,
        }}
        onSignInClick={() => signIn({ pathname: "/" })}
        onCreateAccountClick={() => createAccount({ pathname: "/" })}
        sx={{ maxWidth: "355px", marginTop: "20px" }}
      />
    );
  }

  return (
    <FlowContainer>
      <FinishContainer>
        <ConfettiContainer>
          <StoreConfetti />
        </ConfettiContainer>
        <div className="title">Thank you</div>
        <div className="subtitle">
          {successSubtitle
            ? successSubtitle
            : `We appreciate your time. You earned a ${formatMoney(
                receivedReward
              )} reward That will be
            unlocked with your next purchase.`}
        </div>
        {actionButtons}
      </FinishContainer>
    </FlowContainer>
  );
};

export default Finish;
