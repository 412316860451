import { CardGrid } from "../components/CardGrid";
import { PreviewHeader } from "../components/Header";
import { useFetchAffiliate } from "./data";
import PreviewWidgetWrapper from "../components/PreviewWidgetWrapper";
import { ProductRewardSearchResult } from "@brandclub/common-ui";
import { Link } from "react-router-dom";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import { useRef, useEffect } from "react";
import { useInView } from "framer-motion";

import PreviewContainer from "../components/PreviewContainer";
import { defaultPreviewLazyLoadConfig } from "../../../../../utils/lazyLoadConfig";
import ProductCard from "../../../../StoreComponents/StoreCard.tsx/ProductCard";
import { useLinkPrefix } from "../../../../../utils/hooks/useLinkPrefix";
import { useResponsiveData } from "../../../../../utils/hooks/useResponsiveData";

const AffiliatePreview = ({
  brandIds,
  arrowColor = "grey",
}: {
  brandIds?: number[];
  arrowColor?: "blue" | "grey";
}) => {
  const ref = useRef(null);
  const widgetInView = useInView(ref, defaultPreviewLazyLoadConfig);
  const { linkPrefix } = useLinkPrefix();
  const { data, loading, fetchInit } = useFetchAffiliate({
    pageSize: 10,
    autoInitFetch: false,
    brandIds,
  });
  const responsiveData = useResponsiveData(data);

  useEffect(() => {
    if (widgetInView) {
      fetchInit();
    }
  }, [widgetInView, fetchInit]);

  if (!loading && data.length === 0) {
    return null;
  }

  return (
    <PreviewWidgetWrapper ref={ref}>
      <PreviewContainer>
        {loading ? (
          <StoreLoadingSkeleton header row={2} />
        ) : (
          <>
            <PreviewHeader
              title={"Affiliate commission"}
              linkTo={linkPrefix + "/affiliate"}
              subtitle="Get paid to refer purchases"
              indicator={arrowColor}
            />
            <CardGrid>
              {responsiveData.map((d) => (
                <ProductCard
                  key={d.stacklineSku}
                  LinkComponent={Link}
                  product={d as ProductRewardSearchResult}
                ></ProductCard>
              ))}
            </CardGrid>
          </>
        )}
      </PreviewContainer>
    </PreviewWidgetWrapper>
  );
};

export default AffiliatePreview;
