import Typography, { TypographyProps } from "@mui/material/Typography";
import ErrorIcon from "@mui/icons-material/Error";
import { SvgIconProps } from "@mui/material/SvgIcon";
import { HTMLAttributes } from "react";
import Box from "@mui/material/Box";

export interface StoreErrorProps extends HTMLAttributes<HTMLElement> {
  errorMessage?: string;
  iconProps?: SvgIconProps;
  variant?: "medium" | "large";
  sx?: TypographyProps["sx"];
}

const fontSizeVariant = {
  medium: 14,
  large: 16,
};

export const StoreError = ({
  errorMessage,
  iconProps,
  variant = "medium",
  sx,
  ...props
}: StoreErrorProps) => {
  const fontSize = fontSizeVariant[variant];

  const lineHeight = 1.5;
  return !errorMessage ? null : (
    <Typography
      {...props}
      sx={{
        color: "#ff534a",
        backgroundColor: "rgba(255, 83, 74, 0.05)", // #ff534a with 5% opacity,
        borderRadius: 1,
        fontSize,
        marginTop: 2,
        marginBottom: 1,
        padding: "7px 12px 7px 9px",
        display: "flex",
        alignItems: "flex-start",
        fontWeight: 600,
        lineHeight,
        maxWidth: " 100%",
        width: "100%",
        boxSizing: "border-box",
        textAlign: "left",
        justifyContent: "flex-start",
        ...sx,
      }}
    >
      <Box
        component="span"
        display="flex"
        alignItems="center"
        height={`${lineHeight}em`}
      >
        <ErrorIcon
          {...iconProps}
          sx={{ paddingRight: 0.75, fontSize: 18, ...iconProps?.sx }}
        />
      </Box>
      {errorMessage}
    </Typography>
  );
};
