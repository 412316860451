import {
  useEffect,
  createContext,
  useContext,
  useState,
  ReactElement,
} from "react";
import { useCheckout } from "./CheckoutMachine";

interface OrderContextInterface {
  openPayment: boolean;
  setOpenPayment: (open: boolean) => void;
  openShipping: boolean;
  setOpenShipping: (open: boolean) => void;
  openDelivery: boolean;
  setOpenDelivery: (open: boolean) => void;
}

export const OrderContext = createContext({} as OrderContextInterface);

export const OrderProvider = ({ children }: { children: ReactElement }) => {
  const [openPayment, setOpenPayment] = useState(false);
  const [openShipping, setOpenShipping] = useState(false);
  const [openDelivery, setOpenDelivery] = useState(false);
  const { snapshot } = useCheckout();
  const { userInputError } = snapshot.context;

  useEffect(() => {
    const state = snapshot.context.globalCheckoutState;
    if (
      state === "CHECKOUT_SUMMARY" &&
      !userInputError &&
      !snapshot.context.retailerCheckoutOrderStatus
        ?.userRetailerCheckoutSessionStatus?.lastFailedAttempt?.type
    ) {
      setOpenPayment(false);
      setOpenShipping(false);
      setOpenDelivery(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snapshot.context.globalCheckoutState]);

  return (
    <OrderContext.Provider
      value={{
        openPayment,
        setOpenPayment,
        openShipping,
        setOpenShipping,
        openDelivery,
        setOpenDelivery,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export const useOrder = () => {
  return useContext(OrderContext);
};
