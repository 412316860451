import { ChevronDownIcon, brandclub_colors } from "@brandclub/common-ui";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  styled,
} from "@mui/material";
import { useId, useMemo } from "react";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../AppNavigation/constants";

const Container = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== "fullWidth",
})(({ theme, fullWidth }) => ({
  position: "relative",
  maxWidth: fullWidth ? "100%" : 353, // Conditional maxWidth based on fullWidth prop
  width: "100%",
  marginBottom: 0,
  ".store-select": {
    boxSizing: "border-box",
    maxWidth: fullWidth ? "100%" : 353, // Conditional maxWidth based on fullWidth prop
    width: "100%",
    border: "1.5px solid #dcdddc",
    height: 60,
    outline: "none",
    borderRadius: "6px",
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.primary.main,
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      maxWidth: "unset",
    },
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      maxWidth: "unset",
    },
  },
  ".MuiInputLabel-root": {
    color: theme.palette.primary.main,
    opacity: 0.3,
    fontWeight: 500,
    zIndex: 1,
    top: "50%",
    transform: "translate(14px, -50%) scale(1)",
  },
  ".MuiInputLabel-shrink": {
    transform: "translate(14px, -90%) scale(0.75)",
  },
}));

const StyledSelect = styled(Select)({
  "&.MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
  },
  ".MuiOutlinedInput-input": {
    padding: "20px 14px 0px 14px",
  },
});

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 14,
  fontWeight: 500,
  paddingTop: "12px",
  paddingBottom: "12px",
  ":hover": {
    backgroundColor: brandclub_colors.blueWhite,
  },
  "&.Mui-selected": {
    backgroundColor: brandclub_colors.blueWhite,
    ":hover": {
      backgroundColor: brandclub_colors.blueWhite,
    },
  },
}));

export type StoreSelectOptions = {
  value: string;
  displayName: string;
  logo?: string;
};

const SelectMenuItem = ({
  item,
  atLeastOneLogo,
}: {
  item: StoreSelectOptions;
  atLeastOneLogo?: boolean;
}) => {
  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
      {atLeastOneLogo && item.logo ? (
        <img
          src={item.logo}
          alt={`${item?.displayName} logo`}
          style={{ height: 20, width: "auto" }}
        />
      ) : null}
      <Box
        component="span"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
        title={item?.displayName}
      >
        {item?.displayName}
      </Box>
    </Box>
  );
};

const StoreSelect = ({
  label,
  items,
  fullWidth = false,
  value,
  ...props
}: SelectProps & {
  items: StoreSelectOptions[];
  label?: string;
  fullWidth?: boolean;
}) => {
  const atLeastOneLogo = items.some((i) => i.logo);
  const inputLabelId = useId();

  const selectedItem = useMemo(
    () => items.find((i) => i.value?.toString() === value?.toString()),
    [items, value]
  );
  return (
    <Container fullWidth={fullWidth}>
      {label ? (
        <InputLabel id={`store-select-label__${inputLabelId}`}>
          {label}
        </InputLabel>
      ) : null}
      <StyledSelect
        label={label}
        labelId={`store-select-label__${inputLabelId}`}
        value={value ?? ""} // fixes the MUI error: "changing the controlled value state to be uncontrolled"
        {...props}
        MenuProps={{
          slotProps: {
            paper: {
              sx: { width: 353 },
            },
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        }}
        className="store-select"
        renderValue={() => {
          return (
            <SelectMenuItem
              item={selectedItem!}
              atLeastOneLogo={atLeastOneLogo}
            />
          );
        }}
        IconComponent={(p) => <ChevronDownIcon {...p} color="primary" />}
      >
        {items.map((i) => {
          return (
            <StyledMenuItem
              key={i.value}
              sx={{
                display: "inline-block",
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden !important",
                textOverflow: "ellipsis",
              }}
              disableRipple
              value={i.value}
            >
              <SelectMenuItem item={i} atLeastOneLogo={atLeastOneLogo} />
            </StyledMenuItem>
          );
        })}
      </StyledSelect>
    </Container>
  );
};

export default StoreSelect;
