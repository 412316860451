import _orderBy from "lodash/orderBy";
import _uniqBy from "lodash/uniqBy";
import { useEffect, useState } from "react";
import { getCustomerPaymentTransactions } from "../../../../../../api/graphQl/authenticated";
import reduxApolloClient from "../../../../../../redux/reduxApolloClient";
import { Payout } from "../../../../../../types/misc";
import ConfigUtils from "../../../../../../utils/ConfigUtils";
import RewardList from "./RewardList";
import { useSiteContext } from "../../../../../SiteProvider";

const FetchVariables = {
  expiringRewards: true,
  brandIds: undefined,
};

const PayoutRewardList = () => {
  const { brandId } = useSiteContext();
  //   const [page, setPage] = useState(0);
  const [rows, setRows] = useState<Payout[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchRewardsForUser = async (variables: any) => {
    try {
      setLoading(true);
      const { data } = await reduxApolloClient.query<{
        CustomerPaymentTransactions: Payout[];
      }>({
        query: getCustomerPaymentTransactions,
        variables,
        fetchPolicy: "no-cache",
        context: {
          uri: `${ConfigUtils.getGraphQlUri()}?id=getCustomerPaymentTransactions`,
        },
      });

      if (data?.CustomerPaymentTransactions) {
        // filter out rewards for products that don't have metadata associated with them to display
        setRows((existing) =>
          _orderBy(
            _uniqBy(
              [...(existing || []), ...data.CustomerPaymentTransactions],
              "transactionId"
            ),
            ["updatedTime"],
            ["desc"]
          )
        );
      }
    } catch (e) {
      console.error("error: ", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRewardsForUser({
      ...FetchVariables,
      brandIds: brandId ? [brandId] : undefined,
      page: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RewardList
      data={rows}
      listType="payout"
      loading={loading}
      headerText="Payouts"
    />
  );
};

export default PayoutRewardList;
