import { initialState } from "../../initialState";
import { createSlice } from "@reduxjs/toolkit";
import ReduxStore, { Action } from "../../types";

// Use the initialState as a default value
const visitorTrackingSlice = createSlice({
  name: "visitorTracking",
  initialState: initialState.visitorTracking,
  reducers: {
    updateVisitorTracking(
      _state,
      action: Action<ReduxStore["visitorTracking"]>
    ) {
      return action.payload;
    },
    clearVisitorTracking(state) {
      return state;
    },
  },
  extraReducers: (builders) => {
    builders.addDefaultCase((state) => state);
  },
});

export const { updateVisitorTracking, clearVisitorTracking } =
  visitorTrackingSlice.actions;

export default visitorTrackingSlice.reducer;
