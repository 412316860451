import LandingPageWrapper from "../pages/AppPages/Pages/components/LandingPageWrapper";
import { ShippingAddress } from "./types";

export const buildAddressString = (
  shippingAddress?: Partial<ShippingAddress>
): string => {
  const address = [
    shippingAddress?.address1,
    shippingAddress?.address2,
    shippingAddress?.city,
    shippingAddress?.state,
  ]
    .map((str) => str?.trim())
    .filter((str) => !!str)
    .join(", ");
  const zipCode = shippingAddress?.zipCode?.trim();
  if (zipCode) {
    return address + " " + zipCode;
  }
  return address;
};

// Assumption - we only go here when we have initialized the order

export const Checkout = () => {
  return (
    <LandingPageWrapper>
      <></>
    </LandingPageWrapper>
  );
};
