import { CampaignType, CheckoutEligibility } from "@brandclub/common-ui";
import { PageFilter } from "./misc";

export enum RewardAmountType {
  Fixed = "fixed",
  Percent = "percent",
}

export enum UserRewardStatus {
  Claimed = "claimed",
  Available = "available",
  Unavailable = "unavailable",
}

export type MatchedProduct = {
  retailerId: number;
  retailPrice: number;
  checkoutEligibility?: CheckoutEligibility;
};

export interface ProductReward {
  campaignId: string;
  campaignName: string;
  campaignStatus: string;
  campaignType: CampaignType;
  createdBy: string;
  createdTime: string;
  endDate: string;
  rewardAmount: number;
  rewardAmountType: RewardAmountType;
  rewardCurrency: string;
  startDate: string;
  updatedBy: string;
  updatedTime: string;
}

export interface ProductRewardSearchResult {
  retailPrice?: number;
  affiliateRewardAmount: number;
  childProductMinRetailPrice?: number;
  childProductMaxRetailPrice?: number;
  checkoutEligibility?: CheckoutEligibility;
  retailPriceDaily?: {
    dayId: string;
    value: number;
  }[];
  activeProductPromotion?: {
    promoType: string;
    isActive: boolean;
    listPrice: number;
    promoPrice: number;
  };
  totalRewardAmount?: number;
  subCategoryId?: number;
  campaignRewards?: ProductReward[];
  stacklineSku: string;
  retailerSku: string;
  title: string;
  brandName: string;
  brandId: number;
  hasActiveInstantReward: boolean;
  retailerId: number;
  reviewsRating: number;
  reviewsCount: number;
  searchScore: number;
  scores?: {
    maxPurchaseRewardPercent?: number;
    maxPurchaseRewardAmount?: number;
    maxAffiliateRewardPercent?: number;
    maxAffiliateRewardAmount?: number;
    totalReviewRewardFeesAmount?: number;
  };
  retailerProductPageUrl: string;
  dtcProductPageUrl?: string;
  // adding these for new product cards
  matchedProducts?: MatchedProduct[];
  dailyDealRewardInfo?: {
    rewardAmount: number;
    availableRewardCount: number;
    totalRewardCount: number;
    userRewardStatus: UserRewardStatus;
  };
  imageUrls?: string[];
}

export interface SearchResult<T = ProductRewardSearchResult> {
  items: T[];
  hasMore: boolean;
  total?: number;
  maxPages?: number;
  nextPage?: PageFilter;
  additionalResponseParams?: Record<string, unknown>;
}
