import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../../initialState";

const retailersSlice = createSlice({
  name: "retailers",
  initialState: initialState.retailers,
  reducers: {
    setRetailers: (_state, action) => {
      return action.payload;
    },
    clearRetailers: () => {
      return [];
    },
  },
});

export const { setRetailers, clearRetailers } = retailersSlice.actions;
export default retailersSlice.reducer;
