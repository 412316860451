import { useEffect, useState, useContext } from "react";
import StoreInput from "../../StoreComponents/StoreInput";
import AuthSolidButton from "./components/AuthSolidButton";
import _get from "lodash/get";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import StoreLoginOptionsDivider from "../../StoreComponents/StoreLogin/StoreLoginOptionsDivider";
import { validateEmail } from "../../../utils/StringUtils";
import { UserProfile } from "../../../redux/types";
import { updateProfile } from "../../../api";
import { UserLoginContext } from "./UserLoginProvider";
import { StoreThirdPartyLogin } from "../../StoreComponents/StoreLogin/StoreThirdPartyLogin";
import { setUserProfile } from "../../../redux/reducers/userProfile";
import { ButtonLoadingEllipsis } from "../../StoreComponents/StoreButton";
import { ReactComponent as UserCircle } from "../../../assets/user_circle.svg";
import { useTheme } from "@mui/material/styles";
import OTPWrapper from "./OTPWrapper";

interface SignUpError {
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  server?: string;
}

const defaultSignUpError: SignUpError = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  server: "",
};

const ROW_SPACING = 25;
const EMPTY_ERROR_MSG = "cannot be empty";

const SetupUserProfile = () => {
  const userProfile = useAppSelector(({ userProfile }) => userProfile);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const [errors, setErrors] = useState(defaultSignUpError);
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const { initialOpenUrl, send } = useContext(UserLoginContext);
  const setErrorMsg = (e: SignUpError) => {
    const newError = { ...errors, ...e };
    setErrors(newError);
  };

  const clearError = () => {
    setErrors(defaultSignUpError);
  };

  const onCreateAccountFormSubmit = async () => {
    try {
      setSubmitting(true);
      const req: Record<string, any> = {
        given_name: firstName,
        family_name: lastName,
        name: `${firstName} ${lastName}`,
        email: email,
      };
      const res = await updateProfile(req);
      if (res.data && !res.data.result.error) {
        dispatch(setUserProfile(res.data.result as UserProfile));
        send({ type: "SUBMIT" });
      } else if (res.data && res.data.status === "error") {
        setErrorMsg({ server: res.data.errorMessage });
      }
    } catch (e: any) {
      setErrorMsg({ server: e?.message });
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  const getErrors = () => {
    const newErrors: SignUpError = {};
    if ((firstName || "").trim() === "") {
      newErrors.firstName = EMPTY_ERROR_MSG;
    }

    if ((lastName || "").trim() === "") {
      newErrors.lastName = EMPTY_ERROR_MSG;
    }

    return newErrors;
  };

  useEffect(() => {
    if (userProfile) {
      const familyName = _get(userProfile, ["family_name"], "");
      const givenName = _get(userProfile, ["given_name"], "");
      const email = _get(userProfile, ["email"], "");
      setFirstName(givenName);
      setLastName(familyName);
      setEmail(email);
    }
  }, [userProfile]);

  const disabledNextButton =
    submitting || !firstName || !lastName || !validateEmail(email);

  const handleNextClick = () => {
    const newErrors = getErrors();

    if (
      newErrors.firstName ||
      newErrors.lastName ||
      newErrors.server ||
      newErrors.email
    ) {
      setErrors(newErrors);
    } else {
      onCreateAccountFormSubmit();
    }
  };

  return (
    <OTPWrapper>
      <div className="auth_container">
        <UserCircle fill={theme.palette.primary.main} width={75} height={75} />
        <div className="page_title">Let's fill in the blanks</div>
        <div className="page_subtitle">Please enter your details here.</div>
        {!userProfile?.email && (
          <StoreInput
            value={email}
            inputStyle={{ width: "100%" }}
            label="Email"
            style={{ marginTop: ROW_SPACING }}
            onChange={(e) => {
              clearError();
              setEmail(e.target.value);
            }}
            errorMsg={errors.email}
          />
        )}
        {!userProfile?.given_name && (
          <StoreInput
            value={firstName}
            onChange={(e) => {
              clearError();
              setFirstName(e.target.value);
            }}
            inputStyle={{ width: "100%" }}
            label="First name"
            style={{ marginTop: ROW_SPACING }}
          />
        )}
        {!userProfile?.family_name && (
          <StoreInput
            value={lastName}
            onChange={(e) => {
              clearError();
              setLastName(e.target.value);
            }}
            inputStyle={{ width: "100%" }}
            label="Last name"
            style={{ marginTop: ROW_SPACING }}
            errorMsg={errors?.server}
          />
        )}
        <AuthSolidButton
          disabled={disabledNextButton}
          style={{ marginTop: ROW_SPACING + 5 }}
          onClick={handleNextClick}
        >
          {submitting ? (
            <>
              Submitting
              <ButtonLoadingEllipsis baseFontSize={16} />
            </>
          ) : (
            "Sign Up"
          )}
        </AuthSolidButton>
        {!userProfile?.email && (
          <>
            <StoreLoginOptionsDivider style={{ margin: "20px 0px" }}>
              OR
            </StoreLoginOptionsDivider>
            <StoreThirdPartyLogin
              sx={{
                width: "100%",
              }}
              redirectPathname={initialOpenUrl?.pathname || ""}
              redirectSearch={initialOpenUrl?.search || ""}
            />
          </>
        )}
      </div>
    </OTPWrapper>
  );
};

export default SetupUserProfile;
