import { Site } from "@brandclub/types";
import axios, { axiosEdgeCached } from "../../../utils/axios";

export type SplitSitePagesResponse = {
  site: Omit<Site, "pages">;
  sitePages: Site;
};

export const getSiteForLandingPage = async ({
  entityId,
  entityType,
}: {
  entityId: string | number;
  entityType: "brand" | "product";
}) => {
  return await axiosEdgeCached.get<SplitSitePagesResponse>(
    `/cms/landing/${entityType}/${entityId}/site`
  );
};

export const getEntityForLandingPage = async ({
  entityId,
  entityType,
}: {
  entityId: string | number;
  entityType: "brand" | "product" | "category" | "subCategory";
}) => {
  return await axiosEdgeCached.get(
    `/cms/landing/${entityType}/${entityId}/entity`
  );
};

export const getSite = async ({
  siteId,
  versionId,
}: {
  siteId: string;
  versionId: number;
}) => {
  const {
    data: { site, sitePages },
  } = await axios.get<SplitSitePagesResponse>(
    `/cms/site/${siteId}/${versionId}`
  );

  return { ...site, pages: sitePages?.pages } as Site;
};
