import { Skeleton, styled } from "@mui/material";
import _groupBy from "lodash/groupBy";
import moment from "moment";
import { useMemo } from "react";
import { useAppSelector } from "../../../../../../redux/hooks";
import { StoreBrandingType } from "../../../../../../types/misc";
import { escapeUriPath } from "../../../../../../utils/StringUtils";
import useRetailerIdToNameMap from "../../../../../../utils/hooks/useRetailerIdToNameMap";
import { DashboardHeaderContainer } from "../../DashboardHeaderContainer";
import RewardCard from "./RewardCard";
import {
  getActivityExpiringCardRenderDetail,
  getPayoutCardRenderDetail,
} from "./helper";
import { DashboardStoreMessageCard } from "@/components/StoreComponents/StoreCard.tsx/DashboardStoreMessageCard";

export type RewardListType = "activity" | "expiring" | "payout";

const RewardListContainer = styled("div")(({ theme }) => ({
  ".reward_section": {
    // contentVisibility: "auto",
    // containIntrinsicSize: "auto 500px",
    marginBottom: 30,
    ".sec_title": {
      fontSize: 20,
      fontWeight: 700,
      color: theme.palette.primary.main,
      marginBottom: 20,
    },
    ".sec_data": {
      display: "flex",
      flexDirection: "column",
      gap: 20,
    },
  },
  ".empty_header": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ".empty_title": {
      maxWidth: 500,
      textAlign: "center",
      fontSize: 24,
      fontWeight: 700,
      color: theme.palette.primary.main,
      marginBottom: 15,
    },
    ".empty_subtitle": {
      maxWidth: 500,
      textAlign: "center",
      fontSize: 18,
      fontWeight: 600,
      color: theme.palette.primary.main,
      marginBottom: 30,
    },
  },
}));

interface ISection {
  title: string;
  data: any[];
}

const RewardList = ({
  listType,
  data,
  loading,
  headerText,
}: {
  listType: RewardListType;
  data?: any[];
  loading: boolean;
  headerText?: string;
}) => {
  const retailerIdToNameMap = useRetailerIdToNameMap();
  const appConfig = useAppSelector((state) => state.appConfig);

  const brandEntity = useAppSelector(({ brandEntity }) => brandEntity);

  const dataByDay = useMemo(() => {
    // if it's a payout list, filter out denied transactions
    const groups = _groupBy(
      listType === "payout"
        ? data?.filter((x) => x.transactionStatus !== "denied")
        : data,
      (r) => moment(r.createdTime).format("MMMM D, YYYY")
    );
    return Object.entries(groups).map(
      ([key, value]) => ({ title: key, data: value } as ISection)
    );
  }, [data, listType]);

  const rewardsForYouRedirectLink =
    appConfig?.domainConfig?.storeBrandingType ===
      StoreBrandingType.CustomDTCStore &&
    appConfig?.domainConfig?.customShopDomainName
      ? `https://${appConfig?.domainConfig?.customShopDomainName}/`
      : brandEntity?.entity
      ? `/${escapeUriPath(brandEntity?.entity?.name)}/b/${
          brandEntity?.entity?.brandId
        }/home`
      : "/";

  if (loading) {
    return (
      <RewardListContainer>
        <Skeleton
          variant="rectangular"
          width={100}
          height={27}
          style={{ borderRadius: 12, marginBottom: 20 }}
          sx={{ bgcolor: "#f6f8fa" }}
        />
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={198}
          style={{ borderRadius: 12, marginBottom: 20 }}
          sx={{ bgcolor: "#f6f8fa" }}
        />
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={198}
          style={{ borderRadius: 12, marginBottom: 20 }}
          sx={{ bgcolor: "#f6f8fa" }}
        />
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={198}
          style={{ borderRadius: 12, marginBottom: 20 }}
          sx={{ bgcolor: "#f6f8fa" }}
        />
      </RewardListContainer>
    );
  }
  if (!loading && !dataByDay?.length) {
    if (listType === "payout") {
      return (
        <RewardListContainer>
          {headerText ? (
            <DashboardHeaderContainer>
              <div className="title">{headerText}</div>
            </DashboardHeaderContainer>
          ) : null}
          <DashboardStoreMessageCard
            title="You haven’t received any payouts yet."
            content="Can we make some suggestions on how to start earning?"
            cta="Rewards for you"
            linkTo={rewardsForYouRedirectLink}
          />
        </RewardListContainer>
      );
    }
    if (listType === "expiring") {
      return (
        <RewardListContainer>
          {headerText ? (
            <DashboardHeaderContainer>
              <div className="title">{headerText}</div>
            </DashboardHeaderContainer>
          ) : null}
          <DashboardStoreMessageCard
            title="You don’t have any expiring rewards right now."
            content="Can we make some suggestions on more ways to earn?"
            cta="Rewards for you"
            linkTo={rewardsForYouRedirectLink}
          />
        </RewardListContainer>
      );
    }
    const emptyDisplay = "You don't have any previous activity yet.";

    return (
      <RewardListContainer>
        {headerText ? (
          <DashboardHeaderContainer>
            <div className="title">{headerText}</div>
          </DashboardHeaderContainer>
        ) : null}
        <DashboardStoreMessageCard
          title={emptyDisplay}
          content="Can we make some suggestions on more ways to earn?"
          cta="Rewards for you"
          linkTo={rewardsForYouRedirectLink}
        />
      </RewardListContainer>
    );
  }
  return (
    <RewardListContainer className="RewardListContainer">
      {headerText ? (
        <DashboardHeaderContainer>
          <div className="title">{headerText}</div>
        </DashboardHeaderContainer>
      ) : null}
      {dataByDay.map((section) => {
        return (
          <div key={section.title} className="reward_section">
            <div className="sec_title">{section.title}</div>
            <div className="sec_data">
              {section.data.map((row) => {
                const formatItem =
                  listType === "payout"
                    ? getPayoutCardRenderDetail(row)
                    : getActivityExpiringCardRenderDetail(row);
                return (
                  <RewardCard
                    key={
                      listType === "payout" ? row.transactionId : row.rewardId
                    }
                    item={formatItem}
                    type={listType}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </RewardListContainer>
  );
};

export default RewardList;
