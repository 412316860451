import { ContentRewardCampaignForCarousel } from "../../../../../types/misc";

import { LongCardList } from "../components/CardGrid";
import { PreviewHeader } from "../components/Header";
import ContentCard from "./components/ContentCard";
import { useFetchContent } from "./data";

import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";

import { useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import PreviewContainer from "../components/PreviewContainer";
import { useLinkPrefix } from "../../../../../utils/hooks/useLinkPrefix";

interface ContentEmptyRewardPreviewProps {
  brandclubIds?: string[];
}
const ContentEmptyRewardPreview = (props: ContentEmptyRewardPreviewProps) => {
  const ref = useRef(null);
  const widgetInView = useInView(ref, { once: true, margin: "100px" });
  const { linkPrefix } = useLinkPrefix();
  const { data, loading, fetchInit } = useFetchContent({
    pageSize: 4,
    autoInitFetch: false,
    brandclubIds: props.brandclubIds,
  });

  useEffect(() => {
    if (widgetInView) {
      fetchInit();
    }
  }, [widgetInView, fetchInit]);

  if (!loading && data.length === 0) {
    return null;
  }

  return (
    <>
      <PreviewContainer ref={ref}>
        {loading ? (
          <StoreLoadingSkeleton cardStyle="longCardList" header row={2} />
        ) : (
          <>
            <PreviewHeader
              title={"Content rewards"}
              linkTo={linkPrefix + "/contentrewards"}
              subtitle="Earn rewards for viewing content"
              indicator="blue"
            />

            <LongCardList>
              {data.map((d: ContentRewardCampaignForCarousel) => (
                <ContentCard
                  key={d.campaignId}
                  content={d}
                  brandName={d.brandclub?.name || ""}
                  border
                ></ContentCard>
              ))}
            </LongCardList>
          </>
        )}
      </PreviewContainer>
    </>
  );
};

export default ContentEmptyRewardPreview;
