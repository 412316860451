import { keyframes, styled } from "@mui/material";
import React from "react";
import {
  desktopHeaderHeight,
  mobileHeaderHeight,
} from "../../../StoreComponents/StoreSinglePageOutlet";
import { MOBILE_SCREEN_SIZE } from "../../../AppNavigation/constants";
import { useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

const ABSTRACT_ICON_URL_PREFIX =
  "https://media.brandclub.com/brandclub/icons/abstract/";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const antiSpin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`;

const Img = styled("img")({});

const Container = styled("div")({
  position: "absolute",
  width: "100%",
  height: `calc(100% - ${desktopHeaderHeight}px)`, // handle mobile here
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    height: `calc(100% - ${mobileHeaderHeight}px)`, // handle mobile here
  },

  ".abs_group": {
    height: "100%",
    width: "100%",
    // ".group_left": {
    //   height: "100%",
    //   width: "50%",
    //   paddingRight: "200px",
    // },
    // ".group_right": {
    //   height: "100%",
    //   width: "50%",
    //   paddingLeft: "200px",
    // },

    ".abs_icon": {
      objectFit: "contain",
      position: "absolute",
      // random to spin or anti-spin
    },
  },
});

const ABSTRACT_ICONS = Array.from({ length: 13 }, (_, i) => {
  return {
    id: i + 1,
    size: Math.floor(Math.random() * (76 - 60 + 1) + 60),
    url: `${ABSTRACT_ICON_URL_PREFIX}${i + 1}.webp`,
  };
});

// generate 4 random layout plans
const ABSTRACT_LAYOUTS = [
  [
    { left: "calc(72vw + 200px)", top: "10%" },
    {
      left: "calc(62vw + 200px)",
      top: `${Math.floor(Math.random() * (10 + 1) + 65)}%`,
    },
    {
      right: "calc(66vw + 200px)",
      top: `${Math.floor(Math.random() * (10 + 1) + 35)}%`,
    },
  ],
  [
    { right: "calc(72vw + 200px)", top: "10%" },
    {
      right: "calc(62vw + 200px)",
      top: `${Math.floor(Math.random() * (10 + 1) + 65)}%`,
    },
    {
      left: "calc(66vw + 200px)",
      top: `${Math.floor(Math.random() * (10 + 1) + 35)}%`,
    },
  ],
  [
    { left: "calc(72vw + 200px)", top: "10%" },
    {
      left: "calc(62vw + 200px)",
      top: `${Math.floor(Math.random() * (10 + 1) + 65)}%`,
    },
    {
      right: "calc(66vw + 200px)",
      top: `${Math.floor(Math.random() * (10 + 1) + 35)}%`,
    },
  ],
  [
    { right: "calc(72vw + 200px)", top: "10%" },
    {
      right: "calc(62vw + 200px)",
      top: `${Math.floor(Math.random() * (10 + 1) + 65)}%`,
    },
    {
      left: "calc(66vw + 200px)",
      top: `${Math.floor(Math.random() * (10 + 1) + 35)}%`,
    },
  ],
];

// make 5 groups of 3 random icons, each group has one random layout plan, next group has different layout
const ABSTRACT_ICON_GROUPS = Array.from({ length: 5 }, (_, i) => {
  // pick 3 random icons from ABSTRACT_ICONS, no repeat in a group
  const temp_icons = [...ABSTRACT_ICONS];

  const layoutToUse = ABSTRACT_LAYOUTS[i % ABSTRACT_LAYOUTS.length];

  return {
    id: i + 1,
    icons: Array.from({ length: 3 }, (_, j) => {
      const randomIndex = Math.floor(Math.random() * temp_icons.length);
      const randomIcon = temp_icons[randomIndex];
      temp_icons.splice(randomIndex, 1);
      return { ...randomIcon, style: layoutToUse[j] };
    }),
  };
});

const AuthAnimateBackground = () => {
  const location = useLocation();

  const [counter, setCounter] = React.useState(0);

  React.useEffect(() => {
    setCounter((prev) => prev + 1);
  }, [location?.pathname]);
  return (
    <>
      <Container className="auth_animate_bg">
        <AnimatePresence mode="wait">
          <motion.div
            className="abs_group"
            key={counter % ABSTRACT_ICON_GROUPS.length}
            initial={{ y: "70vh", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: "-20vh", opacity: 0 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            {ABSTRACT_ICON_GROUPS[
              counter % ABSTRACT_ICON_GROUPS.length
            ].icons.map((icon) => {
              return (
                <Img
                  key={icon.id}
                  alt="abs_icon"
                  className="abs_icon"
                  src={icon.url}
                  sx={{
                    animation: `${
                      Math.random() > 0.5 ? spin : antiSpin
                    } 5s infinite linear`,
                  }}
                  style={{ width: icon.size, height: icon.size, ...icon.style }}
                ></Img>
              );
            })}
          </motion.div>
        </AnimatePresence>
      </Container>
    </>
  );
};

export default AuthAnimateBackground;
