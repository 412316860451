import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import { CardGridForFour } from "../components/CardGrid";
import { LandingHeader } from "../components/Header";
import LandingContainer, {
  LandingContainerProps,
} from "../components/LandingContainer";

import RetailerCard from "./components/RetailerCard";
import { useFetchAccount } from "./data";

const AccountsConnectPreview = ({ sx, ...props }: LandingContainerProps) => {
  const { loading, connectedRetailers, disconnectedRetailers } =
    useFetchAccount({});
  if (
    !loading &&
    [...connectedRetailers, ...disconnectedRetailers].length === 0
  ) {
    return null;
  }

  return (
    <LandingContainer sx={{ paddingTop: 0, ...sx }} {...props}>
      {loading ? (
        <StoreLoadingSkeleton header row={2} />
      ) : (
        <>
          <LandingHeader
            title="Your accounts"
            subtitle="View the status of connected accounts"
          ></LandingHeader>
          <CardGridForFour style={{ marginTop: 20 }}>
            {[...connectedRetailers, ...disconnectedRetailers].map((r) => {
              return (
                <RetailerCard key={r.retailerId} retailer={r}></RetailerCard>
              );
            })}
          </CardGridForFour>
        </>
      )}
    </LandingContainer>
  );
};

export default AccountsConnectPreview;
