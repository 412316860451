import { gql } from "@apollo/client";

export const getContentForUserV2 = gql`
  query getContentForUserV2(
    $brandclubIds: [String!]
    $excludedBrandclubIds: [String!]
    $includedCampaignIds: [String!]
    $page: PageFilter
  ) {
    ContentForUserV2(
      brandclubIds: $brandclubIds
      excludedBrandclubIds: $excludedBrandclubIds
      includedCampaignIds: $includedCampaignIds
      page: $page
    ) {
      items {
        campaignId
        extendedAttributes
        brandclub {
          brandId
          imageUrl
          name
          brandclubId
        }
        hasRewardForContent
        content {
          description
          previewImageUrl
          title
          mediaDurationInSeconds
          successTitle
          successSubtitle
          pagesForCarousel {
            title
            isIntroPage
            mediaType
            thumbnail
            preTitle
            subtitle
            mediaUrl
            question {
              description
              type
              required
              responseOptions
              minResponseLength
              canSelectMultipleResponses
            }
          }
        }
      }
      hasMore
      nextPage
    }
  }
`;

export const getContentForUnauthedUser = gql`
  query getContentForUnauthedUser(
    $brandclubIds: [String!]
    $excludedBrandclubIds: [String!]
    $includedCampaignIds: [String!]
    $page: PageFilter
  ) {
    UnauthedContentForUser(
      brandclubIds: $brandclubIds
      excludedBrandclubIds: $excludedBrandclubIds
      includedCampaignIds: $includedCampaignIds
      page: $page
    ) {
      items {
        campaignId
        extendedAttributes
        brandclub {
          brandId
          imageUrl
          name
          brandclubId
        }
        hasRewardForContent
        content {
          description
          previewImageUrl
          title
          mediaDurationInSeconds
          successTitle
          successSubtitle
          pagesForCarousel {
            title
            isIntroPage
            mediaType
            thumbnail
            preTitle
            subtitle
            mediaUrl
            question {
              description
              type
              required
              responseOptions
              minResponseLength
              canSelectMultipleResponses
            }
          }
        }
      }
      hasMore
      nextPage
    }
  }
`;
