import { getAuthorizationHeader } from "../../../Auth";
import { CustomerCashBackRewardForTable } from "../../../types/misc";
import axios from "../../../utils/axios";

interface Reward {
  reward?: CustomerCashBackRewardForTable;
  status: "success" | "error";
  errorMessage?: string;
}

interface TransferVisitorActionsResponse {
  rewards: Reward[];
  status: "success" | "failed";
  errorMessage?: string;
}

export const transferVisitorActions = async () => {
  return await axios.post<TransferVisitorActionsResponse>(
    "/users/transferVisitorActions",
    {},
    {
      headers: {
        ...(await getAuthorizationHeader()),
      },
    }
  );
};
