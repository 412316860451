import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import { LongCardGrid } from "../components/CardGrid";
import { PreviewHeader } from "../components/Header";
import PreviewWidgetWrapper from "../components/PreviewWidgetWrapper";
import { useFetchSpotlightBrandProducts } from "./data";
import { escapeUriPath } from "../../../../../utils/StringUtils";
import { getEntityImage } from "@stackline/ui";
import { useRef, useEffect } from "react";
import { useInView } from "framer-motion";
import PreviewContainer from "../components/PreviewContainer";
import { defaultPreviewLazyLoadConfig } from "../../../../../utils/lazyLoadConfig";
import LongProductCard from "../../../../StoreComponents/StoreCard.tsx/LongProductCard";

const SpotlightBrandProductsPreview = () => {
  const ref = useRef(null);
  const { data, loading, spotlightBrand, fetchInit } =
    useFetchSpotlightBrandProducts({
      pageSize: 10,
      autoInitFetch: false,
    });
  const widgetInView = useInView(ref, defaultPreviewLazyLoadConfig);

  useEffect(() => {
    if (widgetInView) {
      fetchInit();
    }
  }, [widgetInView, fetchInit]);

  let linkTo = "";
  if (spotlightBrand) {
    linkTo = `/${escapeUriPath(spotlightBrand.name)}/b/${
      spotlightBrand.brandId
    }/home`;
  }

  if (!loading && data.length === 0) {
    return null;
  }
  return (
    <PreviewWidgetWrapper ref={ref}>
      <PreviewContainer>
        {loading ? (
          <StoreLoadingSkeleton header row={2} />
        ) : (
          <>
            <PreviewHeader
              logoImg={
                spotlightBrand?.imageUrl ??
                getEntityImage(spotlightBrand?.brandId ?? "", "brand")
              }
              title={spotlightBrand?.name ?? "Top picks"}
              linkTo={linkTo}
              subtitle={`Top picks from ${spotlightBrand?.name}`}
              indicator="grey"
            />
            <LongCardGrid>
              {data.map((p) => (
                <LongProductCard
                  key={`trending:${p.stacklineSku}`}
                  product={p}
                />
              ))}
            </LongCardGrid>
          </>
        )}
      </PreviewContainer>
    </PreviewWidgetWrapper>
  );
};

export default SpotlightBrandProductsPreview;
