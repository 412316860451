import {
  Action,
  combineReducers,
  configureStore,
  createListenerMiddleware,
} from "@reduxjs/toolkit";
import { ThunkDispatch } from "redux-thunk";
import { setupAllMiddleware } from "../middleware";
import appConfig from "../reducers/appConfig";
import brandEntity from "../reducers/brandEntity";
import categories from "../reducers/categories";
import checkout from "../reducers/checkout";
import customerSpendByBrand from "../reducers/customerSpendByBrand";
import departments from "../reducers/departments";
import mainEntity from "../reducers/mainEntity";
import referralReward from "../reducers/referralReward";
import retailers from "../reducers/retailers";
import rewards from "../reducers/rewards";
import topBrands from "../reducers/topBrands";
import userProfile from "../reducers/userProfile";
import visitorTracking from "../reducers/visitorTracking";
import brandApp from "../reducers/brandApp";

const rootReducer = combineReducers({
  appConfig,
  visitorTracking,
  mainEntity,
  brandEntity,
  retailers,
  rewards,
  checkout,
  userProfile,
  topBrands,
  categories,
  departments,
  referralReward,
  customerSpendByBrand,
  brandApp,
});

type AppAction = Action<any>;

export const listenerMiddleware = createListenerMiddleware({
  onError: (error) => console.error("middlewareError", error),
});
export const startAppListening =
  listenerMiddleware.startListening.withTypes<RootState, AppDispatch>();

setupAllMiddleware();

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().prepend(listenerMiddleware.middleware),
    preloadedState,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = ThunkDispatch<RootState, any, AppAction>;
export type AppStore = ReturnType<typeof setupStore>;

const store = setupStore();

export default store;
