import { motion } from "framer-motion";
import _cloneDeep from "lodash/cloneDeep";
import { useEffect, useState } from "react";
import { MINIMUM_ANSWERING_TIME } from ".";
import { ButtonLoadingEllipsis } from "../../../../../StoreComponents/StoreButton";
import { FlowSolidButtonGrey } from "../../components/FlowButtons";

import SurveyTimingNextButton from "../components/SurveyTimingButton";
import { useSurvey } from "./SurveyProvider";
import moment from "moment";
import Box from "@mui/material/Box";
import StoreSelect, {
  StoreSelectOptions,
} from "../../../../../StoreComponents/StoreSelect";
import { updateArray } from "../../../../../../utils/misc";
import { numberToString } from "../../../../../../utils/StringUtils";

const getDaysForMonth = (
  month: number | string,
  year: string | number
): number[] => {
  month = month || 2; // default to the month with fewest days (February)
  year = year || 1978; // default to non-leap year
  const daysInMonth = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
  const days = [];

  for (let i = 1; i <= daysInMonth; i++) {
    days.push(i);
  }

  return days;
};

const defaultValueOptions = ({
  startYear = 1900,
  maxYear = moment().year(),
}: {
  startYear?: number;
  maxYear?: number;
}) => {
  const years: StoreSelectOptions[] = [];
  const months: StoreSelectOptions[] = [];
  const days: StoreSelectOptions[] = [];

  for (let i = 1; i <= 31; i++) {
    days.push({ value: i.toString(), displayName: i.toString() });
  }

  for (let i = 1; i <= 12; i++) {
    months.push({
      value: i.toString(),
      displayName: moment()
        .month(i - 1)
        .format("MMMM"),
    });
  }

  for (let i = startYear; i <= maxYear; i++) {
    years.push({ value: i.toString(), displayName: i.toString() });
  }

  years.reverse(); // Use the latest date

  // Use the latest date
  const defaultYear = years[0].value;
  const defaultMonth = months[0].value;
  const defaultDay = days[0].value;

  return { years, months, days, defaultYear, defaultMonth, defaultDay };
};

const getDate = (year: string, month: string, day: string) => {
  if (year && month && day) {
    const date = moment(`${year}-${month}-${day}`, "YYYY-MM-DD");
    return date.isValid() ? date : undefined;
  }
  return undefined;
};

const defaultOptions = defaultValueOptions({
  // Assumption: user must be at least 18 to fill out this survey
  maxYear: moment().subtract(18, "years").year(),
});

const DateQuestion = () => {
  const {
    currentStep,
    question,
    moveToNextStep,
    moveToPreviousStep,
    answers,
    setAnswers,
    timer,
    setTimer,
    submitting,
  } = useSurvey();
  const ans = answers[currentStep]?.text ?? "";
  const date = moment(ans, "YYYY-MM-DD");
  const [year, setYear] = useState(numberToString(date.year()));
  const [month, setMonth] = useState(numberToString(date.month() + 1));
  const [day, setDay] = useState(numberToString(date.date()));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((timerState) => {
        const timerClone = [...timerState];
        timerClone[currentStep] = timerClone[currentStep] + 1;
        return timerClone;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [currentStep, setTimer]);

  const handleNextClick = () => {
    // save and move to next
    moveToNextStep();
  };

  const answerTime = timer[currentStep];
  const disableNext =
    answerTime < MINIMUM_ANSWERING_TIME ? true : !date.isValid();

  const setDate = (year: string, month: string, day: string) => {
    setYear(year);
    setMonth(month);
    setDay(day);
  };

  const saveAnswer = (update: {
    year?: string;
    month?: string;
    day?: string;
  }) => {
    const newYear = update.year ?? year;
    const newMonth = update.month ?? month;
    const newDay = update.day ?? day;
    setDate(newYear, newMonth, newDay);
    const date = getDate(newYear, newMonth, newDay);
    setAnswers((state) =>
      updateArray(state, currentStep, {
        type: "date",
        text: date?.isValid() ? date.format("YYYY-MM-DD") : "",
      })
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const timerClone = _cloneDeep(timer);
      if (timerClone[currentStep] < MINIMUM_ANSWERING_TIME) {
        timerClone[currentStep] = timerClone[currentStep] + 1;
        setTimer(timerClone);
      }
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, timer[currentStep]]);

  return (
    <>
      {" "}
      <motion.div
        key={question?.description}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={`question select`}
      >
        <div className="title">{question?.description}</div>
        <Box className="options select_options">
          <StoreSelect
            fullWidth
            value={month}
            label="Month"
            items={defaultOptions.months}
            onChange={(e) => {
              const itemValue = e.target.value as string;
              saveAnswer({ month: itemValue });
            }}
          />
          <StoreSelect
            fullWidth
            value={day}
            label="Day"
            items={getDaysForMonth(month, year).map((day) => ({
              value: day.toString(),
              displayName: day.toString(),
            }))}
            onChange={(e) => {
              const itemValue = e.target.value as string;
              saveAnswer({ day: itemValue });
            }}
          />
          <StoreSelect
            fullWidth
            value={year}
            label="Year"
            items={defaultOptions.years}
            onChange={(e) => {
              const itemValue = e.target.value as string;
              saveAnswer({ year: itemValue });
            }}
          />
        </Box>
      </motion.div>
      <div className="actions">
        <FlowSolidButtonGrey disabled={submitting} onClick={moveToPreviousStep}>
          Back
        </FlowSolidButtonGrey>
        <SurveyTimingNextButton
          answerTime={answerTime}
          onClick={handleNextClick}
          disabled={disableNext}
          text={
            submitting ? (
              <>
                Submitting
                <ButtonLoadingEllipsis baseFontSize={16} />
              </>
            ) : (
              "Next"
            )
          }
        />
      </div>
    </>
  );
};

export default DateQuestion;
