import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../../initialState";

const categoriesSlice = createSlice({
  name: "categories",
  initialState: initialState.categories,
  reducers: {
    setCategories: (_state, action) => {
      return {
        categories: action.payload,
        loading: false,
      };
    },
    setCategoriesLoading: (_state) => {
      return {
        ..._state,
        loading: true,
      };
    },
    clearCategories: () => {
      return {
        categories: undefined,
        loading: false,
      };
    },
  },
});

export const { setCategories, setCategoriesLoading, clearCategories } =
  categoriesSlice.actions;
export default categoriesSlice.reducer;
