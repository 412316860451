import { ShipmentStatus } from "../../../../../types/misc";

export const getShipmentStatus = (orderStatus: string | undefined) => {
  if (orderStatus === ShipmentStatus.DELIVERED) {
    return "Delivered";
  }
  if (orderStatus === ShipmentStatus.CANCELLED) {
    return "Cancelled";
  }
  if (orderStatus === ShipmentStatus.SHIPPED) {
    return "Shipped";
  }
  if (orderStatus === ShipmentStatus.UNKNOWN) {
    return "Processing";
  }
  return "Processing";
};
