import { Theme } from "@mui/material/styles";
import LottieUtils from "./lottie_utils";

export const progressLottieOptions = (theme: Theme, animation: any) => {
  const primaryColor = theme.palette.primary;
  let overrideAnimationData = null;
  const colors = LottieUtils.getAllColors(animation);
  overrideAnimationData = LottieUtils.replaceColors(
    animation,
    Object.fromEntries(colors.map((color) => [color, primaryColor.main]))
  );

  return {
    loop: true,
    autoplay: true,
    animationData: theme.dtc ? overrideAnimationData : animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
};
