import {
  ContentRewardCampaignForCarousel,
  RewardUnlockStrategy,
} from "../../../../../../types/misc";
import { styled } from "@mui/material";
import FlowContainer from "../../components/FlowContainer";
import { formatMoney } from "../../../../../../utils/StringUtils";
import {
  CampaignType,
  RewardPillWithText,
  brandclub_colors,
} from "@brandclub/common-ui";
import { FlowSolidButton } from "../../components/FlowButtons";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../../AppNavigation/constants";

const CoverContainer = styled("div")(({ theme }) => ({
  maxWidth: 500,
  padding: 20,
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  flex: 1,

  ".logo": {
    maxWidth: 225,
    maxHeight: 225,
    objectFit: "contain",
    borderRadius: 999,

    aspectRatio: 1,
    marginBottom: 40,
  },
  ".title": {
    marginBottom: 15,
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.primary.main,
    textAlign: "center",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 24,
    },
  },
  ".subtitle": {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 16,
    color: theme.palette.primary.main,
    textAlign: "center",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 18,
    },
  },
  ".est": {
    marginTop: 20,
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
}));

const StyledRewardPill = styled(RewardPillWithText)(({ theme }) => ({
  columnGap: 12,
  ".reward-pill": {
    fontSize: 16,
    paddingTop: "5.5px",
    paddingBottom: "5.5px",
    paddingLeft: "15px",
    paddingRight: "15.9px",
  },
  ".rewardPercent-text": {
    fontSize: 16,
  },
}));

const Cover = ({
  content,
  moveNext,
}: {
  content: ContentRewardCampaignForCarousel;
  moveNext: () => void;
}) => {
  const estimatedMinutes = Math.max(
    Math.floor((content?.content?.mediaDurationInSeconds || 0) / 60),
    1
  );
  const rewardAmount = content?.extendedAttributes?.reward?.reward?.amount ?? 0;
  const hasInstantReward =
    content?.extendedAttributes?.rewardUnlockStrategy ===
    RewardUnlockStrategy.Instant;
  return (
    <FlowContainer style={{ background: brandclub_colors.white }}>
      <CoverContainer>
        <img className="logo" alt="logo" src={content.brandclub?.imageUrl} />
        <div className="title">Content reward</div>
        <div className="subtitle">
          {`You will earn ${formatMoney(
            content.extendedAttributes.reward.reward.amount
          )} for viewing this content from ${
            content?.brandclub?.name || "this brand"
          }.`}
        </div>
        <StyledRewardPill
          rewardAmount={rewardAmount ?? 0}
          rewardCampaignType={
            hasInstantReward
              ? CampaignType.DailyDealsRewardCampaign
              : CampaignType.ContentRewardCampaign
          }
          containerStyle={{}}
          baseFontSize={14}
          rewardPercentTextOverride={
            hasInstantReward ? "instant reward" : "content reward"
          }
        />
        <FlowSolidButton
          style={{ width: "80%", maxWidth: 355, marginTop: 40 }}
          onClick={() => {
            moveNext();
          }}
        >
          Continue
        </FlowSolidButton>
        <div className="est">
          Estimated time: {estimatedMinutes} minute
          {estimatedMinutes > 1 ? "s" : ""}
        </div>
      </CoverContainer>
    </FlowContainer>
  );
};

export default Cover;
