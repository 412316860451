import { createContext, useContext, ReactElement } from "react";
import { useActor } from "@xstate/react";
import { ActorRefFrom, ActorLogicFrom, SnapshotFrom } from "xstate";
import { globalCheckoutMachine } from "./GlobalCheckoutMachine";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "@/redux/hooks";
import ReduxStore from "@/redux/types";
import ServerSideCheckoutPoll from "../ServerSideCheckoutPoll";

type GlobalCheckoutContextType = {
  snapshot: SnapshotFrom<typeof globalCheckoutMachine>;
  send: ActorRefFrom<ActorLogicFrom<typeof globalCheckoutMachine>>["send"];
  actorRef: ActorRefFrom<typeof globalCheckoutMachine>;
  useCartProduct: (stacklineSku: string) => any;
};

export const GlobalCheckoutContext = createContext<GlobalCheckoutContextType>(
  {} as GlobalCheckoutContextType
);

export const useCartProduct = (stacklineSku: string) => {
  const cartProducts = useAppSelector(
    (state: ReduxStore) => state.checkout.products
  );
  return cartProducts?.[stacklineSku] ?? null;
};

export const CheckoutProvider = ({ children }: { children: ReactElement }) => {
  const navigate = useNavigate();
  const actor = useActor(globalCheckoutMachine, {
    input: {
      context: {
        navigate,
      },
    },
  });

  const [snapshot, send, actorRef] = actor;

  return (
    <GlobalCheckoutContext.Provider
      value={{
        snapshot,
        send,
        actorRef,
        useCartProduct,
      }}
    >
      <ServerSideCheckoutPoll />
      {children}
    </GlobalCheckoutContext.Provider>
  );
};

export const useCheckout = () => {
  return useContext(GlobalCheckoutContext);
};
