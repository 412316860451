import { styled } from "@mui/material";
import React from "react";
import { formatMoney } from "../../../../utils/StringUtils";

const RewardFooterWrapper = styled("div")(({ theme }) => ({
  marginTop: 30,
  display: "flex",
  alignItems: "center",
  width: "calc(100% - 24px)",
  justifyContent: "space-between",
  ".label": {
    fontWeight: 600,
    fontSize: 14,
    maxWidth: 120,
    color: theme.palette.primary.main,
  },
  ".amount": {
    fontWeight: 600,
    fontSize: 24,
    color: theme.palette.primary.main,
    position: "relative",
    ".left_confetti": {
      position: "absolute",
      top: "-10px",
      left: "-20px",
    },
    ".right_confetti": {
      position: "absolute",
      bottom: "-8px",
      right: "-20px",
    },
  },
}));

const RewardFooter = ({ rewardAmount }: { rewardAmount: number }) => {
  return (
    <RewardFooterWrapper>
      <div className="label">Your total cash earnings</div>
      <div className="amount">
        <img
          className="left_confetti"
          alt="cfd"
          src="https://media.brandclub.com/brandclub/icons/left-confetti.svg"
        ></img>
        <img
          className="right_confetti"
          alt="cfd"
          src="https://media.brandclub.com/brandclub/icons/right-confetti.svg"
        ></img>
        {formatMoney(rewardAmount)}
      </div>
    </RewardFooterWrapper>
  );
};

export default RewardFooter;
