import { useState } from "react";

import { useAppSelector } from "../../../../../redux/hooks";

export const useFetchMyClubs = () => {
  const [error] = useState(null);

  const { topBrands: data, loading } = useAppSelector(
    (state) => state.topBrands
  );
  return { data, loading, error, hasMore: false };
};
