import React from "react";
import { getEntityImage } from "../../../../../../utils/StringUtils";
import { styled } from "@mui/material";

import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../../AppNavigation/constants";
import { decodeHTMLCharacters } from "@stackline/ui";

const ProductHeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 14,
  ".product_img": {
    width: 75,
    height: 75,
    objectFit: "contain",
  },
  ".product_title": {
    flex: 1,
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.primary.main,
    letterSpacing: 0.36,
    WebkitLineClamp: 3,
    lineHeight: 1.5,
    // height: `3em`, // line height * 2
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-word",
    overflow: "hidden",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 12,
    },
  },
}));

const ProductHeader = ({ product }: { product: any }) => {
  const cleanProductTitle = decodeHTMLCharacters(product?.title || "");
  return (
    <ProductHeaderContainer>
      <img
        className="product_img"
        alt="logo"
        src={getEntityImage(product.stacklineSku, "product")}
      />
      <div className="product_title">{cleanProductTitle}</div>
    </ProductHeaderContainer>
  );
};

export default ProductHeader;
