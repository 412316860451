import { LandingHeader } from "../components/Header";
import { useFetchAccount } from "./data";
import { CardGridForFour } from "../components/CardGrid";

import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import RetailerCard from "./components/RetailerCard";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import LandingContainer from "../components/LandingContainer";

const AccountsDisconnectPreview = () => {
  const { loading, connectedRetailers } = useFetchAccount({});
  const navigate = useNavigate();
  useEffect(() => {
    if (!loading && connectedRetailers.length === 0) {
      // Navigate user to accounts so they can connect
      navigate("/dashboard/accounts");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedRetailers, loading]);

  return (
    <>
      <LandingContainer sx={{ paddingTop: 0 }}>
        {loading ? (
          <StoreLoadingSkeleton header row={2} />
        ) : (
          <>
            <LandingHeader
              title="Edit your accounts"
              subtitle="Disconnecting will allow you to restart and setup a new account"
            ></LandingHeader>
            <CardGridForFour style={{ marginTop: 20 }}>
              {[...connectedRetailers].map((r) => {
                return (
                  <RetailerCard
                    disconnect
                    key={r.retailerId}
                    retailer={r}
                  ></RetailerCard>
                );
              })}
            </CardGridForFour>
          </>
        )}
      </LandingContainer>
    </>
  );
};

export default AccountsDisconnectPreview;
