import { gql } from "@apollo/client";

export const getBuyItAgainProductsV2 = gql`
  query getBuyItAgainProductsV2($page: PageFilter) {
    BuyItAgainProductsV2(page: $page) {
      items {
        stacklineSku
        title
        brandId
        retailerId
        retailerSku
        retailPrice
        totalRewardAmount
        scores {
          maxPurchaseRewardPercent
          maxPurchaseRewardAmount
          maxAffiliateRewardPercent
          maxAffiliateRewardAmount
          totalReviewRewardFeesAmount
        }
        hasActiveInstantReward
        affiliateRewardAmount
        activeProductPromotion {
          promoType
          isActive
          listPrice
          promoPrice
        }
        matchedProducts {
          retailPrice
          retailerId
        }
        campaignRewards {
          campaignId
          campaignType
          rewardAmount
          rewardPercent
          rewardAmountType
          campaignStatus
        }

        retailerProductPageUrl
        childProductMinRetailPrice
        childProductMaxRetailPrice
      }
    }
  }
`;
