import ContentFlow from "../pages/AppPages/Pages/Content/flow";
import ReviewFlow from "../pages/AppPages/Pages/Review/flow";
import SurveyFlow from "../pages/AppPages/Pages/Survey/flow";
import EnterCheckoutFlow from "../Checkout/components/CheckoutFlow/EnterCheckoutFlow";

import { JoinBrandStoreBrandClubSuccess } from "../pages/AppPages/BrandStore/JoinBrandstoreBrandclubSuccess";
import CheckoutFlow from "../Checkout/components/CheckoutFlow";
import { StoreNavAction } from "../StoreComponents/StoreSinglePageOutlet";
import { AppRoutesWithChildrenProps } from "./routeBuilder";
import MembershipProgram from "../pages/AppPages/MembershipProgram";

export const singlePageCustomCloseRoutes: AppRoutesWithChildrenProps[] = [
  {
    path: "joinclubsuccess/:brandId",
    index: true,
    element: <JoinBrandStoreBrandClubSuccess />,
  },
];
export const membershipProgramRoutes: AppRoutesWithChildrenProps[] = [
  {
    path: "membership/",
    index: true,
    element: <MembershipProgram />,
  },
];

export const dtcCheckoutRoutes: AppRoutesWithChildrenProps[] = [
  {
    path: "checkout/",
    element: <CheckoutFlow />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.ToBagAndKillCheckout,
      },
    },
    pageType: "Checkout",
  },
  { path: "entercheckout/", element: <EnterCheckoutFlow /> },
];

export const dtcSinglePageRoutes: AppRoutesWithChildrenProps[] = [
  {
    path: "content/:campaignId",
    element: <ContentFlow />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.Back,
      },
    },
    pageType: "Content",
  },
  {
    path: "survey/:campaignId",
    element: <SurveyFlow />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.Back,
      },
    },
    pageType: "Survey",
  },
  {
    path: "review/:stacklineSku",
    element: <ReviewFlow />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.Back,
      },
    },
    pageType: "Review",
  },
];

export const singlePageRoutes: AppRoutesWithChildrenProps[] = [
  {
    path: "checkout/",
    element: <CheckoutFlow />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.ToBagAndKillCheckout,
      },
    },
    pageType: "Checkout",
  },
  {
    path: "content/:campaignId",
    element: <ContentFlow />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.Back,
      },
    },
    pageType: "Content",
  },
  {
    path: "survey/:campaignId",
    element: <SurveyFlow />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.Back,
      },
    },
    pageType: "Survey",
  },
  {
    path: "review/:stacklineSku",
    element: <ReviewFlow />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.Back,
      },
    },
    pageType: "Review",
  },
  { path: "entercheckout/", element: <EnterCheckoutFlow /> }
];
