import { styled } from "@mui/material";
import { motion } from "framer-motion";
import React from "react";
import {
  desktopHeaderHeight,
  mobileHeaderHeight,
} from "../../../../StoreComponents/StoreSinglePageOutlet";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../AppNavigation/constants";

const Container = styled(motion.div)({
  width: "100%",
  minHeight: `calc(100dvh - ${desktopHeaderHeight}px)`,
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  paddingBottom: 30,
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    justifyContent: "flex-start",
  },
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    justifyContent: "flex-start",
    minHeight: `calc(100dvh - ${mobileHeaderHeight}px)`,
  },
  ".content": {},
  ".actions": {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    ".back_button": {},
    ".next_button": {},
  },
});
const FlowContainer = ({
  children,
  style = {},
}: {
  children: React.ReactNode | React.ReactNode[];
  style?: React.CSSProperties;
}) => {
  return (
    <Container
      // transition={{ duration: 3 }}
      style={style}
    >
      {children}
    </Container>
  );
};

export default FlowContainer;
