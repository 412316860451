import { useEffect, useMemo, useState } from "react";
import useStoreType from "./useStoreType";
import { getCustomTheme, ThemeResponse } from "../../api";
import { StoreBrandingType } from "../../types/misc";
import { useAppSelector } from "../../redux/hooks";
import { AppConfigTheme } from "../../redux/types";
import { ThemeOptions } from "@mui/material/styles";

const buildDtcTheme = (theme: ThemeResponse): ThemeOptions => ({
  palette: {
    primary: {
      main: theme.primary,
    },
    secondary: {
      main: theme.secondary,
    },
  },
  fontWeight: theme.dtcFonts?.fontWeight,
  customTypography: {
    fontFamily: theme.dtcFonts?.fontFamily as string,
    baseFontSize: theme.dtcFonts?.baseFontSize as number,
    fontFamilyLink: theme.dtcFonts?.fontFamilyLink as string,
  },
  barFonts: {
    fontFamily: theme.barFonts?.fontFamily as string,
    baseFontSize: theme.barFonts?.baseFontSize as number,
    fontFamilyLink: theme.barFonts?.fontFamilyLink as string,
  },
  baseFontSize: theme.dtcFonts?.baseFontSize as number,
  typography: {
    fontFamily: [
      theme.dtcFonts?.fontFamily as string,
      "Poppins",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },

  componentStyleOverrides: theme.componentStyleOverrides,
  globalButtonRadius: theme.globalButtonRadius,
  dtc: true,
});

const buildCustomStoreTheme = (theme: AppConfigTheme): ThemeOptions => ({
  palette: {
    primary: {
      main: theme.primaryColor,
    },
    secondary: {
      main: theme.secondaryColor,
    },
    tertiary: {
      main: theme.tertiaryColor,
    },
    headerColor: {
      main: theme.headerColor,
    },
  },
  baseFontSize: theme.baseFontSize,
  typography: {
    fontFamily: [
      "Poppins",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export const useCustomTheme = () => {
  const [theme, setTheme] = useState<ThemeOptions | undefined>(undefined);
  const brandAppThemeConfig = useAppSelector(
    ({ appConfig }) => appConfig?.brandAppThemeConfig
  );
  const storeType = useStoreType();
  const isDtc = storeType === StoreBrandingType.CustomDTCStore;

  const brandAppTheme = useMemo(() => {
    return (
      brandAppThemeConfig?.theme &&
      buildCustomStoreTheme(brandAppThemeConfig.theme)
    );
  }, [brandAppThemeConfig]);

  useEffect(() => {
    const fetchDtcTheme = async () => {
      const theme = await getCustomTheme();
      setTheme(theme && buildDtcTheme(theme));
      if (theme?.dtcFonts?.fontFamily) {
        const link = document.createElement("link");
        link.rel = "preload stylesheet";
        link.href = theme.dtcFonts.fontFamilyLink || theme.dtcFonts.fontFamily;
        const barLink = document.createElement("link");
        barLink.rel = "preload stylesheet";
        barLink.href = theme.barFonts.fontFamilyLink || theme.barFonts.fontFamily;
        document.head.appendChild(barLink);
        document.head.appendChild(link);
      }
    };
    if (isDtc) {
      fetchDtcTheme();
    }
  }, [isDtc]);

  switch (storeType) {
    case StoreBrandingType.CustomDTCStore:
      return { theme };
    case StoreBrandingType.CustomStore:
      return { theme: brandAppTheme };
    default:
      return undefined;
  }
};
