import { useMediaQuery } from "@mui/material";
import { useMemo } from "react";
import { MOBILE_SCREEN_SIZE } from "../../components/AppNavigation/constants";

/**
 * returns a subset of the provided data array if the screen size is less than or equal to the specified `maxWidth`,
 * otherwixe the entire data is returned unmodified.
 *
 * @example
 * const data = [1,2,3,4,5]
 * size = 3
 * maxWidth = 500
 *
 * const result = useResponsiveData(data, size, maxWidth)
 * // result = data when screen size is > maxWidth
 * // result = [1,2,3] when screen size is <= maxWidth
 *
 * @param data the array to modify
 * @param size **Default is 4**. The size of the array to return if the screen size is less than the `maxWidth`
 * @param maxWidth **Default is MOBILE_SCREEN_SIZE**. The maximum screen size where the size restriction should be applied to the data.
 */
export const useResponsiveData = <T>(
  data: T[],
  size = 4,
  maxWidth = MOBILE_SCREEN_SIZE
) => {
  const matchesScreenSize = useMediaQuery(`(max-width:${maxWidth}px)`);
  return useMemo(() => {
    if (matchesScreenSize) {
      return data.slice(0, size);
    }
    return data;
  }, [data, matchesScreenSize, size]);
};
