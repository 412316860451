import Sync from "../pages/AppPages/Sync";
import ConnectFullScreen from "../pages/AppPages/Sync/Connect/ConnectFullScreen";
import SyncOutlet from "../pages/AppPages/Sync/SyncOutlet";
import SyncSummary from "../pages/AppPages/Sync/SyncSummary/SyncSummary";
import SyncSummaryOutlet from "../pages/AppPages/Sync/SyncSummary/SyncSummaryOutlet";
import { AppRoutesWithChildrenProps } from "./routeBuilder";

const syncChildRoutes: AppRoutesWithChildrenProps[] = [
  {
    element: <SyncOutlet />,
    children: [{ path: "", index: true, element: <Sync /> }],
  },
  {
    element: <SyncSummaryOutlet />,
    children: [{ path: "summary/:syncBatchId", element: <SyncSummary /> }],
  },
];

export const syncRoutes: AppRoutesWithChildrenProps[] = [
  { path: "sync/", children: syncChildRoutes },
];

export const connectRoutes: AppRoutesWithChildrenProps[] = [
  { path: "connect/", element: <ConnectFullScreen /> },
];
