import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../../initialState";

const referralRewardSlice = createSlice({
  name: "referralReward",
  initialState: initialState.referralReward,
  reducers: {
    setReferralReward: (_state, action) => {
      return {
        amountForReferringUser: action.payload.amountForReferringUser,
        amountForInstallingUser: action.payload.amountForInstallingUser,
      };
    },

    clearReferralReward: () => {
      return {
        amountForReferringUser: null,
        amountForInstallingUser: null,
      };
    },
  },
});

export const { setReferralReward, clearReferralReward } =
  referralRewardSlice.actions;
export default referralRewardSlice.reducer;
