import { createSlice } from "@reduxjs/toolkit";
import {
  CLEAR_TOP_BRANDS,
  SET_TOP_BRANDS,
  SET_TOP_BRANDS_LOADING,
} from "../../actions/types";
import { initialState } from "../../initialState";
import ReduxStore, { Action, TopBrand } from "../../types";

export function topBrands1(
  state: ReduxStore["topBrands"] = initialState.topBrands,
  action: Action & {
    payload: {
      topBrands: TopBrand[];
    };
  }
): ReduxStore["topBrands"] {
  switch (action.type) {
    case CLEAR_TOP_BRANDS:
      return {
        topBrands: [],
        loading: false,
      };
    case SET_TOP_BRANDS:
      return {
        ...state,
        topBrands: action.payload.topBrands,
        loading: false,
      };
    case SET_TOP_BRANDS_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}

const topBrands = createSlice({
  name: "topBrands",
  initialState: initialState.topBrands,
  reducers: {
    setTopBrands: (state, action) => {
      return { ...state, topBrands: action.payload, loading: false };
    },
    setTopBrandLoading(state) {
      return {
        ...state,
        loading: true,
      };
    },

    // update brandEntity
    clearUserProfile(_state) {
      return {
        topBrands: undefined,
        loading: false,
      };
    },
  },
});

export const { setTopBrands, setTopBrandLoading, clearUserProfile } =
  topBrands.actions;
export default topBrands.reducer;
