import { useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as LockedAccount } from "../../../../assets/locked-account.svg";
import { ReactComponent as RightArrow } from "../../../../assets/right-arrow.svg";
import { ButtonLoadingEllipsis } from "../../../StoreComponents/StoreButton";
import StoreInput from "../../../StoreComponents/StoreInput";
import AuthSolidButton from "../components/AuthSolidButton";
import OTPWrapper from "../OTPWrapper";
import { UserLoginContext } from "../UserLoginProvider";
import { startUpdatePhoneFlow, verifyEmailWithOTP } from "./api";

const ROW_SPACING = 20;

const VerifyEmail = () => {
  const [resendCoolDown, setResendCoolDown] = React.useState(0);
  const [code, setCode] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const { send, snapshot } = useContext(UserLoginContext);
  const theme = useTheme();

  const { session, sessionId, emailToVerify, otpPhoneNumber } =
    snapshot.context;

  useEffect(() => {
    if (resendCoolDown > 0) {
      setTimeout(() => setResendCoolDown(resendCoolDown - 1), 1000);
    } else {
      setResendCoolDown(0);
    }
  }, [setResendCoolDown, resendCoolDown]);

  const verifyOtp = async (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e?.preventDefault();
    setSubmitting(true);
    try {
      if (!session || !sessionId) {
        throw new Error("Session is not defined");
      }
      const res = await verifyEmailWithOTP({
        session,
        sessionId,
        emailVerificationCodeInput: code,
      });

      if (res.status === "success") {
        setCode("");
        send({
          type: "SUBMIT_OTP_FOR_UPDATE_PHONE",
          session: res.session,
          sessionId,
        });
      } else {
        setErrorMsg(`An error occurred: Please try again after some time`);
      }
    } catch (e) {
      setErrorMsg(`An error occurred: Please try again after some time`);
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  const resendOtp = async () => {
    setResendCoolDown(30);
    try {
      if (!otpPhoneNumber) {
        throw new Error("Phone number is not defined");
      }
      const res = await startUpdatePhoneFlow({ phoneNumber: otpPhoneNumber });
      console.log(res);
      if (res.status === "success") {
        send({
          type: "FOUND_ACCOUNT",
          session: res.session,
          sessionId: res.sessionId,
          emailToVerify: res.emailToVerify,
          otpPhoneNumber,
        });
      } else {
        setErrorMsg(`An error occurred: Please try again after some time`);
      }
    } catch (e: any) {
      setErrorMsg(`An error occurred: Please try again after some time`);
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  const clearErrors = () => {
    setErrorMsg("");
  };

  return (
    <OTPWrapper>
      <div className="auth_container">
        <LockedAccount
          style={{
            width: 75,
            height: 75,
            fill: theme.palette.primary.main,
          }}
        />
        <div className="page_title">Verify your email address</div>
        <div className="page_subtitle">
          Please enter the verification code sent to {emailToVerify} here.
        </div>
        <StoreInput
          errorMsg={errorMsg || ""}
          value={code}
          onChange={(e) => {
            clearErrors();
            setCode(e.target.value);
          }}
          placeholder="Verification code"
          inputStyle={{ width: "100%" }}
          style={{ marginTop: ROW_SPACING }}
        ></StoreInput>
        <AuthSolidButton
          disabled={submitting || code.length === 0}
          style={{ marginTop: ROW_SPACING }}
          onClick={verifyOtp}
        >
          {submitting ? (
            <>
              Submitting
              <ButtonLoadingEllipsis baseFontSize={16} />
            </>
          ) : (
            "Continue"
          )}
        </AuthSolidButton>
        {resendCoolDown <= 0 ? (
          <div className="resend">
            Please click{" "}
            <span
              className="click"
              onClick={() => {
                setResendCoolDown(30);
                resendOtp();
              }}
            >
              here
            </span>{" "}
            to resend the code.
          </div>
        ) : (
          <div className="resend">
            We sent the new code.
            <br />
            You can resend in {resendCoolDown}s.
          </div>
        )}
        <a
          href="/contact/support"
          target="_blank"
          rel="noreferrer"
          className="resend"
          style={{
            textDecoration: "none",
            marginTop: "10px",
          }}
        >
          Contact customer support <RightArrow />
        </a>
      </div>
    </OTPWrapper>
  );
};

export default VerifyEmail;
