import {
  ProductRewardSearchResult,
  tryGetVisitorId,
} from "@brandclub/common-ui";
import { getAuthorizationHeader, getVisitorIdCookie } from "../../Auth";
import { axiosRetailerSync } from "../../utils/axios";
import {
  ShoppingCartItem,
  UserRetailerCheckoutBatch,
  UserRetailerCheckoutBatchStatus,
  UserRetailerCheckoutEvent,
  UserRetailerCheckoutOrder,
  UserRetailerCheckoutSessionStatus,
  UserRetailerShoppingCart,
} from "@/components/Checkout/types";

import { RetailerSyncSession, SyncStatus } from "../../types/misc";

export interface IProducts {
  [retailerSku: string]: ProductRewardSearchResult;
}

async function getHeadersWithUniqueVisitorId() {
  const uniqueVisitorId = await tryGetVisitorId();
  const uniqueVisitorIdFromCookie = getVisitorIdCookie();

  const uniqueVisitorIdToUse =
    uniqueVisitorIdFromCookie && uniqueVisitorIdFromCookie !== ""
      ? uniqueVisitorIdFromCookie
      : uniqueVisitorId;
  return {
    ...(await getAuthorizationHeader()),
    "x-bc-visitor-id": uniqueVisitorIdToUse,
  };
}

async function getHeaders() {
  return await getHeadersWithUniqueVisitorId();
}

export const getAllUserRetailerShoppingCarts = async (signal?: AbortSignal) => {
  const { data } = await axiosRetailerSync.get(
    `/retailerCheckout/getAllUserRetailerShoppingCarts`,
    {
      headers: await getHeadersWithUniqueVisitorId(),
      signal,
    }
  );

  return data as {
    userRetailerShoppingCarts: UserRetailerShoppingCart[];
    products: IProducts;
  };
};

export const resetUserRetailerCurrentShoppingCart = async (
  {
    retailerId,
  }: {
    retailerId: number;
  },
  uniqueVisitorId?: string
) => {
  const { data } = await axiosRetailerSync.post(
    `/retailerCheckout/resetUserRetailerCurrentShoppingCart?retailerId=${retailerId}`,
    null,
    {
      headers: await getHeadersWithUniqueVisitorId(),
    }
  );
  return data as { userRetailerShoppingCart: UserRetailerShoppingCart };
};

const mapShoppingCartItemsToRequest = (
  items: ShoppingCartItem[]
): {
  brandId: number;
  stacklineSku: string;
  quantity: number;
  retailPrice: number;
  retailerSku: string;
  retailerId: number;
}[] => {
  return items.map((item) => ({
    brandId: item.brandId,
    stacklineSku: item.stacklineSku,
    quantity: item.quantity,
    retailPrice: item.retailPrice,
    retailerSku: item.retailerSku,
    retailerId: item.retailerId,
  }));
};

export const updateItemsToUserRetailerShoppingCart = async (
  {
    retailerId,
    items,
  }: {
    retailerId: number;
    items: ShoppingCartItem[];
  },
  uniqueVisitorId?: string,
  signal?: AbortSignal
) => {
  const { data } = await axiosRetailerSync.post(
    `/retailerCheckout/updateItemsToUserRetailerShoppingCart`,
    { retailerId, items: mapShoppingCartItemsToRequest(items) },
    {
      headers: await getHeadersWithUniqueVisitorId(),
      signal,
    }
  );
  return data as { userRetailerShoppingCart: UserRetailerShoppingCart };
};

export const removeItemFromShoppingCart = async (
  {
    retailerId,
    items,
  }: {
    retailerId: number;
    items: ShoppingCartItem[];
  },
  signal?: AbortSignal
) => {
  const request = {
    retailerId,
    items: mapShoppingCartItemsToRequest(items),
  };

  const { data } = await axiosRetailerSync.post(
    `/retailerCheckout/removeItemsToUserRetailerShoppingCart`,
    request,
    {
      headers: await getHeadersWithUniqueVisitorId(),
      signal,
    }
  );
  return data as { userRetailerShoppingCart: UserRetailerShoppingCart };
};

export const moveAllUserRetailerShoppingCarts = async (
  uniqueVisitorId: string
) => {
  const { data } = await axiosRetailerSync.post(
    `/retailerCheckout/moveAllUserRetailerShoppingCarts`,
    null,
    {
      headers: await getHeadersWithUniqueVisitorId(),
    }
  );
  return data;
};

export const initiateRetailerCheckout = async (req: {
  retailerId: number;
  retailerIds: number[];
  checkoutBatchId: string;
  checkoutMode: string;
  currentStamp?: number;
  connectionInfo?: string;
}) => {
  const { data } = await axiosRetailerSync.post(
    `/retailerCheckout/initiateRetailerCheckout`,
    req,
    {
      headers: await getHeaders(),
    }
  );
  return data as {
    userRetailerCheckoutBatch: UserRetailerCheckoutBatch;
    userRetailerCheckoutOrder: UserRetailerCheckoutOrder;
    status: string;
    errorMessage?: string;
  };
};

export const retryRetailerLoginDuringCheckout = async (req: {
  orderId: string;
  currentStamp?: number;
  connectionInfo?: string;
}) => {
  const { data } = await axiosRetailerSync.post(
    `/retailerCheckout/retryLogin`,
    req,
    {
      headers: await getHeaders(),
    }
  );
  return data as {
    userRetailerCheckoutSession: UserRetailerLiveShoppingSession;
    status: string;
    errorMessage?: string;
  };
};

export const confirmCheckout = async (req: {
  retailerId: number;
  orderId: string;
  paymentConfirmation?: {
    type: "cvv" | "cardNumber" | "expirationDate" | "cardHolderName";
    value: string;
  };
}) => {
  try {
    const { data } = await axiosRetailerSync.post(
      `/retailerCheckout/updatePaymentConfirmationAndCompleteCheckout`,
      req,
      {
        headers: await getHeaders(),
      }
    );
    return data as {
      userRetailerCheckoutSession?: UserRetailerCheckoutEvent;
      status: string;
      errorMessage?: string;
    };
  } catch {
    throw new Error("Error confirming order");
  }
};

export const cancelCheckout = async (req: {
  retailerId: number;
  orderId: string;
}) => {
  try {
    const { data } = await axiosRetailerSync.post(
      `/retailerCheckout/cancelCheckout`,
      req,
      {
        headers: await getHeaders(),
      }
    );
    return data as {
      userRetailerCheckoutSession?: UserRetailerCheckoutEvent;
      status: string;
      errorMessage?: string;
    };
  } catch {
    throw new Error("Error cancelling order");
  }
};

export interface FailedAttemptType {
  type:
    | "login"
    | "updateShippingAddress"
    | "updatePaymentMethod"
    | "updateShippingOption"
    | "placeOrder"
    | "addShippingAddress"
    | "addPaymentMethod";
  errorMessage?: string;
  timestamp: number;
}

export interface UserRetailerLiveShoppingSession {
  userId: string;
  sessionId: string;
  syncId: string;
  retailerId: number;
  brandId: number;
  checkoutId: string;
  checkoutBatchId: string;
  checkoutMode: "clientSide" | "serverSide";
  connectStatusEvents?: Set<string>;
  checkoutStatusEvents?: Set<string>;
  eventsSequence?: UserRetailerCheckoutEvent[];
  errorMessage?: string;
  notificationEvents?: Set<string>;
  items?: ShoppingCartItem[];
  failureAttempts?: FailedAttemptType[];
  status: UserRetailerCheckoutSessionStatus;
  checkoutStatus: UserRetailerCheckoutSessionStatus;
  serverUpdatedAt: number;
  clientUpdatedAt: number;
  cartUpdatedTime: number;
  cartUpdateAt: number;
  createdTime: number;
  updatedTime: number;
  lastKnownActiveTime?: number;
}

export type EnterAnimationStaging =
  | "itemSelection"
  | "userAuthentication"
  | "orderSummary"
  | "success";

export interface RetailerCheckoutOrderStatus {
  userRetailerCheckoutOrder?: UserRetailerCheckoutOrder;
  userRetailerCheckoutSessionStatus?: {
    retailerId: number;
    status: UserRetailerCheckoutSessionStatus;
    createdAt: number;
    updatedAt: number;
    eventSequence: UserRetailerCheckoutEvent;
    lastFailedAttempt: FailedAttemptType;
  };
  retailerSyncSessions?: RetailerSyncSession[];
  userRetailerSyncSessionStatus?: {
    createdAt: number;
    retailerId: number;
    syncId: string;
    syncStatus: SyncStatus;
    updatedAt: number;
  };
  displayStatusInfo?: {
    title: string;
    description: string;
    stage: EnterAnimationStaging;
  };
  status: string;
  products?: IProducts;
  errorMessage?: string;
}

export const getRetailerCheckoutOrderStatus = async (
  { orderId }: { orderId: string },
  abortSignal?: AbortSignal
) => {
  const { data } = await axiosRetailerSync.get(
    `/retailerCheckout/getRetailerCheckoutOrderStatus?orderId=${orderId}`,
    {
      headers: await getHeaders(),
      signal: abortSignal,
    }
  );

  return data as RetailerCheckoutOrderStatus;
};

export const getUserRetailerCheckoutBatch = async ({
  checkoutBatchId,
}: {
  checkoutBatchId: string;
}) => {
  const { data } = await axiosRetailerSync.get(
    `/retailerCheckout/getUserRetailerCheckoutBatch?checkoutBatchId=${checkoutBatchId}`,
    {
      headers: await getHeaders(),
    }
  );
  return data as UserRetailerCheckoutBatchStatus;
};

interface UpdateShippingToUserRetailerCheckoutOrderRequest {
  retailerId: number;
  orderId: string;
  shippingAddress: UserRetailerCheckoutOrder["shippingAddress"];
}
export const addShippingToUserRetailerCheckoutOrder = async (
  addShippingToUserRetailerCheckoutOrderRequest: UpdateShippingToUserRetailerCheckoutOrderRequest
) => {
  try {
    const { data } = await axiosRetailerSync.post(
      `/retailerCheckout/addShippingAddress`,
      addShippingToUserRetailerCheckoutOrderRequest,
      {
        headers: await getHeaders(),
      }
    );
    return data as {
      userRetailerCheckoutSession?: UserRetailerCheckoutEvent;
      status: string;
      errorMessage?: string;
    };
  } catch {
    throw new Error("Error confirming order");
  }
};

export const updateShippingToUserRetailerCheckoutOrder = async (
  updateShippingToUserRetailerCheckoutOrderRequest: UpdateShippingToUserRetailerCheckoutOrderRequest
) => {
  try {
    const { data } = await axiosRetailerSync.post(
      `/retailerCheckout/updateShippingAddress`,
      updateShippingToUserRetailerCheckoutOrderRequest,
      {
        headers: await getHeaders(),
      }
    );
    return data as {
      userRetailerCheckoutSession?: UserRetailerCheckoutEvent;
      status: string;
      errorMessage?: string;
    };
  } catch {
    throw new Error("Error confirming order");
  }
};

export const updateShippingOption = async (updateShippingOptionRequest: {
  retailerId: number;
  orderId: string;
  deliveryOption?: {
    name: string;
    cssSelector: string;
  };
  deliveryTimeWindow?: {
    name: string;
    cssSelector: string;
  };
}) => {
  try {
    const { data } = await axiosRetailerSync.post(
      `/retailerCheckout/updateShippingOption`,
      updateShippingOptionRequest,
      {
        headers: await getHeaders(),
      }
    );
    return data as {
      userRetailerCheckoutSession?: UserRetailerCheckoutEvent;
      status: string;
      errorMessage?: string;
    };
  } catch {
    throw new Error("Error confirming order");
  }
};

interface UpdatePaymentMethodToUserRetailerCheckoutOrderRequest {
  retailerId: number;
  orderId: string;
  cardInfo: {
    cardNumber?: string;
    cardIssuer: string;
    expirationDate: string;
    cvv?: string;
    cardHolderName: string;
    cardNumberEnding?: string;
  };
  billingAddress: UserRetailerCheckoutOrder["billingAddress"];
}
export const updatePaymentMethodToUserRetailerCheckoutOrder = async (
  updatePaymentMethodToUserRetailerCheckoutOrderRequest: UpdatePaymentMethodToUserRetailerCheckoutOrderRequest
) => {
  try {
    const { data } = await axiosRetailerSync.post(
      `/retailerCheckout/updatePaymentMethod`,
      updatePaymentMethodToUserRetailerCheckoutOrderRequest,
      {
        headers: await getHeaders(),
      }
    );
    return data as {
      userRetailerCheckoutSession?: UserRetailerCheckoutEvent;
      status: string;
      errorMessage?: string;
    };
  } catch {
    throw new Error("Error confirming order");
  }
};

export const addPaymentMethodToUserRetailerCheckoutOrder = async (
  addPaymentMethodToUserRetailerCheckoutOrderRequest: UpdatePaymentMethodToUserRetailerCheckoutOrderRequest
) => {
  try {
    const { data } = await axiosRetailerSync.post(
      `/retailerCheckout/addPaymentMethod`,
      addPaymentMethodToUserRetailerCheckoutOrderRequest,
      {
        headers: await getHeaders(),
      }
    );
    return data as {
      userRetailerCheckoutSession?: UserRetailerCheckoutEvent;
      status: string;
      errorMessage?: string;
    };
  } catch {
    throw new Error("Error confirming order");
  }
};

export const deleteItemsInCheckoutPage = async (req: {
  retailerId?: number;
  orderId?: string;
  brandId?: number;
  items?: {
    quantity?: number;
    retailPrice?: number;
    retailerSku?: string;
    retailerId?: number;
  }[];
}) => {
  try {
    const { data } = await axiosRetailerSync.post(
      `/retailerCheckout/deleteItemsInCheckoutPage`,
      req,
      {
        headers: await getHeaders(),
      }
    );
    return data as {
      userRetailerCheckoutSession?: UserRetailerCheckoutEvent;
      status: string;
      errorMessage?: string;
    };
  } catch {
    throw new Error("Error confirming order");
  }
};

export const updateUserConfirmationToCheckoutItems = async (req: {
  retailerId?: number;
  orderId?: string;
  brandId?: number;
  items?: {
    quantity?: number;
    retailPrice?: number;
    retailerSku?: string;
    retailerId?: number;
  }[];
}) => {
  try {
    const { data } = await axiosRetailerSync.post(
      `/retailerCheckout/updateUserConfirmationToCheckoutItems`,
      req,
      {
        headers: await getHeaders(),
      }
    );
    return data as {
      userRetailerCheckoutSession?: UserRetailerCheckoutEvent;
      status: string;
      errorMessage?: string;
    };
  } catch (e: any) {
    console.log(`updateUserConfirmationToCheckoutItems error: ${e.message}`);
    throw new Error("Error confirming order");
  }
};

export const deleteOutOfStockItemsFromCheckoutOrder = async (req: {
  retailerId?: number;
  orderId?: string;
  brandId?: number;
  items?: {
    quantity?: number;
    retailPrice?: number;
    retailerSku?: string;
    retailerId?: number;
  }[];
}) => {
  try {
    const { data } = await axiosRetailerSync.post(
      `/retailerCheckout/deleteOutOfStockItemsFromCheckoutOrder`,
      req,
      {
        headers: await getHeaders(),
      }
    );
    return data as {
      userRetailerCheckoutSession?: UserRetailerCheckoutEvent;
      status: string;
      errorMessage?: string;
    };
  } catch (e: any) {
    console.log(`updateUserConfirmationToCheckoutItems error: ${e.message}`);
    throw new Error("Error confirming order");
  }
};
