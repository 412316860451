import { useEffect, useMemo } from "react";

import {
  AnalyticsService,
  AnalyticsTrackingEvent,
  ProductPageDialog,
} from "@brandclub/common-ui";
import { create } from "zustand";
import { useAppSelector } from "../../../redux/hooks";
import { buildReferralLink, formatMoney } from "../../../utils/StringUtils";
import { LoggedOutProductShareDialog } from "./ShareProductDialog";
import { useLocation } from "react-router-dom";

interface BearState {
  open: boolean;
  close: () => void;
}

const useConfirmDialogStore = create<BearState>((set) => ({
  open: false,
  close: () => set({ open: false }),
}));

const InviteFriendsDialog = () => {
  const { open, close } = useConfirmDialogStore();
  const brandAppConfig = useAppSelector(
    ({ appConfig }) => appConfig?.brandAppConfig
  );
  const { amountForReferringUser, amountForInstallingUser } = useAppSelector(
    ({ referralReward }) => referralReward
  );
  const userProfile = useAppSelector(({ userProfile }) => userProfile);
  const isLoggedOut = !userProfile;
  const shareLink = useMemo(() => {
    if (!brandAppConfig) {
      return "";
    }
    return buildReferralLink(brandAppConfig, userProfile?.referralCode);
  }, [userProfile?.referralCode, brandAppConfig]);
  const location = useLocation();
  useEffect(() => {
    open && close();
    // close the dialog whenever the location changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const secondTitle = `Give ${formatMoney(
    amountForInstallingUser || 0
  )}, Get ${formatMoney(amountForReferringUser || 0)}`;
  const subtitle = `Get a ${formatMoney(
    amountForInstallingUser || 0
  )} reward every time someone signs up using your referral link. Your friend will also get a ${formatMoney(
    amountForInstallingUser || 0
  )} reward.`;

  const productDialogProps = {
    popupDrawerProps: { open: open, onClose: close },
    imageUrl:
      "https://media.brandclub.com/brandclub/icons/actions/invite-dark.svg",
    imageSize: 93,
    title: "Invite friends",
    sharePage: true,
    sharePageUrl: shareLink,
    video: undefined,
  };

  return (
    <>
      {isLoggedOut ? (
        <LoggedOutProductShareDialog
          {...productDialogProps}
          subtitle="Create an account or sign in to get your personal affiliate link."
        />
      ) : (
        <ProductPageDialog
          {...productDialogProps}
          secondTitle={secondTitle}
          subtitle={subtitle}
          onShareLinkCopied={async () => {
            AnalyticsService.track(
              AnalyticsTrackingEvent.SHARED_REFERRAL_CODE,
              {
                referralCode: userProfile?.referralCode,
              }
            );
          }}
        />
      )}
    </>
  );
};

InviteFriendsDialog.show = () => {
  useConfirmDialogStore.setState({
    open: true,
  });
};

export default InviteFriendsDialog;
