import { ProductCardProps } from "./components/CheckoutFlow/EnterCheckoutAnimation";

export interface PaymentMethod {
  cardNumber: string;
  cardType: string;
  cardExpiry: string;
  cardHolderName: string;
}
export enum UserApprovalStatus {
  PENDING = "pending",
  APPROVED = "approved",
  DELETED = "deleted",
}

export interface ShoppingCartItem {
  brandId: number;
  retailerSku: string;
  retailerId: number;
  stacklineSku: string;
  quantity: number;
  retailPrice: number;
  status?: string;
  errorMessage?: string;
  requestedQuantity?: number;
  userApprovalStatus?: UserApprovalStatus;
}

export interface UserRetailerShoppingCart {
  checkoutOrderId?: string;
  userId: string;
  shoppingCartId: string;
  retailerId: number;
  shippingAddress?: ShippingAddress;
  billingAddress?: ShippingAddress;
  paymentMethod?: PaymentMethod;
  items: ShoppingCartItem[];
  cartCount?: number;
  status:
    | "active"
    | "checkout_in_progress"
    | "checkout_success"
    | "checkout_error";
  statusMessage?: string;
  createdTime: Date;
  createdBy: string;
  updatedTime: Date;
  updatedBy: string;
  totalItems: number;
}

export interface UserRetailerCheckoutBatch {
  userId: string;
  checkoutBatchId: string;

  retailerIds: number[];

  checkoutOrderIdsByRetailerId: {
    [retailerId: number]: string;
  };

  checkoutOrderStatusByRetailerId: {
    [retailerId: number]: UserRetailerCheckoutOrderStatus;
  };

  checkoutOrderStatusMessageByRetailerId: {
    [retailerId: number]: string | undefined;
  };
  createdTime: Date;

  createdBy: string;
  updatedTime: Date;

  updatedBy: string;
}

export interface UserRetailerCheckoutBatchStatus {
  status: string;
  userRetailerCheckoutBatch: UserRetailerCheckoutBatch;
}

export interface ShippingAddress {
  lastName: string;
  zipCode: string;
  country: string;
  address2: string;
  city: string;
  address1: string;
  userId: string;
  phoneNumber: string;
  extendedAttributes: {
    retailerId: number;
    isSelected: boolean;
  };
  firstName: string;
  id: string;
  state: string;
  updatedAt: any;
  createdAt: any;
}

export enum UserRetailerCheckoutSessionStatus {
  // add to cart session status
  ADD_TO_CART_IN_PROGRESS = "add_to_cart_in_progress",
  ADD_TO_CART_SUCCESS = "add_to_cart_success",
  ADD_TO_CART_FAILED = "add_to_cart_failed",
  // navigate to checkout session status
  NAVIGATE_TO_CHECKOUT_IN_PROGRESS = "navigate_to_checkout_in_progress",
  NAVIGATE_TO_CHECKOUT_SUCCESS = "navigate_to_checkout_success",
  NAVIGATE_TO_CHECKOUT_FAILED = "navigate_to_checkout_failed",
  // login session status
  LOGIN_IN_PROGRESS = "login_in_progress",
  LOGIN_SUCCESS = "login_success",
  LOGIN_FAILED = "login_failed",
  // validate checkout items session status
  VALIDATING_CHECKOUT_ITEMS_IN_PROGRESS = "validating_checkout_items_in_progress",
  VALIDATING_CHECKOUT_ITEMS_SUCCESS = "validating_checkout_items_success",
  VALIDATING_CHECKOUT_ITEMS_FAILED = "validating_checkout_items_failed",
  // retrieve order summary session status
  RETRIEVE_ORDER_SUMMARY_IN_PROGRESS = "retrieve_order_summary_in_progress",
  RETRIEVE_ORDER_SUMMARY_SUCCESS = "retrieve_order_summary_success",
  RETRIEVE_ORDER_SUMMARY_FAILED = "retrieve_order_summary_failed",
  // update payment method session status
  UPDATE_PAYMENT_METHOD_IN_PROGRESS = "update_payment_method_in_progress",
  UPDATE_PAYMENT_METHOD_SUCCESS = "update_payment_method_success",
  UPDATE_PAYMENT_METHOD_FAILED = "update_payment_method_failed",
  // update shipping address session status
  UPDATE_SHIPPING_ADDRESS_IN_PROGRESS = "update_shipping_address_in_progress",
  UPDATE_SHIPPING_ADDRESS_SUCCESS = "update_shipping_address_success",
  UPDATE_SHIPPING_ADDRESS_FAILED = "update_shipping_address_failed",
  // update payment confirmation session status
  UPDATE_PAYMENT_CONFIRMATION_IN_PROGRESS = "update_payment_confirmation_in_progress",
  UPDATE_PAYMENT_CONFIRMATION_SUCCESS = "update_payment_confirmation_success",
  UPDATE_PAYMENT_CONFIRMATION_FAILED = "update_payment_confirmation_failed",
  // update shipping option session status
  UPDATE_SHIPPING_OPTION_IN_PROGRESS = "update_shipping_option_in_progress",
  UPDATE_SHIPPING_OPTION_SUCCESS = "update_shipping_option_success",
  UPDATE_SHIPPING_OPTION_FAILED = "update_shipping_option_failed",
  // add shipping address session status
  ADD_SHIPPING_ADDRESS_IN_PROGRESS = "add_shipping_address_in_progress",
  ADD_SHIPPING_ADDRESS_SUCCESS = "add_shipping_address_success",
  ADD_SHIPPING_ADDRESS_FAILED = "add_shipping_address_failed",
  // add payment method session status
  ADD_PAYMENT_METHOD_IN_PROGRESS = "add_payment_method_in_progress",
  ADD_PAYMENT_METHOD_SUCCESS = "add_payment_method_success",
  ADD_PAYMENT_METHOD_FAILED = "add_payment_method_failed",
  // place order session status
  PLACE_ORDER_IN_PROGRESS = "place_order_in_progress",
  PLACE_ORDER_SUCCESS = "place_order_success",
  PLACE_ORDER_FAILED = "place_order_failed",
  // remove item from checkout session status
  DELETE_ITEMS_IN_CHECKOUT_IN_PROGRESS = "delete_items_in_checkout_in_progress",
  DELETE_ITEMS_IN_CHECKOUT_SUCCESS = "delete_items_in_checkout_success",
  DELETE_ITEMS_IN_CHECKOUT_FAILED = "delete_items_in_checkout_failed",
  // waiting for user input session status
  CHECKOUT_WAITING_FOR_USER_INPUT = "checkout_waiting_for_user_input",
  // user requested actions session status
  USER_REQUESTED_UPDATE_PAYMENT_METHOD = "user_requested_update_payment_method",
  USER_REQUESTED_UPDATE_SHIPPING_ADDRESS = "user_requested_update_shipping_address",
  USER_REQUESTED_ADD_SHIPPING_ADDRESS = "user_requested_add_shipping_address",
  USER_REQUESTED_ADD_PAYMENT_METHOD = "user_requested_add_payment_method",
  USER_REQUESTED_UPDATE_SHIPPING_OPTION = "user_requested_update_shipping_option",
  USER_REQUESTED_UPDATE_PAYMENT_CONFIRMATION = "user_requested_update_payment_confirmation",
  USER_REQUESTED_COMPLETE_CHECKOUT = "user_requested_complete_checkout",
  USER_REQUESTED_CANCEL_CHECKOUT = "user_requested_cancel_checkout",
  USER_REQUESTED_UPDATE_PAYMENT_CONFIRMATION_AND_COMPLETE_CHECKOUT = "user_requested_update_payment_confirmation_and_complete_checkout",
  USER_REQUESTED_RETRY_LOGIN = "user_requested_retry_login",
  USER_REQUESTED_CHECKOUT_SUMMARY = "user_requested_checkout_summary",
  USER_REQUESTED_DELETE_ITEMS_IN_CHECKOUT = "user_requested_delete_items_in_checkout",
  // Final state checkout session status
  CHECKOUT_CANCELLED = "checkout_cancelled",
  CHECKOUT_ERROR = "checkout_error",
  CHECKOUT_SUCCESS = "checkout_success",
}

export type UserRetailerCheckoutOrderStatus =
  | "queued"
  | "initializing"
  | "login_in_progress"
  | "login_success"
  | "login_failed"
  | "checkout_in_progress"
  | "checkout_success"
  | "checkout_cancelled"
  | "checkout_error"
  | "checkout_timed_out";

export interface UserRetailerCheckoutSession {
  errorMessage?: string;
  notificationEvents?: Set<string>;
  serverUpdatedAt: number;
  clientUpdatedAt: number;
  createdAt: number;
  updatedAt: number;
  status: UserRetailerCheckoutSessionStatus;
}

export interface ShippingGroup {
  products: {
    retailerSku: string;
    stacklineSku: string;
    name?: string;
    quantity: number;
    retailPrice: number;
  }[];
  deliveryOptions: {
    name: string;
    description: string;
    isSelected: boolean;
    offers: string[];
    cssSelector: string;
    deliveryTimeWindows: {
      name: string;
      description: string;
      isSelected: boolean;
      cssSelector: string;
    }[];
  }[];
}

export interface CanPlaceOrder {
  status: boolean;
  reasons?: {
    section: "shipping_address" | "payment_method";
    status: "enabled" | "disabled";
  }[];
}
export interface UserRetailerCheckoutOrder {
  userId: string;
  brandId: number;
  orderId: string;
  checkoutBatchId: string;
  checkoutId: string;
  createdTime: string;
  retailerId: number;
  status?: UserRetailerCheckoutOrderStatus;
  statusMessage?: string;
  items: ShoppingCartItem[];
  canPlaceOrder?: CanPlaceOrder;
  shippingAddress?: ShippingAddress;
  availableAddresses?: ShippingAddress[];
  billingAddress?: ShippingAddress;
  errorMessage?: string;
  availablePaymentMethods?: {
    cardHolderName: string;
    cardIssuer: string;
    cardNumberEnding: string;
    expirationDate: string;
    isSelected: boolean;
  }[];
  paymentMethod?: {
    cardNumberEnding: string;
    cardIssuer: string;
    cardHolderName: string;
    paymentConfirmationTypes: {
      type: "cvv" | "cardNumber" | "expirationDate" | "cardHolderName";
    }[];
  };
  shippingOptions?: {
    shippingGroups: ShippingGroup[];
  };
  orderSummary?: {
    subtotal: number;
    shippingFees: number;
    shippingDiscount: number;
    tax: number;
    total: number;
    giftCard?: number;
  };
}

// Order

interface CardInfo {
  cardNumber: string;
  cardType: string;
  expirationDate: string;
  cvv: string;
  cardHolderName: string;
}

interface DeliveryOptions {
  name: string;
  cssSelector: string;
}

interface DeliveryTimeWindow {
  name: string;
  cssSelector: string;
}

export type EnterAnimationConfig = {
  title: string;
  stepCards: ProductCardProps[];
  retailerId: number;
};

export interface UserRetailerCheckoutEvent {
  id: string;
  initiator: "client" | "server";
  timestamp: number;
  type:
    | "update-shipping-address"
    | "update-payment-method"
    | "update-delivery-option"
    | "confirm-checkout"
    | "cancel-checkout";
  status: "queued" | "processing" | "completed" | "failed";
  data:
    | {
        shippingAddress: ShippingAddress;
      }
    | {
        cardInfo: CardInfo;
        billingAddress: ShippingAddress;
      }
    | {
        deliveryOptions?: DeliveryOptions;
        deliveryTimeWindow?: DeliveryTimeWindow;
      };
}
