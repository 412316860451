import { styled } from "@mui/material/styles";
import { useContext, useEffect, useRef } from "react";
import { RetailerSyncSession } from "../../../../types/misc";
import { useSyncContext } from "../../../SyncProvider";
import ChooseMFAOption from "../../AppPages/Sync/MFA/ChooseMFAOption";
import EnterMFACode from "../../AppPages/Sync/MFA/EnterMFACode";
import { UserLoginContext } from "../UserLoginProvider";

const StyledEnterMFACode = styled(EnterMFACode)(() => ({
  flex: 1,
  minHeight: "unset",
  ".auth_container": {
    ".page_title": {
      marginTop: 53,
      marginBottom: 16,
    },
    ".retailer_logo": {
      width: 225,
    },
  },
}));
const StyledChooseMFAOption = styled(ChooseMFAOption)(() => ({
  flex: 1,
  minHeight: "unset",
  ".auth_container": {
    ".retailer_logo": {
      width: 225,
    },
  },
}));
const MFAWrapper = styled("form")(({ theme }) => ({
  color: theme.palette.primary.main,
  maxWidth: 400,
  ".title": {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
  },
  ".subtitle": {
    marginTop: 16,
    textAlign: "center",
  },
  ".store-input": {
    maxWidth: "100%",
  },
  ".MuiFormControlLabel-label": {
    fontSize: 14,
    fontWeight: 500,
  },
  ".MuiFormControlLabel-root": {
    margin: 0,
  },
}));

const isRetailerSyncSessionInMFAChoose = (
  retailer: RetailerSyncSession | undefined
): retailer is RetailerSyncSession => {
  if (!retailer) {
    return false;
  }
  return Boolean(
    retailer.syncStatus === "userintervention" &&
      retailer.eventSequence &&
      retailer.eventSequence.type === "mfa-choose" &&
      retailer.eventSequence.initiator === "server"
  );
};
const isRetailerSyncSessionInMFAEnter = (
  retailer: RetailerSyncSession | undefined
): retailer is RetailerSyncSession => {
  if (!retailer) {
    return false;
  }
  return Boolean(
    retailer.syncStatus === "userintervention" &&
      retailer.eventSequence &&
      retailer.eventSequence.type === "mfa-enter" &&
      retailer.eventSequence.initiator === "server"
  );
};

const ConnectRetailerOTP = () => {
  const { mfaRetailerSyncSession, syncStatus } = useSyncContext();
  const { send } = useContext(UserLoginContext);
  const isMFADone = syncStatus === "syncing" && !mfaRetailerSyncSession;
  const waitForMFADoneIntervalRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isMFADone) {
      send({
        type: "SUBMIT",
      });
    }
  }, [isMFADone, send]);

  // Clear interval on unmount to prevent memory leaks
  useEffect(() => {
    return () => {
      clearInterval(waitForMFADoneIntervalRef.current);
    };
  }, []);

  const waitForMFADone = () => {
    return new Promise<void>((resolve) => {
      clearInterval(waitForMFADoneIntervalRef.current);
      waitForMFADoneIntervalRef.current = setInterval(() => {
        if (isMFADone) {
          resolve();
        }
      }, 50); // Check every 50 milliseconds
    });
  };

  return (
    <MFAWrapper className="auth_container">
      {isRetailerSyncSessionInMFAChoose(mfaRetailerSyncSession) && (
        <StyledChooseMFAOption
          retailerId={mfaRetailerSyncSession.retailerId}
          retailerSyncSession={mfaRetailerSyncSession}
          onCompleteCallback={() => waitForMFADone()}
        />
      )}
      {isRetailerSyncSessionInMFAEnter(mfaRetailerSyncSession) && (
        <StyledEnterMFACode
          retailerId={mfaRetailerSyncSession.retailerId}
          retailerSyncSession={mfaRetailerSyncSession}
          onCompleteCallback={() => waitForMFADone()}
        />
      )}
    </MFAWrapper>
  );
};

export default ConnectRetailerOTP;
