import { useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import { defaultPreviewLazyLoadConfig } from "../../../../../utils/lazyLoadConfig";
import BrandCard from "../../../../StoreComponents/StoreCard.tsx/BrandCard";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import { CardGrid } from "../components/CardGrid";
import { PreviewHeader } from "../components/Header";
import PreviewContainer from "../components/PreviewContainer";
import PreviewWidgetWrapper from "../components/PreviewWidgetWrapper";
import { useFetchClubInvites } from "./data";
import { useResponsiveData } from "../../../../../utils/hooks/useResponsiveData";

interface ClubInvitesPreviewProps {
  brandIds?: number[];
}
const ClubInvitesPreview = (props: ClubInvitesPreviewProps) => {
  const ref = useRef(null);
  const widgetInView = useInView(ref, defaultPreviewLazyLoadConfig);
  const { data, loading, fetchInit } = useFetchClubInvites({
    pageSize: 10,
    autoInitFetch: false,
    brandIds: props.brandIds,
  });

  useEffect(() => {
    if (widgetInView) {
      fetchInit();
    }
  }, [widgetInView, fetchInit]);

  const responsiveData = useResponsiveData(data);

  if (!loading && data.length === 0) {
    return null;
  }
  return (
    <PreviewWidgetWrapper ref={ref}>
      <PreviewContainer>
        {loading ? (
          <StoreLoadingSkeleton header row={2} />
        ) : (
          <>
            <PreviewHeader
              title={"Club invites"}
              linkTo="/clubinvites"
              subtitle="Earn rewards by joining new clubs"
              indicator="grey"
            />
            <CardGrid>
              {responsiveData.map((d) => (
                <BrandCard key={d.brandclubId} brand={d} />
              ))}
            </CardGrid>
          </>
        )}
      </PreviewContainer>
    </PreviewWidgetWrapper>
  );
};

export default ClubInvitesPreview;
