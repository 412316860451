import { getAuthorizationHeader } from "../../../Auth";
import { SurveyResponse } from "../../../types/misc";

import axios from "../../../utils/axios";

export interface SubmitContentForRequest {
  campaignId: string;
  contentId: string;
  answer?: SurveyResponse;
}

export const submitContentForReward = async (req: SubmitContentForRequest) => {
  return await axios.post("/content/submitContentV2", req, {
    headers: {
      ...(await getAuthorizationHeader()),
    },
  });
};
