import { createAsyncThunk } from "@reduxjs/toolkit";
import { getBrandClubById, getEntityForLandingPage } from "../../../api";
import ReduxStore from "../../types";
import reduxApolloClient from "../../reduxApolloClient";
import ConfigUtils from "../../../utils/ConfigUtils";

export const getBrandEntity = createAsyncThunk(
  "/brandEntity/getBrandEntity",
  async ({ id }: { id?: string }, thunkAPI) => {
    const { mainEntity } = thunkAPI.getState() as ReduxStore;
    if (
      `${mainEntity?.id}` === `${id}` &&
      mainEntity?.entity &&
      mainEntity?.type === "brand"
    ) {
      return new Promise((resolve, reject) => {
        resolve({ data: { ...mainEntity?.entity } } as any);
      });
    }
    const entityResponse = await getEntityForLandingPage({
      entityId: id as any,
      entityType: "brand",
    });
    return { data: entityResponse.data };
  }
);

export const getBrandclubEntity = createAsyncThunk(
  "/brandEntity/getBrandclubEntity",
  async ({ id }: { id?: number }, thunkAPI) => {
    return await reduxApolloClient.query({
      query: getBrandClubById,
      variables: {
        brandId: id,
      },
      fetchPolicy: "network-only",
      context: {
        uri: `${ConfigUtils.getGraphQlUri()}?id=getBrandclubEntity`,
      },
    });
  }
);
