import { styled } from "@mui/material";
import RecentlyViewedPreview from "../Pages/RecentlyViewed/preview";
import TrendingPreview from "../Pages/Trending/preview";
import BuyItAgainPreview from "../Pages/BuyAgain/preview";
import InstantRewardsPreview from "../Pages/InstantRewards/preview";
import TopPicksPreview from "../Pages/TopPicks/preview";
import SpotlightBrandProductsPreview from "../Pages/SpotlightBrandProducts/preview";
import DepartmentsPreview from "../Pages/Departments/preview";

const Container = styled("div")({
  marginTop: 30,
  width: "100%",
});

const Shop = () => {
  return (
    <Container>
      <RecentlyViewedPreview />
      <TrendingPreview />
      <BuyItAgainPreview />
      <DepartmentsPreview />
      <InstantRewardsPreview />
      <SpotlightBrandProductsPreview />
      <TopPicksPreview />
    </Container>
  );
};

export default Shop;
