import { useCallback, useEffect, useState } from "react";
import reduxApolloClient from "../../../../../redux/reduxApolloClient";
import ConfigUtils from "../../../../../utils/ConfigUtils";
import _get from "lodash/get";
import { SearchResult } from "../../../../../api/types/searchResult";
import { ProductRewardSearchResult } from "@brandclub/common-ui";
import {
  getInstantRewardsV2,
  getUnauthedInstantRewardProductsV2,
} from "../../../../../api/graphQl/authenticated/InstantRewards";
import { uniqBy } from "lodash";
import { useUserLogin } from "../../../Auth/UserLoginProvider";

type RESKEY = "InstantRewardProductsV2" | "UnauthedInstantRewardProductsV2";
type InstantRewardProductsResponse = {
  [key in RESKEY]: SearchResult<ProductRewardSearchResult>;
};

export const useFetchInstantRewards = ({
  pageSize = 25,
  autoInitFetch = true,
  brandIds,
}: {
  brandIds?: number[];
  pageSize?: number;
  autoInitFetch?: boolean;
}) => {
  const [data, setData] = useState<ProductRewardSearchResult[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { signedIn } = useUserLogin();

  const fetchData = useCallback(
    async (pageNumber = 0) => {
      try {
        setError(null);
        const RESKEY = signedIn
          ? "InstantRewardProductsV2"
          : "UnauthedInstantRewardProductsV2";
        const res =
          await reduxApolloClient.query<InstantRewardProductsResponse>({
            query: signedIn
              ? getInstantRewardsV2
              : getUnauthedInstantRewardProductsV2,
            variables: {
              page: {
                start: pageNumber,
                size: pageSize,
              },
              brandIds,
            },
            fetchPolicy: "network-only",
            context: {
              uri: `${ConfigUtils.getGraphQlUri()}?id=${RESKEY}`,
            },
          });
        const newData = _get(res, ["data", RESKEY, "items"], []);
        const newHasMore = _get(res, ["data", RESKEY, "hasMore"], false);
        setData((d) => uniqBy([...d, ...newData], "stacklineSku"));
        setHasMore(newHasMore);
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message);
        }
        console.error(e);
      }
    },
    [pageSize, brandIds, signedIn]
  );

  const fetchInit = useCallback(async () => {
    try {
      setLoading(true);
      await fetchData(0);
    } finally {
      setPage(0);
      setLoading(false);
    }
  }, [fetchData]);

  const fetchMore = useCallback(async () => {
    try {
      setLoadingMore(true);
      await fetchData((page + 1) * pageSize);
    } finally {
      setPage((p) => p + 1);
      setLoadingMore(false);
    }
  }, [fetchData, page, pageSize]);

  useEffect(() => {
    if (autoInitFetch) {
      fetchInit();
    }
  }, [fetchInit, autoInitFetch]);

  return { data, loading, error, hasMore, fetchMore, loadingMore, fetchInit };
};
