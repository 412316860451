import React from "react";
import InviteFriendsDialog from "./InviteFriendsDialog";
import ShareProductDialog from "./ShareProductDialog";
import ConnectRetailerDialog from "./ConnectRetailerDialog";
import SurveyLimitDialog from "./SurveyLimitDialog";
import DisconnectRetailerDialog from "./DisconnectRetailerDialog";
import CheckoutTransition from "../../Checkout/components/CheckoutTransition";
import GenericDialog from "./GenericDialog";
import GenericDialogNoClose from "./GenericDialogNoClose";

const AppDialogs = () => {
  return (
    <>
      <InviteFriendsDialog />
      <ShareProductDialog />
      <ConnectRetailerDialog />
      <DisconnectRetailerDialog />
      <CheckoutTransition />
      <GenericDialog />
      <GenericDialogNoClose />
      <SurveyLimitDialog />
    </>
  );
};

export default AppDialogs;
