import { useEffect, useState } from "react";

import { ProductRewardSearchResult } from "@brandclub/common-ui";
import { generateAffiliateLink } from "../../api/rest/authenticated/Affiliate";
import { getEntityImage } from "../StringUtils";
import { copyQueryParamsToUrlSearchParams } from "../misc";
import ConfigUtils from "../ConfigUtils";

/**
 * @returns shareLink, shareSubject, trackerParams required for sharing pdp
 */
export const useShareProductParams = ({
  product,
  analyticsTrackingParams,
}: any) => {
  const [sharePageProps, setSharePageProps] = useState({
    shareLink: ``,
    shareSubject: ``,
    trackerParams: analyticsTrackingParams,
  });

  useEffect(() => {
    if (product?.stacklineSku) {
      buildShareProductPageParams({ product, analyticsTrackingParams })
        .then((data) => {
          setSharePageProps(data);
        })
        .catch((error) => {
          console.error(`error building product page share params: ${error}`);
        });
    } else {
      setSharePageProps({
        shareLink: ``,
        shareSubject: ``,
        trackerParams: analyticsTrackingParams,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.stacklineSku]);

  return sharePageProps;
};

export type ShareProductParams = {
  shareLink: string;
  shareSubject: string;
  trackerParams: any;
};

export const buildShareProductPageParams = async ({
  product,
  analyticsTrackingParams,
}: {
  product: {
    title: ProductRewardSearchResult["title"];
    retailerId: ProductRewardSearchResult["retailerId"];
    retailerSku: ProductRewardSearchResult["retailerSku"];
    stacklineSku: ProductRewardSearchResult["stacklineSku"];
  };
  analyticsTrackingParams: any;
}): Promise<ShareProductParams> => {
  const { title, retailerId, retailerSku, stacklineSku } = product;
  const affiliateLink = await generateAffiliateLink({
    retailerId,
    retailerSku,
    stacklineSku,
  });
  const affiliateLinkId = affiliateLink.data?.affiliateLink?.affiliateLinkId;
  const shortUrl = affiliateLink.data?.affiliateLink?.shortUrl;

  // escaping via URLSearchParams doesn't work hence create ogQueryParams url directly
  const ogQueryParams = `$og_title=${encodeURIComponent(
    title
  )}&$og_image_url=${encodeURIComponent(
    getEntityImage(stacklineSku, "product")
  )}&$og_description=${encodeURIComponent("Brandclub - Get rewarded to shop")}`;
  const shareParams = {
    affiliateLinkId,
    sku: stacklineSku,
  };
  const urlSearchParams = copyQueryParamsToUrlSearchParams(shareParams);

  const link = shortUrl
    ? `${shortUrl}`
    : ["prod", "gamma"].includes(ConfigUtils.getAppStage())
    ? `https://app.brandclub.com/landing?${urlSearchParams.toString()}&${ogQueryParams}`
    : `https://app-beta.brandclub.com/landing?${urlSearchParams.toString()}&${ogQueryParams}`;

  const trackerParams = {
    ...analyticsTrackingParams,
    sharedAffiliateLinkId: affiliateLinkId,
    retailerId,
    retailerSku,
    stacklineSku,
  };

  return {
    shareLink: link,
    shareSubject: `Get rewarded to shop.`,
    trackerParams,
  };
};
