import { Skeleton, styled } from "@mui/material";
import _groupBy from "lodash/groupBy";
import moment from "moment";
import { useMemo } from "react";
import SyncSummaryCard from "./SyncSummaryCard";
import ListEmpty from "./ListEmpty";
import useRetailerIdToNameMap from "../../../../../utils/hooks/useRetailerIdToNameMap";

export type RewardListType = "activity" | "expiring" | "payout";

const SyncSummaryListContainer = styled("div")(({ theme }) => ({
  maxWidth: 764,
  contentVisibility: "auto",
  containIntrinsicSize: "auto 500px",

  ".reward_section": {
    contentVisibility: "auto",
    containIntrinsicSize: "auto 500px",
    marginBottom: 20,
    ".sec_title": {
      fontSize: 18,
      fontWeight: 700,
      color: theme.palette.primary.main,
      marginBottom: 20,
    },
    ".sec_data": {
      display: "flex",
      flexDirection: "column",
      gap: 20,
    },
  },
}));

interface ISection {
  title: string;
  data: any[];
}

const SyncSummaryList = ({
  data,
  loading,
}: {
  data?: any[];
  loading: boolean;
}) => {
  const retailerIdToNameMap = useRetailerIdToNameMap();
  const dataByDay = useMemo(() => {
    const groups = _groupBy(data, (r) =>
      moment(r.updatedAt).format("MMMM D, YYYY")
    );
    return Object.entries(groups).map(
      ([key, value]) => ({ title: key, data: value } as ISection)
    );
  }, [data]);

  if (loading) {
    return (
      <SyncSummaryListContainer>
        <Skeleton
          variant="rectangular"
          width={100}
          height={27}
          style={{ borderRadius: 12, marginBottom: 20 }}
          sx={{ bgcolor: "#f6f8fa" }}
        />
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={198}
          style={{ borderRadius: 12, marginBottom: 20 }}
          sx={{ bgcolor: "#f6f8fa" }}
        />
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={198}
          style={{ borderRadius: 12, marginBottom: 20 }}
          sx={{ bgcolor: "#f6f8fa" }}
        />
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={198}
          style={{ borderRadius: 12, marginBottom: 20 }}
          sx={{ bgcolor: "#f6f8fa" }}
        />
      </SyncSummaryListContainer>
    );
  }
  if (!loading && !dataByDay?.length) {
    return (
      <SyncSummaryListContainer>
        <ListEmpty />
      </SyncSummaryListContainer>
    );
  }
  return (
    <SyncSummaryListContainer>
      {dataByDay.map((section) => {
        return (
          <div key={section.title} className="reward_section">
            <div className="sec_title">{section.title}</div>
            <div className="sec_data">
              {section.data.map((row) => {
                const { aggregatedSyncSessionStatsByRetailer, updatedAt } = row;
                const title = `Synced ${moment(updatedAt).fromNow()}`;
                const retailerIdsByStatus: {
                  [status: string]: {
                    retailerId: number;
                    retailerName: string;
                    syncId: string;
                  }[];
                } = {};
                Object.keys(aggregatedSyncSessionStatsByRetailer).forEach(
                  (x) => {
                    const retailerId = parseInt(x);
                    if (
                      !retailerIdsByStatus[
                        aggregatedSyncSessionStatsByRetailer[retailerId].status
                      ]
                    ) {
                      retailerIdsByStatus[
                        aggregatedSyncSessionStatsByRetailer[retailerId].status
                      ] = [];
                    }
                    retailerIdsByStatus[
                      aggregatedSyncSessionStatsByRetailer[retailerId].status
                    ].push({
                      retailerId,
                      retailerName: retailerIdToNameMap[retailerId],
                      syncId:
                        aggregatedSyncSessionStatsByRetailer[retailerId].syncId,
                    });
                  }
                );
                const formattedItem = {
                  ...row,
                  title,
                  retailerIdsAttempted: Object.keys(
                    aggregatedSyncSessionStatsByRetailer
                  ).map((x) => parseInt(x)),
                  retailerIdsByStatus,
                };
                return (
                  <SyncSummaryCard key={row.syncBatchId} item={formattedItem} />
                );
              })}
            </div>
          </div>
        );
      })}
    </SyncSummaryListContainer>
  );
};

export default SyncSummaryList;
