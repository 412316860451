import { useAppSelector } from "../../../../redux/hooks";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material";
import { SolidButton } from "../../../StoreComponents/StoreButton";
import FlowContainer from "../../../pages/AppPages/Pages/components/FlowContainer";
import { StoreBrandingType } from "../../../../types/misc";
import { useCheckout } from "../../CheckoutMachine";

const EmptyImageUri =
  "https://media.brandclub.com/brandclub/icons/Review_Failed_no_bg.svg";

const Container = styled("div")(({ theme }) => ({
  margin: "65px auto",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  maxWidth: 500,
  padding: "0",
  // marginBottom: 300,
  alignItems: "center",
  ".title": {
    fontSize: 48,
    fontWeight: 700,
    textAlign: "center",
    color: theme.palette.primary.main,
    marginBottom: 40,
  },
  ".empty_image": {
    width: 132,
    height: 132,
    marginBottom: 40,
  },
  ".subtitle": {
    fontSize: 20,
    fontWeight: 700,
    textAlign: "center",
    color: theme.palette.primary.main,
    marginBottom: 12,
  },

  ".text": {
    fontSize: 20,
    fontWeight: 500,
    textAlign: "center",
    color: theme.palette.primary.main,
    marginBottom: 24,
    padding: "0 30px",
  },
}));

export const useIsAllOutOfStock = () => {
  const { snapshot } = useCheckout();

  const errorMessage =
    snapshot?.context?.retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
      ?.errorMessage;
  const batch = snapshot?.context?.checkoutBatch;
  const allOutOfStock = errorMessage === "No items in the cart";
  const showFullScreen = batch?.retailerIds?.length === 1;
  return { allOutOfStock, showFullScreen };
};

const AllOutOfStockScreen = () => {
  const navigate = useNavigate();

  const appConfig = useAppSelector(({ appConfig }) => appConfig);

  const linkTo =
    appConfig?.domainConfig?.storeBrandingType ===
      StoreBrandingType.CustomDTCStore &&
    appConfig?.domainConfig?.customShopDomainName
      ? `https://${appConfig?.domainConfig?.customShopDomainName}/`
      : `/shop`;

  return (
    <FlowContainer>
      <Container className="all_out_of_stock">
        <img alt="" className="empty_image" src={EmptyImageUri}></img>
        <div className="subtitle">
          It looks like your items are out of stock
        </div>

        <div className="text">
          The retailer no longer has inventory available for your order.
        </div>
        <SolidButton
          onClick={() => {
            if (linkTo && linkTo.startsWith("http")) {
              window.open(linkTo, "_self");
            } else if (linkTo) {
              navigate(linkTo);
            }
          }}
          style={{ maxWidth: 355, width: "100%", height: 50, fontSize: 16 }}
        >
          Shop popular products
        </SolidButton>
      </Container>
    </FlowContainer>
  );
};

export default AllOutOfStockScreen;
