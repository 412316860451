import { useMediaQuery } from "@mui/material";
import { CardGridForThree } from "../components/CardGrid";
import { PreviewHeader } from "../components/Header";
import { DealCard } from "./components/DealCard";
import { useFetchDailyDeals } from "./data";
import { ProductRewardSearchResult } from "@/types/search";


import { useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import { MOBILE_SCREEN_SIZE } from "../../../../AppNavigation/constants";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import PreviewContainer from "../components/PreviewContainer";
import { useLinkPrefix } from "../../../../../utils/hooks/useLinkPrefix";

const DailyDealsEmptyRewardPreview = () => {
  const mobileView = useMediaQuery(`(max-width: ${MOBILE_SCREEN_SIZE}px)`);
  const ref = useRef(null);
  const widgetInView = useInView(ref, { once: true, margin: "100px" });
  const { linkPrefix } = useLinkPrefix();
  const { data, loading, fetchInit } = useFetchDailyDeals({
    pageSize: 5,
    autoInitFetch: false,
  });

  useEffect(() => {
    if (widgetInView) {
      fetchInit();
    }
  }, [widgetInView, fetchInit]);

  if (!loading && data.length === 0) {
    return null;
  }

  const dataToRender = mobileView ? data : data.slice(0, 3);

  return (
    <>
      <PreviewContainer ref={ref}>
        {loading ? (
          <StoreLoadingSkeleton header cardStyle="cardForThree" />
        ) : (
          <>
            <PreviewHeader
              title={"Daily deals"}
              linkTo={linkPrefix + "/dailydeals"}
              subtitle="Access the top deals and rewards"
              indicator="grey"
            />

            <CardGridForThree>
              {dataToRender.map((d: ProductRewardSearchResult) => (
                <DealCard product={d} key={d.stacklineSku}/>
              ))}
            </CardGridForThree>
          </>
        )}
      </PreviewContainer>
    </>
  );
};

export default DailyDealsEmptyRewardPreview;
