import { getAuthorizationHeader } from "@/Auth";
import axios from "@/utils/axios";

export enum Status {
  success = "success",
  error = "error",
}

export const startUpdatePhoneFlow = async (payload: {
  phoneNumber: string;
}) => {
  const res = await axios.post<{
    status: Status;
    sessionId: string;
    session: string;
    emailToVerify: string;
    errorMessage?: string;
  }>("/profile/updatePhone/start", payload, {
    headers: {
      ...(await getAuthorizationHeader()),
    },
  });
  const d = res?.data;
  return d;
};

export const verifyEmailWithOTP = async (payload: {
  session: string;
  sessionId: string;
  emailVerificationCodeInput: string;
}) => {
  const res = await axios.post<{
    status: Status;
    session: string;
    errorMessage?: string;
  }>("/profile/updatePhone/verifyEmail", payload, {
    headers: {
      ...(await getAuthorizationHeader()),
    },
  });
  const d = res?.data;
  return d;
};

export const saveNewPhoneNumber = async (payload: {
  session: string;
  sessionId: string;
  phoneNumber: string;
}) => {
  const res = await axios.post<{
    status: Status;
    errorMessage?: string;
  }>("/profile/updatePhone/savePhoneNumber", payload, {
    headers: {
      ...(await getAuthorizationHeader()),
    },
  });
  const d = res?.data;
  return d;
};
