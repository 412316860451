import { Auth } from "aws-amplify";
import { useCallback, useContext, useState } from "react";
import { UserLoginContext } from "../../pages/Auth/UserLoginProvider";
import { useLocation } from "react-router-dom";
import { ERROR_MESSAGES } from "../../../utils/errors/errorUtils";

export type RedirectLoginPath = {
  pathname: string;
  search?: string;
};

interface UseStoreLoginProps {
  redirectPath?: RedirectLoginPath;
}

const localStorageAccountKey = "universal-store-email";
const localStoragePasswordKey = "universal-store-password";
const localStorageRememberMeKey = "universal-store-remember-me";

/**
 * Records the account information in the local storage.
 * @param props - The object containing the account information.
 * @param props.rememberMe - A boolean indicating whether to remember the account.
 * @param props.account - The account name.
 * @param props.password - The account password.
 */
const recordAccount = (props: {
  rememberMe: boolean;
  account: string;
  password: string;
}) => {
  if (props.rememberMe) {
    localStorage.setItem(localStorageAccountKey, props.account);
    localStorage.setItem(localStoragePasswordKey, props.password);
    localStorage.setItem(localStorageRememberMeKey, "true");
  } else {
    localStorage.setItem(localStorageAccountKey, "");
    localStorage.setItem(localStoragePasswordKey, "");
    localStorage.setItem(localStorageRememberMeKey, "false");
  }
};

/**
 * Validates the provided account and password.
 * @param account - The account to validate (email).
 * @param password - The password to validate.
 * @throws {Error} - Throws an error if the account or password is missing.
 */
const validateCredentials = (account: string, password: string) => {
  if (!account.trim() || !password) {
    if (password && !account.trim()) {
      throw new Error("Please enter your email.");
    }
    if (account && !password) {
      throw new Error("Please enter your password.");
    }
    throw new Error(ERROR_MESSAGES.credentialsInvalid[0]);
  }
};

/**
 * Custom hook for handling sign in functionality on the store login page.
 * It will remember the current location and redirect to the sign in page.
 * @returns A callback function that triggers the sign in process.
 */
export const useSignInPage = (initialOpenUrl?: RedirectLoginPath) => {
  const { send, setInitialOpenUrl } = useContext(UserLoginContext);
  const location = useLocation();
  const { pathname: originPathname, search: originSearch } =
    initialOpenUrl ?? location;

  return useCallback(
    (redirectPath?: RedirectLoginPath) => {
      setInitialOpenUrl({ pathname: originPathname, search: originSearch });
      if (redirectPath) {
        send({
          type: "SIGN_IN",
          redirectPath,
        });
      } else {
        send({ type:"SIGN_IN" });
      }
    },
    [send, setInitialOpenUrl, originPathname, originSearch]
  );
};

/**
 * Custom hook for handling store login functionality.
 *
 * @param props - Optional props for configuring the hook behavior.
 * @returns An object containing the state and functions related to store login.
 */
export const useStoreLogin = (props?: UseStoreLoginProps) => {
  const location = useLocation();
  const { setInitialOpenUrl } = useContext(UserLoginContext);
  const originPathname = location.pathname;
  const originSearch = location.search;
  const redirectPathPathname = props?.redirectPath?.pathname;
  const redirectPathSearch = props?.redirectPath?.search;
  const signUp = useSignInPage();

  const [loggingIn, setLoggingIn] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(
    localStorage.getItem(localStorageRememberMeKey) === "true"
  );
  const [account, setAccount] = useState<string>(
    localStorage.getItem(localStorageAccountKey) || ""
  );
  const [password, setPassword] = useState<string>(
    localStorage.getItem(localStoragePasswordKey) || ""
  );
  const [error, setError] = useState<string | undefined>();

  const onPasswordChange = useCallback((password: string) => {
    setPassword(password);
    setError(undefined);
  }, []);

  const onAccountChange = useCallback((account: string) => {
    setAccount(account);
    setError(undefined);
  }, []);

  const signIn = useCallback(async () => {
    try {
      validateCredentials(account, password);
      setLoggingIn(true);
      const user = await Auth.signIn(account.trim(), password);
      if (user) {
        recordAccount({ rememberMe, account, password });
        if (redirectPathPathname) {
          setInitialOpenUrl({
            pathname: redirectPathPathname,
            search: redirectPathSearch,
          });
        } else {
          setInitialOpenUrl({ pathname: originPathname, search: originSearch });
        }
      }
    } catch (e: any) {
      setError(e?.message);
    } finally {
      setLoggingIn(false);
    }
  }, [
    account,
    password,
    rememberMe,
    setInitialOpenUrl,
    originPathname,
    originSearch,
    redirectPathPathname,
    redirectPathSearch,
  ]);

  const createAccount = useCallback(() => {
    signUp(
      redirectPathPathname
        ? { pathname: redirectPathPathname, search: redirectPathSearch }
        : undefined
    );
  }, [signUp, redirectPathPathname, redirectPathSearch]);

  return {
    account,
    error,
    loggingIn,
    onAccountChange,
    onPasswordChange,
    onRememberMeChange: setRememberMe,
    rememberMe,
    password,
    signIn,
    createAccount,
  };
};
