import { Outlet } from "react-router-dom";
import { withFadeTransition } from "../../../StoreComponents/StoreTransition";

const HomeOutlet = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default withFadeTransition(HomeOutlet);
