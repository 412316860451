import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { FC } from "react";

const Line = styled("div")(({ theme }) => ({
  flex: 1,
  height: 1.5,
  backgroundColor: theme.palette.primary.main,
}));

const Word = styled("div")(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  margin: "0 14px",
  color: theme.palette.primary.main,
}));

const StoreLoginOptionsDivider: FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      margin="24px 0 35px 0"
      width="100%"
      {...props}
    >
      <Line />
      <Word>{children ?? "OR"}</Word>
      <Line />
    </Box>
  );
};

export default StoreLoginOptionsDivider;
