import React from "react";
import { Outlet } from "react-router-dom";
// import { MainOutlet } from "../Layout/MainOutlet";

const SearchOutlet = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default SearchOutlet;
