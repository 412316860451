import { Reorder } from "framer-motion";
import { useState } from "react";
import { ProfileContainer } from "../Profile";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { Address } from "../../../../../redux/types";
import _sortBy from "lodash/sortBy";
import { styled, useMediaQuery } from "@mui/material";
import {
  formatFullCityStateZip,
  formatFullName,
} from "../../../../../utils/StringUtils";

import { updateDefaultAddress } from "../../../../../api";

import { reloadProfile } from "../../../../../redux/actions";

import StoreToggle from "../../../../StoreComponents/StoreToggle";
import { Link } from "react-router-dom";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../AppNavigation/constants";
import { DashboardSolidButton } from "../DashboardOutlet";
import { DashboardHeaderContainer } from "../DashboardHeaderContainer";

const AddressCardContainer = styled("div")(({ theme }) => ({
  border: `1px solid #dcdddc`,
  padding: "24px",
  height: "100%",
  boxSizing: "border-box",
  backgroundColor: "#f6f8fa80",
  borderRadius: 8,
  display: "flex",
  flexDirection: "column",
  position: "relative",
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    padding: "20px 20px 20px 20px",
  },

  "&:hover": {
    ".edit": {
      display: "flex",
    },
  },
  ".edit": {
    right: "14px",
    top: "14px",
    position: "absolute",
    display: "none",
    flexDirection: "row",
    justifyContent: "flex-end",
    a: {
      textDecoration: "none",
    },
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      position: "absolute",
      top: 16.4,
      right: 10.3,
    },
  },
  ".info": {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    ".line": {
      fontSize: 16,
      fontWeight: 500,

      "&.name": {
        [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontWeight: 700,
        },
      },
      color: theme.palette.primary.main,
      [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: 12,
      },
    },
  },

  ".action": {
    display: "flex",
    flexDirection: "row",
    height: 25,
    alignItems: "center",
    marginTop: 5,
    ".label": {
      marginLeft: 9.5,
      fontSize: 16,
      fontWeight: 500,
      color: theme.palette.primary.main,
      [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: 12,
      },
    },
  },
}));

const AddressCard = ({
  address,
  isDefault,
  toggleDefault,
  updating,
}: {
  address: Address;
  isDefault: boolean;
  toggleDefault: (address: Address) => void;
  updating: boolean;
}) => {
  return (
    <Link
      to={`/dashboard/address/${address.id}`}
      style={{ textDecoration: "none" }}
    >
      <AddressCardContainer>
        <div className="info">
          <div className="line name">
            {formatFullName(address.firstName, address.lastName)}
          </div>
          <div className="line">{address.address1}</div>
          <div className="line">{address.address2}</div>
          <div className="line">
            {formatFullCityStateZip(
              address.city,
              address.state,
              address.zipCode
            )}
          </div>

          <div></div>
        </div>
        <div
          className="action"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <StoreToggle
            disabled={updating}
            checked={updating ? false : isDefault}
            onClick={() => toggleDefault(address)}
          />

          <div className="label">
            {updating ? "" : isDefault ? "Default" : "Set as default"}
          </div>
        </div>
      </AddressCardContainer>
    </Link>
  );
};

const AddressList = () => {
  const mobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);
  const [updating, setUpdating] = useState(false);
  const dispatch = useAppDispatch();
  const addresses = useAppSelector(
    ({ userProfile }) => userProfile?.addresses || []
  );

  const defaultShippingAddressId = useAppSelector(
    ({ userProfile }) => userProfile?.defaultShippingAddressId || null
  );

  const toggleDefault = async (address: Address) => {
    setUpdating(true);
    await updateDefaultAddress({ shippingAddress: address });
    await dispatch(reloadProfile(true));
    setUpdating(false);
  };

  const addressesOrdered = _sortBy(addresses, (a) =>
    a.id === defaultShippingAddressId ? -1 : 1
  );

  return (
    <ProfileContainer style={{ maxWidth: "100%" }}>
      <div className="profile_header_container">
        <div className="profile_header">
          <DashboardHeaderContainer>
            <div className="title">Addresses</div>
          </DashboardHeaderContainer>
        </div>
        <div className="header_action">
          <Link to={"/dashboard/address/new"}>
            <DashboardSolidButton>Add address</DashboardSolidButton>
          </Link>
        </div>
      </div>

      <Reorder.Group
        as="div"
        values={addressesOrdered}
        onReorder={() => {}}
        style={{
          display: "grid",
          gridTemplateColumns: mobileView ? "repeat(1, 1fr)" : "repeat(3, 1fr)",

          gap: mobileView ? 20 : 25,
          maxWidth: 1048,
          listStyle: "none",
        }}
      >
        {addressesOrdered.map((item) => (
          <Reorder.Item key={item.id} value={item.id} drag={false}>
            <AddressCard
              address={item}
              isDefault={item.id === defaultShippingAddressId}
              toggleDefault={toggleDefault}
              updating={updating}
            />
          </Reorder.Item>
        ))}
      </Reorder.Group>
    </ProfileContainer>
  );
};

export default AddressList;
