import React from "react";
import FlowContainer from "../../components/FlowContainer";
import { brandclub_colors } from "@brandclub/common-ui";

import {
  FlowSolidButton,
  FlowSolidButtonGrey,
} from "../../components/FlowButtons";
import ReviewSelectPill from "../components/ReviewSelectPill";
import { ReviewQuestionContainer } from "./pros";
import Loading from "../../../../../Loading";
import ProductHeader from "../components/ProductHeader";

type ConsPageProps = {
  product: any;
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
  selectedOptions: string[];
  options: string[];
  moveBack: () => void;
  moveNext: () => void;
};

const Cons = ({
  product,
  options,
  selectedOptions,
  setSelectedOptions,
  moveBack,
  moveNext,
}: ConsPageProps) => {
  const handleSelect = (option: string) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((o) => o !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const hasSelectedOptions = selectedOptions.length > 0;
  return (
    <FlowContainer style={{ background: brandclub_colors.white }}>
      <ReviewQuestionContainer>
        <div className="review_info">
          <ProductHeader product={product} />
          <div className="section">
            <div className="title">What features need improved?</div>
            <div className="subtitle">Select all that apply</div>
            <div className="selections">
              {options?.length ? (
                options.map((option) => {
                  const selected = selectedOptions?.includes(option);
                  return (
                    <ReviewSelectPill
                      selected={selected}
                      text={option}
                      onClick={() => {
                        handleSelect(option);
                      }}
                    />
                  );
                })
              ) : (
                <div style={{ width: "100%" }}>
                  <Loading dot />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="actions">
          <FlowSolidButtonGrey
            onClick={() => {
              moveBack();
            }}
          >
            Back
          </FlowSolidButtonGrey>
          <FlowSolidButton
            onClick={() => {
              moveNext();
            }}
          >
            {hasSelectedOptions ? "Next" : "Skip"}
          </FlowSolidButton>
        </div>
      </ReviewQuestionContainer>
    </FlowContainer>
  );
};

export default Cons;
