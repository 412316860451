import { styled } from "@mui/material";
import _get from "lodash/get";
import { useEffect, useState } from "react";

import {
  PreviewNextCustomerPaymentTransaction,
  getNextPayout,
} from "../../../../../api/graphQl/authenticated/Payouts";
import { useAppSelector } from "../../../../../redux/hooks";
import reduxApolloClient from "../../../../../redux/reduxApolloClient";
import ConfigUtils from "../../../../../utils/ConfigUtils";
import { formatMoney } from "../../../../../utils/StringUtils";
import { withFadeTransition } from "../../../../StoreComponents/StoreTransition";
import { InfoButton } from "./components/InfoButton";
import GenericDialog from "../../../../StoreComponents/StoreDialogs/GenericDialog";
import StoreMessageCard from "../../../../StoreComponents/StoreCard.tsx/StoreMessageCard";
import { MOBILE_SCREEN_SIZE } from "../../../../AppNavigation/constants";
import { useSiteContext } from "../../../../SiteProvider";
import {
  getRewardAmountsByStatus,
  joinWaitList,
  sendTextInvite,
} from "../../../../../api";
import { DashboardHeaderContainer } from "../DashboardHeaderContainer";
import PhoneNumber from "awesome-phonenumber";
import { SiteBrandingType } from "../../../../../types/misc";

export const CashoutContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 30,
  ".balance-section": {
    border: `1px solid #dcdddc`,
    borderRadius: 10,
    padding: "10px 30px",
  },
  ".row": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 50,
    ".label": {
      fontSize: 16,
      fontWeight: 500,
      color: theme.palette.primary.main,
      display: "flex",

      alignItems: "center",
    },
    ".value": {
      fontSize: 16,
      fontWeight: 600,
      color: theme.palette.primary.main,
      display: "flex",
      gap: 16,
      alignItems: "center",
    },
  },

  ".row + .row": {
    borderTop: `1px solid #dcdddc`,
  },

  ".get_app .store-message-card__cta": {
    paddingLeft: 100,
    paddingRight: 100,
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      paddingLeft: 75,
      paddingRight: 75,
    },
  },
}));

const handleBalanceDialogOpen = (title: string, text: string) => {
  GenericDialog.show({
    title,
    text,

    actions: [
      {
        text: "Close",
        onClick: () => {},
        variant: "solid",
      },
    ],
  });
};

export const handleTotalRewardsClick = () => {
  handleBalanceDialogOpen(
    "Total rewards",
    "The grand total of all rewards you have earned over the past 365 days."
  );
};

export const handleTotalPayoutClick = () => {
  handleBalanceDialogOpen(
    "Total payouts",
    "The amount of funds you have withdrawn using the cash out feature and transferred to your PayPal or Venmo account."
  );
};

export const handleTotalAdjustmentsClick = () => {
  handleBalanceDialogOpen(
    "Total adjustments",
    "The amount of rewards that were adjusted due to order status changes with your retailer."
  );
};
export const handleRewardBalanceClick = () => {
  handleBalanceDialogOpen(
    "Reward balance",
    "The remaining amount of rewards you have earned, but not yet withdrawn using the cash out feature."
  );
};
export const handlePendingRewardsClick = () => {
  handleBalanceDialogOpen(
    "Pending rewards",
    "The amount of rewards that are still pending since the order has not yet been delivered or confirmed with the retailer. Sync your account to update the status."
  );
};
export const handleLockedRewardsClick = () => {
  handleBalanceDialogOpen(
    "Locked rewards",
    "The amount of rewards not yet eligible to be withdrawn. Locked rewards need to be “unlocked” by making purchases and syncing your accounts."
  );
};
export const handleUnlockedRewardsClick = () => {
  handleBalanceDialogOpen(
    "Unlocked rewards",
    "The amount of rewards eligible to be withdrawn using the cash out feature. You may transfer these funds to your PayPal or Venmo."
  );
};
export const handleCancelledRewardsClick = () => {
  handleBalanceDialogOpen(
    "Cancelled rewards",
    "The amount of rewards that were removed due to order cancellation. Sync your account to get new rewards."
  );
};
export const handleCashOutEligibleClick = () => {
  handleBalanceDialogOpen(
    "Cash out eligible",
    "You are eligible to cash out a maximum of $50 daily."
  );
};

const GetAppCard = ({ balance }: { balance: number }) => {
  const userProfile = useAppSelector(({ userProfile }) => userProfile);
  const given_name = _get(userProfile, ["given_name"], "");
  const [send, setSend] = useState<"send" | "not_sent" | "sending">("not_sent");

  const sendGetAppText = async () => {
    try {
      setSend("sending");
      const { data: waitListEntry } = await joinWaitList({
        phoneNumber:
          userProfile?.phoneNumber?.indexOf("+") === 0
            ? userProfile?.phoneNumber
            : `+1${userProfile?.phoneNumber}`,
        email: "gettextinvite@stackline.com",
        name: "gettextinvite@stackline.com",
      });
      await sendTextInvite({
        id: waitListEntry.id,
        forceSendInvite: true,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setSend("send");
    }
  };

  const onCtaClick = () => {
    if (send === "not_sent") {
      sendGetAppText();
    }
  };

  const pn = new PhoneNumber(userProfile?.phoneNumber || "", "US");
  const formattedPhone = pn.getNumber("national");

  const ctaText =
    send === "sending"
      ? "Sending..."
      : send === "not_sent"
      ? "Text link to " + formattedPhone
      : "Sent to " + formattedPhone;
  return (
    <StoreMessageCard
      className="get_app"
      title={`Congrats, ${given_name}!`}
      content={`You have ${formatMoney(balance)} in rewards. Plus, you can
      earn more rewards in the Brandclub app. Download the app to access
      your rewards.`}
      cta={ctaText}
      onActionClick={onCtaClick}
    />
  );
};

const Balance = () => {
  const { siteBrandingType } = useSiteContext();
  const mainEntity = useAppSelector(({ mainEntity }) => mainEntity);

  const rewards = useAppSelector(({ rewards }) => rewards);
  const [nextPayout, setNextPayout] =
    useState<PreviewNextCustomerPaymentTransaction | undefined>();
  const [rewardSummary, setRewardSummary] = useState<{
    availableBalance: number;
    lifetimeEarnings: number;
    lifetimePayouts: number;
    lifetimeAdjustments: number;
    lockedBalance: number;
    pendingBalance: number;
    unlockedBalance: number;
    cancelledBalance: number;
    hasLoaded: boolean;
  }>({
    lifetimeEarnings: 0,
    lifetimePayouts: 0,
    lifetimeAdjustments: 0,
    lockedBalance: 0,
    pendingBalance: 0,
    unlockedBalance: 0,
    cancelledBalance: 0,
    availableBalance: 0,
    hasLoaded: false,
  });
  const loadBrandRewards = async (brandIds: number[]) => {
    const variables = { brandIds, forceRefresh: true };
    const { data } = await reduxApolloClient.query({
      query: getRewardAmountsByStatus,
      variables,
      context: {
        uri: `${ConfigUtils.getGraphQlUri()}?id=getRewardAmountsByStatus`,
      },
    });
    if (data && data.RewardSummary) {
      return data.RewardSummary;
    }
  };

  useEffect(() => {
    (async () => {
      if (
        mainEntity?.id &&
        (siteBrandingType === SiteBrandingType.CustomStore ||
          siteBrandingType === SiteBrandingType.BrandStore)
      ) {
        const brandRewardSummary = await loadBrandRewards([mainEntity.id]);
        setRewardSummary({ ...brandRewardSummary, hasLoaded: true });
      } else {
        const lifetimeEarnings = _get(
          rewards,
          ["rewards", "lifetimeEarnings"],
          0
        );
        const lifetimePayouts = _get(
          rewards,
          ["rewards", "lifetimePayouts"],
          0
        );
        const lifetimeAdjustments = _get(
          rewards,
          ["rewards", "lifetimeAdjustments"],
          0
        );
        const availableBalance = _get(
          rewards,
          ["rewards", "availableBalance"],
          0
        );
        const lockedBalance = _get(rewards, ["rewards", "lockedBalance"], 0);
        const pendingBalance = _get(rewards, ["rewards", "pendingBalance"], 0);
        const unlockedBalance = _get(
          rewards,
          ["rewards", "unlockedBalance"],
          0
        );
        const cancelledBalance = _get(
          rewards,
          ["rewards", "cancelledBalance"],
          0
        );
        setRewardSummary({
          availableBalance,
          lifetimeEarnings,
          lifetimePayouts,
          lifetimeAdjustments,
          lockedBalance,
          pendingBalance,
          unlockedBalance,
          cancelledBalance,
          hasLoaded: true,
        });
      }
    })();
  }, [siteBrandingType, mainEntity, rewards]);

  const {
    availableBalance,
    unlockedBalance,
    lockedBalance,
    pendingBalance,
    cancelledBalance,
    lifetimeAdjustments,
    lifetimeEarnings,
    lifetimePayouts,
  } = rewardSummary;
  // Do not let it be > unlocked balance (can happen with RDS <> ES sync issues)

  const cashOutEligibleBalance = Math.min(
    nextPayout?.totalCashOutAmount // this will be non zero if there are enough rewards to cover the cash out fee
      ? Math.min(
          (nextPayout?.totalCashOutAmount ?? 0) +
            (nextPayout?.cashOutFeeAmount ?? 0),
          50
        )
      : availableBalance,
    availableBalance
  );

  const fetchNextPayout = async () => {
    try {
      const { data } = await reduxApolloClient.query<{
        PreviewNextCustomerPaymentTransaction: PreviewNextCustomerPaymentTransaction;
      }>({
        query: getNextPayout,
        fetchPolicy: "no-cache",
        context: {
          uri: `${ConfigUtils.getGraphQlUri()}?id=getNextPayoutSummary`,
        },
      });

      setNextPayout(data.PreviewNextCustomerPaymentTransaction);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchNextPayout();
  }, []);

  const rewardBalance =
    lifetimeEarnings - lifetimePayouts - lifetimeAdjustments;

  if (!rewardSummary.hasLoaded) {
    return null;
  }

  return (
    <div>
      <DashboardHeaderContainer>
        <div className="title">Reward balance</div>
      </DashboardHeaderContainer>
      <CashoutContainer>
        <div className="balance-section">
          <div className="row">
            <div className="label">Total rewards</div>
            <div className="value">
              {formatMoney(lifetimeEarnings)}{" "}
              <div role="button" onClick={handleTotalRewardsClick}>
                <InfoButton />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="label">Total payouts</div>
            <div className="value">
              {formatMoney(lifetimePayouts)}{" "}
              <div role="button" onClick={handleTotalPayoutClick}>
                <InfoButton />
              </div>
            </div>
          </div>
          {lifetimeAdjustments ? (
            <div className="row">
              <div className="label">Total adjustments</div>
              <div className="value">
                {formatMoney(lifetimeAdjustments)}{" "}
                <div role="button" onClick={handleTotalAdjustmentsClick}>
                  <InfoButton />
                </div>
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="label">Reward balance</div>
            <div className="value">
              {formatMoney(rewardBalance)}{" "}
              <div role="button" onClick={handleRewardBalanceClick}>
                <InfoButton />
              </div>
            </div>
          </div>
        </div>
        {nextPayout ? (
          <div className="balance-section">
            <div className="sperator" />
            {pendingBalance ? (
              <div className="row">
                <div className="label">Pending rewards</div>
                <div className="value">
                  {formatMoney(pendingBalance)}{" "}
                  <div role="button" onClick={handlePendingRewardsClick}>
                    <InfoButton />
                  </div>
                </div>
              </div>
            ) : null}
            <div className="row">
              <div className="label">Locked rewards</div>
              <div className="value">
                {formatMoney(lockedBalance)}{" "}
                <div role="button" onClick={handleLockedRewardsClick}>
                  <InfoButton />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="label">Unlocked rewards</div>
              <div className="value">
                {formatMoney(unlockedBalance)}{" "}
                <div role="button" onClick={handleUnlockedRewardsClick}>
                  <InfoButton />
                </div>
              </div>
            </div>
            {cancelledBalance ? (
              <div className="row">
                <div className="label">Cancelled rewards</div>
                <div className="value">
                  {formatMoney(cancelledBalance)}{" "}
                  <div role="button" onClick={handleCancelledRewardsClick}>
                    <InfoButton />
                  </div>
                </div>
              </div>
            ) : null}
            <div className="row">
              <div className="label">Cash out eligible</div>
              <div className="value">
                {formatMoney(cashOutEligibleBalance)}{" "}
                <div role="button" onClick={handleCashOutEligibleClick}>
                  <InfoButton />
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {rewardBalance ? <GetAppCard balance={rewardBalance} /> : null}
      </CashoutContainer>
    </div>
  );
};

export default withFadeTransition(Balance);
