import React, { useState, useEffect, useContext, ReactNode } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { UserLoginContext } from "./UserLoginProvider";
import { UserLoginStateToRouteMapping } from "./UserLoginMachines";

type InitialOpenUrl = {
  pathname: string;
  search?: string;
};

/**
 * Returns the route based on the initial open URL.
 * @param initialOpenUrl - The initial open URL object.
 * @returns The route string.
 */
export const getRoute = (initialOpenUrl: InitialOpenUrl) => {
  let search = initialOpenUrl.search || "";
  return `${initialOpenUrl.pathname}${search}`;
};

/**
 * Checks if a given key exists in an object.
 * @param obj - The object to check.
 * @param key - The key to check for existence in the object.
 * @returns A boolean indicating whether the key exists in the object.
 */
const isObjectKey = <T extends Object>(
  obj: T,
  key: keyof any
): key is keyof T => {
  return key in obj;
};

/**
 * Checks if a value is an object.
 *
 * @param value - The value to check.
 * @returns `true` if the value is an object, `false` otherwise.
 */
const isObject = (value: unknown): value is Object => {
  return typeof value === "object" && value !== null;
};

/**
 * Checks if an object is empty.
 * @param obj - The object to check.
 * @returns True if the object is empty, false otherwise.
 */
const isObjectEmpty = (obj: Object) => {
  return Object.keys(obj).length === 0;
};

/**
 * Checks if the initial open URL is valid based on the provided stateRouteMapping.
 * @param initialOpenUrl - The initial open URL to check.
 * @param stateRouteMapping - The mapping of user login states to routes.
 * @returns True if the initial open URL is valid, false otherwise.
 */
const isValidInitialOpenUrl = (
  initialOpenUrl?: InitialOpenUrl,
  stateRouteMapping = UserLoginStateToRouteMapping
): initialOpenUrl is InitialOpenUrl => {
  if (!initialOpenUrl) {
    return false;
  }
  const routesToSkip = Object.values(stateRouteMapping).map((r) =>
    r.toLowerCase()
  );
  return !routesToSkip.includes(initialOpenUrl.pathname.toLowerCase());
};

/**
 * Choose the first valid path from the list of paths.
 * Valid paths are paths that are defined and not in the stateRouteMapping.
 * @param paths the list of paths to choose from.
 * @param stateRouteMapping optional mapping of state to route.
 * @returns the first valid path or undefined if none are valid.
 */
const chooseRedirectPath = (
  paths: (InitialOpenUrl | undefined)[],
  stateRouteMapping = UserLoginStateToRouteMapping
) => {
  const validPaths = paths.filter((path) =>
    isValidInitialOpenUrl(path, stateRouteMapping)
  );
  return validPaths[0];
};

/**
 * Returns the validated route corresponding to the machine state.
 *
 * @param machineState - The current state of the machine.
 * @param initialOpenUrl - The initial open URL. Used to redirect to the correct page if the state is not found in the mapping.
 * @param stateRouteMapping - The mapping of states to routes.
 * @returns The route corresponding to the machine state, or undefined if no valid route is found.
 */
export const getRouteForState = (
  machineState: any,
  initialOpenUrl?: InitialOpenUrl,
  stateRouteMapping = UserLoginStateToRouteMapping
): string | undefined => {
  const stateValue = machineState.value;
  const redirectPath = chooseRedirectPath(
    [machineState.context?.redirectPath, initialOpenUrl],
    stateRouteMapping
  );

  if (isObjectKey(stateRouteMapping, stateValue)) {
    const newState = stateRouteMapping[stateValue];
    return newState;
  }

  if (redirectPath) {
    return getRoute(redirectPath);
  }
  if (stateValue === "goHome") {
    return "/";
  }
  if (isObject(stateValue) && !isObjectEmpty(stateValue)) {
    const [key] = Object.keys(stateValue);
    return key;
  }
  return undefined;
};

const AuthOutlet = ({ children }: { children: ReactNode }) => {
  const { actorRef, initialOpenUrl } = useContext(UserLoginContext);
  const navigate = useNavigate();
  const { search } = useLocation();
  const [previousStateValue, setPreviousStateValue] = useState<string>("");

  useEffect(() => {
    // redirect logic here
    // use useEffect to handle redirect people to the right signup step
    // /signin -> (Optional /setupProfile) -> /onBoardingJoinClub -> /onBoardingConnectRetailer
    const subscribe = actorRef.subscribe((newState) => {
      const oldRoute = window.location.pathname
      const newRoute = getRouteForState(newState, initialOpenUrl)
      const query = new URLSearchParams(search);
      const hasRouteChanged = oldRoute !== newRoute;
      const currentStateValue = newState.value;
      if (previousStateValue !== currentStateValue) {
        setPreviousStateValue(currentStateValue);
      }

      const hasStateChanged = previousStateValue !== currentStateValue;
      if (hasRouteChanged || hasStateChanged) {
        if (newRoute) {
          const replace =
            oldRoute === "/initialize" || newRoute === "/initialize";
          navigate(
            {
              pathname: newRoute.split("?")[0],
              search: query.toString(),
            },
            { replace: replace }
          ); // redirects should always replace the current route
        } else {
          navigate(
            {
              pathname: "/",
              search: query.toString(),
            },
            { replace: true }
          ); // redirects should always replace the current route
        }
      }
    });
    return () => subscribe.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actorRef, initialOpenUrl, search]);

  return <div>{children}</div>;
};

export default AuthOutlet;
