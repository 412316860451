import { decodeHTMLCharacters } from "@stackline/ui";
import { ReviewProductResponse } from "../../../../../../types/misc";
import { ProductRewardSearchResult } from "../../../../../../types/search";
import LongRewardCard from "../../../../../StoreComponents/StoreCard.tsx/LongRewardCard";

import { CampaignType } from "@brandclub/common-ui";
import { getEntityImage } from "../../../../../../utils/StringUtils";

const LongReviewCard = ({
  product,
}: {
  product: Partial<ReviewProductResponse & ProductRewardSearchResult>;
}) => {
  const cleanProductTitle = decodeHTMLCharacters(product?.title || "");
  return (
    <LongRewardCard
      type="review"
      title="Write review"
      productTitle={cleanProductTitle}
      rewardAmount={0}
      image={getEntityImage(product?.stacklineSku ?? "", "product") || ""}
      rewardType={CampaignType.RatingCampaign}
      linkTo={`/review/${product.stacklineSku}`}
      border={true}
      linkState={{
        product: product,
        brandId: product.brandId,
        brandName: product.brandName,
      }}
    ></LongRewardCard>
  );
};

export default LongReviewCard;
