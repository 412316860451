import React, { useEffect } from "react";

import { Backdrop, Dialog, styled } from "@mui/material";
import { CloseIcon, hexadecimal } from "@brandclub/common-ui";
import StoreRetailerLogosOverlap from "../../../../StoreComponents/StoreRetailerLogosOverlap";
import { getSignedScreenshotUrl } from "../../../../../api";
import BrandclubVideo from "../../../../BrandclubVideo";
import Loading from "../../../../Loading";

export interface SyncSummaryCardRenderingProps {
  id: string;
  show: boolean;
  icon: string;
  title: string;
  subtitle1?: string;
  subtitle2?: string;
  amount?: string;
  linkTitle: string;
  linkSubtitle1?: string;
  linkSubtitle2?: string;
  linkIndicatorDot?: "yellow" | "purple" | "blue" | "green" | "red";
  steps?: any[];
  currentStep?: number;
  retailerIdsAttempted: number[];
  retailerIdsByStatus: {
    [status: string]: {
      retailerId: number;
      syncId: string;
      retailerName: string;
    }[];
  };
}

const INDICATOR_COLOR_MAPPING = {
  yellow: "#ffbd32",
  blue: "#5b71b2",
  red: "#de6055",
  purple: "#a21671",
  green: "#71c184",
};

const CardContainer = styled("div")(({ theme }) => ({
  border: "1.5px solid #dcdddc",
  borderRadius: 10,
  widows: "100%",

  ".top_container": {
    padding: "17px 25px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ".icon": {
      width: 80,
      height: 80,
      marginRight: 25,
      objectFit: "contain",
    },
    ".detail": {
      flex: 1,
      minWidth: 0,
      ".card_title": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        fontSize: 18,
        fontWeight: 600,
        color: theme.palette.primary.main,
      },
      ".card_subtitle": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        fontSize: 16,
        fontWeight: 500,
        color: theme.palette.primary.main,
      },
    },
    ".amount": {
      marginLeft: 25,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      ".amount_title": {
        fontSize: 16,
        fontWeight: 500,
      },
      ".amount_value": {
        fontSize: 18,
        fontWeight: 600,
      },
    },
  },
  ".bot_container": {
    // height: 50,
    padding: "17px 25px",
    backgroundColor: "#f3f4f5",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    ".link_title": {
      display: "flex",
      alignItems: "center",
      fontSize: 16,
      fontWeight: 600,
      ".card_indicator_dot": {
        display: "inline-block",
        marginLeft: 8,
        height: 8,
        width: 8,
        borderRadius: 8,
      },
    },
    ".link_subtitle": {
      display: "flex-row",
      alignItems: "center",
      fontSize: 14,
      fontWeight: 500,
      marginRight: 10,
    },
  },
}));

const SyncDetailsContainer = styled("div")(({ theme }) => ({
  width: 581,
  maxWidth: 581,
  borderRadius: 10,
  padding: "35px 50px 40px 50px",
  position: "relative",
  boxSizing: "border-box",
  ".close": {
    cursor: "pointer",
    position: "absolute",
    top: 20,
    right: 20,
    width: 20,
    height: 20,
    border: "none",
    backgroundColor: "transparent",
    outline: "none",
    img: {
      width: 13,
      height: 13,
      objectFit: "contain",
    },
  },
  ".header": {
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.primary.main,
    margin: "10px 0 26px 0",
  },
  ".step_warp": {
    display: "flex",
    gap: 26,
    ".indicator": {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      ".progress_bg": {
        width: 14,
        borderRadius: 6,
        height: "100%",
        backgroundColor: "#e4eaef",
        ".progress_bar": {},
      },

      ".dot": {
        width: 30,
        height: 30,
        display: "flex",
        zIndex: 1,
        alignItems: "center",
        justifyContent: "center",
        ".check_icon": {
          width: 18,
          height: 18,
          objectFit: "contain",
        },
      },
    },
    ".stepper": {
      display: "flex",
      flexDirection: "column",
      gap: 70,
      ".single_step": {
        maxWidth: 374,
        ".title": {
          display: "flex",
          height: 30,
          alignItems: "center",
          fontSize: 20,
          fontWeight: 600,
          color: theme.palette.primary.main,
        },
        ".desc": {
          fontSize: 14,
          fontWeight: 500,
          color: theme.palette.primary.main,
          height: 50,
        },
      },
    },
  },
}));

const SyncSummaryDetail = ({
  retailerSyncToShow,
  onClose,
}: {
  retailerSyncToShow: { retailerId: number; syncId: string };
  onClose: () => void;
}) => {
  const [loading, setLoading] = React.useState(true);
  const [fileUrl, setFileUrl] = React.useState<string>();
  useEffect(() => {
    (async () => {
      setLoading(true);
      const { status, signedScreenshotUrl } = await getSignedScreenshotUrl({
        retailerId: retailerSyncToShow.retailerId,
        syncId: retailerSyncToShow.syncId,
        fileName: "session_recording.mp4",
      });
      if (status === "success") {
        setFileUrl(signedScreenshotUrl);
      } else {
        setFileUrl(undefined);
      }
      setLoading(false);
    })();
  }, [retailerSyncToShow]);
  return (
    <SyncDetailsContainer>
      <button className="close" onClick={onClose}>
        <CloseIcon color="primary" className="close" />
      </button>
      <div className="header">Sync screen recording</div>
      {loading && <Loading dot></Loading>}
      {!loading && fileUrl && (
        <BrandclubVideo videoUrl={fileUrl} canPlay={true} />
      )}
      {!loading && !fileUrl && <div>Screen not found</div>}
    </SyncDetailsContainer>
  );
};

const SyncSummaryCard = ({ item }: { item: SyncSummaryCardRenderingProps }) => {
  const [retailerSyncToShow, setRetailerSyncToShow] = React.useState<any>();
  const [openSyncSummaryDetail, setOpenSyncSummaryDetail] =
    React.useState(false);

  const closeSyncSummaryDetail = () => {
    setOpenSyncSummaryDetail(false);
  };
  return (
    <>
      <CardContainer>
        <div className="top_container">
          <div className="detail">
            <StoreRetailerLogosOverlap
              retailerIds={item.retailerIdsAttempted}
              size={57}
            />
            <div className="card_title">{item.title}</div>
            <div className="card_subtitle">{item.subtitle1}</div>
            <div className="card_subtitle">{item.subtitle2}</div>
          </div>
        </div>
        <div className="bot_container">
          <div>
            <div className="link_title">
              {item.linkTitle}
              {item.linkIndicatorDot ? (
                <div
                  className="card_indicator_dot"
                  style={{
                    backgroundColor:
                      INDICATOR_COLOR_MAPPING[item.linkIndicatorDot],
                  }}
                />
              ) : null}
            </div>
            {item.retailerIdsByStatus["completed"]?.length > 0 && (
              <div>
                <span className="link_subtitle">Completed: </span>
                {item.retailerIdsByStatus["completed"]?.map((retailer) => {
                  return (
                    <span
                      className="link_subtitle"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setRetailerSyncToShow({
                          retailerId: retailer.retailerId,
                          syncId: retailer.syncId,
                        });
                        setOpenSyncSummaryDetail(true);
                      }}
                    >
                      {retailer.retailerName}
                    </span>
                  );
                })}
              </div>
            )}
            {item.retailerIdsByStatus["error"]?.length > 0 && (
              <div>
                <span className="link_subtitle">Error: </span>
                {item.retailerIdsByStatus["error"]?.map((retailer) => {
                  return (
                    <span
                      className="link_subtitle"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setRetailerSyncToShow({
                          retailerId: retailer.retailerId,
                          syncId: retailer.syncId,
                        });
                        setOpenSyncSummaryDetail(true);
                      }}
                    >
                      {retailer.retailerName}
                    </span>
                  );
                })}
              </div>
            )}
            {item.retailerIdsByStatus["timed_out"]?.length > 0 && (
              <div>
                <span className="link_subtitle">Timed out: </span>
                {item.retailerIdsByStatus["timed_out"]?.map((retailer) => {
                  return (
                    <span
                      className="link_subtitle"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setRetailerSyncToShow({
                          retailerId: retailer.retailerId,
                          syncId: retailer.syncId,
                        });
                        setOpenSyncSummaryDetail(true);
                      }}
                    >
                      {retailer.retailerName}
                    </span>
                  );
                })}
              </div>
            )}
            {item.retailerIdsByStatus["queued"]?.length > 0 && (
              <div>
                <span className="link_subtitle">Queued: </span>
                {item.retailerIdsByStatus["queued"]?.map((retailer) => {
                  return (
                    <span
                      className="link_subtitle"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setRetailerSyncToShow({
                          retailerId: retailer.retailerId,
                          syncId: retailer.syncId,
                        });
                        setOpenSyncSummaryDetail(true);
                      }}
                    >
                      {retailer.retailerName}
                    </span>
                  );
                })}
              </div>
            )}
          </div>

          <div>{/* <RightArrow width={5} /> */}</div>
        </div>
      </CardContainer>

      <Dialog
        slots={{
          backdrop: () => (
            <Backdrop
              onClick={closeSyncSummaryDetail}
              open={openSyncSummaryDetail}
              sx={(theme) => ({
                backgroundColor: hexadecimal(theme.palette.primary.main)(21),
              })}
            />
          ),
        }}
        open={openSyncSummaryDetail}
        onClose={(e) => closeSyncSummaryDetail()}
      >
        <SyncSummaryDetail
          retailerSyncToShow={retailerSyncToShow}
          onClose={closeSyncSummaryDetail}
        />
      </Dialog>
    </>
  );
};

export default SyncSummaryCard;
