import { TextField, styled } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  SearchIcon,
  TuneIcon,
  brandclub_colors,
  FilterDialog,
} from "@brandclub/common-ui";
import { PageContext } from "../pages/AppPages/BrandStore/BrandStoreLandingPage";
import { useSiteContext } from "../SiteProvider";
import axios from "../../utils/axios";

const SearchBarContainer = styled("div")({
  flex: 1,
  // "@keyframes pulsate": {
  //   "0%": {
  //     opacity: 0,
  //   },
  //   "60%": {
  //     opacity: 0,
  //   },
  //   "100%": {
  //     opacity: 1,
  //   },
  // },
  // animation: "pulsate .5s",
});

const FilterIcon = styled("div")(({ theme }) => ({
  height: 25,
  width: 25,
  cursor: "pointer",
  border: `1.5px solid`,
  borderColor: "#00000000",
  borderRadius: 300,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  ".filter_dot": {
    position: "absolute",
    backgroundColor: theme.palette.primary.main,
    height: 6,
    width: 6,
    borderRadius: 300,
    top: "-1.2px",
    right: "-1.2px",
    border: `1.5px solid ${brandclub_colors.blueWhite}`,
    display: "none",
  },
  "&.unfiltered": {
    color: brandclub_colors.gray,
    opacity: 0.3,
    borderColor: brandclub_colors.gray,
  },
  "&.filtered": {
    borderColor: theme.palette.primary.main,
    boxSizing: "border-box",

    ".filter_dot": {
      display: "block",
    },
  },
}));

const convertSearchParamToFilterCount = (searchParams: URLSearchParams) => {
  const params: any = {};
  searchParams.forEach((value, key) => {
    let decodedKey = decodeURIComponent(key);
    const decodedValue = decodeURIComponent(value);
    // This key is part of an array
    if (decodedKey.endsWith("[]")) {
      decodedKey = decodedKey.replace("[]", "");
      params[decodedKey] || (params[decodedKey] = []);
      params[decodedKey].push(decodedValue);
      // Just a regular parameter
    } else {
      params[decodedKey] = decodedValue;
    }
  });

  let count = 0;

  if (params.campaignTypes) {
    count += 1;
  }
  if (params.retailPrice) {
    count += 1;
  }
  if (params.retailerIds) {
    count += 1;
  }
  if (params.categoryIds) {
    count += 1;
  }
  if (params.subCategoryIds) {
    count += 1;
  }

  return count;
};

const SearchInput = ({
  searchInput,
  setSearchInput,
  filterDialogOpen,
  setFilterDialogOpen,
  filterCount,
  setFilterCount,
  onSubmit,
  onClose,
  pageContext,
  persistentQueryParams,
  searchIcon = false,
}: {
  searchInput: string;
  setSearchInput: (input: string) => void;
  filterDialogOpen: boolean;
  setFilterDialogOpen: (open: boolean) => void;
  filterCount: number;
  setFilterCount: (count: number) => void;
  onSubmit: () => void;
  onClose: () => void;
  pageContext: PageContext;
  persistentQueryParams?: string;
  searchIcon?: boolean;
}) => {
  // On press enter, navigate to search results page
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { routePrefix, brandId } = useSiteContext();

  useEffect(() => {
    const newFilterCount = convertSearchParamToFilterCount(searchParams);
    setFilterCount(newFilterCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <SearchBarContainer>
      <FilterDialog
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        navigate={navigate}
        open={filterDialogOpen}
        onClose={() => setFilterDialogOpen(false)}
        persistentQueryParams={persistentQueryParams}
        searchParams={searchParams}
        pageContext={pageContext}
        onCloseSearchDropdown={onClose}
        routePrefix={routePrefix}
        brandId={brandId}
        axios={axios}
      />
      <TextField
        aria-owns="search-popover"
        aria-haspopup="true"
        id="filled-adornment-weight"
        aria-describedby="filled-weight-helper-text"
        variant="standard"
        placeholder="Search products"
        autoFocus
        value={searchInput}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onClose();
            onSubmit();
          }
        }}
        onChange={(event) => setSearchInput(event.target.value)}
        // inputProps={{ maxLength: 200 }}
        InputProps={{
          autoComplete: "off",
          "aria-label": "weight",
          disableUnderline: true,
          startAdornment: searchIcon ? (
            <SearchIcon
              color="primary"
              sx={{ fontSize: 19, marginRight: 1.25 }}
              className="search_icon"
            />
          ) : undefined,
          endAdornment: (
            <FilterIcon
              className={filterCount ? "filtered" : "unfiltered"}
              onClick={() => {
                setFilterDialogOpen(true);
              }}
            >
              <div className="filter_dot" />
              <TuneIcon color="primary" sx={{ fontSize: 12 }} role="button" />
            </FilterIcon>
          ),
        }}
        sx={(theme) => ({
          borderRadius: 17,
          //   border: `1px solid ${brandclub_colors.blueWhiteBorder}`,
          height: 50,
          width: "100%",

          // backgroundColor: brandclub_colors.blueWhite,
          "& div.MuiInput-root": {
            padding: "0 4px 0 14px",
            display: "flex",
            alignItems: "center",
            height: 50,
            "& input": {
              padding: 0,
              fontSize: 16,
              fontWeight: 500,
              flex: 1,
              color: theme.palette.primary.main,
            },
          },
        })}
      />
    </SearchBarContainer>
  );
};

export default SearchInput;
