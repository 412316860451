import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import { CardGrid } from "../components/CardGrid";
import { LandingHeader } from "../components/Header";
import LandingPageWrapper from "../components/LandingPageWrapper";
import { useFetchTrendingProduct } from "./data";
import { useInView } from "framer-motion";
import RewardEmptyPage from "../components/RewardEmptyPage";
import ProductCard from "../../../../StoreComponents/StoreCard.tsx/ProductCard";
import LandingContainer from "../components/LandingContainer";

interface TrendingLandingProps {
  brandIds?: number[];
}
const TrendingLanding = (props: TrendingLandingProps) => {
  const { data, loading, loadingMore, fetchMore, hasMore } =
    useFetchTrendingProduct({
      pageSize: 20,
      brandIds: props.brandIds,
    });

  const ref = useRef<any>(null);

  const widgetInView = useInView(ref);

  useEffect(() => {
    if (widgetInView && !loading && !loadingMore && hasMore) {
      fetchMore();
    }
  }, [widgetInView, loading, loadingMore, hasMore, fetchMore]);

  if (!loading && data.length === 0) {
    return <RewardEmptyPage emptyTitle="No trending products?" />;
  }
  return (
    <LandingPageWrapper>
      <LandingContainer>
        {loading ? (
          <StoreLoadingSkeleton header />
        ) : (
          <>
            <LandingHeader
              title="Trending"
              subtitle="Popular products with great rewards"
            ></LandingHeader>
            <CardGrid style={{ marginTop: 20 }}>
              {data.map((d) => {
                return (
                  <ProductCard
                    LinkComponent={Link}
                    product={d}
                    key={`trending:${d.stacklineSku}`}
                  />
                );
              })}
            </CardGrid>
          </>
        )}
        {loadingMore ? (
          <div style={{ marginTop: 20 }}>
            <StoreLoadingSkeleton header={false} />
          </div>
        ) : null}

        <div className="end_detect" ref={ref}></div>
      </LandingContainer>
    </LandingPageWrapper>
  );
};

export default TrendingLanding;
