import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import Stripe from "stripe";
import { formatExpiry } from "../../../../../utils/StringUtils";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../AppNavigation/constants";

import CardBrandIcon from "./CardBrandIcon";

const PaymentCardContainer = styled("div")(({ theme }) => ({
  padding: "24px",
  height: "100%",
  boxSizing: "border-box",
  backgroundColor: "#f6f8fa80",
  borderRadius: 8,
  display: "flex",
  flexDirection: "column",
  border: `1px solid #dcdddc`,

  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    padding: "20px 20px 20px 20px",
  },
  position: "relative",
  "&:hover": {
    ".edit": {
      display: "flex",
    },
  },
  ".edit": {
    right: "14px",
    top: "14px",
    position: "absolute",
    display: "none",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",

    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      marginBottom: 10,
    },
    ".card_title": {
      flex: 1,
      fontWeight: 700,
      fontSize: 16,
      color: theme.palette.primary.main,
      [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: 12,
      },
    },
  },
  ".payment_details": {
    display: "flex",
    flexDirection: "row",
    gap: 30,
  },
  ".info": {
    display: "flex",
    flexDirection: "column",
    flex: 1,

    ".line": {
      ".payment_logo": {
        maxHeight: 21,
        maxWidth: 30,
        marginRight: 10,

        [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          maxWidth: 15,
          maxHeight: 20,
        },
      },
      marginTop: 1.5,
      marginBottom: 1.5,
      display: "flex",
      alignItems: "center",
      fontSize: 16,
      fontWeight: 500,
      color: theme.palette.primary.main,
      [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: 12,
      },
    },
  },

  ".action": {
    display: "flex",
    flexDirection: "row",
    height: 25,
    alignItems: "center",
    marginTop: 10,
    ".label": {
      marginLeft: 9.5,
      fontSize: 16,
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
  },
}));

export const PaymentCard = ({ payment }: { payment: Stripe.PaymentMethod }) => {
  const {
    billing_details: { name },
    card,
  } = payment;

  const expiration = formatExpiry(card?.exp_year, card?.exp_month);

  return (
    <Link
      to={`/dashboard/wallet/${payment.id}`}
      style={{ textDecoration: "none" }}
    >
      <PaymentCardContainer>
        <div className="payment_details">
          <div className="info">
            <div className="line">{name}</div>
            <div className="line">
              <CardBrandIcon cardBrand={card?.brand}></CardBrandIcon>
              {`ending in ${card?.last4}`}
            </div>
            {expiration ? (
              <div className="line">{`Expiration ${expiration}`}</div>
            ) : null}
          </div>
        </div>
      </PaymentCardContainer>
    </Link>
  );
};
