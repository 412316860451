import { styled } from "@mui/material";
import {
  OutlineButton,
  SolidButton,
} from "../../../../StoreComponents/StoreButton";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../AppNavigation/constants";

const size = {
  height: 50,
  fontSize: 16,
  paddingLeft: 30,
  paddingRight: 30,
};
export const FlowSolidButton = styled(SolidButton)({
  ...size,
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    fontSize: 14,
    height: 45,
    paddingLeft: 26,
    paddingRight: 26,
  },
});

export const FlowSolidButtonGrey = styled(SolidButton)(({ theme }) => ({
  ...size,
  backgroundColor: "#f6f8fa",
  borderColor: "#f6f8fa",
  color: theme.palette.primary.main,
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    fontSize: 14,
    height: 45,
    paddingLeft: 26,
    paddingRight: 26,
  },
}));

export const FlowOutlineButton = styled(OutlineButton)({
  ...size,
  border: "#fff",
  backgroundColor: "#fff",

  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    fontSize: 14,
    height: 45,
    paddingLeft: 26,
    paddingRight: 26,
  },
});

export const FlowOutlineBorderButton = styled(OutlineButton)(({ theme }) => ({
  ...size,
  border: `1.5px solid ${theme.palette.primary.main}`,
  backgroundColor: "#fff",

  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    fontSize: 14,
    height: 45,
    paddingLeft: 26,
    paddingRight: 26,
  },
}));
