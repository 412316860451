import { ProductRewardSearchResult } from "@brandclub/common-ui";

import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import { CardGrid } from "../components/CardGrid";
import { PreviewHeader } from "../components/Header";
import PreviewWidgetWrapper from "../components/PreviewWidgetWrapper";
import { useFetchTopPicks } from "./data";
import { useInView } from "framer-motion";
import PreviewContainer from "../components/PreviewContainer";
import { defaultPreviewLazyLoadConfig } from "../../../../../utils/lazyLoadConfig";
import ProductCard from "../../../../StoreComponents/StoreCard.tsx/ProductCard";

interface TopPicksPreviewProps {
  brandIds?: number[];
}
const TopPicksPreview = (props: TopPicksPreviewProps) => {
  const { data, loading, loadingMore, fetchInit } = useFetchTopPicks({
    pageSize: 40,
    autoInitFetch: false,
    brandIds: props.brandIds,
  });

  const widgetRef = useRef<any>(null);

  const widgetInView = useInView(widgetRef, defaultPreviewLazyLoadConfig);

  useEffect(() => {
    if (widgetInView) {
      fetchInit();
    }
  }, [widgetInView, fetchInit]);

  if (!loading && data.length === 0) {
    return null;
  }

  return (
    <PreviewWidgetWrapper ref={widgetRef}>
      <PreviewContainer>
        {loading ? (
          <StoreLoadingSkeleton header />
        ) : (
          <>
            <PreviewHeader
              title={"Top picks for you"}
              linkTo="/toppicks"
              subtitle="Product recommendations for you"
              indicator="grey"
            />
            <CardGrid>
              {data.map((d) => (
                // <card survey={d} />
                // <></>
                <ProductCard
                  key={d.stacklineSku}
                  LinkComponent={Link}
                  product={d as ProductRewardSearchResult}
                ></ProductCard>
              ))}
            </CardGrid>
          </>
        )}
        {loadingMore ? (
          <div style={{ marginTop: 20 }}>
            <StoreLoadingSkeleton header={false} />
          </div>
        ) : null}
        <div className="end_detect"></div>
      </PreviewContainer>
    </PreviewWidgetWrapper>
  );
};

export default TopPicksPreview;
