import { useEffect, useRef } from "react";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import { LongCardGrid } from "../components/CardGrid";
import { LandingHeader } from "../components/Header";
import LandingPageWrapper from "../components/LandingPageWrapper";
import SurveyCard from "./components/SurveyCard";
import { useFetchSurveys } from "./data";
import RewardEmptyPage from "../components/RewardEmptyPage";
import { useInView } from "framer-motion";
import LandingContainer from "../components/LandingContainer";

const SurveyLanding = ({ brandclubIds }: { brandclubIds?: string[] }) => {
  const { data, loading, loadingMore, fetchMore, hasMore } = useFetchSurveys({
    pageSize: 20,
    brandclubIds,
  });

  const ref = useRef<any>(null);

  const widgetInView = useInView(ref);

  useEffect(() => {
    if (widgetInView && !loading && !loadingMore && hasMore) {
      fetchMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetInView, loading, loadingMore, hasMore]);

  if (!loading && data.length === 0) {
    return <RewardEmptyPage emptyTitle="No surveys?" />;
  }
  return (
    <LandingPageWrapper>
      <LandingContainer>
        {loading ? (
          <StoreLoadingSkeleton cardStyle="longCard" header />
        ) : (
          <>
            <LandingHeader
              title="Surveys"
              subtitle="Earn rewards for completing surveys"
            />

            <LongCardGrid style={{ marginTop: 20 }}>
              {data.map((d) => (
                <SurveyCard key={`survey:${d.campaignId}`} survey={d} border />
              ))}
            </LongCardGrid>
          </>
        )}

        {loadingMore ? (
          <div style={{ marginTop: 20 }}>
            <StoreLoadingSkeleton cardStyle="longCard" header={false} />
          </div>
        ) : null}
        <div className="end_detect" ref={ref}></div>
      </LandingContainer>
    </LandingPageWrapper>
  );
};

export default SurveyLanding;
