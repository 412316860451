import ConfigUtils from "../../utils/ConfigUtils";
import { AppDispatch } from "../store";
import reduxApolloClient from "../reduxApolloClient";
import { getRewardAmountsByStatus } from "../../api/graphQl/authenticated";
import { setRewards, setRewardsLoading } from "../reducers/rewards";

export const loadRewards = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setRewardsLoading(true));
    const { data } = await reduxApolloClient.query({
      query: getRewardAmountsByStatus,
      fetchPolicy: "no-cache",
      variables: {
        forceRefresh: true,
      },
      context: {
        uri: `${ConfigUtils.getGraphQlUri()}?id=getRewardAmountsByStatus`,
      },
    });
    if (data) {
      dispatch(setRewards(data.RewardSummary));
    }
  } catch (e) {
    console.error("loadRewards error: ", e);
  } finally {
    dispatch(setRewardsLoading(false));
  }
};
