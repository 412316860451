import { getRetailerCheckoutOrderStatus } from "@/api/rest/checkoutApi";
import { useRef, useEffect } from "react";
import { useCheckout } from "./CheckoutMachine";

const ServerSideCheckoutStatusPoller = () => {
  const { send, snapshot } = useCheckout();
  const activeCheckoutOrder = snapshot.context.activeCheckoutOrder;
  const aborterRef = useRef(new AbortController());
  const pollIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const fetchOrderForPoll = async (orderId: string) => {
    aborterRef.current.abort();
    const controller = new AbortController();
    aborterRef.current = controller;
    try {
      const res = await getRetailerCheckoutOrderStatus(
        {
          orderId,
        },
        controller.signal
      );
      send({
        type: "ON_CHECKOUT_STATUS_CHANGE",
        data: {
          retailerCheckoutOrderStatus: res,
        },
      });
      return res;
    } catch (e) {
      console.log("polling error", e);
    }
  };
  useEffect(() => {
    if (!activeCheckoutOrder?.orderId) {
      return;
    }
    pollIntervalRef.current = setInterval(async () => {
      fetchOrderForPoll(activeCheckoutOrder.orderId);
    }, 3e3);

    return () => {
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current);
      }
    };
  }, [activeCheckoutOrder?.orderId]);

  return <> </>;
};

export default ServerSideCheckoutStatusPoller;
