import React, { useCallback, useEffect, useState, useContext } from "react";
import _get from "lodash/get";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { UserLoginContext } from "./UserLoginProvider";
import ReduxStore from "../../../redux/types";
import { loadTopBrands, reloadProfile } from "../../../redux/actions";
import { getBrandAppConfigs } from "../../../redux/reducers/appConfig/thunk";
import { loadReferralReward } from "../../../redux/actions/referralReward";
import { getCustomerSpendByBrand } from "../../../redux/reducers/customerSpendByBrand/thunk";
import { getAllCarts } from "@/redux/selectors";
import { getUserSignedInState, setSharedAuthCookie } from "../../../Auth";
import StoreFullScreenLoading from "../../StoreComponents/StoreFullScreenLoading";
import { getActiveCarts } from "@/redux/reducers/checkout/thunk";

const InitializeUserData = () => {
  const { snapshot, send } = useContext(UserLoginContext);
  const { retailers, appConfig, userProfile } = useAppSelector(
    (state) => state
  );

  const topBrands = useAppSelector(({ topBrands }: ReduxStore) => topBrands);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const dispatch = useAppDispatch();
  const allCarts = useAppSelector(getAllCarts);

  useEffect(() => {
    dispatch(
      getBrandAppConfigs({ appId: appConfig?.domainConfig?.domainName })
    );
  }, [dispatch, appConfig?.domainConfig?.domainName]);

  const getCartsToConnect = useCallback(() => {
    const allCartsSet = new Set(allCarts.map((cart) => cart.retailerId));

    const connect = retailers.filter((retailer) => {
      const status = _get(
        userProfile,
        [
          "extendedAttributes",
          `connectedRetailer_${retailer.parentRetailerId}_state`,
        ],
        "invalid"
      );
      return (
        retailer.parentRetailerId &&
        status !== "valid" &&
        allCartsSet.has(retailer.parentRetailerId)
      );
    });

    return new Set(connect.map((retailer) => retailer.parentRetailerId));
  }, [allCarts, retailers, userProfile]);

  useEffect(() => {
    const retailersToConnect = getCartsToConnect();
    send({
      type: "SET_DISCONNECTED_CHECKOUT_RETAILERS",
      disconnectedCheckoutRetailers: retailersToConnect,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCarts, userProfile, retailers]);

  const initUser = useCallback(async () => {
    try {
      const { signedIn, userInfo } = await getUserSignedInState();
      if (userInfo && signedIn) {
        await setSharedAuthCookie();
        send({ type: "RECEIVE_USER", user: userInfo });

        const promises: any[] = [];

        promises.push(dispatch(loadTopBrands()));
        promises.push(dispatch(reloadProfile()));
        promises.push(dispatch(loadReferralReward()));
        promises.push(dispatch(getCustomerSpendByBrand()));
        promises.push(dispatch(getActiveCarts()));

        await Promise.all(promises);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setAllDataLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, send]);

  useEffect(() => {
    (async () => {
      if (allDataLoaded) {
        if (appConfig) {
          send({ type: "RECEIVE_APP_CONFIG", appConfig });
        }
        if (userProfile) {
          send({ type: "RECEIVE_USER_PROFILE", userProfile });
        }
        if (topBrands) {
          send({
            type: "RECEIVE_TOP_BRANDS",
            topBrands: topBrands?.topBrands,
          });
        }
        send({ type: "INITIALIZED_USER_DATA" });
      }
    })();

    if (
      userProfile?.email &&
      userProfile?.given_name &&
      snapshot.context.ssoRedirectUrl
    ) {
      window.location.href = snapshot.context.ssoRedirectUrl;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, topBrands, allDataLoaded, appConfig]);

  useEffect(() => {
    initUser();
  }, [initUser]);

  return <StoreFullScreenLoading />;
};

export default InitializeUserData;
