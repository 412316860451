import { brandclub_colors } from "@brandclub/common-ui";
import { styled, keyframes } from "@mui/material";
import { useNavigate } from "react-router";
import Carousel from "@/components/Carousel";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../AppNavigation/constants";
import InviteFriendsDialog from "../../../StoreComponents/StoreDialogs/InviteFriendsDialog";
import { useSyncContext } from "../../../SyncProvider";
import PreviewWidgetWrapper from "../Pages/components/PreviewWidgetWrapper";
import { useUserLogin } from "../../Auth/UserLoginProvider";
import "../../../home.scss";
import { useAppSelector } from "@/redux/hooks";
import { formatMoney } from "@/utils/StringUtils";
import { useRewards } from "./useRewards";

const ArrowButton = styled("div")({
  display: "flex",
  "&:before": {
    display: "none !important",
  },
  "&:after": {
    display: "none !important",
  },
  "&.slick-disabled": {
    display: "none",
  },
});

const NextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <ArrowButton className={className} style={{}} onClick={onClick}>
      <img
        className="perks_icon"
        src="https://media.brandclub.com/brandclub/image_asset/arrow_right_blue.svg"
        alt="arrow_l"
      />
    </ArrowButton>
  );
};

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;

  return (
    <ArrowButton className={className} style={{}} onClick={onClick}>
      <img
        className="perks_icon"
        src="https://media.brandclub.com/brandclub/image_asset/arrow_left_blue.svg"
        alt="arrow_l"
      />
    </ArrowButton>
  );
};
const sliderSettings = {
  infinite: false,
  speed: 500,
  centerPadding: "60px",
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  draggable: true,
  responsive: [
    {
      breakpoint: 99999,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 1310,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 1,
        infinite: false,
      },
    },

    {
      breakpoint: TABLET_SCREEN_SIZE,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: MOBILE_SCREEN_SIZE,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
      },
    },
  ],
};
interface IAction {
  icon: string;
  title: string;
  onClick?: () => void;
  notification?: string;
  shouldShow: boolean;
}

export const ACTION_LOGO_LINK_PREFIX =
  "https://media.brandclub.com/brandclub/icons/actions/";
const HomeActionContainer = styled("div")({
  width: "100%",
  padding: "20px 0 30px 0",
  // display: "flex",
  // justifyContent: "center",

  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    padding: "15px 0 15px 0",
  },
  ".action_container": {
    display: "block",
  },
});

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const SingleAction = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 20,
  position: "relative",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: 14,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    gap: 10,
    marginTop: 10,
  },
  cursor: "pointer",
  transition: "transform 0.4s",
  width: "100%",
  maxWidth: 120,
  ".action_notification": {
    position: "absolute",
    top: -12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "25px",
    borderRadius: 999,
    color: "#fff",
    fontSize: 12,
    fontWeight: 600,
    boxSizing: "border-box",
    minWidth: 42,
    padding: "0 12px",
    backgroundColor: theme.palette.primary.main,
    animation: `${fadeIn} 0.5s ease-in`,
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      height: "18px",
      top: -7,
      padding: "0 8px",
      minWidth: 22,
      fontSize: 9,
    },
  },
  ".image_container": {
    maxWidth: 120,
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      maxWidth: 100,
    },
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      maxWidth: 80,
    },
    width: "100%",
    aspectRatio: "1/1",
    backgroundColor: brandclub_colors.blueWhite,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    gap: 7,
    img: {
      marginTop: 10,
      aspectRatio: "1/1",
      width: "47%",
      height: "47%",
      maxWidth: 55,
      maxHeight: 55,
      objectFit: "contain",
    },
  },
  ".label": {
    fontSize: 14,
    fontWeight: 600,
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 10,
    },
  },
}));

const ActionBlock = ({ action }: { action: IAction }) => {
  return (
    <SingleAction className="action" onClick={action.onClick}>
      {action.notification ? (
        <div className="action_notification">{action.notification}</div>
      ) : null}
      <div className="image_container">
        <img alt="bc_action" src={ACTION_LOGO_LINK_PREFIX + action.icon}
          style={{
            pointerEvents: "none",
          }}
        />
        <div className="label">{action.title}</div>
      </div>
    </SingleAction>
  );
};

const HomeActionSection = () => {
  const { syncAllRetailer } = useSyncContext();
  const navigate = useNavigate();

  const { signedIn: isSignedIn } = useUserLogin();
  const { amountForReferringUser } = useAppSelector(
    ({ referralReward }) => referralReward
  );
  const { totalContentRewards, accountRewards, contentCount, surveyRewards, inviteRewards, rewards } = useRewards();

  const actionButtons: IAction[] = [
    {
      shouldShow: isSignedIn,
      icon: "sync-purchases.svg",
      title: "Sync",
      onClick: syncAllRetailer,
    },
    {
      shouldShow: true,
      icon: "accounts.svg",
      title: "Accounts",
      onClick: () => {
        navigate("/dashboard/accounts");
      },
      notification: accountRewards ? formatMoney(accountRewards) : undefined,
    },
    {
      shouldShow: isSignedIn,
      icon: "purchasev2.svg",
      title: "Purchases",
      onClick: () => {
        navigate("/dashboard/purchase");
      },
    },
    {
      shouldShow: isSignedIn,
      icon: "reviews.svg",
      title: "Reviews",
      onClick: () => {
        navigate("/productreviews");
      },
      notification: contentCount ? contentCount.toString() : undefined,
    },
    {
      shouldShow: true,
      icon: "content.svg",
      title: "Content",
      onClick: () => {
        navigate("/contentrewards");
      },
      notification: totalContentRewards ? formatMoney(totalContentRewards) : undefined,
    },
    {
      shouldShow: true,
      icon: "surveys.svg",
      title: "Surveys",
      onClick: () => {
        navigate("/surveys");
      },
      notification: surveyRewards ? formatMoney(surveyRewards) : undefined,
    },
    {
      shouldShow: true,
      icon: "affiliate.svg",
      title: "Affiliate",
      onClick: () => {
        navigate("/affiliate");
      },
    },
    {
      icon: "invite-dark.svg",
      title: "Invite",
      onClick: () => {
        InviteFriendsDialog.show();
      },
      notification: amountForReferringUser
        ? formatMoney(amountForReferringUser)
        : undefined,
      shouldShow: isSignedIn || !!amountForReferringUser,
    },
    {
      icon: "join.svg",
      title: "Join",
      onClick: () => {
        navigate("/clubinvites");
      },
      shouldShow: true,
      notification: inviteRewards ? formatMoney(inviteRewards) : undefined,
    },
    {
      icon: "view-balance.svg",
      title: "Rewards",
      onClick: () => {
        navigate("/dashboard/rewards/balance");
      },
      notification: rewards?.currentBalance
        ? formatMoney(rewards?.currentBalance)
        : undefined,
      shouldShow: isSignedIn,
    },
    {
      icon: "expiring.svg",
      title: "Expiring",
      onClick: () => {
        navigate("/dashboard/rewards/expiring");
      },
      notification: rewards?.expiringRewardAmount
        ? formatMoney(rewards?.expiringRewardAmount)
        : undefined,
      shouldShow: isSignedIn && !!rewards?.rewards?.expiringRewardAmount,
    },
    {
      icon: "cash-out.svg",
      title: "Cash out",
      onClick: () => {
        navigate("/dashboard/rewards/balance");
      },
      notification: rewards?.availableBalance
        ? formatMoney(rewards?.availableBalance)
        : undefined,
      shouldShow: isSignedIn,
    },
    {
      icon: "dashboard.svg",
      title: "Dashboard",
      onClick: () => {
        navigate("/dashboard");
      },
      shouldShow: isSignedIn,
    },
  ];

  const actionToRender = actionButtons.filter((action) => {
    if (action.title === "Sync" || action.title === "Reviews") {
      return isSignedIn;
    }
    return true;
  });
  return (
    <PreviewWidgetWrapper backgroundColor="white">
      <HomeActionContainer
        sx={{
          ".action_container": {
            gridTemplateColumns: "repeat(auto-fit, minmax(80px, 120px))",
            padding: "0 5%",
            [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
              padding: "0 0",
            },
            [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
              padding: "0 0",
              gridTemplateColumns: "repeat(4, 1fr)",
            },
            ".slick-list": {
              overflow: "scroll",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            },
          },
        }}
      >
        <div className="action_container">
          <Carousel
            showArrows={true}
            slidesToShow={8}
          >
            {actionToRender.map((action, idx) => {
              return (
                <div key={idx}>
                  <ActionBlock key={action.title} action={action}></ActionBlock>
                </div>
              );
            })}
          </Carousel>
        </div>
      </HomeActionContainer>
    </PreviewWidgetWrapper>
  );
};

export default HomeActionSection;
