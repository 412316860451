import React from "react";
import { Navigate } from "react-router-dom";
import Loading from "../Loading";
import AffiliateLanding from "../pages/AppPages/Pages/Affiliate/landing";
import BuyItAgainLanding from "../pages/AppPages/Pages/BuyAgain/landing";
import ClubInvitesLanding from "../pages/AppPages/Pages/ClubInvites/landing";
import ContentLanding from "../pages/AppPages/Pages/Content/landing";
import DailyDealsLanding from "../pages/AppPages/Pages/DailyDeals/landing";
import DepartmentLanding, {
  DepartmentLandingDetail,
} from "../pages/AppPages/Pages/Departments/landing";
import InstantRewardsLanding from "../pages/AppPages/Pages/InstantRewards/landing";
import MyClubLanding from "../pages/AppPages/Pages/MyClubs/landing";
import RecentlyViewedLanding from "../pages/AppPages/Pages/RecentlyViewed/landing";
import ReviewLanding from "../pages/AppPages/Pages/Review/landing";
import SurveyLanding from "../pages/AppPages/Pages/Survey/landing";
import TopPickLanding from "../pages/AppPages/Pages/TopPicks/landing";
import TrendingLanding from "../pages/AppPages/Pages/Trending/landing";
import SignInRedirect from "../pages/Auth/SignInRedirect";
import SignOutRedirect from "../pages/Auth/SignOutRedirect";
import MyBag from "../Checkout/MyBag";
import HomeOutlet from "../pages/AppPages/Home/HomeOutlet";
import Home from "../pages/AppPages/Home";
import ShopOutlet from "../pages/AppPages/Shop/ShopOutlet";
import Shop from "../pages/AppPages/Shop";
import SearchOutlet from "../pages/AppPages/Search/SearchOutlet";
import Search from "../pages/AppPages/Search";
import { AppRoutesWithChildrenProps } from "./routeBuilder";

const BrandStoreLandingPage = React.lazy(
  () => import("../pages/AppPages/BrandStore/BrandStoreLandingPage")
);
const ScanGetAppQRCode = React.lazy(() => import("../ScanGetAppQRCode"));

export const fallbackRoutes: AppRoutesWithChildrenProps[] = [
  { path: "signinredirect", element: <SignInRedirect /> },
  { path: "signoutredirect", element: <SignOutRedirect /> },
  { path: "scangetappqrcode", element: <ScanGetAppQRCode /> },
  { path: "loading", element: <Loading star fullscreen /> },
  { path: "*", element: <Navigate to="" /> },
];

const departmentRoutes: AppRoutesWithChildrenProps[] = [
  { path: "department/:departmentId", element: <DepartmentLanding /> },
  {
    path: "department/:departmentId/instant",
    element: <DepartmentLandingDetail type={"instant"} />,
  },
  {
    path: "department/:departmentId/best",
    element: <DepartmentLandingDetail type={"best"} />,
  },
  {
    path: "department/:departmentId/popular",
    element: <DepartmentLandingDetail type={"popular"} />,
  },
];

/**
 * Routes for brandstore pages of the brandclub universal store
 */
export const brandstoreLandingRoutes: AppRoutesWithChildrenProps[] = [
  {
    path: ":brandName/b/:brandId/*",
    element: <BrandStoreLandingPage />,
    pageType: "BrandStoreSummary",
    pageId: "BrandStoreSummary",
  },
  {
    path: ":title/p/:sku/*",
    element: <BrandStoreLandingPage />,
    pageType: "Catalog",
    pageId: ":sku",
  },
];
export const dtcCommonAppPageRoutes: AppRoutesWithChildrenProps[] = [
  {
    path: "mybag/",
    element: <MyBag />,
    pageType: "MyBag",
    pageId: "MyBag",
  },
];

export const commonAppPageRoutes: AppRoutesWithChildrenProps[] = [
  {
    path: "mybag/",
    element: <MyBag />,
    pageType: "MyBag",
    pageId: "MyBag",
  },
  {
    path: "dailydeals/",
    element: <DailyDealsLanding />,
    pageType: "DailyDeals",
    pageId: "DailyDeals",
  },
  {
    path: "surveys/",
    element: <SurveyLanding />,
    pageType: "Survey",
    pageId: "SurveySummary",
  },
  { path: "myclubs/", element: <MyClubLanding /> },
  {
    path: "contentrewards/",
    element: <ContentLanding />,
    pageType: "Content",
    pageId: "ContentSummary",
  },
  {
    path: "affiliate/",
    element: <AffiliateLanding />,
    pageType: "Affiliate",
    pageId: "AffiliateSummary",
  },
  { path: "clubinvites/", element: <ClubInvitesLanding /> },
  {
    path: "trending/",
    element: <TrendingLanding />,
    pageType: "Trending",
    pageId: "Trending",
  },
  {
    path: "toppicks/",
    element: <TopPickLanding />,
    pageType: "TopPicks",
    pageId: "TopPicks",
  },
  {
    path: "productreviews/",
    element: <ReviewLanding />,
    pageType: "Reviews",
    pageId: "ReviewsSummary",
  },
  {
    path: "recent/",
    element: <RecentlyViewedLanding />,
    pageType: "RecentlyViewed",
    pageId: "RecentlyViewed",
  },
  {
    path: "buyagain/",
    element: <BuyItAgainLanding />,
    pageType: "BuyItAgain",
    pageId: "BuyItAgain",
  },
  {
    path: "instantrewards/",
    element: <InstantRewardsLanding />,
    pageType: "Instant",
    pageId: "Instant",
  },
  ...departmentRoutes,
];

/**
 * Routes for app pages to be nested under AppMainPageOutlet
 */
export const appPageRoutes: AppRoutesWithChildrenProps[] = [
  ...commonAppPageRoutes,
  {
    path: "/",
    element: <HomeOutlet />,
    children: [
      { path: "", element: <Home />, pageType: "Home", pageId: "Home" },
    ],
  },
  {
    path: "shop/",
    element: <ShopOutlet />,
    children: [
      { path: "", element: <Shop />, pageType: "Shop", pageId: "Shop" },
    ],
  },
  {
    path: "search/",
    element: <SearchOutlet />,
    children: [
      {
        path: "",
        element: <Search />,
        pageType: "SearchResultsPage",
        pageId: "SearchResultsPage",
      },
    ],
  },
];

/**
 * Routes for custom brandstore app pages to be nested under AppMainPageOutlet
 */
export const customBrandpageAppRoutes: AppRoutesWithChildrenProps[] = [
  ...commonAppPageRoutes,
  {
    path: ":title/p/:sku/*",
    element: <BrandStoreLandingPage />,
    pageType: "Catalog",
    pageId: ":sku",
  },
  {
    path: "*",
    element: <BrandStoreLandingPage />,
    pageType: "BrandStoreSummary",
    pageId: "BrandStoreSummary",
  },
];
