import { CardGrid } from "../components/CardGrid";
import { PreviewHeader } from "../components/Header";
import ReviewCard from "./components/ReviewCard";
import { useFetchReviews } from "./data";
import PreviewWidgetWrapper from "../components/PreviewWidgetWrapper";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import colors from "../../../../styles/colors";
import { useEffect, useRef } from "react";
import { useInView } from "framer-motion";
import PreviewContainer from "../components/PreviewContainer";
import { defaultPreviewLazyLoadConfig } from "../../../../../utils/lazyLoadConfig";
import { useLinkPrefix } from "../../../../../utils/hooks/useLinkPrefix";
import { useResponsiveData } from "../../../../../utils/hooks/useResponsiveData";
import { isWhite } from "../../../../../utils/StringUtils";

const ReviewPreview = ({
  brandIds,
  backgroundColor = colors.blueWhite,
  arrowColor = "blue",
}: {
  brandIds?: number[];
  backgroundColor?: string;
  arrowColor?: "blue" | "grey";
}) => {
  const ref = useRef(null);
  const widgetInView = useInView(ref, defaultPreviewLazyLoadConfig);
  const { linkPrefix } = useLinkPrefix();
  const { data, loading, fetchInit } = useFetchReviews({
    pageSize: 5,
    autoInitFetch: false,
    brandIds,
  });

  useEffect(() => {
    if (widgetInView) {
      fetchInit();
    }
  }, [widgetInView, fetchInit]);

  const responsiveData = useResponsiveData(data);

  if (!loading && data.length === 0) {
    return null;
  }
  return (
    <PreviewWidgetWrapper ref={ref} backgroundColor={backgroundColor}>
      <PreviewContainer>
        {loading ? (
          <StoreLoadingSkeleton
            header
            bgColor={isWhite(backgroundColor) ? "white" : "grey"}
          />
        ) : (
          <>
            <PreviewHeader
              title={"Product reviews"}
              linkTo={linkPrefix + "/productreviews"}
              subtitle="Share feedback by writing reviews"
              indicator={arrowColor}
            />

            <CardGrid>
              {responsiveData.map((d) => (
                <ReviewCard product={d} key={d.stacklineSku} />
              ))}
            </CardGrid>
          </>
        )}
      </PreviewContainer>
    </PreviewWidgetWrapper>
  );
};

export default ReviewPreview;
