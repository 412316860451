import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { updateMFADetails } from "../../../../../api";
import { useAppSelector } from "../../../../../redux/hooks";
import { Retailer } from "../../../../../redux/types";
import { RetailerSyncSession } from "../../../../../types/misc";
import Loading from "../../../../Loading";
import { AuthSignUpPageContainer } from "../../../Auth/AuthSignUpPageOutlet";
import AuthSolidButton from "../../../Auth/components/AuthSolidButton";
import { useTrackActions } from "../../../../../utils/hooks/useTracking";
import { AnalyticsTrackingEvent } from "@brandclub/common-ui";

const ROW_SPACING = 25;

const ChooseMFAOption = ({
  retailerId: retailerIdFromProps,
  retailerSyncSession: retailerSyncSessionFromProps,
  onCompleteCallback,
  className,
}: {
  className?: string;
  retailerId?: number;
  retailerSyncSession?: RetailerSyncSession;
  onCompleteCallback?: () => Promise<void> | void;
}) => {
  const [trackAction] = useTrackActions();
  const [retailer, setRetailer] = useState<Retailer>();
  const { retailerId: retailerIdFromParams } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { retailerSyncSession: retailerSyncSessionFromState, navigateBackTo } =
    state || {};

  const retailers = useAppSelector(({ retailers }) => retailers);
  const [logoLoading, setLogoLoading] = useState(true);
  const [mfaOption, setMfaOption] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>();

  useEffect(() => {
    trackAction(AnalyticsTrackingEvent.SERVER_SIDE_SYNC_MFA_CHOOSE_OPEN, {
      retailer,
      retailerSyncSessionFromState,
      retailerSyncSessionFromProps,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (retailers && (retailerIdFromParams || retailerIdFromProps)) {
      const retailerId = parseInt(
        `${retailerIdFromParams || retailerIdFromProps}`
      );
      const currentRetailer = retailers.find(
        (r) => r.retailerId === retailerId
      );
      if (currentRetailer) {
        setRetailer(currentRetailer);
      }
    }
  }, [retailerIdFromParams, retailerIdFromProps, retailers]);

  return (
    <AuthSignUpPageContainer className={className}>
      {!retailer ||
      !(retailerSyncSessionFromState || retailerSyncSessionFromProps) ||
      isSubmitting ? (
        <div className="page_title">
          <Loading dot />
        </div>
      ) : (
        <div className="auth_container">
          <img
            style={{ contentVisibility: logoLoading ? "hidden" : "visible" }}
            onLoad={() => setLogoLoading(false)}
            onLoadStart={() => setLogoLoading(true)}
            className="retailer_logo"
            alt="r_logo"
            src={retailer?.logo}
          ></img>
          <div className="page_title" style={{ marginTop: "30px" }}>
            2-step verification
          </div>
          <div className="page_subtitle" style={{ marginTop: "30px" }}>
            {
              "Choose how would you like to receive your One Time Password (OTP)"
            }
          </div>
          <div className="page_content">
            <FormControl>
              <RadioGroup
                aria-labelledby="choose-mfa-option-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setMfaOption((event.target as HTMLInputElement).value);
                }}
              >
                {(
                  retailerSyncSessionFromState || retailerSyncSessionFromProps
                ).eventSequence.data.otpOptions.map((otpOption: string) => {
                  return (
                    <FormControlLabel
                      key={otpOption}
                      value={otpOption}
                      control={<Radio />}
                      label={otpOption}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </div>
          <AuthSolidButton
            trackedAdditionalData={{
              retailer,
              retailerSyncSessionFromState,
              retailerSyncSessionFromProps,
              mfaOption,
            }}
            style={{
              marginTop: ROW_SPACING + 5,
            }}
            disabled={!mfaOption || isSubmitting}
            onClick={async () => {
              setIsSubmitting(true);
              await updateMFADetails({
                syncId: (
                  retailerSyncSessionFromState || retailerSyncSessionFromProps
                ).syncId,
                reqId: (
                  retailerSyncSessionFromState || retailerSyncSessionFromProps
                ).eventSequence?.id,
                selectedOption: mfaOption,
                eventType: "choose",
              });
              if (navigateBackTo) {
                navigate(navigateBackTo);
              }
              if (onCompleteCallback) {
                await onCompleteCallback();
              }
              setIsSubmitting(false);
            }}
          >
            Next
          </AuthSolidButton>
        </div>
      )}
    </AuthSignUpPageContainer>
  );
};

export default ChooseMFAOption;
