import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { MOBILE_SCREEN_SIZE } from "../../AppNavigation/constants";
import { SolidButton, OutlineButton } from "../../StoreComponents/StoreButton";
import StoreConfetti, {
  ConfettiContainer,
} from "../../StoreComponents/StoreConfetti";
import React, { useContext } from "react";
import { UserLoginContext } from "./UserLoginProvider";
import { AuthSignUpPageContainer } from "./AuthSignUpPageOutlet";
import { Link } from "react-router-dom";

export const OnBoardingSuccess = () => {
  const { send } = useContext(UserLoginContext);
  const handleSubmit = () => {
    send({ type: "SUBMIT" });
  };
  return (
    <AuthSignUpPageContainer>
      <Box
        className="auth_container"
        style={{
          width: "auto",
          maxWidth: "unset",
        }}
      >
        <ConfettiContainer
          style={{
            // This is needed to undo the wildcard CSS rule that sets margin-left and margin-right to auto in the auth_container
            marginLeft: "unset",
            marginRight: "unset",
          }}
        >
          <StoreConfetti />
        </ConfettiContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: 405,
          }}
        >
          <Typography component="h2" className="page_title" textAlign="center">
            You’re all set
          </Typography>
          <Typography fontSize="16px" className="page_subtitle">
            Your account is now setup and ready to get started.
          </Typography>
          <SolidButton
            sx={{
              width: "100%",
              maxWidth: "355px",
              marginTop: 4,
              paddingTop: 1.75,
              paddingBottom: 1.75,
              marginBottom: 2,
              fontSize: "16px",
              [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
                marginBottom: "18px",
              },
            }}
            onClick={handleSubmit}
          >
            Get Started
          </SolidButton>
          <Link
            to={`/dashboard/rewards/activity`}
            style={{
              textDecoration: "none",
              width: "100%",
              maxWidth: 355,
              display: "inline-flex",
              justifyContent: "center",
            }}
          >
            <OutlineButton
              sx={{
                width: "100%",
                fontSize: 16,
                paddingTop: 1.75,
                paddingBottom: 1.75,
              }}
            >
              View rewards
            </OutlineButton>
          </Link>
        </Box>
      </Box>
    </AuthSignUpPageContainer>
  );
};
