import { useEffect } from "react";
import ConfigUtils from "../utils/ConfigUtils";
import { useAppSelector } from "../redux/hooks";

const DownloadApp = () => {
  const appConfig = useAppSelector(({ appConfig }) => appConfig);

  useEffect(() => {
    if (!appConfig?.brandAppConfig) return;
    let params = "";
    if (window?.location?.search) {
      params = new URLSearchParams(window.location.search).toString();
    }
    const downloadAppLinkUrl = ConfigUtils.getBranchDownloadAppLink(
      appConfig.brandAppConfig
    );
    const redirectUrl =
      downloadAppLinkUrl + (params ? `?${params.toString()}` : "");
    window.location.href = redirectUrl;
  }, [appConfig]);

  return <></>;
};

export default DownloadApp;
