import { gql } from "@apollo/client";

export interface PreviewNextCustomerPaymentTransaction {
  totalCashOutAmount: number;
  cashOutFeeAmount: number;
  rewardIds: string[];
}

export const getNextPayout = gql`
  query {
    PreviewNextCustomerPaymentTransaction {
      totalCashOutAmount
      cashOutFeeAmount
      rewardIds
    }
  }
`;
