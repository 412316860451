import {
  BrandclubEntityType,
  IReduxStoreBase,
  ProductRewardSearchResult,
  BrandClub,
} from "@brandclub/common-ui";
import {
  PaymentOption,
  PaymentStatus,
  RewardStatus,
  UserStatus,
} from "@brandclub/types";
import { ExtendedGetResult } from "@fingerprintjs/fingerprintjs-pro";
import { AnyAction } from "redux";
import { Category, StoreBrandingType } from "../types/misc";
import {
  UserRetailerCheckoutBatch,
  UserRetailerShoppingCart,
} from "../components/Checkout/types";

export enum EligibilityStatus {
  ENABLED = "enabled",
  DISABLED = "disabled",
}

export type RetailerSyncConfig = {
  clientSide: EligibilityStatus;
  serverSide: EligibilityStatus;
};

export interface Retailer {
  retailerId: number;
  name: string;
  color: string;
  displayName: string;
  type: string;
  currencySymbol: string;
  locale: string;
  logo: string;
  baseOrderUrl: string;
  isBrandClubReady: boolean;
  isSocialPlatform: boolean;
  retailerConnectRewardAmount?: number;
  existingRetailerConnectReward?: {
    rewardAmount: number;
    rewardStatus: RewardStatus;
    paymentStatus: PaymentStatus;
    rewardCurrency: "USD";
    createdTime: Date;
  };
  retailerType?: string;
  currentSubRetailerId?: number;
  // Unique Id used for connect and sync
  parentRetailerId: number;
  parentDisplayName?: string;
  sortScore?: number | string;
  tosUrl?: {
    policies: string;
    terms: string;
  };
  syncConfig: RetailerSyncConfig;
  retailerLoginUsernameFieldTypes?: string[];
}

export type RetailerWithSyncStatus = Retailer & {
  connectionStatus: "valid" | "invalid" | "new";
  currentSyncStatus: "none" | "syncing" | "anotherSyncInProgress";
};

export interface UserProfile {
  email: string;
  email_verified: "true" | "false";
  emailVerified: boolean;
  family_name: string;
  given_name: string;
  name: string;
  phone_number_verified: "false" | "true";
  picture: string;
  sub: string;
  referralCode: string;
  referralStatus: "active" | "inactive";
  referralMax: number;
  username: string;
  paymentOption?: PaymentOption;
  userPoolType: "brand" | "consumer";
  phoneNumber?: string;
  phoneNumberVerified: boolean;
  createdAt: string;
  extendedAttributes: any;
  oauthUserId: string;
  updatedAt: string;
  userId: string;
  gender?: string;
  birthdate?: string;
  profileImageUrl?: string;
  userStatus: UserStatus;
  userPersonalization: any;
  defaultShippingAddress?: Address;
  userPhysicalIdVerificationSessionId?: string;
  userPhysicalIdVerificationStatus?:
    | "notVerified"
    | "verificationStarted"
    | "verificationInProgress"
    | "verificationSuccess"
    | "verificationFailed"
    | "verificationRedacted"
    | "verificationCancelled";
  userPhysicalIdVerificationErrorCode?: string;
  userPhysicalIdVerificationLastUpdated?: number;
  defaultShippingAddressId?: string;
  billingAccountId?: string;
  billingPaymentMethodIds?: string[];
}

export interface Address {
  id?: string;
  firstName: string;
  lastName: string;
  address1: string;
  address2?: string;
  state: string;
  city: string;
  country: string;
  zipCode: string;
  phoneNumber?: string;
}

export interface TopBrand {
  brandId: number;
  brandclubId: string;
  rewardAmount?: number;
  name: string;
  imageUrl: string;
  expiringRewardAmount?: number;
}

export interface IAppAuthConfig {
  userPoolId: string;
  userPoolClientId: string;
  identityPoolId: string;
  signInDomain: string;
  region: string;
}

export interface IAppDomainConfig {
  domainName: string;
  storeName: string;
  brandId: number;
  brandName: string;
  storeBrandingType: StoreBrandingType;
  checkoutAllowedOfferType?: "firstPartyOnly" | "thirdPartyOnly" | "both";
  customShopDomainName?: string;
  storeDescription?: string;
  mobileAppLink?: string;
}

export interface DepartmentForRedux {
  departmentId: number;
  departmentName: string;
  relevancyScore: number;
  categories: Category[];
}

export interface BrandEntity {
  entity: any;
  brandclub?: BrandClub;
  loading?: boolean;
  error?: any;
  id?: any;
  type?: BrandclubEntityType.Brand;
}

interface SocialLinks {
  youtube?: string;
  instagram?: string;
  x?: string;
  facebook?: string;
  pinterest?: string;
  tiktok?: string;
}

export interface BrandAppConfig {
  appConfigId: string;
  version: string;
  appName: string;
  companyId: string;
  appStatus: string;
  brandIds: number[];
  brandclubIds: string[];
  retailerIds: number[];
  checkoutBrandId: number;
  mobileAppConfig?: MobileAppConfig;
  updatedBy: string;
  updatedTime: string;
  publishedVersion: number;
  appId: string;
  appType: string;
  configId: string;
  branchIOConfig: {
    webAppBranchLink: string;
    mobileAppBranchLink: string;
    accessToken: string;
    key: string;
    appId: string;
  };
  socialLinks?: SocialLinks;
  membershipPageConfig?: {
    surveyPicture?: string;
    logoPicture?: string;
  };
}

export interface MobileAppConfig {
  appIcon: string;
  appHeaderLogo: string;
  signInLogo: string;
  shareLinkPreviewImage: string;
  splashScreen?: AppConfigSplashScreen;
  theme?: AppConfigTheme;
  font?: AppConfigFont;
}

// Splash Screen Type
export interface AppConfigSplashScreen {
  backgroundColor: string;
  imageUrl: string;
}

// Theme Type
export interface AppConfigTheme {
  primaryColor: string;
  headerColor: string;
  tertiaryColor: string;
  secondaryColor: string;
  baseFontSize: number;
}

// Font Type
export interface AppConfigFont {
  fontFamilyLight?: string;
  fontFamilyHeavy?: string;
  fontMultiplier?: number;
}

export type BrandAppThemeConfig = Partial<
  Pick<MobileAppConfig, "theme" | "font" | "appHeaderLogo">
>;

/**
 * Merged WebAppConfig and BrandAppConfig
 */
export interface WebAndBrandAppConfig {
  /// The following comes from api-rewards/config/webApp/getAppConfig - aka WebAppConfig
  authConfig: IAppAuthConfig;
  domainConfig: IAppDomainConfig;

  /// The following comes from api-rewards/appConfig/getAppConfig - aka BrandAppConfig
  brandAppConfig?: BrandAppConfig;

  // TODO: The following properties should be refactored to use the above brandAppConfig
  membershipPageConfig?: {
    surveyPicture?: string;
    logoPicture?: string;
  };
  socialLinks?: SocialLinks;
  customTheme?: any;
  brandAppThemeConfig?: BrandAppThemeConfig;
}

export default interface ReduxStore extends IReduxStoreBase {
  appConfig?: WebAndBrandAppConfig | null;
  brandAppSlice?: {
    topProduct?: ProductRewardSearchResult | null;
  };
  visitorTracking?: {
    uniqueVisitorId: string | null;
    referrer: string | null;
    fingerprintResult?: ExtendedGetResult;
  } | null;
  checkout: {
    batch?: UserRetailerCheckoutBatch;
    allCarts: UserRetailerShoppingCart[];
    products?: { [retailerSku: string]: ProductRewardSearchResult };
    optimisticAllCarts?: Partial<UserRetailerShoppingCart>[];
    open: boolean;
    loadingCarts: boolean;
    errorState: string | undefined;
  };
  mainEntity: {
    entity: any;
    loading?: boolean;
    error?: any;
    id?: any;
    type?: BrandclubEntityType;
  } | null;
  brandEntity: BrandEntity | null;
  retailers: Retailer[];
  rewards: {
    loading: boolean;
    rewards: Record<string, number> & {
      availableBalance?: number;
      cancelledBalance?: number;
      currentBalance?: number;
      expiringRewardAmount?: number;
      last1DayPayout?: number;
      last365DaysPayout?: number;
      lifetimeAdjustments?: number;
      lifetimeEarnings?: number;
      lifetimePayouts?: number;
      lockedBalance?: number;
      pendingBalance?: number;
      unlockedBalance?: number;
    };
  };
  userProfile:
    | (UserProfile & {
        addresses: Address[];
        errorFetchingUser?: boolean;
        oauthEmail?: string;
        emailVerified: boolean;
        defaultShippingAddressId?: string;
        oauthEmailVerified?: boolean;
        userPhysicalIdVerificationSessionId?: string;
        userPhysicalIdVerificationStatus?: string;
      })
    | null;
  topBrands: {
    topBrands?: TopBrand[];
    loading: boolean;
  };
  categories?: {
    categories?: Category[];
    loading: boolean;
  };
  departments?: {
    departments?: DepartmentForRedux[];
    loading: boolean;
  };
  referralReward: {
    amountForReferringUser: number | null;
    amountForInstallingUser: number | null;
  };
  customerSpendByBrand: {
    brandIds: number[];
    brandclubIds: string[];
    spendByBrandId: {
      [brandId: number]: {
        brandId: number;
        brandName: string;
        brandclubId?: string;
        spend: number;
      };
    };
    loading: boolean;
  };
  carouselRewards: {
    [key: string]: ProductRewardSearchResult;
  };
}

export type Action<T = any> = AnyAction & { payload: T };
