export type CardBrand =
  | "visa"
  | "mastercard"
  | "american-express"
  | "express" // temp fix for amex
  | "diners-club"
  | "discover"
  | "jcb"
  | "unionpay"
  | "maestro"
  | "elo"
  | "hiper"
  | "hipercard";

const BaseUrl =
  "https://media.brandclub.com/brandclub/icons/payments/card-issuer/";

const card_issuer_icon_mapping: Record<string, string> = {
  "american-express": `${BaseUrl}americanExpress.png`,
  amex: `${BaseUrl}americanExpress.png`,
  discover: `${BaseUrl}discover.png`,
  visa: `${BaseUrl}visa.png`,
  mastercard: `${BaseUrl}masterCard.png`,
};

export const getLogoUrlForCardIssuer = (cardIssuer: CardBrand) => {
  return card_issuer_icon_mapping[cardIssuer] || `${BaseUrl}generic.png`;
};

interface CardBrandIconProps
  extends Omit<
    React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >,
    "src"
  > {
  cardBrand?: string;
}
const CardBrandIcon = ({ cardBrand, ...props }: CardBrandIconProps) => {
  return (
    <img
      alt="cardBrand"
      src={getLogoUrlForCardIssuer(cardBrand as any)}
      className="payment_logo"
      {...props}
    ></img>
  );
};

export default CardBrandIcon;
