import Box, { BoxProps } from "@mui/material/Box";
import { MOBILE_SCREEN_SIZE } from "../../../../AppNavigation/constants";

interface ProductImageWithQuantityProps extends BoxProps {
  url: string | undefined;
  quantity: number;
  quantityProps?: BoxProps;
  imageProps?: BoxProps;
}
const ProductImageWithQuantity = ({
  quantity,
  quantityProps,
  imageProps,
  url,
  ...rest
}: ProductImageWithQuantityProps) => {
  // bounds the quantity between 0 and 99
  quantity = quantity && Math.min(quantity, 99);
  quantity = quantity && Math.max(quantity, 0);
  return (
    <Box
      display="inline-block"
      lineHeight={0}
      padding={0}
      margin={0}
      minWidth={0}
      boxSizing="border-box"
      position="relative"
      width="100%"
      maxWidth="max-content"
      height="100%"
      {...rest}
    >
      <Box
        alt="Product"
        sx={{
          width: "100px",
          aspectRatio: "1/1",
          [`@media screen and (max-width:${MOBILE_SCREEN_SIZE}px)`]: {
            width: "76px",
          },
          objectFit: "contain",
          ...imageProps?.sx,
        }}
        {...imageProps}
        src={url}
        component="img"
      />
      {quantity >= 1 && (
        <Box
          {...quantityProps}
          sx={(theme) => {
            const sxProps =
              typeof quantityProps?.sx === "function"
                ? quantityProps.sx(theme)
                : (quantityProps?.sx as Record<string, unknown>);
            return {
              position: "absolute",
              right: "0",
              bottom: "0",
              fontSize: 12,
              fontWeight: 600,
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#f6f8fa",
              border: "solid 1px #dcdddc",
              borderRadius: "50%",
              height: "25px",
              transform: "translate(0%, 50%)",
              aspectRatio: "1/1",
              color: theme.palette.primary.main,
              [`@media screen and (max-width:${MOBILE_SCREEN_SIZE}px)`]: {
                fontSize: 10,
                height: "20px",
              },
              ...sxProps,
            };
          }}
        >
          {quantity}
        </Box>
      )}
    </Box>
  );
};

export default ProductImageWithQuantity;
