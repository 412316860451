import { useContext } from "react";
import { getEntityImage, getRetailerFromId } from "@brandclub/common-ui";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, styled } from "@mui/material";
import { useEffect } from "react";
import LandingPageWrapper from "../pages/AppPages/Pages/components/LandingPageWrapper";
import { OutlineButton, SolidButton } from "../StoreComponents/StoreButton";
import { CartProduct } from "./components/CartProduct";
import { calculateTotal } from "./utils";
import { UserLoginContext } from "../pages/Auth/UserLoginProvider";
import pluralize from "pluralize";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { Retailer } from "@/redux/types";
import { getAllCarts } from "@/redux/selectors";
import { sumItemQuantities } from "../../utils/misc";
import { getActiveCarts } from "@/redux/reducers/checkout/thunk";
import _get from "lodash/get";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../AppNavigation/constants";
import Loading from "../Loading";
import { EmptyBagMessageCard } from "./EmptyBagMessageCard";
import { UserRetailerShoppingCart } from "./types";
import { GlobalCheckoutContext } from "@/components/Checkout/CheckoutMachine";

const StyledLandingPageWrapper = styled(LandingPageWrapper)({
  flex: 1,
  ".my-bag__wrapper": {
    flex: 1,
    paddingBottom: 105,
  },
});

const MyBagContainer = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  maxWidth: 735,
  padding: "0",
  alignItems: "center",
  gap: 40,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    gap: 30,
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    gap: 30,
  },
  ".head": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 15,
    maxWidth: 552,
    marginTop: 95,
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      alignSelf: "flex-start",
      marginTop: 48,
    },

    ".title": {
      fontSize: 24,
      fontWeight: 700,
      textAlign: "center",
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: 22,
        textAlign: "left",
      },
    },
  },
  ".product_section": {
    width: "100%",
    display: "flex",

    flexDirection: "column",
    gap: 40,
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      gap: 30,
    },
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      gap: 30,
    },
    ".retailer_cart": {},
    ".retailer": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: 18,

      ".retailer_logo": {
        width: 30,
        height: 30,
        objectFit: "contain",
        [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          width: 25,
          height: 25,
        },
      },
      ".retailer_name": {
        flex: 1,
        fontSize: 18,
        fontWeight: 600,
        [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 18,
          fontWeight: 600,
        },
      },
      ".actions": {},
    },
    ".group_info": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 20,
      marginBottom: 30,
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        marginTop: 10,
        marginBottom: 24,
      },
      ".group_count": {
        fontSize: 16,
        fontWeight: 500,
        [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 14,
        },
      },
      ".group_subtotal": {
        fontSize: 16,
        fontWeight: 500,
        [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 14,
        },
        ".price": {
          fontWeight: 700,
        },
      },
    },

    ".product": {
      width: "100%",
    },
  },
});

const StyledEmptyBagMessageCard = styled(EmptyBagMessageCard)({
  margin: "auto",
  marginTop: 200,
  marginBottom: 200,
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    margin: "32px auto 0 auto",
  },
});

const RetailerCheckoutButton = styled(OutlineButton)(({ theme }) => ({
  transition: "all 0.5s",
  fontSize: 14,
  ":hover": {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    fontSize: 10,
    height: 27,
  },
}));
const OverAllCheckoutButton = styled(SolidButton)({
  fontSize: 16,
  height: 50,

  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    fontSize: 14,
    height: 44,
    marginTop: 23,
  },
});

interface ProductSectionProps {
  items: UserRetailerShoppingCart[];
  onRetailerCheckout: (item: UserRetailerShoppingCart) => void;
}
const ProductSection = (props: ProductSectionProps) => {
  return (
    <Box className="product_section">
      {/* Render all carts (those with > 0 items - filtered by redux already) */}
      {props.items.map((item, _index, allCarts) => (
        <Box
          key={`cartGroup:${item.shoppingCartId}`}
          display="flex"
          flexDirection="column"
          className="retailer_cart"
          gap={0}
        >
          <Box className="retailer">
            <img
              src={getEntityImage(item.retailerId, "retailer")}
              alt={`Logo for retailer: ${item.retailerId}`}
              className="retailer_logo"
            />
            <Typography className="retailer_name">
              {getRetailerFromId(item.retailerId)}
            </Typography>
            {allCarts.length >= 2 && (
              <RetailerCheckoutButton
                onClick={() => {
                  props.onRetailerCheckout(item);
                }}
              >
                Checkout {getRetailerFromId(item.retailerId)}
              </RetailerCheckoutButton>
            )}
          </Box>
          <Box className="group_info">
            <Box className="group_count">
              {pluralize("item", sumItemQuantities(item?.items), true)}
            </Box>
            <Box className="group_subtotal">
              Subtotal:{" "}
              <span className="price">{calculateTotal(item?.items ?? [])}</span>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "40px",
              [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
                gap: "30px",
              },
            }}
          >
            {(item?.items ?? []).map((product) => (
              <CartProduct
                large
                product={product}
                key={`cart:${item?.shoppingCartId}:item:${product.stacklineSku}`}
              />
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const MyBag = () => {
  const { search } = useLocation();

  const hasOptimisticItems = useAppSelector(
    (state) => !!state.checkout.optimisticAllCarts
  );
  const userProfile = useAppSelector((state) => state.userProfile);
  const retailers = useAppSelector((state) => state.retailers);
  const { send, setInitialOpenUrl } = useContext(UserLoginContext);
  const { loadingCarts } = useAppSelector((state) => state.checkout);
  const { send: checkoutSend } = useContext(GlobalCheckoutContext);
  const allCarts = useAppSelector(getAllCarts);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    !hasOptimisticItems && dispatch(getActiveCarts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledLandingPageWrapper wrapperClassName="my-bag__wrapper">
      {loadingCarts ? (
        <Loading dot></Loading>
      ) : (
        <MyBagContainer
          style={{ maxWidth: allCarts.length > 0 ? undefined : "100%" }}
        >
          {allCarts.length > 0 ? (
            <>
              <div className="head">
                <div className="title">Your Bag</div>
              </div>

              <ProductSection
                items={allCarts as any}
                onRetailerCheckout={(item) => {
                  checkoutSend({
                    type: "INITIATE_CHECKOUT",
                    data: {
                      retailerId: item.retailerId,
                      retailerIds: allCarts.map(
                        (item) => item.retailerId
                      ).filter((retailer) => {
                        return retailer === item.retailerId;
                      }) as number[],
                      retailerConnectionInfoList: retailers.map((retailer: Retailer) => {
                        const status = _get(userProfile, [
                          "extendedAttributes",
                          `connectedRetailer_${retailer.parentRetailerId}_state`,
                        ]);

                        return {
                          retailerId: retailer.parentRetailerId,
                          credential: {
                            connectionStatus: status,
                          },
                        };
                      }),
                    },
                  });
                  navigate("/entercheckout");
                }}
              />

              <OverAllCheckoutButton
                sx={{
                  width: "100%",
                  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
                    width: "100%",
                    maxWidth: "100%",
                  },
                  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
                    width: "100%",
                    maxWidth: "100%",
                  },
                }}
                onClick={() => {
                  if (search.includes("action=checkout")) {
                    setInitialOpenUrl({ pathname: "/entercheckout", search });
                    send({
                      type: "SIGN_IN",
                    });
                  } else {
                    navigate("/entercheckout");
                  }
                }}
                style={{ width: "100%" }}
              >
                {`Checkout${allCarts.length >= 2 ? " all retailers" : ""}`}
              </OverAllCheckoutButton>
            </>
          ) : (
            <StyledEmptyBagMessageCard />
          )}
        </MyBagContainer>
      )}
    </StyledLandingPageWrapper>
  );
};

export default MyBag;
