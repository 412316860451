import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../../initialState";

const rewardsSlice = createSlice({
  name: "rewards",
  initialState: initialState.rewards,
  reducers: {
    setRewards: (_state, action) => {
      return {
        rewards: action.payload,
        loading: false,
      };
    },
    setRewardsLoading: (_state, action) => {
      return {
        ..._state,
        loading: action.payload,
      };
    },
    clearRewards: () => {
      return {
        rewards: {},
        loading: false,
      };
    },
  },
});

export const { setRewards, setRewardsLoading, clearRewards } =
  rewardsSlice.actions;
export default rewardsSlice.reducer;
