import {
  AnalyticsTrackingEvent,
  CampaignType,
  changeAmazonImageUrlResolution,
  getAppConsistentParams,
  getProductUrl,
} from "@brandclub/common-ui";
import LongRewardCard from "../../../../../StoreComponents/StoreCard.tsx/LongRewardCard";
import {
  ProductRewardSearchResult,
  UserRewardStatus,
} from "../../../../../../types/search";
import _get from "lodash/get";

const LongDealCard = ({
  product,
  border = false,
}: {
  product: ProductRewardSearchResult;
  border: boolean;
}) => {
  const urlSearchParams = getAppConsistentParams(window.location.search);

  const {
    matchedProducts,
    retailerId,
    retailPrice,
    stacklineSku,
    title,
    dailyDealRewardInfo: { rewardAmount = 0, userRewardStatus } = {},
  } = product;

  const DESIRED_AMAZON_IMAGE_SIZE = 300; // pixels
  const img = changeAmazonImageUrlResolution(
    _get(product, ["imageUrls", 0])!,
    DESIRED_AMAZON_IMAGE_SIZE
  );
  const targetUrl = getProductUrl({ title, stacklineSku, urlSearchParams });

  const matchedProductsRetailerIds = (matchedProducts ?? []).map(
    (matchedProduct) => matchedProduct.retailerId
  );
  const retailerIds = [retailerId, ...matchedProductsRetailerIds];

  return (
    <LongRewardCard
      type="product"
      trackedEventName={AnalyticsTrackingEvent.DAILY_DEALS_PRODUCT_CARD_CLICK}
      productTitle={title}
      productPrice={retailPrice}
      productRetailerIds={retailerIds}
      image={img || ""}
      rewardType={CampaignType.DailyDealsRewardCampaign}
      rewardAmount={rewardAmount}
      linkTo={targetUrl}
      border={border}
      productSoldOut={userRewardStatus === UserRewardStatus.Unavailable}
      productRewardEarned={userRewardStatus === UserRewardStatus.Claimed}
      product={product}
    ></LongRewardCard>
  );
};

export default LongDealCard;
