import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
  styled,
} from "@mui/material";
import React, { ReactNode, useDeferredValue, useEffect, useState } from "react";
import { autocompleteAddress, getFullAddressFromPlaceId } from "../../api";
import Loading from "../Loading";

export type predictionType = {
  placeId: string;
  fullAddress: string;
  title: string;
  desc: string;
  address1: string;
  city: string;
  state: string;
};

export type autocompleteAddressType = {
  line1: string;
  city: string;
  state: string;
  zipCode: string;
};

const ListItem = styled("li")(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
  ".title": {
    fontWeight: 600,
    fontSize: 14,
    fontFamily: "Poppins",
    color: theme.palette.primary.main,
  },
  ".subtitle": {
    fontWeight: 500,
    fontSize: 12,
    fontFamily: "Poppins",
    color: theme.palette.primary.main,
  },
}));

const StoreAddressAutocompleteInput = ({
  searchTerm,
  renderInput,
  selectAddressCallback,
  ...props
}: any & {
  searchTerm: string;
  renderInput: (params: AutocompleteRenderInputParams) => ReactNode;
  selectAddressCallback?: (address: autocompleteAddressType) => void;
}) => {
  const searchInput = useDeferredValue(searchTerm);
  const [loading, setLoading] = useState(false);

  const [suggestions, setSuggestions] = useState<predictionType[]>([]);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchAddressSuggestions = async (input: string) => {
      try {
        setLoading(true);
        const res = await autocompleteAddress(
          {
            searchText: input,
            countryCodes: ["us"],
          },
          abortController
        );
        const predictions = res?.data?.autocompleteResult?.predictions ?? [];

        const newAddressPredictions = predictions
          .slice(0, 5)
          .map(
            ({
              place_id,
              description,
              terms,
              structured_formatting: { main_text, secondary_text },
            }: {
              place_id: string;
              description: string;
              terms: { value: string }[];
              structured_formatting: {
                main_text: string;
                secondary_text: string;
              };
            }) => {
              return {
                placeId: place_id,
                fullAddress: description,
                title: main_text,
                desc: secondary_text,
                address1: main_text,
                city: terms[terms.length - 3]?.value,
                state: terms[terms.length - 2]?.value,
              };
            }
          )
          .filter((x: any) => x !== null);
        setSuggestions(newAddressPredictions);
        setLoading(false);
      } catch (error) {
      } finally {
      }
    };
    if (searchInput.length >= 1) {
      fetchAddressSuggestions(searchInput as string);
    } else {
      setSuggestions([]);
      setLoading(false);
    }

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  const selectAddress = async (prediction: predictionType) => {
    const detailAddress = await getFullAddressFromPlaceId({
      placeId: prediction.placeId,
    });
    if (detailAddress.data.status === "success") {
      const address_components =
        detailAddress?.data?.autocompleteResult?.results?.[0]
          ?.address_components ?? [];

      const zipCode = address_components?.find((i: any) =>
        i?.types?.includes("postal_code")
      )?.long_name;
      const res = {
        line1: prediction.address1,
        city: prediction.city,
        state: prediction.state,
        zipCode: zipCode,
      };
      if (selectAddressCallback) {
        selectAddressCallback(res);
      }
    }
    setSuggestions([]);
  };

  return (
    <Autocomplete
      sx={{
        width: "100%",
        ".MuiOutlinedInput-root": {
          paddingTop: "0px",
          paddingBottom: "0px",
        },
      }}
      {...props}
      freeSolo
      disableClearable
      options={suggestions}
      renderInput={renderInput}
      onChange={(
        event: React.SyntheticEvent<Element, Event>,
        value: predictionType | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<predictionType>
      ) => {
        if (reason === "selectOption" && value) {
          selectAddress(value);
        }
      }}
      loading={loading}
      loadingText={<Loading dot />}
      filterOptions={(options) => options}
      getOptionKey={(option: predictionType) => option.placeId}
      getOptionLabel={(option: predictionType) => option.address1}
      renderOption={(props, option: predictionType) => {
        return (
          <ListItem {...props}>
            <div>
              <div className="title">{option.address1}</div>
              <div className="subtitle">{option.desc}</div>
            </div>
          </ListItem>
        );
      }}
    />
  );
};

export default StoreAddressAutocompleteInput;
