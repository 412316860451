import { styled } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import { CardGrid } from "../components/CardGrid";
import { LandingHeader } from "../components/Header";
import { useFetchDepartmentProduct } from "./data";
import ProductCard from "../../../../StoreComponents/StoreCard.tsx/ProductCard";

const SectionContainer = styled("div")({
  width: "100%",
  margin: "60px 0 20px 0",
});

const DepartmentLandingInstantInner = ({
  departmentId,
}: {
  departmentId: number;
}) => {
  const departmentIds = useMemo(() => [departmentId], [departmentId]);
  const { data, loading, fetchMore, loadingMore, hasMore } =
    useFetchDepartmentProduct({
      departmentIds,
      type: "instant",
      pageSize: 20,
    });

  const [endReached, setEndReached] = useState(false);
  const ref = useRef<any>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setEndReached(entry.isIntersecting);
      },
      { rootMargin: "-10px" }
    );
    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [endReached]);

  useEffect(() => {
    if (endReached && !loading && !loadingMore && hasMore) {
      fetchMore();
    }
  }, [endReached, loading, loadingMore, hasMore, fetchMore]);

  return (
    <div>
      {loading ? (
        <StoreLoadingSkeleton header />
      ) : data.length ? (
        <SectionContainer>
          <LandingHeader
            title={"Instant rewards"}
            subtitle="Unlock rewards instantly when purchased"
          />
          <CardGrid style={{ marginTop: 20 }}>
            {data.map((p) => (
              <ProductCard
                key={`trending:${p.stacklineSku}`}
                LinkComponent={Link}
                product={p}
              />
            ))}
          </CardGrid>
        </SectionContainer>
      ) : null}

      <div className="end_detect" ref={ref}></div>
    </div>
  );
};

export default DepartmentLandingInstantInner;
