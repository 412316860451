import React, { createContext, useContext, useState } from "react";
import {
  SurveyQuestion,
  SurveyResponse,
  SurveyRewardCampaignForCarousel,
} from "../../../../../../types/misc";
import { submitSurveyForReward } from "../../../../../../api/rest/authenticated/Survey";
import { useAppDispatch } from "../../../../../../redux/hooks";
import { loadRewards } from "../../../../../../redux/actions/rewards";

interface ISurveyContext {
  numberOfQuestion: number;
  submitting: boolean;
  surveyQuestions: SurveyQuestion[];
  currentStep: number;
  receivedReward: number;
  question?: SurveyQuestion;
  setCurrentStep: (step: number) => void;
  isInFirstStep: boolean;
  isInLastStep: boolean;
  moveToNextStep: () => void;
  survey: SurveyRewardCampaignForCarousel;
  moveToPreviousStep: () => void;
  answers: Partial<SurveyResponse>[];
  setAnswers: React.Dispatch<React.SetStateAction<Partial<SurveyResponse>[]>>;
  timer: number[];
  setTimer: React.Dispatch<React.SetStateAction<number[]>>;
}

// Create a context with a default value.
const SurveyContext = createContext<ISurveyContext>({} as ISurveyContext);

// Create a Provider component.
export const SurveyProvider = ({
  children,
  survey,
}: {
  children: React.ReactNode;
  survey: SurveyRewardCampaignForCarousel;
}) => {
  const surveyQuestions = survey?.survey?.surveyQuestions ?? [];
  const dispatch = useAppDispatch();
  const [answers, setAnswers] = useState<Partial<SurveyResponse>[]>(
    surveyQuestions.map(() => ({}))
  );
  const [submitting, setSubmitting] = useState(false);
  const [timer, setTimer] = useState<number[]>(surveyQuestions.map(() => 0));
  const [receivedReward, setReceivedReward] = useState<number>(0);
  // Copied
  const [currentStep, setCurrentStep] = useState(-1);
  const isInFirstStep = currentStep === 0;
  const isInLastStep = currentStep + 1 >= surveyQuestions.length;

  const numberOfQuestion = surveyQuestions.length;

  const moveToNextStep = async () => {
    if (currentStep === numberOfQuestion - 1) {
      await handleSubmit();
    }

    setCurrentStep((prev) => prev + 1);
  };
  const moveToPreviousStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const submit = async () => {
    try {
      const surveyId = survey?.extendedAttributes.surveyId;
      const res = await submitSurveyForReward({
        campaignId: survey?.campaignId,
        surveyId,
        responses: answers as SurveyResponse[],
      });
      if (res.data) {
        if (res.data.reward?.rewardAmount) {
          setReceivedReward(res.data.reward.rewardAmount);
          dispatch(loadRewards());
        }
      }
    } catch (e) {
      // Go to error state here
      console.error(e);
    } finally {
      // setSubmitting(false);
    }
  };
  const handleSubmit = async () => {
    setSubmitting(true);
    await submit();
  };
  const question =
    currentStep === -1 ? undefined : surveyQuestions[currentStep];

  // The value prop is where we provide the data that consuming components can subscribe to.
  return (
    <SurveyContext.Provider
      value={{
        numberOfQuestion,
        receivedReward,
        surveyQuestions,
        currentStep,
        question,
        submitting,
        setCurrentStep,
        isInFirstStep,
        isInLastStep,
        moveToNextStep,
        moveToPreviousStep,
        survey,
        answers,
        setAnswers,
        timer,
        setTimer,
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
};

// Custom hook to use the context
export const useSurvey = () => useContext(SurveyContext);
