import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "../../initialState";
import { getTopProduct } from "./thunks";

const brandAppSlice = createSlice({
  name: "appConfig",
  initialState: initialState.brandAppSlice,
  reducers: {
    setBrandAppSlice(_state, action) {
      return {
        ...action.payload,
      };
    },
    clearBrandAppSlice(_state) {
      return initialState.brandAppSlice;
    },
  },
  extraReducers: (builders) => {
    builders.addCase(getTopProduct.fulfilled, (state, action) => {
      if (!state) {
        return;
      }

      state.topProduct = action.payload;
    });
  },
});

export const { setBrandAppSlice, clearBrandAppSlice } = brandAppSlice.actions;

export default brandAppSlice.reducer;
