import { useMemo } from "react";
import { RetailerWithSyncStatus } from "../../../../../redux/types";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";

import { useResponsiveData } from "../../../../../utils/hooks/useResponsiveData";
import { useFetchAccount } from "../../Pages/Accounts/data";

import { CardGridForFour } from "../../Pages/components/CardGrid";
import RetailerCard from "../../Pages/Accounts/components/RetailerCard";
import { MOBILE_SCREEN_SIZE } from "../../../../AppNavigation/constants";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DashboardPreviewHeader } from "../DashboardPreviewHeader";
import useStoreType from "../../../../../utils/hooks/useStoreType";
import { StoreBrandingType } from "../../../../../types/misc";

const MAX_RETAILERS = 4;

const NewAccountSummary = () => {
  const storeType = useStoreType();
  const { loading, newRetailers } = useFetchAccount({});
  const isMobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);

  const homeRetailer = useMemo(() => {
    return newRetailers.slice(0, MAX_RETAILERS);
  }, [newRetailers]);

  const responsiveHomeRetailer = useResponsiveData(homeRetailer);

  if (!loading && homeRetailer.length === 0) {
    return null;
  }

  const linkPrefix =
    storeType === StoreBrandingType.CustomDTCStore ? "" : "/dashboard";

  return (
    <div>
      {loading ? (
        <StoreLoadingSkeleton header row={2} />
      ) : (
        <>
          <DashboardPreviewHeader
            title={isMobileView ? "Connect accounts" : "Connect new accounts"}
            linkTo={`${linkPrefix}/accounts`}
            subtitle="Earn more rewards from your purchases"
            indicator="grey"
          />
          <CardGridForFour style={{ marginTop: 20 }}>
            {responsiveHomeRetailer.map((r) => {
              return (
                <RetailerCard
                  key={r.retailerId}
                  retailer={r as RetailerWithSyncStatus}
                />
              );
            })}
          </CardGridForFour>
        </>
      )}
    </div>
  );
};

export default NewAccountSummary;
