import {
  createMachine,
  DoneActorEvent,
  fromPromise,
  assign,
  ErrorActorEvent,
} from "xstate";
import {
  AddPaymentMethodEvent,
  AddShippingAddressEvent,
  CheckoutUserInputEvent,
  CheckoutUserInputMachineContext,
  CheckoutUserInputMachineEvent,
  RemovePartaillyOutOfStockItemEvent,
  UpdatePaymentConfirmationAndCompleteCheckoutEvent,
  UpdatePaymentMethodEvent,
  UpdateShippingAddressEvent,
  UpdateShippingOptionEvent,
} from "./types/CheckoutUserInputMachine";
import { getActiveCarts } from "@/redux/reducers/checkout/thunk";
import {
  addPaymentMethodToUserRetailerCheckoutOrder,
  addShippingToUserRetailerCheckoutOrder,
  deleteItemsInCheckoutPage,
  removeItemFromShoppingCart,
  confirmCheckout,
  updatePaymentMethodToUserRetailerCheckoutOrder,
  updateShippingOption,
  updateShippingToUserRetailerCheckoutOrder,
} from "@/api/rest/checkoutApi";
import { UserInputUpdateType } from "./types/GlobalCheckoutMachine";

export const CheckoutUserInputMachine = (() => {
  return createMachine(
    {
      id: "checkoutUserInputMachine",
      initial: "extractEvent",
      types: {} as unknown as {
        context: CheckoutUserInputMachineContext;
        events: CheckoutUserInputMachineEvent;
      },
      context: ({ input: { context } }: { input: { context: any } }) =>
        context as CheckoutUserInputMachineContext,
      states: {
        extractEvent: {
          invoke: {
            input: ({ context, event }) => ({ context, event }),
            src: "extractEvent",
            onDone: [
              {
                target: "handleUpdateShippingAddress",
                guard: "isUpdateShippingAddressEvent",
              },
              {
                target: "handleAddShippingAddress",
                guard: "isAddShippingAddressEvent",
              },
              {
                target: "handleUpdateShippingOption",
                guard: "isUpdateShippingOptionEvent",
              },
              {
                target: "handleUpdatePaymentMethod",
                guard: "isUpdatePaymentMethodEvent",
              },
              {
                target: "handleAddPaymentMethod",
                guard: "isAddPaymentMethodEvent",
              },
              {
                target: "removePartiallyOutOfStockItem",
                guard: "isRemovePartiallyOutOfStockItemEvent",
              },
              {
                target: "handleUpdatePaymentConfirmationAndCompleteCheckout",
                guard: "isUpdatePaymentConfirmationAndCompleteCheckout",
              },
            ],
          },
        },
        handleUpdateShippingAddress: {
          invoke: {
            input: ({ context, event }) => ({ context, event }),
            src: "handleUpdateShippingAddress",
            onDone: "handleSuccess",
            onError: "handleError",
          },
        },
        handleAddShippingAddress: {
          invoke: {
            input: ({ context, event }) => ({ context, event }),
            src: "handleAddShippingAddress",
            onDone: "handleSuccess",
            onError: "handleError",
          },
        },
        handleUpdateShippingOption: {
          invoke: {
            input: ({ context, event }) => ({ context, event }),
            src: "handleUpdateShippingOption",
            onDone: "handleSuccess",
            onError: "handleError",
          },
        },
        handleUpdatePaymentMethod: {
          invoke: {
            input: ({ context, event }) => ({ context, event }),
            src: "handleUpdatePaymentMethod",
            onDone: "handleSuccess",
            onError: "handleError",
          },
        },
        handleAddPaymentMethod: {
          invoke: {
            input: ({ context, event }) => ({ context, event }),
            src: "handleAddPaymentMethod",
            onDone: "handleSuccess",
            onError: "handleError",
          },
        },
        handleUpdatePaymentConfirmationAndCompleteCheckout: {
          invoke: {
            input: ({ context, event }) => ({ context, event }),
            src: "handleUpdatePaymentConfirmationAndCompleteCheckout",
            onDone: "handleSuccess",
            onError: "handleError",
          },
        },
        removePartiallyOutOfStockItem: {
          invoke: {
            input: ({ context, event }) => ({ context, event }),
            src: "removePartiallyOutOfStockItem",
            onDone: "handleSuccess",
            onError: "handleError",
          },
        },
        handleSuccess: {
          type: "final",
        },
        handleError: {
          entry: [
            assign(({ event }) => ({
              error: (event as ErrorActorEvent<Error>).error,
            })),
          ],
          type: "final",
        },
      },
      output: ({ context }) => ({
        userInputUpdateType: context.userInput
          ?.inputEvent as UserInputUpdateType,
        error: context.error,
      }),
    },
    {
      actors: {
        extractEvent: fromPromise(
          async ({
            input: { context },
          }: {
            input: {
              context: CheckoutUserInputMachineContext;
            };
          }) => {
            return context?.userInput;
          }
        ),
        handleUpdateShippingAddress: fromPromise(
          async ({
            input: { context, event },
          }: {
            input: {
              context: CheckoutUserInputMachineContext;
              event: DoneActorEvent<UpdateShippingAddressEvent>;
            };
          }) => {
            const { activeCheckoutOrder } = context;
            if (activeCheckoutOrder) {
              await updateShippingToUserRetailerCheckoutOrder({
                retailerId: activeCheckoutOrder.retailerId,
                orderId: activeCheckoutOrder.orderId,
                shippingAddress: event.output.data.address,
              });
            }
          }
        ),
        handleAddShippingAddress: fromPromise(
          async ({
            input: { context, event },
          }: {
            input: {
              context: CheckoutUserInputMachineContext;
              event: DoneActorEvent<AddShippingAddressEvent>;
            };
          }) => {
            const { activeCheckoutOrder } = context;
            if (activeCheckoutOrder) {
              await addShippingToUserRetailerCheckoutOrder({
                retailerId: activeCheckoutOrder.retailerId,
                orderId: activeCheckoutOrder.orderId,
                shippingAddress: event.output.data.address,
              });
            }
          }
        ),
        handleUpdateShippingOption: fromPromise(
          async ({
            input: { context, event },
          }: {
            input: {
              context: CheckoutUserInputMachineContext;
              event: DoneActorEvent<UpdateShippingOptionEvent>;
            };
          }) => {
            const { activeCheckoutOrder } = context;
            if (activeCheckoutOrder) {
              await updateShippingOption({
                retailerId: activeCheckoutOrder.retailerId,
                orderId: activeCheckoutOrder.orderId,
                deliveryOption: event.output.data.deliveryOption,
                deliveryTimeWindow: event.output.data.deliveryTimeWindow,
              });
            }
          }
        ),
        handleUpdatePaymentMethod: fromPromise(
          async ({
            input: { context, event },
          }: {
            input: {
              context: CheckoutUserInputMachineContext;
              event: DoneActorEvent<UpdatePaymentMethodEvent>;
            };
          }) => {
            const { activeCheckoutOrder } = context;
            if (activeCheckoutOrder) {
              await updatePaymentMethodToUserRetailerCheckoutOrder({
                retailerId: activeCheckoutOrder.retailerId,
                orderId: activeCheckoutOrder.orderId,
                cardInfo: event.output.data.cardInfo,
                billingAddress: event.output.data.billingAddress,
              });
            }
          }
        ),
        handleAddPaymentMethod: fromPromise(
          async ({
            input: { context, event },
          }: {
            input: {
              context: CheckoutUserInputMachineContext;
              event: DoneActorEvent<AddPaymentMethodEvent>;
            };
          }) => {
            const { activeCheckoutOrder } = context;
            if (activeCheckoutOrder) {
              const res = await addPaymentMethodToUserRetailerCheckoutOrder({
                retailerId: activeCheckoutOrder.retailerId,
                orderId: activeCheckoutOrder.orderId,
                cardInfo: event.output.data.cardInfo,
                billingAddress: event.output.data.billingAddress,
              });

              if (res.status === "error") {
                throw new Error(res.errorMessage);
              }
            }
          }
        ),
        handleUpdatePaymentConfirmationAndCompleteCheckout: fromPromise(
          async ({
            input: { context, event },
          }: {
            input: {
              context: CheckoutUserInputMachineContext;
              event: DoneActorEvent<UpdatePaymentConfirmationAndCompleteCheckoutEvent>;
            };
          }) => {
            const { activeCheckoutOrder, retailerCheckoutOrderStatus } =
              context;
            if (activeCheckoutOrder) {
              await confirmCheckout({
                retailerId: activeCheckoutOrder.retailerId,
                orderId: activeCheckoutOrder.orderId,
                paymentConfirmation:
                  retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
                    ?.paymentMethod?.paymentConfirmationTypes?.[0]?.type &&
                  event.output.data.paymentConfirmation
                    ? {
                        type: retailerCheckoutOrderStatus
                          ?.userRetailerCheckoutOrder?.paymentMethod
                          ?.paymentConfirmationTypes?.[0]?.type,
                        value: event.output.data.paymentConfirmation,
                      }
                    : undefined,
              });
            }
          }
        ),
        removePartiallyOutOfStockItem: fromPromise(
          async ({
            input: { context, event },
          }: {
            input: {
              context: CheckoutUserInputMachineContext;
              event: DoneActorEvent<RemovePartaillyOutOfStockItemEvent>;
            };
          }) => {
            const { retailerCheckoutOrderStatus: order } = context;
            const { stacklineSku } = event.output.data;
            const removeItem = order?.userRetailerCheckoutOrder?.items.find(
              (item: any) => item.stacklineSku === stacklineSku
            );
            if (!removeItem) {
              return;
            }
            const remainingItemsAfterRemoval =
              order?.userRetailerCheckoutOrder?.items.filter(
                (item: any) => item.stacklineSku !== removeItem.stacklineSku
              );
            const request = {
              retailerId: order?.userRetailerCheckoutOrder?.retailerId,
              orderId: order?.userRetailerCheckoutOrder?.orderId,
              brandId: order?.userRetailerCheckoutOrder?.brandId,
              items: [
                {
                  quantity: removeItem?.quantity,
                  retailPrice: removeItem?.retailPrice,
                  retailerSku: removeItem?.retailerSku,
                  retailerId: removeItem?.retailerId,
                },
              ],
            };
            await deleteItemsInCheckoutPage(request);
            if (
              remainingItemsAfterRemoval?.length === 0 &&
              order?.userRetailerCheckoutOrder
            ) {
              try {
                await removeItemFromShoppingCart({
                  retailerId: order?.userRetailerCheckoutOrder?.retailerId,
                  items: [removeItem],
                });
                getActiveCarts();
              } catch (e) {
                console.error(
                  `failed to remove item ${removeItem.stacklineSku} from the cart`,
                  e
                );
              }
              return;
            }
          }
        ),
      },
      guards: {
        isUpdateShippingAddressEvent: ({ event }) => {
          const { inputEvent } = (
            event as DoneActorEvent<CheckoutUserInputEvent>
          ).output;
          return inputEvent === UserInputUpdateType.UPDATE_SHIPPING_ADDRESS;
        },
        isAddShippingAddressEvent: ({ event }) => {
          const { inputEvent } = (
            event as DoneActorEvent<CheckoutUserInputEvent>
          ).output;
          return inputEvent === UserInputUpdateType.ADD_SHIPPING_ADDRESS;
        },
        isUpdateShippingOptionEvent: ({ event }) => {
          const { inputEvent } = (
            event as DoneActorEvent<CheckoutUserInputEvent>
          ).output;
          return inputEvent === UserInputUpdateType.UPDATE_SHIPPING_OPTION;
        },
        isUpdatePaymentMethodEvent: ({ event }) => {
          const { inputEvent } = (
            event as DoneActorEvent<CheckoutUserInputEvent>
          ).output;
          return inputEvent === UserInputUpdateType.UPDATE_PAYMENT_METHOD;
        },
        isAddPaymentMethodEvent: ({ event }) => {
          const { inputEvent } = (
            event as DoneActorEvent<CheckoutUserInputEvent>
          ).output;
          return inputEvent === UserInputUpdateType.ADD_PAYMENT_METHOD;
        },
        isUpdatePaymentConfirmationAndCompleteCheckout: ({ event }) => {
          const { inputEvent } = (
            event as DoneActorEvent<CheckoutUserInputEvent>
          ).output;
          return (
            inputEvent ===
            UserInputUpdateType.UPDATE_PAYMENT_CONFIRMATION_AND_COMPLETE_CHECKOUT
          );
        },
        isRemovePartiallyOutOfStockItemEvent: ({ event }) => {
          const { inputEvent } = (
            event as DoneActorEvent<CheckoutUserInputEvent>
          ).output;
          return (
            inputEvent ===
            UserInputUpdateType.REMOVE_PARTIALLY_OUT_OF_STOCK_ITEM
          );
        },
      },
    }
  );
})();
