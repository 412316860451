import ReduxStore from '../types';
import { sumAllItemQuantities } from '@/utils/misc';
import lodash from 'lodash';

interface RewardsResponse {
  accountRewards: number;
  contentCount: number;
  surveyRewards: number;
  inviteRewards: number;
  joinRewards: number;
  userRewards: number;
  expiringRewards: number;
  cashOutRewards: number;
}

export const getAllCarts = (state: ReduxStore) => {
  const cart = state.checkout.optimisticAllCarts ?? state.checkout.allCarts;
  return cart.filter((c) => c.items && c.items.length > 0);
};

export const getCartCounts = (state: ReduxStore) => {
  const carts = state.checkout.optimisticAllCarts ?? state.checkout.allCarts;
  return sumAllItemQuantities(carts);
};

export const getRewards = (state: ReduxStore) : RewardsResponse => {
  const retailers = state.retailers
  const accountRewards = retailers.reduce((acc, retailer) => {
    return acc + (retailer.retailerConnectRewardAmount ?? 0);
  }, 0);

};
