import { useEffect } from "react";
import { CountBreakdownAnswerState } from "../../../../../../types/misc";

import { motion } from "framer-motion";
import _cloneDeep from "lodash/cloneDeep";
import { MINIMUM_ANSWERING_TIME } from ".";
import { updateArray } from "../../../../../../utils/misc";
import { ButtonLoadingEllipsis } from "../../../../../StoreComponents/StoreButton";
import { FlowSolidButtonGrey } from "../../components/FlowButtons";
import SurveyCountBreakdownRow from "../components/SurveyCountBreakdownRow";
import SurveyTimingNextButton from "../components/SurveyTimingButton";
import { useSurvey } from "./SurveyProvider";

const CountBreakdownQuestion = () => {
  const {
    question,
    answers,
    currentStep,
    setAnswers,
    moveToNextStep,
    moveToPreviousStep,
    submitting,
    timer,
    setTimer,
  } = useSurvey();
  const ans = answers[currentStep]?.value as
    | CountBreakdownAnswerState
    | undefined;

  const saveAnswer = (category: string, value: number) => {
    const updatedAns = { ...ans };
    updatedAns[category] = value;
    setAnswers((state) =>
      updateArray(state, currentStep, {
        type: "countBreakdown",
        value: updatedAns,
      })
    );
  };

  useEffect(() => {
    let interval: any = null;

    interval = setInterval(() => {
      const timerClone = _cloneDeep(timer);
      if (timerClone[currentStep] < MINIMUM_ANSWERING_TIME) {
        timerClone[currentStep] = timerClone[currentStep] + 1;
        setTimer(timerClone);
      }
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, timer[currentStep]]);
  const answerTime = timer[currentStep];
  const disableNext = answerTime < MINIMUM_ANSWERING_TIME;

  return (
    <>
      {" "}
      <motion.div
        key={question?.description}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="question"
      >
        <div className="title">{question?.description}</div>

        <div className="count_breaddown_settings">
          {question?.responseOptions?.map((option) => {
            return (
              <SurveyCountBreakdownRow
                key={option}
                text={option}
                value={ans?.[option] as number}
                plus={() => {
                  saveAnswer(option, (ans?.[option] ?? 0) + 1);
                }}
                minus={() => {
                  saveAnswer(option, Math.max(0, (ans?.[option] ?? 0) - 1));
                }}
              />
            );
          })}
        </div>
      </motion.div>
      <div className="actions">
        <FlowSolidButtonGrey disabled={submitting} onClick={moveToPreviousStep}>
          Back
        </FlowSolidButtonGrey>
        <SurveyTimingNextButton
          answerTime={answerTime}
          onClick={moveToNextStep}
          disabled={disableNext}
          text={
            submitting ? (
              <>
                Submitting
                <ButtonLoadingEllipsis baseFontSize={16} />
              </>
            ) : (
              "Next"
            )
          }
        />
      </div>
    </>
  );
};

export default CountBreakdownQuestion;
