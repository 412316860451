import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../../initialState";

const departmentsSlice = createSlice({
  name: "departments",
  initialState: initialState.departments,
  reducers: {
    setDepartments: (_state, action) => {
      return {
        departments: action.payload,
        loading: false,
      };
    },
    setDepartmentsLoading: (_state) => {
      return {
        ..._state,
        loading: true,
      };
    },
    clearDepartments: () => {
      return {
        departments: undefined,
        loading: false,
      };
    },
  },
});

export const { setDepartments, setDepartmentsLoading, clearDepartments } =
  departmentsSlice.actions;
export default departmentsSlice.reducer;
