import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDashboardLinkPrefix } from "../../../../../utils/hooks/useDashboardLinkPrefix";
import ConnectRetailer from ".";
import FlowContainer from "../../Pages/components/FlowContainer";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../AppNavigation/constants";
import { styled } from "@mui/material";

const ConnectContainer = styled("div")(({ theme }) => ({
  maxWidth: "580px",
  width: "100%",
  marginTop: "33px",
  marginBottom: "68px",
  ".auth_container": {
    ".reward_summary": {
      textAlign: "center",
      ...(theme.dtc && {
        margin: "unset",
        width: "100%",
        maxWidth: "unset !important",
        marginTop: "20px",
      }),
    },
  },
  ".close": {
    cursor: "pointer",
    position: "absolute",
    top: 20,
    right: 20,
    width: 20,
    height: 20,
    border: "none",
    backgroundColor: "transparent",
    outline: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ".close_img": {
      width: 14,
      height: 14,
    },
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      top: 10,
      right: 10,
      width: 20,
      height: 20,
    },
  },
}));

const ConnectFullScreen = () => {
  const prefix = useDashboardLinkPrefix();
  const location = useLocation();
  const navigate = useNavigate();
  const retailerId = location?.state?.retailerId;
  const errorMessages = location?.state?.error;

  useEffect(() => {
    if (!retailerId) {
      // navigate to the previous page
      navigate(`${prefix}/accounts`);
    }
  }, [navigate, prefix, retailerId]);

  return (
    <FlowContainer>
      <ConnectContainer>
        <ConnectRetailer
          retailerId={retailerId}
          onConnectCallback={async () => {}}
          errorMsg={errorMessages}
        />
      </ConnectContainer>
    </FlowContainer>
  );
};

export default ConnectFullScreen;
