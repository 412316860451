import React, { useState, useEffect, useContext } from "react";
import StoreInput from "../../StoreComponents/StoreInput";
import AuthSolidButton from "./components/AuthSolidButton";
import { UserLoginContext } from "./UserLoginProvider";
import { ReactComponent as LockedAccount } from "../../../assets/locked-account.svg";
import { useTheme } from "@mui/material";
import { ButtonLoadingEllipsis } from "../../StoreComponents/StoreButton";
import { setOtpAuth } from "../../../Auth";
import { AuthHelper } from "./AuthHelper";
import OTPWrapper from "./OTPWrapper";

const ROW_SPACING = 20;

const SignInVerify = () => {
  const [resendCooldown, setResendCooldown] = React.useState(0);
  const [code, setCode] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const { send, snapshot } = useContext(UserLoginContext);
  const theme = useTheme();

  useEffect(() => {
    if (resendCooldown > 0) {
      setTimeout(() => setResendCooldown(resendCooldown - 1), 1000);
    } else {
      setResendCooldown(0);
    }
  }, [setResendCooldown, resendCooldown]);

  const verifyOtp = async (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e?.preventDefault();
    setSubmitting(true);
    const verifyingUserSession = snapshot.context.cognitoSession;
    await AuthHelper.answerCustomChallenge(verifyingUserSession, code)
      .then(async (cognitoUser) => {
        const jwt = await AuthHelper.getJwt(cognitoUser, true);
        await setOtpAuth(jwt, cognitoUser);
        setCode("");
        send({ type: "SUBMIT" });
      })
      .catch((err) => {
        setCode("");
        setErrorMsg("Invalid code, please try again");
        console.error(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const resendOtp = async () => {
    setResendCooldown(30);
    try {
      setSubmitting(true);
      if (snapshot.context.otpPhoneNumber === undefined) {
        throw new Error("Phone number is not defined");
      }
      const cognitoSession = await AuthHelper.sendAuthOtpWithSms(
        snapshot.context.otpPhoneNumber,
        snapshot.context.isNewUser
      );
      send({
        type: "RESEND_VERIFY_OTP",
        cognitoSession,
      });
    } catch (e) {
      setErrorMsg(`An error occurred: Please try again after some time`);
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  const clearErrors = () => {
    setErrorMsg("");
  };

  return (
    <OTPWrapper>
      <div className="auth_container">
        <LockedAccount
          style={{
            width: 75,
            height: 75,
            fill: theme.palette.primary.main,
          }}
        />
        <div className="page_title">Verify your phone number</div>
        <div className="page_subtitle">
          Please enter your verification code here.
        </div>
        <StoreInput
          errorMsg={errorMsg || ""}
          value={code}
          autoFocus
          onChange={(e) => {
            clearErrors();
            setCode(e.target.value);
          }}
          placeholder="Verification code"
          inputStyle={{ width: "100%" }}
          style={{ marginTop: ROW_SPACING }}
        ></StoreInput>
        <AuthSolidButton
          disabled={submitting || code.length === 0}
          style={{ marginTop: ROW_SPACING }}
          onClick={verifyOtp}
        >
          {submitting ? (
            <>
              Submitting
              <ButtonLoadingEllipsis baseFontSize={16} />
            </>
          ) : (
            "Continue"
          )}
        </AuthSolidButton>
        {resendCooldown <= 0 ? (
          <div className="resend">
            Please click{" "}
            <span
              className="click"
              onClick={() => {
                setResendCooldown(30);
                resendOtp();
              }}
            >
              here
            </span>{" "}
            to resend the code.
          </div>
        ) : (
          <div className="resend">
            We sent the new code.
            <br />
            You can resend in {resendCooldown}s.
          </div>
        )}
      </div>
    </OTPWrapper>
  );
};

export default SignInVerify;
