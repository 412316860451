import { styled } from "@mui/material";
import React from "react";

const DepartmentHeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  marginBottom: 60,
  alignItems: "center",
  ".department_logo": {
    width: 53,
    height: 53,
    objectFit: "contain",
    marginRight: 30,
  },
  ".department_name": {
    fontSize: 32,
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
}));

const DepartmentHeader = ({ image, name }: { image: string; name: string }) => {
  return (
    <DepartmentHeaderContainer>
      <img className="department_logo" alt="logo" src={image}></img>
      <div className="department_name">{name}</div>
    </DepartmentHeaderContainer>
  );
};

export default DepartmentHeader;
