import { getBrandIds } from "@brandclub/common-ui";
import { useAppSelector } from "../../../../../redux/hooks";
import AffiliateEmptyRewardPreview from "../Affiliate/emptyRewardPreview";
import ContentEmptyRewardPreview from "../Content/emptyRewardPreview";
import DailyDealsEmptyRewardPreview from "../DailyDeals/emptyRewardPreview";
import InstantRewardsEmptyRewardPreview from "../InstantRewards/emptyRewardPreview";
import ReviewEmptyRewardPreview from "../Review/emptyRewardPreview";
import { useMemo } from "react";
import FiveStarRating from "../Review/components/FiveStarRating";

const RewardEmptyPageSmall = () => {
  const brandEntity = useAppSelector(({ brandEntity }) => brandEntity);
  const brandClubIds = useMemo(
    () =>
      brandEntity?.entity?.brandclubId != null
        ? [brandEntity.entity.brandclubId]
        : undefined,
    [brandEntity?.entity?.brandclubId]
  );
  // this is passed to several useCallback hooks so we need to memoize it to maintain referential equality
  const brandIds = useMemo(
    () => getBrandIds(brandEntity?.entity),
    [brandEntity?.entity]
  );
  return (
    <>
      <DailyDealsEmptyRewardPreview />
      <ReviewEmptyRewardPreview brandIds={brandIds} />
      <AffiliateEmptyRewardPreview brandIds={brandIds} />
      <ContentEmptyRewardPreview brandclubIds={brandClubIds} />
      <InstantRewardsEmptyRewardPreview brandIds={brandIds} />
      {/* <ReviewPreview />
      <AffiliatePreview />
      <MyClubsPreview />
      <ContentPreview />
      <ClubInvitesPreview />
      <SurveysPreview />
      <TrendingPreview /> */}
    </>
  );
};

export default RewardEmptyPageSmall;
