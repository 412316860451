import { Link } from "react-router-dom";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";

import { PreviewHeader } from "../components/Header";

import { useFetchInstantRewards } from "./data";
import {
  INSTANT_REWARDS_ROUTE,
  INSTANT_REWARDS_SUBTITLE,
  INSTANT_REWARDS_TITLE,
} from "./text";

import { useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import ProductCard from "../../../../StoreComponents/StoreCard.tsx/ProductCard";
import { CardGridForThree } from "../components/CardGrid";
import PreviewContainer from "../components/PreviewContainer";
import { useLinkPrefix } from "../../../../../utils/hooks/useLinkPrefix";

interface InstantRewardsEmptyRewardPreviewProps {
  brandIds?: number[];
}
const InstantRewardsEmptyRewardPreview = (
  props: InstantRewardsEmptyRewardPreviewProps
) => {
  const ref = useRef(null);
  const widgetInView = useInView(ref, { once: true, margin: "100px" });
  const { linkPrefix } = useLinkPrefix();
  const { data, loading, fetchInit } = useFetchInstantRewards({
    pageSize: 6,
    autoInitFetch: false,
    brandIds: props.brandIds,
  });

  useEffect(() => {
    if (widgetInView) {
      fetchInit();
    }
  }, [widgetInView, fetchInit]);

  if (!loading && data.length === 0) {
    return null;
  }

  return (
    <>
      <PreviewContainer ref={ref}>
        {loading ? (
          <StoreLoadingSkeleton header row={2} cardStyle="cardForThree" />
        ) : (
          <>
            <PreviewHeader
              title={INSTANT_REWARDS_TITLE}
              linkTo={linkPrefix + INSTANT_REWARDS_ROUTE}
              subtitle={INSTANT_REWARDS_SUBTITLE}
              indicator="grey"
            />
            <CardGridForThree>
              {data.map((p) => (
                <ProductCard
                  key={`trending:${p.stacklineSku}`}
                  LinkComponent={Link}
                  product={p}
                />
              ))}
            </CardGridForThree>
          </>
        )}
      </PreviewContainer>
    </>
  );
};

export default InstantRewardsEmptyRewardPreview;
