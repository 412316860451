import { useCallback, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  closeCartDrawer,
  openCartDrawer,
  toggleCartDrawer,
} from "../../redux/reducers/checkout";
import { useLocation } from "react-router-dom";

export const useCheckoutDrawer = () => {
  const isOpen = useAppSelector((state) => state.checkout.open);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const prevLocation = useRef(location);
  const toggle = useCallback(() => {
    dispatch(toggleCartDrawer());
  }, [dispatch]);

  const closeDrawer = useCallback(() => {
    dispatch(closeCartDrawer());
  }, [dispatch]);

  const openDrawer = useCallback(() => {
    dispatch(openCartDrawer());
  }, [dispatch]);

  useEffect(() => {
    if (location !== prevLocation.current) {
      closeDrawer();
    }
    prevLocation.current = location;
  }, [location, closeDrawer]);

  // todo: use redux for global state

  return {
    isOpen,
    toggle,
    closeDrawer,
    openDrawer,
  };
};
