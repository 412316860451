import { Dispatch, ListenerEffectAPI, UnknownAction } from "@reduxjs/toolkit";

export const DEFAULT_DEBOUNCE_DELAY = 500;

export const cancelActiveListeners = (
  listenerApi: ListenerEffectAPI<unknown, Dispatch<UnknownAction>>
) => {
  // Repeated calls cancel previous ones, no work performed
  // until the specified delay elapses without another call

  // Cancel any ongoing update task
  listenerApi.cancelActiveListeners();
};

export const debounceListener = async (
  listenerApi: ListenerEffectAPI<unknown, Dispatch<UnknownAction>>,
  delay = DEFAULT_DEBOUNCE_DELAY
) => {
  try {
    cancelActiveListeners(listenerApi);
    // debounce logic
    await listenerApi.delay(delay);
  } catch (e: any) {
    if (e?.name === "TaskAbortError") {
      return;
    }
    console.error("Error in debounceListener", e);
  }
};

/**
 * Waits for a specific action to be dispatched within a specified timeout.
 * @param listenerApi - The listener effect API.
 * @param actionTypes - An array of action types to wait for.
 * @param timeoutMilliseconds - Optional. The timeout value in milliseconds.
 * @returns A forked listener effect that waits for the specified action types.
 * The task will have a resolved value of true if the action is dispatched within the timeout, and false otherwise.
 */
export const waitForSpecificActionTask = (
  listenerApi: ListenerEffectAPI<unknown, Dispatch<UnknownAction>>,
  actionTypes: string[],
  timeoutMilliseconds?: number
) => {
  const activityChecker = listenerApi.fork(() =>
    // waits for any of the specified action types to be dispatched
    waitForActionOfType(listenerApi, actionTypes, timeoutMilliseconds)
  );
  return activityChecker;
};

/**
 * Waits for any of the specified action types to be dispatched.
 *
 * @param listenerApi - The listener effect API.
 * @param actionTypes - An array of action types to wait for.
 * @param timeout - Optional. The timeout duration in milliseconds.
 * @returns A promise that resolves to true if any of the specified action types are dispatched within the timeout, and false otherwise.
 */
export const waitForActionOfType = async (
  listenerApi: ListenerEffectAPI<unknown, Dispatch<UnknownAction>>,
  actionTypes: string[],
  timeout?: number
) => {
  // waits for any of the specified action types to be dispatched
  const res = await listenerApi.condition((action) => {
    return actionTypes.includes(action.type);
  }, timeout);

  return res;
};
