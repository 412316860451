import { BRANDCLUB_BRAND_ID } from "@brandclub/common-ui";
import { useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ReactComponent as UserIcon } from "../../../../assets/user_circle.svg";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { clearBrandEntity } from "../../../../redux/reducers/brandEntity";
import {
  formatPhone,
  getPhoneNumberMasking,
  validatePhone,
} from "../../../../utils/StringUtils";
import { ButtonLoadingEllipsis } from "../../../StoreComponents/StoreButton";
import StoreInput from "../../../StoreComponents/StoreInput";
import { NumberFormatCustom } from "../../AppPages/Dashboard/Wallet/EditCard";
import AuthSolidButton from "../components/AuthSolidButton";
import OTPWrapper from "../OTPWrapper";
import { UserLoginContext } from "../UserLoginProvider";
import { startUpdatePhoneFlow } from "./api";

const ROW_SPACING = 20;

const FindAccount = () => {
  const { send } = useContext(UserLoginContext);
  const appConfig = useAppSelector(({ appConfig }) => appConfig);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (appConfig?.domainConfig.brandId === BRANDCLUB_BRAND_ID) {
      dispatch(clearBrandEntity());
    }
  }, [dispatch, appConfig?.domainConfig.brandId]);

  const [phone, setPhone] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const theme = useTheme();

  const submit = async (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e?.preventDefault();
    const phoneNumber = formatPhone(phone);
    setSubmitting(true);
    setErrorMsg(null);
    try {
      const res = await startUpdatePhoneFlow({ phoneNumber });
      console.log(res);
      if (res.status === "success") {
        send({
          type: "FOUND_ACCOUNT",
          session: res.session,
          sessionId: res.sessionId,
          emailToVerify: res.emailToVerify,
          otpPhoneNumber: phoneNumber,
        });
      } else {
        setErrorMsg(`An error occurred: Please try again after some time`);
      }
    } catch (e: any) {
      setErrorMsg(`An error occurred: Please try again after some time`);
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  const clearErrors = () => {
    setErrorMsg("");
  };

  const isNumberValid = validatePhone(phone);

  const phoneMasking = getPhoneNumberMasking(phone || "");

  return (
    <OTPWrapper>
      <div className="auth_container">
        <UserIcon
          style={{
            width: 75,
            height: 75,
            fill: theme.palette.primary.main,
          }}
        />
        <div className="page_title">Let's find your account</div>
        <div className="page_subtitle">
          Please enter your current account number here.
        </div>
        <StoreInput
          errorMsg={errorMsg || ""}
          value={phone}
          onChange={(e) => {
            const value = e.target.value;
            setPhone(value?.trim());
            clearErrors();
          }}
          inputProps={{
            format: phoneMasking,
          }}
          InputProps={{
            inputComponent: NumberFormatCustom as any,
          }}
          placeholder="Phone number"
          inputStyle={{ width: "100%" }}
          style={{ marginTop: ROW_SPACING }}
        ></StoreInput>
        <AuthSolidButton
          onClick={submit}
          disabled={submitting || !isNumberValid}
          style={{ marginTop: ROW_SPACING }}
        >
          {submitting ? (
            <>
              Submitting
              <ButtonLoadingEllipsis baseFontSize={16} />
            </>
          ) : (
            "Continue"
          )}
        </AuthSolidButton>
      </div>
    </OTPWrapper>
  );
};

export default FindAccount;
