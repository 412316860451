import FlowContainer from "../../../pages/AppPages/Pages/components/FlowContainer";
import { useCheckout } from "@/components/Checkout/CheckoutMachine";

import ChooseMFAOption from "../../../pages/AppPages/Sync/MFA/ChooseMFAOption";

const CheckoutMFAChoose = () => {
  const { snapshot } = useCheckout();
  const { retailerSyncSessions } =
    snapshot?.context?.retailerCheckoutOrderStatus || {};
  if (!retailerSyncSessions) {
    return null;
  }

  return (
    <FlowContainer>
      <ChooseMFAOption
        retailerId={retailerSyncSessions[0].retailerId}
        retailerSyncSession={retailerSyncSessions[0]}
      />
    </FlowContainer>
  );
};

export default CheckoutMFAChoose;
