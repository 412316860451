import { ReactNode, useEffect, useState } from "react";

import "./getApp.scss";
import { joinWaitList, sendTextInvite } from "../../../../api";
import {
  isImageSrcExist,
  joinClassNames,
  removeUndefinedValues,
  validatePhone,
} from "../../../../utils/StringUtils";
import { Helmet } from "react-helmet";
import QRCodeInner from "react-qr-code";
import ConfigUtils from "../../../../utils/ConfigUtils";
import { useLocationTrackingParams } from "../../../providers/LocationTrackingProvider";
import {
  AbstractArcIcon,
  BRANDCLUB_BRAND_ID,
  TrackingParams,
} from "@brandclub/common-ui";
import PreviewWidgetWrapper from "../Pages/components/PreviewWidgetWrapper";
import PreviewContainer from "../Pages/components/PreviewContainer";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../AppNavigation/constants";
import { StoreError } from "../../../StoreComponents/StoreError";
import { useMediaQuery } from "@mui/material";
import { useAppSelector } from "../../../../redux/hooks";
import {
  ButtonLoadingEllipsis,
  SolidButton,
} from "../../../StoreComponents/StoreButton";
import queryStringInner from "query-string";
import { ERROR_MESSAGES } from "../../../../utils/errors/errorUtils";
import PhoneNumber from "awesome-phonenumber";

// @ts-ignore
const QRCode = QRCodeInner.default
  ? // @ts-ignore
    QRCodeInner.default
  : QRCodeInner;

// @ts-ignore
const queryString = queryStringInner.default
  ? // @ts-ignore
    queryStringInner.default
  : queryStringInner;

const Container = styled("div")({
  paddingTop: "60px",
  paddingBottom: "0px",
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    paddingTop: "50px",
  },
  ".content_container": {
    display: "flex",
    flexDirection: "row",
    gap: "50px",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  ".getAppHead_left_section": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 5,
    alignItems: "flex-start",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      alignItems: "center",
    },
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      alignItems: "center",
    },
    ".title": {
      fontWeight: "bold",
      fontSize: "62px",
      color: "#181d56",
      lineHeight: 1.1,
      maxWidth: "500px",
      [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
        fontSize: "40px",
        textAlign: "center",
      },
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: "28px",
        textAlign: "center",
      },
    },
    ".subtitle": {
      fontSize: "20px",
      lineHeight: 1.67,
      maxWidth: "446px",
      color: "#181d56",
      marginBottom: "25px",
      marginTop: "25px",
      [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
        textAlign: "center",
        marginBottom: "16px",
        marginTop: "16px",
      },
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        textAlign: "center",
        fontSize: 14,
        marginBottom: "16px",
        marginTop: "16px",
      },
    },
    ".textme_input": {
      boxSizing: "border-box",
    },
    ".brandclub_btn": { marginTop: "25px" },
  },
  ".getAppHead_right_section": {
    position: "relative",
    width: "100%",
    flex: 5,
    ".phone_img_top": { width: "100%", position: "absolute", zIndex: 2 },
    ".phone_img_bot": { width: "100%", zIndex: 1 },
    ".img_container": {
      position: "relative",
      width: "100%",
      aspectRatio: 1,
      ".partner_img": {
        maxWidth: "100%",
        height: "100%",
      },
      ".phone_img_top": {
        position: "absolute",

        width: "auto",
        left: "4%",
        top: "0%",
        height: "100%",
      },
      ".phone_img_bot": {
        position: "absolute",
        width: "auto",
        top: "9%",
        right: "4%",
        height: "93%",
      },
    },
  },
});

type SendStatus = "notSend" | "sending" | "sent";

const getSendStatusMessage = (
  sendStatus: SendStatus,
  defaultMessage: string
): ReactNode => {
  switch (sendStatus) {
    case "sending":
      return (
        <>
          Sending
          <ButtonLoadingEllipsis baseFontSize={16} />
        </>
      );
    case "sent":
      return "Text sent!";
    default:
      return defaultMessage;
  }
};

interface UseSendStatusProps {
  onStatusReset?: VoidFunction;
}

const useSendStatus = ({ onStatusReset }: UseSendStatusProps) => {
  const [sendStatus, setSendStatus] = useState<SendStatus>("notSend");
  const [error, setError] = useState("");

  useEffect(() => {
    if (sendStatus === "sent") {
      // Reset the send status after 3 seconds
      setTimeout(() => {
        setSendStatus("notSend");
        onStatusReset?.();
      }, 3000);
    }
    // only run this effect when sendStatus changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendStatus]);

  return { sendStatus, setSendStatus, error, setError };
};

export const sendText = async ({
  phoneNumber,
  queryParams,
}: {
  phoneNumber: string;
  queryParams?: TrackingParams;
}) => {
  const { data: waitListEntry } = await joinWaitList({
    phoneNumber:
      phoneNumber.indexOf("+") === 0 ? phoneNumber : `+1${phoneNumber}`,
    email: "gettextinvite@stackline.com",
    name: "gettextinvite@stackline.com",
  });
  await sendTextInvite({
    id: waitListEntry.id,
    forceSendInvite: true,
    queryParams,
  });
};

export const TextTheApp = ({
  hideHeader = false,
  textTheAppCallBack,
  className = "",
}: {
  hideHeader?: boolean;
  textTheAppCallBack?: ({
    phoneNumber,
    email,
    name,
  }: {
    phoneNumber: string;
    email: string;
    name: string;
  }) => void;
  className?: string;
}) => {
  const [number, setNumber] = useState("");
  const { error, setError, sendStatus, setSendStatus } = useSendStatus({
    onStatusReset() {
      setNumber("");
    },
  });
  const userProfile = useAppSelector((state) => state.userProfile);
  const isSignedIn = !!userProfile;
  const userPhoneNumber = userProfile?.phoneNumber;
  const pn = new PhoneNumber(userPhoneNumber || "", "US");
  const formattedPhoneNumber = pn.getNumber("national");
  const isMobileView = useMediaQuery(`(max-width: ${MOBILE_SCREEN_SIZE}px)`);
  const [queryParams] = useLocationTrackingParams();

  const getValidPhoneNumber = () => {
    if (userPhoneNumber) {
      // remove all non-digit characters
      return userPhoneNumber.replace(/\D/g, "");
    }
    if (validatePhone(number)) {
      return `+1${number}`;
    }
    return undefined;
  };

  const handleSendText = async () => {
    try {
      const validPhoneNumber = getValidPhoneNumber();
      if (!validPhoneNumber) {
        setError("Please enter a valid phone number.");
        return;
      }
      setError("");
      setSendStatus("sending");
      if (textTheAppCallBack) {
        await textTheAppCallBack({
          phoneNumber: validPhoneNumber,
          email: "gettextinvite@stackline.com",
          name: "gettextinvite@stackline.com",
        });
      } else {
        await sendText({ phoneNumber: validPhoneNumber, queryParams });
      }
      setSendStatus("sent");
    } catch (e) {
      console.error("Error sending text invite", e);
      setError(ERROR_MESSAGES.default[0]);
      setSendStatus("notSend");
    }
  };
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
        [theme.breakpoints.down("md")]: {
          alignItems: "center",
        },
      })}
    >
      <div
        className={joinClassNames(className, "text_app_container")}
        style={{
          width: isMobileView ? "100%" : undefined,
        }}
      >
        <div className={`text_box ${sendStatus}`}>
          <div className="textme_header">Download the mobile app</div>
          {isSignedIn && userPhoneNumber ? (
            <SolidButton
              sx={{
                height: 50,
                fontSize: 16,
                width: 355,
                marginTop: "10px",
                [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
                  width: "100%",
                  height: 40,
                  fontSize: 12,
                  maxWidth: 300,
                },
              }}
              className="solid_textlink_to_button"
              onClick={sendStatus === "notSend" ? handleSendText : undefined}
              disabled={sendStatus === "sending"}
            >
              {getSendStatusMessage(
                sendStatus,
                `Text link to ${formattedPhoneNumber}`
              )}
            </SolidButton>
          ) : (
            <Box
              className="textme_input"
              sx={{
                marginTop: "10px",
                height: isMobileView ? "auto" : undefined,
                padding: isMobileView ? "5px" : undefined,
              }}
            >
              {sendStatus !== "sent" && (
                <div
                  className="text_prefix"
                  style={{
                    fontSize: isMobileView ? "14px" : undefined,
                  }}
                >
                  +1
                </div>
              )}
              {sendStatus !== "sent" && (
                <input
                  className={sendStatus}
                  disabled={["sending", "sent"].includes(sendStatus)}
                  placeholder="Phone number"
                  value={number}
                  onChange={(e) => {
                    setError("");
                    setNumber(e.target.value);
                  }}
                  style={{
                    fontSize: isMobileView ? "14px" : undefined,
                  }}
                />
              )}
              <SolidButton
                className={`text_button ${sendStatus}`}
                onClick={sendStatus === "notSend" ? handleSendText : undefined}
                disabled={sendStatus === "sending"}
                style={{
                  fontSize: isMobileView ? "14px" : undefined,
                  height: isMobileView ? "40px" : undefined,
                  width:
                    isMobileView && sendStatus !== "sent" ? "auto" : undefined,
                  paddingLeft: isMobileView ? "14px" : undefined,
                  paddingRight: isMobileView ? "14px" : undefined,
                }}
              >
                {getSendStatusMessage(sendStatus, "Send text")}
              </SolidButton>
            </Box>
          )}
        </div>
      </div>
      <StoreError
        errorMessage={error}
        sx={{
          marginTop: 2.5,
          width: "max-content",
        }}
      />
    </Box>
  );
};

export const TextTheAppWithSQ = ({
  qrCodeUrl,
  textTheAppCallBack,
}: {
  qrCodeUrl?: string;
  textTheAppCallBack?: ({
    phoneNumber,
    email,
    name,
  }: {
    phoneNumber: string;
    email: string;
    name: string;
  }) => void;
}) => {
  const isMobileView = useMediaQuery(`(max-width: ${MOBILE_SCREEN_SIZE}px)`);
  const userProfile = useAppSelector((state) => state.userProfile);
  const isSignedIn = !!userProfile;
  const phoneNumber = userProfile?.phoneNumber;
  const pn = new PhoneNumber(phoneNumber || "", "US");
  const formattedPhoneNumber = pn.getNumber("national");
  const [number, setNumber] = useState("");
  const [queryParams] = useLocationTrackingParams();
  const { error, setError, sendStatus, setSendStatus } = useSendStatus({
    onStatusReset() {
      setNumber("");
    },
  });

  const getValidPhoneNumber = () => {
    if (phoneNumber) {
      // remove all non-digit characters
      return phoneNumber.replace(/\D/g, "");
    }
    if (validatePhone(number)) {
      return `+1${number}`;
    }
    return undefined;
  };

  const handleSendText = async () => {
    const validPhoneNumber = getValidPhoneNumber();
    if (!validPhoneNumber) {
      setError("Please enter a valid phone number.");
      return;
    }
    try {
      setError("");
      setSendStatus("sending");
      if (textTheAppCallBack) {
        await textTheAppCallBack({
          phoneNumber: validPhoneNumber,
          email: "gettextinvite@stackline.com",
          name: "gettextinvite@stackline.com",
        });
      } else {
        const { data: waitListEntry } = await joinWaitList({
          phoneNumber: validPhoneNumber,
          email: "gettextinvite@stackline.com",
          name: "gettextinvite@stackline.com",
        });
        await sendTextInvite({
          id: waitListEntry.id,
          forceSendInvite: true,
          queryParams,
        });
      }
      setSendStatus("sent");
    } catch (e) {
      console.error("Error sending text invite", e);
      setError(ERROR_MESSAGES.default[0]);
      setSendStatus("notSend");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          width: "100%",
        },
        [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
          alignItems: "center",
          ".text_app_container_with_sq": {
            justifyContent: "center",
          },
        },
      }}
    >
      <div
        className="text_app_container_with_sq"
        style={{
          width: isMobileView ? "100%" : undefined,
        }}
      >
        <div className="sq_box">
          {!qrCodeUrl && (
            <img
              src="https://media.brandclub.com/brandclub/image_asset/qr-code.png"
              alt="qr"
            />
          )}
          {qrCodeUrl && (
            <QRCode value={qrCodeUrl} fgColor="#181d56" size={95} />
          )}
        </div>
        {isSignedIn && phoneNumber ? (
          <SolidButton
            trackedAdditionalData={{
              isSignedIn,
              userId: userProfile?.userId,
              qrCodeUrl,
            }}
            sx={{
              height: 50,
              maxWidth: 355,
              width: "100%",
              fontSize: 16,
              alignSelf: "center",
              [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
                height: 40,
                fontSize: 12,
                maxWidth: 300,
              },
            }}
            onClick={sendStatus === "notSend" ? handleSendText : undefined}
            disabled={sendStatus === "sending"}
          >
            {getSendStatusMessage(
              sendStatus,
              `Text link to ${formattedPhoneNumber}`
            )}
          </SolidButton>
        ) : (
          <div className="text_box">
            <div className="textme_header">Download the mobile app</div>
            <Box
              className={`textme_input ${sendStatus}`}
              sx={{
                height: isMobileView ? "auto" : undefined,
                padding: isMobileView ? "5px" : undefined,
                maxWidth: isMobileView ? 360 : undefined,
                alignSelf: "center",
              }}
            >
              {sendStatus !== "sent" && (
                <div
                  className="text_prefix"
                  style={{
                    fontSize: isMobileView ? "14px" : undefined,
                  }}
                >
                  +1
                </div>
              )}
              {sendStatus !== "sent" && (
                <input
                  className={sendStatus}
                  placeholder="Phone number"
                  disabled={["sending", "sent"].includes(sendStatus)}
                  value={number}
                  onChange={(e) => {
                    setError("");
                    setNumber(e.target.value);
                  }}
                  style={{
                    fontSize: isMobileView ? "14px" : undefined,
                  }}
                />
              )}
              <SolidButton
                className={`text_button ${sendStatus}`}
                onClick={sendStatus === "notSend" ? handleSendText : undefined}
                disabled={sendStatus === "sending"}
                style={{
                  fontSize: isMobileView ? "14px" : undefined,
                  height: isMobileView ? "40px" : undefined,
                  width:
                    isMobileView && sendStatus !== "sent" ? "auto" : undefined,
                  paddingLeft: isMobileView ? "14px" : undefined,
                  paddingRight: isMobileView ? "14px" : undefined,
                }}
              >
                {getSendStatusMessage(sendStatus, "Send text")}
              </SolidButton>
            </Box>
            <div className="textme_botton_group">
              <SolidButton
                className="download_button"
                onClick={() => {
                  window.open(
                    qrCodeUrl ||
                      "https://apps.apple.com/us/app/brandclub-rewards/id1551836360",
                    "_self"
                  );
                }}
              >
                <img
                  src="https://media.brandclub.com/brandclub/image_asset/apple_appstore.svg"
                  alt="app store"
                />
                App Store
              </SolidButton>
              <SolidButton
                className="text_button"
                onClick={handleSendText}
                disabled={["sending", "sent"].includes(sendStatus)}
              >
                {getSendStatusMessage(sendStatus, "Text me a link")}
              </SolidButton>
            </div>
          </div>
        )}
      </div>
      <StoreError
        errorMessage={error}
        sx={{
          padding: "7px 12px 7px 9px",
          backgroundColor: "rgba(255, 83, 74, 0.05)", // #ff534a with 5% opacity
          marginTop: 2.5,
          borderRadius: 1,
          width: "max-content",
        }}
      />
    </Box>
  );
};

export const GetAppHeadSection = ({
  imgUrl,
  qrCodeUrl,
  textTheAppCallBack,
  desc,
}: {
  imgUrl?: string;
  qrCodeUrl?: string;
  textTheAppCallBack?: ({
    phoneNumber,
    email,
    name,
  }: {
    phoneNumber: string;
    email: string;
    name: string;
  }) => void;
  desc?: string;
}) => {
  return (
    <Container>
      <div className="content_container">
        <div className="getAppHead_left_section">
          <div className="title">Get rewarded to engage</div>
          <div className="subtitle">
            {desc
              ? desc
              : "Earn cash rewards when you shop and engage with your favorite brands."}
          </div>
          <TextTheAppWithSQ
            qrCodeUrl={qrCodeUrl}
            textTheAppCallBack={textTheAppCallBack}
          />
        </div>
        <div className="getAppHead_right_section">
          {imgUrl ? (
            <div className="img_container">
              <img src={imgUrl} alt="phone_img_top" className="partner_img" />
            </div>
          ) : (
            <div className="img_container">
              <img
                className="phone_img_bot"
                src="https://media.brandclub.com/brandclub/image_asset/Brandclub_getapp_2024_3.webp"
                alt="phone_bot"
              />
              <img
                className="phone_img_top"
                src="https://media.brandclub.com/brandclub/image_asset/Brandclub_getapp_2024_1.webp"
                alt="phone_top"
              />
              <AbstractArcIcon
                fillColor="#16949b"
                sx={{
                  transform: "rotate(197deg) translateY(-50%)",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "10%",
                  height: "10%",
                }}
              />
              <Box
                component="img"
                src="https://media.brandclub.com/brandclub/icons/abstract/14.webp"
                alt="abstract icon"
                sx={{
                  position: "absolute",
                  top: "31%",
                  right: "19.5%",
                  zIndex: 0,
                  aspectRatio: "1/1",
                  width: "9.3%",
                }}
              />
              <Box
                component="img"
                src="https://brandclub-images.s3.us-west-2.amazonaws.com/brandclub/icons/abstract/17.svg"
                alt="abstract icon"
                sx={{
                  position: "absolute",
                  top: "18%",
                  left: 0,
                  zIndex: 0,
                  aspectRatio: "1/1",
                  width: "7%",
                  transform: "rotate(-40deg)",
                }}
              />
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

const GetApp = () => {
  const [{ referralCode }] = useLocationTrackingParams();
  const appConfig = useAppSelector(({ appConfig }) => appConfig);
  const [qrCodeUrl, setQrCodeUrl] = useState<string | undefined>(undefined);
  const [storeName, setStoreName] = useState<string | undefined>(undefined);
  const [getAppImage, setGetAppImage] = useState<string | undefined>(undefined);

  useEffect(() => {
    const getThreePartyGetAppImage = async (brandId: string | number) => {
      const customFavicon = `https://media.brandclub.com/brandclub/image_asset/${brandId}/getapp.png`;
      const imageValid = await isImageSrcExist(customFavicon);
      if (imageValid) {
        setGetAppImage(customFavicon);
      } else {
        setGetAppImage(undefined);
      }
    };

    if (
      appConfig?.domainConfig?.brandId &&
      appConfig?.domainConfig?.brandId !== BRANDCLUB_BRAND_ID
    ) {
      getThreePartyGetAppImage(appConfig?.domainConfig?.brandId);
    } else {
      setGetAppImage(undefined);
    }
    setStoreName(appConfig?.brandAppConfig?.appName || "");
  }, [appConfig]);

  useEffect(() => {
    if (appConfig?.brandAppConfig) {
      const queryParams = removeUndefinedValues({
        referralCode,
      });
      const branchIOLinkPrefix = ConfigUtils.getBranchIOLinkPrefix(
        appConfig.brandAppConfig
      );
      setQrCodeUrl(
        `${branchIOLinkPrefix}?${queryString.stringify(queryParams)}`
      );
    }
  }, [appConfig, referralCode]);

  return (
    <PreviewWidgetWrapper>
      <PreviewContainer>
        <Helmet>
          <title>{`Sign Up - Get the ${storeName} app`}</title>
          <meta
            name="description"
            content={`Get the ${storeName} app and begin earning cash back rewards.`}
          />
        </Helmet>
        <GetAppHeadSection imgUrl={getAppImage} qrCodeUrl={qrCodeUrl} />
      </PreviewContainer>
    </PreviewWidgetWrapper>
  );
};

export default GetApp;
