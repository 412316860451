import {
  brandclub_colors,
  brandclubStyles,
  hexadecimal,
} from "@brandclub/common-ui";
import { Grid, styled } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import React from "react";
import { useAppSelector } from "../../../redux/hooks";
import { TABLET_SCREEN_SIZE, SingleNavType } from "../constants";
import AuthAttachment from "./AuthAttachment";

import UnauthAttachment from "./UnauthAttachment";

import BrandstoreMenu from "./BrandstoreMenu";

export const NAV_ATTACHMENT_SIZE = 28;

const DrawerContainer = styled(Grid)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: "Poppins",
  flexDirection: "column",
  width: "100vw",
  flexGrow: "inherit",
  display: "grid",
  gridTemplateRows: "min-content 1fr min-content",
  justifyContent: "space-between",
  height: "100dvh",
  a: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
}));

const MenuList = styled(Grid)(({ theme }) => ({
  width: "100vw",

  height: "100%",
  minHeight: "100dvh",
  overflowY: "scroll",
  overflowX: "hidden",
  display: "flex",
  flexDirection: "column",
  paddingTop: 70,
  ".section + .section": {
    borderTop: "2px solid #EAEEF2",
  },

  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    // width: MOBILE_DRAWER_WIDTH,
    maxWidth: "100vw",
  },
  "&::-webkit-scrollbar": {
    height: 5,
    width: 5,
    backgroundColor: "transparent",
    borderRadius: 20,
    position: "absolute",
    zIndex: 99999,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: brandclub_colors.darkBlue1,
    borderRadius: 20,
  },
  a: { textDecoration: "none" },

  ".nav_head": {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.primary.main,
    padding: "10px 35px",
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      padding: "16px 20px",
    },
  },
  ".nav_item": {
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    color: theme.palette.primary.main,

    fontSize: 16,
    fontWeight: 600,
    padding: "8px 20px",
    "&.top-level": {
      fontSize: 20,
      fontWeight: 600,
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
        fontSize: 20,
        fontWeight: 600,
      },
    },
    "&.second-level": {
      fontSize: 14,
      fontWeight: 500,
      padding: "6px 20px",
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
        fontSize: 14,
        fontWeight: 500,
      },
    },
    ".left-icon": {
      position: "relative",
      left: -8,
    },
    ".right-icon": {
      // visibility: "hidden",
      margin: "0 6px",
    },
  },
  ".divider": {
    margin: "0 20px",
    boxSizing: "border-box",
    height: 2,
    background: "#EAEEF2",
  },
  ".home_header": {
    flex: 1,
    ".attachments": {
      display: "flex",
      flexDirection: "column",
      gap: 20,
      marginTop: 20,
      marginBottom: 30,
    },
  },
  ".home_attachment": {
    ".attachments": {
      display: "flex",
      flexDirection: "column",
      gap: 20,
      marginTop: 30,
      marginBottom: 50,
    },
  },
}));

export const HeaderLink = styled("div")({
  display: "flex",
  alignItems: "center",
  marginLeft: 20,
  fontSize: 16,
  img: {},
  gap: 15,
  ".label": { fontWeight: 600, fontSize: 18 },
});

const DrawerMenu = ({
  navMenu = [],
  open,
  setOpen,
  handleLoginOpen,
}: {
  navMenu: SingleNavType[];
  open: boolean;
  setOpen: (newVal: boolean) => void;
  handleLoginOpen: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}) => {
  const userProfile = useAppSelector(({ userProfile }) => userProfile);

  // const { siteBrandingType } = useSiteContext();
  //   const mobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);
  const toggleDrawer =
    (newVal: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(newVal);
    };

  const list = () => (
    <DrawerContainer>
      <MenuList>
        <BrandstoreMenu navMenu={navMenu} onClose={toggleDrawer(false)} />
        <div className=" home_attachment">
          <div className="divider" />
          <div className="attachments">
            {userProfile ? (
              <AuthAttachment
                onClose={() => {
                  setOpen(false);
                }}
              ></AuthAttachment>
            ) : (
              <UnauthAttachment
                onClose={() => {
                  setOpen(false);
                }}
                handleLoginOpen={handleLoginOpen}
              ></UnauthAttachment>
            )}
          </div>
        </div>
      </MenuList>
    </DrawerContainer>
  );

  return (
    <div className="mobile_drawer">
      <Drawer
        anchor={"right"}
        open={open}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: "100%",
            height: "100%",
            boxShadow: "none",
            borderRadius: "0",
            ...brandclubStyles.customScrollbar,
          },
        }}
        slotProps={{
          backdrop: {
            sx: (theme) => ({
              backgroundColor: hexadecimal(theme.palette.primary.main)(21),
            }),
          },
        }}
      >
        {list()}
      </Drawer>
    </div>
  );
};

export default DrawerMenu;
