import FlowContainer from "../../../pages/AppPages/Pages/components/FlowContainer";

import { useAppSelector } from "../../../../redux/hooks";
import { useCheckout } from "../../CheckoutMachine";
import { styled } from "@mui/material";
import ConnectRetailer from "../../../pages/AppPages/Sync/Connect";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../AppNavigation/constants";

export interface FormState {
  username: string;
  password: string;
}

const ConnectContainer = styled("div")(({ theme }) => ({
  maxWidth: "580px",
  width: "100%",
  marginTop: "33px",
  marginBottom: "68px",
  ".auth_container": {
    ".reward_summary": {
      textAlign: "center",
      ...(theme.dtc && {
        margin: "unset",
        width: "100%",
        maxWidth: "unset !important",
        marginTop: "20px",
      }),
    },
  },
  ".close": {
    cursor: "pointer",
    position: "absolute",
    top: 20,
    right: 20,
    width: 20,
    height: 20,
    border: "none",
    backgroundColor: "transparent",
    outline: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ".close_img": {
      width: 14,
      height: 14,
    },
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      top: 10,
      right: 10,
      width: 20,
      height: 20,
    },
  },
}));

const CheckoutConnect = () => {
  const { snapshot, send: checkoutSend } = useCheckout();
  const { activeCheckoutRetailerId, checkoutRetailerConnectionState } =
    snapshot.context;

  const retailers = useAppSelector(({ retailers }) => retailers);
  const retailerToConnect = retailers.find(
    (retailer) => retailer.retailerId === activeCheckoutRetailerId
  );

  const setLoginInfo = (values: FormState) => {
    checkoutSend({
      type: "ON_LOGIN_INFO_SUBMIT",
      data: {
        connectionInfo: {
          retailerId: retailerToConnect?.retailerId as number,
          credential: {
            username: values.username,
            password: values.password,
            connectionStatus: "notConnected",
          },
        },
      },
    });
  };

  return (
    <FlowContainer>
      <ConnectContainer>
        <ConnectRetailer
          retailerId={retailerToConnect?.retailerId}
          connectOverwrite={(username, password) => {
            setLoginInfo({ username, password });
          }}
          errorMsg={checkoutRetailerConnectionState?.errorMessage}
        />
      </ConnectContainer>
    </FlowContainer>
  );
};

export default CheckoutConnect;
