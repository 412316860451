import { Box, styled, Typography } from "@mui/material";
import { formatMoney } from "../../../utils/StringUtils";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../AppNavigation/constants";

const TotalLineItemContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  fontSize: 16,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    fontSize: 14,
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    fontSize: 14,
  },
});

export const TotalLineItem = ({
  label,
  value,
  zeroString = "",
}: {
  label: string;
  value: number;
  zeroString?: string; // String to render if 0
}) => {
  return (
    <TotalLineItemContainer>
      <Typography fontSize={"inherit"} fontWeight={500}>
        {label}
      </Typography>
      <Typography fontSize={"inherit"} fontWeight={600}>
        {value ? formatMoney(value) : zeroString || formatMoney(0)}
      </Typography>
    </TotalLineItemContainer>
  );
};
