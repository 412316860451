import { CardGridForThree } from "../components/CardGrid";
import { PreviewHeader } from "../components/Header";
import { useFetchAffiliate } from "./data";

import { ProductRewardSearchResult } from "@brandclub/common-ui";
import { useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";

import ProductCard from "../../../../StoreComponents/StoreCard.tsx/ProductCard";
import PreviewContainer from "../components/PreviewContainer";
import { useLinkPrefix } from "../../../../../utils/hooks/useLinkPrefix";

interface AffiliateEmptyRewardPreviewProps {
  brandIds?: number[];
}
const AffiliateEmptyRewardPreview = (
  props: AffiliateEmptyRewardPreviewProps
) => {
  const ref = useRef(null);
  const widgetInView = useInView(ref, { once: true, margin: "100px" });
  const { linkPrefix } = useLinkPrefix();
  const { data, loading, fetchInit } = useFetchAffiliate({
    pageSize: 6,
    autoInitFetch: false,
    brandIds: props.brandIds,
  });

  useEffect(() => {
    if (widgetInView) {
      fetchInit();
    }
  }, [widgetInView, fetchInit]);

  if (!loading && data.length === 0) {
    return null;
  }

  return (
    <>
      <PreviewContainer ref={ref}>
        {loading ? (
          <StoreLoadingSkeleton header row={2} cardStyle="cardForThree" />
        ) : (
          <>
            <PreviewHeader
              title={"Affiliate commission"}
              linkTo={linkPrefix + "/affiliate"}
              subtitle="Get paid to refer purchases"
              indicator="grey"
            />
            <CardGridForThree>
              {data.map((d) => (
                <ProductCard
                  key={d.stacklineSku}
                  LinkComponent={Link}
                  product={d as ProductRewardSearchResult}
                ></ProductCard>
              ))}
            </CardGridForThree>
          </>
        )}
      </PreviewContainer>
    </>
  );
};

export default AffiliateEmptyRewardPreview;
