import { styled } from "@mui/material";
import React from "react";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../../AppNavigation/constants";

const RowContainer = styled("div")(({ theme }) => ({
  backgroundColor: "#f6f8fa",
  borderRadius: 3,

  border: `1px solid #dcdddc`,
  boxSizing: "border-box",
  width: "auto",
  padding: "0 15px",
  minWidth: 56,
  height: 56,
  transition: "all 0.3s",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    minWidth: 50,
    height: 50,
  },
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    minWidth: 30,
    height: 30,
  },

  "&.selected": {
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.main,
    ".row_text": { color: "#fff" },
  },

  ".row_text": {
    flex: 1,
    whiteSpace: "nowrap",
    fontSize: 20,
    textAlign: "center",
    fontWeight: 600,
    color: theme.palette.primary.main,
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      fontSize: 16,
    },
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 12,
    },
  },
  ".indicator": {
    marginLeft: 10,
    width: 23,
    height: 23,
    objectFit: "contain",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      width: 18,
      height: 18,
    },
  },

  "&.disabled": {
    ".row_text": {
      opacity: 0.5,
    },
  },
}));

export const SurveyNpsCheckbox = ({
  text,
  onClick,
  selected = false,
  disabled = false,
}: {
  text: string;
  onClick: () => void;
  selected?: boolean;
  disabled?: boolean;
}) => {
  return (
    <RowContainer
      className={`${disabled ? "disabled" : ""} ${selected ? "selected" : ""}`}
      onClick={onClick}
    >
      <div className="row_text">{text}</div>
    </RowContainer>
  );
};

export default SurveyNpsCheckbox;
