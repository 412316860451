import { Outlet } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import axios from "../../../utils/axios";
import { AppMainNavigation } from "../../AppNavigation";
import { useSiteContext } from "../../SiteProvider";
import { withFadeTransition } from "../../StoreComponents/StoreTransition";
import { buildFooterConfig } from "./BrandStore/config";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  BrandclubFooter,
  removeTrailingSlash,
  UNIVERSAL_STORE_SITE,
} from "@brandclub/common-ui";
import { sumCampaignRewardCounts } from "../../../utils/misc";
import { SiteBrandingType } from "../../../types/misc";
import ConfigUtils from "../../../utils/ConfigUtils";
import reduxApolloClient from "../../../redux/reduxApolloClient";
import { getRewardAmountsByStatus } from "../../../api";
import AppLink from "../../AppNavigation/AppLink";
import { UserLoginContext, useUserLogin } from "../Auth/UserLoginProvider";
import { styled } from "@mui/material";
import { TextTheApp } from "@/components/pages/AppPages/GetApp/GetApp";

const StyledTextTheApp = styled(TextTheApp)(({ theme }) => ({
  maxWidth: 433,
  width: "100%",
  [`@media only screen and (max-width: 1280px)`]: {
    maxWidth: 369,
  },
  [theme.breakpoints.down("md")]: {
    width: 369, // we do this to allow the style in tablet view to be properly applied
    maxWidth: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    maxWidth: 369,
  },
  ".textme_header": {
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  ".solid_textlink_to_button": {
    marginTop: 20.9,
    width: 285,
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  ".textme_input": {
    display: "flex",
    alignItems: "center",
    border: `1.5px solid ${theme.palette.primary.main}`,
    borderRadius: 100,
    backgroundColor: theme.palette.common.white,
    padding: 9,
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
    fontSize: 18,
    "& input": {
      flex: 1,
      margin: "0 10px",
      fontWeight: 500,
      color: theme.palette.primary.main,
      border: 0,
      outline: "none",
      fontSize: 18,
      "&::placeholder": {
        color: "#a7afc4",
      },
    },
    "& .text_button": {
      fontWeight: 500,
      border: 0,
      fontSize: 20,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
      width: "100%",
      maxWidth: 153,
      borderRadius: 100,
      height: 52,
      padding: 0,
    },
    ".text_prefix": {
      marginLeft: 12, // marginLeft of 21px - 9px of padding,
    },
  },
}));

const AppMainPageOutlet = () => {
  const { siteBrandingType, site, persistentQueryParams, routePrefix } =
    useSiteContext();
  const { send } = useContext(UserLoginContext);
  const { signedIn } = useUserLogin();
  const appConfig = useAppSelector(({ appConfig }) => appConfig);
  const userProfile = useAppSelector(({ userProfile }) => userProfile);
  const [rewardSummaryForBrand, setRewardSummaryForBrand] = useState<{
    lifetimeEarnings: number;
    lifetimePayouts: number;
    lifetimeAdjustments: number;
    lockedBalance: number;
    pendingBalance: number;
    unlockedBalance: number;
    cancelledBalance: number;
    hasLoaded: boolean;
  }>({
    lifetimeEarnings: 0,
    lifetimePayouts: 0,
    lifetimeAdjustments: 0,
    lockedBalance: 0,
    pendingBalance: 0,
    unlockedBalance: 0,
    cancelledBalance: 0,
    hasLoaded: false,
  });
  const currentBalance = useAppSelector(
    ({ rewards }) => rewards?.rewards.currentBalance
  );
  const brandEntity = useAppSelector(({ brandEntity }) => brandEntity);
  const totalRewardCounts = useMemo(() => {
    return brandEntity?.brandclub
      ? sumCampaignRewardCounts(brandEntity.brandclub)
      : 0;
  }, [brandEntity?.brandclub]);

  useEffect(() => {
    (async () => {
      if (brandEntity?.brandclub && userProfile?.userId) {
        const variables = {
          brandIds: brandEntity?.brandclub?.brandIds,
          forceRefresh: true,
        };
        const { data } = await reduxApolloClient.query({
          query: getRewardAmountsByStatus,
          variables,
          context: {
            uri: `${ConfigUtils.getGraphQlUri()}?id=getRewardAmountsByStatus`,
          },
        });
        if (data && data.RewardSummary) {
          setRewardSummaryForBrand({ ...data.RewardSummary, hasLoaded: true });
        }
      }
    })();
  }, [brandEntity?.brandclub, userProfile]);

  const appLink = appConfig?.domainConfig?.mobileAppLink
    ? removeTrailingSlash(appConfig.domainConfig.mobileAppLink) + "/getapp"
    : UNIVERSAL_STORE_SITE + "/getapp";

  const footerConfigs = useMemo(() => {
    const handleSigninClick = (
      e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
      e?.preventDefault();
      e?.stopPropagation();
      send({ type: "SIGN_IN" });
      return false;
    };
    const config = buildFooterConfig({
      siteBrandingType,
      userRewardAmount:
        rewardSummaryForBrand.lifetimeEarnings -
        rewardSummaryForBrand.lifetimePayouts -
        rewardSummaryForBrand.lifetimeAdjustments,
      userRewardCounts: totalRewardCounts,
      site,
      routePrefix,
      persistentQueryParams,
      signedIn,
      brandEntity,
      appLink,
      onSigninClick: handleSigninClick,
    });
    const universalStoreFooterConfig = buildFooterConfig({
      siteBrandingType: SiteBrandingType.UniversalStore,
      userRewardAmount: currentBalance,
      userRewardCounts: totalRewardCounts,
      site: undefined,
      routePrefix: "",
      persistentQueryParams,
      signedIn,
      appLink,
      onSigninClick: handleSigninClick,
    });

    if (siteBrandingType === SiteBrandingType.UniversalStore) {
      return [universalStoreFooterConfig];
    }
    if (siteBrandingType === SiteBrandingType.CustomStore) {
      return [config];
    }
    return [config, universalStoreFooterConfig];
  }, [
    routePrefix,
    siteBrandingType,
    currentBalance,
    totalRewardCounts,
    site,
    persistentQueryParams,
    rewardSummaryForBrand,
    brandEntity,
    signedIn,
    send,
  ]);

  return (
    <div style={{}}>
      <AppMainNavigation></AppMainNavigation>
      <div>
        <div
          className="main-page-content"
          style={{
            minHeight: "66vh",
            paddingBottom: 100,
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Outlet />
        </div>

        <BrandclubFooter
          LinkComponent={AppLink}
          axios={axios}
          brandEntity={brandEntity as any}
          footerConfigs={footerConfigs}
          userProfile={userProfile}
          socialLinks={appConfig?.socialLinks}
          PhoneInputComponent={StyledTextTheApp}
        />
      </div>
    </div>
  );
};

export default withFadeTransition(AppMainPageOutlet);
