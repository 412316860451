import axios from "../../../utils/axios";
import { getAuthorizationHeader } from "../../../Auth";

export const autocompleteAddress = async (
  req: {
    searchText: string;
    countryCodes: string[];
    location?: {
      latitude: number;
      longitude: number;
    };
  },
  abortSignal?: AbortController
) => {
  const res = await axios.post("/userAddress/autocompleteAddress", req, {
    headers: {
      ...(await getAuthorizationHeader()),
    },
    signal: abortSignal?.signal,
  });
  return res;
};

export const getFullAddressFromPlaceId = async (req: { placeId: string }) => {
  const res = await axios.post("/userAddress/getFullAddressFromPlaceId", req, {
    headers: {
      ...(await getAuthorizationHeader()),
    },
  });
  return res;
};
