import { styled } from "@mui/material/styles";
import { PreviewHeader } from "../Pages/components/Header";
import { MOBILE_SCREEN_SIZE } from "../../../AppNavigation/constants";

export const DashboardPreviewHeader = styled(PreviewHeader)({
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    ".title": {
      fontSize: 18,
      "& .arrow": {
        width: 18,
        height: 18,
      },
    },
  },
});
