import { Widget, WidgetPropConfig, WidgetBuilderType } from "@brandclub/types";
import { Link, useSearchParams } from "react-router-dom";
import axios, { axiosEdgeCached } from "./axios";
import reduxApolloClient from "../redux/reduxApolloClient";
import ReduxStore from "../redux/types";
import { BrandClub, escapeUriPath } from "@brandclub/common-ui";

export const buildWidgetProps = ({
  widget,
  reduxState,
  persistentQueryParams,
}: {
  widget: Widget;
  reduxState: ReduxStore;
  persistentQueryParams?: string;
}) => {
  const helper = (propsConfig: Widget["propsConfig"]) => {
    const widgetProps: Record<string, any> = {};
    Object.values(propsConfig).forEach(
      ({
        propType,
        propName,
        propValue,
        propValueConfig,
        propsConfig: nestedPropsConfig,
      }: WidgetPropConfig) => {
        const { builderInputType } = propValueConfig;
        switch (propType) {
          case "userInput":
            if (
              propValueConfig &&
              builderInputType === WidgetBuilderType.Link
            ) {
              const propValueUpdated = { ...propValue };
              if (propValueUpdated.id) {
                if (propValueUpdated.id.indexOf("?") > 0) {
                  propValueUpdated.id = `${propValueUpdated.id}&${
                    persistentQueryParams || ""
                  }`;
                } else {
                  propValueUpdated.id = `${propValueUpdated.id}?${
                    persistentQueryParams || ""
                  }`;
                }
              }
              widgetProps[propName] = propValueUpdated;
            } else if (propValue) {
              widgetProps[propName] = propValue;
            } else if (
              builderInputType === WidgetBuilderType.Nested &&
              nestedPropsConfig
            ) {
              // ex: center product hero
              widgetProps[propName] = helper(nestedPropsConfig);
            }
            break;
          case "react":
            if (propValueConfig.componentType === "Link") {
              widgetProps[propName] = Link;
            }
            if (propValueConfig.componentType === "UseSearchParams") {
              widgetProps[propName] = useSearchParams;
            }
            if (propValueConfig.componentType === "Axios") {
              widgetProps[propName] = axios;
            }
            if (propValueConfig.componentType === "AxiosEdgeCached") {
              widgetProps[propName] = axiosEdgeCached;
            }
            if (propValueConfig.componentType === "ApolloClient") {
              widgetProps[propName] = reduxApolloClient;
            }
            break;
          case "redux":
            widgetProps[propName] = (reduxState as any)[
              propValueConfig.storeValueName
            ];
            break;
        }
      }
    );
    return widgetProps;
  };

  return helper(widget.propsConfig);
};

export const getBrandPagePath = (
  brandclub: Pick<BrandClub, "name" | "brandId"> | undefined
): string => {
  if (!brandclub) {
    return "/_/b/";
  }

  const { name, brandId } = brandclub;
  const escapedName = escapeUriPath(name || "_");

  return `/${escapedName}/b/${brandId}`;
};
