import { brandclub_colors } from "@brandclub/common-ui";
import { AnimatePresence } from "framer-motion";
import React from "react";
import {
  FlowSolidButton,
  FlowSolidButtonGrey,
} from "../../components/FlowButtons";
import FlowContainer from "../../components/FlowContainer";
import CharactersLimit from "../components/CharactersLimit";
import { ReviewQuestionContainer } from "./pros";
import ProductHeader from "../components/ProductHeader";

export const TITLE_LENGTH_MIN = 20;
export const TITLE_LENGTH_MAX = 50;
export const REVIEW_LENGTH_MIN = 50;
export const REVIEW_LENGTH_MAX = 500;
export const REVIEW_LENGTH_MAX_AMAZON = 1000;

export const reviewLengthCheck = (
  title: string,
  review: string,
  maxLength = REVIEW_LENGTH_MAX
) => {
  if (title.length < TITLE_LENGTH_MIN || title.length > TITLE_LENGTH_MAX) {
    return false;
  }
  if (review.length < REVIEW_LENGTH_MIN || review.length > maxLength) {
    return false;
  }
  return true;
};

const LowRatingInput = ({
  product,
  reviewText,
  setReviewText,
  reviewTitle,
  setReviewTitle,
  moveNext,
  moveBack,
}: {
  product: any;
  reviewText: string;
  setReviewText: React.Dispatch<React.SetStateAction<string>>;
  reviewTitle: string;
  setReviewTitle: React.Dispatch<React.SetStateAction<string>>;
  moveNext: () => void;
  moveBack: () => void;
}) => {
  const maxReviewLength =
    product?.retailerId === 1 ? REVIEW_LENGTH_MAX_AMAZON : REVIEW_LENGTH_MAX;

  const disabledNext = !reviewLengthCheck(
    reviewTitle,
    reviewText,
    maxReviewLength
  );
  return (
    <FlowContainer style={{ background: brandclub_colors.white }}>
      <ReviewQuestionContainer>
        <div className="review_info">
          <ProductHeader product={product} />
          <div className="section">
            <div className="title">Let’s start your product review.</div>
            <div className="text_input">
              <div className="label">Title</div>
              <div style={{ position: "relative" }}>
                <input
                  className="input_box"
                  value={reviewTitle}
                  style={{ height: 54, marginBottom: 5 }}
                  onChange={(e) => setReviewTitle(e.target.value)}
                ></input>
                <AnimatePresence mode="wait">
                  <CharactersLimit
                    text={reviewTitle}
                    minLength={TITLE_LENGTH_MIN}
                    maxLength={TITLE_LENGTH_MAX}
                  />
                </AnimatePresence>
              </div>
              <div className="label" style={{ marginTop: 40 }}>
                Review
              </div>
              <div style={{ position: "relative" }}>
                <textarea
                  className="input_box"
                  value={reviewText}
                  // style={{ height: 434 }}
                  onChange={(e) => setReviewText(e.target.value)}
                ></textarea>
                <AnimatePresence mode="wait">
                  <CharactersLimit
                    text={reviewText}
                    minLength={REVIEW_LENGTH_MIN}
                    maxLength={maxReviewLength}
                  />
                </AnimatePresence>
              </div>
            </div>
          </div>
        </div>
        <div className="actions">
          <FlowSolidButtonGrey
            onClick={() => {
              moveBack();
            }}
          >
            Back
          </FlowSolidButtonGrey>
          <FlowSolidButton
            disabled={disabledNext}
            onClick={() => {
              moveNext();
            }}
          >
            Next
          </FlowSolidButton>
        </div>
      </ReviewQuestionContainer>
    </FlowContainer>
  );
};

export default LowRatingInput;
