import { styled } from "@mui/material";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../../AppNavigation/constants";

const DepartmentGrid = styled("div")({
  width: "100%",
  display: "grid",
  gridTemplateColumns: `repeat(auto-fit, minmax(100px, 122px))`,
  columnGap: 20,
  rowGap: 40,
  [`@media screen and (max-width: ${
    (TABLET_SCREEN_SIZE + MOBILE_SCREEN_SIZE) / 2
  }px)`]: {
    columnGap: 10,
  },
});

export default DepartmentGrid;
