import { forwardRef } from "react";
import { Link, LinkProps, useLocation } from "react-router-dom";

/**
 * Represents a custom link component for the application navigation.
 * When the link is clicked and the current location is the same as the link's destination,
 * the window will scroll to the top of the page.
 */
const AppLinkComponent = (
  { onClick, ...props }: LinkProps,
  ref: React.ForwardedRef<HTMLAnchorElement>
) => {
  const location = useLocation();

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (location.pathname === props.to) {
      window.scrollTo(0, 0);
    }
    return onClick?.(e);
  };

  return <Link {...props} ref={ref} onClick={handleClick} />;
};

const AppLink = forwardRef(AppLinkComponent);
export default AppLink;
