import React from "react";

import { NavButton } from "./NavSearchButton";
import { StoreShoppingBagIcon } from "../../StoreComponents/StoreShoppingBagIcon";
import { MobileNavButton } from "../MobileNav";

type NavButtonProps = React.ComponentProps<typeof NavButton>;
type MobileNavButtonProps = React.ComponentProps<typeof MobileNavButton>;
interface NavCartButtonProps {
  onClick?: NavButtonProps["onClick"];
  id?: NavButtonProps["id"];
  count?: number;
}

interface MobileNavCartButtonProps extends MobileNavButtonProps {
  count?: number;
}

const NavCartButton = ({ onClick, id, count }: NavCartButtonProps) => {
  return (
    <NavButton id={id} onClick={onClick}>
      <StoreShoppingBagIcon sx={{ fontSize: 25 }} count={count} />
    </NavButton>
  );
};

export const MobileNavCartButton = ({
  count,
  ...props
}: MobileNavCartButtonProps) => {
  return (
    <MobileNavButton {...props}>
      <StoreShoppingBagIcon sx={{ fontSize: 25 }} count={count} />
    </MobileNavButton>
  );
};

export default NavCartButton;
