import LottieInner, { LottieProps } from "react-lottie";
import Confetti from "../../assets/lottie/brandclub_success_confetti_v2.json";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

// @ts-ignore
const Lottie = LottieInner.default
  ? // @ts-ignore
    LottieInner.default
  : LottieInner;

const option = {
  loop: true,
  autoplay: true,
  animationData: Confetti,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const ConfettiContainer = styled(Box)({
  width: "98vw",
  maxWidth: 1000,
  aspectRatio: "1000/561",
  overflow: "visible",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  pointerEvents: "none",
  marginBottom: "25px",
  zIndex: 2,
});

export type StoreConfettiProps = Omit<LottieProps, "options">;

const StoreConfetti = (props: StoreConfettiProps) => {
  return <Lottie isClickToPauseDisabled {...props} options={option}></Lottie>;
};

export default StoreConfetti;
