import { useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import { LongCardList } from "../components/CardGrid";
import { PreviewHeader } from "../components/Header";
import LongReviewCard from "./components/LongReviewCard";
import { useFetchReviews } from "./data";
import PreviewContainer from "../components/PreviewContainer";
import { useLinkPrefix } from "../../../../../utils/hooks/useLinkPrefix";

interface ReviewEmptyRewardPreviewProps {
  brandIds?: number[];
}
const ReviewEmptyRewardPreview = (props: ReviewEmptyRewardPreviewProps) => {
  const ref = useRef(null);
  const widgetInView = useInView(ref, { once: true, margin: "100px" });
  const { linkPrefix } = useLinkPrefix();
  const { data, loading, fetchInit } = useFetchReviews({
    pageSize: 5,
    autoInitFetch: false,
    brandIds: props.brandIds,
  });

  useEffect(() => {
    if (widgetInView) {
      fetchInit();
    }
  }, [widgetInView, fetchInit]);

  if (!loading && data.length === 0) {
    return null;
  }
  return (
    <>
      <PreviewContainer ref={ref}>
        {loading ? (
          <StoreLoadingSkeleton header cardStyle="longCardList" />
        ) : (
          <>
            <PreviewHeader
              title={"Product reviews"}
              linkTo={linkPrefix + "/productreviews"}
              subtitle="Share feedback by writing reviews"
              indicator="grey"
            />

            <LongCardList>
              {data.map((d) => (
                <LongReviewCard product={d} key={d.stacklineSku} />
              ))}
            </LongCardList>
          </>
        )}
      </PreviewContainer>
    </>
  );
};

export default ReviewEmptyRewardPreview;
