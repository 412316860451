import { decodeHTMLCharacters, formatMoney } from "@stackline/ui";

import {
  AnalyticsTrackingEvent,
  CampaignType,
  RetailerIconGroup,
  RewardPillWithText,
  SuccessCheckCircleIcon,
  changeAmazonImageUrlResolution,
  getAppConsistentParams,
  getProductUrl,
  FallbackImage,
} from "@brandclub/common-ui";
import { styled, useMediaQuery, useTheme } from "@mui/material";
import _get from "lodash/get";
import { Link } from "react-router-dom";
import {
  ProductRewardSearchResult,
  UserRewardStatus,
} from "../../../../../../types/search";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../../AppNavigation/constants";
import {
  OutlineButton,
  SolidButton,
} from "../../../../../StoreComponents/StoreButton";
import ShareProductDialog from "../../../../../StoreComponents/StoreDialogs/ShareProductDialog";

import { useUserLogin } from "../../../../Auth/UserLoginProvider";

const ProductCard = styled("div")(({ theme }) => ({
  border: "1px solid #dcdddc",
  width: "100%",
  height: "100%",
  borderRadius: 12,
  padding: `18px 18px 26px 18px`,
  cursor: "pointer",
  boxSizing: "border-box",
  transition: "background-color 0.4s ease-in-out",
  "*": {
    mixBlendMode: "multiply",
  },
  "&:hover": {
    backgroundColor: "#F6F8FA",
  },
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    padding: 10,
  },
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    width: "100%",
  },
  ".reward_info": {
    display: "flex",
    justifyContent: "flex-end",
    "*": {
      mixBlendMode: "unset",
    },
  },
  ".image": {
    // width: `calc(100% - 24px)`,
    width: `60%`,
    maxWidth: 124,
    maxHeight: 124,
    aspectRatio: "1/1",
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: `4px auto 20px`,
    img: {
      maxHeight: "100%",
      maxWidth: "100%",
      aspectRatio: "1",
      objectFit: "contain",
    },
  },
  ".title": {
    textDecoration: "none",
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 500,
    marginTop: 10,
    width: "100%",
    overflow: "hidden",
    letterSpacing: 0.36,
    WebkitLineClamp: 3,
    lineHeight: 1.5,
    height: `4.5em`, // line height * 3
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-word",
    [`@media screen and (max-width: ${1250}px)`]: {
      fontSize: 12,
    },
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 12,
      WebkitLineClamp: 2,
      height: `3em`,
    },
  },
  ".price": {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 600,
    marginTop: 10,
    marginBottom: 10,
    display: "flex",
    columnGap: 14,
    alignItems: "center",
    ".retailer-logos": {
      display: "flex",
      "*": {
        mixBlendMode: "unset",
      },
    },
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      columnGap: 7,
      fontSize: 16,
    },
  },
}));

const SoldPrecentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: 18.4,
  ".text": {
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.primary.main,
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 10,
    },
  },
  ".bar_bg": {
    borderRadius: 10,
    overflow: "hidden",
    margin: "0 10px",
    flexDirection: "row",
    height: 12.7,
    flex: 1,
    display: "flex",
    backgroundColor: "#f3f4f5",
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      height: 11,
    },
  },
  ".bar_progress": {
    backgroundColor: "#98A5B9",
  },
}));

const SoldPrecent = ({ percentSold }: { percentSold: number }) => {
  return (
    <SoldPrecentContainer>
      <div className="text">Sold</div>
      <div className="bar_bg">
        <div className="bar_progress" style={{ flex: percentSold / 100 }}></div>
      </div>
      <div className="text">{percentSold}%</div>
    </SoldPrecentContainer>
  );
};

const BuyButton = styled(SolidButton)({
  width: "100%",
  height: 38.5,
  fontSize: 14,
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    height: 38.5,
  },
});

const EarnedButton = styled(OutlineButton)({
  width: "100%",
  height: 34,
  fontSize: 14,
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    height: 34,
  },
});

const DefaultShareContainer = styled("div")(({ theme }) => ({
  width: 30,
  height: 30,
  backgroundColor: theme.palette.primary.main,
  cursor: "pointer",
  borderRadius: "999px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [`@media screen and (max-width: ${1250}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    width: 23,
    height: 23,
  },
  img: {
    width: "50%",
    height: "50%",
    objectFit: "contain",
  },
}));

const ShareButton = ({
  onClick,
}: {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}) => {
  return (
    <DefaultShareContainer onClick={onClick}>
      <img
        src="https://media.brandclub.com/brandclub/image_asset/affiliate-reward-icon.webp"
        alt=""
      ></img>
    </DefaultShareContainer>
  );
};

export const DealCard = ({
  product,
}: {
  product: ProductRewardSearchResult;
}) => {
  const {
    affiliateRewardAmount,
    hasActiveInstantReward,
    matchedProducts,
    retailerId,
    retailPrice,
    stacklineSku,
    title,
    dailyDealRewardInfo: {
      rewardAmount = 0,
      userRewardStatus,
      totalRewardCount,
      availableRewardCount,
    } = {},
  } = product;

  const isLoggedOut = !useUserLogin().signedIn;
  const urlSearchParams = getAppConsistentParams(window.location.search);
  const targetUrl = getProductUrl({ title, stacklineSku, urlSearchParams });
  const DESIRED_AMAZON_IMAGE_SIZE = 300; // pixels
  const img = changeAmazonImageUrlResolution(
    _get(product, ["imageUrls", 0])!,
    DESIRED_AMAZON_IMAGE_SIZE
  );
  const cleanProductTitle = decodeHTMLCharacters(title || "");

  const rewardPercent = retailPrice ? (rewardAmount * 100) / retailPrice : 0;

  const matchedProductsRetailerIds = (matchedProducts ?? []).map(
    (matchedProduct) => matchedProduct.retailerId
  );
  const retailerIds = [retailerId, ...matchedProductsRetailerIds];

  const theme = useTheme();
  const smBreakpoint = theme.breakpoints.values.sm;
  const tabletView = useMediaQuery(`(min-width: ${smBreakpoint}px)`);
  const rewardTextBreakPointHit = useMediaQuery(`(min-width: ${1380}px)`);
  const mobileView = !tabletView;

  const percentSold = (() => {
    if (
      userRewardStatus === UserRewardStatus.Unavailable ||
      availableRewardCount === 0 ||
      totalRewardCount === 0
    ) {
      return 100;
    } else if (availableRewardCount && totalRewardCount) {
      return Math.round(
        ((totalRewardCount - availableRewardCount) / totalRewardCount) * 100
      );
    }
    return 0;
  })();

  const handleShare: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    ShareProductDialog.show(product as any, isLoggedOut);
  };

  return (
    <Link to={`${targetUrl}`} style={{ textDecoration: "none" }}>
      <ProductCard className={"card-link"}>
        <div className="reward_info" style={{ height: 30 }}>
          {affiliateRewardAmount ? (
            <RewardPillWithText
              onPillClick={handleShare}
              rewardAmount={affiliateRewardAmount ?? 0}
              rewardCampaignType={CampaignType.AffiliateRewardCampaign}
              containerStyle={{
                justifyContent: "flex-end",
                alignItems: "flex-start",
              }}
              baseFontSize={11}
            />
          ) : (
            <ShareButton onClick={handleShare} />
          )}
        </div>

        <div className="image">
          <FallbackImage
            src={img}
            fallbackImage={img.replace(
              "media.brandclub.com",
              "media-api.brandclub.com"
            )}
            alt={title}
          />
        </div>
        <div className="reward_info" style={{ justifyContent: "flex-start" }}>
          <RewardPillWithText
            rewardAmount={rewardAmount ?? 0}
            rewardCampaignType={
              hasActiveInstantReward
                ? CampaignType.DailyDealsRewardCampaign
                : CampaignType.PurchaseRewardCampaign
            }
            rewardPercent={rewardPercent}
            rewardPercentText={
              rewardTextBreakPointHit ? "instant reward" : "reward"
            }
            containerStyle={mobileView ? { columnGap: 8 } : undefined}
            baseFontSize={mobileView ? 11 : 12}
            pillContainerStyle={
              mobileView ? {} : { paddingTop: "3px", paddingBottom: "3px" }
            }
          />
        </div>

        <div className="title">{cleanProductTitle}</div>
        <div className="price">
          <div>{formatMoney(retailPrice ?? 0)}</div>
          <RetailerIconGroup
            retailerIds={retailerIds}
            maxSize={5}
            iconSize={mobileView ? 15 : 23}
          />
        </div>
        {userRewardStatus === UserRewardStatus.Unavailable ? (
          <BuyButton
            trackedEventName={
              AnalyticsTrackingEvent.DAILY_DEALS_PRODUCT_CARD_CLICK
            }
            trackedAdditionalData={{
              product,
              cta: "Sold out",
            }}
            disabled
          >
            Sold out
          </BuyButton>
        ) : null}
        {userRewardStatus === UserRewardStatus.Claimed ? (
          <EarnedButton
            trackedEventName={
              AnalyticsTrackingEvent.DAILY_DEALS_PRODUCT_CARD_CLICK
            }
            trackedAdditionalData={{
              product,
              cta: "You Bought This",
            }}
          >
            <SuccessCheckCircleIcon className="button_img" />
            You Bought This
          </EarnedButton>
        ) : null}
        {userRewardStatus === UserRewardStatus.Available ? (
          <BuyButton
            trackedEventName={
              AnalyticsTrackingEvent.DAILY_DEALS_PRODUCT_CARD_CLICK
            }
            trackedAdditionalData={{
              product,
              cta: "Buy",
            }}
          >
            Buy
          </BuyButton>
        ) : null}
        <SoldPrecent percentSold={percentSold} />
      </ProductCard>
    </Link>
  );
};
