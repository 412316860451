import { MOBILE_SCREEN_SIZE } from "../AppNavigation/constants";
import { styled } from "@mui/material/styles";
import StoreMessageCard, {
  StoreMessageCardProps,
} from "../StoreComponents/StoreCard.tsx/StoreMessageCard";
import { FC } from "react";
import { useAppSelector } from "../../redux/hooks";
import { StoreBrandingType } from "../../types/misc";

export const StyledStoreMesssageCard = styled(StoreMessageCard)({
  width: "100%",
  maxWidth: "880px",
  padding: "35px 23px",

  ".store-message-card__cta": {
    width: "100%",
    maxWidth: 355,
    marginTop: 20,
  },

  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    ".store-message-card__cta": {
      marginTop: 10,
    },
  },
});

export const EmptyBagMessageCard: FC<Partial<StoreMessageCardProps>> = (
  props
) => {
  const appConfig = useAppSelector(({ appConfig }) => appConfig);

  const linkTo =
    appConfig?.domainConfig?.storeBrandingType ===
      StoreBrandingType.CustomDTCStore &&
    appConfig?.domainConfig?.customShopDomainName
      ? `https://${appConfig?.domainConfig?.customShopDomainName}/`
      : `/trending`;

  return (
    <StyledStoreMesssageCard
      title="Your bag is empty."
      content="Can we make some suggestions to help get you started?"
      cta="Shop popular products"
      linkTo={linkTo}
      {...props}
    />
  );
};
