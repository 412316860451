import { gql } from "@apollo/client";

export const getBrandSpotlight = gql`
  query getSpotlight {
    BrandSpotlight {
      name
      imageUrl
      brandclubId
      brandId
      trendingProducts {
        stacklineSku
        title
        brandId
        retailerId
        retailerSku
        retailPrice
        totalRewardAmount
        scores {
          maxPurchaseRewardPercent
          maxPurchaseRewardAmount
          maxAffiliateRewardPercent
          maxAffiliateRewardAmount
          totalReviewRewardFeesAmount
        }
        hasActiveInstantReward
        affiliateRewardAmount
        activeProductPromotion {
          promoType
          isActive
          listPrice
          promoPrice
        }
        campaignRewards {
          campaignId
          campaignType
          rewardAmount
          rewardAmountType
          campaignStatus
        }
        retailerProductPageUrl
        childProductMinRetailPrice
        childProductMaxRetailPrice
      }
    }
  }
`;
