import { useEffect, useContext } from "react";
import Loading from "../../Loading";
import { useAppSelector } from "../../../redux/hooks";
import { StoreBrandingType } from "../../../types/misc";
import { UserLoginContext } from "./UserLoginProvider";
import { useNavigate } from "react-router-dom";

export default function SignOutRedirect() {
  const navigate = useNavigate();
  const appConfig = useAppSelector((state) => state.appConfig);
  const { send } = useContext(UserLoginContext);
  const linkTo =
    appConfig?.domainConfig?.storeBrandingType ===
      StoreBrandingType.CustomDTCStore &&
    appConfig?.domainConfig?.customShopDomainName
      ? `https://${appConfig?.domainConfig?.customShopDomainName}/`
      : `/`;

  useEffect(() => {
    if (linkTo) {
      if (linkTo.startsWith("http")) {
        window.location.href = linkTo;
      } else {
        send({
          type: "SIGN_OUT",
          redirectPath: {
            pathname: linkTo,
          },
        });
        navigate(linkTo);
      }
    }
  }, [linkTo, navigate]);

  return <Loading fullscreen dot />;
}
