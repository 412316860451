import { createAsyncThunk } from "@reduxjs/toolkit";
import reduxApolloClient from "../../reduxApolloClient";
import { getCustomerSpendByBrandGQL } from "../../../api";
import ConfigUtils from "../../../utils/ConfigUtils";
import moment from "moment";

export const getCustomerSpendByBrand = createAsyncThunk(
  "/customerSpendByBrand/getCustomerSpendByBrand",
  async () => {
    return await reduxApolloClient.query({
      query: getCustomerSpendByBrandGQL,
      fetchPolicy: "no-cache",
      variables: {
        page: {
          start: 0,
          size: 1000, // Note: if a user has > 1000 brands, they may not be able to take some of the surveys
          // As of 4-14-22, max # of brands in 60 days was ~750 --- this should give us wiggle room
        },
        orderDate: {
          min: Math.round(
            moment().subtract(180, "days").toDate().getTime() / 1000
          ),
        },
      },
      context: {
        uri: `${ConfigUtils.getGraphQlUri()}?id=getCustomerSpendByBrand`,
      },
    });
  }
);
