import React from "react";
import { SuccessCheckCircleIcon } from "@brandclub/common-ui";

export const SignUpCard = ({ text, placeholderLength } : { text: string, placeholderLength: number }) => {

  return (
    <div
      style={{
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        backgroundColor: "#fff",
        padding: '13px 25px 16px 13px',
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        maxWidth: 316,
      }}
    >
      <div className="body_left">
        <h4
          style={{
            margin: "unset",
            fontSize: 12,
            fontWeight: 500,
            marginBottom: 10,
          }}
        >{text}</h4>
        <span className="loading-box"
          style={{
            width: placeholderLength,
            height: 20,
            display: "block",
            backgroundColor: "#f0f0f0",
          }}
        />
      </div>
      <div className="body_right"
        style={{
          flexGrow: 1,
          display: 'flex',
        }}
      >
        <SuccessCheckCircleIcon
          style={{
            width: 32,
            height: 32,
            marginLeft: 'auto',
          }}
        />
      </div>
    </div>
  )
}
