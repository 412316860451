import { getAuthorizationHeader } from "../../../Auth";
import ConfigUtils from "../../../utils/ConfigUtils";
import axios, { axiosRetailerSync } from "../../../utils/axios";

export const suggestProductFeaturesToReviewAsStream = async (
  retailerId: number,
  retailerSku: string,
  onErrorCallBack: (error: {
    errorMessage: string;
    responseData?: string;
  }) => void,
  onProgressCallBack: (
    feedbackQuestions: {
      question: string;
      questionType: "features_you_like" | "features_for_improvement";
      options: string[];
    }[]
    // index: number,
  ) => void,
  onCompleteCallBack: (
    feedbackQuestions: {
      question: string;
      type: "features_you_like" | "features_for_improvement";
      options: string[];
    }[]
  ) => void
) => {
  const url = `${ConfigUtils.getApiHostName()}/product/suggestProductFeaturesToReviewAsStream`;
  const response = await fetch(url, {
    method: "post",
    body: JSON.stringify({
      productId: {
        retailerId,
        retailerSku,
      },
    }),
    headers: {
      ...(await getAuthorizationHeader()),
      "content-type": "application/json",
    } as any,
  });
  const reader = response.body?.getReader();
  if (reader) {
    const textEncoder = new TextDecoder();
    let jsonResponseString = "";
    let maxQuestions = 0;
    const processResult: any = (
      result: ReadableStreamReadResult<Uint8Array>
    ) => {
      if (result.done) {
        jsonResponseString = jsonResponseString.trim();
        if (jsonResponseString.endsWith("[DONE]")) {
          jsonResponseString = jsonResponseString.substring(
            0,
            jsonResponseString.length - "[DONE]".length
          );
          onCompleteCallBack(JSON.parse(jsonResponseString));
        } else if (jsonResponseString.endsWith("[ERROR]")) {
          jsonResponseString = jsonResponseString.substring(
            0,
            jsonResponseString.length - "[ERROR]".length
          );
          onErrorCallBack({
            errorMessage: "Failed unexpectedly",
            responseData: jsonResponseString,
          });
        }
        return;
      }
      try {
        const message = textEncoder
          .decode(result.value)
          .replace(/(?:\r\n|\r|\n)/g, "");
        jsonResponseString += message;
        // check if there are end brackets so that we can build a valid json
        if (jsonResponseString.lastIndexOf("}") > 0) {
          try {
            const questions = JSON.parse(
              jsonResponseString.substring(
                0,
                jsonResponseString.lastIndexOf("}") + 1
              ) + "]"
            );
            // we dont want to do a callback with the same question multiple times
            if (questions.length > maxQuestions) {
              maxQuestions = questions.length;

              onProgressCallBack(questions);
            }
          } catch {
            console.error("failed to parse json:", jsonResponseString);
          }
        }
      } catch (error: any) {
        onErrorCallBack({
          errorMessage: error?.message,
        });
      }
      return reader.read().then(processResult);
    };
    reader.read().then(processResult);
  }
};

export const generateShortProductReview = async ({
  req,
}: {
  req: {
    userProductStarRating: number;
    productId: {
      retailerId: number;
      retailerSku: string;
    };
    userProductFeedback: {
      question: string;
      selectedOption?: string[];
      selectedOptions?: string[];
    };
  };
}) => {
  const res = await axios.post("/product/generateShortProductReview", req, {
    headers: {
      ...(await getAuthorizationHeader()),
    },
  });
  return res?.data;
};

export const saveReviewAttempt = async (productReview: {
  purchaseRetailerId: number;
  purchaseRetailerSku: string;
  reviewStarRating: number;
  reviewTitle: string;
  reviewText: string;
  extendedAttributes: {
    publishedStateByRetailer: {
      [retailerId: string]: {
        status: string;
        error?: string;
      };
    };
  };
}): Promise<any> => {
  return await axios.post(
    `/customerProductReview/saveReviewAttempt`,
    productReview,
    {
      headers: {
        ...(await getAuthorizationHeader()),
      },
    }
  );
};

export const publishReview = async (productReview: {
  retailerId: number;
  productId: string;
  action: "publishReview";
  parentRetailerSku: string;
  reviewInfo: {
    reviewStarRating: number;
    reviewText: string;
    reviewTitle: string;
    product: {
      brandName?: string;
      purchaseRetailerId?: number;
      purchaseRetailerSku?: string;
      brandId?: number;
      retailerId: number;
      stacklineSku?: string;
      title?: string;
      retailerSku: string;
      retailerParentSku?: string;
      purchaseOrderId?: string;
    };
  };
}): Promise<any> => {
  return await axiosRetailerSync.post(
    `/productReview/publishReview`,
    productReview,
    {
      headers: {
        ...(await getAuthorizationHeader()),
      },
    }
  );
};
