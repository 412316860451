import "./App.css";
import AppRoutes from "./components/AppRoutes";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "./redux/hooks";
import {
  getAppConfig,
  getBrandAppConfigs,
} from "./redux/reducers/appConfig/thunk";
import axiosInstance from "./utils/axios";
import { getAuthorizationHeader, initializeAuth } from "./Auth";
import StoreFullScreenLoading from "./components/StoreComponents/StoreFullScreenLoading";
import { FingerprintJSProvider } from "@brandclub/common-ui";
import {
  buildAppInstallationInfo,
  getLocationWithoutPermissionPromptAsync,
} from "./utils/appInstallationUtils";

export const updateAppInstallationInfo = async (appInstallationInfo: {
  deviceInfo: any;
  appInfo: any;
}): Promise<any> => {
  const headers: Record<any, any> = {
    ...(await getAuthorizationHeader()),
  };
  const res = await axiosInstance.post(
    "/appInstallation/update",
    appInstallationInfo,
    {
      headers,
    }
  );

  const { status, payload } = res.data || {};
  return status === "success" ? payload : undefined;
};

const App = () => {
  const appConfig = useAppSelector(({ appConfig }) => appConfig);
  const userId = useAppSelector(({ userProfile }) => userProfile?.userId);
  const [isAuthInitialized, setIsAuthInitialized] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      if (appConfig?.authConfig) {
        await initializeAuth(appConfig?.authConfig);
        setIsAuthInitialized(true);

        try {
          if (userId) {
            // const locationInfo = await getLocationWithoutPermissionPromptAsync(); // This currently asks the browser for permission to access the user's location
            const appInstallationInfo = await buildAppInstallationInfo({ userId });
            if (appInstallationInfo?.appInstallationInfo) {
              await updateAppInstallationInfo(appInstallationInfo.appInstallationInfo);
            }
          }
        } catch (e) {
          console.error(
            "An error occurred when getting locationInfo and appInstallationInfo: ",
            e
          );
        }
      } else {
        dispatch(getAppConfig({}) as any);
      }
    })();
  }, [appConfig, userId]);

  useEffect(() => {
    dispatch(
      getBrandAppConfigs({ appId: appConfig?.domainConfig?.domainName })
    );
  }, [dispatch, appConfig?.domainConfig?.domainName]);

  // we should let the app load until we have the app config and cognito initialized
  if (!appConfig?.authConfig) {
    return <div data-testid="store-loading-skeleton-fullsite-content" />;
  }

  if (!isAuthInitialized) {
    return <StoreFullScreenLoading />;
  }

  return (
    <FingerprintJSProvider
      hostDomainName={`https://${window.location.hostname}`}
    >
      <AppRoutes />
    </FingerprintJSProvider>
  );
};

export default App;
