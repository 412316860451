import { getCurrentReferralRewardCampaigns } from "../../api/graphQl/authenticated/ReferralReward";
import ConfigUtils from "../../utils/ConfigUtils";
import { setReferralReward } from "../reducers/referralReward";
import reduxApolloClient from "../reduxApolloClient";
import { AppDispatch } from "../store";

/* --------------------- referralReward ---------------------*/

export const loadReferralReward = () => async (dispatch: AppDispatch) => {
  try {
    const { data } = await reduxApolloClient.query({
      query: getCurrentReferralRewardCampaigns,
      fetchPolicy: "no-cache",
      context: {
        uri: `${ConfigUtils.getGraphQlUri()}?id=getCurrentReferralRewardCampaigns`,
      },
    });
    if (data && data.CurrentReferralRewardCampaigns) {
      const amountForInstallingUser =
        data?.CurrentReferralRewardCampaigns?.[0]?.extendedAttributes?.reward
          .rewardAmountForInstaller || null;
      const amountForReferringUser =
        data?.CurrentReferralRewardCampaigns?.[0]?.extendedAttributes?.reward
          .rewardAmountForReferrer || null;
      dispatch(
        setReferralReward({ amountForInstallingUser, amountForReferringUser })
      );
    }
  } catch (e) {
    console.error("loadReferralReward error: ", e);
  }
};
