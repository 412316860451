import { styled } from "@mui/material";
import { MOBILE_SCREEN_SIZE } from "../../../../AppNavigation/constants";
import {
  PREVIEW_WIDGET_VERTICAL_PADDING,
  PREVIEW_WIDGET_VERTICAL_PADDING_MOBILE,
} from "./Constant";

const VISUALIZATION_WIDGET_VERTICAL_ADJUSTMENT = 3;

const PreviewContainer = styled("div")({
  width: "100%",
  padding: `${
    PREVIEW_WIDGET_VERTICAL_PADDING - VISUALIZATION_WIDGET_VERTICAL_ADJUSTMENT
  }px 0 ${
    PREVIEW_WIDGET_VERTICAL_PADDING + VISUALIZATION_WIDGET_VERTICAL_ADJUSTMENT
  }px 0`,
  [`@media screen and (max-width: ${1250}px)`]: {
    padding: `${
      PREVIEW_WIDGET_VERTICAL_PADDING_MOBILE -
      VISUALIZATION_WIDGET_VERTICAL_ADJUSTMENT
    }px 0 ${
      PREVIEW_WIDGET_VERTICAL_PADDING_MOBILE +
      VISUALIZATION_WIDGET_VERTICAL_ADJUSTMENT
    }px 0`,
  },
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    padding: `${
      PREVIEW_WIDGET_VERTICAL_PADDING_MOBILE -
      VISUALIZATION_WIDGET_VERTICAL_ADJUSTMENT
    }px 0 ${
      PREVIEW_WIDGET_VERTICAL_PADDING_MOBILE +
      VISUALIZATION_WIDGET_VERTICAL_ADJUSTMENT
    }px 0`,
  },
});

export default PreviewContainer;
