import { LandingHeader } from "../components/Header";
import { useFetchAccount } from "./data";
import { CardGridForFour } from "../components/CardGrid";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import RetailerCard from "./components/RetailerCard";
import LandingContainer, {
  LandingContainerProps,
} from "../components/LandingContainer";

const AccountsUnconnectPreview = (props: LandingContainerProps) => {
  const { loading, newRetailers } = useFetchAccount({});

  if (!loading && newRetailers.length === 0) {
    return null;
  }
  return (
    <LandingContainer {...props}>
      {loading ? (
        <StoreLoadingSkeleton header row={2} />
      ) : (
        <>
          <LandingHeader
            title="Connect new accounts"
            subtitle="Earn more rewards from your purchases"
          ></LandingHeader>
          <CardGridForFour style={{ marginTop: 20 }}>
            {[...newRetailers].map((r) => {
              return (
                <RetailerCard key={r.retailerId} retailer={r}></RetailerCard>
              );
            })}
          </CardGridForFour>
        </>
      )}
    </LandingContainer>
  );
};

export default AccountsUnconnectPreview;
