import { useState, useCallback, useRef, useEffect } from "react";

/**
 * A hook that returns a loading state that's updated whenever the provided promise is resolved or rejected.
 */

function useWithLoading<
  T extends (...args: Parameters<T>) => Promise<Awaited<ReturnType<T>>>
>(asyncFunction: T): [T, boolean] {
  const [loading, setLoading] = useState(false);
  const asyncFunctionRef = useRef(asyncFunction);

  // trick to keep the latest asyncFunction reference without causing re-renders
  useEffect(() => {
    asyncFunctionRef.current = asyncFunction;
  }, [asyncFunction]);

  const withLoading = useCallback(
    async (...args: Parameters<T>): Promise<ReturnType<T>> => {
      setLoading(true);
      try {
        const result = await asyncFunctionRef.current(...args);
        return result;
        // don't catch errors here, let the caller handle them
      } finally {
        // always set loading back to false when the promise is resolved or rejected
        setLoading(false);
      }
    },
    []
  ) as T;

  return [withLoading, loading];
}

export default useWithLoading;
