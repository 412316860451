import OnBoardingConnect from "../pages/Auth/Connect";
import OnBoardingConnectRetailer from "../pages/Auth/OnBoardingConnectRetailer";
import OnBoardingJoinClub from "../pages/Auth/OnBoardingJoinClub";
import ConnectRetailerInProgress from "../pages/Auth/Connect/ConnectRetailerInProgress";
import ConnectRetailerOTP from "../pages/Auth/Connect/ConnectRetailerOTP";
import { OnBoardingSuccess } from "../pages/Auth/OnBoardingSuccess";
import SignIn from "../pages/Auth/SignIn";
import SignInVerify from "../pages/Auth/SignInVerify";
import { StoreNavAction } from "../StoreComponents/StoreSinglePageOutlet";
import { AppRoutesWithChildrenProps } from "./routeBuilder";
import SetupUserProfile from "../pages/Auth/SetupUserProfile";
import FindAccount from "../pages/Auth/updatePhone/FindAccount";
import VerifyEmail from "../pages/Auth/updatePhone/VerifyEmail";

export const authRoutes: AppRoutesWithChildrenProps[] = [
  {
    path: "signin",
    element: <SignIn />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.ToShopDomain,
      },
    },
    pageType: "Auth",
    pageId: "SignIn",
  },
  {
    path: "signinverify",
    element: <SignInVerify />,
    handle: {
      pageNavConfig: {
        back: StoreNavAction.SentBack,
        close: StoreNavAction.ToShopDomain,
      },
    },
    pageType: "Auth",
    pageId: "SignInVerify",
  },
  {
    path: "findAccount",
    element: <FindAccount />,
    handle: {
      pageNavConfig: {
        back: StoreNavAction.SentBack,
        close: StoreNavAction.ToShopDomain,
      },
    },
    pageType: "Auth",
    pageId: "FindAccount",
  },
  {
    path: "verifyEmail",
    element: <VerifyEmail />,
    handle: {
      pageNavConfig: {
        back: StoreNavAction.SentBack,
        close: StoreNavAction.ToShopDomain,
      },
    },
    pageType: "Auth",
    pageId: "VerifyEmail",
  },
  {
    path: "updatePhoneNumber",
    element: <SignIn isUpdatePhoneFlow />,
    handle: {
      pageNavConfig: {
        back: StoreNavAction.SentBack,
        close: StoreNavAction.ToShopDomain,
      },
    },
    pageType: "Auth",
    pageId: "UpdatePhoneNumber",
  },
  {
    path: "setupuserprofile",
    element: <SetupUserProfile />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.SignOut,
      },
    },
    pageType: "Auth",
    pageId: "SetupUserProfile",
  },
  {
    path: "joinbrandclubs",
    element: <OnBoardingJoinClub />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.SignOut,
      },
    },
    pageType: "OnBoarding",
    pageId: "JoinBrandclubs",
  },
  {
    path: "connectretailers",
    element: <OnBoardingConnectRetailer />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.SignOut,
      },
    },
    pageType: "OnBoarding",
    pageId: "ConnectRetailers",
  },
  {
    path: "authenticatewithretailer",
    element: <OnBoardingConnect />,
    pageType: "OnBoarding",
    pageId: "AuthenticateWithRetailer",
  },
  {
    path: "connectretailerinprogress",
    element: <ConnectRetailerInProgress />,
    pageType: "ConnectRetailer",
    pageId: "ConnectRetailerInProgress",
  },
  {
    path: "connectretailerotp",
    element: <ConnectRetailerOTP />,
    pageType: "ConnectRetailer",
    pageId: "ConnectRetailerOTP",
  },
  {
    path: "success",
    element: <OnBoardingSuccess />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.ToShopDomain,
      },
    },
    pageType: "OnBoarding",
    pageId: "OnBoardingSuccess",
  },
];
