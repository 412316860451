import React, { useEffect } from "react";
import { Skeleton } from "@mui/material";
import {
  CampaignType,
  ProductRewardSearchResult,
  RewardPillWithText,
  getAppConsistentParams,
  getProductUrl,
  getPurchaseRewardAmountForProduct,
  FallbackImage,
} from "@brandclub/common-ui";
import { useNavigate } from "react-router-dom";
import { RewardUnlockStrategy } from "@brandclub/types";
import { Box, Typography, styled, useMediaQuery } from "@mui/material";
import { useCartProduct } from "@/components/Checkout/CheckoutMachine";
import { Link } from "react-router-dom";
import { formatMoney } from "../../../utils/StringUtils";
import {
  getDefaultProductImage,
  getFallbackProductImage,
} from "../../../utils/media";
import { ShoppingCartItem } from "../types";
import {
  addOptimisticShoppingCartItem,
  removeOptimisticShoppingCartItem,
  clearErrorState,
} from "@/redux/reducers/checkout";
import { MOBILE_SCREEN_SIZE } from "../../AppNavigation/constants";
import QuantityControl from "./QuantityControl";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { showBagFullDialog } from "./BagFullDialog";

const CartProductContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  textDecoration: "none",
  color: theme.palette.primary.main,

  alignItems: "center",
  gap: 15,

  ".product_image": {
    objectFit: "contain",
    width: "92px",
    height: "104px",
  },

  ".product_detail": {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",

    width: "100%",
    gap: 6,
    ".info": {
      display: "flex",
      flexDirection: "column",
      gap: 8,
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        gap: 4,
      },
      ".price": {
        display: "flex",
        alignItems: "center",
        fontSize: 16,
        fontWeight: 600,
        gap: 13,
        [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          gap: 11,
        },
      },
      ".name": {
        fontSize: 12,
        fontWeight: 500,
      },
    },
    ".actions": {
      display: "flex",
      gap: 50,
      alignItems: "center",
      ".quantity": {},
      ".remove": {
        fontSize: 14,
        fontWeight: 500,
        "&:hover": {
          cursor: "pointer",
          textDecoration: "underline",
        },
      },
    },
  },

  "&.large": {
    gap: 30,
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      gap: 25,
    },
    ".product_image": {
      width: 115,
      height: 115,
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        width: "92px",
        height: "104px",
      },
    },
    ".product_detail": {
      gap: 10,
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        gap: 6,
      },

      ".info": {
        ".price": {
          fontWeight: 600,
          fontSize: 16,
        },
        ".name": {
          fontWeight: 500,
          fontSize: 14,
        },
      },
      ".actions": {
        justifyContent: "space-between",

        ".remove": {
          fontWeight: 500,
          fontSize: 14,
          [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            fontSize: 12,
          },
        },
      },
    },
  },
}));

export const CartProduct = ({
  large = false,
  product,
  className,
}: {
  large?: boolean;
  product: ShoppingCartItem;
  className?: string;
}) => {
  const metadata =
    useCartProduct(product.stacklineSku) ??
    (product as ShoppingCartItem & ProductRewardSearchResult);
  // hasActiveInstantReward on metadata is not always set, so we need to check the campaignRewards as well
  const hasActiveInstantReward =
    !!metadata?.hasActiveInstantReward ||
    (!!metadata?.campaignRewards &&
      metadata?.campaignRewards.some(
        (campaignReward) =>
          campaignReward?.campaignType ===
            CampaignType.DailyDealsRewardCampaign ||
          (campaignReward &&
            "rewardUnlockStrategy" in campaignReward &&
            campaignReward.rewardUnlockStrategy ===
              RewardUnlockStrategy.Instant)
      ));
  const navigate = useNavigate();
  const rewardAmount = metadata && getPurchaseRewardAmountForProduct(metadata);
  const rewardPercent =
    !!metadata?.retailPrice && !!rewardAmount
      ? (rewardAmount * 100) / metadata.retailPrice
      : 0;
  const dispatch = useAppDispatch();
  const { checkout } = useAppSelector((state) => state);
  const error = checkout.errorState;
  const [dialogEvent, setDialogEvent] = React.useState("");

  useEffect(() => {
    if (error) {
      showBagFullDialog(navigate, window.location.pathname, (newState) => {
        setDialogEvent(newState.event);
      });
    }
  }, [error, navigate]);

  useEffect(() => {
    if (dialogEvent === "close") {
      dispatch(clearErrorState());
    }
  }, [dialogEvent, dispatch]);

  const handleAddQuantity = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    dispatch(
      addOptimisticShoppingCartItem({
        items: [{ ...product, quantity: 1 }],
        retailerId: product.retailerId,
        totalItems: 1,
      })
    );
  };

  const handleMinusQuantity = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (product.quantity - 1 <= 0) {
      onRemove();
      return;
    }
    dispatch(
      addOptimisticShoppingCartItem({
        items: [{ ...product, quantity: -1 }],
        retailerId: product.retailerId,
        totalItems: -1,
      })
    );
  };

  const onRemove = () => {
    dispatch(
      removeOptimisticShoppingCartItem({
        items: [{ ...product, quantity: -product.quantity }],
        retailerId: product.retailerId,
        totalItems: -product.quantity,
      })
    );
  };

  const isMobileView = useMediaQuery(`(max-width: ${MOBILE_SCREEN_SIZE}px)`);
  const urlSearchParams = getAppConsistentParams(window.location.search);
  const appConfig = useAppSelector(({ appConfig }) => appConfig);

  if (!metadata?.title) {
    return (
      <CartProductContainer>
        <Skeleton className='product_image'/>
        <Box className='product_detail'>
          <Box className='info'>
            <Skeleton className='price'/>
            <Skeleton className='name'/>
          </Box>
        </Box>
      </CartProductContainer>
    )
  }

  return (
    <Link
      to={getProductUrl({
        title: metadata.title,
        stacklineSku: metadata.stacklineSku,
        urlSearchParams,
        dtcProductPageUrl: metadata.dtcProductPageUrl,
        storeType: appConfig?.domainConfig?.storeBrandingType,
      })}
      style={{ textDecoration: "none" }}
      target="_blank"
    >
      <CartProductContainer
        className={[large && "large", className].filter(Boolean).join(" ")}
      >
        <Box>
          <FallbackImage
            title={metadata?.title ?? product.stacklineSku}
            className="product_image"
            src={getDefaultProductImage(product?.stacklineSku || "")}
            fallbackImage={getFallbackProductImage(product?.stacklineSku || "")}
          />
        </Box>
        <Box className={"product_detail"}>
          <Box className={"info"}>
            <Box className={"price"}>
              {formatMoney(metadata?.retailPrice ?? product.retailPrice)}
              {!!rewardAmount && (
                <RewardPillWithText
                  rewardAmount={rewardAmount}
                  rewardPercentText="reward"
                  baseFontSize={isMobileView ? 12 : 14}
                  pillContainerStyle={{
                    boxSizing: "border-box",
                    padding: isMobileView ? "2px 8.5px" : "3px 11.4px",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  rewardPercent={rewardPercent}
                  rewardCampaignType={
                    hasActiveInstantReward
                      ? CampaignType.DailyDealsRewardCampaign
                      : CampaignType.PurchaseRewardCampaign
                  }
                />
              )}
            </Box>
            <Typography
              className={"name"}
              title={metadata?.title}
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
              }}
            >
              {metadata?.title ?? "Unknown Product"}
            </Typography>
          </Box>
          <Box className={"actions"}>
            <Box className={"quantity"}>
              <QuantityControl
                large={large}
                count={product.quantity}
                onAddClick={handleAddQuantity}
                onMinusClick={handleMinusQuantity}
                trackedParams={product}
              />
            </Box>
            <div
              role="button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                onRemove();
              }}
              className="remove"
            >
              Remove
            </div>
          </Box>
        </Box>
      </CartProductContainer>
    </Link>
  );
};
