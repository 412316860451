import { styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { escapeUriPath } from "../../../../../../utils/StringUtils";

import {
  OutlineButtonWithArrow,
  SolidButtonWithArrow,
} from "../../../../../StoreComponents/StoreButton";
import StoreConfetti, {
  ConfettiContainer,
} from "../../../../../StoreComponents/StoreConfetti";
import FlowContainer from "../../components/FlowContainer";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../../AppNavigation/constants";

const FinishContainer = styled("div")(({ theme }) => ({
  maxWidth: 655,
  padding: "60px 20px",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
  flex: 1,
  ".title": {
    marginBottom: 9,
    fontSize: 32,
    fontWeight: 700,
    color: theme.palette.primary.main,
    textAlign: "center",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 24,
    },
  },
  ".subtitle": {
    maxWidth: 474,
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 30,
    color: theme.palette.primary.main,
    textAlign: "center",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 18,
    },
  },
}));
const Finish = ({ product }: { product: any }) => {
  const navigate = useNavigate();

  const brandName = product?.brandName;
  const brandId = product?.brandId;
  const shopBrand = () => {
    const path = `/${escapeUriPath(brandName)}/b/${brandId}/home`;
    navigate(path);
  };

  const returnHome = () => {
    navigate("/");
  };

  return (
    <FlowContainer>
      <FinishContainer>
        <ConfettiContainer>
          <StoreConfetti />
        </ConfettiContainer>
        <div className="title">Thank you</div>
        <div className="subtitle">
          We’re working on sending your review to the retailer.
        </div>
        <SolidButtonWithArrow
          style={{
            width: "100%",
            maxWidth: "349px",
            marginTop: "10px",
            marginBottom: "15px",
            height: "50px",
            fontSize: "16px",
          }}
          onClick={shopBrand}
        >
          Explore {brandName}
        </SolidButtonWithArrow>
        <OutlineButtonWithArrow
          style={{ width: "100%", maxWidth: 349, fontSize: 16, height: "50px" }}
          onClick={returnHome}
        >
          Return to home
        </OutlineButtonWithArrow>
      </FinishContainer>
    </FlowContainer>
  );
};

export default Finish;
