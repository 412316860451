import {
  FilterParam,
  SearchIcon,
  TuneIcon,
  brandclub_colors,
  FilterDialog,
  AnalyticsTrackingEvent,
} from "@brandclub/common-ui";
import { styled } from "@mui/material";
import { motion } from "framer-motion";
import {
  PropsWithChildren,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import { formatMoney } from "../../../../utils/StringUtils";
import { useOutsideClick } from "../../../../utils/hooks/useOutsideClick";
import { KeywordSuggestionsType } from "../../../AppNavigation/SearchDropdown";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../AppNavigation/constants";
import { useSiteContext } from "../../../SiteProvider";
import PreviewWidgetWrapper from "../Pages/components/PreviewWidgetWrapper";
import {
  getCombinedSearchTerms,
  useSearchAutoComplete,
} from "../../../AppNavigation/useSearchAutoComplete";
import axios from "../../../../utils/axios";
import { useTrackActions } from "../../../../utils/hooks/useTracking";
import { useUserLogin } from "../../Auth/UserLoginProvider";

const SEARCH_BAR_HEIGHT_DESKTOP = 65;
const SEARCH_BAR_HEIGHT_MOBILE = 46;

const HomeSearchBarContainer = styled("div")({
  width: "100%",
  marginTop: 0,
  padding: "30px 0",
  gap: 10,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    marginTop: 0,
  },
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    marginTop: 0,
    paddingTop: 0,
    paddingBottom: 15,
    gap: 10,
  },
});

const SearchBar = styled("div")(({ theme }) => ({
  width: "100%",
  position: "relative",
  boxSizing: "border-box",
  maxWidth: 783,
  transition: "all 0.4s ease-in-out",
  "&.focus": {
    maxWidth: 943,
  },
  ".input_area": {
    height: SEARCH_BAR_HEIGHT_DESKTOP,
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    background: brandclub_colors.white,
    borderRadius: SEARCH_BAR_HEIGHT_DESKTOP / 2,
    border: "1px solid ",
    borderColor: "#dcdddc",
    padding: "0 30px",
    boxShadow: "0 0px 0px 0 rgba(124, 115, 115, 0)",
    transition: "all 0.4s ease-in-out",
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      height: SEARCH_BAR_HEIGHT_MOBILE,
      borderRadius: SEARCH_BAR_HEIGHT_MOBILE / 2,
      padding: "0 15px",
    },
    ".filter_icon_container": {
      border: `1.5px solid`,
      borderRadius: 300,
      borderColor: brandclub_colors.gray,
      height: 32,
      opacity: 0.3,
      width: 32,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: "all 0.4s ease-in-out",
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        width: 26,
        height: 26,
        marginLeft: 8,
      },
    },
    ".filter_icon": {
      width: 14,
      height: 14,
      objectFit: "contain",
      transition: "all 0.4s ease-in-out",
      color: brandclub_colors.gray,
      cursor: "pointer",
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        width: 12,
        height: 12,
      },
    },
    ".search_icon": {
      width: 25,
      height: 25,
      objectFit: "contain",
      transition: "all 0.4s ease-in-out",
      marginRight: 15,
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        width: 18,
        height: 18,
        marginLeft: 8,
      },
    },
    "&.focus": {
      padding: "0 34px",
      borderColor: brandclub_colors.white,
      boxShadow: "0 2px 7px 0 rgba(124, 115, 115, 0.16)",
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        padding: "0 15px",
      },
      ".search_icon": {
        width: 18,
        height: 18,
        objectFit: "contain",
        marginRight: 20,
      },
      ".filter_icon_container": {
        opacity: 0,
      },
    },
    "&.open": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      input: {
        "&::placeholder": {
          fontSize: 16,
          fontWeight: 500,
          color: "#7e8fa8",
          opacity: 0.3,
          [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
          [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            fontSize: 12,
          },
        },
      },
    },

    input: {
      flex: 1,
      fontSize: 18,
      fontWeight: 500,
      color: theme.palette.primary.main,
      background: "none",
      width: "100%",
      outline: "none",
      border: "none",
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        marginTop: 0,
      },
      "&::placeholder": {
        transition: "all 0.4s ease-in-out",
        fontSize: 18,
        fontWeight: 500,
        color: "#7e8fa8",
        opacity: 0.3,
        [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 14,
        },
      },
      "&:focus": {
        fontSize: 16,
      },
    },
  },
  ".dropdown_area": {
    background: brandclub_colors.white,
    zIndex: 3,
    position: "absolute",
    width: "100%",
    minHeight: 0,
    height: 0,
    overflow: "hidden",

    boxSizing: "border-box",
    borderRadius: SEARCH_BAR_HEIGHT_DESKTOP / 2,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    // transition: "height 0.4s ease-in-out",
    boxShadow: "0 3px 6px 0 rgba(124, 115, 115, 0.16)",
    display: "flex",
    flexDirection: "column",
    listStyleType: "none",
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      borderRadius: SEARCH_BAR_HEIGHT_MOBILE / 2,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },

    ".divider": {
      height: 1,
      //   width: "100%",
      transition: "all 0s",
      opacity: 0,
      margin: "0 36px 10px 36px",
      borderTop: "1px solid #dcdddc",
      boxSizing: "border-box",
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        margin: "0 15px 10px 15px",
      },
    },
    "&.open": {
      minHeight: 10,
      height: "auto",
      ".divider": {
        transition: "all 0.4s",
        opacity: 1,
      },
    },

    ".list": {
      listStyle: "none",
      padding: "0px 0px 10px 0px",
      marginTop: 0,
      ".list_opt": {
        padding: "10px 36px",
        cursor: "pointer",
        fontSize: 16,
        fontWeight: 500,
        color: theme.palette.primary.main,

        [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          padding: "10px 18px",
          fontSize: 14,
        },
        "&:hover": {
          background: brandclub_colors.blueWhite,
        },
      },
    },
  },
}));

const WelcomeHeader = styled("div")({
  fontSize: 40,
  fontWeight: 700,

  textAlign: "center",
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    fontSize: 24,
  },
});

const WelcomeSubHeader = styled("div")({
  fontSize: 20,
  fontWeight: 500,
  maxWidth: 540,
  textAlign: "center",
  marginBottom: 20,
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    fontSize: 16,
    marginBottom: 10,
  },
});

const WelcomeText = () => {
  const { signedIn: isSignedIn } = useUserLogin();
  const userProfile = useAppSelector((status) => status?.userProfile);

  const given_name = userProfile?.given_name;
  const name = userProfile?.name;

  const nameDisplay = given_name || name;
  const reward = useAppSelector(({ rewards }) => rewards?.rewards);

  const {
    lifetimeEarnings = 0,
    lifetimePayouts = 0,
    lifetimeAdjustments = 0,
  } = reward;
  const rewardBalance =
    lifetimeEarnings - lifetimePayouts - lifetimeAdjustments;

  if (!isSignedIn) {
    return (
      <>
        <WelcomeHeader>{"Join the club"}</WelcomeHeader>
        <WelcomeSubHeader>
          {
            "Earn cash rewards when you shop and engage with your favorite brands."
          }
        </WelcomeSubHeader>
      </>
    );
  }
  // if (!rewardBalance && !lifetimeEarnings && !lifetimePayouts) {
  //   return (
  //     <>
  //       <WelcomeHeader>{`Welcome back${
  //         nameDisplay ? `, ${nameDisplay.trim()}` : ""
  //       }.`}</WelcomeHeader>
  //       <WelcomeSubHeader>
  //       You earned {formatMoney(lifetimeEarnings ?? 0)}. in total reward and
  //       received {formatMoney(lifetimePayouts)} in payouts, so your reward
  //       balance is {formatMoney(rewardBalance ?? 0)}.
  //       </WelcomeSubHeader>
  //     </>
  //   );
  // }
  return (
    <>
      <WelcomeHeader>{`Welcome back${
        nameDisplay ? `, ${nameDisplay.trim()}` : ""
      }`}</WelcomeHeader>
      <WelcomeSubHeader>
        You earned {formatMoney(lifetimeEarnings ?? 0)} in total rewards and
        received {formatMoney(lifetimePayouts)} in payouts, so your reward
        balance is {formatMoney(rewardBalance ?? 0)}.
      </WelcomeSubHeader>
    </>
  );
};

type HomeSearchBarInputProps = PropsWithChildren<{
  className?: string;
  placeholder?: string;
}>;
export const HomeSearchBarInput = (props: HomeSearchBarInputProps) => {
  const brandEntity = useAppSelector(({ brandEntity }) => brandEntity);
  const [searchParams] = useSearchParams();

  const [focusInput, setFocusInput] = useState(false);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [popularKeywords, setPopularKeywords] = useState<
    KeywordSuggestionsType[]
  >([]);
  const [keywordsSuggestions, setKeywordsSuggestions] = useState<
    KeywordSuggestionsType[]
  >([]);
  const [, setLoadingSuggestKeywords] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState("");
  const deferredInput = useDeferredValue(searchInput);
  const { abTestingTreatments, persistentQueryParams, routePrefix, brandId } =
    useSiteContext();
  const navigate = useNavigate();
  const [trackAction] = useTrackActions();

  const wrapperRef = useRef(null);

  useOutsideClick(wrapperRef, () => {
    setFocusInput(false);
  });
  const {
    fetchSearchTermsWithSearchHistory,
    fetchSearchAutoComplete,
    searchHistory,
  } = useSearchAutoComplete();

  useEffect(() => {
    const fetchPopularKeywords = async () => {
      try {
        const args = {
          searchText: "",
          searchScoreByCategoryId: brandEntity?.entity?.searchScoreByCategory,
          brandId,
          preventAbort: true,
        };
        // if on a brandstore page, show only the suggestions without search history
        const suggestions = await (brandId
          ? fetchSearchAutoComplete(args)
          : fetchSearchTermsWithSearchHistory(args));
        setPopularKeywords(suggestions.map((t) => ({ text: t.searchTerm })));
      } catch (error) {
        console.error("error on search suggestion", error);
      } finally {
        setLoadingSuggestKeywords(false);
      }
    };
    fetchPopularKeywords();
  }, [
    brandEntity,
    fetchSearchTermsWithSearchHistory,
    fetchSearchAutoComplete,
    brandId,
  ]);

  useEffect(() => {
    const fetchSuggestKeywords = async () => {
      setLoadingSuggestKeywords(true);
      try {
        const suggestions = await fetchSearchAutoComplete({
          searchText: deferredInput,
          searchScoreByCategoryId: brandEntity?.entity?.searchScoreByCategory,
          brandId,
        });
        if (brandId) {
          // if on a brandstore page, show only the suggestions without search history
          setKeywordsSuggestions(
            suggestions.map((t) => ({ text: t.searchTerm }))
          );
        } else {
          const filteredSuggestions = getCombinedSearchTerms({
            searchHistory,
            suggestedKeywords: suggestions,
            searchText: deferredInput,
          });
          setKeywordsSuggestions(
            filteredSuggestions.map((t) => ({ text: t.searchTerm }))
          );
        }
      } catch (error) {
        console.error("error on search suggestion", error);
      } finally {
        setLoadingSuggestKeywords(false);
      }
    };

    if (deferredInput && deferredInput.length > 0) {
      fetchSuggestKeywords();
    } else {
      setKeywordsSuggestions([]);
    }
  }, [
    deferredInput,
    brandEntity,
    abTestingTreatments.searchImprovementTreatment,
    fetchSearchAutoComplete,
    searchHistory,
    brandId,
  ]);

  const onSubmitSearch = (newKeyword?: string) => {
    let refinedInput = searchInput.trim();
    if (newKeyword) {
      refinedInput = newKeyword.trim();
    }

    if (!refinedInput) {
      return;
    }
    refinedInput = encodeURIComponent(refinedInput);
    let searchRoute =
      routePrefix + `/search?${FilterParam.TitleInclude}=${refinedInput}`;

    if (persistentQueryParams) {
      searchRoute += `&${persistentQueryParams}`;
    }
    trackAction(AnalyticsTrackingEvent.SELECTED_SEARCH_TERM_FROM_DROPDOWN, {
      searchRoute,
      searchTerm: refinedInput,
    });
    navigate(`${searchRoute}`);
  };

  let keywordList: KeywordSuggestionsType[] = popularKeywords;
  if (searchInput.length > 0) {
    keywordList = keywordsSuggestions;
  } else if (popularKeywords.length > 0) {
    keywordList = popularKeywords;
  }

  const openDropdown = focusInput && keywordList.length > 0;

  return (
    <PreviewWidgetWrapper backgroundColor="white">
      <HomeSearchBarContainer className={props.className}>
        <FilterDialog
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          navigate={navigate}
          open={filterDialogOpen}
          onClose={() => setFilterDialogOpen(false)}
          persistentQueryParams={persistentQueryParams}
          searchParams={searchParams}
          pageContext={{ abTestingTreatments: abTestingTreatments }}
          onCloseSearchDropdown={() => {}}
          routePrefix={routePrefix}
          brandId={brandId}
          axios={axios}
        />
        {props.children}
        <SearchBar
          ref={wrapperRef}
          className={`${openDropdown ? "open" : "close"} ${
            focusInput ? "focus" : "unfocus"
          }`}
        >
          <div
            className={`input_area ${openDropdown ? "open" : "close"} ${
              focusInput ? "focus" : "unfocus"
            }`}
          >
            <SearchIcon className="search_icon" />
            <input
              placeholder={props.placeholder || "Search for brands or products"}
              value={searchInput}
              autoComplete="off"
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              onFocus={() => {
                setFocusInput(true);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onSubmitSearch();
                }
              }}
            ></input>
            <div className="filter_icon_container">
              <TuneIcon
                className="filter_icon"
                onClick={() => {
                  setFilterDialogOpen(true);
                }}
              />
            </div>
          </div>

          <div className={`dropdown_area ${openDropdown ? "open" : "close"}`}>
            <ul className="list">
              <div className="divider"></div>
              {keywordList.map((item) => {
                return (
                  <motion.li
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ opacity: { duration: 0.3 } }}
                    key={item.text}
                    className="list_opt"
                    onClick={() => {
                      setSearchInput(item.text);
                      onSubmitSearch(item.text);
                    }}
                  >
                    {item.text}
                  </motion.li>
                );
              })}
            </ul>
          </div>
        </SearchBar>
      </HomeSearchBarContainer>
    </PreviewWidgetWrapper>
  );
};

const HomeSearchBar = () => {
  return (
    <HomeSearchBarInput>
      <WelcomeText></WelcomeText>
    </HomeSearchBarInput>
  );
};

export default HomeSearchBar;
