import { LandingHeader } from "../components/Header";
import { useFetchReviews } from "./data";
import { CardGrid } from "../components/CardGrid";
import LandingPageWrapper from "../components/LandingPageWrapper";
import { useEffect, useRef } from "react";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import ReviewCard from "./components/ReviewCard";
import { useInView } from "framer-motion";
import RewardEmptyPage from "../components/RewardEmptyPage";
import LandingContainer from "../components/LandingContainer";

const ReviewLanding = ({ brandIds }: { brandIds?: number[] }) => {
  const { loading, loadingMore, fetchMore, hasMore, data } = useFetchReviews({
    pageSize: 20,
    brandIds,
  });

  const ref = useRef<any>(null);

  const widgetInView = useInView(ref);

  useEffect(() => {
    if (widgetInView && !loading && !loadingMore && hasMore) {
      fetchMore();
    }
  }, [widgetInView, loading, loadingMore, hasMore, fetchMore]);

  if (!loading && data.length === 0) {
    return <RewardEmptyPage emptyTitle="No reviews?" />;
  }
  return (
    <LandingPageWrapper>
      <LandingContainer>
        {loading ? (
          <StoreLoadingSkeleton header />
        ) : (
          <>
            <LandingHeader
              title="Product reviews"
              subtitle="Share feedback by writing reviews"
            />

            <CardGrid style={{ marginTop: 20 }}>
              {data.map((d) => (
                <ReviewCard product={d} key={d.stacklineSku} />
              ))}
            </CardGrid>
          </>
        )}
        {loadingMore ? (
          <div style={{ marginTop: 20 }}>
            <StoreLoadingSkeleton header={false} />
          </div>
        ) : null}

        <div className="end_detect" ref={ref}></div>
      </LandingContainer>
    </LandingPageWrapper>
  );
};

export default ReviewLanding;
