import Typography from "@mui/material/Typography";
import {
  Link,
  LinkProps as RouterLinkProps,
  useNavigate,
} from "react-router-dom";
import {
  MOBILE_SCREEN_SIZE,
  SMALL_MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../AppNavigation/constants";
import {
  TrackedMotionButtonProps,
  OutlineButtonWithArrow,
  SolidButtonWithArrow,
} from "./StoreButton";
import StoreConfetti, {
  ConfettiContainer,
  StoreConfettiProps,
} from "./StoreConfetti";
import { SxProps, styled } from "@mui/material/styles";
import { HTMLAttributes, PropsWithChildren } from "react";
import Box from "@mui/material/Box";

type LinkProps = RouterLinkProps | { to: number };

interface StoreFinishProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  subtitle: string;
  confettiProps?: StoreConfettiProps;
  sx?: SxProps;
  children?: React.ReactNode;
}

interface StoreFinishButtonProps extends TrackedMotionButtonProps {
  sx?: SxProps;
}

interface StoreFinishLinkProps extends StoreFinishButtonProps {
  linkProps: LinkProps;
}

const StoreFinishWrapper = styled("div")(({ theme }) => ({
  maxWidth: 655,
  padding: 20,
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  flex: 1,

  ".store-finish-title": {
    marginBottom: 9,
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.primary.main,
    textAlign: "center",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 24,
    },
  },
  ".store-finish-subtitle": {
    maxWidth: 694,
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 30,
    color: theme.palette.primary.main,
    textAlign: "center",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 18,
    },
  },
}));

export const StoreFinishButton = ({
  sx,
  children,
  ...props
}: StoreFinishButtonProps) => {
  return (
    <SolidButtonWithArrow
      style={{
        whiteSpace: "nowrap",
        width: "100%",
        paddingTop: 1.75,
        height: 50,
        paddingBottom: 1.75,
        marginBottom: 2,
        fontSize: "16px",
      }}
      {...props}
    >
      {children}
    </SolidButtonWithArrow>
  );
};

export const StoreFinishLink = ({
  children,
  style,
  ...props
}: RouterLinkProps) => {
  return (
    <Link
      {...props}
      style={{
        textDecoration: "none",
        width: "100%",
        display: "flex",
        justifyContent: "center",

        ...style,
      }}
    >
      {children}
    </Link>
  );
};

export const StoreFinishLinkButton = ({
  children,
  sx,
  linkProps,
  ...props
}: StoreFinishLinkProps) => {
  const navigate = useNavigate();
  const { to, ...restLinkProps } = linkProps;
  const btn = (
    <OutlineButtonWithArrow
      style={{
        width: "100%",
        height: 50,
        whiteSpace: "nowrap",
        fontSize: 16,
        paddingTop: 1.75,
        paddingBottom: 1.75,
      }}
      {...props}
      onClick={(e) => {
        props.onClick?.(e);
        if (typeof to === "number") {
          navigate(to);
        }
      }}
    >
      {children}
    </OutlineButtonWithArrow>
  );
  if (typeof to === "number") {
    return <>{btn}</>;
  }
  return (
    <StoreFinishLink to={to} {...restLinkProps}>
      {btn}
    </StoreFinishLink>
  );
};

export const StoreFinishButtonGroup = ({
  children,
  ...props
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <Box
      sx={{
        width: "max-content",
        maxWidth: "100%",
        minWidth: 355,
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          minWidth: 350,
        },
        [`@media (max-width: ${SMALL_MOBILE_SCREEN_SIZE}px)`]: {
          minWidth: "100%",
        },
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export const StoreFinish = ({
  title,
  subtitle,
  children,
  confettiProps: { style: confettiStyle, ...confettiProps } = {},
  ...props
}: StoreFinishProps) => {
  return (
    <StoreFinishWrapper {...props}>
      <ConfettiContainer>
        <StoreConfetti {...confettiProps} />
      </ConfettiContainer>
      <Typography
        component="h2"
        className="store-finish-title"
        textAlign="center"
      >
        {title}
      </Typography>
      <Typography fontSize="16px" className="store-finish-subtitle">
        {subtitle}
      </Typography>
      <StoreFinishButtonGroup className="store-finish-button-group">
        {children}
      </StoreFinishButtonGroup>
    </StoreFinishWrapper>
  );
};
