import { hexadecimal } from "@brandclub/common-ui";
import { Dialog, styled } from "@mui/material";
import { create } from "zustand";

import { ButtonLoadingEllipsis, SolidButton } from "../StoreButton";
import { useEffect, useState } from "react";
import { updateConnectedRetailer } from "../../../api";
import { useFetchAccount } from "../../pages/AppPages/Pages/Accounts/data";
import { Retailer } from "../../../redux/types";
import { useLocation } from "react-router-dom";

interface BearState {
  retailer: Retailer | undefined;
  open: boolean;
  close: () => void;
}

const DisconnectContentContainer = styled("div")(({ theme }) => ({
  padding: "30px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  maxWidth: "400px",
  ".title": {
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.primary.main,
    margin: "20px 0 10px 0",
  },
  ".subtitle": {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.primary.main,
    marginBottom: 20,
  },
}));

const useConfirmDialogStore = create<BearState>((set) => ({
  retailer: undefined,
  open: false,
  close: () => set({ open: false }),
}));

const DisconnectContent = ({
  retailer,
  close,
}: {
  retailer?: Retailer;
  close: () => void;
}) => {
  const [disconnecting, setDisconnecting] = useState(false);
  const { connectedRetailers } = useFetchAccount({});

  useEffect(() => {
    if (
      disconnecting &&
      retailer?.parentRetailerId &&
      !connectedRetailers.find(
        (connectRetailer) =>
          connectRetailer.parentRetailerId === retailer.parentRetailerId
      )
    ) {
      setDisconnecting(false);
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disconnecting, connectedRetailers, retailer?.parentRetailerId]);

  const handleDisconnect = async () => {
    try {
      setDisconnecting(true);
      if (retailer?.parentRetailerId) {
        await updateConnectedRetailer({
          retailerId: retailer.parentRetailerId,
          connectionState: "notConnected",
        });
      }
    } catch (e) {
    } finally {
    }
  };
  return (
    <DisconnectContentContainer>
      <div className="icon">
        <img
          alt="disconect"
          src="https://media.brandclub.com/brandclub/icons/heroicons/reconnect.svg"
        ></img>
      </div>
      <div className="title">Are you sure?</div>
      <div className="subtitle">
        Disconnecting will prevent you from earning rewards from this account.
      </div>
      <div>
        <SolidButton onClick={handleDisconnect} disabled={disconnecting}>
          {disconnecting ? (
            <>
              Disconnecting
              <ButtonLoadingEllipsis baseFontSize={12} />
            </>
          ) : (
            "Disconnect"
          )}
        </SolidButton>
      </div>
    </DisconnectContentContainer>
  );
};

const DisconnectRetailerDialog = () => {
  const { open, close, retailer } = useConfirmDialogStore();
  const location = useLocation();
  useEffect(() => {
    close();
    // close the dialog whenever the location changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        fullScreen={false}
        sx={{
          zIndex: 1400,
        }}
        PaperProps={{
          sx: { zIndex: 1502 },
        }}
        slotProps={{
          backdrop: {
            sx: (theme) => ({
              backgroundColor: hexadecimal(theme.palette.primary.main)(21),
              zIndex: 1501,
            }),
          },
        }}
      >
        <DisconnectContent retailer={retailer} close={close} />
      </Dialog>
    </>
  );
};

DisconnectRetailerDialog.show = (retailer: Retailer) => {
  useConfirmDialogStore.setState({
    retailer,
    open: true,
  });
};

export default DisconnectRetailerDialog;
