import { styled } from "@mui/material";
import React from "react";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../../AppNavigation/constants";
import { AddCircleIcon, RemoveCircleIcon } from "@brandclub/common-ui";

const RowContainer = styled("div")(({ theme }) => ({
  backgroundColor: "#f6f8fa",
  borderRadius: 8,

  border: `1px solid #dcdddc`,
  padding: "16px 24px",
  cursor: "normal",
  display: "flex",
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    padding: "10px 20px",
  },

  alignItems: "center",
  ".row_text": {
    flex: 1,
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.primary.main,
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 16,
    },
  },
  ".count": {
    display: "flex",
    alignItems: "center",
  },
  ".value": {
    width: 30,
    textAlign: "center",
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.primary.main,

    margin: "0px 10px",
  },
  ".count_action": {
    cursor: "pointer",
    width: 23,
    height: 23,
    objectFit: "contain",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      width: 18,
      height: 18,
    },
  },

  "&.disabled": {
    ".row_text": {
      opacity: 0.5,
    },
  },
}));

export const SurveyCountBreakdownRow = ({
  text,
  value = 0,
  plus,
  minus,
}: {
  text: string;
  value: number;
  plus: () => void;
  minus: () => void;
}) => {
  return (
    <RowContainer>
      <div className="row_text">{text}</div>
      <div className="count">
        <RemoveCircleIcon
          backgroundColor="primary"
          htmlColor="#fff"
          role="button"
          className="count_action"
          onClick={minus}
        />
        <div className="value">{value}</div>
        <AddCircleIcon
          backgroundColor="primary"
          htmlColor="#fff"
          role="button"
          className="count_action"
          onClick={plus}
        />
      </div>
    </RowContainer>
  );
};

export default SurveyCountBreakdownRow;
