import React, { useEffect, useContext } from "react";
import { useSyncContext } from "../../../SyncProvider";
import Box from "@mui/material/Box";
import { UserLoginContext } from "../UserLoginProvider";
import Typography from "@mui/material/Typography";
import { AuthSignUpPageContainer } from "../AuthSignUpPageOutlet";
import { styled } from "@mui/material/styles";

import { RetailerList } from "../../AppPages/Sync";
import { SyncStatus } from "../../../../types/misc";
import { getErrorMessage } from "../../../../utils/errors/errorUtils";

const ConnectionStatus = styled(RetailerList)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginTop: 20,
  ".row": {
    padding: "20px",
    border: "solid 1px #dcdddc",
    borderRadius: "20px",
    height: 62,
    ".name": {
      fontSize: 16,
      textAlign: "left",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  },
}));

const isError = (syncStatus: SyncStatus) => {
  const errorStatuses: SyncStatus[] = ["error", "timed_out"];
  return errorStatuses.includes(syncStatus);
};

const ConnectRetailerInProgress = () => {
  const {
    syncStatus,
    slowestSyncSessionWithStatusMessage,
    syncStatusByParentRetailer,
  } = useSyncContext();
  const { send } = useContext(UserLoginContext)

  const { title, message } =
    slowestSyncSessionWithStatusMessage?.displayMessage ?? {};

  useEffect(() => {
    if (slowestSyncSessionWithStatusMessage && syncStatus === "normal") {
      if (isError(slowestSyncSessionWithStatusMessage.syncStatus)) {
        const errorMessage = getErrorMessage({
          retailerId: slowestSyncSessionWithStatusMessage.retailerId,
          error: slowestSyncSessionWithStatusMessage.errorMessage,
        });
        send({
          type: "CONNECT_RETAILER_FAILED",
          retailerId: slowestSyncSessionWithStatusMessage.retailerId,
          errorMessage,
        });
      } else {
        send({
          type: "CONNECT_RETAILER_SUCCEEDED",
          retailerId: slowestSyncSessionWithStatusMessage.retailerId,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncStatus]);

  return (
    <AuthSignUpPageContainer>
      <Box className="auth_container" textAlign="center">
        <Typography component="h2" className="page_title" marginBottom={16}>
          {title}
        </Typography>
        <Typography component="h2" className="page_subtitle">
          {message}
        </Typography>
        <ConnectionStatus
          syncStatusByParentRetailer={syncStatusByParentRetailer}
        />
      </Box>
    </AuthSignUpPageContainer>
  );
};

export default ConnectRetailerInProgress;
