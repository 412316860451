import { BRANDCLUB_RETAILERS } from "@brandclub/common-ui";
import { getErrorCategory, UserErrorMatch } from "./errorPatterns";

export type RetailerErrorType =
  | "duplicateloginerror"
  | "usererror"
  | "boterror"
  | "unknown";

interface ErrorConfig {
  pattern: RegExp;
  message: string;
  errorType: RetailerErrorType;
}

interface RetailerErrorConfigs {
  [retailerId: number]: ErrorConfig[];
}

interface ErrorMessageProps {
  retailerId?: number;
  error?: Error | string;
}

// naming convention is [errorType][context]. ex: formIncomplete, loginUnavailable
export const ERROR_MESSAGES = {
  // default errors
  default: [
    "An error occurred. Please try again.",
    "We encountered an error. Please try again.",
    "We encountered an error, please resubmit!",
    "An error occurred. Please try again after some time.",
  ],
  serviceUnavailable:
    "This service is currently unavailable. Please try again later.",
  formIncomplete: [
    "Please complete all fields and try again.",
    "Please check your information and try again.",
  ],
  // login errors
  loginUnavailable: "Login is currently unavailable. Please try again later.",
  // credentials errors
  credentialsInvalid: [
    "Please enter your email and password.",
    "Please check username and password.",
  ],
  passwordInvalid: [
    "The password you entered is incorrect. Please try again or reset your password.",
    "The password you entered doesn’t meet the requirements above. Please try another password.",
  ],
  emailInvalid: "Please enter a valid email address.",
  passwordResetRetry: "Please reset your password and try again later.",
  otpInvalid: "Incorrect MFA input.",
  // address errors
  addressIncorrect: "Please try re-entering your address.",
  // payment errors
  paymentSecurityCodeIncorrect:
    "The security code you entered is incorrect. Please check the code you entered, or try again later.",
  paymentMethodIncorrect: "Please try re-entering your payment method.",
  paymentMethodInvalid:
    "There was an issue with your payment method. Please retry or select another card.",
  // account errors
  accountLocked: [
    "Your account is locked. Please reset the password and try again.",
    "Your account is temporarily locked. Please try again later.",
  ],
  accountVerificationRequired: "Please verify your account and try again.",
  // checkout errors
  checkoutOutOfStock: "Out of stock item, please update your bag.",
  // retailer errors
  retailerVerifyAccount: {
    bestBuy: "Please verify your Best Buy account and try again.",
    costco: "Please ensure that you are a Costco member and are verified.",
  },
  retailerAccountIssue: {
    homeDepotPro:
      "The account is detected as a pro account and cannot be integrated right now.",
    homeDepotCreate: "Please create a Home Depot account and try again later.",
  },
} as const;

const isPredefinedErrorMessage = (errorMessage: string): boolean => {
  const constErrorMessages = Object.values(ERROR_MESSAGES).flatMap((v) =>
    Array.isArray(v) ? v : [v]
  );
  return constErrorMessages.includes(errorMessage);
};

export const retailerErrorConfigs: RetailerErrorConfigs = {
  [BRANDCLUB_RETAILERS.BestBuy]: [
    {
      pattern: /We just need to verify that you're you/i,
      message: ERROR_MESSAGES.retailerVerifyAccount.bestBuy,
      errorType: "boterror",
    },
  ],
  [BRANDCLUB_RETAILERS.Costco]: [
    {
      pattern: /Membership number does not exist or it is not verified/i,
      message: ERROR_MESSAGES.retailerVerifyAccount.costco,
      errorType: "usererror",
    },
  ],
  [BRANDCLUB_RETAILERS.HomeDepot]: [
    {
      pattern: /ProAccountError/,
      message: ERROR_MESSAGES.retailerAccountIssue.homeDepotPro,
      errorType: "usererror",
    },
    {
      pattern: /CreateNewAccountError/,
      message: ERROR_MESSAGES.retailerAccountIssue.homeDepotCreate,
      errorType: "usererror",
    },
  ],
};

const isDuplicateLoginError = (errorMessage: string | undefined) => {
  const errorMatch = errorMessage && getErrorCategory(errorMessage);
  if (!errorMatch) {
    return false;
  }
  return (
    errorMatch.subcategory === "Duplicate Account" &&
    errorMatch.category === "User Error"
  );
};

const getRetailerErrorMessage = (
  retailerId: number | undefined,
  error: string | undefined
): string | undefined => {
  if (!error || !retailerId) {
    return undefined;
  }
  if (isDuplicateLoginError(error)) {
    return error;
  }
  const retailerErrorConfig = retailerErrorConfigs[retailerId];
  const errorConfig = retailerErrorConfig?.find(({ pattern }) =>
    pattern.test(error)
  );
  if (!errorConfig) {
    return undefined;
  }
  return errorConfig.message;
};

const getUserErrorMessage = (
  errorMessage: string,
  errorMatch: UserErrorMatch
): string => {
  if (errorMatch.category !== "User Error") {
    throw new Error("Invalid error category, expected User Error");
  }
  const message = errorMessage || ERROR_MESSAGES.default[1];
  switch (errorMatch.subcategory) {
    case "Duplicate Account":
      return message;
    case "OTP Error":
      return ERROR_MESSAGES.otpInvalid;
    case "Incorrect Email":
      return ERROR_MESSAGES.emailInvalid;
    case "Incorrect Password":
      return ERROR_MESSAGES.passwordInvalid[0];
    case "Deactivated Account":
      return message;
    case "Verify Account":
      return ERROR_MESSAGES.accountVerificationRequired;
    case "Invalid Membership":
      return ERROR_MESSAGES.accountVerificationRequired;
    case "Missing Credentials":
      return ERROR_MESSAGES.credentialsInvalid[0];
    case "Invalid Credentials":
      return ERROR_MESSAGES.credentialsInvalid[1];
    case "Account Status":
      return message;
    case "Invalid Phone":
      return message;
    case "Missing Phone Country Code":
      return message;
    case "OTP Exceeded Attempts Error":
      return message;
    case "Reset Password Error":
      return ERROR_MESSAGES.passwordResetRetry;
    default:
      return ERROR_MESSAGES.default[1];
  }
};

export const getErrorMessage = ({ retailerId, error }: ErrorMessageProps) => {
  const errorMessage = error instanceof Error ? error.message : error;
  if (!errorMessage) {
    return ERROR_MESSAGES.default[0];
  }
  if (isPredefinedErrorMessage(errorMessage)) {
    return errorMessage;
  }
  const retailerError = getRetailerErrorMessage(retailerId, errorMessage);
  if (retailerError) {
    return retailerError;
  }
  const errorCategory = getErrorCategory(errorMessage);

  if (errorCategory && errorCategory.category === "User Error") {
    return getUserErrorMessage(errorMessage, errorCategory);
  }
  return ERROR_MESSAGES.default[0];
};
