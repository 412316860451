import { LongCardGrid } from "../components/CardGrid";
import { LandingHeader } from "../components/Header";
import LandingPageWrapper from "../components/LandingPageWrapper";
import { useFetchDailyDeals } from "./data";
import { useEffect, useRef } from "react";
import LongDealCard from "./components/LongDealCard";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import RewardEmptyPage from "../components/RewardEmptyPage";
import { useInView } from "framer-motion";
import LandingContainer from "../components/LandingContainer";

const DailyDealsLanding = () => {
  const { data, loading, loadingMore, fetchMore, hasMore } = useFetchDailyDeals(
    {
      pageSize: 20,
    }
  );

  const ref = useRef<any>(null);

  const widgetInView = useInView(ref);

  useEffect(() => {
    if (widgetInView && !loading && !loadingMore && hasMore) {
      fetchMore();
    }
  }, [widgetInView, loading, loadingMore, hasMore, fetchMore]);

  if (!loading && data.length === 0) {
    return <RewardEmptyPage emptyTitle="No daily deals?" />;
  }
  return (
    <LandingPageWrapper>
      <LandingContainer>
        {loading ? (
          <StoreLoadingSkeleton header cardStyle="longCard" row={2} />
        ) : (
          <>
            <LandingHeader
              title="Daily deals"
              subtitle="Access the top deals and rewards"
            ></LandingHeader>
            <LongCardGrid style={{ marginTop: 20 }}>
              {data.map((d) => (
                <LongDealCard key={d.stacklineSku} product={d} border />
              ))}
            </LongCardGrid>
          </>
        )}

        {loadingMore ? (
          <div style={{ marginTop: 20 }}>
            <StoreLoadingSkeleton cardStyle="longCard" header={false} />
          </div>
        ) : null}
        <div className="end_detect" ref={ref}></div>
      </LandingContainer>
    </LandingPageWrapper>
  );
};
export default DailyDealsLanding;
