import { motion } from "framer-motion";
import React from "react";

export const withSlideTransition = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  const withSlideTransition: React.FC<P> = (props) => {
    return (
      <motion.div
        className=""
        initial={{ y: "100vh" }}
        animate={{ y: "0vh" }}
        exit={{ y: "100vh" }}
        transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
        style={{ flex: 1 }}
      >
        <WrappedComponent {...props} />
      </motion.div>
    );
  };
  return withSlideTransition;
};

export const withFadeTransition = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  const withFadeTransition: React.FC<P> = (props) => {
    return (
      <motion.div
        className=""
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
        style={{ flex: 1 }}
      >
        <WrappedComponent {...props} />
      </motion.div>
    );
  };
  return withFadeTransition;
};
