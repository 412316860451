import { styled } from "@mui/material";
import React from "react";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../../AppNavigation/constants";
import { CheckCircleIcon } from "@brandclub/common-ui";

const RowContainer = styled("div")(({ theme }) => ({
  backgroundColor: "#f6f8fa",
  borderRadius: 8,

  border: `1px solid #dcdddc`,
  padding: "16px 24px",
  cursor: "pointer",
  display: "flex",
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    padding: "10px 20px",
  },

  alignItems: "center",
  ".row_text": {
    flex: 1,
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.primary.main,
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 16,
    },
  },
  ".indicator": {
    marginLeft: 10,
    width: 23,
    height: 23,
    objectFit: "contain",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      width: 18,
      height: 18,
    },
  },

  "&.disabled": {
    ".row_text": {
      opacity: 0.5,
    },
  },
}));

export const SurveySelectRow = ({
  text,
  onClick,
  selected = false,
  disabled = false,
}: {
  text: string;
  onClick: () => void;
  selected?: boolean;
  disabled?: boolean;
}) => {
  return (
    <RowContainer className={disabled ? "disabled" : ""} onClick={onClick}>
      <div className="row_text">{text}</div>
      {selected ? (
        <CheckCircleIcon
          backgroundColor="primary"
          htmlColor="#ffffff"
          className="indicator"
        />
      ) : null}
    </RowContainer>
  );
};

export default SurveySelectRow;
