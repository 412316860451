import moment from "moment";
import {
  CustomerCashBackRewardForTable,
  Payout,
} from "../../../../../../types/misc";
import {
  formatMoney,
  formatPhone,
  generateStatus,
  generateStatusDetail,
  getEntityImage,
} from "../../../../../../utils/StringUtils";
import { getFallbackProductImage } from "../../../../../../utils/media";
import _capitalize from "lodash/capitalize";
import { RewardCardRenderingProps } from "./RewardCard";
import { getRetailerFromId } from "@brandclub/common-ui";

export const getPayoutCardRenderDetail = (
  item: Payout
): RewardCardRenderingProps => {
  let receiverDisplayName = "";
  if (item.extendedAttributes?.payoutResult?.payout_item?.receiver) {
    receiverDisplayName =
      item.extendedAttributes?.payoutResult?.payout_item?.receiver;
    if (
      item.extendedAttributes?.payoutResult?.payout_item.recipient_type ===
      "PHONE"
    ) {
      receiverDisplayName = formatPhone(receiverDisplayName, "national");
    }
  }
  const transactionAmount = item.transactionAmount;
  let payoutEligibleAmount = item.payoutBreakdown.payoutEligibleAmount;
  let cashOutFeeAmount = item.payoutBreakdown.cashOutFeeAmount;
  if (payoutEligibleAmount + cashOutFeeAmount !== transactionAmount) {
    payoutEligibleAmount = transactionAmount;
    cashOutFeeAmount = 0;
  }

  const payoutEligibleRewardCount =
    item.payoutBreakdown.payoutEligibleRewardCount ??
    item.extendedAttributes?.rewardIds?.length;

  return {
    id: item.transactionId,
    show: true,
    icon: "https://media.brandclub.com/brandclub/icons/payout_icon.webp",
    title: "Payout",
    subtitle1: `Sent to ${_capitalize(item.recipientWalletType)}`,
    subtitle2: receiverDisplayName || "",
    amount: formatMoney(payoutEligibleAmount + cashOutFeeAmount) || "",
    linkTitle: "View details",
    linkSubtitle1: payoutEligibleRewardCount
      ? `${payoutEligibleRewardCount} total rewards paid out`
      : "",
    linkSubtitle2: "",
    linkIndicatorDot: undefined,
  };
};

export const getActivityExpiringCardRenderDetail = (
  item: CustomerCashBackRewardForTable
): RewardCardRenderingProps => {
  let show = false;
  let icon = "";
  let typeTitle = "";
  let rewardTitle = "";
  let rewardSubtitle = "";
  let rewardAmount = "";
  let statusTitle: string | undefined = "";
  let statusSubtitle: string | undefined = "";
  let steps: any[] = [];
  let currentStep = 0;
  const expiringDate = item.expiresAt
    ? moment(item.expiresAt).format("MMM Do")
    : undefined;

  const isInstant =
    item?.extendedAttributes?.rewardUnlockStrategy === "instant";
  if (item.rewardType === "customerReferralReward") {
    const actionName = "inviting a friend to join Brandclub";
    show = true;
    icon = "";
    typeTitle = "Referral reward";
    rewardTitle = moment(item.createdTime).format("MMMM D, YYYY");
    rewardAmount = formatMoney(item.rewardAmount);
    statusTitle = generateStatus(item.rewardStatus, item.paymentStatus);
    switch (item.rewardStatus) {
      case "locked":
        statusSubtitle = `${item.referredUserName} must finish setup`;
        break;
      case "unlocked":
        statusSubtitle = `${item.referredUserName} created account`;
        break;
    }
    const { activitySteps, activityCurrentStep } =
      generateStepsForReferralReward(statusTitle, actionName, rewardAmount);
    steps = activitySteps;
    currentStep = activityCurrentStep;
  }

  if (item.rewardType === "pageScanReward") {
    const actionName = "syncing account";
    show = true;
    icon = "LightGreenLogo";
    typeTitle = `Scan Reward ${
      item?.extendedAttributes?.retailerId
        ? getRetailerFromId(item?.extendedAttributes?.retailerId)
        : ""
    }`;
    rewardTitle = moment(item.createdTime).format("MMMM D, YYYY");
    rewardAmount = formatMoney(item.rewardAmount);
    statusTitle = generateStatus(item.rewardStatus, item.paymentStatus);
    statusSubtitle = generateStatusDetail(statusTitle);
    const { activitySteps, activityCurrentStep } = generateStepsForGroupB(
      statusTitle,
      actionName,
      rewardAmount
    );
    steps = activitySteps;
    currentStep = activityCurrentStep;
  }

  if (item.rewardType === "retailerConnectReward") {
    const actionName = "connecting an account";
    show = true;
    icon = getEntityImage(
      item.extendedAttributes.retailerId as number,
      "retailer"
    );
    typeTitle = "Connect reward";
    rewardTitle = item?.extendedAttributes?.retailerId
      ? getRetailerFromId(item?.extendedAttributes?.retailerId)
      : "";
    rewardAmount = formatMoney(item.rewardAmount);
    statusTitle = generateStatus(item.rewardStatus, item.paymentStatus);
    statusSubtitle =
      statusTitle === "Locked reward"
        ? "Activate account to unlock"
        : generateStatusDetail(statusTitle);
    const { activitySteps, activityCurrentStep } =
      generateStepsForConnectReward(statusTitle, actionName, rewardAmount);
    steps = activitySteps;
    currentStep = activityCurrentStep;
  }

  if (item.rewardType === "promoteBrandClubCashBackReward") {
    const actionName =
      item.extendedAttributes.actionType === "referral"
        ? "referring a friend to join a club"
        : `joining a club`;
    show = true;
    icon = item.brandClub?.imageUrl || "";
    typeTitle =
      item.extendedAttributes.actionType === "referral"
        ? "Promote reward"
        : "Membership reward";
    rewardTitle = item.brandClub
      ? item.extendedAttributes.actionType === "referral"
        ? `Promoted ${item.brandClub.name}`
        : `Joined ${item.brandClub.name}`
      : "";
    rewardAmount = formatMoney(item.rewardAmount);
    statusTitle = generateStatus(item.rewardStatus, item.paymentStatus);
    statusSubtitle = generateStatusDetail(statusTitle, item.brandClub?.name);
    const { activitySteps, activityCurrentStep } =
      isInstant || item.extendedAttributes.actionType === "referral"
        ? generateStepsForGroupB(statusTitle, actionName, rewardAmount)
        : generateStepsForGroupA(
            statusTitle,
            actionName,
            rewardAmount,
            item.brandClub?.name || "",
            expiringDate
          );
    steps = activitySteps;
    currentStep = activityCurrentStep;
  }

  if (item.rewardType === "surveyReward") {
    const actionName = "completing a survey";
    show = true;
    icon =
      item.brandClub?.imageUrl ||
      getEntityImage(item.extendedAttributes?.brandId || "", "brand");
    typeTitle = "Survey reward";
    rewardTitle = item.brandClub ? item.brandClub.name : "";
    rewardAmount = formatMoney(item.rewardAmount);
    statusTitle = generateStatus(item.rewardStatus, item.paymentStatus);
    statusSubtitle = generateStatusDetail(
      statusTitle,
      item.brandClub?.name || ""
    );
    const { activitySteps, activityCurrentStep } = isInstant
      ? generateStepsForGroupB(statusTitle, actionName, rewardAmount)
      : generateStepsForGroupA(
          statusTitle,
          actionName,
          rewardAmount,
          rewardTitle,
          expiringDate
        );
    steps = activitySteps;
    currentStep = activityCurrentStep;
  }

  if (item.rewardType === "contentReward") {
    const actionName = "completing a tutorial";
    show = true;
    icon =
      item.brandClub?.imageUrl ||
      getEntityImage(item.extendedAttributes?.brandId || "", "brand");
    typeTitle = "Content reward";
    rewardTitle = item.brandClub ? item.brandClub.name : "";
    rewardAmount = formatMoney(item.rewardAmount);
    statusTitle = generateStatus(item.rewardStatus, item.paymentStatus);
    statusSubtitle = generateStatusDetail(
      statusTitle,
      item.brandClub?.name || ""
    );
    const { activitySteps, activityCurrentStep } = isInstant
      ? generateStepsForGroupB(statusTitle, actionName, rewardAmount)
      : generateStepsForGroupA(
          statusTitle,
          actionName,
          rewardAmount,
          rewardTitle,
          expiringDate
        );

    steps = activitySteps;
    currentStep = activityCurrentStep;
  }

  if (item.rewardType === "purchaseCashBackReward") {
    if (
      item.extendedAttributes.additionalMetaData &&
      item.extendedAttributes.additionalMetaData.stacklineSku
    ) {
      const actionName = "making a purchase";
      typeTitle = "Purchase reward";
      const sku = item.extendedAttributes.additionalMetaData.stacklineSku;
      show = true;

      icon = getFallbackProductImage(sku);
      rewardTitle = item.extendedAttributes.additionalMetaData.title;
      rewardSubtitle = `${
        item.extendedAttributes.additionalMetaData.retailPrice > 0
          ? formatMoney(
              item.extendedAttributes.additionalMetaData.retailPrice
            ) + "  •  "
          : ""
      }${
        item.extendedAttributes.additionalMetaData.retailerId
          ? getRetailerFromId(
              item.extendedAttributes.additionalMetaData.retailerId
            )
          : ""
      }`;
      rewardAmount = formatMoney(item.rewardAmount);
      statusTitle = generateStatus(item.rewardStatus, item.paymentStatus);
      statusSubtitle = generateStatusDetail(
        statusTitle,
        item.extendedAttributes.additionalMetaData.brandName
      );
      const { activitySteps, activityCurrentStep } = isInstant
        ? generateStepsForGroupB(statusTitle, actionName, rewardAmount)
        : generateStepsForGroupA(
            statusTitle,
            actionName,
            rewardAmount,
            item.extendedAttributes.additionalMetaData.brandName,
            expiringDate
          );
      steps = activitySteps;
      currentStep = activityCurrentStep;
    }
  }

  if (item.rewardType === "productReviewCashBackReward") {
    if (
      item.extendedAttributes.additionalMetaData &&
      item.extendedAttributes.additionalMetaData.stacklineSku
    ) {
      const actionName = "writing a review";
      typeTitle = "Product feedback";
      const sku = item.extendedAttributes.additionalMetaData.stacklineSku;
      show = true;
      icon = getFallbackProductImage(sku);
      rewardTitle = item.extendedAttributes.additionalMetaData.title;
      rewardSubtitle = "";
      rewardAmount = formatMoney(item.rewardAmount);
      statusTitle = generateStatus(item.rewardStatus, item.paymentStatus);
      statusSubtitle = generateStatusDetail(
        statusTitle,
        item.extendedAttributes.additionalMetaData.brandName
      );
      const { activitySteps, activityCurrentStep } = generateStepsForGroupA(
        statusTitle,
        actionName,
        rewardAmount,
        item.extendedAttributes.additionalMetaData.brandName,
        expiringDate
      );
      steps = activitySteps;
      currentStep = activityCurrentStep;
    }
  }

  if (item.rewardType === "affiliateLinkCashBackReward") {
    if (
      item.extendedAttributes.productId &&
      item.extendedAttributes.productId.stacklineSku
    ) {
      const sku = item.extendedAttributes.productId.stacklineSku;
      const actionName = "referring an affiliate sale";
      show = true;
      icon = getFallbackProductImage(sku);
      if (item.extendedAttributes.additionalMetaData) {
        rewardTitle = item.extendedAttributes.additionalMetaData.title;
        rewardSubtitle = `${
          item.extendedAttributes.additionalMetaData.retailPrice > 0
            ? formatMoney(
                item.extendedAttributes.additionalMetaData.retailPrice
              ) + "  •  "
            : ""
        }${
          item.extendedAttributes.additionalMetaData.retailerId
            ? getRetailerFromId(
                item.extendedAttributes.additionalMetaData.retailerId
              )
            : ""
        }`;
      }
      typeTitle = "Affiliate commission";
      rewardAmount = formatMoney(item.rewardAmount);
      statusTitle = generateStatus(item.rewardStatus, item.paymentStatus);
      statusSubtitle = generateStatusDetail(statusTitle);
      const { activitySteps, activityCurrentStep } = generateStepsForGroupB(
        statusTitle,
        actionName,
        rewardAmount
      );
      steps = activitySteps;
      currentStep = activityCurrentStep;
    }
  }

  if (item.rewardType === "limitedTimePurchaseCashBackReward") {
    if (
      item.extendedAttributes.additionalMetaData &&
      item.extendedAttributes.additionalMetaData.stacklineSku
    ) {
      const actionName = "making a purchase";
      typeTitle = "Instant reward";
      const sku = item.extendedAttributes.additionalMetaData.stacklineSku;
      show = true;
      icon = getFallbackProductImage(sku);
      rewardTitle = item.extendedAttributes.additionalMetaData.title;
      rewardSubtitle = `${formatMoney(
        item.extendedAttributes.additionalMetaData.retailPrice
      )}  •  ${
        item.extendedAttributes.additionalMetaData.retailerId
          ? getRetailerFromId(
              item.extendedAttributes.additionalMetaData.retailerId
            )
          : ""
      }`;
      rewardAmount = formatMoney(item.rewardAmount);
      statusTitle = generateStatus(item.rewardStatus, item.paymentStatus);
      statusSubtitle = generateStatusDetail(
        statusTitle,
        item.extendedAttributes.additionalMetaData.brandName
      );
      const { activitySteps, activityCurrentStep } = generateStepsForGroupA(
        statusTitle,
        actionName,
        rewardAmount,
        item.extendedAttributes.additionalMetaData.brandName,
        expiringDate
      );
      steps = activitySteps;
      currentStep = activityCurrentStep;
    }
  }

  if (item.rewardType === "syncReward") {
    // if (item.rewardType === 'surveyReward') {
    // const actionName = 'syncing account';
    show = true;
    icon = "https://media.brandclub.com/brandclub/logo_rounded.webp";
    typeTitle = `Sync reward`;
    rewardTitle = "Brandclub";
    rewardAmount = formatMoney(item.rewardAmount);
    statusTitle = generateStatus(item.rewardStatus, item.paymentStatus);
    statusSubtitle = generateStatusDetail(
      statusTitle,
      item.brandClub?.name || "",
      "syncReward"
    );
    const expirationDate = item.expiresAt
      ? moment(item.expiresAt).format("MMM Do")
      : undefined;
    const { activitySteps, activityCurrentStep } = generateStepsForSyncReward(
      statusTitle,
      rewardAmount,
      expirationDate
    );
    steps = activitySteps;
    currentStep = activityCurrentStep;
  }

  let linkIndicatorDot: RewardCardRenderingProps["linkIndicatorDot"] =
    undefined;
  if (statusTitle === "Locked reward") {
    linkIndicatorDot = "yellow";
  } else if (statusTitle === "Pending reward") {
    linkIndicatorDot = "blue";
  } else if (statusTitle === "Cancelled reward") {
    linkIndicatorDot = "red";
  } else if (statusTitle === "Unlocked reward") {
    linkIndicatorDot = "purple";
  } else if (statusTitle === "Cashed out") {
    linkIndicatorDot = "green";
  }

  return {
    id: item.rewardId,
    show,
    icon,
    title: typeTitle,
    subtitle1: rewardTitle,
    subtitle2: rewardSubtitle,
    amount: rewardAmount,
    linkTitle: statusTitle,
    linkSubtitle1: statusSubtitle,
    linkSubtitle2: expiringDate ? `Expires on ${expiringDate}` : "",
    linkIndicatorDot,
    steps,
    currentStep,
  };
};

export const generateStepsForGroupA = (
  statusTitle: string,
  actionName: string,
  rewardAmount: string,
  brandName: string,
  expiresAt?: string
) => {
  const pastSteps = [
    {
      title: "Earned locked reward",
      detail: `You earned a ${rewardAmount} reward for ${actionName}`,
    },
    {
      title: "Purchased product",
      detail: `You purchased a ${brandName} product`,
    },
    {
      title: "Synced and unlocked reward",
      detail: `Your retail account sync confirmed purchase and unlocked ${rewardAmount} reward`,
    },
    {
      title: "Cashed out",
      detail: `Sent payout to your financial account`,
    },
  ];

  const futureSteps = [
    {
      title: "Earned locked reward",
      detail: `You earned a ${rewardAmount} reward for ${actionName}`,
    },
    {
      title: "Purchase product",
      detail: `You must purchase a product with a price greater than ${rewardAmount} from ${brandName} to unlock this reward`,
    },
    {
      title: "Sync and unlock reward",
      detail: `You must sync your retail account to confirm this purchase and unlock reward ${
        !expiresAt ? "" : `before it expires on ${expiresAt}`
      }`,
    },
    {
      title: "Cash out",
      detail: `You can cash out this reward after it is unlocked`,
    },
  ];

  const cashOutPadding = {
    title: "Pending cash out",
    detail: `Your cash out request is processing`,
  };

  const cashOutReady = {
    title: "Cash out",
    detail: `This reward is now available to cash out`,
  };

  const activitySteps: { title: string; detail: string }[] = [];
  let activityCurrentStep = 0;
  if (statusTitle === "Cashed out") {
    activitySteps.push(pastSteps[0]);
    activitySteps.push(pastSteps[1]);
    activitySteps.push(pastSteps[2]);
    activitySteps.push(pastSteps[3]);
    activityCurrentStep = 3;
  } else if (statusTitle === "Pending cash out") {
    activitySteps.push(pastSteps[0]);
    activitySteps.push(pastSteps[1]);
    activitySteps.push(pastSteps[2]);
    activitySteps.push(cashOutPadding);
    activityCurrentStep = 3;
  } else if (statusTitle === "Unlocked reward") {
    activitySteps.push(pastSteps[0]);
    activitySteps.push(pastSteps[1]);
    activitySteps.push(pastSteps[2]);
    activitySteps.push(cashOutReady);
    activityCurrentStep = 2;
  } else if (statusTitle === "Locked reward") {
    activitySteps.push(futureSteps[0]);
    activitySteps.push(futureSteps[1]);
    activitySteps.push(futureSteps[2]);
    activitySteps.push(futureSteps[3]);
    activityCurrentStep = 0;
  }

  return { activitySteps, activityCurrentStep };
};

export const generateStepsForGroupB = (
  statusTitle: string,
  actionName: string,
  rewardAmount: string
) => {
  const pastSteps = [
    {
      title: "Earned reward",
      detail: `You earned a ${rewardAmount} reward for ${actionName}`,
    },
    {
      title: "Unlocked reward",
      detail: `Your ${rewardAmount} reward has been unlocked`,
    },
    {
      title: "Cashed out",
      detail: `Sent payout to your financial account`,
    },
  ];

  const futureSteps = [
    {
      title: "Earned reward",
      detail: `You earned a ${rewardAmount} reward for ${actionName}`,
    },
    {
      title: "Unlocked reward",
      detail: `Your ${rewardAmount} reward has been unlocked`,
    },
    {
      title: "Cash out",
      detail: `You can cash out this reward after it is unlocked`,
    },
  ];

  const cashOutReady = {
    title: "Cash out",
    detail: `This reward is now available to cash out`,
  };

  const cashOutPadding = {
    title: "Pending cash out",
    detail: `Your cash out request is processing`,
  };

  const activitySteps: { title: string; detail: string }[] = [];
  let activityCurrentStep = 0;
  if (statusTitle === "Cashed out") {
    activitySteps.push(pastSteps[0]);
    activitySteps.push(pastSteps[1]);
    activitySteps.push(pastSteps[2]);
    activityCurrentStep = 2;
  } else if (statusTitle === "Pending cash out") {
    activitySteps.push(pastSteps[0]);
    activitySteps.push(pastSteps[1]);
    activitySteps.push(cashOutPadding);
    activityCurrentStep = 2;
  } else if (statusTitle === "Unlocked reward") {
    activitySteps.push(futureSteps[0]);
    activitySteps.push(futureSteps[1]);
    activitySteps.push(cashOutReady);
    activityCurrentStep = 1;
  }

  return { activitySteps, activityCurrentStep };
};

export const generateStepsForConnectReward = (
  statusTitle: string,
  actionName: string,
  rewardAmount: string
) => {
  const pastSteps = [
    {
      title: "Earned locked reward",
      detail: `You earned a ${rewardAmount} reward for ${actionName}`,
    },
    {
      title: "Activated and unlocked",
      detail: `Your retail account was activated and unlocked your reward`,
    },
    {
      title: "Cashed out",
      detail: `Sent payout to your financial account`,
    },
  ];

  const futureSteps = [
    {
      title: "Earned locked reward",
      detail: `You earned a ${rewardAmount} reward for ${actionName}`,
    },
    {
      title: "Activate account",
      detail: `Your retail account must be active to unlock your reward`,
    },
    {
      title: "Cash out",
      detail: `You can cash out this reward after it is unlocked`,
    },
  ];

  const cashOutReady = {
    title: "Cash out",
    detail: `This reward is now available to cash out`,
  };

  const cashOutPadding = {
    title: "Pending cash out",
    detail: `Your cash out request is processing`,
  };

  const activitySteps: { title: string; detail: string }[] = [];
  let activityCurrentStep = 0;
  if (statusTitle === "Cashed out") {
    activitySteps.push(pastSteps[0]);
    activitySteps.push(pastSteps[1]);
    activitySteps.push(pastSteps[2]);
    activityCurrentStep = 2;
  } else if (statusTitle === "Pending cash out") {
    activitySteps.push(pastSteps[0]);
    activitySteps.push(pastSteps[1]);
    activitySteps.push(cashOutPadding);
    activityCurrentStep = 2;
  } else if (statusTitle === "Unlocked reward") {
    activitySteps.push(pastSteps[0]);
    activitySteps.push(pastSteps[1]);
    activitySteps.push(cashOutReady);
    activityCurrentStep = 1;
  } else {
    activitySteps.push(pastSteps[0]);
    activitySteps.push(futureSteps[1]);
    activitySteps.push(futureSteps[2]);
    activityCurrentStep = 0;
  }

  return { activitySteps, activityCurrentStep };
};

export const generateStepsForReferralReward = (
  statusTitle: string,
  actionName: string,
  rewardAmount: string
) => {
  const pastSteps = [
    {
      title: "Earned locked reward",
      detail: `You earned a ${rewardAmount} reward for ${actionName}`,
    },
    {
      title: "Created account and unlocked",
      detail: `Your friend created a Brandclub account and unlocked your reward`,
    },
    {
      title: "Cashed out",
      detail: `Sent payout to your financial account`,
    },
  ];

  const futureSteps = [
    {
      title: "Earned locked reward",
      detail: `You earned a ${rewardAmount} reward for ${actionName}`,
    },
    {
      title: "Create account and unlock",
      detail: `Your friend must create a Brandclub account to unlock your reward`,
    },
    {
      title: "Cash out",
      detail: `You can cash out this reward after it is unlocked`,
    },
  ];

  const cashOutPadding = {
    title: "Pending cash out",
    detail: `Your cash out request is processing`,
  };

  const cashOutReady = {
    title: "Cash out",
    detail: `This reward is now available to cash out`,
  };

  const activitySteps: { title: string; detail: string }[] = [];
  let activityCurrentStep = 0;
  if (statusTitle === "Cashed out") {
    activitySteps.push(pastSteps[0]);
    activitySteps.push(pastSteps[1]);
    activitySteps.push(pastSteps[2]);
    activityCurrentStep = 2;
  } else if (statusTitle === "Pending cash out") {
    activitySteps.push(pastSteps[0]);
    activitySteps.push(pastSteps[1]);
    activitySteps.push(cashOutPadding);
    activityCurrentStep = 2;
  } else if (statusTitle === "Unlocked reward") {
    activitySteps.push(pastSteps[0]);
    activitySteps.push(pastSteps[1]);
    activitySteps.push(cashOutReady);
    activityCurrentStep = 1;
  } else if (statusTitle === "Locked reward") {
    activitySteps.push(futureSteps[0]);
    activitySteps.push(futureSteps[1]);
    activitySteps.push(futureSteps[2]);
    activityCurrentStep = 0;
  }

  return { activitySteps, activityCurrentStep };
};

export const generateStepsForSyncReward = (
  statusTitle: string,
  rewardAmount: string,
  expirationDate?: string
) => {
  const pastSteps = [
    {
      title: "Earned locked reward",
      detail: `You earned a ${rewardAmount} reward that is available in your account`,
    },
    {
      title: "Synced and unlocked",
      detail: `Your account was successfully synced, which unlocked your reward`,
    },
    {
      title: "Cashed out",
      detail: `Sent payout to your account`,
    },
  ];

  const futureSteps = [
    {
      title: "Earned locked reward",
      detail: `You earned a ${rewardAmount} reward that is available in your account`,
    },
    {
      title: "Sync and unlock",
      detail: `You must sync your account to confirm and unlock this reward before it expires${
        expirationDate ? ` on ${expirationDate}` : ""
      }`,
    },
    {
      title: "Cash out",
      detail: `You can cash out this reward after it is unlocked`,
    },
  ];

  const cashOutReady = {
    title: "Cash out",
    detail: `This reward is now available to cash out`,
  };

  const activitySteps: { title: string; detail: string }[] = [];
  let activityCurrentStep = 0;
  if (statusTitle === "Cashed out") {
    activitySteps.push(pastSteps[0]);
    activitySteps.push(pastSteps[1]);
    activitySteps.push(pastSteps[2]);
    activityCurrentStep = 2;
  } else if (statusTitle === "Unlocked reward") {
    activitySteps.push(pastSteps[0]);
    activitySteps.push(pastSteps[1]);
    activitySteps.push(cashOutReady);
    activityCurrentStep = 1;
  } else if (statusTitle === "Locked reward") {
    activitySteps.push(futureSteps[0]);
    activitySteps.push(futureSteps[1]);
    activitySteps.push(futureSteps[2]);
    activityCurrentStep = 0;
  }

  return { activitySteps, activityCurrentStep };
};
