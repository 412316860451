import { useRef, useState } from "react";
import { ContentRewardCampaignForCarousel } from "../../../../../../types/misc";
import { styled } from "@mui/material";
import FlowContainer from "../../components/FlowContainer";
import _get from "lodash/get";
import {
  FlowSolidButton,
  FlowSolidButtonGrey,
} from "../../components/FlowButtons";
import { ButtonLoadingEllipsis } from "../../../../../StoreComponents/StoreButton";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../../AppNavigation/constants";
import {
  CampaignType,
  RewardPillWithText,
  RewardUnlockStrategy,
} from "@brandclub/common-ui";

const MediaContainer = styled("div")(({ theme }) => ({
  padding: "20px 40px",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  flex: 1,
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    padding: "20px 20px",
  },

  ".player": {
    margin: "40px 0px 28px 0px",
    height: "100%",
    width: "100%",
    flex: 1,
    borderWidth: 1,
    position: "relative",
    display: "flex",

    alignItems: "center",
    justifyContent: "center",
    ".play_button": {
      cursor: "pointer",
      zIndex: 1,
      width: 100,
      height: 100,
      img: {
        width: 100,
        height: 100,
        objectFit: "contain",
      },
    },
    video: {
      height: "100%",
      left: "0",
      outline: "none",
      position: "absolute",
      top: "0",
      width: "100%",
    },
  },
  ".video_desc": {
    fontSize: 20,
    fontWeight: 500,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 16,
    color: theme.palette.primary.main,
    maxWidth: "100%",
    width: 854,
    textAlign: "center",
    marginBottom: 40,
  },
}));

const Media = ({
  content,
  submitting,
  moveBack,
  moveNext,
}: {
  content: ContentRewardCampaignForCarousel;
  submitting: boolean;
  moveBack: () => void;
  moveNext: () => void;
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [finishWatching, setFinishWatching] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const rewardAmount = content?.extendedAttributes?.reward?.reward?.amount ?? 0;
  const hasInstantReward =
    content?.extendedAttributes?.rewardUnlockStrategy ===
    RewardUnlockStrategy.Instant;
  const videoUrl = _get(
    content,
    ["content", "pagesForCarousel", 0, "mediaUrl"],
    ""
  );

  const thumbnailUrl = _get(
    content,
    ["content", "pagesForCarousel", 0, "thumbnail"],
    ""
  );

  const description = _get(content, ["content", "description"], "");

  const togglePlay = () => {
    if (videoRef.current as any) {
      if (isPlaying) {
        videoRef?.current?.pause();
      } else {
        videoRef?.current?.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <FlowContainer style={{ background: "#fff" }}>
      <MediaContainer>
        <div className="player" onClick={togglePlay}>
          <video
            poster={thumbnailUrl}
            ref={videoRef}
            onEnded={() => {
              setIsPlaying(false);
              setFinishWatching(true);
            }}
          >
            <source src={videoUrl} type="video/mp4" />
          </video>
          {isPlaying ? null : (
            <div className="play_button">
              <img
                src={
                  "https://media.brandclub.com/brandclub/icons/player_control/play_button.svg"
                }
                alt=""
              ></img>
            </div>
          )}
        </div>
        <div className="video_desc">
          {description ?? ""}
          {rewardAmount ? (
            <div className="reward_pill">
              <RewardPillWithText
                rewardAmount={rewardAmount ?? 0}
                rewardCampaignType={
                  hasInstantReward
                    ? CampaignType.DailyDealsRewardCampaign
                    : CampaignType.ContentRewardCampaign
                }
                containerStyle={{}}
                baseFontSize={14}
                rewardPercentTextOverride={
                  hasInstantReward ? "instant reward" : "content reward"
                }
              />
            </div>
          ) : null}
        </div>

        <div className="actions">
          <FlowSolidButtonGrey
            disabled={submitting}
            onClick={() => {
              moveBack();
            }}
          >
            Back
          </FlowSolidButtonGrey>
          <FlowSolidButton
            style={{ minWidth: 140 }}
            disabled={!finishWatching || submitting}
            onClick={() => {
              moveNext();
            }}
          >
            {submitting ? (
              <>
                Submitting
                <ButtonLoadingEllipsis baseFontSize={16} />
              </>
            ) : (
              "Next"
            )}
          </FlowSolidButton>
        </div>
      </MediaContainer>
    </FlowContainer>
  );
};

export default Media;
