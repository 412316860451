import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { withFadeTransition } from "../../../../StoreComponents/StoreTransition";
import { useSyncContext } from "../../../../SyncProvider";
import AccountsDisconnectLanding from "../../Pages/Accounts/DisconnectedPreview";

const AccountEdit = () => {
  const navigate = useNavigate();
  const { syncStatus } = useSyncContext();

  useEffect(() => {
    if (syncStatus !== "normal") {
      navigate("/dashboard/accounts");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        style={{
          marginBottom: 300,
        }}
      >
        <AccountsDisconnectLanding />
      </div>
    </>
  );
};

export default withFadeTransition(AccountEdit);
