import { useEffect } from "react";

import { motion } from "framer-motion";
import _cloneDeep from "lodash/cloneDeep";
import { MINIMUM_ANSWERING_TIME } from ".";
import { convertS3UrlToCDN } from "../../../../../../utils/media";
import { updateArray } from "../../../../../../utils/misc";
import { ButtonLoadingEllipsis } from "../../../../../StoreComponents/StoreButton";
import {
  FlowOutlineButton,
  FlowSolidButton,
} from "../../components/FlowButtons";
import { useSurvey } from "./SurveyProvider";

const TextQuestion = () => {
  const {
    currentStep,
    question,
    answers,
    moveToNextStep,
    moveToPreviousStep,
    setAnswers,
    timer,
    setTimer,
    submitting,
  } = useSurvey();
  const ans = answers[currentStep]?.text ?? "";
  const minResponseLength = question?.minResponseLength ?? 1;
  const maxResponseLength = question?.maxResponseLength;
  useEffect(() => {
    let interval: any = null;

    interval = setInterval(() => {
      const timerClone = _cloneDeep(timer);
      if (timerClone[currentStep] < MINIMUM_ANSWERING_TIME) {
        timerClone[currentStep] = timerClone[currentStep] + 1;
        setTimer(timerClone);
      }
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, timer[currentStep]]);

  const saveAnswer = (updatedAns: string) => {
    setAnswers((state) =>
      updateArray(state, currentStep, {
        type: "longAnswer",
        text: updatedAns,
      })
    );
  };

  const handleNextClick = () => {
    // save and move to next
    moveToNextStep();
  };
  const handleBackClick = () => {
    moveToPreviousStep();
  };

  const inputLength = (ans || "").trim().length;
  const lengthNotMeet =
    minResponseLength &&
    minResponseLength >= 1 &&
    inputLength < minResponseLength;

  const lengthExceeded = maxResponseLength && inputLength > maxResponseLength;

  return (
    <>
      {" "}
      <motion.div
        key={question?.description}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={`question text`}
      >
        <div className="title">{question?.description}</div>

        <div
          className={`text_input text ${
            question?.imageUrl ? "with_image" : ""
          }`}
        >
          {question?.imageUrl ? (
            <div>
              <img
                className="question_image"
                alt="question_image"
                src={convertS3UrlToCDN(question.imageUrl)}
              />
            </div>
          ) : null}
          <textarea
            className="input_box"
            value={ans}
            onChange={(e) => saveAnswer(e.target.value)}
          ></textarea>
          <div className="remaining_char" style={{}}>
            {!minResponseLength
              ? "Optional"
              : minResponseLength &&
                minResponseLength >= 1 &&
                inputLength < minResponseLength
              ? `Must be at least ${minResponseLength} characters `
              : ""}
            {maxResponseLength && inputLength > maxResponseLength
              ? `Exceeded the ${maxResponseLength} character limit.`
              : ""}
          </div>
        </div>
      </motion.div>
      <div className="actions">
        <FlowOutlineButton disabled={submitting} onClick={handleBackClick}>
          Back
        </FlowOutlineButton>
        <FlowSolidButton
          disabled={lengthNotMeet || lengthExceeded || submitting}
          onClick={handleNextClick}
        >
          {submitting ? (
            <>
              Submitting
              <ButtonLoadingEllipsis baseFontSize={16} />
            </>
          ) : (
            "Next"
          )}
        </FlowSolidButton>
      </div>
    </>
  );
};

export default TextQuestion;
