import { styled } from "@mui/material";
import { formatMoney } from "@stackline/ui";
import { useNavigate } from "react-router-dom";

import { escapeUriPath } from "../../../../../../utils/StringUtils";
import {
  OutlineButtonWithArrow,
  SolidButtonWithArrow,
} from "../../../../../StoreComponents/StoreButton";
import FlowContainer from "../../components/FlowContainer";
import StoreConfetti, {
  ConfettiContainer,
} from "../../../../../StoreComponents/StoreConfetti";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../../AppNavigation/constants";
import { useSurvey } from "./SurveyProvider";

import LoginButtons from "../../../../../StoreComponents/StoreLogin/LoginButtons";
import { useSignInPage } from "../../../../../StoreComponents/StoreLogin/useStoreLogin";
import { useUserLogin } from "../../../../Auth/UserLoginProvider";
import { useEffect } from "react";
import { useTrackActions } from "../../../../../../utils/hooks/useTracking";
import { AnalyticsTrackingEvent } from "@brandclub/common-ui";

const FinishContainer = styled("div")(({ theme }) => ({
  maxWidth: 655,
  padding: 20,
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  flex: 1,

  ".title": {
    marginBottom: 9,
    fontSize: 32,
    fontWeight: 700,
    color: theme.palette.primary.main,
    textAlign: "center",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 24,
    },
  },
  ".subtitle": {
    maxWidth: 694,
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 30,
    color: theme.palette.primary.main,
    textAlign: "center",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 18,
    },
  },
}));

const Finish = () => {
  const { survey, receivedReward } = useSurvey();
  const navigate = useNavigate();
  const { signedIn } = useUserLogin();
  const signIn = useSignInPage();
  const createAccount = useSignInPage();
  const [trackAction] = useTrackActions();

  const brandName = survey?.brandclub?.name;
  const brandId = survey?.brandclub?.brandId;

  useEffect(() => {
    trackAction(AnalyticsTrackingEvent.COMPLETED_SURVEY, {
      ...(survey.brandclub || {}),
      surveyId: survey?.extendedAttributes?.surveyId,
      rewardAmount: survey?.extendedAttributes?.reward?.reward?.amount,
      campaignId: survey?.campaignId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shopBrand = () => {
    const path = `/${escapeUriPath(brandName)}/b/${brandId}/home`;
    navigate(path);
  };

  const returnHome = () => {
    navigate("/");
  };

  let actionButtons = (
    <>
      <SolidButtonWithArrow
        style={{
          width: "100%",
          maxWidth: 349,
          marginBottom: 15,
          height: 50,
          fontSize: 16,
        }}
        onClick={shopBrand}
      >
        Explore {brandName}
      </SolidButtonWithArrow>
      <OutlineButtonWithArrow
        style={{ width: "100%", maxWidth: 349, fontSize: 16, height: 50 }}
        onClick={returnHome}
      >
        Return to home
      </OutlineButtonWithArrow>
    </>
  );
  if (!signedIn) {
    actionButtons = (
      <LoginButtons
        trackedAdditionalData={{
          brandName: brandName,
          brandId,
          survey,
        }}
        onSignInClick={() => signIn({ pathname: "/" })}
        onCreateAccountClick={() => createAccount({ pathname: "/" })}
        sx={{ maxWidth: "355px", marginTop: "20px" }}
      />
    );
  }

  return (
    <FlowContainer>
      <FinishContainer>
        <ConfettiContainer>
          <StoreConfetti />
        </ConfettiContainer>
        <div className="title">Thank you</div>
        <div className="subtitle">
          {`We appreciate your feedback. ${
            receivedReward > 0
              ? `You earned a ${formatMoney(
                  receivedReward
                )} reward that will be unlocked with your next purchase.`
              : ""
          }`}
        </div>
        {actionButtons}
      </FinishContainer>
    </FlowContainer>
  );
};

export default Finish;
