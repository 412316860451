import { CardGrid } from "../components/CardGrid";
import { PreviewHeader } from "../components/Header";
import { DealCard } from "./components/DealCard";
import { useFetchDailyDeals } from "./data";
import PreviewWidgetWrapper from "../components/PreviewWidgetWrapper";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import { useEffect, useMemo, useRef } from "react";
import { useInView } from "framer-motion";
import _orderBy from "lodash/orderBy";
import PreviewContainer from "../components/PreviewContainer";
import { defaultPreviewLazyLoadConfig } from "../../../../../utils/lazyLoadConfig";
import { ProductRewardSearchResult } from "@/types/search";

const DailyDealsPreview = () => {
  const ref = useRef(null);
  const widgetInView = useInView(ref, defaultPreviewLazyLoadConfig);
  const { data, loading, fetchInit } = useFetchDailyDeals({
    pageSize: 5,
    autoInitFetch: false,
  });

  useEffect(() => {
    if (widgetInView) {
      fetchInit();
    }
  }, [widgetInView, fetchInit]);

  const sortedDeals = useMemo(() => {
    const MAX_REWARD_PERCENT = 0.25;
    const rewardPercentWeight = 1;

    return _orderBy(
      data?.map((deal) => {
        const { dailyDealRewardInfo, retailPrice } = deal;
        const { rewardAmount } = dailyDealRewardInfo ?? { rewardAmount: 0 };
        const percentReward = retailPrice ? rewardAmount / retailPrice : 0;
        const rewardPercentBoost =
          (rewardPercentWeight * percentReward) / MAX_REWARD_PERCENT;
        return {
          ...deal,
          sortScore: rewardPercentBoost,
        };
      }),
      ["sortScore"],
      ["desc"]
    );
  }, [data]);

  if (!loading && data.length === 0) {
    return null;
  }

  return (
    <PreviewWidgetWrapper ref={ref} backgroundColor="white">
      <PreviewContainer>
        {loading ? (
          <StoreLoadingSkeleton header />
        ) : (
          <>
            <PreviewHeader
              title={"Daily deals"}
              linkTo="/dailydeals"
              subtitle="Access the top deals and rewards"
              indicator="grey"
            />

            <CardGrid>
              {sortedDeals.map((d: ProductRewardSearchResult) => (
                <DealCard product={d} key={d.stacklineSku}></DealCard>
              ))}
            </CardGrid>
          </>
        )}
      </PreviewContainer>
    </PreviewWidgetWrapper>
  );
};

export default DailyDealsPreview;
