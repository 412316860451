import Masonry from "@mui/lab/Masonry";
import { styled } from "@mui/material";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { SingleNavType, TABLET_SCREEN_SIZE } from "./constants";
import { StyledLink } from "./StyledLink";

type CategoriesDropdownProps = {
  categoryNav: SingleNavType;
  isCustomGrouped: boolean;
  handleCategoriesClose: () => void;
};

// hardcoding values for calculation purpose because
// we need to determine column width based on paper width
const XL_BREAKPOINT = 1400;
const LG_BREAKPOINT = 1200;
const MD_BREAKPOINT = TABLET_SCREEN_SIZE;

const COLUMNS_CONSTRAINT_CATEGORY_COUNT = 12;

const COLUMN_COUNT_BY_BREAKPOINT = {
  [XL_BREAKPOINT]: 6,
  [LG_BREAKPOINT]: 5,
  [MD_BREAKPOINT]: 4,
};

const BOX_VERTICAL_PADDING = 30;
const BOX_HORIZONTAL_PADDING = 0;

const MASONRY_COLUMN_GAP = 20;
const MASONRY_SPACING = 10;
const MASONRY_SPACING_MARGIN = MASONRY_SPACING / 2;

const NAV_COLUMN_SIDE_SPACING = Math.max(
  (MASONRY_COLUMN_GAP - MASONRY_SPACING_MARGIN) / 2,
  0
);
const SCROLLBAR_WIDTH = 20;

export const getNavConfig = ({
  isCustomGrouped,
  topLevelNavItems,
}: {
  isCustomGrouped: CategoriesDropdownProps["isCustomGrouped"];
  topLevelNavItems: number;
}) => {
  const windowWidth = window?.innerWidth ?? MD_BREAKPOINT;
  let columnCount = COLUMN_COUNT_BY_BREAKPOINT[MD_BREAKPOINT];
  const containerWidth = Math.min(
    window.innerWidth * 0.9 - SCROLLBAR_WIDTH,
    1350
  );

  if (windowWidth >= XL_BREAKPOINT) {
    columnCount = COLUMN_COUNT_BY_BREAKPOINT[XL_BREAKPOINT];
  } else if (windowWidth > LG_BREAKPOINT) {
    columnCount = COLUMN_COUNT_BY_BREAKPOINT[LG_BREAKPOINT];
  }

  if (
    !isCustomGrouped &&
    topLevelNavItems <= COLUMNS_CONSTRAINT_CATEGORY_COUNT
  ) {
    // design tweak for brands with less number of categories
    columnCount = COLUMN_COUNT_BY_BREAKPOINT[MD_BREAKPOINT];
  }
  const columnWidth =
    (containerWidth -
      BOX_HORIZONTAL_PADDING * 2 -
      MASONRY_COLUMN_GAP * (columnCount - 1)) /
    columnCount;

  columnCount = Math.min(columnCount, topLevelNavItems);
  return { columnCount, columnWidth, containerWidth };
};

export const StyledBox = styled("div")(({ theme }) => ({
  paddingTop: `${Math.max(BOX_VERTICAL_PADDING - MASONRY_SPACING_MARGIN, 0)}px`,
  paddingBottom: 30,
  margin: `0 auto`,

  ".wrap-text": {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  a: {
    fontWeight: 500,
    marginBottom: 10,
    "&.bold": {
      fontWeight: 600,
    },
    "&:hover": {
      textDecoration: "underline !important",
    },
  },
  "span.group-name": {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 16,
    // whiteSpace: 'nowrap',
  },
}));

const CategoriesDropdown = ({
  categoryNav,
  isCustomGrouped,
  handleCategoriesClose,
}: CategoriesDropdownProps) => {
  const [collectionToRender, setCollectionToRender] = useState<SingleNavType[]>(
    []
  );
  const [categoriesToRender, setCategoriesToRender] = useState<SingleNavType[]>(
    []
  );
  const categories = _get(categoryNav, ["subNav"], []);

  useEffect(() => {
    // MUI's Masonry and Popover are having conflict with each other, delaying the rendering her to avoid the issue
    setTimeout(() => {
      setCollectionToRender(
        categories.filter(
          (group) => group.name === "Collections" || group.name === "Editorials"
        )
      );
      setCategoriesToRender(
        /// categories
        categories.filter(
          (group) => group.name !== "Collections" && group.name !== "Editorials"
        )
      );
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!categories || _isEmpty(categories)) {
    return null;
  }

  const { columnCount, columnWidth, containerWidth } = getNavConfig({
    isCustomGrouped,
    topLevelNavItems: categoriesToRender.length,
  });

  return (
    <StyledBox
      style={{
        width: containerWidth,
        display: "flex",
        justifyContent: "center",
      }}
    >
      {categoriesToRender.length ? (
        <Masonry
          columns={columnCount}
          spacing={`${MASONRY_SPACING}px`}
          sx={{
            margin: 0,
            alignContent: "space-between",
          }}
        >
          {categoriesToRender.map((group, groupIndex) =>
            group.route || group.subNav ? (
              <div
                key={groupIndex}
                title={group.name}
                className="wrap-text"
                style={{
                  width: columnWidth,
                  marginLeft: NAV_COLUMN_SIDE_SPACING,
                  marginRight: NAV_COLUMN_SIDE_SPACING,
                  paddingTop:
                    groupIndex >= columnCount && isCustomGrouped ? "20px" : 0,
                }}
              >
                {group.route ? (
                  <StyledLink
                    to={group.route}
                    className="bold"
                    style={{
                      fontSize: isCustomGrouped ? 16 : 14,
                      textDecoration: "none",
                    }}
                    onClick={handleCategoriesClose}
                  >
                    {group.name}
                  </StyledLink>
                ) : (
                  <span className="group-name" title={group.name}>
                    {group.name}
                  </span>
                )}
                {isCustomGrouped &&
                  group.subNav?.map((category, catIndex) =>
                    category.route ? (
                      <div
                        key={catIndex}
                        title={category.name}
                        style={{ marginTop: 10, fontSize: 14 }}
                      >
                        <StyledLink
                          to={category.route}
                          style={{
                            textDecoration: "none",
                          }}
                          onClick={handleCategoriesClose}
                        >
                          {category.name}
                        </StyledLink>
                      </div>
                    ) : null
                  )}
              </div>
            ) : null
          )}
        </Masonry>
      ) : null}
      <div
        style={{
          margin: 0,
          alignContent: "space-between",
          display: "flex",
          flexDirection: "column",
          gap: `${MASONRY_SPACING}px`,
        }}
      >
        {collectionToRender.map((group, groupIndex) =>
          group.route || group.subNav ? (
            <div
              key={groupIndex}
              title={group.name}
              className="wrap-text"
              style={{
                width: columnWidth,
                marginLeft: NAV_COLUMN_SIDE_SPACING,
                marginRight: NAV_COLUMN_SIDE_SPACING,
                marginTop: 5,
                marginBottom: 5,
                paddingTop:
                  groupIndex >= columnCount && isCustomGrouped ? "20px" : 0,
              }}
            >
              {group.route ? (
                <StyledLink
                  to={group.route}
                  className="bold"
                  style={{
                    fontSize: isCustomGrouped ? 16 : 14,
                    textDecoration: "none",
                  }}
                  onClick={handleCategoriesClose}
                >
                  {group.name}
                </StyledLink>
              ) : (
                <span className="group-name" title={group.name}>
                  {group.name}
                </span>
              )}
              {group.subNav?.map((category, catIndex) =>
                category.route ? (
                  <div
                    key={catIndex}
                    title={category.name}
                    style={{ marginTop: 10, fontSize: 14 }}
                  >
                    <StyledLink
                      to={category.route}
                      style={{
                        textDecoration: "none",
                      }}
                      onClick={handleCategoriesClose}
                    >
                      {category.name}
                    </StyledLink>
                  </div>
                ) : null
              )}
            </div>
          ) : null
        )}
      </div>
    </StyledBox>
  );
};

export default CategoriesDropdown;
