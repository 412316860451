import { forwardRef } from "react";
import { MainContainer, MainWrapper } from "../../Layout/MainOutlet";

const PreviewWidgetWrapper = (
  {
    backgroundColor = "white",
    children,
    className,
  }: {
    backgroundColor?: string;
    children: React.ReactNode;
    className?: string;
  },
  ref: any
) => {
  return (
    <MainContainer
      ref={ref}
      style={{
        background: backgroundColor ?? "#f6f8fa", // default to grey
      }}
      className={className}
    >
      <MainWrapper>{children}</MainWrapper>
    </MainContainer>
  );
};

export default forwardRef(PreviewWidgetWrapper);
