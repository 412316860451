import React, { useEffect, useState, useContext } from "react";
import AuthSolidButton from "./components/AuthSolidButton";
import { styled } from "@mui/material";
import { SuccessCheckCircleIcon, brandclubStyles } from "@brandclub/common-ui";
import {
  OnBoardingButtonGreen,
  OnBoardingButtonGrey,
} from "./OnBoardingJoinClub";
import { formatMoney, getEntityImage } from "../../../utils/StringUtils";
import _get from "lodash/get";
import Loading from "../../Loading";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import { reloadProfile } from "../../../redux/actions";
import { loadRetailersAuth } from "../../../redux/actions/retailers";
import { UserLoginContext } from "./UserLoginProvider";
import {
  desktopHeaderHeight,
  mobileHeaderHeight,
} from "../../StoreComponents/StoreSinglePageOutlet";
import { MOBILE_SCREEN_SIZE } from "../../AppNavigation/constants";

const ConnenctContainer = styled("div")({});
const BrandclubTableContainer = styled("div")({
  flex: 1,
  padding: "0 10px",
  overflow: "scroll",
  margin: "23.5px 0 7px 0 !important",
  minHeight: "60px",
  ...brandclubStyles.customScrollbar,

  ".list": {
    display: "flex",
    flexDirection: "column",
    gap: 10,

    width: "100%",
  },
});
const BrandclubRowContainer = styled("div")(({ theme }) => ({
  height: 50,
  display: "flex",
  alignItems: "center",
  padding: "7px 0",
  ".logo": {
    width: 36,
    height: 36,
    objectFit: "contain",
    borderRadius: 100,
  },
  ".detail": {
    margin: "0 13px",
    flex: 1,
    ".title": {
      fontSize: 14,
      color: theme.palette.primary.main,
      fontWeight: 600,
      textAlign: "left",
    },
    ".reward": {
      fontSize: 12,

      color: theme.palette.primary.main,
      fontWeight: 500,
    },
  },
}));

const getRetailerRewardStatus = (
  retailer: any,
  mode: "connected" | "unconnect"
) => {
  if (mode === "connected") {
    return "Connected";
  }
  if (retailer?.retailerConnectRewardAmount > 0) {
    const reward = formatMoney(retailer.retailerConnectRewardAmount);
    return `Earn ${reward} to connect`;
  }
  return "Connect to earn rewards";
};

const RetailerRow = ({
  item,
  mode,
  onConnectClick,
}: {
  item: any;
  mode: "connected" | "unconnect";
  onConnectClick: VoidFunction;
}) => {
  return (
    <BrandclubRowContainer>
      <img
        className="logo"
        alt="brand_logo"
        src={getEntityImage(item.retailerId, "retailer")}
      />
      <div className="detail">
        <div className="title">{item.displayName}</div>
        <div className="reward">{getRetailerRewardStatus(item, mode)}</div>
      </div>
      <div>
        {mode === "connected" ? (
          <OnBoardingButtonGreen trackedAdditionalData={item}>
            <SuccessCheckCircleIcon
              sx={{ fontSize: 14 }}
              className="left_icon"
            />
            Success
          </OnBoardingButtonGreen>
        ) : (
          <OnBoardingButtonGrey
            trackedAdditionalData={item}
            onClick={onConnectClick}
          >
            Connect
          </OnBoardingButtonGrey>
        )}
      </div>
    </BrandclubRowContainer>
  );
};

const RetailerTable = ({
  loading,
  rows,
  connectedRetailers,
  onConnectClick,
}: {
  connectedRetailers: any[];
  loading: boolean;
  rows: any[];
  onConnectClick: (retailerId: number) => void;
}) => {
  if (loading) {
    return (
      <BrandclubTableContainer>
        <Loading dot />
      </BrandclubTableContainer>
    );
  }
  return (
    <BrandclubTableContainer>
      <div className="list">
        {rows.map((r) => (
          <RetailerRow
            key={r.retailerId}
            item={r}
            onConnectClick={() => onConnectClick(r.retailerId)}
            mode={
              connectedRetailers.includes(r.parentRetailerId)
                ? "connected"
                : "unconnect"
            }
          />
        ))}
      </div>
    </BrandclubTableContainer>
  );
};

const ROW_SPACING = 25;
const OnBoardingConnectRetailer = () => {
  const { send } = useContext(UserLoginContext);
  const retailers = useAppSelector(({ retailers }) => retailers ?? []);
  const userProfile = useAppSelector(({ userProfile }) => userProfile);
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  const reloadStatus = async () => {
    try {
      setLoading(true);
      await dispatch(reloadProfile(true));
      await dispatch(loadRetailersAuth());
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    reloadStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasAtLeastOneConnected = !!retailers.find(
    (retailer) =>
      retailer.parentRetailerId &&
      _get(
        userProfile,
        [
          "extendedAttributes",
          `connectedRetailer_${retailer.parentRetailerId}_state`,
        ],
        "invalid"
      ) === "valid"
  );

  const connectedRetailers = retailers.filter((retailer) => {
    return (
      retailer.parentRetailerId &&
      _get(
        userProfile,
        [
          "extendedAttributes",
          `connectedRetailer_${retailer.parentRetailerId}_state`,
        ],
        "invalid"
      ) === "valid"
    );
  });

  const connectedRetailerIds = connectedRetailers.map(
    (retailer) => retailer.parentRetailerId
  );

  return (
    <ConnenctContainer
      className="auth_container"
      sx={{
        height: `calc(100dvh - ${desktopHeaderHeight}px)`, // handle mobile here
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          height: `calc(100dvh - ${mobileHeaderHeight}px)`, // handle mobile here
        },
      }}
    >
      <div className="page_title" style={{ textAlign: "center" }}>
        Get cash rewards when you connect retailers
      </div>
      <RetailerTable
        connectedRetailers={connectedRetailerIds}
        loading={loading}
        rows={retailers}
        onConnectClick={(retailerId) => {
          send({
            type: "AUTHENTICATE_WITH_RETAILER",
            retailerId,
          });
        }}
      />
      <AuthSolidButton
        disabled={!hasAtLeastOneConnected}
        style={{
          marginTop: ROW_SPACING + 5,
        }}
        onClick={() => {
          send({ type: "SUBMIT" });
        }}
      >
        Continue
      </AuthSolidButton>
    </ConnenctContainer>
  );
};

export default OnBoardingConnectRetailer;
