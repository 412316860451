import { gql } from "@apollo/client";

export const getTrendingProductsV2 = gql`
  query getTrendingProductsV2(
    $titleInclude: [String!]
    $titleExclude: [String!]
    $campaignTypes: [String!]
    $retailPrice: [RangeFilter!]
    $reviewsRating: RangeFilter
    $reviewsCount: RangeFilter
    $brandIds: [Float!]
    $departmentIds: [Float!]
    $categoryIds: [Float!]
    $subCategoryIds: [Float!]
    $retailerIds: [Float!]
    $isBrandClubReady: Boolean
    $totalRewardAmount: RangeFilter
    $page: PageFilter
    $sort: [SortFilter!]
    $facetFieldNames: [String!]
    $computeFacets: Boolean
    $applySpellingCheck: Boolean
  ) {
    GetTrendingProductsV2(
      titleInclude: $titleInclude
      titleExclude: $titleExclude
      campaignTypes: $campaignTypes
      retailPrice: $retailPrice
      reviewsRating: $reviewsRating
      totalRewardAmount: $totalRewardAmount
      reviewsCount: $reviewsCount
      brandIds: $brandIds
      categoryIds: $categoryIds
      departmentIds: $departmentIds
      subCategoryIds: $subCategoryIds
      retailerIds: $retailerIds
      isBrandClubReady: $isBrandClubReady
      page: $page
      sort: $sort
      facetFieldNames: $facetFieldNames
      computeFacets: $computeFacets
      applySpellingCheck: $applySpellingCheck
    ) {
      items {
        stacklineSku
        title
        brandId
        retailerId
        retailerSku
        retailPrice
        totalRewardAmount
        scores {
          maxPurchaseRewardPercent
          maxPurchaseRewardAmount
          maxAffiliateRewardPercent
          maxAffiliateRewardAmount
          totalReviewRewardFeesAmount
        }
        hasActiveInstantReward
        affiliateRewardAmount
        activeProductPromotion {
          promoType
          isActive
          listPrice
          promoPrice
        }
        matchedProducts {
          retailPrice
          retailerId
        }
        campaignRewards {
          campaignId
          campaignType
          rewardAmount
          rewardAmountType
          campaignStatus
        }
        retailerProductPageUrl
        childProductMinRetailPrice
        childProductMaxRetailPrice
      }
      hasMore
      nextPage
    }
  }
`;

export const getUnauthedGetTrendingProducts = gql`
  query getUnauthedGetTrendingProducts(
    $titleInclude: [String!]
    $titleExclude: [String!]
    $campaignTypes: [String!]
    $retailPrice: [RangeFilter!]
    $reviewsRating: RangeFilter
    $reviewsCount: RangeFilter
    $brandIds: [Float!]
    $departmentIds: [Float!]
    $categoryIds: [Float!]
    $subCategoryIds: [Float!]
    $retailerIds: [Float!]
    $isBrandClubReady: Boolean
    $totalRewardAmount: RangeFilter
    $page: PageFilter
    $sort: [SortFilter!]
    $facetFieldNames: [String!]
    $computeFacets: Boolean
    $applySpellingCheck: Boolean
  ) {
    UnauthedGetTrendingProducts(
      titleInclude: $titleInclude
      titleExclude: $titleExclude
      campaignTypes: $campaignTypes
      retailPrice: $retailPrice
      reviewsRating: $reviewsRating
      totalRewardAmount: $totalRewardAmount
      reviewsCount: $reviewsCount
      brandIds: $brandIds
      categoryIds: $categoryIds
      departmentIds: $departmentIds
      subCategoryIds: $subCategoryIds
      retailerIds: $retailerIds
      isBrandClubReady: $isBrandClubReady
      page: $page
      sort: $sort
      facetFieldNames: $facetFieldNames
      computeFacets: $computeFacets
      applySpellingCheck: $applySpellingCheck
    ) {
      items {
        stacklineSku
        title
        brandId
        retailerId
        retailerSku
        retailPrice
        totalRewardAmount
        scores {
          maxPurchaseRewardPercent
          maxPurchaseRewardAmount
          maxAffiliateRewardPercent
          maxAffiliateRewardAmount
          totalReviewRewardFeesAmount
        }
        hasActiveInstantReward
        affiliateRewardAmount
        activeProductPromotion {
          promoType
          isActive
          listPrice
          promoPrice
        }
        matchedProducts {
          retailPrice
          retailerId
        }
        campaignRewards {
          campaignId
          campaignType
          rewardAmount
          rewardAmountType
          campaignStatus
        }
        retailerProductPageUrl
        childProductMinRetailPrice
        childProductMaxRetailPrice
      }
      hasMore
      nextPage
    }
  }
`;
