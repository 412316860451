import Box from "@mui/material/Box";
import { StyledLink } from "../../AppNavigation/StyledLink";
import { useAppSelector } from "../../../redux/hooks";
import { UNIVERSAL_STORE_SITE } from "@brandclub/common-ui";

export const TermsAndConditions = () => {
  const domainConfig = useAppSelector(
    ({ appConfig }) => appConfig?.domainConfig
  );
  const linkPrefix =
    domainConfig?.storeBrandingType === "CustomDTCStore"
      ? UNIVERSAL_STORE_SITE
      : "";

  return (
    <Box
      component="p"
      sx={(theme) => ({
        fontSize: 14,
        fontWeight: 500,
        marginTop: 4,
        color: theme.palette.primary.main,
        textAlign: "center",
      })}
    >
      {`By continuing, I agree to Brandclub's `}
      <StyledLink to={linkPrefix + "/terms"} target="_blank">
        Terms of Service
      </StyledLink>{" "}
      and{" "}
      <StyledLink to={linkPrefix + "/privacy"} target="_blank">
        Privacy Policy
      </StyledLink>
      .
    </Box>
  );
};
