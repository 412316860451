import React from "react";

import { NavButton } from "./NavSearchButton";
import { keyframes } from "@mui/material";
import { NotificationActiveIcon } from "@brandclub/common-ui";

const bellBounce = keyframes`
    0%, 25%, 75%, to {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    40% {
      -webkit-transform: rotate(8deg);
      transform: rotate(8deg);
    }
    45% {
      -webkit-transform: rotate(-8deg);
      transform: rotate(-8deg);
    }
    55% {
      -webkit-transform: rotate(2deg);
      transform: rotate(2deg);
    }
    60% {
      -webkit-transform: rotate(-2deg);
      transform: rotate(-2deg);
    }
`;

const NavBellButton = ({
  animated = false,
  onClick,
  id,
}: Pick<React.ComponentProps<typeof NavButton>, "onClick" | "id"> & {
  animated?: boolean;
}) => {
  return (
    <NavButton
      id={id}
      onClick={onClick}
      sx={{ animation: animated ? `${bellBounce} 2.2s linear 1` : "none" }}
    >
      <NotificationActiveIcon sx={{ fontSize: 25 }} />
    </NavButton>
  );
};

export default NavBellButton;
