import { styled } from "@mui/material";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../../AppNavigation/constants";

export const SurveyQuestionContainer = styled("div")(({ theme }) => ({
  padding: "20px 40px",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  flex: 1,
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    padding: "20px 20px",
  },
  ".question": {
    padding: "20px 10px 50px 10px",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",

    "&.select": {
      maxWidth: 685,

      "&.nps": {
        maxWidth: 770,
      },
    },
    "&.text": {
      maxWidth: 740,
    },
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      padding: "0px 0px",
      width: "100%",
    },
    ".title": {
      fontSize: 24,
      fontWeight: 700,
      color: theme.palette.primary.main,
      textAlign: "center",
      alignSelf: "center",
      maxWidth: 690,
      width: "100%",
      [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: 20,
      },
    },

    ".subtitle": {
      marginTop: 17,
      fontSize: 14,
      fontWeight: 500,
      maxWidth: 676,
      width: "100%",
      color: theme.palette.primary.main,
      [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        marginTop: 7,
        fontSize: 10,
      },
    },
    ".count_breaddown_settings": {
      maxWidth: 500,
      width: "100%",
      marginTop: 50,
      marginBottom: 40,
      gap: 20,
      display: "flex",
      flexDirection: "column",

      [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        marginTop: 30,
        gap: 14,
      },
    },
    ".options": {
      marginTop: 50,
      marginBottom: 10,
      maxWidth: 676,
      "&.with_image": {
        maxWidth: 500,
      },
      width: "100%",
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        marginTop: 20,
      },
      ".nps_subtitle": {
        marginBottom: 14,
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        maxWidth: 770,
        color: theme.palette.primary.main,
        fontSize: 12,
        fontWeight: 500,
        [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          marginTop: 7,
          fontSize: 10,
        },
      },
    },

    ".pills": {
      marginTop: 50,
      marginBottom: 10,
      maxWidth: 676,
      "&.with_image": {
        maxWidth: 500,
      },
      width: "100%",
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        marginTop: 20,
      },

      ".pill_options": {
        display: "flex",
        flexDirection: "row",
        gap: 10,
        flexWrap: "wrap",
      },
    },

    ".question_image": {
      borderRadius: 8,
      width: "100%",
      aspectRatio: 2 / 1,
      objectFit: "cover",
      marginBottom: 25,
    },
    ".select_options": {
      width: "100%",
      gap: 20,
      display: "flex",
      flexDirection: "column",

      [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        marginTop: 20,
        gap: 14,
      },
      "&.nps": {
        maxWidth: 770,
        display: "flex",
        flexWrap: "nowrap",
        flexDirection: "row",

        gap: 15,
        [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
          gap: 15,
        },
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          gap: 5,
        },
      },
    },
    ".text_input": {
      maxWidth: 740,
      marginTop: 50,
      marginBottom: 30,
      width: "100%",
      [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        marginTop: 30,
      },

      "&.with_image": {
        maxWidth: 500,
        ".input_box": {
          height: 308,
        },
      },

      ".input_box": {
        outline: "none",
        resize: "none",
        width: "100%",
        border: "1px solid #dcdddc",
        borderRadius: "8px",
        padding: "21px 25px",
        fontSize: 16,
        fontWeight: 500,
        color: theme.palette.primary.main,
        backgroundColor: "#f6f8fa",
        height: "378px",
        maxHeight: "378px",
        boxSizing: "border-box",
        [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 16,
          padding: "20px",
        },
      },
      ".remaining_char": {
        marginTop: 5,
        fontSize: 12,
        fontWeight: 500,
        color: theme.palette.primary.main,
        textAlign: "right",
      },
    },

    ".image_question": {
      marginTop: 50,
      marginBottom: 10,
      maxWidth: 530,
      "&.wideContainer": {
        maxWidth: 680,
      },
      width: "100%",
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        marginTop: 20,
      },
      ".image_options": {
        display: "grid",
        gap: 30,
        "&.square": {
          gridTemplateColumns: "repeat(2, minmax(100px, 1fr))",
        },
        "&.rectangleHorizontal": {
          gridTemplateColumns: "repeat(1, minmax(100px, 1fr))",
        },
        "&.rectangleVertical": {
          gridTemplateColumns: "repeat(2, minmax(100px, 1fr))",
        },
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          gap: 10,
        },
      },
    },
  },
}));
