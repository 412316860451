import { Collapse, styled, useMediaQuery, useTheme } from "@mui/material";

import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import { useState } from "react";
import { useBus } from "react-bus";
import { NavLink, Outlet } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import { CLOSE_LOGIN_DRAWER } from "../../../../utils/busEvents";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../AppNavigation/constants";
import { useSiteContext } from "../../../SiteProvider";
import {
  OutlineButton,
  SolidButton,
  TrackedMotionButton,
} from "../../../StoreComponents/StoreButton";
import { LoggedInMainOutlet } from "../Layout/MainOutlet";
import { CloseButton } from "@brandclub/common-ui";
import DashboardBottomBar from "./DashboardBottomBar/DashboardBottomBar";
import useStoreType from "../../../../utils/hooks/useStoreType";
import { StoreBrandingType } from "../../../../types/misc";

export const SIDEBAR_WIDTH = 185;
export const SIDEBAR_MARGIN_RIGHT = 30;

export const SideNavContainer = styled("div")({
  width: SIDEBAR_WIDTH,
  marginRight: SIDEBAR_MARGIN_RIGHT,
  a: {
    textDecoration: "none",
    width: "fit-content",
    "&:hover": {
      div: {
        ":after": {
          width: "100%",
        },
      },
    },
    "&.active": {
      div: {
        ":after": {
          width: "100%",
        },
      },
    },
  },
});

export const DashboardSolidButton = styled(SolidButton)({
  fontSize: 14,
  padding: "6px 18px",
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    fontSize: 12,
    padding: "5px 18px",
  },
});

export const DashboardSolidButtonGrey = styled(SolidButton)(({ theme }) => ({
  fontSize: 14,
  padding: "6px 18px",
  backgroundColor: "#f6f8fa",
  border: `1px solid #f6f8fa`,
  color: theme.palette.primary.main,

  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    fontSize: 12,
    padding: "5px 18px",
  },
}));

export const DashboardOutlineButton = styled(OutlineButton)({
  fontSize: 14,
  padding: "6px 18px",
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    fontSize: 12,
    padding: "5px 18px",
  },
});

export const NavItem = styled("div")(({ theme }) => ({
  margin: "0 0 15px 0",
  position: "relative",
  fontSize: 18,
  fontWeight: 700,
  cursor: "pointer",
  color: theme.palette.primary.main,
  width: "fit-content",
  display: "flex",
  alignItems: "center",
  ":after": {
    content: "''",
    display: "block",
    position: "absolute",
    backgroundColor: theme.palette.primary.main,
    width: 0,
    bottom: "-2px",
    height: "3px",
    transition: "width 0.3s ease-in-out",
    willWhange: "width",
    borderRadius: "10px",
  },
}));

export const SubNavItem = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  margin: "8px 0 0px 0px",
  position: "relative",
  fontSize: 16,
  fontWeight: 600,
  cursor: "pointer",
  color: theme.palette.primary.main,
  width: "fit-content",
  ":after": {
    content: "''",
    display: "block",
    position: "absolute",
    backgroundColor: theme.palette.primary.main,
    width: 0,
    bottom: "-2px",
    height: "3px",
    transition: "width 0.4s ease-in-out",
    willWhange: "width",
    borderRadius: "10px",
  },
  // "&:hover": {
  //   ":after": {
  //     width: "100%",
  //   },
  // },
}));
const DrawerContainer = styled("div")({
  padding: "31px 66px 40px 43px",
  position: "relative",
  ".close_button": {
    position: "absolute",
    right: 40,
    top: 30,
    height: 24,
    width: 24,
    cursor: "pointer",
    border: "none",
    background: "none",
    outline: "none",
    padding: 0,
    margin: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const DashboardContentContainer = styled("div")({
  flex: 1,
  maxWidth: 934,
  width: "100%",
  margin: "0 auto",
  paddingRight: SIDEBAR_WIDTH + SIDEBAR_MARGIN_RIGHT,
  [`@media screen and (max-width: 1350px)`]: {
    paddingRight: 0,
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    paddingRight: 0,
  },
});

export const ProfileNavDrawer = ({
  closeDrawer,
}: {
  closeDrawer: () => void;
}) => {
  return (
    <DrawerContainer className="DrawerContainer">
      <TrackedMotionButton className="close_button" onClick={closeDrawer}>
        <CloseButton iconSize={18.3} />
      </TrackedMotionButton>
      <ProfileNav />
    </DrawerContainer>
  );
};

const ProfileNav = ({ brand }: { brand?: boolean }) => {
  const storeType = useStoreType();
  const { routePrefix } = useSiteContext();
  const [rewardsOpen, setRewardsOpen] = useState(true);
  const bus = useBus();
  const userProfile = useAppSelector(({ userProfile }) => userProfile);
  const isDtc = storeType === StoreBrandingType.CustomDTCStore;

  const navigateCallback = () => {
    bus.emit(CLOSE_LOGIN_DRAWER);
  };

  const dashboardRoute = brand ? "branddashboard" : `dashboard`;
  const linkPrefix = brand ? routePrefix : "";
  const prefix = isDtc ? linkPrefix : `${linkPrefix}/${dashboardRoute}`;
  return (
    <SideNavContainer>
      <NavLink onClick={navigateCallback} to={`${prefix}/me`}>
        <NavItem>Dashboard</NavItem>
      </NavLink>
      <NavLink onClick={navigateCallback} to={`${prefix}/purchase`}>
        <NavItem>Purchases</NavItem>
      </NavLink>
      {brand ? null : (
        <NavLink onClick={navigateCallback} to={`${prefix}/accounts`}>
          <NavItem>Accounts</NavItem>
        </NavLink>
      )}

      <NavItem>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            onClick={() => setRewardsOpen(!rewardsOpen)}
          >
            Rewards{" "}
            {rewardsOpen ? (
              <KeyboardArrowUpRoundedIcon />
            ) : (
              <KeyboardArrowDownRoundedIcon />
            )}
          </div>
          <Collapse in={rewardsOpen}>
            <NavLink
              onClick={navigateCallback}
              to={`${prefix}/rewards/balance`}
            >
              <SubNavItem>Balance</SubNavItem>
            </NavLink>

            <NavLink
              onClick={navigateCallback}
              to={`${prefix}/rewards/activity`}
            >
              <SubNavItem>Activity</SubNavItem>
            </NavLink>

            <NavLink
              onClick={navigateCallback}
              to={`${prefix}/rewards/expiring`}
            >
              <SubNavItem>Expiring</SubNavItem>
            </NavLink>
            <NavLink
              onClick={navigateCallback}
              to={`${prefix}/rewards/payouts`}
            >
              <SubNavItem>Payouts</SubNavItem>
            </NavLink>
          </Collapse>
        </div>
      </NavItem>

      {isDtc ? (
        <NavLink onClick={navigateCallback} to={`${prefix}/profile`}>
          <NavItem>Personal</NavItem>
        </NavLink>
      ) : null}

      {brand || isDtc ? null : (
        <>
          <NavLink onClick={navigateCallback} to={`${prefix}/profile`}>
            <NavItem>Personal</NavItem>
          </NavLink>
          <NavLink onClick={navigateCallback} to={`${prefix}/wallet`}>
            <NavItem>Wallet</NavItem>
          </NavLink>
          <NavLink onClick={navigateCallback} to={`${prefix}/address`}>
            <NavItem>Addresses</NavItem>
          </NavLink>
          {userProfile?.extendedAttributes?.isInternalUser && (
            <NavLink onClick={navigateCallback} to={`${prefix}/debugging/sync`}>
              <NavItem>Sync Debugging</NavItem>
            </NavLink>
          )}
        </>
      )}
    </SideNavContainer>
  );
};

const DashboardOutlet = ({
  topOffset,
  brand = false,
}: {
  topOffset?: number;
  brand?: boolean;
}) => {
  const storeType = useStoreType();
  const tabletView = useMediaQuery(`(max-width:${TABLET_SCREEN_SIZE}px)`);
  const mobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);
  const defaultTopOffset = mobileView ? 0 : 50;

  const isDtc = storeType === StoreBrandingType.CustomDTCStore;
  return (
    <div
      className="DashboardOutlet"
      style={{ marginTop: topOffset ?? defaultTopOffset, paddingBottom: 200 }}
    >
      <LoggedInMainOutlet>
        {isDtc ? <DashboardBottomBar /> : null}
        {mobileView || tabletView ? null : <ProfileNav brand={brand} />}
        <DashboardContentContainer className="DashboardContentContainer">
          <Outlet />
        </DashboardContentContainer>
      </LoggedInMainOutlet>
    </div>
  );
};

export default DashboardOutlet;
