import { Params, useLocation } from "react-router-dom";
import queryString from "query-string";
import React, { useEffect, createContext, ReactNode, useState } from "react";
import {
  BrandclubStoreConfigUtils,
  PATHNAME_CHECKER,
  TrackingParams,
  useFingerprintJSContext,
} from "@brandclub/common-ui";
import _get from "lodash/get";
import _omitBy from "lodash/omitBy";
import * as Branch from "@brandclub/common-ui/src/analytics/Branch";
import { useAppSelector } from "../../redux/hooks";
import useBranchIOSession from "../../utils/hooks/useBranchIOSession";
import { SessionData } from "branch-sdk";

export const LocationTrackingContext = createContext<
  [TrackingParams, (p: Params) => void, boolean]
>(null as never);

export const LocationTrackingContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const location = useLocation();
  const [params, setParams] = useState<Params>({});
  const [hasApp, setHasApp] = useState<boolean>(false);
  const domainConfig = useAppSelector((state) => state.appConfig?.domainConfig);
  const branchIOSessionData: SessionData | null = useBranchIOSession();

  const visitorTracking = useFingerprintJSContext();
  const [trackingParams, setTrackingParams] =
    useState<TrackingParams | null>(null);

  useEffect(() => {
    if (!visitorTracking?.visitorSessionId) {
      return;
    }
    const appId = BrandclubStoreConfigUtils.getWebAppId();
    const {
      sku,
      referralCode,
      jl_id,

      affiliateLinkId,
      jl_s,
      jl_m, // traffic type = organic / email / partner  / referral
      jl_c, // purpose
      brandclubId,
      brandId,
      brandName,
      invitedBy,
      utm_source,
      utm_medium,
      utm_campaign,
      _branch_referrer, // skip this one from passing
      _branch_match_id,
      ...queryParamsAll
    } = queryString.parse(location?.search) || {};
    const queryParams = _omitBy(queryParamsAll, (_, k) => {
      const key = k?.toLowerCase();
      return key?.startsWith("$og_");
    });
    const skuFromParams = _get(params, ["sku"]);
    let skuFromPath: string | undefined = undefined;
    const pdpMatch = PATHNAME_CHECKER.isPDP(location.pathname);
    if (pdpMatch && pdpMatch[1]) {
      skuFromPath = pdpMatch[1];
    }
    const brandclubIdFromParams = _get(params, ["brandclubId"]);
    const brandIdFromParams = _get(params, ["brandId"]);
    const brandNameFromParams = _get(params, ["brandName"]);
    const newTrackingParams: TrackingParams = {
      ...queryParams,
      appId: appId as string | undefined,
      vs_id: visitorTracking?.visitorSessionId,
      v_id: visitorTracking?.uniqueVisitorId,
      sku: (skuFromPath || sku || skuFromParams) as string | undefined,
      brandclubId: (brandclubId || brandclubIdFromParams) as string | undefined,
      brandId: (brandId || brandIdFromParams) as string | undefined,
      brandName: (brandName || brandNameFromParams) as string | undefined,
      invitedBy: invitedBy as string | undefined,
      referralCode: referralCode as string | undefined,

      jl_id: jl_id as string | undefined,
      affiliateLinkId: affiliateLinkId as string | undefined,

      jl_s: jl_s as string | undefined,
      jl_m: jl_m as string | undefined,
      jl_c: jl_c as string | undefined,

      utm_source: utm_source as string | undefined,
      utm_medium: utm_medium as string | undefined,
      utm_campaign: utm_campaign as string | undefined,
      storePlatformType: "web",
      storeDomainName: domainConfig?.domainName,
      storeBrandId: domainConfig?.brandId,
      storeBrandingType: domainConfig?.storeBrandingType,
    };
    window.dispatchEvent(
      new CustomEvent("bc-init", {
        bubbles: true,
        detail: newTrackingParams,
      })
    );
    setTrackingParams(newTrackingParams);
  }, [
    location?.search,
    visitorTracking?.visitorSessionId,
    visitorTracking?.uniqueVisitorId,
    location?.pathname,
    params,
    domainConfig,
  ]);

  useEffect(() => {
    const setupBranch = async () => {
      if (!branchIOSessionData) {
        return;
      }

      const data = branchIOSessionData;
      if (data?.has_app) {
        setHasApp(true);
      }
      if (trackingParams) {
        await Branch.setupDeepView({
          ...data?.data_parsed,
          ...trackingParams,
          $uri_redirect_mode: Branch.isInstagramOrFBBrowser() ? 2 : 0,
          $after_click_url: window.location.href,
        });
      }
    };

    setupBranch();
  }, [branchIOSessionData, trackingParams]);

  return (
    trackingParams && (
      <LocationTrackingContext.Provider
        value={[trackingParams, setParams, hasApp]}
      >
        {children}
      </LocationTrackingContext.Provider>
    )
  );
};

export const useLocationTrackingParams = () => {
  return React.useContext(LocationTrackingContext);
};
