import { gql } from "@apollo/client";

export const getRewardAmountsByBrand = gql`
  query getRewardAmountsByBrand {
    UsersBrandClubs {
      brandClub {
        brandclubId
        brandId
        name
        rewardAmount
        imageUrl
        expiringRewardAmount
      }
    }
  }
`;

// getRewardsForUser, default page size on API is 200
export const getRewardsForUser = gql`
  query getRewardsForUser(
    $page: Float
    $brandIds: [Float!]
    $expiringRewards: Boolean
  ) {
    CustomerCashBackReward(
      page: $page
      brandIds: $brandIds
      expiringRewards: $expiringRewards
    ) {
      rewardType
      rewardStatus
      rewardAmount
      expiresAt
      updatedTime
      createdTime
      rewardId
      paymentStatus
      extendedAttributes
      brandClub {
        brandclubId
        name
        imageUrl
      }
      referredUserName
    }
  }
`;

export type RewardsForBrandClub = {
  lifetimeEarnings: number;
  lifetimePayouts: number;
  lifetimeAdjustments: number;
  lifetimeActivityCount: number;
  currentBalance: number;
  lockedBalance: number;
  pendingBalance: number;
  availableBalance: number;
  cancelledBalance: number;
  expiringRewardAmount: number;
  cashOutFee: number;
  last1DayPayout: number;
  last365DaysPayout: number;
  unlockedBalance: number;
};

export const getRewardAmountsByStatus = gql`
  query getRewardAmountsByStatusV2(
    $brandIds: [Float!]
    $forceRefresh: Boolean
  ) {
    RewardSummary(brandIds: $brandIds, forceRefresh: $forceRefresh) {
      lifetimeEarnings
      lifetimePayouts
      lifetimeAdjustments
      lifetimeActivityCount
      currentBalance
      pendingBalance
      lockedBalance
      unlockedBalance
      cancelledBalance
      availableBalance
      last365DaysPayout
      last1DayPayout
      expiringRewardAmount
      cashOutFee
    }
  }
`;

export const getCustomerPaymentTransactions = gql`
  query getCustomerPaymentTransactions {
    CustomerPaymentTransactions {
      transactionId
      transactionType
      transactionAmount
      transactionStatus
      updatedTime
      createdTime
      recipientWalletType
      payoutBreakdown
      extendedAttributes
    }
  }
`;

export const getCustomerPaymentTransactionById = gql`
  query getCustomerPaymentTransactionById($transactionId: String!) {
    GetCustomerPaymentTransaction(transactionId: $transactionId) {
      transactionId
      transactionType
      transactionAmount
      transactionStatus
      updatedTime
      createdTime
      recipientWalletType
      payoutBreakdown
      extendedAttributes
      rewards {
        rewardType
        rewardId
        rewardStatus
        rewardAmount
        expiresAt
        updatedTime
        createdTime
        rewardId
        paymentStatus
        extendedAttributes
        brandClub {
          brandclubId
          name
          imageUrl
        }
        referredUserName
      }
    }
  }
`;
