import {
  BrandclubProductCard,
  ProductRewardSearchResult,
} from "@brandclub/common-ui";
import React from "react";
import { Link } from "react-router-dom";
import ShareProductDialog from "../StoreDialogs/ShareProductDialog";
import { useUserLogin } from "../../pages/Auth/UserLoginProvider";

const ProductCard = ({
  ...props
}: {
  product: ProductRewardSearchResult;
  className?: string | undefined;
  LinkComponent: typeof Link;
}) => {
  const isLoggedOut = !useUserLogin().signedIn;
  const onShareClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    ShareProductDialog.show(props.product, isLoggedOut);
  };
  return <BrandclubProductCard onShareClick={onShareClick} {...props} />;
};

export default ProductCard;
