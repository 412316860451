import { Skeleton, styled } from "@mui/material";

import {
  desktopHeaderHeight,
  mobileHeaderHeight,
} from "../StoreSinglePageOutlet";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../AppNavigation/constants";

const InnerWidth = 800;
const TitleHeight = 40;
const SubTitleHeight = 27;
const DescriptionHeight = 110;

const darKColorStyle = "#e6ebf0";

const HeaderContainer = styled("div")(({ theme }) => ({
  top: 0,
  backgroundColor: "#ffffff",
  height: desktopHeaderHeight,
  width: "100%",
  boxShadow: "0 5px 10px 0 rgba(181, 176, 176, 0.16)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: 80,
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    height: mobileHeaderHeight,
    marginBottom: 30,
  },
}));

const InnerBlock = () => {
  return (
    <div
      data-testid="store-loading-skeleton-fullsite-content"
      style={{
        display: "flex",
        alignItems: "flex-start",

        justifyContent: "flex-start",
        flexDirection: "column",
        gap: 10,
        width: "100%",
        maxWidth: InnerWidth,
        boxSizing: "border-box",
      }}
    >
      <Skeleton
        variant="rectangular"
        width={"34%"}
        style={{ borderRadius: 6 }}
        sx={{
          bgcolor: darKColorStyle,
          display: "block",
          boxSizing: "border-box",
          height: TitleHeight,
          [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            height: TitleHeight * 0.6,
          },
        }}
      />
      <Skeleton
        variant="rectangular"
        width={"10%"}
        style={{ borderRadius: 6 }}
        sx={{
          bgcolor: darKColorStyle,
          display: "block",
          boxSizing: "border-box",
          height: SubTitleHeight,
          [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            height: SubTitleHeight * 0.6,
          },
        }}
      />
      <Skeleton
        variant="rectangular"
        width={"100%"}
        style={{ borderRadius: 6 }}
        sx={{
          bgcolor: darKColorStyle,
          display: "block",
          boxSizing: "border-box",
          height: DescriptionHeight,
          [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            height: DescriptionHeight * 0.6,
          },
        }}
      />
      <Skeleton
        variant="rectangular"
        width={"100%"}
        style={{ borderRadius: 6 }}
        sx={{
          bgcolor: darKColorStyle,
          display: "block",
          boxSizing: "border-box",
          height: DescriptionHeight,
          [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            height: DescriptionHeight * 0.6,
          },
        }}
      />
    </div>
  );
};

const StoreLoadingSkeletonFullSite = () => {
  return (
    <>
      <HeaderContainer data-testid="single-page-skeleton-full-loading-header">
        <Skeleton
          variant="rectangular"
          style={{ borderRadius: 6 }}
          sx={{
            bgcolor: darKColorStyle,
            display: "block",
            boxSizing: "border-box",
            width: 60,
            height: 60,
            [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
            [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
              width: 40,
              height: 40,
            },
          }}
        />
      </HeaderContainer>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          padding: "20px",
          justifyContent: "center",
          boxSizing: "border-box",
          flexDirection: "column",
          gap: 20,
        }}
      >
        <InnerBlock />
        <InnerBlock />
      </div>
    </>
  );
};

export default StoreLoadingSkeletonFullSite;
