import { gql } from "@apollo/client";

export const getAllCategories = gql`
  query getAllCategories {
    AllCategories {
      categoryId
      categoryName
      parentCategoryId
      parentCategoryName
      relevancyScore
    }
  }
`;
