import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "../../initialState";
import { RootState } from "../../store";
import { getAppConfig, getBrandAppConfigs } from "./thunk";

// Use the initialState as a default value
const appConfigSlice = createSlice({
  name: "appConfig",
  initialState: initialState.appConfig,
  reducers: {
    setAppConfig(_state, action) {
      return {
        ...action.payload,
      };
    },
    clearAppConfig(_state) {
      return null;
    },
  },
  extraReducers: (builders) => {
    builders.addCase(getBrandAppConfigs.fulfilled, (state, action) => {
      if (!state || "error" in action.payload) {
        return;
      }
      const brandAppConfig = action.payload;
      state.brandAppConfig = brandAppConfig;
      state.socialLinks = brandAppConfig?.socialLinks;
      state.membershipPageConfig = brandAppConfig?.membershipPageConfig;
      const { theme, font, appHeaderLogo } = brandAppConfig.mobileAppConfig ?? {};
      state.brandAppThemeConfig = { theme, font, appHeaderLogo };
    });
    builders
      .addCase(
        getAppConfig.fulfilled,
        (state: RootState["appConfig"], action) => {
          return action.payload.appConfig;
        }
      )
      .addDefaultCase((state) => state);
  },
});

export const { setAppConfig, clearAppConfig } = appConfigSlice.actions;

export default appConfigSlice.reducer;
