import LongRewardCard from "./LongRewardCard";
import {
  CampaignType,
  ProductRewardSearchResult,
  escapeUriPath,
  getAppConsistentParams,
  getEntityImage,
  getPurchaseRewardAmountForProduct,
} from "@brandclub/common-ui";

const LongProductCard = ({
  product,
}: {
  product: ProductRewardSearchResult;
}) => {
  const urlSearchParams = getAppConsistentParams(window.location.search);
  const {
    hasActiveInstantReward,
    matchedProducts,
    totalRewardAmount,
    affiliateRewardAmount,
    retailPrice,
  } = product;
  const matchedProductsRetailerIds = (matchedProducts ?? []).map(
    (matchedProduct) => matchedProduct.retailerId
  );
  const retailerIds = [product.retailerId, ...matchedProductsRetailerIds];

  const targetUrl = `/${escapeUriPath(
    product.title?.substring(0, 40),
    "-"
  )}/p/${product.stacklineSku}/product?${urlSearchParams.toString()}`;

  const purchaseRewardAmount = getPurchaseRewardAmountForProduct(product);
  const rewardPercent =
    !!retailPrice && !!purchaseRewardAmount
      ? (purchaseRewardAmount * 100) / retailPrice
      : 0;

  return (
    <LongRewardCard
      type="product"
      productTitle={product.title}
      productPrice={retailPrice}
      productRetailerIds={retailerIds}
      image={getEntityImage(product.stacklineSku, "product") || ""}
      rewardType={
        hasActiveInstantReward
          ? CampaignType.DailyDealsRewardCampaign
          : CampaignType.PurchaseRewardCampaign
      }
      affiliateRewardAmount={affiliateRewardAmount}
      rewardAmount={totalRewardAmount ?? 0}
      rewardPercent={rewardPercent}
      linkTo={targetUrl}
      border={true}
      productSoldOut={false}
      productRewardEarned={false}
      product={product}
    />
  );
};

export default LongProductCard;
