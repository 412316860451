import { createAsyncThunk } from "@reduxjs/toolkit";
import { UNAUTHENTICATED_SEARCH_PRODUCTS } from "../../../api";
import reduxApolloClient from "../../reduxApolloClient";
import { ProductOperation } from "../../../api/graphQl/unauthenticated/Product";

export const getTopProduct = createAsyncThunk(
  "/brandApp/getTopProduct",
  async ({ brandId }: { brandId: number }, thunkAPI) => {
    const productEntityRes = await reduxApolloClient.query({
      query: UNAUTHENTICATED_SEARCH_PRODUCTS,
      variables: {
        page: { start: 0, size: 1 },
        brandId: brandId,
        computeFacets: false,
        isBrandClubRead: true,
      },
    });

    return productEntityRes?.data?.[ProductOperation.UnauthenticatedSearch]
      ?.products?.[0];
  }
);
