import React, { useState, useRef, useEffect, useMemo } from "react";
import {
  CarouselContainer,
  ScrollContainer,
  SlideContainer,
  NavigationButton,
  DotContainer,
  Dot,
  Slide,
  NextArrow,
  PrevArrow,
} from "./styled";

interface CarouselProps {
  children: React.ReactNode;
  autoPlay?: boolean;
  autoPlayInterval?: number;
  showDots?: boolean;
  showArrows?: boolean;
  className?: string;
  slidesToShow?: number; // New prop for number of slides to show
}

const Carousel: React.FC<CarouselProps> = ({
  children,
  autoPlay = false,
  autoPlayInterval = 3000,
  showDots = false,
  showArrows = true,
  className,
  slidesToShow = 1,
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [leftDisabled, setLeftDisabled] = useState<boolean>(true);
  const [rightDisabled, setRightDisabled] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isDragging, setIsDragging] = useState(false);
  const [dragThresholdExceeded, setDragThresholdExceeded] = useState(false);
  const startX = useRef<number>(0);
  const scrollLeft = useRef<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const autoPlayRef = useRef<NodeJS.Timeout>();

  const DRAG_THRESHOLD = 100;

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setDragThresholdExceeded(false);
    startX.current = e.pageX - (containerRef.current?.offsetLeft || 0);
    scrollLeft.current = containerRef.current?.scrollLeft || 0;
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging || !containerRef.current) return;
    e.preventDefault();
    const x = e.pageX - (containerRef.current.offsetLeft || 0);
    const walk = (x - startX.current) * 1;

    if (Math.abs(walk) > DRAG_THRESHOLD) {
      setDragThresholdExceeded(true);
    }

    containerRef.current.scrollLeft = scrollLeft.current - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setDragThresholdExceeded(false);
  };

  const totalSlides = useMemo(() => {
    if (windowWidth <= 768) {
      return Math.ceil(React.Children.count(children) / (slidesToShow / 2));
    }
    return Math.ceil(React.Children.count(children) / slidesToShow);
  }, [children, slidesToShow, windowWidth]);

  const scroll = (direction: "left" | "right"): void => {
    if (!containerRef.current) return;

    const container = containerRef.current;
    const scrollAmount = container.clientWidth;
    const maxScroll = container.scrollWidth - container.clientWidth;

    if (direction === "left") {
      setRightDisabled(false);
      setLeftDisabled(container.scrollLeft - scrollAmount <= 0);
    } else {
      setLeftDisabled(false);
      setRightDisabled(container.scrollLeft + scrollAmount >= maxScroll);
    }

    let newScrollLeft =
      direction === "left"
        ? Math.max(container.scrollLeft - scrollAmount, 0)
        : Math.min(container.scrollLeft + scrollAmount, maxScroll);

    container.scrollTo({
      left: newScrollLeft,
      behavior: "smooth",
    });
  };

  const startAutoPlay = (): void => {
    if (autoPlay && totalSlides > 1) {
      autoPlayRef.current = setInterval(() => {
        if (containerRef.current) {
          const container = containerRef.current;
          const maxScroll = container.scrollWidth - container.clientWidth;
          const newScrollLeft = container.scrollLeft + container.clientWidth;

          if (newScrollLeft > maxScroll) {
            container.scrollTo({ left: 0, behavior: "smooth" });
          } else {
            scroll("right");
          }
        }
      }, autoPlayInterval);
    }
  };

  const stopAutoPlay = (): void => {
    if (autoPlayRef.current) {
      clearInterval(autoPlayRef.current);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    if (container) {
      container.addEventListener("mouseleave", handleMouseUp);
      container.addEventListener("mouseup", handleMouseUp);
    }

    const handleScroll = (): void => {
      const index = Math.round(container.scrollLeft / container.clientWidth);
      if (container.scrollLeft === 0) {
        setLeftDisabled(true);
      } else {
        setLeftDisabled(false);
      }
      if (
        container.scrollLeft ===
        container.scrollWidth - container.clientWidth
      ) {
        setRightDisabled(true);
      } else {
        setRightDisabled(false);
      }
      setCurrentIndex(index);
    };

    container.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
        container.removeEventListener("mouseleave", handleMouseUp);
        container.removeEventListener("mouseup", handleMouseUp);
      }
      window.removeEventListener("resize", () =>
        setWindowWidth(window.innerWidth)
      );
    };
  }, []);

  useEffect(() => {
    startAutoPlay();
    return () => stopAutoPlay();
  }, [autoPlay, autoPlayInterval, children]);

  return (
    <CarouselContainer className={className}>
      {showArrows && (
        <>
          <NavigationButton
            sx={{ left: "-2%" }}
            onClick={() => scroll("left")}
            size="small"
            aria-label="Previous slide"
            disabled={leftDisabled}
          >
            <PrevArrow />
          </NavigationButton>

          <NavigationButton
            sx={{ right: "-2%" }}
            onClick={() => scroll("right")}
            size="small"
            aria-label="Next slide"
            disabled={rightDisabled}
          >
            <NextArrow />
          </NavigationButton>
        </>
      )}

      <ScrollContainer
        ref={containerRef}
        role="region"
        aria-label="Carousel"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <SlideContainer
          sx={{
            display: "grid",
            gridTemplateColumns: `repeat(${React.Children.count(
              children
            )}, calc(${100 / slidesToShow}% - 20px))`,
            gap: "20px",
            [`@media screen and (max-width: 768px)`]: {
              gridTemplateColumns: `repeat(${React.Children.count(children)}, ${
                200 / slidesToShow
              }%)`,
              gap: 0,
            },
          }}
        >
          {React.Children.map(children, (child, index) => (
            <Slide
              key={index}
              role="group"
              aria-label={`Slide ${index + 1}`}
              style={{
                pointerEvents: dragThresholdExceeded ? "none" : "all",
              }}
            >
              {child}
            </Slide>
          ))}
        </SlideContainer>
      </ScrollContainer>

      {showDots && (
        <DotContainer>
          {Array.from({ length: totalSlides }, (_, index) => (
            <Dot
              key={index}
              active={currentIndex === index}
              onClick={() => {
                containerRef.current?.scrollTo({
                  left: index * (containerRef.current?.clientWidth || 0),
                  behavior: "smooth",
                });
              }}
              aria-label={`Go to slide group ${index + 1}`}
            />
          ))}
        </DotContainer>
      )}
    </CarouselContainer>
  );
};

export default Carousel;
