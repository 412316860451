import { useEffect, useRef } from "react";
import BrandCard from "../../../../StoreComponents/StoreCard.tsx/BrandCard";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import { CardGrid } from "../components/CardGrid";
import { LandingHeader } from "../components/Header";
import LandingPageWrapper from "../components/LandingPageWrapper";
import { useFetchClubInvites } from "./data";
import { useInView } from "framer-motion";
import RewardEmptyPage from "../components/RewardEmptyPage";
import LandingContainer from "../components/LandingContainer";

interface ClubInvitesLandingProps {
  brandIds?: number[];
}
const ClubInvitesLanding = (props: ClubInvitesLandingProps) => {
  const { data, loading, loadingMore, fetchMore, hasMore } =
    useFetchClubInvites({
      pageSize: 20,
      brandIds: props.brandIds,
    });

  const ref = useRef<any>(null);
  const widgetInView = useInView(ref);

  useEffect(() => {
    if (widgetInView && !loading && !loadingMore && hasMore) {
      fetchMore();
    }
  }, [widgetInView, loading, loadingMore, hasMore, fetchMore]);

  if (!loading && data.length === 0) {
    return <RewardEmptyPage emptyTitle="No club invites?" />;
  }
  return (
    <LandingPageWrapper>
      <LandingContainer>
        {loading ? (
          <StoreLoadingSkeleton header row={2} />
        ) : (
          <>
            <LandingHeader
              title={"Club invites"}
              subtitle="Earn rewards by joining new clubs"
            ></LandingHeader>
            <CardGrid style={{ marginTop: 20 }}>
              {data?.map((d) => (
                <BrandCard key={d.brandclubId} brand={d} />
              ))}
            </CardGrid>
          </>
        )}
        {loadingMore ? (
          <div style={{ marginTop: 20 }}>
            <StoreLoadingSkeleton header={false} />
          </div>
        ) : null}
        <div className="end_detect" ref={ref}></div>
      </LandingContainer>
    </LandingPageWrapper>
  );
};

export default ClubInvitesLanding;
