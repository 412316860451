import { setup, assign, StateValue } from "xstate";
import ReduxStore from "@/redux/types";
import {
  MIN_N_BRANDCLUB_MEMBERSHIPS,
  shouldConnectRetailer,
} from "./userAuthHelpers";
import { BRANDCLUB_BRAND_ID } from "@brandclub/common-ui";
import { StoreBrandingType } from "../../../types/misc";
import { lookup } from "dns";

type UserProfileForSetup = Partial<{
  username: string;
  password: string;
  phoneNumber: string;
  phoneNumberOTP: string;
  attributes: {
    email: string;
    given_name: string;
  };
}>;

export const UserLoginRoutes = [
  "/initialize",
  "/signin",
  "/findAccount",
  "/verifyEmail",
  "/updatePhoneNumber",
  "/signinverify",
  "/setupuserprofile",
  "/joinbrandclubs",
  "/connectretailers",
  "/success",
  "/connectretailerotp",
  "/setupuserprofile",
];

export const UserLoginStateToRouteMapping = {
  initializeUserData: "/initialize",
  setupUserProfile: "/setupuserprofile",
  signIn: "/signin",
  signInVerify: "/signinverify",
  joinBrandclubs: "/joinbrandclubs",
  connectRetailers: "/connectretailers",
  authenticateWithRetailer: "/authenticatewithretailer",
  connectRetailerInProgress: "/connectretailerinprogress",
  connectRetailerOTP: "/connectretailerotp",
  findAccount: "/findAccount",
  verifyEmail: "/verifyEmail",
  updatePhoneNumber: "/updatePhoneNumber",
  success: "/success",
};

type RedirectLoginPath = {
  pathname: string;
  search?: string;
};

export type UserLoginContext = Partial<{
  providerType: "email" | "Google" | "Facebook" | "SignInWithApple";
  appConfig: ReduxStore["appConfig"];
  user: any;
  appInstallationInfo: any;
  userProfile: Partial<ReduxStore["userProfile"]> & UserProfileForSetup;
  disconnectedCheckoutRetailers: Set<number>;
  topBrands?: ReduxStore["topBrands"]["topBrands"];
  localAuthenticationInfo: {
    hasHardware: boolean;
    isEnrolled: boolean;
  };
  settleStatus: {
    location: boolean;
    notification: boolean;
  };
  redirectPath?: RedirectLoginPath;
  otpPhoneNumber?: string;
  otpRequestId?: string;
  cognitoSession?: any;
  isNewUser?: boolean;
  ssoRedirectUrl?: string | null;

  // Phone update flow
  session?: string;
  sessionId?: string;
  emailToVerify?: string;
  errorMessage: string;
}>;

export type UserLoginEvents =
  | {
      type: "START";
    }
  | {
      type: "SET_DISCONNECTED_CHECKOUT_RETAILERS";
      disconnectedCheckoutRetailers: Set<number>;
    }
  | {
      type: "RESET_ERROR";
    }
  | {
      type:
        | "Hub.Auth.SignOut"
        | "Hub.Auth.signIn_failure"
        | "Hub.Auth.cognitoHostedUI_failure";
      errorMessage?: string;
    }
  | {
      type: "INITIALIZED_APP_DATA";
    }
  | {
      type: "SIGN_IN";
      redirectPath?: RedirectLoginPath;
    }
  | {
      type: "SIGN_OUT";
      redirectPath?: RedirectLoginPath;
    }
  | {
      type: "MOVE_TO_VERIFY_OTP";
      redirectPath?: RedirectLoginPath;
      cognitoSession: any;
      isNewUser: boolean;
      otpPhoneNumber: string;
    }
  | {
      type: "RESEND_VERIFY_OTP";
      redirectPath?: RedirectLoginPath;
      cognitoSession: any;
    }
  | {
      type: "Hub.Auth.SignIn";
      data: unknown;
    }
  | {
      type: "INITIALIZED_USER_DATA";
    }
  | {
      type: "LOGGED_IN";
    }
  | {
      type: "EMAIL";
    }
  | {
      type: "BACK";
      prevState?: StateValue;
    }
  | {
      type: "SET_TARGET_PATH";
      redirectPath: RedirectLoginPath;
    }
  | {
      type: "SUBMIT";
    }
  | {
      type: "SKIP";
    }
  | {
      type: "JOIN_CLUB";
    }
  | {
      type: "ERROR";
    }
  | {
      type: "DISMISS";
    }
  | {
      type: "SUCCESS";
    }
  | {
      type: "COGNITO";
      data: "google" | "facebook" | "apple";
    }
  | {
      type: "RECEIVE_APP_CONFIG";
      appConfig: UserLoginContext["appConfig"];
    }
  | {
      type: "RECEIVE_USER";
      user: ReduxStore["userProfile"];
    }
  | {
      type: "RECEIVE_USER_PROFILE";
      userProfile: UserLoginContext["userProfile"];
    }
  | {
      type: "RECEIVE_USER_PROFILE_FOR_SETUP";
      settleStatus: {
        location: boolean;
        notification: boolean;
      };
    }
  | {
      type: "RECEIVE_TOP_BRANDS";
      topBrands?: UserLoginContext["topBrands"];
    }
  | {
      type: "AUTHENTICATE_WITH_RETAILER";
      retailerId: number;
    }
  | {
      type: "CONNECT_RETAILER_IN_PROGRESS";
      retailerId: number;
    }
  | {
      type: "CONNECT_RETAILER_SUCCEEDED";
      retailerId: number;
    }
  | {
      type: "CONNECT_RETAILER_FAILED";
      retailerId: number;
      errorMessage: string;
    }
  | {
      type: "CONNECT_RETAILER_REQUEST_OTP";
    }
  | {
      type: "RERUN_MACHINE";
    }
  | {
      type: "FORM_RECEIVED";
      received: boolean;
    }
  | {
      type: "CHANGE_PHONE_NUMBER";
    }
  | {
      type: "FOUND_ACCOUNT";
      session: string;
      sessionId: string;
      emailToVerify: string;
      otpPhoneNumber: string;
    }
  | {
      type: "SUBMIT_OTP_FOR_UPDATE_PHONE";
      session: string;
      sessionId: string;
    };

const setupUserLoginMachine = setup({
  types: {
    context: {} as UserLoginContext,
    events: {} as UserLoginEvents,
  },
  actions: {
    RESET_ERROR: assign({
      errorMessage: undefined,
    }),
  },
  guards: {
    isUniversalStore: ({ context }) => {
      return (
        !!context.appConfig &&
        context.appConfig?.domainConfig?.storeBrandingType ===
          StoreBrandingType.UniversalStore
      );
    },
    isCustomDTCStore: ({ context }) => {
      return (
        !!context.appConfig &&
        context.appConfig?.domainConfig?.storeBrandingType ===
          StoreBrandingType.CustomDTCStore
      );
    },
    isCustomStore: ({ context }) => {
      return (
        !!context.appConfig &&
        context.appConfig?.domainConfig?.storeBrandingType ===
          StoreBrandingType.CustomStore
      );
    },
    isSignedIn: ({ context }) => {
      return !!context.user;
    },
    isNotSignedIn: ({ context }) => {
      return !context.user;
    },
    isUserDataInitialized: ({ context }) => {
      return context.userProfile && context.topBrands ? true : false;
    },
    needsProfileSetup: ({ context }) => {
      const needs =
        !context.userProfile?.given_name ||
        !context.userProfile?.family_name ||
        !context.userProfile?.email;
      return needs;
    },
    shouldConnectRetailers: ({ context }) => {
      const needs = shouldConnectRetailer(context.userProfile);
      return (
        needs &&
        context.appConfig?.domainConfig?.storeBrandingType ===
          StoreBrandingType.UniversalStore
      );
    },
    shouldJoinBrandClubs: ({ context }) => {
      // user need to join brandclubs if user is on brandclub.com and has less than 4 brandclubs
      const needs =
        context.appConfig?.domainConfig?.brandId === BRANDCLUB_BRAND_ID &&
        !!context.topBrands &&
        context.topBrands.filter((b: any) => b.brandclubId !== "BRANDCLUB")
          .length < MIN_N_BRANDCLUB_MEMBERSHIPS;

      return (
        needs &&
        context.appConfig?.domainConfig?.storeBrandingType ===
          StoreBrandingType.UniversalStore
      );
    },
  },
});

export const userLoginMachine = setupUserLoginMachine.createMachine({
  initial: "initializeUserData",
  context: ({ input }) => ({
    ...input,
  }),
  on: {
    "Hub.Auth.SignOut": {
      actions: assign(({ context, event }) => {
        return {
          ...context,
          cognitoSession: undefined,
          otpPhoneNumber: undefined,
          user: undefined,
          userProfile: undefined,
          topBrands: undefined,
          appInstallationInfo: undefined,
          redirectPath: undefined,
          errorMessage: event?.errorMessage,
          disconnectedCheckoutRetailers: undefined,
          isNewUser: undefined,
          localAuthenticationInfo: undefined,
          settleStatus: undefined,
          session: undefined,
          sessionId: undefined,
          emailToVerify: undefined,
        };
      }),
      target: ".welcome",
    },
    BACK: {
      target: ".welcome",
    },
    SET_DISCONNECTED_CHECKOUT_RETAILERS: {
      actions: assign({
        disconnectedCheckoutRetailers: ({ event }) =>
          event.disconnectedCheckoutRetailers,
      }),
    },
    SET_TARGET_PATH: {
      target: ".goHome",
      actions: assign({
        redirectPath: ({ event }) => event.redirectPath,
      }),
    },
    RERUN_MACHINE: {
      target: ".initializeUserData",
      actions: assign({ redirectPath: undefined }),
    },
    RECEIVE_USER: {
      actions: assign({
        user: ({ event }) => {
          return event.user;
        },
      }),
    },
    RECEIVE_APP_CONFIG: {
      actions: assign({ appConfig: ({ event }) => event.appConfig }),
    },
    RECEIVE_USER_PROFILE: {
      actions: assign({
        userProfile: ({ event }) => {
          return event.userProfile;
        },
      }),
    },
    RECEIVE_USER_PROFILE_FOR_SETUP: {
      actions: assign({ settleStatus: ({ event }) => event.settleStatus }),
    },
    RECEIVE_TOP_BRANDS: {
      actions: assign({ topBrands: ({ event }) => event.topBrands }),
    },
  },
  states: {
    welcome: {
      always: [{ target: "initializeUserData", guard: "isSignedIn" }],
      on: {
        SIGN_IN: [
          {
            target: "signIn",
            actions: assign({
              redirectPath: ({ event }) => event.redirectPath,
            }),
          },
        ],
      },
    },
    signIn: {
      on: {
        MOVE_TO_VERIFY_OTP: [
          {
            target: "signInVerify",
            actions: assign({
              otpPhoneNumber: ({ event }) => event.otpPhoneNumber,
              cognitoSession: ({ event }) => event.cognitoSession,
              isNewUser: ({ event }) => event.isNewUser,
            }),
          },
        ],
        CHANGE_PHONE_NUMBER: {
          target: "findAccount",
        },
        BACK: {
          target: "welcome",
          actions: assign({
            redirectPath: undefined,
          }),
        },
      },
    },
    findAccount: {
      on: {
        FOUND_ACCOUNT: {
          target: "verifyEmail",
          actions: [
            assign((ctx, _) => {
              const newContext = {
                ...ctx,
                session: ctx.event?.session,
                sessionId: ctx.event?.sessionId,
                emailToVerify: ctx.event?.emailToVerify,
                otpPhoneNumber: ctx.event?.otpPhoneNumber,
              };

              return newContext;
            }),
          ],
        },
      },
    },
    verifyEmail: {
      on: {
        FOUND_ACCOUNT: {
          target: "verifyEmail",
          actions: [
            assign((ctx, _) => {
              const newContext = {
                ...ctx,
                session: ctx.event?.session,
                sessionId: ctx.event?.sessionId,
                emailToVerify: ctx.event?.emailToVerify,
                otpPhoneNumber: ctx.event?.otpPhoneNumber,
              };

              return newContext;
            }),
          ],
        },
        SUBMIT_OTP_FOR_UPDATE_PHONE: {
          target: "updatePhoneNumber",
          actions: [
            assign((ctx, _) => {
              const newContext = {
                ...ctx,
                session: ctx.event?.session,
                sessionId: ctx.event?.sessionId,
              };

              return newContext;
            }),
          ],
        },
      },
    },
    updatePhoneNumber: {
      on: {
        BACK: {
          target: "welcome",
        },
        MOVE_TO_VERIFY_OTP: {
          target: "signInVerify",
          actions: [
            assign((ctx, event) => {
              const newContext = {
                ...ctx,
                otpPhoneNumber: ctx.event?.otpPhoneNumber,
                cognitoSession: ctx.event?.cognitoSession,
                isNewUser: ctx.event?.isNewUser,
              };

              return newContext;
            }),
          ],
        },
        "Hub.Auth.SignIn": [{ target: "initializeUserData" }],
      },
    },
    signInVerify: {
      entry: ["RESET_ERROR"],
      on: {
        RESEND_VERIFY_OTP: [
          {
            target: "signInVerify",
            actions: assign({
              cognitoSession: ({ event }) => event.cognitoSession,
            }),
          },
        ],
        SUBMIT: [
          {
            target: "setupUserProfile",
            guard: ({ context }) => {
              return (
                !context.userProfile?.given_name ||
                !context.userProfile?.family_name ||
                !context.userProfile?.email
              );
            },
          },
          { target: "initializeUserData" },
        ],
        BACK: {
          target: "signIn",
        },
      },
    },
    initializeUserData: {
      on: {
        INITIALIZED_USER_DATA: [
          { target: "goHome", guard: "isNotSignedIn" },
          {
            target: "setupUserProfile",
            guard: "needsProfileSetup",
          },
          { target: "joinBrandclubs", guard: "shouldJoinBrandClubs" },
          { target: "connectRetailers", guard: "shouldConnectRetailers" },
          { target: "goHome" },
        ],
      },
    },
    joinBrandclubs: {
      on: {
        SUBMIT: [
          { target: "connectRetailers", guard: "shouldConnectRetailers" },
          { target: "success" },
        ],
        BACK: undefined,
      },
    },
    connectRetailers: {
      on: {
        SUBMIT: [{ target: "success" }],
        AUTHENTICATE_WITH_RETAILER: [{ target: "authenticateWithRetailer" }],
        CONNECT_RETAILER_REQUEST_OTP: [{ target: "connectRetailerOTP" }],
        BACK: undefined,
      },
    },
    setupUserProfile: {
      on: {
        SUBMIT: [{ target: "initializeUserData" }],
      },
    },
    authenticateWithRetailer: {
      on: {
        CONNECT_RETAILER_IN_PROGRESS: [{ target: "connectRetailerInProgress" }],
        BACK: [{ target: "connectRetailers" }],
        CONNECT_RETAILER_REQUEST_OTP: [{ target: "connectRetailerOTP" }],
      },
    },
    connectRetailerInProgress: {
      on: {
        CONNECT_RETAILER_SUCCEEDED: [{ target: "connectRetailers" }],
        CONNECT_RETAILER_FAILED: [{ target: "authenticateWithRetailer" }],
        CONNECT_RETAILER_REQUEST_OTP: [{ target: "connectRetailerOTP" }],
        BACK: undefined,
      },
    },
    connectRetailerOTP: {
      on: {
        SUBMIT: [{ target: "connectRetailerInProgress" }],
        BACK: undefined,
      },
    },
    success: {
      on: {
        SUBMIT: {
          target: "goHome",
        },
        BACK: undefined,
      },
    },
    goHome: {
      on: {
        SIGN_IN: [
          {
            target: "welcome",
            guard: "isSignedIn",
          },
          {
            target: "signIn",
            actions: assign({
              redirectPath: ({ event }) => event.redirectPath,
            }),
          },
        ],
      },
    },
  },
});
