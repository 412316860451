import { styled } from "@mui/material";
import React, { useEffect } from "react";
import ClubSection from "./ClubSection";
import ProgramSection from "./ProgramSection";
import MemberSection from "./MemberSection";
import { useUserLogin } from "../../Auth/UserLoginProvider";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { getTopProduct } from "../../../../redux/reducers/brandApp/thunks";

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "80px",
});

const MembershipProgram = () => {
  const { signedIn } = useUserLogin();
  const navigate = useNavigate();
  useEffect(() => {
    if (signedIn) {
      navigate("/", { replace: true });
    }
  }, [signedIn, navigate]);
  const { appConfig } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (appConfig?.domainConfig?.brandId) {
      dispatch(getTopProduct({ brandId: appConfig?.domainConfig?.brandId }));
    }
  }, [appConfig?.domainConfig?.brandId, dispatch]);

  return (
    <Container>
      <ClubSection />
      <ProgramSection />
      <MemberSection />
    </Container>
  );
};

export default MembershipProgram;
