import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import Collapse from "@mui/material/Collapse";
import _isEmpty from "lodash/isEmpty";
import React from "react";
import { Link } from "react-router-dom";
import { SingleNavType } from "../constants";

const hasChildren = (item: SingleNavType) => {
  return !_isEmpty(item.subNav);
};

const MenuItem = ({
  item,
  topLevel,
  moveIn,
  onClose,
  secondLevel,
}: {
  item: SingleNavType;
  topLevel?: boolean;
  moveIn: (item: SingleNavType) => void;
  onClose: ((event: any) => void) | undefined;
  secondLevel?: boolean;
}) => {
  const [open, setOpen] = React.useState(false);
  if (hasChildren(item)) {
    return (
      <>
        <div
          className={`nav_item ${topLevel ? "top-level" : ""}`}
          role={"button"}
          onClick={() => {
            topLevel ? moveIn(item) : setOpen(!open);
          }}
        >
          {item?.displayName ?? item.name}

          {topLevel ? (
            <>
              <KeyboardArrowRightRoundedIcon className="right-icon" />
            </>
          ) : (
            <>
              {open ? (
                <KeyboardArrowUpRoundedIcon
                  className="right-icon"
                  sx={{ fontSize: 18 }}
                />
              ) : (
                <KeyboardArrowDownRoundedIcon
                  className="right-icon"
                  sx={{ fontSize: 18 }}
                />
              )}
            </>
          )}
        </div>
        <Collapse in={open}>
          {item?.subNav?.map((menu) => {
            return (
              <MenuItem
                key={menu.name}
                item={menu}
                onClose={onClose}
                moveIn={moveIn}
                secondLevel
              />
            ); // elements rendered as items in an Array need a key prop
          })}
        </Collapse>
      </>
    );
  } else {
    return (
      <Link to={item.route || ""} onClick={item.route ? onClose : undefined}>
        <div
          className={`nav_item ${topLevel ? "top-level" : ""} ${
            secondLevel ? "second-level" : "null"
          }`}
        >
          {item?.displayName ?? item.name}
        </div>
      </Link>
    );
  }
};

export default MenuItem;
