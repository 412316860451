import { BrandClub } from "@brandclub/types";
import axios from "../../../utils/axios";
import { getAuthorizationHeader } from "../../../Auth";
import { AnalyticsService, AnalyticsTrackingEvent } from "@brandclub/common-ui";

export const joinBrandClub = async (
  req: Partial<BrandClub> & {
    brandclubId: string;
    campaignId?: string;
    invitedBy?: string;
  }
) => {
  const { brandclubId, campaignId, invitedBy } = req;

  try {
    AnalyticsService.track(AnalyticsTrackingEvent.JOINED_BRANDCLUB, req);
  } catch (e) {
    console.log("error when attempting to track: ", e);
  }

  return axios.post(
    "/brandClub/joinClubV2",
    {
      brandclubId,
      campaignId,
      invitedBy,
      syncFetchReward: true,
    },
    {
      headers: {
        ...(await getAuthorizationHeader()),
      },
    }
  );
};

export const handleJoinClub = async (
  brandclubId: string,
  campaignId?: string
) => {
  try {
    const res = await joinBrandClub({
      brandclubId,
      campaignId,
    });
    if (res.status === 200 && res.data && res.data.status === "success") {
      // await dispatch(getBrandclubEntity({ id: +brandId }));
      return "success";
    }
    return "failed";
  } catch (e) {
    console.error("join club error: ", e);
    return "failed";
  }
};
