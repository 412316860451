import { useMemo } from "react";
import { useAppSelector } from "../../../../../redux/hooks";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import { PreviewHeader } from "../components/Header";
import PreviewWidgetWrapper from "../components/PreviewWidgetWrapper";
import DepartmentCard from "./components/DepartmentCard";
import DepartmentGrid from "./components/DepartmentGrid";
import { Department, departmentMapping } from "./departmentMapping";
import PreviewContainer from "../components/PreviewContainer";

const DepartmentsPreview = () => {
  //   const { data, loading, spotlightBrand } = useFetchSpotlightBrandProducts({
  //     pageSize: 10,
  //   });

  const departments = useAppSelector(({ departments }) => departments);

  const departmentsToRender = useMemo(() => {
    const departmentsToRender: Department[] = [];
    if (departments?.departments) {
      departments.departments.forEach((d, index) => {
        const { departmentId } = d;
        if (departmentMapping[departmentId]) {
          departmentsToRender.push(departmentMapping[departmentId]);
        }
      });
    }
    departmentsToRender.sort((a, b) => a.order - b.order);
    return departmentsToRender;
  }, [departments?.departments]);

  return (
    <PreviewWidgetWrapper>
      <PreviewContainer>
        {departments?.loading ? (
          <StoreLoadingSkeleton header row={2} />
        ) : (
          <>
            <PreviewHeader
              title={"Departments"}
              subtitle={"Shop popular products in each department"}
            />
            <DepartmentGrid>
              {departmentsToRender.map((department) => (
                <DepartmentCard
                  key={`department:${department.departmentId}`}
                  department={department}
                />
              ))}
            </DepartmentGrid>
          </>
        )}
      </PreviewContainer>
    </PreviewWidgetWrapper>
  );
};

export default DepartmentsPreview;
