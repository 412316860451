import { useCallback, useEffect, useState } from "react";
import { getRecentlyViewedProductsV2 } from "../../../../../api/graphQl/authenticated/RecentlyViewed";
import reduxApolloClient from "../../../../../redux/reduxApolloClient";
import ConfigUtils from "../../../../../utils/ConfigUtils";
import _get from "lodash/get";
import _uniqBy from "lodash/uniqBy";
import { SearchResult } from "../../../../../api/types/searchResult";
import { ProductRewardSearchResult } from "@brandclub/common-ui";

const RESKEY = "RecentlyViewedProductsV2";

export const useFetchRecentlyViewedProducts = ({
  brandIds,
  pageSize = 25,
  autoInitFetch = true,
}: {
  brandIds?: number[];
  pageSize?: number;
  autoInitFetch?: boolean;
}) => {
  const [data, setData] = useState<ProductRewardSearchResult[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(
    async (pageNumber = 0) => {
      try {
        setError(null);

        const res = await reduxApolloClient.query<{
          [RESKEY]: SearchResult<ProductRewardSearchResult>;
        }>({
          query: getRecentlyViewedProductsV2,
          variables: {
            brandIds,
            page: {
              start: pageNumber,
              size: pageSize,
            },
          },
          fetchPolicy: "network-only",
          context: {
            uri: `${ConfigUtils.getGraphQlUri()}?id=${RESKEY}`,
          },
        });
        const newData = _get(res, ["data", RESKEY, "items"], []);
        const newHasMore = _get(res, ["data", RESKEY, "hasMore"], false);
        setData((d) => _uniqBy([...d, ...newData], "stacklineSku"));
        setHasMore(newHasMore);
      } catch (e: any) {
        setError(e);
      }
    },
    [brandIds, pageSize]
  );

  const fetchInit = useCallback(async () => {
    try {
      setLoading(true);
      await fetchData(0);
    } finally {
      setPage(0);
      setLoading(false);
    }
  }, [fetchData]);

  const fetchMore = useCallback(async () => {
    try {
      setLoadingMore(true);
      await fetchData((page + 1) * pageSize);
    } finally {
      setPage((p) => p + 1);
      setLoadingMore(false);
    }
  }, [fetchData, page, pageSize]);

  useEffect(() => {
    if (autoInitFetch) {
      fetchInit();
    }
  }, [fetchInit, autoInitFetch]);

  return {
    data,
    loading,
    error,
    hasMore,
    fetchMore,
    loadingMore,
    fetchInit,
  };
};
