import { useFetchReviews } from "@/components/pages/AppPages/Pages/Review/data";
import { useAppSelector } from "@/redux/hooks";
import { Retailer } from "@/redux/types";
import { useFetchSurveys } from "@/components/pages/AppPages/Pages/Survey/data";
import { useFetchClubInvites } from "@/components/pages/AppPages/Pages/ClubInvites/data";
import { useFetchContent } from "@/components/pages/AppPages/Pages/Content/data";

export const useRewards = () => {
  const { retailers } = useAppSelector(({ retailers }) => retailers);
  const { rewards } = useAppSelector(({ rewards }) => rewards);
  const accountRewards = retailers?.reduce((acc : number, retailer: Retailer) => {
    return acc + (retailer.retailerConnectRewardAmount ?? 0);
  }, 0);

  const { data } = useFetchReviews({
    autoInitFetch: false,
  });

  const contentCount = data?.length ?? 0;

  const { data: surveyData } = useFetchSurveys({
    pageSize: 20,
  });

  const { data: contentRewards } = useFetchContent({
    pageSize: 20,
  });

  const totalContentRewards = contentRewards?.reduce((acc: number, content) => {
    return acc = content.extendedAttributes?.reward.reward.amount;
  }, 0);

  const surveyRewards = surveyData?.reduce((acc: number, survey) => {
    return acc + survey.extendedAttributes?.reward.reward.amount;
  }, 0);

  const { data: clubInviteData } = useFetchClubInvites({pageSize: 20});

  const inviteRewards = clubInviteData?.reduce((acc: number, clubInvite) => {
    return acc + (clubInvite?.totalRewardAmount ?? 0);
  }, 0);

  return { totalContentRewards, accountRewards, contentCount, surveyRewards, inviteRewards, rewards };
}
