import * as CryptoJS from "crypto-js";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTOtp, syncConnections } from "../../../../../api";
import { useAppSelector } from "../../../../../redux/hooks";
import { Retailer } from "../../../../../redux/types";
import Loading from "../../../../Loading";
import StoreInput from "../../../../StoreComponents/StoreInput";
import { useSyncContext } from "../../../../SyncProvider";
import { AuthSignUpPageContainer } from "../../../Auth/AuthSignUpPageOutlet";
import AuthSolidButton from "../../../Auth/components/AuthSolidButton";
import { RetailerSyncFlowTypes } from "@brandclub/types";
import Box from "@mui/material/Box";
import { formatMoney } from "../../../../../utils/StringUtils";
import { useTheme } from "@mui/material/styles";

const ROW_SPACING = 25;

const ConnectRetailer = ({
  retailerId: retailerIdFromProps,
  onConnectCallback,
  connectOverwrite,
  redirectToSync = true,
  mode = RetailerSyncFlowTypes.CONNECT,
  errorMsg,
}: {
  retailerId?: number;
  onConnectCallback?: () => Promise<void>;
  connectOverwrite?: (userName: string, password: string) => void;
  redirectToSync?: boolean;
  mode?: RetailerSyncFlowTypes.FIX | RetailerSyncFlowTypes.CONNECT;
  errorMsg?: string;
}) => {
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [retailer, setRetailer] = useState<Retailer>();
  const { retailerId: retailerIdFromParams } = useParams();
  const retailers = useAppSelector(({ retailers }) => retailers);
  const { connectRetailer } = useSyncContext();

  const [logoLoading, setLogoLoading] = useState(true);

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const theme = useTheme();

  const getUserNameFieldHeading = (retailer: Retailer) => {
    if (retailer.retailerLoginUsernameFieldTypes?.length) {
      const supportedFieldTypes = retailer.retailerLoginUsernameFieldTypes;
      if (
        supportedFieldTypes.includes("email") &&
        supportedFieldTypes.includes("phoneNumber")
      ) {
        return "Email or mobile phone";
      }
    }
    return "Email";
  };

  useEffect(() => {
    if (retailers && (retailerIdFromParams || retailerIdFromProps)) {
      const currentRetailer = retailers.find(
        (r) =>
          r.retailerId ===
          parseInt(`${retailerIdFromParams || retailerIdFromProps}`)
      );
      if (currentRetailer) {
        setRetailer(currentRetailer);
      }
    }
  }, [retailerIdFromParams, retailerIdFromProps, retailers]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (retailer) {
          const { token, currentStamp } = await getTOtp();
          const accountsDataEncrypted = await syncConnections({ currentStamp });
          const accounts = JSON.parse(
            CryptoJS.AES.decrypt(accountsDataEncrypted, token).toString(
              CryptoJS.enc.Utf8
            )
          );
          const account = accounts?.find(
            (x: any) => x.retailerId === retailer.parentRetailerId
          );
          if (account) {
            setUsername(account.credential.username);
            setPassword(account.credential.password);
          }
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [retailer]);

  return (
    <AuthSignUpPageContainer>
      {!retailer || loading ? (
        <div className="page_title">
          <Loading dot />
        </div>
      ) : (
        <div className="auth_container">
          <img
            style={{ contentVisibility: logoLoading ? "hidden" : "visible" }}
            onLoad={() => setLogoLoading(false)}
            onLoadStart={() => setLogoLoading(true)}
            className="retailer_logo"
            alt="r_logo"
            src={retailer?.logo}
          ></img>
          <Box className="page_title" marginTop={5} textAlign="center">
            Login to continue
          </Box>
          <Box className="page_subtitle" marginTop={1.5} textAlign="center">
            Use your {retailer.displayName} username and password.
          </Box>
          <StoreInput
            label={getUserNameFieldHeading(retailer)}
            inputStyle={{ width: "100%" }}
            style={{ marginTop: ROW_SPACING }}
            onChange={(e) => {
              setUsername(e.target.value as any);
            }}
            sx={{ maxWidth: "100%" }}
            disabled={submitting}
            value={username}
          ></StoreInput>
          <StoreInput
            label="Password"
            // placeholder=""
            type="password"
            inputStyle={{ width: "100%" }}
            style={{ marginTop: ROW_SPACING }}
            onChange={(e) => {
              setPassword(e.target.value as any);
            }}
            sx={{ maxWidth: "100%" }}
            disabled={submitting}
            value={password}
            errorMsg={errorMsg}
          ></StoreInput>
          <AuthSolidButton
            style={{
              marginTop: ROW_SPACING + 5,
            }}
            disabled={
              submitting ||
              !username ||
              !password ||
              username.length < 1 ||
              password.length < 1
            }
            onClick={async () => {
              setSubmitting(true);
              if (username && password && connectOverwrite) {
                connectOverwrite(username, password);
              } else if (username && password) {
                await connectRetailer(
                  retailer.parentRetailerId || retailer.retailerId,
                  username,
                  password,
                  mode,
                  redirectToSync
                );
                if (onConnectCallback) {
                  await onConnectCallback();
                }
              }
              setSubmitting(false);
            }}
          >
            Connect
          </AuthSolidButton>
          {!!retailer.retailerConnectRewardAmount && (
            <Box className="reward_summary">
              Get {formatMoney(retailer.retailerConnectRewardAmount)} in rewards
              to connect your account.
            </Box>
          )}
          {theme.dtc && (
            <span className="terms">
              Rewards are subject to the Brandclub{" "}
              <a
                href="https://brandclub.com/terms"
                target="_blank"
                rel="noreferrer"
              >
                Reward Terms
              </a>{" "}
              and your information will be collected pursuant to their{" "}
              <a
                href="https://brandclub.com/privacy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              . Rewards must be redeemed through a preferred mobile app from{" "}
              {retailer.displayName}.
            </span>
          )}
        </div>
      )}
    </AuthSignUpPageContainer>
  );
};

export default ConnectRetailer;
