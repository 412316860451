import { moveAllUserRetailerShoppingCarts } from "@/api/rest/checkoutApi";
import {
  AnalyticsService,
  AnalyticsTrackingEvent,
  tryGetVisitorId,
} from "@brandclub/common-ui";
import { useActor } from "@xstate/react";
import { Hub } from "aws-amplify";
import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useBus } from "react-bus";
import { useLocation, useSearchParams } from "react-router-dom";
import { ActorLogicFrom, ActorRefFrom, SnapshotFrom } from "xstate";
import { transferVisitorActions } from "../../../api/rest/authenticated/transferVisitorActions";
import {
  clearSharedAuthCookie,
  getUserSignedInState,
  getVisitorIdCookie,
  setSharedAuthCookie,
} from "../../../Auth";
import { loadTopBrands, reloadProfile } from "../../../redux/actions";
import { loadReferralReward } from "../../../redux/actions/referralReward";
import { loadRewards } from "../../../redux/actions/rewards";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { clearCustomerSpendByBrand } from "../../../redux/reducers/customerSpendByBrand";
import { clearRewards } from "../../../redux/reducers/rewards";
import { clearUserProfile } from "../../../redux/reducers/userProfile";
import { CLOSE_LOGIN_DRAWER } from "../../../utils/busEvents";
import { useTrackActions } from "../../../utils/hooks/useTracking";
import Loading from "../../Loading";
import { userLoginMachine, UserLoginRoutes } from "./UserLoginMachines";

export const UserLoginContext = createContext<UserLoginContextType>(
  null as never
);

type InitialOpenUrl = {
  pathname: string;
  search?: string;
};

const getInitialOpenUrl = (url: InitialOpenUrl): InitialOpenUrl | undefined => {
  if (UserLoginRoutes.indexOf(url.pathname) === -1) {
    // ensures only the pathname and search are passed
    return {
      pathname: url.pathname,
      search: url.search,
    };
  }
  return undefined;
};

const UserLoginProvider = ({ children }: { children: ReactElement }) => {
  const [trackAction] = useTrackActions();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [initializeUserData, setInitializeUserData] = useState(true);
  const appConfig = useAppSelector(({ appConfig }) => appConfig);
  const [currentUsername, setCurrentUsername] = useState<any>(null);
  const [initialOpenUrl, setInitialOpenUrl] = useState(
    getInitialOpenUrl(location)
  );

  const [searchParams] = useSearchParams();
  let ssoRedirectUrl = searchParams.get("ssoRedirectUrl");
  if (!ssoRedirectUrl) {
    ssoRedirectUrl = searchParams.get("redirectUrl");
  }
  const updateInitialOpenUrl = useCallback((url?: InitialOpenUrl) => {
    if (!url) {
      setInitialOpenUrl(undefined);
      return;
    }
    const updatedOpenUrl = getInitialOpenUrl(url);
    if (updatedOpenUrl) {
      // the updatedOpenUrl is valid if it's defined
      setInitialOpenUrl(updatedOpenUrl);
    }
  }, []);

  const bus = useBus();
  const [event, setEvent] = useState<any>(null);

  const actor = useActor(userLoginMachine, {
    input: {
      ssoRedirectUrl,
      initialOpenUrl,
    },
    inspect: (inspectionEvent) => {
      if (inspectionEvent.type === "@xstate.event") {
        setEvent(inspectionEvent.event);
      }
    },
  });

  const [snapshot, send, actorRef] = actor;
  const clearStateForSignOut = useCallback(async () => {
    clearSharedAuthCookie();
    // clear all auth snapshot.in redux store
    dispatch(clearUserProfile());
    dispatch(clearRewards());
    dispatch(clearCustomerSpendByBrand());
  }, [dispatch]);

  const fetchUserData = useCallback(async () => {
    try {
      if (currentUsername) {
        Promise.all([
          dispatch(loadTopBrands()),
          dispatch(reloadProfile(true)),
          dispatch(loadReferralReward()),
          dispatch(loadRewards()),
        ]);
        // if user is logging in, we need to rerun the machine to get the app to the correct state
        if (snapshot.value !== "initializeUserData") {
          send({ type: "RERUN_MACHINE" });
        }
      }
    } catch (err) {
      console.error(err); // this means there is no currently authenticated user
    } finally {
      setInitializeUserData(false);
    }
  }, [currentUsername, dispatch, send, snapshot.value]);

  useEffect(() => {
    (async () => {
      try {
        const { signedIn } = await getUserSignedInState();
        if (signedIn) {
          AnalyticsService.track(AnalyticsTrackingEvent.AUTO_SIGN_IN, {});
        }
      } catch {
      }
      return {};
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setInitializeUserData(true);
      if (appConfig?.authConfig && currentUsername) {
        await setSharedAuthCookie();
        await fetchUserData();
      } else {
        const { signedIn, userInfo } = await getUserSignedInState();
        if (signedIn && userInfo) {
          setCurrentUsername(userInfo?.username);
        } else {
          setInitializeUserData(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appConfig?.authConfig, currentUsername]);

  const loadCartIntoUser = useCallback(async () => {
    try {
      const uniqueVisitorId = await tryGetVisitorId();
      const uniqueVisitorIdFromCookie = getVisitorIdCookie();
      const uniqueVisitorIdToUse =
        uniqueVisitorIdFromCookie && uniqueVisitorIdFromCookie !== ""
          ? uniqueVisitorIdFromCookie
          : uniqueVisitorId;
      if (uniqueVisitorIdToUse) {
        await moveAllUserRetailerShoppingCarts(uniqueVisitorIdToUse);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const transferVisitorRewardsToUser = useCallback(async () => {
    try {
      await transferVisitorActions();
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "customOAuthState":
          setInitialOpenUrl({
            pathname: JSON.parse(data).pathname,
            search: JSON.parse(data).search,
          });
          break;
        case "signUp":
          trackAction(AnalyticsTrackingEvent.SIGN_UP, {});
          break;
        case "signIn":
          // complete the sign in process but we need to wait for the complete event to after doing token refresh
          trackAction(AnalyticsTrackingEvent.SIGN_IN, {});
          break;
        case "signIn_Complete":
          loadCartIntoUser();
          transferVisitorRewardsToUser();
          setCurrentUsername(data?.username);
          break;
        case "signOut":
          bus.emit(CLOSE_LOGIN_DRAWER);
          trackAction(AnalyticsTrackingEvent.SIGN_OUT, {});
          clearStateForSignOut();
          setCurrentUsername(null);
          send({ type: "Hub.Auth.SignOut" });
          break;
        case "signIn_failure":
          trackAction(AnalyticsTrackingEvent.SIGN_IN_FAILURE, {});
          console.error("Auth Hub Event: signIn_failure", event, data);
          break;
        case "cognitoHostedUI_failure":
          console.error("Auth Hub Event: Sign in failure", data);
          break;
      }
    });

    return unsubscribe;
  }, [
    bus,
    clearStateForSignOut,
    loadCartIntoUser,
    send,
    trackAction,
    transferVisitorRewardsToUser,
  ]);

  if (!appConfig?.authConfig) {
    return <Loading fullscreen star />;
  }

  return (
    <UserLoginContext.Provider
      value={{
        initializeUserData,
        signedIn: !!currentUsername,
        actorRef: actorRef,
        snapshot: snapshot,
        initialOpenUrl,
        setInitialOpenUrl: updateInitialOpenUrl,
        send: send,
        event,
      }}
    >
      {children}
    </UserLoginContext.Provider>
  );
};

export type UserLoginContextType = {
  signedIn: boolean;
  initializeUserData: boolean;
  actorRef: ActorRefFrom<ActorLogicFrom<typeof userLoginMachine>>;
  snapshot: SnapshotFrom<typeof userLoginMachine>;
  send: ActorRefFrom<ActorLogicFrom<typeof userLoginMachine>>["send"];
  initialOpenUrl?: InitialOpenUrl;
  setInitialOpenUrl: (url?: InitialOpenUrl) => void;
  event: any;
};

export const useUserLogin = () => useContext(UserLoginContext);

export default UserLoginProvider;
