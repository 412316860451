import {
  addOptimisticShoppingCartItem,
  removeOptimisticShoppingCartItem,
  setAllCarts,
  setProducts,
  clearOptimisticAllCarts,
} from "../reducers/checkout";

import { sortBy, groupBy } from "lodash";
import {
  isAnyOf,
  createAction,
  ListenerEffectAPI,
  Dispatch,
  UnknownAction,
} from "@reduxjs/toolkit";
import {
  cancelActiveListeners,
  debounceListener,
  waitForSpecificActionTask,
} from "./utils";
import {
  flattenCartItems,
  createRetailerSkuMap,
} from "../../components/Checkout/utils";
import {
  updateItemsToUserRetailerShoppingCart,
  getAllUserRetailerShoppingCarts,
  removeItemFromShoppingCart,
} from "../../api/rest/checkoutApi";
import {
  ShoppingCartItem,
  UserRetailerShoppingCart,
} from "../../components/Checkout/types";
import { startAppListening } from "../store";

/**
 * Action creator for finalizing an optimistic checkout in the Redux store.
 * This action is used to synchronize the optimistic checkout state with the server response.
 */
const finalizeOptimisticCheckoutSync = createAction(
  "checkout/finalizeOptimisticCheckoutSync"
);

/**
 * Filters the shopping cart items in the optimistic state array of UserRetailerShoppingCart objects based on the filter function.
 *
 * @param optimisticState - The optimistic state array of UserRetailerShoppingCart objects.
 * @param currentState - The current state array of UserRetailerShoppingCart objects.
 * @param filterFn - The filter function used to determine if an optimistic item should be included in the result.
 * @returns An array of ShoppingCartItem objects that satisfy the filter function.
 */
const getFilteredOptimisticItems = (
  optimisticState: Partial<UserRetailerShoppingCart>[],
  currentState: UserRetailerShoppingCart[],
  filterFn: (
    optimisticItem: ShoppingCartItem,
    currentItem?: ShoppingCartItem
  ) => boolean
) => {
  const optimisticItems = flattenCartItems(optimisticState);
  const currentItemsMap = createRetailerSkuMap(currentState);
  return optimisticItems.filter((item) =>
    filterFn(item, currentItemsMap.get(item.retailerSku))
  );
};

/**
 * Returns an array of UserRetailerShoppingCart items that have been added or updated.
 *
 * @param optimisticState - The array of UserRetailerShoppingCart items representing the optimistic state.
 * @param currentState - The array of UserRetailerShoppingCart items representing the current state.
 * @returns An array of UserRetailerShoppingCart items that have been added or updated.
 */
const getAddedOrUpdatedItems = (
  optimisticState: Partial<UserRetailerShoppingCart>[],
  currentState: UserRetailerShoppingCart[]
) =>
  getFilteredOptimisticItems(
    optimisticState,
    currentState,
    (optimisticItem, currentItem) =>
      currentItem == null || currentItem.quantity !== optimisticItem.quantity
  );

/**
 * Fetches all the carts for a given user.
 *
 * @param userId - The ID of the user.
 * @returns A tuple containing the sorted userRetailerShoppingCarts and the products.
 */
const fetchAllCarts = async (
  userId: string | undefined,
  signal?: AbortSignal
) => {
  const { userRetailerShoppingCarts, products } =
    await getAllUserRetailerShoppingCarts(signal);
  return [sortBy(userRetailerShoppingCarts, "retailerId"), products] as const;
};

interface SyncWithServerParams {
  listenerApi: ListenerEffectAPI<unknown, Dispatch<UnknownAction>>;
  updates: ShoppingCartItem[];
  handleSyncShoppingCartWithServer: (
    retailerId: number,
    updates: ShoppingCartItem[]
  ) => Promise<UserRetailerShoppingCart>;
}
/**
 * Synchronizes the updates with the server for each retailer.
 *
 * @param listenerApi - The listener API object.
 * @param updates - The shopping cart item updates.
 * @param   handleSyncShoppingCartWithServer - The function to sync the updates with the server.
 */
const syncWithServer = async ({
  updates,
  handleSyncShoppingCartWithServer,
  listenerApi,
}: SyncWithServerParams) => {
  const retailerUpdates = groupBy(updates, (i) => i.retailerId);
  for (const retailerId in retailerUpdates) {
    // send the updates to the server for each retailer
    const updates = retailerUpdates[retailerId];
    try {
      await listenerApi.pause(
        handleSyncShoppingCartWithServer(+retailerId, updates)
      );
      listenerApi.dispatch(finalizeOptimisticCheckoutSync());
    } catch (e: any) {
      // don't log if it's TaskAbortError
      if (e?.name === "TaskAbortError") {
        return;
      }
      console.error(
        `failed to sync optimistic state with server for retailer ${retailerId}`,
        e
      );
    }
  }
};

// sync item quantities updated optimistically with the server
const setupSyncQuantityUpdateMiddleware = () => {
  startAppListening({
    matcher: isAnyOf(addOptimisticShoppingCartItem),
    effect: async (_action, listenerApi) => {
      debounceListener(listenerApi);

      const state = listenerApi.getState();
      if (!state.checkout.optimisticAllCarts) {
        // no optimistic state to sync
        return;
      }

      const updates = getAddedOrUpdatedItems(
        state.checkout.optimisticAllCarts,
        state.checkout.allCarts
      );
      if (!updates.length) {
        // no updates to perform
        return;
      }
      await syncWithServer({
        listenerApi,
        updates,
        handleSyncShoppingCartWithServer: async (retailerId, items) => {
          const { userRetailerShoppingCart } =
            await updateItemsToUserRetailerShoppingCart(
              {
                retailerId,
                items,
              },
              undefined,
              listenerApi.signal
            );
          return userRetailerShoppingCart;
        },
      });
    },
  });
};

// sync items removed optimistically with the server
const setupSyncRemoveItemsMiddleware = () => {
  startAppListening({
    actionCreator: removeOptimisticShoppingCartItem,
    effect: async (action, listenerApi) => {
      const state = listenerApi.getState();
      if (!state.checkout.optimisticAllCarts) {
        // no optimistic state to remove
        return;
      }
      const updates = action.payload.items;
      if (!updates.length) {
        // no items to remove
        return;
      }

      await syncWithServer({
        listenerApi,
        updates,
        handleSyncShoppingCartWithServer: async (retailerId, items) => {
          const { userRetailerShoppingCart } = await removeItemFromShoppingCart(
            {
              retailerId,
              items,
            }
          );
          return userRetailerShoppingCart;
        },
      });
    },
  });
};

// sync all carts state with the server after the optimistic changes have been synced
const setupSyncAllCartsStateMiddleware = () => {
  startAppListening({
    predicate: (action, state, currentItem) => {
      if (action.type === finalizeOptimisticCheckoutSync.type) {
        return true;
      }
      return false;
    },
    effect: async (_action, listenerApi) => {
      await debounceListener(listenerApi);
      const userId = listenerApi.getState().userProfile?.userId;
      // check if there are any ongoing optimistic actions
      const checkOptimisticCheckoutActionsActivity = waitForSpecificActionTask(
        listenerApi,
        [
          removeOptimisticShoppingCartItem.type,
          addOptimisticShoppingCartItem.type,
        ],
        500
      );
      // update active carts
      const [sortedAllCarts, products] = await fetchAllCarts(
        userId,
        listenerApi.signal
      );
      const activityResult =
        await checkOptimisticCheckoutActionsActivity.result;
      if (activityResult.status === "ok") {
        if (activityResult.value) {
          // there are ongoing optimistic actions, cancel the listener
          listenerApi.cancel();
          return;
        }
      }
      listenerApi.dispatch(setProducts(products));
      listenerApi.dispatch(setAllCarts(sortedAllCarts));
    },
  });

  startAppListening({
    matcher: isAnyOf(setAllCarts, setProducts),
    effect: async (_action, listenerApi) => {
      cancelActiveListeners(listenerApi);
    },
  });
};

// setup all middleware related to optimistic state changes
export const setupOptimisticCheckoutMiddleware = () => {
  setupSyncQuantityUpdateMiddleware();
  setupSyncRemoveItemsMiddleware();
  setupSyncAllCartsStateMiddleware();
};
