import { AddCircleIcon } from "@brandclub/common-ui";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import { HeaderLink, NAV_ATTACHMENT_SIZE } from ".";
import { useAppSelector } from "../../../redux/hooks";
import { formatMoney } from "../../../utils/StringUtils";
import Loading from "../../Loading";
import { useSyncContext } from "../../SyncProvider";
import { useSiteContext } from "../../SiteProvider";
import { SiteBrandingType } from "../../../types/misc";

const NavBalance = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 20,
  fontSize: 12,
  fontWeight: 600,
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  //   border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: 99,
  padding: "1px 13px 0 13px",
  margin: "0px",
}));

const AuthHeader = ({ onClose }: { onClose?: (e: any) => void }) => {
  const { siteBrandingType } = useSiteContext();
  const { rewards } = useAppSelector(({ rewards }) => rewards);
  const currentBalance = rewards.currentBalance;

  const {
    loading: syncLoading,
    syncAllRetailer,
    syncStatus,
  } = useSyncContext();

  return (
    <>
      {siteBrandingType === SiteBrandingType.BrandStore ||
      siteBrandingType === SiteBrandingType.CustomStore ? null : (
        <Link to={`/shop`} className="link" onClick={onClose}>
          <HeaderLink>
            <div className="label">Shop</div>
          </HeaderLink>
        </Link>
      )}

      {syncLoading ? (
        <div className="link" style={{ cursor: "pointer" }}>
          <HeaderLink>
            <div className="label">Loading...</div>
            <Loading storeSpin size={NAV_ATTACHMENT_SIZE} thickness={5} />
          </HeaderLink>
        </div>
      ) : (
        <>
          <div
            className="link"
            onClick={(e) => {
              if (onClose) {
                onClose(e);
              }
              syncAllRetailer();
            }}
            style={{ cursor: "pointer" }}
          >
            <HeaderLink>
              <div className="label">
                {syncStatus === "syncing" ? "Syncing" : "Sync"}
              </div>
              {syncStatus === "syncing" ? (
                <Loading storeSpin size={NAV_ATTACHMENT_SIZE} thickness={5} />
              ) : (
                <AddCircleIcon
                  backgroundColor="primary"
                  sx={(theme) => ({
                    fontSize: 18,
                    color: theme.palette.common.white,
                  })}
                />
              )}
            </HeaderLink>
          </div>
        </>
      )}

      {currentBalance ? (
        <Link to={"/dashboard/rewards"} className="link" onClick={onClose}>
          <HeaderLink>
            <div className="label">Rewards</div>
            <NavBalance>{formatMoney(currentBalance)}</NavBalance>
          </HeaderLink>
        </Link>
      ) : (
        <Link to={"/dashboard/rewards"} className="link" onClick={onClose}>
          <HeaderLink>
            <div className="label">Rewards</div>
          </HeaderLink>
        </Link>
      )}
    </>
  );
};
export default AuthHeader;
