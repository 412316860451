import { useCallback, useEffect, useState } from "react";
import _uniqBy from "lodash/uniqBy";
import {
  getSurveysForUserV2,
  getUnauthedSurveysForUser,
} from "../../../../../api";
import { SearchResult } from "../../../../../api/types/searchResult";
import reduxApolloClient from "../../../../../redux/reduxApolloClient";
import { SurveyRewardCampaignForCarousel } from "../../../../../types/misc";
import ConfigUtils from "../../../../../utils/ConfigUtils";
import { useUserLogin } from "@/components/pages/Auth/UserLoginProvider";

export const useFetchSurveys = ({
  autoInitFetch = true,
  brandclubIds,
  includedCampaignIds,
  pageSize = 25,
}: {
  autoInitFetch?: boolean;
  brandclubIds?: string[];

  includedCampaignIds?: string[];
  pageSize?: number;
}) => {
  const [data, setData] = useState<SurveyRewardCampaignForCarousel[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const signedIn = useUserLogin().signedIn;

  const fetchData = useCallback(
    async (pageNumber = 0) => {
      const RES_KEY = signedIn ? "SurveysForUserV2" : "UnauthedSurveysForUser";
      const query = signedIn ? getSurveysForUserV2 : getUnauthedSurveysForUser;

      try {
        setError(null);

        const res = await reduxApolloClient.query<{
          [k: string]: SearchResult<SurveyRewardCampaignForCarousel>;
        }>({
          query,
          variables: {
            brandclubIds,
            includedCampaignIds,
            page: {
              start: pageNumber,
              size: pageSize,
            },
          },
          fetchPolicy: "network-only",
          context: {
            uri: `${ConfigUtils.getGraphQlUri()}?id=${RES_KEY}`,
          },
        });

        const newData = res?.data?.[RES_KEY]?.items || [];
        const newHasMore = res?.data?.[RES_KEY]?.hasMore ?? false;
        setData((d) => _uniqBy([...d, ...newData], "campaignId"));
        setHasMore(newHasMore);
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message);
        }
        console.error(e);
      }
    },
    [brandclubIds, pageSize, includedCampaignIds]
  );

  const fetchInit = useCallback(async () => {
    try {
      setLoading(true);
      await fetchData(0);
    } finally {
      setPage(0);
      setLoading(false);
    }
  }, [fetchData]);

  const fetchMore = async () => {
    try {
      setLoadingMore(true);
      await fetchData((page + 1) * pageSize);
    } finally {
      setPage((p) => p + 1);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    if (autoInitFetch) {
      fetchInit();
    }
  }, [autoInitFetch, fetchInit]);

  return { data, loading, error, hasMore, fetchMore, loadingMore, fetchInit };
};
