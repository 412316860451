import { styled } from "@mui/material/styles";

import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../AppNavigation/constants";

const OTPStyledWrapper = styled("form")(({ theme }) => ({
  color: theme.palette.primary.main,
  maxWidth: 420,
  display: "flex",
  minWidth: 380,
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
  top: "12%",
  paddingBottom: 74,
  height: "fit-content",
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    minWidth: 300,
  },
  ".auth_container": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Poppins",
    minHeight: "unset",
    padding: "unset",

    ".page_title": {
      fontWeight: "bold",
      fontSize: 24,
      textAlign: "center",
      marginTop: 30.5,
      marginBottom: "unset",
    },
    ".page_subtitle": {
      marginTop: 16,
      textAlign: "center",
      fontWeight: 500,
    },
    ".termsAgreement": {
      width: "250px",

      maxWidth: "250px",
      fontSize: 12,
      ".MuiBox-root": {
        color: theme.palette.primary.main,
        opacity: 0.3,
        marginTop: "30.5px !important",
        fontSize: "inherit !important",
      },
    },
  },
  ".title": {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
  },
  ".subtitle": {
    marginTop: 16,
    textAlign: "center",
  },
  ".store-input": {
    maxWidth: "100%",
  },
  ".MuiFormControlLabel-label": {
    fontSize: 14,
    fontWeight: 500,
  },
  ".MuiFormControlLabel-root": {
    margin: 0,
  },
}));

const OTPWrapper = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  return (
    <OTPStyledWrapper
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      {children}
    </OTPStyledWrapper>
  );
};

export default OTPWrapper;
