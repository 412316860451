import { BrandClub } from "@brandclub/common-ui";
import { RetailerWithSyncStatus } from "../redux/types";
import _toNumber from "lodash/toNumber";

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const download = (url: string, filename: string) => {
  var a = document.createElement("a");
  a.href = url;
  a.setAttribute("download", filename);
  a.click();
};

export const deferredPromise = <T = unknown>() => {
  let resolve: (value: T) => void = () => void 0;
  let reject: (reason?: Error | unknown) => void = () => void 0;
  let isDone = false;
  const p = new Promise<T>((r, e) => {
    resolve = (v) => {
      isDone = true;
      r(v);
    };
    reject = (err) => {
      isDone = true;
      e(err);
    };
  });
  return { p, resolve, reject, isDone: () => isDone } as DeferredPromise<T>;
};

export type DeferredPromise<T> = {
  p: Promise<T>;
  isDone: () => boolean;
  resolve: (value: T) => void;
  reject: (reason?: Error | unknown) => void;
};

export const copyQueryParamsToUrlSearchParams = (
  params: Record<string, string | undefined>,
  searchParams = new URLSearchParams()
) => {
  if (!searchParams) {
    searchParams = new URLSearchParams();
  }
  Object.entries(params).forEach((c) => {
    const key = c[0];
    const val = c[1];
    if (key && val !== undefined) {
      searchParams.set(key, val);
    }
  });
  return searchParams;
};

/**
 * Checks if a value is defined (not null or undefined).
 *
 * @param value - The value to check.
 * @returns `true` if the value is defined, `false` otherwise.
 * @typeParam T - The type of the value.
 */
export const isDefined = <T>(value: T | undefined | null): value is T =>
  value != null;

// Define the maximum weight for the connection status
const MAX_CONNECTION_STATUS_WEIGHT = 5;

/**
 * Gets the weight assigned to a connection status.
 *
 * @param connectionStatus - The connection status.
 * @returns The weight assigned to the connection status.
 */
const getConnectionStatusWeight = (
  connectionStatus: RetailerWithSyncStatus["connectionStatus"]
) => {
  switch (connectionStatus) {
    case "invalid":
      return MAX_CONNECTION_STATUS_WEIGHT;
    case "valid":
      return MAX_CONNECTION_STATUS_WEIGHT - 1;
    default:
      return 0;
  }
};

/**
 * Safely divides two numbers, handling undefined values and division by zero.
 *
 * @param a - The dividend.
 * @param b - The divisor.
 * @returns The result of the division, or 0 if the division is undefined or division by zero occurs.
 */
export const safeDivide = (a?: number, b?: number) => {
  if (!isDefined(a) || !isDefined(b)) {
    return 0;
  }
  if (isNaN(a) || isNaN(b)) {
    return 0;
  }
  return b === 0 ? 0 : a / b;
};

const safeSum = (a?: number, b?: number) => {
  if (!a || isNaN(a)) a = 0;
  if (!b || isNaN(b)) b = 0;
  return a + b;
};

/**
 * Gets sort scores for retailers based on their connection status, sort score, and reward amount.
 * The highest score is assigned to retailers with an invalid connection status, followed by valid status and new status in that order.
 * The next highest score is assigned to retailers with the highest reward amount.
 * Lastly, the sort score is used to break ties.
 *
 * These scores can be used to sort retailers. The scores range from 0 to 100.
 *
 * @param retailers - The array of retailers to sort.
 * @returns A key-value pair of retailer IDs and their corresponding scores.
 */
export const getRetailerSortScores = (retailers: RetailerWithSyncStatus[]) => {
  const scores: Record<number, number> = {}; // Store retailerId and score

  // Find the maximum retailer sort score and reward amount to normalize the scores
  const MAX_RETAILER_SORT_SCORE = Math.max(
    ...retailers.map(({ sortScore }) => Number(sortScore || 0))
  );
  const MAX_RETAILER_REWARD = Math.max(
    ...retailers.map(({ retailerConnectRewardAmount }) =>
      Number(retailerConnectRewardAmount || 0)
    )
  );

  retailers.forEach(
    ({
      retailerId,
      sortScore,
      connectionStatus,
      retailerConnectRewardAmount,
    }) => {
      const retailerScore = _toNumber(sortScore || 0);
      const normalizedRetailerScore = safeDivide(
        retailerScore,
        MAX_RETAILER_SORT_SCORE
      );
      const connectionStatusWeight =
        getConnectionStatusWeight(connectionStatus);
      const normalizedConnectionStatusWeight = safeDivide(
        connectionStatusWeight,
        MAX_CONNECTION_STATUS_WEIGHT
      );
      const normalizedReward = safeDivide(
        retailerConnectRewardAmount,
        MAX_RETAILER_REWARD
      );

      // Calculate the overall score using weighted factors
      // Connection status has the highest weight (90), followed by reward (7) and sort score (3)
      scores[retailerId] =
        90 * normalizedConnectionStatusWeight +
        7 * normalizedReward +
        3 * normalizedRetailerScore;
    }
  );

  return scores;
};

export const sumCampaignRewardCounts = (brandClub: BrandClub): number => {
  const summary = brandClub.availableCampaignRewardsSummary;

  if (!summary) {
    return 0;
  }

  return Object.values(summary).reduce((total, aggregate) => {
    return total + (aggregate?.count || 0);
  }, 0);
};

export const hasCampaignRewards = (
  brandClub: BrandClub | undefined
): boolean => {
  if (!brandClub?.availableCampaignRewardsSummary) {
    return false;
  }
  return Object.entries(brandClub.availableCampaignRewardsSummary).some(
    ([_campaign, reward]) => {
      if (!reward?.amount) {
        return false;
      }
      return reward.amount > 0;
    }
  );
};

type ItemWithQuantity = {
  quantity?: number;
};
export const sumItemQuantities = (
  items: (ItemWithQuantity | undefined)[] | undefined
) => {
  if (!items) {
    return 0;
  }
  return items.reduce(
    (total, item) => safeSum(total, item ? item.quantity ?? 1 : 0),
    0
  );
};

type ItemWithItems = {
  items?: ItemWithQuantity[];
};
export const sumAllItemQuantities = (item: (ItemWithItems | undefined)[]) => {
  return item.reduce(
    (total, i) => safeSum(total, sumItemQuantities(i?.items)),
    0
  );
};

/**
 * Updates an item in an array without mutating the original array.
 * @param array The array to update.
 * @param index The index of the item to update.
 * @param value The new value to replace the item at the specified index.
 * @returns A new array with the item at the specified index replaced with the new value.
 */
export const updateArray = <T>(array: T[], index: number, value: T) => {
  const newArray = [...array];
  newArray[index] = value;
  return newArray;
};

/**
 * If the value is in the array, remove it. Otherwise, add it.
 * @param array The array to update.
 * @param value The value to remove if it exists, or add if it does not.
 * @returns A new array with the value removed if it exists, or added if it does not.
 */
export const toggleArrayValue = <T>(array: T[], value: T) => {
  const newArray = [...array];
  const index = newArray.indexOf(value);
  if (index !== -1) {
    newArray.splice(index, 1);
  } else {
    newArray.push(value);
  }
  return newArray;
};

type RemoveFunctionFields<T> = T extends (...args: any[]) => any
  ? never
  : T extends object
  ? { [K in keyof T]: RemoveFunctionFields<T[K]> }
  : T;

/**
 * Removes function fields from an object recursively.
 *
 * @param input - The input object.
 * @returns - The object with function fields removed.
 */
export const removeFunctionFields = <T extends object>(
  input: T
): RemoveFunctionFields<T> => {
  if (typeof input !== "object" || input === null) {
    return input as RemoveFunctionFields<T>;
  }

  if (Array.isArray(input)) {
    return input
      .filter((item) => typeof item !== "function")
      .map((item) => removeFunctionFields(item)) as RemoveFunctionFields<T>;
  }

  const result: any = {};

  for (const [key, value] of Object.entries(input)) {
    if (typeof value === "function") {
      continue;
    } else if (typeof value === "object" && value !== null) {
      result[key] = removeFunctionFields(value);
    } else {
      result[key] = value;
    }
  }

  return result as RemoveFunctionFields<T>;
};
