import { styled } from "@mui/material";
import StoreMessageCard from "./StoreMessageCard";

export const DashboardStoreMessageCard = styled(StoreMessageCard)(({ theme }) => ({
  marginTop: 40,
  [theme.breakpoints.down("sm")]: {
    marginTop: 30,
  },
  ".store-message-card__cta": {
    width: "100%",
    maxWidth: 355,
  },
}));
