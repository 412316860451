import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAppConfigFromServer } from "../../../api";
import ReduxStore from "../../types";
import { getBrandAppConfig } from "../../../api";

export const getAppConfig = createAsyncThunk(
  "/appConfig/getAppConfig",
  // eslint-disable-next-line no-empty-pattern
  async ({}: {}, thunkAPI) => {
    const { appConfig } = thunkAPI.getState() as ReduxStore;
    if (appConfig) {
      return new Promise((resolve, reject) => {
        resolve(appConfig as any);
      });
    }
    return getAppConfigFromServer();
  }
);

export const getBrandAppConfigs = createAsyncThunk(
  "/appConfig/getBrandAppConfig",
  async (req: { appId: string | undefined }) => {
    if (!req.appId) {
      return { error: "No appId provided" };
    }
    try {
      const response = await getBrandAppConfig({ appId: req.appId });
      return response;
    }
    catch (e: any) {
      return { error: e.message || JSON.stringify(e) };
    }
  }
);
