import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import { CardGrid, LongCardGrid } from "../components/CardGrid";
import { PreviewHeader } from "../components/Header";
import LandingPageWrapper from "../components/LandingPageWrapper";
import DepartmentHeader from "./components/DepartmentHeader";
import { useFetchDepartmentProduct } from "./data";
import { Department, departmentMapping } from "./departmentMapping";
import DepartmentLandingBestInner from "./landingBest";
import LongProductCard from "../../../../StoreComponents/StoreCard.tsx/LongProductCard";
import DepartmentLandingInstantInner from "./landingInstant";
import DepartmentLandingPopularInner from "./landingPopular";
import ProductCard from "../../../../StoreComponents/StoreCard.tsx/ProductCard";
import LandingContainer from "../components/LandingContainer";

const DepartmentLandingInner = ({ departmentId }: { departmentId: number }) => {
  const { data: instantData, loading: instantLoading } =
    useFetchDepartmentProduct({
      departmentIds: [departmentId],
      type: "instant",
      pageSize: 10,
    });

  const { data: bestData, loading: bestLoading } = useFetchDepartmentProduct({
    departmentIds: [departmentId],
    type: "best",
    pageSize: 8,
  });

  const { data: popularData, loading: popularLoading } =
    useFetchDepartmentProduct({
      departmentIds: [departmentId],
      type: "popular",
      pageSize: 10,
    });

  return (
    <div>
      <>
        {instantLoading ? (
          <StoreLoadingSkeleton header />
        ) : instantData.length ? (
          <LandingContainer>
            <PreviewHeader
              title={"Instant rewards"}
              linkTo={`/department/${departmentId}/instant`}
              subtitle="Unlock rewards instantly when purchased"
              indicator="grey"
            />
            <CardGrid>
              {instantData.map((p) => (
                <ProductCard
                  key={`trending:${p.stacklineSku}`}
                  LinkComponent={Link}
                  product={p}
                />
              ))}
            </CardGrid>
          </LandingContainer>
        ) : null}
      </>
      <>
        {bestLoading ? (
          <StoreLoadingSkeleton header />
        ) : bestData.length ? (
          <LandingContainer>
            <PreviewHeader
              title={"Best rewards"}
              linkTo={`/department/${departmentId}/best`}
              subtitle="Get the best deals and rewards"
              indicator="grey"
            />
            <LongCardGrid>
              {bestData.map((p) => (
                <LongProductCard key={p.stacklineSku} product={p} />
              ))}
            </LongCardGrid>
          </LandingContainer>
        ) : null}
      </>
      <>
        {popularLoading ? (
          <StoreLoadingSkeleton header />
        ) : popularData.length ? (
          <LandingContainer>
            <PreviewHeader
              title={"Most popular"}
              linkTo={`/department/${departmentId}/popular`}
              subtitle="Shop the best sellers"
              indicator="grey"
            />
            <CardGrid>
              {popularData.map((p) => (
                <ProductCard
                  key={`trending:${p.stacklineSku}`}
                  LinkComponent={Link}
                  product={p}
                />
              ))}
            </CardGrid>
          </LandingContainer>
        ) : null}
      </>
    </div>
  );
};

const DepartmentLanding = () => {
  const [department, setDepartment] = useState<Department | undefined>();
  const { departmentId } = useParams<{ departmentId: string }>();
  const navigate = useNavigate();
  useEffect(() => {
    if (departmentId) {
      if (departmentMapping[+departmentId]) {
        setDepartment(departmentMapping[+departmentId]);
      } else {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  if (!department) {
    return null;
  }

  return (
    <LandingPageWrapper>
      <LandingContainer>
        <DepartmentHeader
          image={department?.imageUrl}
          name={department?.departmentName}
        ></DepartmentHeader>
        <DepartmentLandingInner
          departmentId={department?.departmentId as number}
        />
      </LandingContainer>
    </LandingPageWrapper>
  );
};

export const DepartmentLandingDetail = ({
  type,
}: {
  type: "instant" | "best" | "popular";
}) => {
  const { departmentId } = useParams<{ departmentId: string }>();

  const [department, setDepartment] = useState<Department | undefined>();

  const navigate = useNavigate();
  useEffect(() => {
    if (departmentId) {
      if (departmentMapping[+departmentId]) {
        setDepartment(departmentMapping[+departmentId]);
      } else {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  if (!department) {
    return null;
  }
  return (
    <LandingPageWrapper>
      <LandingContainer>
        <DepartmentHeader
          image={department?.imageUrl}
          name={department?.departmentName}
        ></DepartmentHeader>
        {type === "best" && (
          <DepartmentLandingBestInner
            departmentId={department.departmentId as number}
          />
        )}
        {type === "instant" && (
          <DepartmentLandingInstantInner
            departmentId={department.departmentId as number}
          />
        )}
        {type === "popular" && (
          <DepartmentLandingPopularInner
            departmentId={department.departmentId as number}
          />
        )}
      </LandingContainer>
    </LandingPageWrapper>
  );
};

export default DepartmentLanding;
