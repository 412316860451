import { MainContainer, MainWrapper } from "../../Layout/MainOutlet";

const LandingPageWrapper = ({
  backgroundColor = "white",
  children,
  className,
  wrapperClassName,
}: {
  backgroundColor?: "grey" | "white";
  children: React.ReactNode;
  className?: string;
  wrapperClassName?: string;
}) => {
  return (
    <MainContainer
      className={className}
      style={{
        background: backgroundColor === "white" ? "#ffffff" : "#f6f8fa",
      }}
    >
      <MainWrapper className={wrapperClassName}>{children}</MainWrapper>
    </MainContainer>
  );
};

export default LandingPageWrapper;
