import StoreInput from "../../StoreComponents/StoreInput";

export const CheckoutInput = (
  props: React.ComponentProps<typeof StoreInput>
) => {
  return (
    <StoreInput
      inputStyle={{ width: "100%", maxWidth: "100%", minWidth: 200 }}
      {...props}
      textFieldStyle={{ maxWidth: "100%" }}
    />
  );
};
