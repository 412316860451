import { UserProfile } from "@brandclub/types";
import { getProfile } from "../../api";
import { AppDispatch } from "../store";
import { clearUserProfile, setUserProfile } from "../reducers/userProfile";
import ReduxStore, { Address } from "../types";

export const reloadProfile =
  (refreshCache?: boolean) => async (dispatch: AppDispatch) => {
    // On failure, we should force the user to logout
    try {
      await getProfile({ refreshCache }, async (profileRes) => {
        if (profileRes.data && profileRes.data.status !== "error") {
          const profile = profileRes.data as UserProfile;
          dispatch(setUserProfile(profile));
        } else {
          dispatch(clearUserProfile());
        }
      });
    } catch (e) {
      console.error("reloadProfile error: ", e);
    }
  };

export const updateAddresses =
  (addresses: Address[]) =>
  async (dispatch: AppDispatch, getState: () => ReduxStore) => {
    try {
      const { userProfile } = getState();
      const newProfile = { ...userProfile, addresses };
      dispatch(setUserProfile(newProfile));
    } catch (e) {
      console.error("loadReferralReward error: ", e);
    }
  };
