import { useState, useEffect } from "react";
import { ProfileContainer } from "../Profile";
import StoreInput from "../../../../StoreComponents/StoreInput";
import { ButtonLoadingEllipsis } from "../../../../StoreComponents/StoreButton";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { Link, useNavigate, useParams } from "react-router-dom";
import { addEditAddress, deleteAddress } from "../../../../../api";
import { Address } from "../../../../../redux/types";
import { updateAddresses } from "../../../../../redux/actions";
import {
  DashboardOutlineButton,
  DashboardSolidButton,
  DashboardSolidButtonGrey,
} from "../DashboardOutlet";
import GenericDialog from "../../../../StoreComponents/StoreDialogs/GenericDialog";
import { DashboardHeaderContainer } from "../DashboardHeaderContainer";

const ADDRESS_PATH = "/dashboard/address";
const EditAddress = ({ mode = "new" }: { mode: "new" | "edit" }) => {
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address1, setAddress1] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userProfile = useAppSelector(({ userProfile }) => userProfile);
  const { addressId } = useParams();

  useEffect(() => {
    if (mode === "edit" && userProfile && addressId && !deleting && !saving) {
      const { addresses } = userProfile;
      const address = addresses.find((address) => address.id === addressId);
      if (address && address.id) {
        // init address
        const { firstName, lastName, address1, city, state, zipCode } = address;

        setFirstName(firstName);
        setLastName(lastName);
        setAddress1(address1);
        setCity(city);
        setState(state);
        setZipCode(zipCode);
      } else {
        navigate(`${ADDRESS_PATH}/new`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, addressId, mode, deleting, saving]);

  const handleSave = async () => {
    const address: Address & {
      id?: string | undefined;
    } = {
      firstName,
      lastName,
      address1,
      city,
      state,
      zipCode,
      country: "United States",
    };

    if (mode === "edit" && addressId) {
      address.id = addressId;
    }

    try {
      setSaving(true);
      const res: any = await addEditAddress(address);

      if (res?.data?.status === "success") {
        if (res?.data?.addresses) {
          dispatch(updateAddresses(res?.data?.addresses));
          navigate(ADDRESS_PATH);
        }
      }
    } catch (e) {
      console.error("error updating address: ", e);
    } finally {
      setSaving(false);
    }
  };
  const handleDelete = async () => {
    try {
      setDeleting(true);
      const res: any = await deleteAddress(addressId ?? "");

      if (res?.data?.status === "success") {
        if (res?.data?.addresses) {
          dispatch(updateAddresses(res?.data?.addresses));
          navigate(ADDRESS_PATH);
        }
      }
    } catch (e) {
      console.error("error deleting address: ", e);
    } finally {
      setDeleting(false);
    }
  };

  const showConfirmDeleteDialog = () => {
    GenericDialog.show({
      title: "Are you sure you want to delete this address?",
      text: "Once deleted, this action cannot be undone.",

      actions: [
        {
          text: "Cancel",
          onClick: () => {},
          variant: "outline",
        },
        {
          text: "Delete address",
          onClick: handleDelete,
          variant: "solid",
        },
      ],
    });
  };

  const sendingRequest = saving || deleting;

  return (
    <ProfileContainer>
      <div className="profile_header_container" style={{}}>
        <div className="profile_header">
          <DashboardHeaderContainer>
            <div className="title">
              {mode === "new" ? "Add address" : "Edit address"}
            </div>
          </DashboardHeaderContainer>
        </div>
        {mode === "new" || userProfile?.addresses?.length === 1 ? null : (
          <div className="header_action">
            <DashboardSolidButtonGrey
              disabled={sendingRequest}
              onClick={showConfirmDeleteDialog}
            >
              {deleting ? (
                <>
                  Deleting
                  <ButtonLoadingEllipsis baseFontSize={16} />
                </>
              ) : (
                "Delete address"
              )}
            </DashboardSolidButtonGrey>
          </div>
        )}
      </div>
      <div className="profile_fields">
        <StoreInput
          label="First name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          sx={{ maxWidth: "100%" }}
        />
        <StoreInput
          label="Last name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          sx={{ maxWidth: "100%" }}
        />
        <StoreInput
          label="Address"
          value={address1}
          onChange={(e) => setAddress1(e.target.value)}
          sx={{ maxWidth: "100%" }}
        />
        <StoreInput
          label="City"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          sx={{ maxWidth: "100%" }}
        />
        <StoreInput
          label="State"
          value={state}
          onChange={(e) => setState(e.target.value)}
          sx={{ maxWidth: "100%" }}
        />
        <StoreInput
          label="Zip code"
          value={zipCode}
          onChange={(e) => setZipCode(e.target.value)}
          sx={{ maxWidth: "100%" }}
        />
      </div>
      <div className="profile_action">
        <Link to="/dashboard/address" style={{ textDecoration: "none" }}>
          <DashboardOutlineButton>Cancel</DashboardOutlineButton>
        </Link>
        <div style={{ flex: 1 }}></div>
        <DashboardSolidButton
          disabled={sendingRequest}
          style={{}}
          onClick={handleSave}
        >
          {saving ? (
            <>
              Saving
              <ButtonLoadingEllipsis baseFontSize={12} />
            </>
          ) : (
            "Save"
          )}
        </DashboardSolidButton>
      </div>
    </ProfileContainer>
  );
};

export default EditAddress;
