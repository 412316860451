import { useFetchPurchase } from "./data";

import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import PurchaseListRow from "./PurchaseListRow";
import { styled, useMediaQuery } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { isItemCheckoutSuccess } from "../../../../Checkout/utils";
import { useAppSelector } from "../../../../../redux/hooks";
import { useSiteContext } from "../../../../SiteProvider";
import { MOBILE_SCREEN_SIZE } from "../../../../AppNavigation/constants";
import { useDashboardLinkPrefix } from "../../../../../utils/hooks/useDashboardLinkPrefix";
import { SolidButton } from "../../../../StoreComponents/StoreButton";
import { SiteBrandingType, StoreBrandingType } from "../../../../../types/misc";

const MiniHeader = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",

  marginBottom: "30px",
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    marginBottom: 16.5,
  },
  ".header": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: 700,
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 18,
    },
  },
  ".link": {
    display: "flex",
    flexDirection: "row",

    alignItems: "center",
    color: theme.palette.primary.main,
    fontSize: "16px",
    fontWeight: 600,
  },
}));

const EmptyContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "18px",
  ".placeholder": {
    width: 100,
    height: 114,
    backgroundColor: "#f6f8fa",
    borderRadius: 3,
  },
  ".info": {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    ".title": {
      color: theme.palette.primary.main,
      fontSize: 16,
      fontWeight: 700,
    },
  },
}));

const EmptyPlaceholder = () => {
  const appConfig = useAppSelector((state) => state.appConfig);

  const linkTo =
    appConfig?.domainConfig?.storeBrandingType ===
      StoreBrandingType.CustomDTCStore &&
    appConfig?.domainConfig?.customShopDomainName
      ? `https://${appConfig?.domainConfig?.customShopDomainName}/`
      : `/shop`;
  const navigate = useNavigate();

  const handleExploreProducts = () => {
    if (linkTo && linkTo.startsWith("http")) {
      window.open(linkTo, "_self");
    } else if (linkTo) {
      navigate(linkTo);
    }
  };

  return (
    <EmptyContainer>
      <div className="placeholder"></div>
      <div className="info">
        <div className="title">You haven’t made any purchases yet</div>

        <SolidButton
          sx={{
            width: "fit-content",
            height: 40,
            fontSize: 12,
            [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
              fontSize: 12,
              height: 40,
            },
          }}
          onClick={handleExploreProducts}
        >
          Explore more products
        </SolidButton>
      </div>
    </EmptyContainer>
  );
};
const PurchasePreview = () => {
  const isMobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);
  const { siteBrandingType } = useSiteContext();

  const dashboardPrefix = useDashboardLinkPrefix();

  const purchasesPageUrl = `${dashboardPrefix}/purchase`;

  const mainEntity = useAppSelector((state) => state.mainEntity);
  const brandIds = useMemo(
    () =>
      (siteBrandingType === SiteBrandingType.BrandStore ||
        siteBrandingType === SiteBrandingType.CustomStore) &&
      mainEntity &&
      mainEntity.type === "brand"
        ? [mainEntity.id]
        : [],
    [mainEntity, siteBrandingType]
  );
  const { data, loading } = useFetchPurchase({
    pageSize: 1,
    brandIds,
  });

  const firstSuccessfulPurchase = useMemo(() => {
    return data.find((d) => isItemCheckoutSuccess(d));
  }, [data]);

  return (
    <div>
      {loading ? (
        <StoreLoadingSkeleton cardStyle="longCardList" header row={1} />
      ) : (
        <>
          <MiniHeader>
            {<div className="header">Recent orders</div>}
            {firstSuccessfulPurchase && !isMobileView ? (
              <Link to={purchasesPageUrl} style={{ textDecoration: "none" }}>
                <div className={"link"}>
                  {"View all purchases "}
                  <KeyboardArrowRightRoundedIcon />
                </div>
              </Link>
            ) : null}
          </MiniHeader>
          {firstSuccessfulPurchase ? (
            <PurchaseListRow
              item={firstSuccessfulPurchase}
              containerStyle={{ padding: 0 }}
              shouldShowTrackPackage={false}
            />
          ) : (
            <EmptyPlaceholder />
          )}
        </>
      )}
    </div>
  );
};

export default PurchasePreview;
