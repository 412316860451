import { gql } from "@apollo/client";

export const getUserRetailerCheckoutOrders = gql`
  query getUserRetailerCheckoutOrders(
    $statuses: [String!]
    $brandIds: [Float!]
    $page: PageFilter
    $sort: [SortFilter!]
  ) {
    UserRetailerCheckoutOrders(
      statuses: $statuses
      brandIds: $brandIds
      page: $page
      sort: $sort
    ) {
      items {
        checkoutBatchId
        orderId
        retailerOrderId
        userId
        updatedTime
        items
        products
        updatedTime
        status
        retailerId
        shippingAddress {
          address1
          address2
          city
          state
          zipCode
          country
          firstName
          lastName
        }
        billingAddress {
          address1
          address2
          city
          state
          zipCode
          country
          firstName
          lastName
        }
      }
      total
      maxPages
      nextPage
      hasMore
    }
  }
`;

export const getUserRetailerCheckoutOrderDetailBreakup = gql`
  query getUserRetailerCheckoutOrders(
    $orderIds: [String!]
    $statuses: [String!]
    $page: PageFilter
    $sort: [SortFilter!]
    $checkoutBatchIds: [String!]
  ) {
    UserRetailerCheckoutOrders(
      orderIds: $orderIds
      statuses: $statuses
      page: $page
      sort: $sort
      checkoutBatchIds: $checkoutBatchIds
    ) {
      items {
        checkoutBatchId
        orderId
        retailerOrderId
        userId
        updatedTime
        items
        products
        updatedTime
        status
        paymentMethod
        orderSummary
        retailerId
        shippingAddress {
          address1
          address2
          city
          state
          zipCode
          country
          firstName
          lastName
        }
        billingAddress {
          address1
          address2
          city
          state
          zipCode
          country
          firstName
          lastName
        }
      }
      total
      maxPages
      nextPage
      hasMore
    }
  }
`;
