import React, { useState, useEffect, useMemo, useContext } from "react";
import ConnectRetailer from "../../AppPages/Sync/Connect";
import { useSyncContext } from "../../../SyncProvider";
import { UserLoginContext } from "../UserLoginProvider";
import { useLocation } from "react-router-dom";
import { getErrorMessage } from "../../../../utils/errors/errorUtils";
import { useAppSelector } from "@/redux/hooks";
import { getAllCarts } from "@/redux/selectors";

const OnBoardingConnect = () => {
  const location = useLocation();
  const { search } = location;
  const { refreshSyncProvider } = useSyncContext();
  const { snapshot, send, event } = useContext(UserLoginContext);
  const [disconnectedRetailers, setDisconnectedRetailers] = useState<number[]>(
    []
  );
  const allCarts = useAppSelector(getAllCarts);

  const errorMessage = useMemo(() => {
    if (event?.type === "CONNECT_RETAILER_FAILED") {
      return getErrorMessage({ error: event.errorMessage });
    }
    return undefined;
  }, [event]);

  useEffect(() => {
    if (!event) {
      return;
    }

    if (search.includes("action=checkout")) {
      setDisconnectedRetailers(
        snapshot?.context?.disconnectedCheckoutRetailers
          ? Array.from(snapshot.context.disconnectedCheckoutRetailers)
          : []
      );
    } else if ("retailerId" in event) {
      const retailerId = event.retailerId;
      setDisconnectedRetailers([retailerId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCarts]);

  return (
    <ConnectRetailer
      retailerId={disconnectedRetailers[0]}
      errorMsg={errorMessage}
      onConnectCallback={async () => {
        await refreshSyncProvider();
        send({
          type: "CONNECT_RETAILER_IN_PROGRESS",
          retailerId: disconnectedRetailers[0],
        });
      }}
      redirectToSync={false}
    />
  );
};

export default OnBoardingConnect;
