import CircularProgress, {
  CircularProgressProps,
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import LottieInner from "react-lottie";
import BrandclubLoading from "../assets/lottie/brandclub_logo_load.json";
import DotLoading from "../assets/lottie/lottie_dot_loading.json";
import EllipsisLoading from "../assets/lottie/ellipsis.json";
import EllipsisBlueLoading from "../assets/lottie/ellipsis_darkblue.json";
// @ts-ignore
const Lottie = LottieInner.default
  ? // @ts-ignore
    LottieInner.default
  : LottieInner;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: BrandclubLoading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const dotOptions = {
  loop: true,
  autoplay: true,
  animationData: DotLoading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const ellipsisBlueOptions = {
  loop: true,
  autoplay: true,
  animationData: EllipsisBlueLoading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const ellipsisOptions = {
  loop: true,
  autoplay: true,
  animationData: EllipsisLoading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const StoreSpin = ({
  size,
  thickness,
  ...props
}: CircularProgressProps & { size?: number; thickness?: number }) => {
  return (
    <div
      style={{
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={(theme) => ({
          color: theme.palette.primary.main,
          opacity: 0.3,
        })}
        {...props}
        value={100}
        size={size}
        thickness={thickness} // need more calculation for large size
      />
      <CircularProgress
        // variant="indeterminate"
        disableShrink
        sx={(theme) => ({
          color: theme.palette.primary.main,
          animationDuration: "1200ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
            strokeDasharray: "30px,200px",
          },
        })}
        {...props}
        size={size}
        thickness={thickness} // need more calculation for large size
      />
    </div>
  );
};

const Loading = ({
  storeSpin,
  thickness = 7,
  dot,
  size,
  star,
  fullscreen = false,
  ellipsis,
  ellipsisBlue,
}: {
  ellipsis?: boolean;
  ellipsisBlue?: boolean;
  thickness?: number;
  storeSpin?: boolean;
  dot?: boolean;
  size?: number;
  star?: boolean;
  fullscreen?: boolean;
}) => {
  const LoadingSymbol = (
    <div
      className="loading_animation"
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      {ellipsisBlue ? (
        <Lottie
          options={ellipsisBlueOptions}
          isClickToPauseDisabled
          width={size ?? 30}
          height={size ?? 30}
        />
      ) : ellipsis ? (
        <Lottie
          options={ellipsisOptions}
          isClickToPauseDisabled
          width={size ?? 30}
          height={size ?? 30}
        />
      ) : storeSpin ? (
        <StoreSpin size={size ?? 100} thickness={thickness ?? 7} />
      ) : dot ? (
        <Lottie
          options={dotOptions}
          isClickToPauseDisabled
          width={size ?? 200}
          height="unset"
        />
      ) : star ? (
        <Lottie options={defaultOptions} isClickToPauseDisabled width={200} />
      ) : (
        <CircularProgress size={100} />
      )}
    </div>
  );

  if (fullscreen) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {LoadingSymbol}
      </div>
    );
  }

  return LoadingSymbol;
};

export default Loading;
