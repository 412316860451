import { useEffect, useRef, useState } from "react";
import "./BrandclubVideo.scss";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const BrandclubVideo = ({
  videoUrl,
  posterUrl,
  canPlay = true,
  lowerPlaybutton = false,
}: {
  videoUrl: string;
  posterUrl?: string;
  canPlay?: boolean;
  lowerPlaybutton?: boolean;
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [playing, setPlaying] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (!canPlay) {
      pauseVideo();
    }
  }, [canPlay]);

  const controls = useAnimation();

  useEffect(() => {
    pauseVideo();
    // if (canPlay) {
    videoSwitchAnimation();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoUrl]);

  const videoSwitchAnimation = async () => {
    if (canPlay && videoUrl) {
      await controls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0 },
      });
      await controls.start({
        opacity: 0,
        y: -10,

        transition: { duration: 0.3 },
      });
    }
    if (canPlay) {
      await controls.start({
        opacity: 0,
        y: 10,

        transition: { duration: 0 },
      });
      await controls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.3 },
      });
    }
  };

  // const videoSwitchAnimation = async () => {
  //   await controls.start({
  //     opacity: 0,
  //     scale: 0.9,

  //     transition: { duration: 0 },
  //   });
  //   return await controls.start({
  //     opacity: 1,
  //     scale: 1,
  //     transition: { duration: 0.4 },
  //   });
  // };
  const playVideo = () => {
    videoRef?.current?.play();
    setPlaying(true);
  };

  const pauseVideo = () => {
    videoRef?.current?.pause();
    setPlaying(false);
  };

  const handleEnd = () => {
    videoRef?.current?.load();
    setPlaying(false);
  };

  return (
    <motion.div className="brandclub_video" animate={controls} ref={ref}>
      {inView && (
        <>
          <div
            className={`video_control ${playing ? "playing" : "paused"} ${
              lowerPlaybutton ? "lower" : ""
            }`}
            role={"button"}
            onClick={() => {
              if (canPlay) {
                if (!playing) {
                  playVideo();
                } else {
                  pauseVideo();
                }
              }
            }}
          >
            {canPlay ? (
              !playing ? (
                <button className={`control_button play `}>
                  <img
                    className={"play_button"}
                    src={
                      "https://media.brandclub.com/brandclub/image_asset/video_play.svg"
                    }
                    alt={"play"}
                  />
                </button>
              ) : (
                <button className={`control_button pause`}>
                  <img
                    className="pause_button"
                    src={
                      "https://media.brandclub.com/brandclub/image_asset/video_pause.svg"
                    }
                    alt="pause"
                  />
                </button>
              )
            ) : null}
          </div>
          {!!videoUrl && (
            <video
              ref={videoRef}
              src={videoUrl}
              playsInline
              onEnded={() => {
                handleEnd();
              }}
              controls={false}
              // poster="https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/June_odd-eyed-cat_cropped.jpg/712px-June_odd-eyed-cat_cropped.jpg"
              style={{ background: "none" }}
              className="video_content"
              poster={posterUrl}
              width="100%"
            ></video>
          )}
        </>
      )}
    </motion.div>
  );
};

export default BrandclubVideo;
