import _get from "lodash/get";
import _uniqBy from "lodash/uniqBy";
import { useCallback, useEffect, useState } from "react";
import {
  getUserRetailerCheckoutOrderDetailBreakup,
  getUserRetailerCheckoutOrders,
} from "../../../../../api/graphQl/authenticated/Purchase";
import { SearchResult } from "../../../../../api/types/searchResult";
import { serverSideSyncApolloClient } from "../../../../../redux/reduxApolloClient";
import ConfigUtils from "../../../../../utils/ConfigUtils";
import { ShipmentStatus, UserPurchaseHistory } from "../../../../../types/misc";
import { ProductRewardSearchResult } from "../../../../../types/search";

const RESKEY = "UserRetailerCheckoutOrders";

export type PurchaseRowType = UserPurchaseHistory["items"][0] &
  ProductRewardSearchResult & {
    orderId: string;
    retailerOrderId: string;
    updatedTime: string;
    status: string;
    checkoutBatchId: string;
    id: string;
    retailerOrderPageUrl?: string;
    canWriteReview?: boolean;
    orderTracking?: {
      shipmentStatus?: ShipmentStatus;
    };
  };
export const useFetchPurchase = ({
  brandIds,
  pageSize = 25,
  autoInitFetch = true,
}: {
  brandIds?: number[];
  pageSize?: number;
  autoInitFetch?: boolean;
}) => {
  const [data, setData] = useState<PurchaseRowType[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(
    async (pageNumber = 0) => {
      try {
        setError(null);

        const res = await serverSideSyncApolloClient.query<{
          [RESKEY]: SearchResult<UserPurchaseHistory>;
        }>({
          query: getUserRetailerCheckoutOrders,
          variables: {
            statuses: ["checkout_success"],
            brandIds,
            page: {
              start: pageNumber,
              size: pageSize,
            },
          },
          fetchPolicy: "network-only",
          context: {
            uri: `${ConfigUtils.getServerSideSyncGraphQlUri()}?id=${RESKEY}`,
          },
        });
        const newData = _get(res, ["data", RESKEY, "items"], []);
        const newHasMore = _get(res, ["data", RESKEY, "hasMore"], false);
        let allNewProducts: PurchaseRowType[] = [];
        newData.forEach((obj) => {
          // Check if the object has an 'items' property and it is an array
          if (Array.isArray(obj.items)) {
            const items = obj.items.map((item) => {
              const newProductMetaData = obj.products[item.stacklineSku];
              return {
                orderId: obj.orderId,
                retailerOrderId: obj.retailerOrderId,
                updatedTime: obj.updatedTime,
                checkoutBatchId: obj.checkoutBatchId,
                ...newProductMetaData,
                ...item,
                status: item.status || obj.status,
              } as PurchaseRowType; // Add type assertion to ensure compatibility with PurchaseRowType
            });
            // Concatenate the 'items' array to the 'allItems' array
            allNewProducts = allNewProducts.concat(items);
          }
        });
        setData((d) => [...d, ...allNewProducts]);
        setHasMore(newHasMore);
      } catch (e: any) {
        setError(e);
      }
    },
    [pageSize, brandIds]
  );

  const fetchInit = useCallback(async () => {
    try {
      setLoading(true);
      await fetchData(0);
    } finally {
      setPage(0);
      setLoading(false);
    }
  }, [fetchData]);

  const fetchMore = useCallback(async () => {
    try {
      setLoadingMore(true);
      await fetchData((page + 1) * pageSize);
    } finally {
      setPage((p) => p + 1);
      setLoadingMore(false);
    }
  }, [fetchData, page, pageSize]);

  useEffect(() => {
    if (autoInitFetch) {
      fetchInit();
    }
  }, [autoInitFetch, fetchInit]);

  return { data, loading, error, hasMore, fetchMore, loadingMore, fetchInit };
};

export const useFetchPurchaseDetail = ({
  batchId,
  pageSize = 25,
  autoInitFetch = true,
}: {
  batchId: string;
  pageSize?: number;
  autoInitFetch?: boolean;
}) => {
  const [data, setData] = useState<UserPurchaseHistory[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(
    async (pageNumber = 0) => {
      try {
        setError(null);
        const res = await serverSideSyncApolloClient.query<{
          [RESKEY]: SearchResult<UserPurchaseHistory>;
        }>({
          query: getUserRetailerCheckoutOrderDetailBreakup,
          variables: {
            checkoutBatchIds: [batchId],
            page: {
              start: pageNumber,
              size: pageSize,
            },
          },
          fetchPolicy: "network-only",
          context: {
            uri: `${ConfigUtils.getServerSideSyncGraphQlUri()}?id=${RESKEY}`,
          },
        });
        const newData = _get(res, ["data", RESKEY, "items"], []);
        const newHasMore = _get(res, ["data", RESKEY, "hasMore"], false);
        setData((d) => _uniqBy([...d, ...newData], "orderId"));
        setHasMore(newHasMore);
      } catch (e: any) {
        setError(e);
      }
    },
    [pageSize, batchId]
  );

  const fetchInit = useCallback(async () => {
    try {
      setLoading(true);
      await fetchData(0);
    } finally {
      setPage(0);
      setLoading(false);
    }
  }, [fetchData]);

  const fetchMore = async () => {
    try {
      setLoadingMore(true);
      await fetchData((page + 1) * pageSize);
    } finally {
      setPage((p) => p + 1);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    if (autoInitFetch) {
      fetchInit();
    }
  }, [autoInitFetch, fetchInit]);

  return { data, loading, error, hasMore, fetchMore, loadingMore, fetchInit };
};
