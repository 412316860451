import { useEffect } from "react";
import LandingPageWrapper from "../../../pages/AppPages/Pages/components/LandingPageWrapper";
import AllOutOfStockScreen, { useIsAllOutOfStock } from "./AllOutOfStockScreen";
import CheckoutConnect from "./CheckoutConnect";
import { CheckoutMainFlow } from "./CheckoutMainFlow";
import CheckoutMFA from "./CheckoutMFA";
import CheckoutMFAChoose from "./CheckoutMFAChoose";
import CheckoutSuccess from "./CheckoutSuccess";
import EnterCheckoutAnimation from "./EnterCheckoutAnimation";
import { useLocation, useNavigate } from "react-router-dom";
import { closeCartDrawer } from "@/redux/reducers/checkout";
import { useAppDispatch } from "@/redux/hooks";
import { useCheckout } from "../../CheckoutMachine";
import { MfaValues } from "@/types/misc";
import CheckoutErrorDialog from "./CheckoutErrorDialog";

const CheckoutFlow = () => {
  const { state } = useLocation();
  const navigateFrom = state?.from;
  const navigate = useNavigate();
  const { snapshot } = useCheckout();

  const { allOutOfStock, showFullScreen } = useIsAllOutOfStock();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(closeCartDrawer());

    if (navigateFrom !== "init_checkout") {
      navigate("/mybag");
    }
  }, [navigate, navigateFrom]);

  const status = snapshot?.context?.globalCheckoutState;
  const otpStatus = snapshot?.context?.otpStatus;
  return (
    <LandingPageWrapper>
      <CheckoutErrorDialog />
      {allOutOfStock && showFullScreen ? (
        <AllOutOfStockScreen />
      ) : otpStatus === MfaValues.MfaChoose ? (
        <CheckoutMFAChoose />
      ) : otpStatus === MfaValues.MfaEnter ? (
        <CheckoutMFA />
      ) : status === "LOGIN_SCREEN" ? (
        <CheckoutConnect />
      ) : status === "CHECKOUT_IN_PROGRESS" ? (
        <EnterCheckoutAnimation />
      ) : status === "CHECKOUT_SUMMARY" ||
        status === "CHECKOUT_USER_INPUT_UPDATE_IN_PROGRESS" ? (
        <CheckoutMainFlow />
      ) : status === "CHECKOUT_SUCCESS" ? (
        <CheckoutSuccess />
      ) : null}
    </LandingPageWrapper>
  );
};

export default CheckoutFlow;
