import { styled } from "@mui/material";
import { MOBILE_SCREEN_SIZE } from "../../../../AppNavigation/constants";
import { PREVIEW_WIDGET_VERTICAL_PADDING } from "./Constant";
import { ComponentProps } from "react";

const LandingContainer = styled("div")({
  width: "100%",
  padding: `${PREVIEW_WIDGET_VERTICAL_PADDING}px 0 ${PREVIEW_WIDGET_VERTICAL_PADDING}px 0`,
  [`@media screen and (max-width: ${1250}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {},
});

export type LandingContainerProps = ComponentProps<typeof LandingContainer>;

export default LandingContainer;
