import { motion } from "framer-motion";

const CharactersLimit = (props: {
  text: string;
  minLength: number;
  maxLength?: number;
  style?: any;
}) => {
  const inputLength = (props.text || "").trim().length;
  const minimumLength = (props.minLength || 0) >= 0 ? props.minLength : 0;
  const maximumLength = props.maxLength;
  return (
    <motion.div
      style={{
        flexDirection: "row",
        justifyContent: "flex-end",
        position: "absolute",
        right: "0px",
        bottom: "-30px",
      }}
    >
      {inputLength < minimumLength && (
        <motion.div
          initial={{ opacity: 0, x: 10 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 10 }}
          style={{
            fontSize: 16,
            color: "#7E8FA8",
          }}
        >
          {`Must be at least ${minimumLength} characters`}
        </motion.div>
      )}

      {maximumLength && inputLength > maximumLength && (
        <motion.div
          initial={{ opacity: 0, x: 10 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 10 }}
          style={{
            fontSize: 16,
            color: "#7E8FA8",
          }}
        >
          {`Exceeded the ${maximumLength} characters limit`}
        </motion.div>
      )}
    </motion.div>
  );
};

export default CharactersLimit;
