import { AnalyticsService, AnalyticsTrackingEvent } from "@brandclub/common-ui";
import { getAuthorizationHeader } from "../../../Auth";
import axios, { axiosRetailerSync } from "../../../utils/axios";

import { RetailerSyncModeTypes, RetailerSyncFlowTypes } from "@brandclub/types";

export const syncRetailerServerSide = async ({
  req,
}: {
  req: {
    parentRetailerIds: number[];
  };
}) => {
  try {
    const url = "/retailerSync/syncRetailers";
    const res = await axiosRetailerSync.post(
      url,
      {
        type: "sync",
        cacheEable: false,
        parentRetailerIds: req.parentRetailerIds,
        syncModeOverRide: RetailerSyncModeTypes.SERVER_SIDE,
        retailerSyncFlow: RetailerSyncFlowTypes.SYNC,
      },
      {
        headers: {
          ...(await getAuthorizationHeader()),
          "content-type": "application/json",
        },
      }
    );
    return res.data;
  } catch (e) {}
};

export const connectRetailerServerSide = async ({
  req,
}: {
  req: {
    connectionInfo?: string; // required for connect but not sync
    parentRetailerIds: number[];
    currentStamp: number;
    retailerSyncFlow: RetailerSyncFlowTypes.CONNECT | RetailerSyncFlowTypes.FIX;
  };
}) => {
  try {
    const url = "/retailerSync/syncRetailers";
    const res = await axiosRetailerSync.post(
      url,
      {
        type: "sync",
        cacheEable: false,
        connectionInfo: req.connectionInfo,
        parentRetailerIds: req.parentRetailerIds,
        currentStamp: req.currentStamp,
        syncModeOverRide: RetailerSyncModeTypes.SERVER_SIDE,
        retailerSyncFlow: req.retailerSyncFlow,
      },
      {
        headers: {
          ...(await getAuthorizationHeader()),
          "content-type": "application/json",
        },
      }
    );
    return res.data;
  } catch (e) {}
};

export const getLatestSyncSession = async (abortSignal?: AbortSignal) => {
  return axiosRetailerSync.get("/retailerSync/getLatestSyncSession", {
    headers: {
      ...(await getAuthorizationHeader()),
    },
    params: {
      // sessionTypes: JSON.stringify(["sync", "liveShopping"]),
      sessionTypes: JSON.stringify(["sync"]),
    },
    signal: abortSignal,
  });
};

export const getRetailerConnections = async () => {
  return axiosRetailerSync.get("/retailerConnect/getRetailerConnections", {
    headers: {
      ...(await getAuthorizationHeader()),
    },
  });
};

export const getSyncStatusBySyncBatchId = async (
  req: {
    syncBatchId: string;
  },
  abortSignal: AbortSignal
) => {
  return axiosRetailerSync.post(
    "/retailerSync/getSyncStatusBySyncBatchId",
    req,
    {
      headers: {
        ...(await getAuthorizationHeader()),
      },
      signal: abortSignal,
    }
  );
};

export const updateMFADetails = async (req: {
  eventType: "enter" | "choose";
  otpValue?: string;
  selectedOption?: string;
  syncId: string;
  reqId: string;
}) => {
  try {
    const res = await axiosRetailerSync.post(
      "/retailerConnect/updateMFADetails",
      req,
      {
        headers: {
          ...(await getAuthorizationHeader()),
          "content-type": "application/json",
        },
      }
    );
    const eventName =
      req.eventType === "enter"
        ? AnalyticsTrackingEvent.SERVER_SIDE_SYNC_MFA_CHOOSE_SUCCESS
        : AnalyticsTrackingEvent.SERVER_SIDE_SYNC_MFA_ENTER_SUCCESS;
    AnalyticsService.track(eventName, {
      ...req,
    });
    return res.data;
  } catch (e) {
    const eventName =
      req.eventType === "enter"
        ? AnalyticsTrackingEvent.SERVER_SIDE_SYNC_MFA_CHOOSE_ERROR
        : AnalyticsTrackingEvent.SERVER_SIDE_SYNC_MFA_ENTER_ERROR;
    AnalyticsService.track(eventName, {
      ...req,
    });
  }
};

export const markSyncSummaryAsViewed = async (req: { syncBatchId: string }) => {
  return axiosRetailerSync.post(
    "/retailerConnect/markSyncSummaryAsViewed",
    req,
    {
      headers: {
        ...(await getAuthorizationHeader()),
      },
    }
  );
};

export const getCustomerSyncSummaries = async ({
  pageSize,
}: {
  pageSize: number;
}) => {
  try {
    const url = `/retailerConnect/getCustomerSyncSummaries?pageSize=${pageSize}`;
    const res = await axiosRetailerSync.get(url, {
      headers: {
        ...(await getAuthorizationHeader()),
        "content-type": "application/json",
      },
    });
    return res.data;
  } catch (e) {}
};

export const getSignedScreenshotUrl = async ({
  retailerId,
  syncId,
  fileName,
}: {
  retailerId: number;
  syncId: string;
  fileName: string;
}) => {
  try {
    const url = `/retailerConnect/getSignedScreenshotUrl`;
    const res = await axiosRetailerSync.post(
      url,
      {
        retailerId,
        syncId,
        fileName,
      },
      {
        headers: {
          ...(await getAuthorizationHeader()),
          "content-type": "application/json",
        },
      }
    );
    return res.data;
  } catch (e) {}
};

export const getTOtp = async () => {
  try {
    const url = `/retailerSync/getTOtp`;
    const res = await axiosRetailerSync.get(url, {
      headers: {
        ...(await getAuthorizationHeader()),
        "content-type": "application/json",
      },
    });
    return res.data;
  } catch (e) {}
};

export const syncConnections = async ({
  deviceConnectionInfo,
  currentStamp,
}: {
  deviceConnectionInfo?: string;
  currentStamp: number;
}) => {
  try {
    const url = `/retailerConnect/syncConnections`;
    const res = await axiosRetailerSync.post(
      url,
      {
        deviceConnectionInfo,
        currentStamp,
      },
      {
        headers: {
          ...(await getAuthorizationHeader()),
          "content-type": "application/json",
        },
      }
    );
    return res.data;
  } catch (e) {}
};

export interface UpdateConnectedRetailerRequest {
  retailerId: number;
  connectionState: "valid" | "invalid" | "notConnected";
}

export const updateConnectedRetailer = async (
  req: UpdateConnectedRetailerRequest
) => {
  const res = await axios.post("/order/updateConnectedRetailer", req, {
    headers: {
      ...(await getAuthorizationHeader()),
    },
  });
  return res;
};
