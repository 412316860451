import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../../initialState";
import { getCustomerSpendByBrand } from "./thunk";
import ReduxStore from "../../types";

const customerSpendByBrandSlice = createSlice({
  name: "customerSpendByBrand",
  initialState: initialState.customerSpendByBrand,
  reducers: {
    setCustomerSpendByBrand: (_state, action) => {
      return {
        ...action.payload,
        loading: false,
      };
    },

    clearCustomerSpendByBrand: () => {
      return {
        ...initialState.customerSpendByBrand,
        loading: false,
      };
    },
  },
  extraReducers: (builders) => {
    builders.addCase(getCustomerSpendByBrand.fulfilled, (state, action) => {
      const data = action?.payload?.data;
      if (data && data.CustomerSpendByBrand) {
        const customerSpendByBrand: ReduxStore["customerSpendByBrand"] = {
          brandIds: [],
          brandclubIds: [],
          spendByBrandId: {},
          loading: false,
        };
        data.CustomerSpendByBrand.forEach(
          (spendByBrand: {
            brandId: number;
            brandclubId?: string;
            brandName: string;
            spend: number;
          }) => {
            customerSpendByBrand.brandIds.push(spendByBrand.brandId);
            if (spendByBrand.brandclubId) {
              customerSpendByBrand.brandclubIds.push(spendByBrand.brandclubId);
            }
            customerSpendByBrand.spendByBrandId[spendByBrand.brandId] = {
              brandId: spendByBrand.brandId,
              brandName: spendByBrand.brandName,
              brandclubId: spendByBrand.brandclubId,
              spend: spendByBrand.spend,
            };
          }
        );
        return customerSpendByBrand;
      }
    });
  },
});

export const { setCustomerSpendByBrand, clearCustomerSpendByBrand } =
  customerSpendByBrandSlice.actions;
export default customerSpendByBrandSlice.reducer;
