import useStoreType from "../../../utils/hooks/useStoreType";
import { StoreBrandingType } from "../../../types/misc";
import SinglePageSkeletonFullLoading from "./SinglePageSkeletonFullLoading";
import StoreLoadingSkeletonFullSite from "./StoreLoadingSkeletonFullSite";

const StoreFullScreenLoading = ({
  storeHeader = true,
}: {
  storeHeader?: boolean;
}) => {
  const storeType = useStoreType();
  if (storeType === StoreBrandingType.CustomDTCStore) {
    return <SinglePageSkeletonFullLoading />;
  }
  return <StoreLoadingSkeletonFullSite header={storeHeader} />;
};

export default StoreFullScreenLoading;
