import { gql } from "@apollo/client";
import { ProductOperation } from "../unauthenticated";

export const AUTHENTICATED_SEARCH_PRODUCTS = gql`
  query searchProductsWithFullDetails(
    $titleInclude: [String!]
    $titleExclude: [String!]
    $stacklineSkus: [String!]
    $stacklineSkusExclude: [String!]
    $campaignTypes: [String!]
    $retailPrice: [RangeFilter!]
    $reviewsRating: RangeFilter
    $reviewsCount: RangeFilter
    $brandIds: [Float!]
    $departmentIds: [Float!]
    $categoryIds: [Float!]
    $subCategoryIds: [Float!]
    $retailerIds: [Float!]
    $isBrandClubReady: Boolean
    $totalRewardAmount: RangeFilter
    $page: PageFilter!
    $sort: [SortFilter!]
    $facetFieldNames: [String!]
    $computeFacets: Boolean!
    $applySpellingCheck: Boolean
  ) {
    ${ProductOperation.AuthenticatedSearch}(
      titleInclude: $titleInclude
      titleExclude: $titleExclude
      stacklineSkus: $stacklineSkus
      stacklineSkusExclude: $stacklineSkusExclude
      campaignTypes: $campaignTypes
      retailPrice: $retailPrice
      reviewsRating: $reviewsRating
      totalRewardAmount: $totalRewardAmount
      reviewsCount: $reviewsCount
      brandIds: $brandIds
      categoryIds: $categoryIds
      departmentIds: $departmentIds
      subCategoryIds: $subCategoryIds
      retailerIds: $retailerIds
      isBrandClubReady: $isBrandClubReady
      page: $page
      sort: $sort
      facetFieldNames: $facetFieldNames
      computeFacets: $computeFacets
      applySpellingCheck: $applySpellingCheck
    ) {
      products {
        checkoutEligibility
        stacklineSku
        title
        brandName
        brandId
        categoryId
        subCategoryId
        categoryName
        retailerId
        retailerSku
        retailPrice
        reviewsRating
        groupId
        groupName
        reviewsCount
        searchScore
        matchedProducts {
          retailPrice
          retailerId
          retailerProductPageUrl
          totalRewardAmount
          stacklineSku
          retailerSku
          checkoutEligibility
          isAvailable
          hasFirstPartyOffer
          offers
        }
        totalRewardAmount
        isAvailable
        hasFirstPartyOffer
        offers
        scores {
          maxPurchaseRewardPercent
          maxPurchaseRewardAmount
          maxAffiliateRewardPercent
          maxAffiliateRewardAmount
          totalReviewRewardFeesAmount
        }
        hasActiveInstantReward
        featureBullets
        productPromotions {
          promoType
          isActive
          listPrice
          promoPrice
        }
        imageUrls
        campaignRewards {
          campaignId
          campaignType
          rewardAmount
          rewardAmountType
          campaignStatus
        }
        rollupParentSku
        retailerProductPageUrl
        products {
          retailerSku
          retailPrice
        }
        activeProductPromotion {
          promoType
          isActive
          listPrice
          promoPrice
        }
        childProductMinRetailPrice
        childProductMaxRetailPrice
      }
      facets
      suggestedTerm
    }
  }
`;

export const productCheckoutEligibilityQuery = gql`
  query getProduct($productIds: [ProductIdInput!]) {
    GetMetadataByProductId(productIds: $productIds) {
      retailerId
      stacklineSku
      checkoutEligibility
      isAvailable
      hasFirstPartyOffer
      offers
      matchedProducts {
        retailerId
        retailPrice
        checkoutEligibility
        stacklineSku
        isAvailable
        hasFirstPartyOffer
        offers
      }
    }
  }
`;
