import { SolidButton } from "../../../../StoreComponents/StoreButton";
import { styled, useMediaQuery } from "@mui/material";

import { Link } from "react-router-dom";
import {
  RetailerIconGroup,
  SuccessCheckCircleIcon,
} from "@brandclub/common-ui";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../AppNavigation/constants";

const RetailerConnectCard = styled("div")({
  display: "flex",
  border: "1.5px solid #dedede",
  padding: "30px 33px",
  //   margin: "15px 0",
  borderRadius: 10,
  flexDirection: "row",
  alignItems: "center",
  backgroundColor: "#fff",
  gap: 26,
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    padding: "18px 15px",
    gap: 15,
  },
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    padding: "18px 15px",
    gap: 15,
  },
  ".logos": {},
  ".detail": {
    flex: 1,
    ".r_title": {
      fontSize: 18,
      fontWeight: 700,
      [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontWeight: 600,
        fontSize: 14,
      },
    },
    ".r_subtitle": {
      fontSize: 14,
      fontWeight: 500,
      [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: 12,
      },
    },
  },
  ".operations": {
    display: "flex",
    alignItems: "center",
    ".check": {
      width: 40,
      height: 40,
      [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
        width: 30,
        height: 30,
      },
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        width: 30,
        height: 30,
      },
    },
  },
});

const RetailerCardButton = styled(SolidButton)({
  height: 40,
  minWidth: 89,
  fontSize: 18,
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    fontSize: 14,
    height: 30,
    minWidth: 65,
  },
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    fontSize: 14,
    height: 30,
    minWidth: 65,
  },
});

const RetailerSummaryCard = ({ retailerIdList, type }: any) => {
  const mobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);
  if (retailerIdList.length <= 0) {
    return null;
  }
  return (
    <RetailerConnectCard>
      <div className="logos">
        <RetailerIconGroup
          retailerIds={retailerIdList}
          iconSize={mobileView ? 30 : 57}
        />
      </div>
      <div className="detail">
        <div className="r_title">
          {type === "success" ? "Sync successful" : "Needs attention"}
        </div>
        <div className="r_subtitle">
          {retailerIdList?.length}{" "}
          {retailerIdList.length > 1 ? "accounts" : "account"}
        </div>
      </div>
      <div className="operations">
        {type === "success" && <SuccessCheckCircleIcon className="check" />}
        {type === "fix" && (
          <Link to="/dashboard/accounts" style={{ textDecoration: "none" }}>
            <RetailerCardButton>Fix</RetailerCardButton>
          </Link>
        )}
      </div>
    </RetailerConnectCard>
  );
};

export default RetailerSummaryCard;
