import { String } from "aws-sdk/clients/batch";
import { DepartmentForRedux } from "../../../../../redux/types";

const DEPARTMENT_ICON_PREFIX =
  "https://media.brandclub.com/brandclub/departments/";

export interface Department {
  order: number;
  departmentName: string;
  departmentId?: number;
  imageUrl: String;
  hide?: boolean;
  empty?: boolean;
}

export const departmentMapping: Record<number, Department> = {
  1: {
    order: 9,
    departmentId: 1,
    departmentName: "Apparel",
    imageUrl: DEPARTMENT_ICON_PREFIX + "apparel.svg",
  },
  2: {
    order: 4,
    departmentId: 2,
    departmentName: "Baby",
    imageUrl: DEPARTMENT_ICON_PREFIX + "baby.svg",
  },
  3: {
    order: 6,
    departmentId: 3,
    departmentName: "Beauty",
    imageUrl: DEPARTMENT_ICON_PREFIX + "beauty.svg",
  },
  4: {
    order: 20,
    departmentId: 4,
    departmentName: "Automotive",
    imageUrl: DEPARTMENT_ICON_PREFIX + "automotive.svg",
  },
  5: {
    order: 1,
    departmentId: 5,
    departmentName: "Electronics",
    imageUrl: DEPARTMENT_ICON_PREFIX + "electronics.svg",
  },
  6: {
    order: 5,
    departmentId: 6,
    departmentName: "Grocery",
    imageUrl: DEPARTMENT_ICON_PREFIX + "grocery.svg",
  },
  7: {
    order: 7,
    departmentId: 7,
    departmentName: "Health",
    imageUrl: DEPARTMENT_ICON_PREFIX + "health.svg",
  },
  8: {
    order: 17,
    departmentId: 8,
    departmentName: "Furniture",
    imageUrl: DEPARTMENT_ICON_PREFIX + "furniture_and_decor.svg",
  },
  9: {
    order: 19,
    departmentId: 9,
    departmentName: "Home",
    imageUrl: DEPARTMENT_ICON_PREFIX + "home_improvement.svg",
  },
  26: {
    order: 16,
    departmentId: 26,
    departmentName: "Self-care",
    imageUrl: DEPARTMENT_ICON_PREFIX + "self-care.svg",
  },
  11: {
    order: 20,
    departmentId: 11,
    departmentName: "Hardware",
    imageUrl: DEPARTMENT_ICON_PREFIX + "industrial_hardware.svg",
  },
  12: {
    order: 13,
    departmentId: 12,
    departmentName: "Kitchen",
    imageUrl: DEPARTMENT_ICON_PREFIX + "kitchen.svg",
  },
  13: {
    order: 14,
    departmentId: 13,
    departmentName: "Lawn",
    imageUrl: DEPARTMENT_ICON_PREFIX + "lawn_and_garden.svg",
  },
  14: {
    order: 2,
    departmentId: 14,
    departmentName: "Office",
    imageUrl: DEPARTMENT_ICON_PREFIX + "office_supplies.svg",
  },
  15: {
    order: 15,
    departmentId: 15,
    departmentName: "Pet",
    imageUrl: DEPARTMENT_ICON_PREFIX + "pet.svg",
  },
  16: {
    order: 18,
    departmentId: 16,
    departmentName: "Tools",
    imageUrl: DEPARTMENT_ICON_PREFIX + "tools.svg",
  },
  17: {
    order: 11,
    departmentId: 17,
    departmentName: "Sports",
    imageUrl: DEPARTMENT_ICON_PREFIX + "sporting_goods.svg",
  },
  18: {
    order: 3,
    departmentId: 18,
    departmentName: "Toys",
    imageUrl: DEPARTMENT_ICON_PREFIX + "toys.svg",
  },
  24: {
    order: 10,
    departmentId: 24,
    departmentName: "Shoes",
    imageUrl: DEPARTMENT_ICON_PREFIX + "shoes.svg",
  },
  25: {
    order: 12,
    departmentId: 25,
    departmentName: "Jewelry",
    imageUrl: DEPARTMENT_ICON_PREFIX + "jewelry.svg",
  },
};

export const setMappings = (departments: DepartmentForRedux[]) => {
  const departmentsToRender: Department[] = [];
  if (departments) {
    departments.forEach((d) => {
      const { departmentId } = d;
      if (departmentMapping[departmentId]) {
        departmentsToRender.push(departmentMapping[departmentId]);
      }
    });
  }

  return departmentsToRender;
};
