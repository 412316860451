import { FC } from "react";
import { RewardUnlockStrategy } from "../../../../../../types/misc";
import { styled } from "@mui/material";
import FlowContainer from "../../components/FlowContainer";
import { formatMoney } from "../../../../../../utils/StringUtils";
import {
  CampaignType,
  RewardPillWithText,
  brandclub_colors,
} from "@brandclub/common-ui";
import { FlowSolidButton } from "../../components/FlowButtons";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../../AppNavigation/constants";
import { useSurvey } from "./SurveyProvider";

const CoverContainer = styled("div")(({ theme }) => ({
  maxWidth: 500,
  padding: 20,
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  flex: 1,

  ".logo": {
    maxWidth: 225,
    maxHeight: 225,
    objectFit: "contain",
    borderRadius: 999,
    aspectRatio: "1/1",
    marginBottom: 40,
  },
  ".survey_info": {
    marginBottom: 40,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ".title": {
      marginBottom: 15,
      fontSize: 24,
      fontWeight: 700,
      color: theme.palette.primary.main,
      textAlign: "center",
      [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: 24,
        marginBottom: 10,
      },
    },
    ".subtitle": {
      fontSize: 20,
      fontWeight: 500,
      marginBottom: 16,

      color: theme.palette.primary.main,
      textAlign: "center",
      [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: 18,
        marginBottom: 10,
      },
    },
  },
  ".est": {
    // marginTop: 22,
    marginTop: 20,
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
}));

const StyledRewardPill = styled(RewardPillWithText)(({ theme }) => ({
  columnGap: 12,
  ".reward-pill": {
    fontSize: 16,
    paddingTop: "5.5px",
    paddingBottom: "5.5px",
    paddingLeft: "15px",
    paddingRight: "15.9px",
  },
  ".rewardPercent-text": {
    fontSize: 16,
  },
}));

const Cover: FC<{ disabled?: boolean }> = (props) => {
  const { survey, moveToNextStep, numberOfQuestion } = useSurvey();
  const estimatedTime = Math.min((numberOfQuestion + 1) / 4 || 1, 3);

  const rewardAmount = survey?.extendedAttributes?.reward?.reward?.amount ?? 0;
  const hasInstantReward =
    survey?.extendedAttributes?.rewardUnlockStrategy ===
    RewardUnlockStrategy.Instant;

  return (
    <FlowContainer style={{ background: brandclub_colors.white }}>
      <CoverContainer>
        <img className="logo" alt="logo" src={survey.brandclub?.imageUrl} />
        <div className="survey_info">
          <div className="title">Complete survey</div>
          <div className="subtitle">
            You will earn{" "}
            {formatMoney(survey.extendedAttributes.reward.reward.amount)} in
            rewards for completing this survey from {survey?.brandclub?.name}.
          </div>
          <StyledRewardPill
            rewardAmount={rewardAmount ?? 0}
            rewardCampaignType={
              hasInstantReward
                ? CampaignType.DailyDealsRewardCampaign
                : CampaignType.SurveyRewardCampaign
            }
            containerStyle={{}}
            baseFontSize={14}
            rewardPercentTextOverride={
              hasInstantReward ? "instant reward" : "survey reward"
            }
          />
        </div>

        <FlowSolidButton
          style={{ width: "80%", maxWidth: 355 }}
          onClick={moveToNextStep}
          disabled={props.disabled}
        >
          Continue
        </FlowSolidButton>
        <div className="est">
          Estimated time: {Math.floor(estimatedTime)} minute
          {Math.floor(estimatedTime) <= 1 ? "" : "s"}
        </div>
      </CoverContainer>
    </FlowContainer>
  );
};

export default Cover;
