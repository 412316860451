import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useCheckout } from "@/components/Checkout/CheckoutMachine";
import { useAppSelector } from "../../../../redux/hooks";
import { UserLoginContext } from "../../../pages/Auth/UserLoginProvider";
import { getAllCarts } from "@/redux/selectors";
import _get from "lodash/get";

const EnterCheckoutFlow = () => {
  const { send: checkoutSend, snapshot } = useCheckout();
  const allCarts = useAppSelector(getAllCarts);
  const retailers = useAppSelector((state) => state.retailers);
  const userProfile = useAppSelector((state) => state.userProfile);
  const { send, setInitialOpenUrl } = useContext(UserLoginContext);
  const { loadingCarts } = useAppSelector((state) => state.checkout);
  const navigate = useNavigate();

  useEffect(() => {
    const search = window.location.search;

    setInitialOpenUrl({ pathname: "/entercheckout", search });

    if (allCarts.length > 0 && !!userProfile && retailers.length > 0) {
      if (snapshot.context.globalCheckoutState === "IDLE") {
        checkoutSend({
          type: "INITIATE_CHECKOUT",
          data: {
            retailerIds: allCarts.map((cart) => cart.retailerId) as number[],
            retailerId: allCarts[0].retailerId as number,
            retailerConnectionInfoList: retailers.map((retailer) => {
              const status = _get(userProfile, [
                "extendedAttributes",
                `connectedRetailer_${retailer.parentRetailerId}_state`,
              ]);

              return {
                retailerId: retailer.parentRetailerId,
                credential: {
                  connectionStatus: status,
                },
              };
            }),
          },
        });
      }
      navigate("/checkout", { state: { from: "init_checkout" } });
    } else if (!userProfile) {
      send({
        type: "SIGN_IN",
      });
    } else if (retailers.length !== 0) {
      navigate("/mybag");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailers, allCarts.length, loadingCarts, userProfile]);

  return <div className="checkout-flow"></div>;
};

export default EnterCheckoutFlow;
