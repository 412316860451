import {
  AnalyticsService,
  AnalyticsTrackingEvent,
  PopupDrawer,
  ProductPageDialog,
  ProductPageDialogContent,
  ProductPageDialogProps,
  ProductRewardSearchResult,
} from "@brandclub/common-ui";
import { create } from "zustand";
import { formatMoney, getEntityImage } from "../../../utils/StringUtils";
import { useShareProductParams } from "../../../utils/hooks/useShareProductParam";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import LoginButtons from "../StoreLogin/LoginButtons";
import { useSignInPage } from "../StoreLogin/useStoreLogin";

interface BearState {
  product: ProductRewardSearchResult | undefined;
  open: boolean;
  close: () => void;
  isLoggedOut: boolean;
}

const useConfirmDialogStore = create<BearState>((set) => ({
  product: undefined,
  open: false,
  close: () => set({ open: false }),
  isLoggedOut: false,
}));

interface GetShareProductMessageProps {
  campaignRewards?: ProductRewardSearchResult["campaignRewards"];
}
export const getShareProductMessage = ({
  campaignRewards,
}: GetShareProductMessageProps) => {
  const affiliateRewardCampaign = (campaignRewards ?? []).find(
    (camapaignReward) =>
      camapaignReward?.campaignType === "affiliateLinkCampaign"
  );
  if (affiliateRewardCampaign && affiliateRewardCampaign?.rewardAmount) {
    return {
      title: "Affiliate rewards",
      description: `Earn ${formatMoney(
        affiliateRewardCampaign.rewardAmount
      )} when someone buys this product using your link.`,
    };
  } else {
    return {
      title: "Share product",
      description: `Choose how you would like to share this product.`,
    };
  }
};

export const LoggedOutProductShareDialog = ({
  popupDrawerProps,
  ...props
}: ProductPageDialogProps) => {
  const signIn = useSignInPage();
  return (
    <PopupDrawer {...popupDrawerProps}>
      <ProductPageDialogContent {...props}>
        <LoginButtons
          trackedAdditionalData={{
            sharePageUrl: props.sharePageUrl,
            trackRetailerPDPNavigation: props.trackRetailerPDPNavigation,
            ...props.trackingParams,
          }}
          onSignInClick={() => signIn({ pathname: "/" })}
          sx={{ marginTop: "20px" }}
        />
      </ProductPageDialogContent>
    </PopupDrawer>
  );
};

const ShareProductDialog = () => {
  const { open, close, product, isLoggedOut } = useConfirmDialogStore();

  const location = useLocation();
  useEffect(() => {
    open && close();
    // close the dialog whenever the location changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const { shareLink } = useShareProductParams({
    product: product,
  });

  const productImage = getEntityImage(product?.stacklineSku || "", "product");

  const { title, description } = getShareProductMessage({
    campaignRewards: product?.campaignRewards,
  });

  return (
    <>
      {isLoggedOut ? (
        <LoggedOutProductShareDialog
          popupDrawerProps={{ open: open, onClose: close }}
          imageUrl={productImage}
          imageSize={100}
          title="Share product"
          subtitle="Create an account or sign in to get your personal affiliate link."
          sharePage
          sharePageUrl={shareLink}
          onShareLinkCopied={async () => {
            AnalyticsService.track(
              AnalyticsTrackingEvent.SHARED_AFFILIATE_LINK,
              {
                stacklineSku: product?.stacklineSku,
                retailerId: product?.retailerId,
                retailerSku: product?.retailerSku,
              }
            );
          }}
          video={undefined}
        />
      ) : (
        <ProductPageDialog
          popupDrawerProps={{ open: open, onClose: close }}
          imageUrl={productImage}
          imageSize={100}
          title={title}
          subtitle={description}
          sharePage
          sharePageUrl={shareLink}
          onShareLinkCopied={async () => {
            AnalyticsService.track(
              AnalyticsTrackingEvent.SHARED_AFFILIATE_LINK,
              {
                stacklineSku: product?.stacklineSku,
                retailerId: product?.retailerId,
                retailerSku: product?.retailerSku,
              }
            );
          }}
          video={undefined}
        />
      )}
    </>
  );
};

ShareProductDialog.show = (
  product: ProductRewardSearchResult,
  isLoggedOut: boolean
) => {
  useConfirmDialogStore.setState({
    product: product,
    open: true,
    isLoggedOut,
  });
};

export default ShareProductDialog;
