import { styled } from "@mui/material";

import { NavLink, Outlet } from "react-router-dom";
import { TABLET_SCREEN_SIZE } from "../../../../AppNavigation/constants";

const HeaderContainer = styled("div")(({ theme }) => ({
  display: "none",
  marginBottom: 30,
  overflow: "visible",
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    display: "block",
  },
  ".header": {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginBottom: 16,
  },
}));

const prefix = "/rewards";

const PillsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: 10,

  ".pill_link": {
    textDecoration: "none",

    ".pill": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2px 15px",
      borderRadius: 999,
      backgroundColor: "#fff",
      border: `1.5px solid ${theme.palette.primary.main}`,
      height: 35,
      color: theme.palette.primary.main,
      fontSize: 14,
      fontWeight: 600,
    },

    "&.active": {
      ".pill": {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
      },
    },
  },
  overflowX: "scroll",
  // hide scrollbar
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "-ms-overflow-style": "none",
}));

const NavHeader = () => {
  return (
    <HeaderContainer>
      <div className="header">Rewards</div>
      <PillsContainer>
        <NavLink className={"pill_link"} to={`${prefix}/balance`}>
          <div className="pill">Balance</div>
        </NavLink>
        <NavLink className={"pill_link"} to={`${prefix}/activity`}>
          <div className="pill">Activity</div>
        </NavLink>
        <NavLink className={"pill_link"} to={`${prefix}/expiring`}>
          <div className="pill">Expiring</div>
        </NavLink>
        <NavLink className={"pill_link"} to={`${prefix}/payouts`}>
          <div className="pill">Payouts</div>
        </NavLink>
      </PillsContainer>
    </HeaderContainer>
  );
};

const DtcRewardsOutlet = () => {
  return (
    <>
      <NavHeader />
      <Outlet />
    </>
  );
};

export default DtcRewardsOutlet;
