import { createSlice } from "@reduxjs/toolkit";

import { BrandclubEntityType } from "@brandclub/common-ui";
import { initialState } from "../../initialState";
import { RootState } from "../../store";
import { getBrandEntity, getBrandclubEntity } from "./thunk";
import _get from "lodash/get";
import ReduxStore from "../../types";

// Use the initialState as a default value
const brandEntitySlice = createSlice({
  name: "brandEntity",
  initialState: initialState.brandEntity,
  reducers: {
    setBrandEntity(state, action) {
      const id = action.payload.entity.brandId || action.payload.entity.id;
      return {
        ...state,
        ...action.payload,
        edited: false,
        id,
      };
    },
    updateBrandEntityEdited(state, action) {
      return {
        ...state,
        entity: state?.entity,
        edited: action.payload,
      };
    },
    // update brandEntity
    updateBrandEntity(state, action) {
      return {
        ...state,
        entity: state?.entity ? { ...state.entity, ...action.payload } : null,
      };
    },
    // updates brandEntity.entity
    addToBrandEntity(state, action) {
      if (state?.entity) {
        state.entity = { ...state.entity, ...action.payload };
      }
    },
    clearBrandEntity(_state) {
      return { entity: null };
    },
  },
  extraReducers: (builders) => {
    builders
      .addCase(
        getBrandEntity.fulfilled,
        (state: RootState["brandEntity"], action) => {
          const entityPayload = _get(action, ["payload", "data"]);
          const newBrandEntity: ReduxStore["brandEntity"] = {
            ...state,
            entity: entityPayload,
            loading: false,
            id: entityPayload.brandId,
            type: BrandclubEntityType.Brand,
          };
          return newBrandEntity;
        }
      )
      .addCase(
        getBrandclubEntity.fulfilled,
        (state: RootState["brandEntity"], action) => {
          const entityPayload = _get(action, [
            "payload",
            "data",
            "GetBrandClub",
          ]);
          const newBrandEntity: ReduxStore["brandEntity"] = {
            ...state,
            entity: state?.entity,
            brandclub: entityPayload,
            loading: false,
            type: BrandclubEntityType.Brand,
          };
          return newBrandEntity;
        }
      )
      .addDefaultCase((state) => state);
  },
});

export const {
  setBrandEntity,
  updateBrandEntity,
  addToBrandEntity,
  clearBrandEntity,
  updateBrandEntityEdited,
} = brandEntitySlice.actions;

export default brandEntitySlice.reducer;
