import { useAppSelector } from "../../redux/hooks";
import { useSiteContext } from "../../components/SiteProvider";
import { StoreBrandingType } from "../../types/misc";

export const useDashboardLinkPrefix = () => {
  const appConfig = useAppSelector((state) => state.appConfig);
  const { routePrefix } = useSiteContext();
  const onDTC =
    appConfig?.domainConfig?.storeBrandingType ===
    StoreBrandingType.CustomDTCStore;

  const purchaseDetailPageUrl = onDTC
    ? ``
    : routePrefix
    ? `${routePrefix}/branddashboard`
    : `/dashboard`;

  return purchaseDetailPageUrl;
};
