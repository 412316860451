import { Backdrop, styled, useTheme } from "@mui/material";
import { useCheckout } from "../../CheckoutMachine";
import SpinAnimation from "../../../../assets/lottie/fill_cycle_spin.json";
import LottieInner from "react-lottie";
import { progressLottieOptions } from "../../../../utils/lottieUtils";
import {
  GlobalCheckoutState,
  UserInputUpdateType,
} from "../../CheckoutMachine/types/GlobalCheckoutMachine";
import { useState, useEffect } from "react";

// @ts-ignore
const Lottie = LottieInner.default
  ? // @ts-ignore
    LottieInner.default
  : LottieInner;

const BackdropContent = styled("div")(({ theme }) => ({
  maxWidth: 250,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 7,
  ".spinner": {
    width: 71,
    height: 71,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 4,
  },
  ".title": {
    coler: theme.palette.primary.main,
    textAlign: "center",
    fontSize: 16,
    fontWeight: 700,
  },
  ".subtitle": {
    coler: theme.palette.primary.main,
    textAlign: "center",
    fontSize: 14,
    fontWeight: 500,
  },
}));
const CheckoutLoadingOverlay = () => {
  const theme = useTheme();
  const { snapshot } = useCheckout();
  const { userInputUpdateType, globalCheckoutState } = snapshot.context;

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");

  useEffect(() => {
    let currentTitle = "";
    let currentSubtitle = "";
    if (
      globalCheckoutState ===
      GlobalCheckoutState.WAIT_FOR_CHECKOUT_SUCCESS_DATA_LOAD
    ) {
      currentTitle = "We’re placing your order";
      currentSubtitle =
        "Stay tuned for a moment while we wrap up the last details";
    } else {
      switch (userInputUpdateType) {
        case UserInputUpdateType.UPDATE_PAYMENT_CONFIRMATION_AND_COMPLETE_CHECKOUT:
          currentTitle = "We’re placing your order";
          currentSubtitle =
            "Stay tuned for a moment while we wrap up the last details";
          break;
        case UserInputUpdateType.UPDATE_PAYMENT_METHOD:
          currentTitle = "Give us just a second";
          currentSubtitle = "We’re updating your payment method";
          break;
        case UserInputUpdateType.UPDATE_SHIPPING_ADDRESS:
          currentTitle = "Give us just a second";
          currentSubtitle = "We’re updating your shipping address";
          break;
        case UserInputUpdateType.UPDATE_SHIPPING_OPTION:
          currentTitle = "Give us just a second";
          currentSubtitle = "We’re updating your shipping choices";
          break;
        case UserInputUpdateType.ADD_PAYMENT_METHOD:
          currentTitle = "Give us just a second";
          currentSubtitle = "We’re updating your shipping address";
          break;
        case UserInputUpdateType.REMOVE_PARTIALLY_OUT_OF_STOCK_ITEM:
          currentTitle = "Give us just a second";
          currentSubtitle = "We’re updating your order";
          break;
        default:
          currentTitle = "";
          currentSubtitle = "";
          break;
      }
    }
    setTitle(currentTitle);
    setSubtitle(currentSubtitle);
  }, [userInputUpdateType, globalCheckoutState]);

  return (
    <Backdrop
      sx={{
        color: (theme) => theme.palette.primary.main,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "rgba(255, 255, 255, 0.95)",
      }}
      open={title !== ""}
      onClick={() => {}}
    >
      <BackdropContent>
        <div className="spinner">
          <div style={{ position: "absolute" }}>
            <Lottie
              options={progressLottieOptions(theme, SpinAnimation)}
              isClickToPauseDisabled
              width={160}
              height={160}
            />
          </div>
        </div>
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
      </BackdropContent>
    </Backdrop>
  );
};

export default CheckoutLoadingOverlay;
