import { styled } from "@mui/material/styles";
import { MOBILE_SCREEN_SIZE } from "../../../AppNavigation/constants";

export const DashboardHeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 10,
  marginBottom: 37,
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    marginBottom: 20,
  },
  ".title": {
    display: "flex",
    alignItems: "center",
    gap: 20,
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: 700,
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 22,
    },
  },
  ".subtitle": {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 500,
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 14,
    },
  },
}));
