import { DoneActorEvent, ErrorActorEvent } from "xstate";
import ReduxStore from "@/redux/types";
import { RetailerConnectionState } from "../../../../types/misc";
import {
  UserRetailerCheckoutBatch,
  UserRetailerCheckoutOrder,
} from "../../types";
import { RetailerCheckoutOrderStatus } from "@/api/rest/checkoutApi";
import { CheckoutUserInputEvent } from "./CheckoutUserInputMachine";
import { MfaValues } from "@/types/misc";
import { NavigateFunction } from "react-router-dom";
import { ProductCardProps } from "@/components/Checkout/components/CheckoutFlow/EnterCheckoutAnimation";

type RetailerConnectionInfoList = {
  retailerId: number;
  credential: {
    username?: string;
    password?: string;
    connectionStatus: RetailerConnectionState;
  };
}[];
export enum GlobalCheckoutState {
  IDLE = "IDLE",
  INITIATING_CHECKOUT = "INITIATING_CHECKOUT",
  LOGIN_SCREEN = "LOGIN_SCREEN",
  CHECKOUT_IN_PROGRESS = "CHECKOUT_IN_PROGRESS",
  CHECKOUT_SUMMARY = "CHECKOUT_SUMMARY",
  CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS",
  CHECKOUT_ERROR = "CHECKOUT_ERROR",
  CHECKOUT_USER_INPUT_UPDATE_IN_PROGRESS = "CHECKOUT_USER_INPUT_UPDATE_IN_PROGRESS",
  WAIT_FOR_CHECKOUT_SUCCESS_DATA_LOAD = "WAIT_FOR_CHECKOUT_SUCCESS_DATA_LOAD",
}

export enum UserInputUpdateType {
  UPDATE_PAYMENT_CONFIRMATION_AND_COMPLETE_CHECKOUT = "UPDATE_PAYMENT_CONFIRMATION_AND_COMPLETE_CHECKOUT",
  UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD",
  UPDATE_SHIPPING_ADDRESS = "UPDATE_SHIPPING_ADDRESS",
  UPDATE_SHIPPING_OPTION = "UPDATE_SHIPPING_OPTION",
  ADD_SHIPPING_ADDRESS = "ADD_SHIPPING_ADDRESS",
  ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD",
  REMOVE_PARTIALLY_OUT_OF_STOCK_ITEM = "REMOVE_PARTIALLY_OUT_OF_STOCK_ITEM",
}
export type GlobalCheckoutMachineContext = {
  globalCheckoutState: GlobalCheckoutState;
  retailerIds?: number[];
  retailerConnectionInfoList?: RetailerConnectionInfoList;
  userProfile?: ReduxStore["userProfile"] | null;
  activeCheckoutRetailerId?: number;
  checkoutBatch?: UserRetailerCheckoutBatch;
  activeCheckoutOrder?: UserRetailerCheckoutOrder;
  retailerCheckoutOrderStatus?: RetailerCheckoutOrderStatus;
  error?: Error;
  userInputError?: Error;
  enterAnimationConfig?: {
    title: string;
    checkoutCards: ProductCardProps[];
  };
  otpStatus: MfaValues;
  checkoutRetailerConnectionState?: {
    retailerId: number;
    type: "INITIAL_CONNECT" | "RETRY_LOGIN";
    errorMessage?: string;
  };
  navigate: NavigateFunction;
  userInputUpdateType?: UserInputUpdateType;
  animationCompleted?: boolean;
};

export type LoginSubmitEvent = {
  type: "ON_LOGIN_INFO_SUBMIT";
  data: {
    connectionInfo: {
      retailerId: number;
      credential: {
        username?: string;
        password?: string;
        connectionStatus: RetailerConnectionState;
      };
    };
  };
};

export type LoginScreenCloseEvent = {
  type: "ON_LOGIN_SCREEN_CLOSE";
};

export type CheckoutStatusChangeEvent = {
  type: "ON_CHECKOUT_STATUS_CHANGE";
  data: {
    retailerCheckoutOrderStatus: RetailerCheckoutOrderStatus;
  };
};

export type InitiateCheckoutEvent = {
  type: "INITIATE_CHECKOUT";
  data: {
    retailerIds: number[];
    retailerId: number;
    retailerConnectionInfoList?: RetailerConnectionInfoList;
  };
};

export type UpdateRetailerConnectionInfoList = {
  type: "UPDATE_RETAILER_CONNECTION_INFO_LIST";
  data: {
    retailerConnectionInfoList: RetailerConnectionInfoList;
  };
};

export type ResetCheckoutEvent = {
  type: "RESET_CHECKOUT";
};

export type CheckoutNextRetailer = {
  type: "CHECKOUT_NEXT_RETAILER";
};

export type CheckoutAnimationCompleteEvent = {
  type: "ON_CHECKOUT_ANIMATION_COMPLETE";
};

export type GlobalCheckoutMachineEvents =
  | DoneActorEvent
  | ErrorActorEvent
  | LoginSubmitEvent
  | LoginScreenCloseEvent
  | CheckoutStatusChangeEvent
  | CheckoutAnimationCompleteEvent
  | InitiateCheckoutEvent
  | ResetCheckoutEvent
  | UpdateRetailerConnectionInfoList
  | CheckoutUserInputEvent
  | CheckoutNextRetailer;
