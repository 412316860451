import { gql } from "@apollo/client";

export const getRewardSummaryByScan = gql`
  query getRewardSummaryByScan($syncIds: [String!]!) {
    RewardSummaryByScan(syncIds: $syncIds) {
      syncIds
      rewardAmountLocked
      rewardAmountPending
      rewardAmountCancelled
      rewardAmountUnlocked
      userRewardAmount {
        rewardAmountLocked
        rewardAmountUnlocked
      }
      rewardSummaryByBrandId {
        id
        name
        rewardAmountPending
        rewardAmountCancelled
        rewardAmountLocked
        rewardAmountUnlocked
        brand {
          brandclubId
          imageUrl
          name
          brandId
          totalRewardAmount
          isMember
          rewardAmountByStatusLifetime {
            rewardAmountUnlocked
            rewardAmountLocked
            rewardAmountPending
            rewardAmountCancelled
          }
        }
      }
    }
  }
`;
