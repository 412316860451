import { LandingHeader } from "../components/Header";
import { useFetchAffiliate } from "./data";
import { CardGrid } from "../components/CardGrid";
import LandingPageWrapper from "../components/LandingPageWrapper";
import { useEffect, useRef } from "react";

import { Link } from "react-router-dom";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";

import { useInView } from "framer-motion";
import RewardEmptyPage from "../components/RewardEmptyPage";
import ProductCard from "../../../../StoreComponents/StoreCard.tsx/ProductCard";
import LandingContainer from "../components/LandingContainer";

const AffiliateLanding = ({ brandIds }: { brandIds?: number[] }) => {
  const { data, loading, loadingMore, fetchMore, hasMore } = useFetchAffiliate({
    pageSize: 20,
    brandIds,
  });

  const ref = useRef<any>(null);

  const widgetInView = useInView(ref);

  useEffect(() => {
    if (widgetInView && !loading && !loadingMore && hasMore) {
      fetchMore();
    }
  }, [widgetInView, loading, loadingMore, hasMore, fetchMore]);

  if (!loading && data.length === 0) {
    return <RewardEmptyPage emptyTitle="No affiliate rewards?" />;
  }

  return (
    <LandingPageWrapper>
      <LandingContainer>
        {loading ? (
          <StoreLoadingSkeleton header row={2} />
        ) : (
          <>
            <LandingHeader
              title="Affiliate commission"
              subtitle="Get paid to refer purchases"
            ></LandingHeader>
            <CardGrid style={{ marginTop: 20 }}>
              {data.map((d) => {
                return (
                  <ProductCard
                    LinkComponent={Link}
                    product={d}
                    key={d.stacklineSku}
                  />
                );
              })}
            </CardGrid>
          </>
        )}
        {loadingMore ? (
          <div style={{ marginTop: 20 }}>
            <StoreLoadingSkeleton header={false} />
          </div>
        ) : null}
        <div className="end_detect" ref={ref}></div>
      </LandingContainer>
    </LandingPageWrapper>
  );
};

export default AffiliateLanding;
