/**
 * Functions for initializing `axios` and the API request environment
 */
import axios, { AxiosInstance } from "axios";
import ConfigUtils from "./ConfigUtils";

export function getCommonRequestHeaders() {
  return {
    "X-Client-AppId": ConfigUtils.getWebAppId(),
  };
}

function add401Interceptor(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}

const axiosInstance = axios.create({
  baseURL: ConfigUtils.getApiHostName(),
  headers: {
    common: getCommonRequestHeaders(),
  },
});

add401Interceptor(axiosInstance);

export const axiosRetailerSync = axios.create({
  baseURL: ConfigUtils.getServerSideSyncHostName(),
  headers: {
    common: getCommonRequestHeaders(),
  },
});

add401Interceptor(axiosRetailerSync);

export const axiosEdgeCached = axios.create({
  baseURL: ConfigUtils.getEdgeCachedApiHostName(),
  headers: {
    common: getCommonRequestHeaders(),
  },
});

add401Interceptor(axiosEdgeCached);

export const axiosBuilder = () => {
  const instance = axios.create({
    baseURL: ConfigUtils.getApiHostName(),
  });
  add401Interceptor(instance);
  return instance;
};

export const axiosEdgeCachedBuilder = () => {
  const instance = axios.create({
    baseURL: ConfigUtils.getEdgeCachedApiHostName(),
  });
  add401Interceptor(instance);
  return instance;
};

export default axiosInstance;
