import CryptoJS from "crypto-js";

export const encryptData = <T>(data: T, encryptionKey: string) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), encryptionKey).toString();
};

export const decryptData = <T>(
  encryptedData: string,
  encryptionKey: string
): T => {
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, encryptionKey);
  const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedData);
};
