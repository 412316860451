import _orderBy from "lodash/orderBy";
import _uniqBy from "lodash/uniqBy";
import { useEffect, useState } from "react";
import { getRewardsForUser } from "../../../../../../api/graphQl/authenticated";
import reduxApolloClient from "../../../../../../redux/reduxApolloClient";
import { CustomerCashBackRewardForTable } from "../../../../../../types/misc";
import ConfigUtils from "../../../../../../utils/ConfigUtils";
import RewardList from "./RewardList";
import { useSiteContext } from "../../../../../SiteProvider";

const FetchVariables = {
  expiringRewards: false,
  brandIds: undefined,
};

const ActivityRewardList = () => {
  const { brandId } = useSiteContext();
  const [rows, setRows] = useState<CustomerCashBackRewardForTable[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchRewardsForUser = async (variables: any) => {
    try {
      setLoading(true);
      const { data } = await reduxApolloClient.query<{
        CustomerCashBackReward: CustomerCashBackRewardForTable[];
      }>({
        query: getRewardsForUser,
        variables,
        fetchPolicy: "no-cache",
        context: {
          uri: `${ConfigUtils.getGraphQlUri()}?id=getRewardsForUser`,
        },
      });

      if (data?.CustomerCashBackReward) {
        // filter out rewards for products that don't have metadata associated with them to display
        setRows((existing) => {
          const orderedlist = _orderBy(
            [
              ...(existing || []),
              ...data.CustomerCashBackReward.filter(
                (x: any) =>
                  !x.extendedAttributes.productId ||
                  (x.extendedAttributes.productId &&
                    x.extendedAttributes.additionalMetaData &&
                    x.extendedAttributes.additionalMetaData.stacklineSku)
              ),
            ].filter((x) => x?.rewardType !== "cashOutFeeReward"),

            ["updatedTime"],
            ["desc"]
          );
          const uniqueList = _uniqBy(orderedlist, "rewardId");
          const orderedUniqueList = _orderBy(
            uniqueList,
            ["createdTime"],
            ["desc"]
          );
          return orderedUniqueList;
        });
      }
    } catch (e) {
      console.error("error: ", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRewardsForUser({
      ...FetchVariables,
      brandIds: brandId ? [brandId] : undefined,
      page: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RewardList
      data={rows}
      listType="activity"
      loading={loading}
      headerText="Reward activity"
    />
  );
};

export default ActivityRewardList;
