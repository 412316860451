import React, { useCallback, useRef, useState } from "react";
import { ArrowCircleRightIcon } from "@brandclub/common-ui";
import { styled } from "@mui/material/styles";
import Box, { BoxProps } from "@mui/material/Box";
import { AnimationLifecycles, motion } from "framer-motion";
import { SvgIconProps } from "@mui/material/SvgIcon";

export interface StoreCarouselProps {
  children: React.ReactNode[];
  styleProps?: BoxProps;
  hideArrows?: boolean;
}

interface CarouselArrowProps extends SvgIconProps {
  disabled: boolean;
  delay?: number;
  onAnimationComplete?: AnimationLifecycles["onAnimationComplete"];
  direction: "prev" | "next";
}

const Carousel = styled(motion.div)({
  display: "flex",
  gap: "15px",
  overflow: "auto",
  scrollSnapType: "x mandatory",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
});

const StyledArrowIcon = styled(ArrowCircleRightIcon)<{ disabled: boolean }>(
  ({ theme, disabled }) => ({
    zIndex: 1,
    color: theme.palette.primary.main,
    backgroundColor: "#f6f8fa",
    borderRadius: "100%",
    pointerEvents: disabled ? "none" : undefined,
    "&.prev": {
      transform: "rotate(180deg)",
    },
  })
);

const CarouselWrapper = styled(Box)({
  position: "relative",
  width: "100%",
  maxWidth: "100%",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const isArrowDisabled = (
  direction: "prev" | "next",
  carousel: HTMLDivElement | null
) => {
  if (carousel) {
    const scrollLeft = carousel.scrollLeft;
    const scrollWidth = carousel.scrollWidth;
    const clientWidth = carousel.clientWidth;
    const threshold = 5; // Threshold for checking if carousel is at start or end

    if (direction === "prev") {
      return scrollLeft <= threshold;
    } else {
      return scrollLeft + clientWidth >= scrollWidth - threshold;
    }
  }
  return false;
};

const CarouselArrow = ({
  direction,
  className,
  sx,
  delay,
  onAnimationComplete,
  ...props
}: CarouselArrowProps) => {
  const initialStyle =
    direction === "prev" ? { paddingRight: 0 } : { paddingLeft: 0 };
  const animatedStyle =
    direction === "prev"
      ? { paddingRight: props.disabled ? 0 : "15px" }
      : { paddingLeft: props.disabled ? 0 : "15px" };
  return (
    <motion.div
      initial={{ opacity: 0, width: 0, ...initialStyle }}
      animate={{
        opacity: props.disabled ? 0 : 1,
        width: props.disabled ? 0 : 43,
        ...animatedStyle,
      }}
      transition={{
        duration: 0.2,
        ease: "linear",
        delay,
      }}
      onAnimationComplete={onAnimationComplete}
    >
      <StyledArrowIcon
        sx={{ fontSize: 43, ...sx }}
        className={[direction, className].filter(Boolean).join(" ")}
        {...props}
        disabled={props.disabled}
      />
    </motion.div>
  );
};

const StoreCarousel = ({
  children,
  styleProps,
  hideArrows,
  ...props
}: StoreCarouselProps) => {
  const carouselRef = useRef<HTMLDivElement | null>(null);
  const itemRefs = useRef<HTMLDivElement[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPrevDisabled, setIsPrevDisabled] = useState(currentIndex === 0);
  const [isNextDisabled, setIsNextDisabled] = useState(
    currentIndex >= children.length - 1
  );
  const delayArrowAnimationRef = useRef<number | undefined>(undefined);
  const resetDelayArrowAnimation = () => {
    delayArrowAnimationRef.current = undefined;
  };

  const handleScroll = useCallback(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      const newIndex = itemRefs.current.findIndex(
        (itemRef) =>
          Math.floor(itemRef.offsetLeft) >= Math.floor(carousel.scrollLeft)
      );
      let index = newIndex !== -1 ? newIndex : itemRefs.current.length - 1;
      setCurrentIndex(index);
      setIsPrevDisabled(isArrowDisabled("prev", carousel));
      setIsNextDisabled(isArrowDisabled("next", carousel));
    }
  }, []);

  const addRefListeners = (ref: HTMLDivElement | null) => {
    if (ref && carouselRef.current !== ref) {
      carouselRef.current = ref;
      // Remove existing listeners
      carouselRef.current.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
      // Add new listeners
      carouselRef.current.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleScroll);
      // Update arrow disabled state when carousel ref is set
      setIsNextDisabled(isArrowDisabled("next", carouselRef.current));
      setIsPrevDisabled(isArrowDisabled("prev", carouselRef.current));
    }
  };

  const scrollToItem = (index: number) => {
    const carousel = carouselRef.current;
    const itemRef = itemRefs.current[index];
    if (carousel && itemRef) {
      carousel.scrollTo({
        left: itemRef.offsetWidth * index,
        behavior: "smooth",
      });
    }
  };

  const handlePrevClick = () => {
    if (!isPrevDisabled) {
      const prevIndex = Math.max(currentIndex - 1, 0);
      scrollToItem(prevIndex);
      setCurrentIndex(prevIndex);
      const isAtStart =
        currentIndex === 0 || isArrowDisabled("prev", carouselRef.current);
      delayArrowAnimationRef.current = 0.3;
      setIsPrevDisabled(isAtStart);
    }
  };

  const handleNextClick = () => {
    if (!isNextDisabled) {
      const lastItemIndex = itemRefs.current.length - 1;
      const nextIndex = Math.min(currentIndex + 1, lastItemIndex);
      const isAtEnd =
        currentIndex >= lastItemIndex ||
        isArrowDisabled("next", carouselRef.current);
      scrollToItem(nextIndex);
      setCurrentIndex(nextIndex);
      delayArrowAnimationRef.current = 0.3;
      setIsNextDisabled(isAtEnd);
    }
  };

  return (
    <CarouselWrapper {...styleProps}>
      {!hideArrows && (
        <CarouselArrow
          direction="prev"
          onClick={handlePrevClick}
          disabled={isPrevDisabled}
          delay={!isPrevDisabled ? delayArrowAnimationRef.current : undefined} // only delay when the arrow was invisible and is now visible
          onAnimationComplete={resetDelayArrowAnimation} // only delay when the arrow is clicked, reset it after animation is complete so it doesn't delay when scrolling
        />
      )}
      <Carousel key={children.length} ref={addRefListeners} {...props}>
        {React.Children.map(children, (child, index) => (
          <Box
            sx={{
              scrollSnapAlign: "start",
              flexShrink: 0,
            }}
            ref={(ref) => {
              const current = itemRefs.current[index];
              if (current && !ref) {
                // Remove current
                itemRefs.current = itemRefs.current.filter(
                  (_, i) => i !== index
                );
                return;
              }
              itemRefs.current[index] = ref as HTMLDivElement;
            }}
            key={index}
          >
            {child}
          </Box>
        ))}
      </Carousel>
      {!hideArrows && (
        <CarouselArrow
          direction="next"
          onClick={handleNextClick}
          disabled={isNextDisabled}
          delay={!isNextDisabled ? delayArrowAnimationRef.current : undefined} // only delay when the arrow was invisible and is now visible
          onAnimationComplete={resetDelayArrowAnimation} // only delay when the arrow is clicked, reset it after animation is complete so it doesn't delay when scrolling
        />
      )}
    </CarouselWrapper>
  );
};

export default StoreCarousel;
