import FlowContainer from "../../components/FlowContainer";
import { AnalyticsTrackingEvent, brandclub_colors } from "@brandclub/common-ui";
import { ReviewQuestionContainer } from "./pros";
import {
  FlowOutlineBorderButton,
  FlowSolidButton,
  FlowSolidButtonGrey,
} from "../../components/FlowButtons";
import FiveStarRating from "../components/FiveStarRating";

import { ButtonLoadingEllipsis } from "../../../../../StoreComponents/StoreButton";
import ProductHeader from "../components/ProductHeader";

const LOW_RATING_CAP = 2;

type SummaryProps = {
  product: any;
  title: string;
  reviewText: string;
  starRating: number;
  moveBack: () => void;
  moveNext: (e: "SUBMIT" | "SEND_TO_BRAND") => void;
  submitting: boolean;
  sending: boolean;
};

const Summary = ({
  product,
  title,
  reviewText,
  starRating,
  moveBack,
  moveNext,
  sending,
  submitting,
}: SummaryProps) => {
  const anySubmitting = sending || submitting;
  return (
    <FlowContainer style={{ background: brandclub_colors.white }}>
      <ReviewQuestionContainer>
        <div className="review_info">
          <ProductHeader product={product} />
          <div className="section">
            <div className="title">Confirm your product review.</div>

            <div style={{ margin: "45px 0 30px 0" }}>
              <FiveStarRating value={starRating} size={33} disabled />
            </div>
            <div className="summary_title">{title}</div>
            <div className="desc" style={{ margin: "24px 0" }}>
              {reviewText}
            </div>
          </div>
        </div>
        {starRating <= LOW_RATING_CAP ? (
          <div className="actions">
            <div style={{ display: "flex", gap: 20 }}>
              <FlowSolidButtonGrey
                style={{ minWidth: 143 }}
                disabled={anySubmitting}
                onClick={() => {
                  moveBack();
                }}
              >
                Back
              </FlowSolidButtonGrey>
              <FlowOutlineBorderButton
                trackedEventName={AnalyticsTrackingEvent.STARTED_SUBMIT_REVIEW}
                trackedAdditionalData={{
                  product,
                }}
                style={{ minWidth: 143 }}
                disabled={anySubmitting}
                onClick={() => {
                  moveNext("SUBMIT");
                }}
              >
                {submitting ? (
                  <>
                    Submitting
                    <ButtonLoadingEllipsis baseFontSize={16} blue />
                  </>
                ) : (
                  "Submit"
                )}
              </FlowOutlineBorderButton>
            </div>

            <FlowSolidButton
              trackedEventName={
                AnalyticsTrackingEvent.SENDING_NON_ELIGIBLE_REVIEW_TO_BRAND
              }
              trackedAdditionalData={{
                product,
              }}
              disabled={anySubmitting}
              onClick={() => {
                moveNext("SEND_TO_BRAND");
              }}
              style={{ paddingLeft: 30, paddingRight: 30 }}
            >
              {sending ? (
                <>
                  Sending
                  <ButtonLoadingEllipsis baseFontSize={16} />
                </>
              ) : (
                `Send to ${product?.brandName ?? "brand"}`
              )}
            </FlowSolidButton>
          </div>
        ) : (
          <div className="actions">
            <FlowSolidButtonGrey
              onClick={() => {
                moveBack();
              }}
            >
              Back
            </FlowSolidButtonGrey>
            <FlowSolidButton
              onClick={() => {
                moveNext("SUBMIT");
              }}
              disabled={anySubmitting}
              style={{ paddingLeft: 30, paddingRight: 30 }}
            >
              {submitting ? (
                <>
                  Submitting
                  <ButtonLoadingEllipsis baseFontSize={16} />
                </>
              ) : (
                "Submit review"
              )}

              {/* {`Send to ${product?.brandName ?? "brand"}`} */}
            </FlowSolidButton>
          </div>
        )}
      </ReviewQuestionContainer>
    </FlowContainer>
  );
};

export default Summary;
