import React from "react";
import FlowContainer from "../../components/FlowContainer";
import { brandclub_colors } from "@brandclub/common-ui";
import { styled } from "@mui/material";
import {
  FlowSolidButton,
  FlowSolidButtonGrey,
} from "../../components/FlowButtons";
import ReviewSelectPill from "../components/ReviewSelectPill";
import Loading from "../../../../../Loading";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../../AppNavigation/constants";
import ProductHeader from "../components/ProductHeader";

export const ReviewQuestionContainer = styled("div")(({ theme }) => ({
  padding: "20px 40px",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  flex: 1,
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    padding: "20px 20px",
  },
  ".review_info": {
    padding: "30px 20px",
    maxWidth: 746,
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "30px",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      padding: "20px 20px",
    },

    ".section": {
      width: "100%",
      ".summary_logo": {
        width: 200,
        height: 200,
        objectFit: "contain",
      },
      ".title": {
        fontSize: 24,
        fontWeight: 700,
        color: theme.palette.primary.main,
        [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 18,
        },
      },
      ".second_title": {
        fontSize: 20,
        fontWeight: 700,
        color: theme.palette.primary.main,
        [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 16,
        },
      },
      ".input_wrapper": {
        marginTop: 25,
      },
      ".twoline_text": {
        overflow: "hidden",
        // letterSpacing: 0.36,
        WebkitLineClamp: 2,
        lineHeight: 1.5,
        height: `3em`,
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        wordBreak: "break-word",
      },
      ".subtitle": {
        marginTop: 7,
        fontSize: 16,
        fontWeight: 500,
        color: theme.palette.primary.main,
        [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 10,
        },
      },
      ".selections": {
        display: "flex",
        flexWrap: "wrap",
        gap: "15px",
        marginTop: 30,
        [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          marginTop: 20,
        },
      },

      ".add_comments": {
        marginTop: 60,
      },

      ".text_input": {
        marginTop: 24,
        [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          marginTop: 20,
        },
        ".remaining_char": {},
      },

      ".label": {
        fontSize: 24,
        fontWeight: 600,
        color: theme.palette.primary.main,
        marginBottom: 20,
        [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 12,
        },
      },

      ".summary_title": {
        fontSize: 20,
        fontWeight: 700,
        color: theme.palette.primary.main,
        [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 18,
        },
      },
      ".desc": {
        fontSize: 16,
        fontWeight: 500,
        color: theme.palette.primary.main,
        [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 14,
        },
      },
      ".input_box": {
        outline: "none",
        resize: "none",
        width: "100%",
        borderRadius: "8px",
        padding: "21px",
        fontSize: 16,
        fontWeight: 500,
        color: theme.palette.primary.main,
        height: 434,
        maxHeight: "474px",
        boxSizing: "border-box",
        border: `1px solid #dcdddc`,
        backgroundColor: "#f6f8fa",
        "&.single": {
          height: 54,
        },
        [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 14,
          padding: "15px",
          height: 200,
          "&.single": {
            height: 72,
          },
        },
        "&:disabled": {
          backgroundColor: "#f6f8fa",
        },
        "&::placeholder": {
          color: brandclub_colors.darkBlue5,
        },
      },
    },
  },
}));

type ProPageProps = {
  product: any;
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
  selectedOptions: string[];
  options: string[];
  moveBack: () => void;
  moveNext: () => void;
  feedback: string;
  setFeedback: React.Dispatch<React.SetStateAction<string>>;
};

const Pros = ({
  product,
  options,
  selectedOptions,
  setSelectedOptions,
  moveBack,
  moveNext,
  feedback,
  setFeedback,
}: ProPageProps) => {
  const handleSelect = (option: string) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((o) => o !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const disabledNext = selectedOptions.length === 0;
  return (
    <FlowContainer style={{ background: brandclub_colors.white }}>
      <ReviewQuestionContainer>
        <div className="review_info">
          <div className="section">
            <ProductHeader product={product} />
          </div>
          <div className="section">
            <div className="title">What features did you like most?</div>
            <div className="subtitle">Select all that apply</div>
            <div className="selections">
              {options?.length ? (
                options.map((option) => {
                  const selected = selectedOptions?.includes(option);
                  return (
                    <ReviewSelectPill
                      selected={selected}
                      text={option}
                      onClick={() => {
                        handleSelect(option);
                      }}
                    />
                  );
                })
              ) : (
                <div style={{ width: "100%" }}>
                  <Loading dot />
                </div>
              )}
            </div>
            <div className="add_comments">
              <div className="second_title">Add comments</div>
              <div className="text_input">
                <textarea
                  className="input_box"
                  style={{ maxHeight: 284 }}
                  value={feedback}
                  placeholder="Enter description (optional)"
                  onChange={(e) => setFeedback(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="actions">
          <FlowSolidButtonGrey
            onClick={() => {
              moveBack();
            }}
          >
            Back
          </FlowSolidButtonGrey>
          <FlowSolidButton
            disabled={disabledNext}
            onClick={() => {
              moveNext();
            }}
          >
            Next
          </FlowSolidButton>
        </div>
      </ReviewQuestionContainer>
    </FlowContainer>
  );
};

export default Pros;
