import { styled } from "@mui/material";
import React from "react";

const InfoButtonContainer = styled("div")({
  height: 21,
  width: 21,
  backgroundColor: "#f6f8fa",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  select: "none",
  fontSize: 12,
  fontWeight: 500,
});
export const InfoButton = () => {
  return <InfoButtonContainer>i</InfoButtonContainer>;
};
