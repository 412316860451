import { SearchIcon } from "@brandclub/common-ui";
import { styled } from "@mui/material";
import React from "react";

const NAV_BUTTON_SIZE = 25;
export const NavButton = styled("div")(({ theme }) => ({
  height: NAV_BUTTON_SIZE,
  width: NAV_BUTTON_SIZE,
  minWidth: NAV_BUTTON_SIZE,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  "& img": {
    maxHeight: NAV_BUTTON_SIZE,
    maxWidth: NAV_BUTTON_SIZE,
  },
  color: "inherit",
}));

const NavSearchButton = ({
  handleClick,
}: {
  handleClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}) => {
  return (
    <NavButton onClick={handleClick}>
      <SearchIcon sx={{ fontSize: 25 }} />
    </NavButton>
  );
};

export default NavSearchButton;
