import { createSlice } from "@reduxjs/toolkit";

import { BrandclubEntityType } from "@brandclub/common-ui";
import { initialState } from "../../initialState";
import { RootState } from "../../store";
import { getMainEntity } from "./thunk";
import _get from "lodash/get";
import ReduxStore from "../../types";

export type MainEntityType = Extract<
  BrandclubEntityType,
  | BrandclubEntityType.Audience
  | BrandclubEntityType.Campaign
  | BrandclubEntityType.Customer
  | BrandclubEntityType.Product
>;

const getIdentifierKeyForEntity = (entity?: BrandclubEntityType) => {
  let key = "id";
  switch (entity) {
    case BrandclubEntityType.Brand:
      key = "brandId";
      break;
    case BrandclubEntityType.Product:
      key = "retailerSku";
      break;
  }
  return key;
};

// Use the initialState as a default value
const mainEntitySlice = createSlice({
  name: "mainEntity",
  initialState: initialState.mainEntity,
  reducers: {
    setMainEntity(state, action) {
      const idKey = getIdentifierKeyForEntity(action.payload.type);
      const id = action.payload.entity[idKey] || action.payload.entity.id;
      return {
        ...state,
        ...action.payload,
        edited: false,
        id,
      };
    },
    updateMainEntityEdited(state, action) {
      return {
        ...state,
        entity: state?.entity,
        edited: action.payload,
      };
    },
    // update mainEntity
    updateMainEntity(state, action) {
      return {
        ...state,
        entity: state?.entity ? { ...state.entity, ...action.payload } : null,
      };
    },
    // updates mainEntity.entity
    addToMainEntity(state, action) {
      if (state?.entity) {
        state.entity = { ...state.entity, ...action.payload };
      }
    },
    clearMainEntity(_state) {
      return { entity: null };
    },
  },
  extraReducers: (builders) => {
    builders
      .addCase(
        getMainEntity.fulfilled,
        (state: RootState["mainEntity"], action) => {
          const entityPayload = _get(action, ["payload", "data"]);
          if (action.meta.arg.type === "brand") {
            const newMainEntity: ReduxStore["mainEntity"] = {
              entity: entityPayload,
              loading: false,
              id: entityPayload.brandId,
              type: BrandclubEntityType.Brand,
            };
            return newMainEntity;
          } else if (action.meta.arg.type === "product") {
            const newMainEntity: ReduxStore["mainEntity"] = {
              entity: entityPayload,
              loading: false,
              id: entityPayload?.stacklineSku,
              type: BrandclubEntityType.Product,
            };
            return newMainEntity;
          }
        }
      )
      .addDefaultCase((state) => state);
  },
});

export const {
  setMainEntity,
  updateMainEntity,
  addToMainEntity,
  clearMainEntity,
  updateMainEntityEdited,
} = mainEntitySlice.actions;

export default mainEntitySlice.reducer;
