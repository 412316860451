import { withFadeTransition } from "../../../../StoreComponents/StoreTransition";
import { useSyncContext } from "../../../../SyncProvider";
import AccountsConnectPreview from "../../Pages/Accounts/ConnectedPreview";
import { useFetchAccount } from "../../Pages/Accounts/data";
import AccountsUnconnectPreview from "../../Pages/Accounts/UnconnectedPreview";
import LandingContainer from "../../Pages/components/LandingContainer";
import { StyledLink } from "../../../../AppNavigation/StyledLink";
import { LandingHeader } from "../../Pages/components/Header";
import { MOBILE_SCREEN_SIZE } from "../../../../AppNavigation/constants";
import { useDashboardLinkPrefix } from "../../../../../utils/hooks/useDashboardLinkPrefix";

const DtcAccount = () => {
  const predix = useDashboardLinkPrefix();
  const { connectedRetailers } = useFetchAccount({});
  const { syncStatus } = useSyncContext();

  return (
    <div>
      <AccountsConnectPreview />
      <AccountsUnconnectPreview
        sx={{
          [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            paddingTop: 0,
          },
        }}
      />
      {syncStatus === "normal" && connectedRetailers.length > 0 ? (
        <LandingContainer
          sx={{
            [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
              paddingTop: "20px",
            },
          }}
        >
          <LandingHeader
            title="Edit your accounts"
            subtitle={
              <span>
                Reset access and&nbsp;
                <StyledLink to={`${predix}/accounts/edit`}>edit</StyledLink>
                &nbsp;accounts
              </span>
            }
          />
        </LandingContainer>
      ) : null}
    </div>
  );
};

export default withFadeTransition(DtcAccount);
