const colors = {
  blue: "#5572b7",
  blueWhite: "#f6f8fa",
  blueWhiteBorder: "#ebeef1",
  darkBlue: "#222155",
  darkBlue1: "rgba(34, 33, 85, 0.1)",
  darkBlue2: "rgba(34, 33, 85, 0.2)",
  darkBlue3: "rgba(34, 33, 85, 0.3)",
  darkBlue5: "rgba(34, 33, 85, 0.5)",
  grayLight: "#e8e8ed",
  gray: "#7e8fa8",
  grayBorder: "#bec7d3",
  lightGreen: "#9ee0d0",
  lightGreenLogo: "#a2d9cd",
  lightGreenLogo2: "rgba(162, 217, 205, 0.2)",
  lightGreenLogo3: "rgba(162, 217, 205, 0.3)",
  white: "#fff",
} as const;

export default colors;
